import React, { useEffect, useState } from "react";
import AdStoreMainCategoriesCard from "./AdStoreMainCategoriesCard";
import "./mainCategories.css";

const AdStoreMainCategories = ({data}) => {
  

  return (
    <>
      <div className="flex flex-col gap-3">
        <h2 className="xl:text-3xl md:text-2xl text-xl font-bold ">
          Main Categories
        </h2>
        <div className="flex justify-start md:gap-[50px] gap-[52px] xl:h-[140px]  carousel carousel-center px-5">
          {data?.length>0&&data?.map((item) => (
            <>
              <div className="carousel-item ">
                <AdStoreMainCategoriesCard item={item} />
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default AdStoreMainCategories;
