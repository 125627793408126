import React, { useState } from "react";

import SubCategoriesSmartFilterSidebarComponent from "./SubCategoriesSmartFilterComponent";

import { Link } from "react-router-dom";

function SubCategorySmartFilterSidebar() {
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  const [isPriceOpen, setIsPriceOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isBrandOpen, setIsBrandOpen] = useState(false);
  const [isColorOpen, setIsColorOpen] = useState(false);
  const [isSizeOpen, setIsSizeOpen] = useState(false);
  const [isSpecialOfferOpen, setIsSpecialOfferOpen] = useState(false);
  const [isStockStatusOpen, setIsStockStatusOpen] = useState(false);
  const [isRatingsOpen, setIsRatingsOpen] = useState(false);
  const [isVendersOpen, setIsVendersOpen] = useState(false);

  const toggleContent = (isOpen, category) => {
    switch (category) {
      case "categories":
        setIsCategoriesOpen(isOpen);
        break;
      case "price":
        setIsPriceOpen(isOpen);
        break;
      case "sort":
        setIsSortOpen(isOpen);
        break;
      case "brand":
        setIsBrandOpen(isOpen);
        break;
      case "color":
        setIsColorOpen(isOpen);
        break;
      case "size":
        setIsSizeOpen(isOpen);
        break;
      case "special-offer":
        setIsSpecialOfferOpen(isOpen);
        break;
      case "stock-status":
        setIsStockStatusOpen(isOpen);
        break;
      case "ratings":
        setIsRatingsOpen(isOpen);
        break;
      case "venders":
        setIsVendersOpen(isOpen);
        break;

      default:
        break;
    }
  };

  return (
    <div className="w-full">
      <div className="flex w-full flex-col justify-start items-start">
        <div className="border-b-2 w-full ">
          <SubCategoriesSmartFilterSidebarComponent
            toggleContent={(isOpen) => toggleContent(isOpen, "categories")}
          >
            Categories
          </SubCategoriesSmartFilterSidebarComponent>
          <div className={`w-full mb-3 ${isCategoriesOpen ? "" : "hidden"}`}>
            <div className="w-full p-3">
              <Link className="text-[#828282] w-full">sub 1</Link>
            </div>
          </div>
        </div>

        <div className="border-b-2 w-full">
          <SubCategoriesSmartFilterSidebarComponent
            toggleContent={(isOpen) => toggleContent(isOpen, "price")}
          >
            Price
          </SubCategoriesSmartFilterSidebarComponent>
          <div className={`w-full mb-3 ${isPriceOpen ? "" : "hidden"}`}>
            <div className="w-full flex items-center gap-4 p-3">
              <div className="w-3/5 flex justify-between items-center gap-3">
                <input
                  type="number"
                  className="w-2/5 border border-black rounded-sm p-1"
                  placeholder="$0"
                />
                to
                <input
                  type="number"
                  className="w-2/4 border border-black rounded-sm p-1"
                  placeholder="$600"
                />
              </div>

              <div className="w-2/6">
                <button className=" w-full bg-[#1983FF] text-white rounded-sm text-center p-1">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b-2 w-full">
          <SubCategoriesSmartFilterSidebarComponent
            toggleContent={(isOpen) => toggleContent(isOpen, "sort")}
          >
            Sort
          </SubCategoriesSmartFilterSidebarComponent>
          <div className={`w-full mb-3 p-3 ${isSortOpen ? "" : "hidden"}`}>
            <div className="flex items-center  gap-3">
              <input type="checkbox" />
              <label className="text-[#828282]">Sort by Popularity</label>
            </div>
            <div className="flex items-center  gap-3">
              <input type="checkbox" />
              <label className="text-[#828282]">Sort by new Arrivals</label>
            </div>
          </div>
        </div>

        <div className="border-b-2 w-full">
          <SubCategoriesSmartFilterSidebarComponent
            toggleContent={(isOpen) => toggleContent(isOpen, "brand")}
          >
            Brand
          </SubCategoriesSmartFilterSidebarComponent>
          <div className={`w-full mb-3 p-3 ${isBrandOpen ? "" : "hidden"}`}>
            <div className="flex items-center gap-3">
              <input type="checkbox" />
              <label className="text-[#828282]">brand 1</label>
            </div>
          </div>
        </div>

        <div className="w-full border-b-2">
          <SubCategoriesSmartFilterSidebarComponent
            toggleContent={(isOpen) => toggleContent(isOpen, "color")}
          >
            Color
          </SubCategoriesSmartFilterSidebarComponent>
          <div className={`w-full mb-3 p-3 ${isColorOpen ? "" : "hidden"}`}>
            <div className=" grid grid-cols-4 gap-3">
              <div className="flex flex-col justify-center items-center">
                <div className="bg-red-700 w-8 h-8 rounded-full"></div>
                <p>Red</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full border-b-2">
          <SubCategoriesSmartFilterSidebarComponent
            toggleContent={(isOpen) => toggleContent(isOpen, "size")}
          >
            Size
          </SubCategoriesSmartFilterSidebarComponent>
          <div className={`w-full mb-3 p-3 ${isSizeOpen ? "" : "hidden"}`}>
            <div className=" grid grid-cols-4 gap-4">
              <div className=" w-14 h-7">
                <button className="border-[#828282] text-[#828282] border-2 opacity-50 w-full rounded-xl">
                  XXS
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full border-b-2">
          <SubCategoriesSmartFilterSidebarComponent
            toggleContent={(isOpen) => toggleContent(isOpen, "special-offer")}
          >
            Special Offers
          </SubCategoriesSmartFilterSidebarComponent>
          <div
            className={`w-full mb-3 p-3 ${isSpecialOfferOpen ? "" : "hidden"}`}
          ></div>
        </div>

        <div className="w-full border-b-2">
          <SubCategoriesSmartFilterSidebarComponent
            toggleContent={(isOpen) => toggleContent(isOpen, "stock-status")}
          >
            Stock Status
          </SubCategoriesSmartFilterSidebarComponent>
          <div
            className={`w-full mb-3 p-3 ${isStockStatusOpen ? "" : "hidden"}`}
          >
            <div className="flex items-center gap-3">
              <input type="checkbox" />
              <label className="text-[#828282]">On Sale</label>
            </div>
            <div className="flex items-center  gap-3">
              <input type="checkbox" />
              <label className="text-[#828282]">In Stock</label>
            </div>
          </div>
        </div>

        <div className="w-full border-b-2">
          <SubCategoriesSmartFilterSidebarComponent
            toggleContent={(isOpen) => toggleContent(isOpen, "ratings")}
          >
            Ratings
          </SubCategoriesSmartFilterSidebarComponent>
          <div className={`w-full mb-3 p-3 ${isRatingsOpen ? "" : "hidden"}`}>
            <div className="flex items-center mb-2 gap-3">
              <input type="checkbox" />
              <label className="text-[#828282]">
                <img
                  src="./assets/images/rating/5star.png"
                  alt="5 stars rating"
                />
              </label>
            </div>
            <div className="flex items-center  mb-2 gap-3">
              <input type="checkbox" />
              <label className="text-[#828282]">
                <img
                  src="./assets/images/rating/4star.png"
                  alt="5 stars rating"
                />
              </label>
            </div>
            <div className="flex items-center mb-2 gap-3">
              <input type="checkbox" />
              <label className="text-[#828282]">
                <img
                  src="./assets/images/rating/3star.png"
                  alt="5 stars rating"
                />
              </label>
            </div>
            <div className="flex items-center mb-2  gap-3">
              <input type="checkbox" />
              <label className="text-[#828282]">
                <img
                  src="./assets/images/rating/2star.png"
                  alt="5 stars rating"
                />
              </label>
            </div>
            <div className="flex items-center mb-2  gap-3">
              <input type="checkbox" />
              <label className="text-[#828282]">
                <img
                  src="./assets/images/rating/1star.png"
                  alt="5 stars rating"
                />
              </label>
            </div>
          </div>
        </div>

        <div className="w-full border-b-2">
          <SubCategoriesSmartFilterSidebarComponent
            toggleContent={(isOpen) => toggleContent(isOpen, "venders")}
          >
            Venders
          </SubCategoriesSmartFilterSidebarComponent>
          <div className={`w-full mb-3 p-3 ${isVendersOpen ? "" : "hidden"}`}>
            <div className="flex items-center  gap-3">
              <input type="checkbox" />
              <label className="text-[#828282]">Ithyllan</label>
            </div>
            <div className="flex items-center  gap-3">
              <input type="checkbox" />
              <label className="text-[#828282]">Verified Sellers</label>
            </div>
            <div className="flex items-center gap-3">
              <input type="checkbox" />
              <label className="text-[#828282]">Fulfilled by Ithyllan</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubCategorySmartFilterSidebar;
