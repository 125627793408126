import React, { useEffect, useState } from "react";
import "./CartPage.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCartProduct,
  getCartProducts,
  updateCartProduct,
} from "../../store/frontendSlice";

export default function CartProducts() {
  const [quantity, setQuantity] = useState(1);

  const [isProductChecked, setIsProductChecked] = useState(false);
  const [isStoreChecked, setIsStoreChecked] = useState(false);

  const dispatch = useDispatch();

  const { cartItems, loading } = useSelector((state) => state.frontend);

  const ProductToggleCheckbox = () => {
    setIsProductChecked(!isProductChecked);
  };
  const StoreToggleCheckbox = () => {
    setIsStoreChecked(!isStoreChecked);
  };

  const handlePlus = (item) => {
    const currentQuantity = parseInt(item.quantity, 10);

    const updatedQuantity = currentQuantity + 1;

    dispatch(
      updateCartProduct({
        product_id: item.product_id,
        quantity: updatedQuantity,
      })
    ).then(() => {
      dispatch(getCartProducts());
    });
    setQuantity(quantity + 1);
  };

  const handleMinus = (item) => {
    const currentQuantity = parseInt(item.quantity, 10);
    if (currentQuantity > 1) {
      const updatedQuantity = currentQuantity - 1;
      dispatch(
        updateCartProduct({
          product_id: item.product_id,
          quantity: updatedQuantity,
        })
      ).then(() => {
        dispatch(getCartProducts());
      });
    } else {
    }
  };

  const handleDelete = (item) => {
    dispatch(deleteCartProduct(item?.product_id)).then(() => {
      dispatch(getCartProducts());
    });
  };

  useEffect(() => {
    dispatch(getCartProducts());
  }, []);

  return (
    <>
      <main className="px-4 md:px-0 md:ps-7 lg:ps-[57px] w-full flex flex-col md:flex-row gap-4 justify-between">
        <div className="w-full md:w-[49%]">
          <div className="font-bold text-2xl tracking-wide">Cart</div>
          {/* 
          <div className="mt-2 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div>
                <div className="relative">
                  Hidden default checkbox
                  <input
                    type="checkbox"
                    className="opacity-0 absolute"
                    // checked={isStoreChecked}
                    // onChange={StoreToggleCheckbox}
                  />

                  Custom checkbox
                  <div className="border border-black p-[0.11rem] rounded">
                    <div
                      className={`w-3 h-3 border flex items-center justify-center border-none rounded ${
                        isStoreChecked ? "bg-black" : "bg-white border-gray-300"
                      }`}
                      //   onClick={StoreToggleCheckbox}
                    >
                      {isStoreChecked && (
                        <span className="text-white text-xs font-bold"></span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-sm lg:text-lg">Choose All Products</div>
            </div>

            <div className="flex items-center gap-2">
              <span className="tracking-wide text-sm">Delete Selected</span>
              <button className="">
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 4H1"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4 4H4.11C4.51245 3.98972 4.90242 3.85822 5.22892 3.62271C5.55543 3.3872 5.80325 3.05864 5.94 2.68L5.974 2.577L6.071 2.286C6.154 2.037 6.196 1.913 6.251 1.807C6.35921 1.59939 6.51451 1.41999 6.70448 1.28316C6.89444 1.14633 7.1138 1.05586 7.345 1.019C7.462 1 7.593 1 7.855 1H11.145C11.407 1 11.538 1 11.655 1.019C11.8862 1.05586 12.1056 1.14633 12.2955 1.28316C12.4855 1.41999 12.6408 1.59939 12.749 1.807C12.804 1.913 12.846 2.037 12.929 2.286L13.026 2.577C13.1527 2.99827 13.4148 3.36601 13.7717 3.62326C14.1285 3.88051 14.5603 4.01293 15 4"
                    stroke="black"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.874 13.4C15.697 16.054 15.608 17.381 14.743 18.19C13.878 19 12.548 19 9.88699 19H9.11299C6.45299 19 5.12299 19 4.25699 18.19C3.39199 17.381 3.30399 16.054 3.12699 13.4L2.66699 6.5M16.333 6.5L16.133 9.5"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </div>
          </div> */}

          {cartItems?.length > 0 &&
            cartItems?.map((item) => {
              return (
                <div
                  className="mt-6 rounded-xl px-4 py-1"
                  style={{ boxShadow: "0px 30px 40px 0px #0508281A" }}
                >
                  <div className="flex justify-between py-3">
                    <div className="flex gap-2 items-center">
                      <div>
                        {/* <div className="relative">
                          Hidden default checkbox
                          <input
                            type="checkbox"
                            className="opacity-0 absolute"
                            checked={isStoreChecked}
                            onChange={StoreToggleCheckbox}
                          />

                          Custom checkbox
                          <div className="border border-black p-[0.11rem] rounded">
                            <div
                              className={`w-3 h-3 border flex items-center justify-center border-none rounded ${
                                isStoreChecked
                                  ? "bg-black"
                                  : "bg-white border-gray-300"
                              }`}
                              onClick={StoreToggleCheckbox}
                            >
                              {isStoreChecked && (
                                <span className="text-white text-xs font-bold"></span>
                              )}
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="font-bold tracking-wide text-sm lg:text-base">
                        {item.Vendor}
                      </div>
                    </div>
                    <div className="space-x-2">
                      <span className="font-bold text-sm lg:text-base tracking-wide">
                        Free shipping
                      </span>
                      <span className="">
                        <progress
                          className="progress w-8 lg:w-44  xl:w-56 bg-[#C19A6B] rounded-none"
                          value="100"
                          max="100"
                        ></progress>
                      </span>
                      <span className="text-[#C19A6B] text-sm">Done</span>
                    </div>
                  </div>

                  <hr />

                  {item?.products?.map((item) => {
                    return (
                      <div className="flex items-center gap-3 py-4">
                        <div>
                          {/* <div className="relative">
                            Hidden default checkbox
                            <input
                              type="checkbox"
                              className="opacity-0 absolute"
                              checked={isProductChecked}
                              onChange={ProductToggleCheckbox}
                            />

                            Custom checkbox
                            <div className="border border-black p-[0.20rem] rounded">
                              <div
                                className={`w-3 h-3 border flex items-center justify-center border-none rounded ${
                                  isProductChecked
                                    ? "bg-black"
                                    : "bg-white border-gray-300"
                                }`}
                                onClick={ProductToggleCheckbox}
                              >
                                {isProductChecked && (
                                  <span className="text-white text-xs font-bold"></span>
                                )}
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div>
                          <img
                            className="max-w-[80px] max-h-[80px] lg:max-w-[150px] lg:max-h-[150px]"
                            src={item.product_picture}
                            alt="img here"
                          />
                        </div>
                        <div className="flex flex-col justify-between">
                          <div>
                            <div className="leading-none text-sm lg:text-base">
                              {item.name}
                            </div>
                            <div className="flex gap-2 pt-1">
                              <div className="border px-3 py-1 rounded text-xs text-center flex justify-center items-center">
                                Sliver
                              </div>
                              <div className="border px-3 py-1 rounded text-xs">
                                16”
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-between gap-4 mt-6">
                            <div className="font-black text-xl lg:text-[22px]">
                              ${item.price}
                            </div>

                            <div className="flex items-center gap-3">
                              <div className="flex items-center gap-7 border px-3 rounded">
                                {item.quantity > 1 ? (
                                  <span
                                    className={`cursor-pointer w-3 text-center ${
                                      loading
                                        ? "pointer-events-none opacity-50"
                                        : ""
                                    }`}
                                    onClick={(e) =>
                                      !loading && handleMinus(item)
                                    }
                                  >
                                    -
                                  </span>
                                ) : (
                                  <span
                                    className={`cursor-pointer w-3 text-center`}
                                  ></span>
                                )}

                                <span className="text-xs">{item.quantity}</span>
                                <span
                                  className={`cursor-pointer w-3 text-center ${
                                    loading
                                      ? "pointer-events-none opacity-50"
                                      : ""
                                  }`}
                                  onClick={(e) => !loading && handlePlus(item)}
                                >
                                  +
                                </span>
                              </div>
                              <button onClick={(e) => handleDelete(item)}>
                                <svg
                                  width="19"
                                  height="20"
                                  viewBox="0 0 19 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 4H1"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M4 4H4.11C4.51245 3.98972 4.90242 3.85822 5.22892 3.62271C5.55543 3.3872 5.80325 3.05864 5.94 2.68L5.974 2.577L6.071 2.286C6.154 2.037 6.196 1.913 6.251 1.807C6.35921 1.59939 6.51451 1.41999 6.70448 1.28316C6.89444 1.14633 7.1138 1.05586 7.345 1.019C7.462 1 7.593 1 7.855 1H11.145C11.407 1 11.538 1 11.655 1.019C11.8862 1.05586 12.1056 1.14633 12.2955 1.28316C12.4855 1.41999 12.6408 1.59939 12.749 1.807C12.804 1.913 12.846 2.037 12.929 2.286L13.026 2.577C13.1527 2.99827 13.4148 3.36601 13.7717 3.62326C14.1285 3.88051 14.5603 4.01293 15 4"
                                    stroke="black"
                                    stroke-width="1.5"
                                  />
                                  <path
                                    d="M15.874 13.4C15.697 16.054 15.608 17.381 14.743 18.19C13.878 19 12.548 19 9.88699 19H9.11299C6.45299 19 5.12299 19 4.25699 18.19C3.39199 17.381 3.30399 16.054 3.12699 13.4L2.66699 6.5M16.333 6.5L16.133 9.5"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>

        <div className="w-full md:w-[49%] md:h-[744px] lg:mt-[-35px] flex justify-center brown-circle cart-bg">
          <div className="lg:ms-[4.5rem] xl:ms-28 w-full ">
            <div className="text-white text-2xl lg:text-[30px]  lg:mt-20  font-bold tracking-wide md:px-2 xl:px-0">
              Order Summery
            </div>

            <div className="mt-4 bg-white w-full  lg:max-w-[336px] xl:max-w-[492px] rounded-md px-5 py-2  order-summary-upper-card ">
              {cartItems.length > 0 &&
                cartItems.map((item) => {
                  return item?.products?.map((item) => {
                    return (
                      <div className="flex justify-between mt-4">
                        <div className="text-[#8C8C8C]">
                          x{item.quantity} {item.name}
                        </div>
                        <div className="">${item.quantity * item.price}</div>
                      </div>
                    );
                  });
                })}

              {/* <div className="flex justify-between">
                <div className="text-[#8C8C8C]">x1 Product Name</div>
                <div className="">$30.00</div>
              </div> */}

              <div className="my-3 font-bold tracking-wide">Taxes</div>
              <div className="flex justify-between">
                <div className="text-[#8C8C8C]">Tax</div>
                <div className="">$5.00</div>
              </div>

              <div className="flex justify-between my-3">
                <div className=" font-bold tracking-wide">Total Amount</div>
                <div className="font-bold">
                  $
                  {cartItems.length > 0
                    ? cartItems.reduce((acc, item) => acc + item.Total, 0) + 5
                    : 5}
                </div>
              </div>
            </div>

            <div className="w-full  lg:max-w-[336px] xl:max-w-[492px] bg-white py-3 rounded-md order-summary-lower-part">
              <div className="border border-dashed border-[#C19A6B] opacity-40 mt-[-0.7rem]"></div>
{/* 
              <div className="flex justify-center px-6 relative">
                <label className="relative input  w-full  border border-[#C19A6B] flex items-center gap-2 rounded my-4 ">
                  <svg
                    className=" "
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8122 4.39993L14.005 5.57811L7.24619 12.2545L6.05347 11.0763L12.8122 4.39993ZM2.07772 0.472656H17.9807C19.084 0.472656 19.9686 1.34647 19.9686 2.43629V6.36357C19.4414 6.36357 18.9357 6.57045 18.563 6.9387C18.1902 7.30695 17.9807 7.80641 17.9807 8.3272C17.9807 8.84799 18.1902 9.34745 18.563 9.7157C18.9357 10.084 19.4414 10.2908 19.9686 10.2908V14.2181C19.9686 15.3079 19.084 16.1817 17.9807 16.1817H2.07772C1.5505 16.1817 1.04488 15.9749 0.672079 15.6066C0.29928 15.2384 0.0898438 14.7389 0.0898438 14.2181V10.2908C1.19311 10.2908 2.07772 9.41702 2.07772 8.3272C2.07772 7.80641 1.86828 7.30695 1.49548 6.9387C1.12268 6.57045 0.617061 6.36357 0.0898438 6.36357V2.43629C0.0898437 1.9155 0.29928 1.41605 0.672079 1.04779C1.04488 0.679539 1.5505 0.472656 2.07772 0.472656ZM2.07772 2.43629V4.93011C2.68171 5.27414 3.18333 5.76923 3.5321 6.36558C3.88087 6.96193 4.0645 7.63849 4.0645 8.3272C4.0645 9.01591 3.88087 9.69248 3.5321 10.2888C3.18333 10.8852 2.68171 11.3803 2.07772 11.7243V14.2181H17.9807V11.7243C17.3767 11.3803 16.8751 10.8852 16.5263 10.2888C16.1776 9.69248 15.9939 9.01591 15.9939 8.3272C15.9939 7.63849 16.1776 6.96193 16.5263 6.36558C16.8751 5.76923 17.3767 5.27414 17.9807 4.93011V2.43629H2.07772ZM7.54437 4.39993C8.36934 4.39993 9.03528 5.05775 9.03528 5.87266C9.03528 6.68757 8.36934 7.34538 7.54437 7.34538C6.71941 7.34538 6.05347 6.68757 6.05347 5.87266C6.05347 5.05775 6.71941 4.39993 7.54437 4.39993ZM12.5141 9.30902C13.339 9.30902 14.005 9.96684 14.005 10.7817C14.005 11.5967 13.339 12.2545 12.5141 12.2545C11.6891 12.2545 11.0232 11.5967 11.0232 10.7817C11.0232 9.96684 11.6891 9.30902 12.5141 9.30902Z"
                      fill="#686868"
                    />
                  </svg>
                  <input
                    type="text"
                    className="text-center text-[15px]  "
                    placeholder="Add coupon code here"
                  />
                </label>
              </div> */}

              <div className="px-6 mb-2 flex justify-center">
                <Link
                  to="/checkout"
                  className="bg-[#C19A6B]  py-2 px-[13px] w-full text-center  rounded text-white font-[500] text-xl tracking-wide"
                >
                  Checkout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
