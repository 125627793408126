import React from "react";
import AuctionSearch from "../../components/Search-Components/AuctionSearch";

export default function SearchPageAuction() {
  return (
    <div>
      <AuctionSearch />
    </div>
  );
}
