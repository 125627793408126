import AdStoreResidencepage from "../../pages/Ad-Market-Place/Ad-Store-Residence-page/AdStoreResidencepage";
import AdStoreSingleProductPage from "../../pages/Ad-Market-Place/Ad-Store-Single-Product-Page/AdStoreSingleProductPage";
import AdStoreVehiclesPage from "../../pages/Ad-Market-Place/Ad-Store-Vehicles-Page/AdStoreVehiclesPage";
import AdMarketPlaceHomePage from "../../pages/Ad-Market-Place/AdMarketPlaceHomePage";
import AuctionHomePage from "../../pages/Ad-Market-Place/Auction/Auction-Home/AuctionHomePage";
import AuctionLastChanceProductsPage from "../../pages/Ad-Market-Place/Auction/Auction-Last-Chance-Products-Page/AuctionLastChanceProductsPage";
import AuctionMainCategoryProductsPage from "../../pages/Ad-Market-Place/Auction/Auction-Main-Category-Products-Page/AuctionMainCategoryProductsPage";
import AuctionPopularProductsPage from "../../pages/Ad-Market-Place/Auction/Auction-Popular-Products-Page/AuctionPopularProductsPage";
import AuctionSingleProductPage from "../../pages/Ad-Market-Place/Auction/Auction-Single-Product-Page/AuctionSingleProductPage";
import BidHistory from "../../pages/Ad-Market-Place/Auction/BidHistory/BidHistory";
import SubCategoryPage from "../../pages/Ad-Market-Place/Sub-Category-Page/SubCategoryPage";
import AdMarketPlaceRoot from "../../pages/AdMarketPlaceRoot";
import SearchPageAdMarketPlace from "../../pages/Search-Pages/SearchPageAdMarketPlace";
import SearchPageAuction from "../../pages/Search-Pages/SearchPageAuction";

const adMarketPlaceRoutes = [
  {
    path: "/ad-market-place",
    element: <AdMarketPlaceRoot />,
    children: [
      {
        index: true,
        element: <AdMarketPlaceHomePage />,
      },
      {
        path: "/ad-market-place/:slug",
        element: <AdStoreVehiclesPage />,
      },
      {
        path: "/ad-market-place/:slug/:subcat",
        element: <SubCategoryPage />,
      },
      {
        path: "/ad-market-place/ad-store-residence",
        element: <AdStoreResidencepage />,
      },
      {
        path: "/ad-market-place/single-product/:slug",
        element: <AdStoreSingleProductPage />,
      },
      {
        path: "/ad-market-place/search",
        element: <SearchPageAdMarketPlace />,
      },

      // Auction Routing
      {
        path: "/ad-market-place/auction",
        children: [
          {
            index: true,
            element: <AuctionHomePage />,
          },
          {
            path: "/ad-market-place/auction/last-chance-products",
            element: <AuctionLastChanceProductsPage />,
          },
          {
            path: "/ad-market-place/auction/popular-products",
            element: <AuctionPopularProductsPage />,
          },
          {
            path: "/ad-market-place/auction/:category",
            element: <AuctionMainCategoryProductsPage />,
          },

          {
            path: "/ad-market-place/auction/single-product/:product",
            element: <AuctionSingleProductPage />,
          },
          {
            path: "/ad-market-place/auction/bid-history/:product",
            element: <BidHistory />,
          },
          {
            path: "/ad-market-place/auction/search",
            element: <SearchPageAuction />,
          },
        ],
      },
    ],
  },
];

export default adMarketPlaceRoutes;
