import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../functions/axios";
import {
  ADD_USER_AFFILIATE_PRODUCT,
  ADD_USER_BUNDLE,
  AD_MARET_PLACE,
  APPROVED_OFFER,
  AUCTION_ADD_PRODUCT,
  AUCTION_ALL_PRODUCTS,
  DELETE_STORE_FOLLOWED,
  GET_USER_AFFILIATE_PRODUCT,
  STATUS_CHANGE_USER_AFFILIATE_PRODUCT,
  STORE_FOLLOWED,
  TICKETS_LIST,
  TICKETS_LIST_SEARCH,
  USER_OFFER,
  USER_PRODUCT_IMAGE,
} from "./URLconst";

const initialState = {
  data: [],
  token: localStorage.getItem("token") || null,
  role: localStorage.getItem("cookies") || null,
  step: localStorage.getItem("step") || null,
  loading: false,
  error: null,
  message: null,
  signout: false,
  selectedCategory: "Cameras, Audio & Video",
  products: [],
  countries: [],
  cities: [],
  citiesByState: [],
  states: [],
  brands: [],
  imageId: null,
  category: [],
  adStoreCat: [],
  auctionCat: [],
  profileSetting: [],
  userDashStats: null,
  notifications:[],
  
};

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (user, { rejectWithValue }) => {
    try {
      const res = await axios.post("/user/login", user);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const signUp = createAsyncThunk(
  "user/signUp",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post("/user/register", data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateProduct = createAsyncThunk(
  "/updateProduct",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/ads/${arg.id}`, arg.formData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
export const updateAuctionProduct = createAsyncThunk(
  "/updateAuctionProduct",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/auction/${arg.id}`, arg.formData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);
export const userImageUpdate = createAsyncThunk(
  "vendorImageUpdate",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const res = await axios.post(USER_PRODUCT_IMAGE + arg.id, arg.formData);
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const verifyOtp = createAsyncThunk(
  "user/verifyOtp",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post("/user/otp", data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const userForgotPassword = createAsyncThunk(
  "user/userForgotPassword",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post("/user/forgot-password", data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// user password reset
export const userPasswordReset = createAsyncThunk(
  "userPasswordReset",
  async (password_detail, { rejectWithValue }) => {
    try {
      const res = await axios.post("/user/reset-password", password_detail);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// getWishlist
export const getWishlist = createAsyncThunk(
  "getWishlist",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/user/wishlists`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
//approved offer
export const getApprovedOffer = createAsyncThunk(
  "getApprovedOffer",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(APPROVED_OFFER);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getUserOffer = createAsyncThunk(
  "getUserOffer",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(USER_OFFER);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const handleStatusAffiliateProduct = createAsyncThunk(
  "handleStatusAffiliateProduct",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(STATUS_CHANGE_USER_AFFILIATE_PRODUCT+"/"+a.uid+"?status="+a.status);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// getWishlist
export const getCategorylist = createAsyncThunk(
  "getCategorylist",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/user/category-list`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFaqlist = createAsyncThunk(
  "getFaqlist",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/user/faqs`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getReviewHistory = createAsyncThunk(
  "getReviewHistory",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/user/review-history`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postReview = createAsyncThunk(
  "postReview",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`/user/ratingreviews`, data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postReturn = createAsyncThunk(
  "postReturn",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`/user/return-order`, data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMyReview = createAsyncThunk(
  "getMyReview",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/user/my-review`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// deleteWishlist
export const deleteWishlist = createAsyncThunk(
  "deleteWishlist",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.post(`/user/wishlists-delete`, a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// getWishlist
export const getFollowerList = createAsyncThunk(
  "getFollowerList",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/user/followers`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// user password reset
export const userAccountSetting = createAsyncThunk(
  "userAccountSetting",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get("/user/get", a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// user password reset
export const userProfileUpdate = createAsyncThunk(
  "userProfileUpdate",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.post("/user/update", a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// user get addresses
export const userAddressList = createAsyncThunk(
  "userAddressList",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get("/user/address", a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// user getbyid addresses
export const userAddressById = createAsyncThunk(
  "userAddressById",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/user/address/${id}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// user getbyid addresses
export const userAddressUpdate = createAsyncThunk(
  "userAddressUpdate",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`/user/address/${id}`, data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// user add addresses
export const userAddressAdd = createAsyncThunk(
  "userAddressAdd",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.post("/user/address", a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// user delete addresses
export const userAddressDelete = createAsyncThunk(
  "userAddressDelete",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.delete(`/user/address/${id}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addProduct = createAsyncThunk(
  "/addProcut",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(AD_MARET_PLACE, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const userAuctionAddProduct = createAsyncThunk(
  "/userAuctionAddProduct",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(AUCTION_ADD_PRODUCT, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const myAffliateStore = createAsyncThunk(
  "/myAffliateStore",
  async (page, { rejectWithValue }) => {
    try {
      var response = null;
      if (page) {
        response = await axios.get(`/user/affilite-store?page=${page}`);
      } else {
        response = await axios.get(`/user/affilite-store`);
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const affiliateStoreSearch = createAsyncThunk(
  "affiliateStoreSearch",
  async (query, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `/user/affilite-store-search?search=${query}`
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addBundles = createAsyncThunk(
  "/addBundles",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post(ADD_USER_BUNDLE, arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getStoreFollowed = createAsyncThunk(
  "getStoreFollowed",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(STORE_FOLLOWED);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const listUserProduct = createAsyncThunk(
  "/listVendorProduct",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(
          ADD_USER_AFFILIATE_PRODUCT + "?page=" + arg,
          arg
        );
        return response.data;
      } else {
        const response = await axios.get(
          ADD_USER_AFFILIATE_PRODUCT + "?page=" + 1,
          arg
        );
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getAdMArketPlace = createAsyncThunk(
  "/getAdMArketPlace3",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(AD_MARET_PLACE + "?page=" + arg, arg);
        return response.data;
      } else {
        const response = await axios.get(AD_MARET_PLACE + "?page=" + 1, arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getAuctionProducts = createAsyncThunk(
  "/getAuctionProducts",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(
          AUCTION_ALL_PRODUCTS + "?page=" + arg,
          arg
        );
        return response.data;
      } else {
        const response = await axios.get(
          AUCTION_ALL_PRODUCTS + "?page=" + 1,
          arg
        );
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// ad MArket Place Product Search search
export const adMArketPlaceProductsSearch = createAsyncThunk(
  "adMArketPlaceProductsSearch",
  async (query, { rejectWithValue }) => {
    try {
      const queryParam = `search=${encodeURIComponent(query)}`;
      const res = await axios.get(`user/ads-search?${queryParam}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// auction
export const auctionProductsSearch = createAsyncThunk(
  "auctionProductsSearch",
  async (query, { rejectWithValue }) => {
    try {
      const queryParam = `search=${encodeURIComponent(query)}`;
      const res = await axios.get(`user/auction-search?${queryParam}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// ad MArket Place Product delete
export const adMArketPlaceProductDelete = createAsyncThunk(
  "/adMArketPlaceProductDelete",
  async (id) => {
    const res = await axios.delete(`user/ads/${id}`);
    return res.data;
  }
);
export const deleteFolloewedStores = createAsyncThunk(
  "/deleteFolloewedStores",
  async (data) => {
    const res = await axios.post(DELETE_STORE_FOLLOWED, data);
    return res.data;
  }
);
// auction Product delete
export const auctionProductDelete = createAsyncThunk(
  "/auctionProductDelete",
  async (id) => {
    const res = await axios.delete(`user/auction/${id}`);
    return res.data;
  }
);
export const getAdMArketPlaceById = createAsyncThunk(
  "/getAdMArketPlaceById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/ads/${id}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getAuctionProductById = createAsyncThunk(
  "/getAuctionProductById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/auction/${id}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createBundle = createAsyncThunk(
  "/createBundle",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.put("/user/bundle");

      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getCitiesByCountries = createAsyncThunk(
  "/getCitiesByCountries",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(`/countries/cities/${arg}`, arg);
        return response.data;
      } else {
        const response = await axios.get(`/countries/cities/${arg}`, arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getCountrie = createAsyncThunk(
  "/getCountrie",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get("countries", arg);
        return response.data;
      } else {
        const response = await axios.get("countries", arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const adStoreCategories = createAsyncThunk(
  "/adStoreCategories",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("user/ad-store");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const auctionCategories = createAsyncThunk(
  "/auctionCategories",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("user/categories-auction");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getCategory = createAsyncThunk(
  "/getCategory",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get("/public/categories?page=all", arg);
        return response.data;
      } else {
        const response = await axios.get("/public/categories?page=all", arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getBrands = createAsyncThunk(
  "/getBrands",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get("user-brands", arg);
        return response.data;
      } else {
        const response = await axios.get("user-brands", arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getStateByCountry = createAsyncThunk(
  "/getStateByCountry",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(`countries/${arg}`, arg);
        return response.data;
      } else {
        const response = await axios.get(`countries/${arg}`, arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getCitiesByState = createAsyncThunk(
  "/getCitiesByState",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/countries/state/${id}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getBundle = createAsyncThunk(
  "/getBundle",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(
          GET_USER_AFFILIATE_PRODUCT + "?page=" + arg,
          arg
        );
        return response.data;
      } else {
        const response = await axios.get(
          GET_USER_AFFILIATE_PRODUCT + "?page=" + 1,
          arg
        );
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const availableAffliateProduct = createAsyncThunk(
  "/availableAffliateProduct",
  async (page, { rejectWithValue }) => {
    try {
      var response = null;
      if (page) {
        response = await axios.get(`/user/affilite-product?page=${page}`);
      } else {
        response = await axios.get(`/user/affilite-product`);
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const searchAffliateProduct = createAsyncThunk(
  "/searchAffliateProduct",
  async (search, { rejectWithValue }) => {
    try {
      var response = await axios.get(
        `/user/affilite-product-search?search=${search}`
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// ticket middlewares start here

// tickets list
export const ticketsList = createAsyncThunk(
  "/ticketsList",
  async (arg = {}, { rejectWithValue }) => {
    try {
      const { arg1, arg2 } = arg;
      const url = arg1 ? `${TICKETS_LIST}?${arg1}=${arg2}` : TICKETS_LIST;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// tickets search
export const ticketsListSearch = createAsyncThunk(
  "ticketsListSearch",
  async (query, { rejectWithValue }) => {
    try {
      const queryParam = `search=${encodeURIComponent(query)}`;
      const res = await axios.get(`${TICKETS_LIST_SEARCH}?${queryParam}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// tickets add
export const addTicket = createAsyncThunk(
  "addTicket",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`/user/tickets`, data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// tickets Category
export const getTicketCategories = createAsyncThunk(
  "getTicketCategories",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/user/category-list`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// tickets get by id
export const getTicketById = createAsyncThunk(
  "getTicketById",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/user/tickets/${id}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// user orders
export const userOrdersList = createAsyncThunk(
  "userOrdersList",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`user/order`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// order list search
export const orderListSearch = createAsyncThunk(
  "orderListSearch",
  async (query, { rejectWithValue }) => {
    try {
      const queryParam = `search=${encodeURIComponent(query)}`;
      const res = await axios.get(`user/order-search?${queryParam}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const OrderByID = createAsyncThunk(
  "OrderByID",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`user/order/${id}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// user bundles
export const userBundleList = createAsyncThunk(
  "/userBundleList",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("user/bundle");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// affiliete available product
export const affiliateAvailableProducts = createAsyncThunk(
  "/affiliateAvailableProducts",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("user/affilite-product?page=all");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

// user bundle add
export const addUserBundle = createAsyncThunk(
  "/addUserBundle",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post("user/bundle", arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const userBundleByID = createAsyncThunk(
  "/userBundleByID",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(`user/bundle/${arg}`);
        return response.data;
      } else {
        const response = await axios.get(`user/bundle/${arg}`);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateUserBundle = createAsyncThunk(
  "/updateUserBundle",
  async ({ id, object }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`user/bundle/${id}`, object);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const userBundlesSearch = createAsyncThunk(
  "userBundlesSearch",
  async (query, { rejectWithValue }) => {
    try {
      const queryParam = `search=${encodeURIComponent(query)}`;
      const res = await axios.get(`user/bundle-search?${queryParam}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const BundleAffiliateProductSearch = createAsyncThunk(
  "BundleAffiliateProductSearch",
  async (query, { rejectWithValue }) => {
    try {
      const queryParam = `search=${encodeURIComponent(query)}`;
      const res = await axios.get(`user/affilite-product-search?${queryParam}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserDashStats = createAsyncThunk(
  "/getUserDashStats",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("/user/dashboard");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const notifications=createAsyncThunk(
  "/notifications",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("/notifications");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const getAvailableAffiliateProduct = createAsyncThunk(
  "/getAvailableAffiliateProduct",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("/user/affilite-product");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const addInreels = createAsyncThunk(
  "/addInreels",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/reels',  arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getInreels = createAsyncThunk(
  "/getInreels",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user/reels');
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    addToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
    },
    logout: (state, action) => {
      state.token = null;
      state.step = null;
      localStorage.removeItem("token");
      localStorage.removeItem("step");
    },
    clearMessage: (state) => {
      state.message = null;
      state.error = null;
    },
    resetSignout: (state) => {
      state.signout = false;
    },
    clearImageId: (state, action) => {
      state.imageId = null;
    },
  },

  extraReducers: (builder) => {
    // for login
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          state.error = payload.message;
        } else {
          state.token = payload.temp_token ? payload.temp_token : payload.token;
          state.message = payload.message;
          state.step = payload.step;
          localStorage.setItem(
            "token",
            payload.temp_token ? payload.temp_token : payload.token
          );
          localStorage.setItem("step", payload.step);
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        const error = action.payload?.error;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        state.error = action.payload.message || "Login failed";
      });

    // for signUp
    builder
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
      })
      .addCase(signUp.rejected, (state, action) => {
        const error = action.payload?.error;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        if (Object.values(action.payload?.errors)[0][0]) {
          state.error = Object.values(action.payload?.errors)[0][0];
        } else {
          state.error = action.payload.error;
        }
      });

    // for verifyOtp
    builder
      .addCase(verifyOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyOtp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        const error = action.payload?.error;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        if (Object.values(action.payload?.errors)[0][0]) {
          state.error = Object.values(action.payload?.errors)[0][0];
        } else {
          state.error = action.payload.error;
        }
      });

    // for userForgotPassword
    builder
      .addCase(userForgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(userForgotPassword.fulfilled, (state, action) => {
        state.loading = false;

        state.data = action.payload;
        // state.message = action.payload.message
        // console.log(action);
      })
      .addCase(userForgotPassword.rejected, (state, action) => {
        const error = action.payload?.error;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;

        // state.error = action?.payload?.errors;
        // console.log(action);
      });

    builder.addCase(adMArketPlaceProductsSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(adMArketPlaceProductsSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // console.log(action);
    });
    builder.addCase(adMArketPlaceProductsSearch.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      state.data = action.payload;
      // console.log(action);
    });
    builder.addCase(auctionProductsSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(auctionProductsSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // console.log(action);
    });
    builder.addCase(auctionProductsSearch.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      state.data = action.payload;
      // console.log(action);
    });

    builder.addCase(adMArketPlaceProductDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(adMArketPlaceProductDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(adMArketPlaceProductDelete.rejected, (state, action) => {
      state.error = action.payload;
      // console.log(action);

      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });
    builder.addCase(deleteFolloewedStores.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteFolloewedStores.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(deleteFolloewedStores.rejected, (state, action) => {
      state.error = action.payload;
      // console.log(action);

      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });

    // auction product delete
    builder.addCase(auctionProductDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(auctionProductDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(auctionProductDelete.rejected, (state, action) => {
      state.error = action.payload;
      // console.log(action);

      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });

    // user password reset otp
    builder
      .addCase(userPasswordReset.pending, (state) => {
        state.loading = true;
      })
      .addCase(userPasswordReset.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(userPasswordReset.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });

    // getwishlist
    builder
      .addCase(getWishlist.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWishlist.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(getWishlist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
    builder
      .addCase(getApprovedOffer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApprovedOffer.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.data = payload;
      })
      .addCase(getApprovedOffer.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      });
    builder
      .addCase(getUserOffer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserOffer.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.data = payload;
      })
      .addCase(getUserOffer.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      });

    // getwishlist
    builder
      .addCase(getCategorylist.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCategorylist.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(getCategorylist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });

    // getwishlist
    builder
      .addCase(getFaqlist.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFaqlist.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.products = payload;
      })
      .addCase(getFaqlist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });

    // getwishlist
    builder
      .addCase(getReviewHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReviewHistory.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(getReviewHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });

      builder
      .addCase(postReview.pending, (state) => {
        state.loading = true;
      })
      .addCase(postReview.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
      })
      .addCase(postReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });

    builder
      .addCase(postReturn.pending, (state) => {
        state.loading = true;
      })
      .addCase(postReturn.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
      })
      .addCase(postReturn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });

    // getMyRievew
    builder
      .addCase(getMyReview.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyReview.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(getMyReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });

    // deletewishlist
    builder
      .addCase(deleteWishlist.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteWishlist.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(deleteWishlist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
    builder
      .addCase(handleStatusAffiliateProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleStatusAffiliateProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(handleStatusAffiliateProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });

    // getFollowerList
    builder
      .addCase(getFollowerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFollowerList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(getFollowerList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });

    // user userAccountSetting
    builder
      .addCase(userAccountSetting.pending, (state) => {
        state.loading = true;
      })
      .addCase(userAccountSetting.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.profileSetting = payload;
      })
      .addCase(userAccountSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });

    // user userProfileUpdate
    builder
      .addCase(userProfileUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(userProfileUpdate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
      })
      .addCase(userProfileUpdate.rejected, (state, action) => {
        state.loading = false;
        // console.log(Object.values(action.payload.errors)[0][0]);
        if (Object.values(action.payload.errors)[0][0]) {
          state.error = Object.values(action.payload.errors)[0][0];
        } else {
          state.error = action.payload.error;
        }
      });

    // user userAddressList
    builder
      .addCase(userAddressList.pending, (state) => {
        state.loading = true;
      })
      .addCase(userAddressList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.profileSetting = payload.data;
      })
      .addCase(userAddressList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });

    // user userAddressById
    builder
      .addCase(userAddressById.pending, (state) => {
        state.loading = true;
      })
      .addCase(userAddressById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.data;
      })
      .addCase(userAddressById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // user userAddressUpdate
    builder
      .addCase(userAddressUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(userAddressUpdate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.data;
        state.message = payload.message;
      })
      .addCase(userAddressUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });

    builder.addCase(adStoreCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(adStoreCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.adStoreCat = action.payload.categories;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(adStoreCategories.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });

    // auction categories
    builder.addCase(auctionCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(auctionCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.auctionCat = action.payload.categories;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(auctionCategories.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });

    // user userAddressAdd
    builder
      .addCase(userAddressAdd.pending, (state) => {
        state.loading = true;
      })
      .addCase(userAddressAdd.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
      })
      .addCase(userAddressAdd.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });

    // user userAddressDelete
    builder
      .addCase(userAddressDelete.pending, (state) => {
        state.loading = true;
      })
      .addCase(userAddressDelete.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
      })
      .addCase(userAddressDelete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
    builder
      .addCase(getStoreFollowed.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStoreFollowed.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload);
        state.data = payload?.followers;
      })
      .addCase(getStoreFollowed.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // myAffliateStore
    builder
      .addCase(myAffliateStore.pending, (state) => {
        state.loading = true;
      })
      .addCase(myAffliateStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload, "---payload");
        if (!payload.success) {
          state.data = payload;
          state.message = payload.message;
        }
      })
      .addCase(myAffliateStore.rejected, (state, { payload }) => {
        const error = payload?.error;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // console.log(payload);
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
      });

    // affiliateStoreSearch
    builder
      .addCase(affiliateStoreSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(affiliateStoreSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload, "---payload");
        if (!payload.success) {
          state.data = payload;
          state.message = payload.message;
        }
      })
      .addCase(affiliateStoreSearch.rejected, (state, { payload }) => {
        const error = payload?.error;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // console.log(payload);
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
      });

    // availableAffliateProduct
    builder
      .addCase(availableAffliateProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(availableAffliateProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload, "---payload");
        if (!payload.success) {
          state.data = payload;
          state.message = payload.message;
        }
      })
      .addCase(availableAffliateProduct.rejected, (state, { payload }) => {
        const error = payload?.error;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // console.log(payload);
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
      });

    // searchAffliateProduct
    builder
      .addCase(searchAffliateProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchAffliateProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload, "---payload");
        if (!payload.success) {
          state.data = payload;
          state.message = payload.message;
        }
      })
      .addCase(searchAffliateProduct.rejected, (state, { payload }) => {
        const error = payload?.error;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // console.log(payload);
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
      });

    builder.addCase(listUserProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listUserProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(listUserProduct.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });
    builder.addCase(getAdMArketPlace.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdMArketPlace.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(getAdMArketPlace.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });
    builder.addCase(getAuctionProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAuctionProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(getAuctionProducts.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });
    builder.addCase(getAdMArketPlaceById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdMArketPlaceById.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      // // console.log(action)
    });
    builder.addCase(getAdMArketPlaceById.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      // // console.log(action)
    });
    builder.addCase(getAuctionProductById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAuctionProductById.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      // // console.log(action)
    });
    builder.addCase(getAuctionProductById.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      // // console.log(action)
    });

    builder
      .addCase(userImageUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(userImageUpdate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.imageId = payload;
        // console.log(payload);
      })
      .addCase(userImageUpdate.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message
        // console.log(action.payload);
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message
        // console.log(action.payload);
      })
      .addCase(updateProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        // console.log(payload);
      })
      .addCase(updateProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAuctionProduct.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message
        // console.log(action.payload);
      })
      .addCase(updateAuctionProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        // console.log(payload);
      })
      .addCase(updateAuctionProduct.pending, (state) => {
        state.loading = true;
      });

    builder.addCase(getBundle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBundle.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(getBundle.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });

    builder.addCase(createBundle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBundle.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(createBundle.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action?.payload?.error;
      // console.log(action);
    });

    // ad store add product
    builder.addCase(addProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      // // console.log(action)
    });
    builder.addCase(addProduct.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      // console.log(action);
    });

    // auction add product
    builder.addCase(userAuctionAddProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userAuctionAddProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      // // console.log(action)
    });
    builder.addCase(userAuctionAddProduct.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      // console.log(action);
    });

    builder.addCase(getCountrie.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(getCountrie.fulfilled, (state, action) => {
      // state.loading = false;
      state.countries = action.payload.countries;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(getCountrie.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      // state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });
    builder.addCase(getCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.category = action.payload.categories;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(getCategory.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });
    builder.addCase(getBrands.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBrands.fulfilled, (state, action) => {
      state.loading = false;
      state.brands = action.payload.Brands;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(getBrands.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });

    builder.addCase(getStateByCountry.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(getStateByCountry.fulfilled, (state, action) => {
      // state.loading = false;
      state.states = action.payload.states;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(getStateByCountry.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      // state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });

    builder.addCase(getCitiesByState.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(getCitiesByState.fulfilled, (state, action) => {
      // state.loading = false;
      state.citiesByState = action.payload.cities;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(getCitiesByState.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      // state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });

    builder.addCase(getCitiesByCountries.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(getCitiesByCountries.fulfilled, (state, action) => {
      // state.loading = false;
      state.cities = action.payload.cities;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(getCitiesByCountries.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      // state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });

    // ticket middlewares add-cases start here

    // ticket list
    builder.addCase(ticketsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ticketsList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // console.log(action);
    });
    builder.addCase(ticketsList.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });

    // ticket search
    builder.addCase(ticketsListSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ticketsListSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(ticketsListSearch.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      state.data = action.payload;
    });

    // ticket addition
    builder.addCase(addTicket.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addTicket.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    });
    builder.addCase(addTicket.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
    });

    // getTicketCategories
    builder.addCase(getTicketCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTicketCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getTicketCategories.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action?.payload?.errors;
    });

    // getTicketById
    builder.addCase(getTicketById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTicketById.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getTicketById.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action?.payload?.errors;
    });

    // get user order
    builder.addCase(userOrdersList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userOrdersList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(userOrdersList.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action?.payload?.errors;
    });

    // order list search
    builder.addCase(orderListSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(orderListSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(orderListSearch.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action?.payload?.errors;
      state.data = action.payload;
    });

    // order by id
    builder.addCase(OrderByID.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(OrderByID.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(OrderByID.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action?.payload?.errors;
    });

    // user bundles
    builder.addCase(userBundleList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userBundleList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(userBundleList.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.errors;
    });

    // affiliate available products
    builder.addCase(affiliateAvailableProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(affiliateAvailableProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
    });
    builder.addCase(affiliateAvailableProducts.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });

    // add user bundles
    builder.addCase(addUserBundle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addUserBundle.fulfilled, (state, action) => {
      state.loading = false;
      // state.data = action.payload;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(addUserBundle.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      // console.log(action);
    });

    // user bundle by id
    builder.addCase(userBundleByID.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userBundleByID.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(userBundleByID.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      // console.log(action);
    });

    // update user bundle
    builder.addCase(updateUserBundle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserBundle.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.message = action.payload.message;
      // console.log(action);
    });
    builder.addCase(updateUserBundle.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = Object.values(action.payload?.errors)[0][0];
      // console.log(action);
    });

    // user bundle search
    builder.addCase(userBundlesSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userBundlesSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // console.log(action);
    });
    builder.addCase(userBundlesSearch.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      state.data = action.payload;
      // console.log(action);
    });

    // user bundle search
    builder.addCase(BundleAffiliateProductSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(BundleAffiliateProductSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // console.log(action);
    });
    builder.addCase(BundleAffiliateProductSearch.rejected, (state, action) => {
      const error = action?.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }

      state.loading = false;
      state.error = action?.payload?.errors;
      state.data = action.payload;
      // console.log(action);
    });
    builder.addCase(getUserDashStats.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDashStats.fulfilled, (state, action) => {
      state.loading = false;
      state.userDashStats = action.payload;
    });
    builder.addCase(getUserDashStats.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(notifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(notifications.fulfilled, (state, action) => {
      state.loading = false;
      state.notifications = action.payload.notifications;
    });
    builder.addCase(notifications.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getAvailableAffiliateProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAvailableAffiliateProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getAvailableAffiliateProduct.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(addInreels.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addInreels.fulfilled, (state, action) => {
      state.loading = false;
      // state.data = action.payload;
      state.message = action.payload.message;
      console.log(action);
    });
    builder.addCase(addInreels.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      if (Object.values(action.payload?.errors)[0][0]){
        state.error = Object.values(action.payload?.errors)[0][0];
      }
      else{
        state.error = action.payload?.error;
      }
      console.log(action);
    });

    builder.addCase(getInreels.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInreels.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getInreels.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  setSelectedCategory,
  setProducts,
  addToken,
  logout,
  clearMessage,
  resetSignout,
  clearImageId,
} = categorySlice.actions;

export default categorySlice.reducer;
