import React, { useEffect, useRef, useState } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { BiChevronLeft } from "react-icons/bi";
import { BiChevronRight } from "react-icons/bi";
import { AiOutlineHeart } from "react-icons/ai";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AdStoreSingleProductDetails({ Adproduct }) {
  const [isHeartFilled, setHeartFilled] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliderimg, setSliderimg] = useState([]);
  const sliderRef = useRef(null);

  const toggleHeart = () => {
    setHeartFilled(!isHeartFilled);
  };

  const handlenext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === sliderimg.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleprev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? sliderimg.length - 1 : prevIndex - 1
    );
  };

  // new
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentIndex(index),
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    // Fetch data from the backend
    const fetchData = async () => {
      try {
        // const response = await fetch("/AdStoreSingleProduct.json");
        // const data = await response.json();
        setSliderimg(Adproduct?.products?.images);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };

    fetchData();

    setSliderimg(Adproduct?.products?.images);
  }, []);
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.frontend);

  const handleChat = (e) => {
    console.log("here");
    // e.preventDefault();
    // console.log(data?.user?.id);
    if (token) {
      const url = "https://messenger.yugoiptv.com/t/" + token + "/" + Adproduct?.products?.user_id;
      window.location.href = url;
    } else {
      navigate("/dashboard/login");
    }
  };
  return (
    <>
      <div className="xl:px-[57px] md:px-[25px] lg:px-[40px] px-4 max-w-screen-2xl mx-auto">
        <div className="text-base breadcrumbs font-bold ">
          <ul>
            <li>
              <a>Home</a>
            </li>
            <li>
              <a>Single Product</a>
            </li>
            <li>
              <a>{Adproduct?.products?.name}</a>
            </li>
          </ul>
        </div>
        <div className="flex flex-col md:flex-row justify-between  items-start">
          {/* left slider */}
          <div className="w-full md:w-[50%]">
            <div className=" relative">
              <div className="slider-container  relative">
                <Slider ref={sliderRef} {...settings}>
                  {sliderimg?.map((item, index) => (
                    <div key={index}>
                      <img
                        src={item}
                        alt=".."
                        className="w-full h-[18.6rem] md:w-[25rem] md:h-[20rem] lg:w-[30.15rem] lg:h-[25.4rem] xl:w-[48rem] xl:h-[37rem] "
                      />
                    </div>
                  ))}
                </Slider>
                {sliderRef.current && (
                  <>
                    <button
                      onClick={handlePrev}
                      className={`absolute top-1/2 -left-7 transform -translate-y-1/2  text-4xl bg-white text-black z-10 bg-opacity-15 hover:bg-opacity-100 hover:shadow-md rounded-full p-1 ${
                        currentIndex === 0 ? "hidden" : ""
                      }`}
                    >
                      <BiChevronLeft className="xl:text-[50px] md:text-[30px] text-[10px]" />
                    </button>
                    <button
                      onClick={handleNext}
                      className={`absolute top-1/2 -right-6 transform -translate-y-1/2 text-4xl  bg-white text-black z-10 bg-opacity-15 hover:bg-opacity-100 hover:shadow-md rounded-full p-1 ${
                        currentIndex === 3 ? "hidden" : ""
                      }`}
                    >
                      <BiChevronRight className="xl:text-[50px]" />
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className="flex gap-[6px] md:gap-3 mt-5">
              {sliderimg?.map((item, index) => (
                <img
                  src={item}
                  alt=""
                  key={index}
                  className={`flex xl:w-[130px] xl:h-[119px] md:w-[91px] md:h-[83px] min-w-[66px] min-h-[60px] top-80 ${
                    index === currentIndex ? "" : "opacity-50"
                  }`}
                  onClick={() => setCurrentIndex(index)}
                />
              ))}
            </div>
          </div>

          {/* right details */}
          <div className="w-full md:w-[45%]">
            <div className="flex justify-between items-center">
              <div>
                <h1 className="text-[27px] xl:text-[40px] font-bold">
                  {Adproduct?.products?.name}
                </h1>
                <p className="text-[33px] xl:text-[48px] font-bold text-[#C19A6B] mt-4">
                  ${Adproduct?.products?.price}
                </p>
              </div>
              {/* <div className="flex flex-col gap-2 text-right ">
                <div
                  className={` bg-gray-700 ml-24  bg-opacity-10 flex items-center justify-center mr-0  rounded-full p-1`}
                >
                  <svg
                    className=" h-[16px] w-[16px]  xl:h-[24px] xl:w-[24px]  cursor-pointer text-[#ffff]"
                    viewBox="0 0 24 24"
                    fill={isHeartFilled ? "#1983FF" : "none"}
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    onClick={toggleHeart}
                  >
                    <path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                  </svg>
                </div>
                <div className="flex gap-1 justify-end items-center">
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 2C5.51562 2 3.5 3.91844 3.5 6.28125C3.5 9 6.5 13.3084 7.60156 14.7966C7.64729 14.8594 7.70722 14.9105 7.77646 14.9457C7.84571 14.981 7.9223 14.9994 8 14.9994C8.0777 14.9994 8.15429 14.981 8.22354 14.9457C8.29278 14.9105 8.35271 14.8594 8.39844 14.7966C9.5 13.3091 12.5 9.00219 12.5 6.28125C12.5 3.91844 10.4844 2 8 2Z"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 8C8.82843 8 9.5 7.32843 9.5 6.5C9.5 5.67157 8.82843 5 8 5C7.17157 5 6.5 5.67157 6.5 6.5C6.5 7.32843 7.17157 8 8 8Z"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p className="text-[11px] xl:text-base">{Adproduct?.products?.state},{Adproduct?.products?.country}</p>
                </div>

                <p className="text-[11px] xl:text-base">{Adproduct?.products?.formatted_created_at}</p>
              </div> */}
            </div>

            <button
              onClick={(e) => handleChat(e)}
              className="bg-[#C19A6B] flex items-center justify-center gap-2 mt-8 w-full py-[11px] xl:py-4 text-white text-base xl:text-[24px] rounded-md"
            >
              <svg
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.9424 19.7969C5.00333 19.5745 4.86978 19.2652 4.74232 19.0423C4.70281 18.9757 4.65975 18.9113 4.61333 18.8493C3.52015 17.1914 2.93748 15.2492 2.93755 13.2634C2.91927 7.56316 7.64599 2.9375 13.4909 2.9375C18.5883 2.9375 22.8438 6.46883 23.8376 11.1564C23.9867 11.8514 24.0621 12.5602 24.0626 13.271C24.0626 18.9793 19.5181 23.6776 13.6732 23.6776C12.7434 23.6776 11.4896 23.444 10.8056 23.2525C10.1216 23.0611 9.43857 22.8072 9.26236 22.7391C9.08197 22.6699 8.8904 22.6342 8.69716 22.634C8.48645 22.6333 8.27776 22.6753 8.08373 22.7574L4.63873 24.0005C4.56344 24.0331 4.48365 24.054 4.40208 24.0625C4.33757 24.0623 4.27373 24.0493 4.21426 24.0243C4.1548 23.9993 4.10088 23.9627 4.05564 23.9167C4.0104 23.8707 3.97473 23.8162 3.95069 23.7563C3.92665 23.6964 3.91473 23.6324 3.9156 23.5679C3.91984 23.5113 3.93005 23.4553 3.94607 23.4008L4.9424 19.7969Z"
                  stroke="white"
                  stroke-width="1.75"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M8.625 15.125C9.52246 15.125 10.25 14.3975 10.25 13.5C10.25 12.6025 9.52246 11.875 8.625 11.875C7.72754 11.875 7 12.6025 7 13.5C7 14.3975 7.72754 15.125 8.625 15.125Z"
                  fill="white"
                />
                <path
                  d="M13.5 15.125C14.3975 15.125 15.125 14.3975 15.125 13.5C15.125 12.6025 14.3975 11.875 13.5 11.875C12.6025 11.875 11.875 12.6025 11.875 13.5C11.875 14.3975 12.6025 15.125 13.5 15.125Z"
                  fill="white"
                />
                <path
                  d="M18.375 15.125C19.2725 15.125 20 14.3975 20 13.5C20 12.6025 19.2725 11.875 18.375 11.875C17.4775 11.875 16.75 12.6025 16.75 13.5C16.75 14.3975 17.4775 15.125 18.375 15.125Z"
                  fill="white"
                />
              </svg>
              <p>Send Message</p>
            </button>

            <div className="mt-8">
              <h1 className="text-[22px]  xl:text-[32px] py-4 font-bold">
                Product Details
              </h1>
              {Adproduct?.products?.attributes?.map((item) => {
                return (
                  <div className="flex justify-between py-1">
                    <p className="text-base xl:text-2xl opacity-75">
                      {item.attribute}
                    </p>
                    <p className="float-end text-base xl:text-2xl font-semibold opacity-80">
                      {item.value}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="lg:w-[402px] xl:w-[576px] w-full shadow-lg mt-7 p-6 rounded-lg">
              <div className="flex gap-2">
                <img
                  src="../assets/images/adstore-single-product/smithdp.png"
                  alt=""
                />
                <div>
                  <p>{Adproduct?.products?.user_name}</p>
                  <p className="text-[#8C8C8C] text-[15px] font-[300] tracking-wider">
                    member since 2017
                  </p>
                  <span className="flex items-center gap-2">
                    <span onClick={() => navigate(`/shop-front/${Adproduct?.products?.user_slug}`)} className="text-sm font-semibold cursor-pointer hover:underline">see profile</span>
                    <img src="/assets/icons/forwardi.svg" alt="" />
                  </span>
                </div>
              </div>
              <button
                onClick={(e) => handleChat(e)}
                className="flex items-center justify-center gap-2 mt-6 w-full rounded-md bg-[#C19A6B] py-[10px] text-white"
              >
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.9424 19.7969C5.00333 19.5745 4.86978 19.2652 4.74232 19.0423C4.70281 18.9757 4.65975 18.9113 4.61333 18.8493C3.52015 17.1914 2.93748 15.2492 2.93755 13.2634C2.91927 7.56316 7.64599 2.9375 13.4909 2.9375C18.5883 2.9375 22.8438 6.46883 23.8376 11.1564C23.9867 11.8514 24.0621 12.5602 24.0626 13.271C24.0626 18.9793 19.5181 23.6776 13.6732 23.6776C12.7434 23.6776 11.4896 23.444 10.8056 23.2525C10.1216 23.0611 9.43857 22.8072 9.26236 22.7391C9.08197 22.6699 8.8904 22.6342 8.69716 22.634C8.48645 22.6333 8.27776 22.6753 8.08373 22.7574L4.63873 24.0005C4.56344 24.0331 4.48365 24.054 4.40208 24.0625C4.33757 24.0623 4.27373 24.0493 4.21426 24.0243C4.1548 23.9993 4.10088 23.9627 4.05564 23.9167C4.0104 23.8707 3.97473 23.8162 3.95069 23.7563C3.92665 23.6964 3.91473 23.6324 3.9156 23.5679C3.91984 23.5113 3.93005 23.4553 3.94607 23.4008L4.9424 19.7969Z"
                    stroke="white"
                    stroke-width="1.75"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M8.625 15.125C9.52246 15.125 10.25 14.3975 10.25 13.5C10.25 12.6025 9.52246 11.875 8.625 11.875C7.72754 11.875 7 12.6025 7 13.5C7 14.3975 7.72754 15.125 8.625 15.125Z"
                    fill="white"
                  />
                  <path
                    d="M13.5 15.125C14.3975 15.125 15.125 14.3975 15.125 13.5C15.125 12.6025 14.3975 11.875 13.5 11.875C12.6025 11.875 11.875 12.6025 11.875 13.5C11.875 14.3975 12.6025 15.125 13.5 15.125Z"
                    fill="white"
                  />
                  <path
                    d="M18.375 15.125C19.2725 15.125 20 14.3975 20 13.5C20 12.6025 19.2725 11.875 18.375 11.875C17.4775 11.875 16.75 12.6025 16.75 13.5C16.75 14.3975 17.4775 15.125 18.375 15.125Z"
                    fill="white"
                  />
                </svg>
                <p className="text-xl">Chat</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
