import React, { useEffect } from "react";
import VendorDetails from "./VendorDetails";
import VendorBundles from "./VendorBundles";
import VendorInReels from "./VendorInReels";
import VendorProducts from "./VendorProducts";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getVendorProfile } from "../../store/frontendSlice";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const VendorProfile = () => {
  const {slug}=useParams()
  const dispatch = useDispatch()
  const { data,error,message } = useSelector(state => state.frontend)

  useEffect(() => {
    dispatch(getVendorProfile(slug))
  }, [])

  useEffect(() => {
    if(message){
      toast.success(message)
      dispatch(getVendorProfile(slug))
    }
    else if (error){
      toast.error(error)
    }
    dispatch(clearMessage())
  },[message,error])

  return (
    <>
      <VendorDetails data={data} />
      <VendorBundles data={data}/>
      <VendorInReels data={data}/>
      <div className="flex justify-center object-cover px-4 sm:px-20 xl:px-[57px] items-center w-full">
        <img className="w-full h-full max-w-screen-2xl" src={data?.storeDecoration?.banner?data.storeDecoration.banner:"/assets/images/beautyProducts.svg"} alt="" />
      </div>
      <VendorProducts data={data} getFunction={getVendorProfile}/>
    </>
  );
};

export default VendorProfile;
