import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaHeart } from "react-icons/fa";

import "./shelf.css";

const generateStarRating = (rating) => {
  const stars = [];
  const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
  const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <img key={i} src={filledStar} alt="filled star" className="w-4 h-4" />
      );
    } else {
      stars.push(
        <img
          key={i}
          src={unfilledStar}
          alt="unfilled star"
          className="w-4 h-4"
        />
      );
    }
  }
  return stars;
};

export default function ShelfCard({ item, key }) {
  const [isHeartFilled, setIsHeartFilled] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const [selectVarient, setSelectVarient] = useState(false);
  const [cartButtonOne, setCartButtonOne] = useState(true);
  const [itemsQuantity, SetItemsQuantity] = useState(1);
  const [itemsQuantityBadge, SetItemsQuantityBadge] = useState(false);
  const [cartButtonSecond, setCartButtonSecond] = useState(false);

  const handleHeartClick = () => {
    setIsHeartFilled(!isHeartFilled);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleCartButtonOne = () => {
    setSelectVarient(true);
    setCartButtonOne(false);
  };

  const handleVarientButtons = () => {
    setSelectVarient(false);
    SetItemsQuantityBadge(true);
    setCartButtonSecond(true);
  };

  const handlePlus = () => {
    SetItemsQuantity(itemsQuantity + 1);
  };
  const handleMinus = () => {
    if (itemsQuantity === 1) {
    } else {
      SetItemsQuantity(itemsQuantity - 1);
    }
  };

  const handleCartButtonSecond = () => {
    setCartButtonSecond(false);
    SetItemsQuantityBadge(false);
    setCartButtonOne(true);
  };

  return (
    <div
      key={key}
      className={`card border-none overflow-hidden bg-black bg-opacity-[50%] relative  cursor-pointer min-w-[202px] md:min-w-[197px] lg:min-w-[218px] h-[355px]  m-3`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* heart and discount start here */}
      <div
        className={`rating gap-1 absolute right-2 top-2 px-1 py-1 bg-[#1983FF33] rounded-full ${
          isHeartFilled
            ? "text-blue-500 duration-500 ease-in-out"
            : "text-white"
        }`}
        onClick={handleHeartClick}
      >
        <FaHeart className="w-[25px] h-[25px] md:w-[19px] md:h-[17px] lg:w-[21px] lg:h-[19px] cursor-pointer" />
      </div>

      <div className={` gap-1 absolute left-2 mt-1`}>
        <p className="text-[black] bg-[#FFFFFF80] bg-opacity-10 py-[2.5px] px-[10px] md:py-[1.97px] md:px-[7.88px] lg:py-[2.16px] lg:px-[8.66px] rounded-[28px] mt-2 text-[13px] md:text-[10px] lg:[11px] font-bold">
          -{(((item.price - item.discountprice)/item.price)*100).toFixed(2)}%
        </p>
      </div>
      {/* heart and discount end here */}

      {/* image start here  */}
      <Link to={`/single-product/${item.slug}`}>
        <img src={item.image} alt="Shoes" className="h-[275px] w-full object-cover" />
      </Link>

      {/* premium badge */}
      <div className="absolute bottom-[88px] right-2 bg-[#FFB800] bg-opacity-[70%] rounded-full text-white py-1 px-[14px] md:py-[3.66px] md:px-[11.7px] lg:py-[3.96px] lg:px-[12.8px] text-lg md:text-sm lg:text-[15.8px] font-bold">
        {item.type}
      </div>

      {/* Image end here */}
      {/* <div
        className={` absolute top-48 right-6  space-x-3 ${
          selectVarient ? " flex slide-from-right" : "hidden slide-to-left"
        }`}
      >
        <div
          onClick={handleVarientButtons}
          className="bg-white py-1 px-2 rounded-full text-xs font-bold text-black"
        >
          100ml
        </div>
        <div
          onClick={handleVarientButtons}
          className="bg-white py-1 px-2 rounded-full text-xs font-bold text-black"
        >
          300ml
        </div>
        <div
          onClick={handleVarientButtons}
          className="bg-white py-1 px-2 rounded-full text-xs font-bold text-black"
        >
          600ml
        </div>
      </div> */}
      <div
        className={`flex opacity-0 absolute top-48 left-16 space-x-3 bg-white rounded-full px-5 ${
          itemsQuantityBadge ? " flex slide-from-right" : "hidden"
        }`}
      >
        <div onClick={handleMinus} className="">
          -
        </div>
        <div className="">{itemsQuantity}</div>
        <div onClick={handlePlus} className="">
          +
        </div>
      </div>

      <div className="px-3 py-1">
        <Link>
          <h2 className="text-white font-semibold text-lg md:text-sm lg:text-base overflow-hidden text-nowrap">
            {item.name}
          </h2>
        </Link>
        <div className="h-4 flex items-center">
          <div
            className={`  ${
              isHovered
                ? "hidden"
                : "flex gap-2 items-center justify-center slide-from-top w-fit "
            }`}
          >
            <div className="rating rating-xs space-x-1 h-[9px] md:h-[10px] lg:h-3">
              {generateStarRating(item.total_ratings)}
            </div>
            <div className="text-[#CBCBCB] text-[7px] md:text-[8px] lg:text-[10px]">
              {item.total_ratings}
            </div>
          </div>
          <div
            className={`${
              isHovered
                ? "flex items-center gap-2 slide-from-bottom "
                : "hidden "
            }`}
          >
            <img src="./assets/images/top-sell/review-avatar.png" alt="" />
            <p className="text-[#CBCBCB] text-[10px] overflow-hidden text-nowrap">
              {item.random_review}
            </p>
          </div>
        </div>

        <div className="card-actions justify-between items-center ">
          <div className="flex items-center gap-1">
            <h5 className="font-semibold text-white  text-lg md:text-sm lg:text-base">
              ${item.discountprice}
            </h5>
            <span className=" text-[#CBCBCB] font-medium text-[13px] md:text-[11px] lg:text-xs">
            ${item.price}
            </span>
          </div>
          <Link
            to={`/single-product/${item.slug}`}
            className={` rounded-md  text-[#1983FF] text-xs cursor-pointer ${
              cartButtonOne ? "block" : "hidden"
            }`}
          >
            <img
              src="/assets/icons/boost-cart-icon.png"
              alt="cart-button"
              className="w-[25px] h-[26px] md:w-5 md:h-5 lg:w-[22px] lg:h-[22px]"
            />
          </Link>
          <div
            onClick={handleCartButtonSecond}
            className={` rounded-md bg-white text-[#1983FF] text-xs cursor-pointer h-[18px] w-[17px] md:h-[22px] md:w-[21px] lg:h-[25px] lg:w-[24px] ${
              cartButtonSecond ? "block" : "hidden"
            }`}
          >
            <img
              src="/assets/images/top-sell/plus-button.png"
              alt="cart-button"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
