import React, { useEffect } from "react";
import { useState } from "react";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import { GoDotFill } from "react-icons/go";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { getCategorylist, getFaqlist } from "../../../store/categorySlice";
// import BreadCrums from "../../Common/BreadCrums";

export default function HelpCenter() {
  const dispatch = useDispatch();
  const { data,products } = useSelector((state) => state.category);

  const [faqs, setFaqs] = useState({id:"",value:false});

  const toggleFaq = (id) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq) => (faq.id === id ? { ...faq, show: !faq.show } : faq))
    );
  };

  useEffect(() => {
    dispatch(getCategorylist());
    dispatch(getFaqlist());
  },[])

  return (
    <>
      <div className="px-4 flex flex-col gap-5">
        <BreadCrums
          breadCrum={[
            {
              name: "User Support",
              path: "/user-dashboard/help-center",
            },
            {
              name: "Help Center",
            },
          ]}
        />
        {/* Self Service Tools */}
        <div className="md:px-12">
          <h2 className="text-xl font-semibold text-[#18120F] text-center py-3">
            Self Service Tools
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8">
            {data?.categories?.length>0?
            data?.categories?.map((a)=>(
              <div className="border hover:bg-[#dab78c] flex flex-col gap-2 items-center justify-center border-[#D9D9D9] p-1 md:px-5 md:py-6 rounded-2xl">
                <img
                  src={a.icon}
                  className=" w-7 h-7 lg:w-10 lg:h-10"
                  alt=""
                />
                <span className="text-[10px] md:text-sm text-center font-[400] text-[#18120F]">
                  {a.label}
                </span>
              </div>
            ))
            :(<></>)}
          </div>
        </div>
        {/* FAQs */}
        <div className="md:px-12">
          <h2 className="text-xl font-semibold text-[#18120F] text-center py-3">
            FAQS
          </h2>
          <div className="flex flex-col gap-2 md:gap-5">
            {products?.faq?.length>0?
            products?.faq?.map((faq) => (
              <div
                onClick={() => setFaqs({id:faq.id,value:!faqs.value})}
                key={faq.id}
                className="w-full border border-[#D9D9D9] cursor-pointer rounded-md md:rounded-xl px-3 py-1 md:py-2 lg:py-4 "
              >
                <div className="flex items-center justify-between cursor-pointer">
                  <div className="flex items-center gap-1">
                    <span className="mb-1">
                      <GoDotFill className="w-2" />
                    </span>

                    <span className="text-[#18120F] font-semibold text-xs lg:text-sm">
                      {faq.title}
                    </span>
                  </div>

                  <span>
                    {faqs.value && faqs.id ==faq.id ? (
                      <GoChevronUp className="w-4 h-4 md:w-6 md:h-6 font-bold" />
                    ) : (
                      <GoChevronDown className="w-4 h-4 md:w-6 md:h-6 font-bold" />
                    )}
                  </span>
                </div>
                {faqs.value && faqs.id ==faq.id && (
                  <div
                    className={`px-3 font-medium text-xs lg:text-sm mt-1 ${
                      faq.show ? "" : ""
                    }`}
                  >
                    {faq.answer}
                  </div>
                )}
              </div>
            )):<></>}
          </div>
        </div>
        {/* Contacts */}
        <div className=" md:px-12 mb-5">
          <h2 className="text-xl font-semibold text-[#18120F] text-center py-3">
            Contact
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8 text-xs md:text-sm lg:text-base">
            <div className="border hover:bg-[#dab78c] flex gap-2 lg:gap-5 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Chat-Bubble.svg"
                className=" w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <span className="text-xs lg:text-sm font-semibold text-[#18120F]">
                  Chat With Us
                </span>
                <span className="text-xs font-[400] text-[#18120F] text-nowrap">
                  10 AM - 6 PM (Mon-Sat)
                </span>
              </div>
            </div>
            <div className="border hover:bg-[#dab78c] flex gap-2 lg:gap-5 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Phone--Streamline.svg"
                className=" w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <span className="text-xs lg:text-sm font-semibold text-[#18120F]">
                  Call Us
                </span>
                <span className="text-xs font-[400] text-[#18120F] text-nowrap">
                  123-456-7890123
                </span>
              </div>
            </div>
            <div className="border hover:bg-[#dab78c] flex  gap-2 lg:gap-5 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Mail-Send.svg"
                className=" w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <span className="text-xs lg:text-sm font-semibold text-[#18120F]">
                  Email Us
                </span>
                <span className="text-xs font-[400] text-[#18120F] text-nowrap">
                  contact@wizalot.com
                </span>
              </div>
            </div>
            <div className="border hover:bg-[#dab78c] flex  gap-2 lg:gap-5 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
              <img
                src="/assets/icons/seller-support/Cyborg-2.svg"
                className=" w-7 h-7 lg:w-10 lg:h-10"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <span className="text-xs lg:text-sm font-semibold text-[#18120F] text-nowrap">
                  Tell Us Your Problem
                </span>
                <span className="text-xs font-[400] text-[#18120F] text-nowrap">
                  Assistance 24/7
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
