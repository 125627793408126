import UserDashboardBuyingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";

import UserDashboardTrackOrder from "../../../../components/User-Dashboard-Components/User-Dashboard-Buying-Components/User-Dashboard-Orders/UserDashboardTrackOrder";

export default function UserDashboardTrackOrderPage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <UserDashboardTrackOrder />
      </UserDashboardBuyingLayout>
    </>
  );
}
