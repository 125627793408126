import { Link, useParams } from "react-router-dom";
import "./My-Shelf-SubCategory.css";
import MyShelfSubCategorySlider from "./MyShelfSubCategorySlider";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myShelfMainCategory } from "../../../store/frontendSlice";

function MyShelfSubCategory() {
  const { category } = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.frontend);


  useEffect(() => {
    // Fetch data from the backend
    dispatch(myShelfMainCategory(category));
  }, [category]);
  // // console.log(data[0]?.subCategory);
  const allSubCategories = data.reduce((acc, item) => {
    return [...acc, ...(item.subCategory || [])];
  }, []);
  
  // Limit to 6 subcategories
  const limitedSubCategories = allSubCategories.slice(0, 6);


  return (
    <div
      className="w-full h-full max-w-screen-2xl mx-auto"
      style={{
      
        backgroundImage: `url(${data[0]?.shelf_image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className=" flex flex-col justify-between h-full gap-28 relative">
        {/* 1st div  */}
        <div className=" md:pl-2 md:pr-2 lg:pr-8 lg:pl-8 xl:pl-64 xl:pr-56 pt-12">
          <div className="flex flex-col md:flex-row  w-full justify-between items-center gap-3">
            {/* image div  */}
            <div className=" image-shadow-bottom w-[258px] md:w-[50%] lg:w-[350px] xl:w-[258px]  rounded-xl flex justify-center items-center p-3">
              <img
                src="../assets/images/shelf/add.png"
                alt=""
                className="w-full h-[346px] "
              />
            </div>

            {/* sub Cat..  */}

            <div className="w-full xl:w-[62%] grid grid-cols-2 md:grid-cols-3 gap-2  justify-items-center">
            {limitedSubCategories.length > 0 && limitedSubCategories.map((item) => (
      <Link
        to={`/my-shelf/${category}/${item.slug}`}
        key={item.id}
        className="image-shadow-bottom text-white p-3 rounded-xl w-[170px] md:w-[150px] lg:w-[170px] md:h-[150px] h-[170px] lg:h-[170px] flex justify-center items-center"
      >
        <div className="flex flex-col items-center hover:text-black w-[150px] h-[145px]">
          <span>
            <img
              src={item.image || 'default-image.png'} // Fallback for missing images
              alt={item.name}
              className="max-w-[106px]"
            />
          </span>
          <span className="font-medium text-lg text-center">
            {item.name}
          </span>
        </div>
      </Link>
    ))}
            </div>
          </div>
        </div>

        {/* 2nd div  */}
        <div className=" h-40 mb-4">
          <MyShelfSubCategorySlider data={data?.subCategory?.length>0&&data} />
        </div>
      </div>
    </div>
  );
}

export default MyShelfSubCategory;
