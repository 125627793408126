import React, { useEffect, useState } from "react";
import BoostedOfferCard from "./BoostedOfferCard";
import { useDispatch, useSelector } from "react-redux";
import { mainOffers } from "../../store/frontendSlice";

export default function BoostedOffers() {
  const dispatch = useDispatch()
  const { mainOffersData } = useSelector(
    (state) => state.frontend
  );

  useEffect(() => {
    dispatch(mainOffers())
  }, [])

  return (
    <>
      <div className={`pt-12 px-2 lg:px-[57px]`}>
        <div className="max-w-screen-2xl container mx-auto">
          {/* Top selling card */}
          <div className={`grid grid-cols-2 md:grid-cols-3 gap-4`}>
            {mainOffersData?.offers?.slice(0,6).map((item) => (
              <BoostedOfferCard key={item._id} item={item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
