import React, { useEffect } from "react";
import StoreSquareMallCard from "../../../components/Store-Square-Components/Store-Square-Mall-Page-Components/StoreSquareMallCard";
import { useDispatch, useSelector } from "react-redux";
import { StoreSquare } from "../../../store/frontendSlice";

export default function StoreSquareMallPage() {
  const { boostedStores } = useSelector(state => state.frontend);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(StoreSquare("malls"));
  },[dispatch])
  return (
    <>
      <div className="px-4 lg:px-10 xl:px-[57px] mt-7">
        {/* boosted store */}
        <div className="flex justify-between mt-8">
          <h2 className="font-bold text-lg md:text-2xl  ">Malls</h2>
        </div>

        <div className="w-full xl:max-w-screen-2xl container mx-auto mt-6 mb-6 flex justify-center">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10">
            {boostedStores?.length > 0 && boostedStores.map((item) => {
              return (
                <StoreSquareMallCard data={ item} />
              )
            })}
          </div>
        </div>
      </div>
    </>
  );
}
