import React, { useEffect, useState } from "react";
import UserDashboardStoredFollowedCard from "./UserDashboardStoredFollowedCard";
import BreadCrums from "../../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";

import { clearMessage, deleteFolloewedStores, getStoreFollowed } from "../../../../store/categorySlice";
import { toast } from "react-toastify";

import { getFollowerList } from "../../../../store/categorySlice";


export default function UserDashboardStoredFollowed() {
  const [isSelecting, setIsSelecting] = useState(false);

  const dispatch = useDispatch()
  const { data ,message,error,loading} = useSelector(state => state.category)
  const [selectedIds, setSelectedIds] = useState([]);


  useEffect(() => {
    dispatch(getStoreFollowed());

  }, []);
  useEffect(() => {
    if(message){
      toast.success(message)
      dispatch(getStoreFollowed());
      setSelectedIds([])
    }
    if(error){
      toast.error(error)
    }
    dispatch(clearMessage());
  }, [loading, message, error]);
  const toggleSelectMode = () => {
    setIsSelecting(!isSelecting);
    setSelectedIds([ ])
  };
  const handleDelete = () => {
  dispatch(deleteFolloewedStores({id:selectedIds}))
  }
//he
  return (
    <>
      {/* // No stores followed  */}

      {data.length === 0 && (

        <div className=" w-full flex flex-col  px-4 gap-2">
          <div className="flex flex-col w-full">
            <BreadCrums
              breadCrum={[
                {
                  name: "Store Followed",
                  path: "",
                },
              ]}
            />
            <div className="w-full pt-8 flex justify-center">
              <img
                src="/assets/icons/user-dash-board/no-store-followed.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {/* followed stores  */}
      {data?.length > 0 && (
        <div className=" w-full flex flex-col px-4 gap-2">
          <div className="flex justify-between items-center w-full">
            <BreadCrums
              breadCrum={[
                {
                  name: "Store Followed",
                  path: "",
                },
              ]}
            />{" "}
            <button
              onClick={toggleSelectMode}
              className={`font-normal text-sm pr-1 underline hover:text-[#C19A6B] ${
                isSelecting ? "hidden" : "block"
              }`}
            >
              Select
            </button>
            <div className={`gap-3 pr-1 ${isSelecting ? "flex" : "hidden"}`}>
              <button
                onClick={toggleSelectMode}
                className="font-normal text-sm underline hover:text-[#C19A6B]"
              >
                Cancel
              </button>
              <button className="flex gap-1 items-center font-normal text-sm underline hover:text-[#C19A6B]" onClick={(e)=>handleDelete()}>
                Delete Selected{" "}
                <span>
                  <img
                    src="/assets/icons/user-dash-board/wishlist-delete.svg"
                    alt=""
                  />
                </span>
              </button>
            </div>
          </div>

          <div className="mt-2">
            <div
              className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-12 `}
            >
              {data?.length > 0 && data.map((item) => (
                <UserDashboardStoredFollowedCard
                  key={item.id}
                  item={item}
                  isSelecting={isSelecting}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
