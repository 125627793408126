import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, resetSignout, getCountrie, getStateByCountry, getCitiesByState, signUp } from "../store/categorySlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

export default function SignUpPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [isCountryDropdownVisible, setCountryDropdownVisible] = useState(false);
  const [isStateDropdownVisible, setStateDropdownVisible] = useState(false);
  const [isCityDropdownVisible, setCityDropdownVisible] = useState(false);
  const [isGenderDropdownVisible, setGenderDropdownVisible] = useState(false);

  const { countries, states, citiesByState, loading, message, error } = useSelector(
    (state) => state.category
  );

  const Gender = [    
    {id: 1, name: 'male'},
    {id: 2, name: 'female'},
    {id: 3, name: 'other'}
  ]

  const [birthDate, setBirthDate] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    birthday: "",
    number: "",
    gender: "",
    role: "0",
    country_id: "",
    city_id: "",
    state_id: "",
  });

  const handleLogin = (e) => {
    e.preventDefault();

    const values = Object.values(data);
    const hasEmptyValue = values.some(value => value === "");

    if (hasEmptyValue) {
      toast.error("All fields must be filled out.");
    } else {
      dispatch(signUp(data))
    }
  };

  useEffect(()=>{
    dispatch(getCountrie());
  },[dispatch])

  useEffect(() => {
    if (!loading) {
      if (message) {
        toast.success(message);
        navigate("/dashboard/login");
      }
      if (error) {
        toast.error(error);
      }
      dispatch(clearMessage());
    }
    dispatch(resetSignout());
  }, [loading, message, error, dispatch, navigate]);

  const selectCountry = (country) => {
    setSelectedCountry(country.name);
    setData({
      ...data,
      country_id: country.id,state_id: "",city_id: ""
    });
    dispatch(getStateByCountry(country.id))
    setSelectedState("");
    setSelectedCity("");
  };

  const selectState = (state) => {
    setSelectedState(state.name);
    setData({
      ...data,
      state_id: state.id,city_id: ""
    });
    dispatch(getCitiesByState(state.id))
    setSelectedCity("");
  };

  const selectCity = (city) => {
    setSelectedCity(city.name);
    setData({
      ...data,
      city_id: city.id,
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleGenderDropdown = () => {
    setGenderDropdownVisible((prev) => !prev);
  };

  const toggleCountryDropdown = () => {
    setCountryDropdownVisible((prev) => !prev);
    setCityDropdownVisible(false);
  };

  const toggleCityDropdown = () => {
    setCityDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
  };

  const toggleStateDropdown = () => {
    setStateDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
  };

  const handleDateChange = (event, setStateFunction) => {
    const date = event.target.value;
    // const formattedDate = moment(date, "HH:mm").format("DD/MM/YY");
    setStateFunction(date);
    setData({...data, birthday: date})
  };

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="py-10 flex justify-center items-center px-4 md:px-0 bg-[#FFFAF4]">
      <div
        className="w-full md:w-fit p-4 md:p-14 bg-white flex flex-col items-center rounded-3xl"
        style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
      >
        <img className="mx-auto" src="/assets/images/login-logo.svg" alt="" />
        <div className="font-bold tracking-wide text-xl md:text-3xl mt-5 mb-2 text-center">
          Create an Account
        </div>
        <div className="text-xs md:text-sm text-center font-bold tracking-wide">
          Create new Wizalot account!
        </div>
        <div className="w-full md:w-[30rem] mt-6">

        <label className="form-control ">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter Your Name
              </span>
            </div>
            <input
              type="text"
              placeholder="john"
              className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
              name="name"
              value={data.name}
              onChange={updateData}
            />
          </label>

          <label className="form-control ">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter Your Email Address
              </span>
            </div>
            <input
              type="email"
              placeholder="mike.tyson@mail.com"
              className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
              name="email"
              value={data.email}
              onChange={updateData}
            />
          </label>

          <label className="form-control ">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Number
              </span>
            </div>
            <input
              type="number"
              placeholder="03319868432"
              className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
              name="number"
              value={data.number}
              onChange={updateData}
            />
          </label>

          <label className="form-control ">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Country
              </span>
            </div>
            <div className="relative w-full">
            <input
                className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                type="text"
                value={selectedCountry}
                readOnly
                onClick={toggleCountryDropdown}
                placeholder="Select Your Country"
            />
            <svg
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                onClick={toggleCountryDropdown}
                width="11"
                height="6"
                viewBox="0 0 11 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742"
                fill="#6B6B6B"
                />
            </svg>

            {isCountryDropdownVisible && (
                <div className="absolute z-10 bg-white border border-[#EBF0ED] h-fit overflow-hidden  rounded-lg mt-2 w-full">
                {countries?.map((country) => (
                    <div
                    key={country.id}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => selectCountry(country)}
                    >
                    {country.name}
                    </div>
                ))}
                </div>
            )}
            </div>
          </label>

          <label className="form-control">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                State
              </span>
            </div>
            <div className="relative w-full">
            <input
                className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                type="text"
                value={selectedState}
                readOnly
                onClick={toggleStateDropdown}
                placeholder="Select Your Country"
            />
            <svg
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                onClick={toggleStateDropdown}
                width="11"
                height="6"
                viewBox="0 0 11 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742"
                fill="#6B6B6B"
                />
            </svg>

            {isStateDropdownVisible && (
                <div className="absolute z-10 bg-white border border-[#EBF0ED] h-fit overflow-hidden  rounded-lg mt-2 w-full">
                {states?.map((state) => (
                    <div
                    key={state.id}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => selectState(state)}
                    >
                    {state.name}
                    </div>
                ))}
                </div>
            )}
            </div>
          </label>

          <label className="form-control ">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                City
              </span>
            </div>
            <div className="relative w-full">
            <input
                className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                type="text"
                value={selectedCity}
                readOnly
                onClick={toggleCityDropdown}
                placeholder="Select Your Country"
            />
            <svg
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                onClick={toggleCityDropdown}
                width="11"
                height="6"
                viewBox="0 0 11 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742"
                fill="#6B6B6B"
                />
            </svg>

            {isCityDropdownVisible && (
                <div className="absolute z-10 bg-white border border-[#EBF0ED] h-fit overflow-hidden  rounded-lg mt-2 w-full">
                {citiesByState?.map((city) => (
                    <div
                    key={city.id}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => selectCity(city)}
                    >
                    {city.name}
                    </div>
                ))}
                </div>
            )}
            </div>
          </label>

          <label className="form-control ">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Gender
              </span>
            </div>
            <div className="relative w-full">
            <input
                className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                type="text"
                value={data.gender}
                readOnly
                onClick={toggleGenderDropdown}
                placeholder="Select Your Country"
            />
            <svg
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                onClick={toggleGenderDropdown}
                width="11"
                height="6"
                viewBox="0 0 11 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742"
                fill="#6B6B6B"
                />
            </svg>

            {isGenderDropdownVisible && (
                <div className="absolute z-10 bg-white border border-[#EBF0ED] h-fit overflow-hidden  rounded-lg mt-2 w-full">
                {Gender?.map((G) => (
                    <div
                    key={G.id}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => setData({...data, gender:G.name})}
                    >
                    {G.name}
                    </div>
                ))}
                </div>
            )}
            </div>
          </label>

          <label className="form-control ">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Date of birth
              </span>
            </div>
            <div className="relative w-full">
                <input
                    className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                    name="reg_start_date"
                    value={birthDate}
                    onChange={(event) =>
                    handleDateChange(event, setBirthDate)
                    }
                    type="date"
                    id=""
                />
            </div>
          </label>


          <label className="form-control mt-3">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter Your Password
              </span>
            </div>
            <span className="flex items-center relative">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="********"
                className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
                name="password"
                value={data.password}
                onChange={updateData}
            />
              {passwordVisible ? (
                <IoEyeOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <IoEyeOffOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              )}
            </span>
          </label>

            <button
              disabled={loading}
              method="post"
            //   onSubmit={handleLogin}
              onClick={handleLogin}
              className="bg-[#C19A6B] w-full rounded-lg py-3 mt-[1.5rem] text-sm md:text-base text-white font-bold tracking-wider"
            >
              {loading?"Loading":"Sign Up"}
            </button>
        </div>
        <p className="mt-3">
          Already have an account? <span onClick={()=>navigate("/dashboard/login")} className="text-[#C19A6B] hover:font-bold cursor-pointer">Sign In</span>
        </p>
      </div>
    </div>
  );
}
