import { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { postReturn } from "../../../../store/categorySlice";
import { useDispatch } from "react-redux";

export default function UserDashboardReturnModal({loading,reason, setReason,selectedImages1,selectedImages,setSelectedImages1,setSelectedImages,data,reviewIndex}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const dispatch = useDispatch();

  const returnReason = [
    "Defective",
    "Change of Mind",
    "Missing Items",
    "Size Issue",
    "Damaged",
    "Wrong Item",
  ];

  const handleInputClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleReasonSelect = (reason) => {
    setSelectedReason(reason);
    setIsDropdownOpen(false);
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const newImages = [...selectedImages];
    if(files.length>4){
      for (let i = 0; i < 4 ; i++) {
        newImages.push(URL.createObjectURL(files[i]));
        selectedImages1.push(files[i])
      }
    } else {
      for (let i = 0; i < files.length ; i++) {
        newImages.push(URL.createObjectURL(files[i]));
        selectedImages1.push(files[i])
      }
    }
    setSelectedImages(newImages);
  };

  const handleImageDelete = (index) => {
    const updatedFiles = selectedImages.filter((_, i) => i !== index);
    const updatedFiles1 = selectedImages1.filter((_, i) => i !== index);
    setSelectedImages(updatedFiles);
    setSelectedImages1(updatedFiles1);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData()
    formData.append("product_id",data[reviewIndex].product_id);
    formData.append("comments",reason);
    formData.append("reason",selectedReason);
    formData.append("order_id",data[reviewIndex].order_id);

    if(selectedImages1.length>0){
      for (let i = 0; i < selectedImages1.length ; i++) {
        if (selectedImages1[i] instanceof File) {
          formData.append("images[]", selectedImages1[i]);
        }
      }
    }

    dispatch(postReturn(formData));
  }

  return (
    <div className="py-2 flex flex-col md:flex-row gap-4 mt-2 items-start">
      {/* select return reason  */}
      <div className="w-full md:w-[309px]">
        <div
          onClick={handleInputClick}
          className="w-full relative flex items-center cursor-pointer"
        >
          <input
            type="text"
            name=""
            id=""
            className="py-3 px-[15px] bg-[#F9FAFB] text-[#828282] text-sm w-full rounded-lg outline-none focus:outline-none cursor-pointer"
            placeholder="Select Reason"
            readOnly
            value={selectedReason}
          />
          {isDropdownOpen ? (
            <RiArrowDropUpLine className="w-8 h-8 absolute right-2 text-[#828282]" />
          ) : (
            <RiArrowDropDownLine className="w-8 h-8 absolute right-2 text-[#828282]" />
          )}

          {/*reason dropdown  */}
          {isDropdownOpen && (
            <ul className="absolute top-12 left-0 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-10">
              {returnReason.map((reason, index) => (
                <li
                  key={index}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100 font-semibold"
                  onClick={() => handleReasonSelect(reason)}
                >
                  {reason}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div class="fileUpload flex justify-between w-full md:w-[309px] my-5">
          <input
            type="file"
            class="upload"
            onChange={handleFileChange}
            multiple
          />
          <span className="w-full font-normal text-sm text-[#828282]">
            Uploaded Images
          </span>
          <span className="w-5 h-5 ">
            <img src="/assets/icons/user-dash-board/upload.svg" alt="" />
          </span>
        </div>

        <div className="w-full md:w-64 flex gap-3 md:gap-1">
          {/* Render selected images dynamically */}
          {selectedImages.slice(0, 4).map((image, index) => (
            <div className="relative w-fit mr-2">
              <button onClick={() => handleImageDelete(index)} className="absolute right-0 rounded-full px-[3px] text-sm bg-white cursor-pointer hover:bg-black hover:text-white">X</button>
              <img
                key={index}
                src={image}
                alt={`Uploaded ${index + 1}`}
                className="w-[58px] h-[58px]"
              />
            </div>
          ))}
        </div>

      </div>

      {/* write return statement  */}
      <div className="flex flex-col gap-4 items-end w-full">
        <textarea
          name="review"
          id=""
          cols="30"
          rows="10"
          className="bg-[#F9FAFB] w-full h-[181px] px-4 py-3 rounded-[8px] outline-[#828282] font-normal text-sm resize-none"
          placeholder="Write your review here..."
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        ></textarea>
        <button disabled={loading} onClick={handleSubmit} className="w-[192px] h-12 mt-2 py-1 px-6 rounded border-[1.63px] border-[#C19A6B] text-[#C19A6B] font-medium text-base text-center hover:bg-[#C19A6B] hover:text-white">
          {!loading ? (
            "Submit"
          ) : (
            <span className="loading loading-spinner loading-md"></span>
          )}
        </button>
      </div>
    </div>
  );
}
