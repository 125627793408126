import React, { useEffect, useState } from "react";
import "./AdStoreCategoriesBar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAdMarketPlace, getAuction, getCitiesByState, getCountries, getStateByCountry, nulling } from "../../../../store/frontendSlice";
import { useDispatch, useSelector } from "react-redux";

import { useRef } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Sidebar from "../../../sidebar/Sidebar";
import ScrollCat from "../../../header/ScrollCat";
import ScrollSubCat from "../../../header/ScrollSubCat";
import { stringConcat } from "../../../../functions/formatNumber";

export default function SliderAdStoreCategory() {
    const dispatch = useDispatch();
    const [select, setSelect] = useState(false);
    const [select1, setSelect1] = useState(false);
    const { data, aucData, countries, states, citiesByState } = useSelector((state) => state.frontend);
    const [visibleItemId, setVisibleItemId] = useState(null);
    const [visibleItemId1, setVisibleItemId1] = useState(null);
    const [selectCategory, setSelectCategory] = useState(false);
    const [mainCatSlug, setMainCatSlug] = useState(null);
    const [isVisible, setIsVisible] = useState(true);
    const [market, setMarket] = useState("adstore");
    const [country, setCountry] = useState("America");
    const [locat, setLocat] = useState({
      country:"",
      state:"",
      city:""
    });
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
      dispatch(getAdMarketPlace());
      dispatch(getAuction());
      dispatch(getCountries());
    }, []);

    useEffect(() => {
      const filteredLocation = Object.fromEntries(
        Object.entries(locat).filter(([key, value]) => value !== '')
      );
      localStorage.setItem("locationData", JSON.stringify(filteredLocation));
    }, [locat]);

    useEffect(() => {
      if(countries?.length>0) {
        const item = countries.find(item => item.name === "Sweden");
        dispatch(getStateByCountry(item.id))
        setCountry(item.name)
        setLocat({...locat,country:item.id,state:"",city:""})
      }
    }, [countries]);
  
    const getSubCat = (a) =>{
      dispatch(getCitiesByState(a));
    }

    const wholeCountryClick = () => {
      setLocat({...locat,state:"",city:""})
      setSelect(false)
      setSelect1(false)
      setSelectCategory(false)
    }

    const handleMarket = (_market) => {
        setMarket(_market);
    };
      
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [closing, setClosing] = useState(false);
    const location = useLocation();
  
    const sidebarRef = useRef(null);
  
    const toggleSidebarOpen = () => {
      if (isSidebarOpen) {
        setClosing(true);
        setTimeout(() => {
          setIsSidebarOpen(false);
          setClosing(false);
        }, 1000);
      } else {
        setIsSidebarOpen(true);
      }
    };
  
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setClosing(true);
        setTimeout(() => {
          setIsSidebarOpen(false);
          setClosing(false);
        }, 1000);
      }
    };
  
    useEffect(() => {
      if (isSidebarOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isSidebarOpen]);
  
    useEffect(() => {
      if (isSidebarOpen) {
        setClosing(true);
        setTimeout(() => {
          setIsSidebarOpen(false);
          setClosing(false);
        }, 1000);
      }
    }, [location.pathname]);
  
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 50) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    return (
      <>
        <div
          className={`${isVisible ? 'transform translate-y-0' : 'transform -translate-y-full hidden'} px-5 xl:px-[30px] ${
            selectCategory ? "h-[90px]" : "pb-2"
          } fixed top-[45px] md:top-[60px] lg:top-[70px] xl:top-[60px] left-0 right-0 bg-white z-30`}
        >
          <div className="flex w-full justify-center">
            <div className="flex w-full h-full pt-3">
  
              <div className="flex w-[20%] sm:w-[30%] lg:w-[25%] h-fit gap-2 justify-between items-start sm:items-center flex-col sm:flex-row lg:gap-1 xl:gap-4 sm:px-3">

                <div className="flex w-full px-2 flex-row justify-start gap-2 sm:justify-between">
                  <div onClick={toggleSidebarOpen} className="cursor-pointer pt-1 sm:pt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <rect width="22" height="22" fill="white" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.5 3.78125C0.5 3.42901 0.639927 3.0912 0.888999 2.84212C1.13807 2.59305 1.47588 2.45313 1.82812 2.45312H20.4219C20.7741 2.45312 21.1119 2.59305 21.361 2.84212C21.6101 3.0912 21.75 3.42901 21.75 3.78125C21.75 4.13349 21.6101 4.4713 21.361 4.72038C21.1119 4.96945 20.7741 5.10938 20.4219 5.10938H1.82812C1.47588 5.10938 1.13807 4.96945 0.888999 4.72038C0.639927 4.4713 0.5 4.13349 0.5 3.78125ZM0.5 11.3073C0.5 10.9551 0.639927 10.6172 0.888999 10.3682C1.13807 10.1191 1.47588 9.97917 1.82812 9.97917H20.4219C20.7741 9.97917 21.1119 10.1191 21.361 10.3682C21.6101 10.6172 21.75 10.9551 21.75 11.3073C21.75 11.6595 21.6101 11.9973 21.361 12.2464C21.1119 12.4955 20.7741 12.6354 20.4219 12.6354H1.82812C1.47588 12.6354 1.13807 12.4955 0.888999 12.2464C0.639927 11.9973 0.5 11.6595 0.5 11.3073ZM0.5 18.8333C0.5 18.4811 0.639927 18.1433 0.888999 17.8942C1.13807 17.6451 1.47588 17.5052 1.82812 17.5052H9.79688C10.1491 17.5052 10.4869 17.6451 10.736 17.8942C10.9851 18.1433 11.125 18.4811 11.125 18.8333C11.125 19.1856 10.9851 19.5234 10.736 19.7725C10.4869 20.0215 10.1491 20.1615 9.79688 20.1615H1.82812C1.47588 20.1615 1.13807 20.0215 0.888999 19.7725C0.639927 19.5234 0.5 19.1856 0.5 18.8333Z"
                        fill="black"
                      />
                    </svg>
                  </div>

                  <div className="flex w-fit justify-start h-max">
                    <div onClick={() => setIsOpen(!isOpen)} className="w-fit flex items-center justify-center gap-[2px] bg-white py-1 px-1 text-sm font-bold cursor-pointer">

                      <div className={`${isOpen?"relative":"hidden"}`}>
                        <div className="absolute bg-white mt-5 px-2 min-w-[250px] shadow-lg rounded-md max-h-[300px] hide-scrollbar overflow-y-scroll">

                          {countries?.length>0 && countries.map((a) => (
                            <div onClick={() => {
                              setCountry(a.name);
                              setSelectCategory(false)
                              dispatch(nulling());
                              dispatch(getStateByCountry(a.id))
                              setLocat({...locat,country:a.id,state:"",city:""})
                            }} className="text-gray-600 flex flex-row gap-2 py-3 hover:text-black hover:translate-x-1 border-b-[1px] border-gray-200">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                >
                                <path
                                    d="M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm0 11c-1.38 0-2.5-1.12-2.5-2.5S10.62 8 12 8s2.5 1.12 2.5 2.5S13.38 13 12 13z"
                                />
                              </svg>{" "}
                              <p>{a.name}</p>
                            </div>
                          ))}

                        </div>
                      </div>

                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="23"
                          height="23"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          >
                          <path
                              d="M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm0 11c-1.38 0-2.5-1.12-2.5-2.5S10.62 8 12 8s2.5 1.12 2.5 2.5S13.38 13 12 13z"
                          />
                      </svg>{" "}

                      <span className="hidden md:flex font-extrabold text-lg lg:text-xl xl:text-2xl text-nowrap">{country.length>10?country.slice(0,10)+"...":country}</span>

                    </div>
                  </div>
                </div>

                <div
                  className="px-2 xl:px-4 py-[2px] bg-[#9279B2] whitespace-nowrap text-[10px] sm:text-sm xl:text-base hover:-translate-y-[2px] hover:shadow-md hover:text-[#9279B2] hover:bg-white hover:border-[2px] hover:border-[#9279B2] text-white rounded-full cursor-pointer"
                  onClick={wholeCountryClick}
                >
                  Whole Country
                </div>

              </div>
  
              <div className="flex w-[52%] sm:w-[40%] lg:w-[50%] px-2 h-full items-center flex-col">

                <div className={`h-[35px] flex w-full ml-3 justify-center items-center`}>
                  <ScrollCat select={select} setSelect={setSelect} locat={locat} setLocat={setLocat} check={market} setMainCatSlug={setMainCatSlug} getFunc={getSubCat} selectCategory={selectCategory} setSelectCategory={setSelectCategory} visibleItemId={visibleItemId} setVisibleItemId={setVisibleItemId} categories={states}/>
                </div>
                
                {selectCategory &&
                  <>
                    <div className="flex w-full h-[4px] justify-center items-center">
                      <div
                        style={{
                          borderStyle: 'solid',
                          borderImageSource: 'linear-gradient(90deg, #BEFFF4 0%, #DCC0FF 33.33%, #FFBFDC 66.67%, #FFEBBF 100%)',
                          borderImageSlice: 1,
                        }}
                        className="border-b-4 flex left-[330px] top-14 w-[95%]"
                      >
                      </div>
                    </div>

                    <div className="flex w-full h-[35px] justify-center items-center">
                      {selectCategory && (
                        <ScrollSubCat select1={select1} setSelect1={setSelect1} locat={locat} setLocat={setLocat} check={market} mainCatSlug={mainCatSlug} visibleItemId={visibleItemId1} setVisibleItemId={setVisibleItemId1} categories={citiesByState}/>
                      )}
                    </div>
                  </>
                }

              </div>
  
              <div className="flex w-[28%] sm:w-[30%] lg:w-[25%] h-fit justify-end items-start flex-row">
                <div className="hidden xl:flex w-full">
                  <div className="relative">
                    <div className="flex w-full whitespace-nowrap xl:-ml-2 -mt-5">{states[visibleItemId+1]?stringConcat(states[visibleItemId+1].name):""}</div>
                  </div>
                </div>


                <div className="flex flex-col gap-2 sm:gap-0 sm:flex-row w-fit">
                  <div className="flex w-fit rounded-full border-[2px] border-gray-100 bg-gray-50">
                    <div
                        className={` py-1 px-1 sm:px-2 md:px-5 rounded-full text-xs sm:text-sm whitespace-nowrap cursor-pointer ${location.pathname.toLowerCase().includes("auction") ? "" : "bg-white font-semibold"}`}
                        onClick={() => handleMarket("adstore")}
                    >
                        <Link to="/Ad-market-place">Ad Store</Link>
                    </div>

                    <div
                        className={` py-1 px-1 sm:px-2 md:px-5 rounded-full text-xs sm:text-sm whitespace-nowrap cursor-pointer ${location.pathname.toLowerCase().includes("auction") ? "bg-white font-semibold" : ""}`}
                        onClick={() => handleMarket("auction")}
                    >
                        <Link to="/ad-market-place/auction">Auction</Link>
                    </div>
                  </div>

                  <Link
                    to="/search"
                    className="w-fit h-[2rem] relative sm:px-2 cursor-pointer "
                  >
                    <svg
                      className="absolute mt-2 ml-1"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.0636 12.8493L9.25661 9.16125M1.64258 6.08788C1.64258 6.65292 1.75746 7.21243 1.98067 7.73446C2.20388 8.25649 2.53104 8.73082 2.94347 9.13036C3.3559 9.52991 3.84553 9.84685 4.3844 10.0631C4.92327 10.2793 5.50083 10.3906 6.0841 10.3906C6.66737 10.3906 7.24493 10.2793 7.7838 10.0631C8.32267 9.84685 8.81229 9.52991 9.22473 9.13036C9.63716 8.73082 9.96432 8.25649 10.1875 7.73446C10.4107 7.21243 10.5256 6.65292 10.5256 6.08788C10.5256 5.52284 10.4107 4.96333 10.1875 4.4413C9.96432 3.91927 9.63716 3.44494 9.22473 3.04539C8.81229 2.64585 8.32267 2.32891 7.7838 2.11268C7.24493 1.89645 6.66737 1.78516 6.0841 1.78516C5.50083 1.78516 4.92327 1.89645 4.3844 2.11268C3.84553 2.32891 3.3559 2.64585 2.94347 3.04539C2.53104 3.44494 2.20388 3.91927 1.98067 4.4413C1.75746 4.96333 1.64258 5.52284 1.64258 6.08788Z"
                        stroke="black"
                        stroke-width="1.63158"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <input
                      className="w-full max-w-[100px] flex text-sm py-1 pl-6 bg-white shadow-xl rounded-md placeholder:text-black  focus:outline-none "
                      type="text"
                      readOnly
                      placeholder="Search"
                    />
                  </Link>
                </div>
              </div>
  
            </div>
          </div>
        </div>
  
        {/*  sidebar */}
        {isSidebarOpen && (
          <div
            ref={sidebarRef}
            className={`flex flex-col  fixed top-[7.6rem] left-0  h-[calc(100vh-7.6rem)] bg-white z-50 w-[15rem] p-3 rounded-br-xl overflow-y-auto hide-scrollbar ${
              closing ? "animate-sm-out" : "animate-sm"
            }`}
          >
            <div className="w-full relative py-4 px-4 flex items-center justify-between">
              <MdKeyboardArrowLeft
                onClick={toggleSidebarOpen}
                className="absolute right-0 z-10 w-8 h-8 text-black rounded-lg shadow-lg cursor-pointer"
              />
            </div>
            <Sidebar />
          </div>
        )}
      </>
    );
  }
  