import React, { useEffect, useState } from "react";

import ProductCard from "../Common/ProductCard";

const RelatedProducts = ({products}) => {
  const [recomendedProducts, setRecomendedProducts] = useState([]);

  useEffect(() => {
    // Fetch data from the backend
    const fetchData = async () => {
      try {
        const response = await fetch("/topSell.json");
        const data = await response.json();
        setRecomendedProducts(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className=" px-4 xl:px-[57px] flex flex-col gap-4">
      <h1 className="text-[24px] md:text-[32px] lg:text-[35px] text-black font-bold">
        Products from same Shop
      </h1>

      <div className="grid md:grid-cols-4 xl:grid-cols-5 grid-cols-2 gap-4">
        {products?.store?.products.slice(0, 4).map((item) => (
          <ProductCard key={item._id} item={item} />
        ))}
      </div>
    </div>
  );
};

export default RelatedProducts;
