import HomePage from "../../pages/Main-Shop/home-page/HomePage";
import SearchPage from "../../pages/Search-Pages/SearchPage";

const homeRoutes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/search",
    element: <SearchPage />,
  },
];

export default homeRoutes;
