import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getCitiesByState, getCountrie, getStateByCountry, userAddressById, userAddressList, userAddressUpdate } from "../../../store/categorySlice";
import { toast } from "react-toastify";

export default function UserDashboardAddresesEditAddressForm({editId}) {
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const dispatch = useDispatch();

    const [isCountryDropdownVisible, setCountryDropdownVisible] = useState(false);
    const [isStateDropdownVisible, setStateDropdownVisible] = useState(false);
    const [isCityDropdownVisible, setCityDropdownVisible] = useState(false);
    const { message, error, loading, data, countries, citiesByState, states} = useSelector((state) => state.category);
    const [payload, setPayload] = useState({
        name: "",
        phone_number: "",
        country_id: "",
        state_id: "",
        city_id: "",
        area: "",
        address: "",
        landmark: "",
        address_type: "",
        zipcode: ""
      });

  useEffect(() => {
    if(data && data.area ){
      setPayload({
          name: data?.name || "",
          phone_number: data?.phone_number || "",
          country_id: data?.country_id || "",
          state_id: data?.state_id || "",
          city_id: data?.city_id || "",
          area: data?.area || "",
          address: data?.address || "",
          landmark: data?.landmark || "",
          address_type: data?.address_type || "",
          zipcode: data?.zipcode || "",
      })
      setSelectedCountry(data?.country?.name);
      setSelectedCity(data?.city?.name);
      setSelectedState(data?.state?.name);
      if(data?.country?.id) dispatch(getStateByCountry(data.country.id));
      if(data?.state?.id) dispatch(getCitiesByState(data.state.id));
      }
  }, [data])

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (message==" address updated successfully") {
      toast.success(message);
      dispatch(userAddressList());
      document.getElementById('add_Edit_address_modal_form').close();
    }
    dispatch(clearMessage());
  }, [error,message]);

    const toggleCountryDropdown = () => {
    setCountryDropdownVisible((prev) => !prev);
    setCityDropdownVisible(false);
    };

    const toggleCityDropdown = () => {
    setCityDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
    };

    const toggleStateDropdown = () => {
    setStateDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
    };

    const selectCountry = (country) => {
    setSelectedCountry(country.name);
    setPayload({
        ...payload,
        country_id: country.id,state_id: "",city_id: ""
    });
    setCountryDropdownVisible(false);
    dispatch(getStateByCountry(country.id))

    setSelectedState("");
    setSelectedCity("");
    };

    const selectState = (state) => {
    setSelectedState(state.name);
    setPayload({
        ...payload,
        state_id: state.id,city_id: ""
    });
    setStateDropdownVisible(false);
    dispatch(getCitiesByState(state.id))
    setSelectedCity("");
    };

    const selectCity = (city) => {
    setSelectedCity(city.name);
    setPayload({
        ...payload,
        city_id: city.id,
    });
    setCityDropdownVisible(false);
    };

    const updateData = (e) => {
    setPayload({
        ...payload,
        [e.target.name]: e.target.value,
    });
    };

    useEffect(() => {
        if(editId) dispatch(userAddressById(editId));
        dispatch(getCountrie());
    },[editId])

    const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    for (const [key, value] of Object.entries(payload)) {
      if (value == "") {
      } else {
        formData.append(key, value);
      }

    }
    // formData.append("status",0)
    // console.log(payload)
    formData.append("_method", "PUT");
    dispatch(userAddressUpdate({id:editId,data:formData}));
    };    

      return (
    <div>
      {/* Add new address modal */}
      <dialog

        id="add_Edit_address_modal_form"
        className="modal "
      >
        <div className="modal-box bg-white max-w-3xl  rounded-md">
          <form method="dialog">
            <button
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              onClick={() => {
                document.getElementById('add_Edit_address_modal_form').close()
            }}
            >
              ✕
            </button>
          </form>
          <h3 className="font-bold text-base md:text-lg tracking-wider">
            Edit Addressss
          </h3>

          <div className="">
            <div className=" py-3">
              <form className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    {/* <label htmlFor="field1" className="block text-sm font-medium text-gray-600">Field 1</label> */}
                    <input
                      placeholder="Name"
                      type="text"
                      id="field1"
                      name="name"
                      value={payload.name}
                      onChange={updateData}
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                  <div>
                    {/* <label htmlFor="field2" className="block text-sm font-medium text-gray-600">Field 2</label> */}
                    <input
                      placeholder="Phone Number"
                      type="text"
                      id="field2"
                      name="phone_number"
                      value={payload.phone_number}
                      onChange={updateData}
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">

                    <div className="w-full">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    COUNTRY
                    </label>
                    <div className="relative w-full">
                    <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                        type="text"
                        value={selectedCountry}
                        readOnly
                        onClick={toggleCountryDropdown}
                        placeholder="Select Your Country"
                    />
                    <svg
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                        onClick={toggleCountryDropdown}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742"
                        fill="#6B6B6B"
                        />
                    </svg>

                    {isCountryDropdownVisible && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] h-fit overflow-hidden  rounded-lg mt-2 w-full">
                        {countries?.map((country) => (
                            <div
                            key={country.id}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => selectCountry(country)}
                            >
                            {country.name}
                            </div>
                        ))}
                        </div>
                    )}
                    </div>
                    </div>

                    <div className="w-full">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                        STATE
                    </label>
                    <div className="relative w-full">
                        <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                        type="text"
                        value={selectedState}
                        readOnly
                        onClick={toggleStateDropdown}
                        placeholder="Select Your State"
                        />
                        <svg
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                        onClick={toggleStateDropdown}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742"
                            fill="#6B6B6B"
                        />
                        </svg>

                        {isStateDropdownVisible && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                            {states?.map((state) => (
                            <div
                                key={state.id}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => selectState(state)}
                            >
                                {state.name}
                            </div>
                            ))}
                        </div>
                        )}
                    </div>
                    </div>

                </div>

                <div className="grid grid-cols-2 gap-4">

                <div className="w-full">
                <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                  CITY
                </label>
                <div className="relative w-full">
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                    type="text"
                    value={selectedCity}
                    readOnly
                    onClick={toggleCityDropdown}
                    placeholder="Select Your City"
                  />
                  <svg
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                    onClick={toggleCityDropdown}
                    width="11"
                    height="6"
                    viewBox="0 0 11 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742"
                      fill="#6B6B6B"
                    />
                  </svg>

                  {isCityDropdownVisible && (
                    <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                      {citiesByState?.map((city) => (
                        <div
                          key={city.id}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => selectCity(city)}
                        >
                          {city.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

                  <div>
                    {/* <label htmlFor="field6" className="block text-sm font-medium text-gray-600">Field 6</label> */}
                    <input
                      placeholder="Zip Code"
                      type="text"
                      id="field6"
                      name="zipcode"
                      value={payload.zipcode}
                      onChange={updateData}
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    {/* <label htmlFor="field7" className="block text-sm font-medium text-gray-600">Field 7</label> */}
                    <input
                      placeholder="Area"
                      type="text"
                      id="field7"
                      name="area"
                      value={payload.area}
                      onChange={updateData}
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                  <div>
                    {/* <label htmlFor="field8" className="block text-sm font-medium text-gray-600">Field 8</label> */}
                    <input
                      placeholder="Landmark"
                      type="text"
                      id="field8"
                      name="landmark"
                      value={payload.landmark}
                      onChange={updateData}
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    {/* <label htmlFor="field7" className="block text-sm font-medium text-gray-600">Field 7</label> */}
                    <input
                      placeholder="Address"
                      type="text"
                      id="field7"
                      name="address"
                      value={payload.address}
                      onChange={updateData}
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                  <div className="flex justify-between">
                    <div
                  onClick={() => setPayload({...payload,address_type:"home"})}
                  className={`cursor-pointer py-3 px-14 border ${
                    payload.address_type === "home"
                          ? "border-[#C19A6B] text-black font-[400]"
                          : "text-[#A5A5A5]"
                      } text-sm rounded tracking-wide bg-white`}
                    >
                      Home
                    </div>
                    <div
                  onClick={() => setPayload({...payload,address_type:"office"})}
                  className={`cursor-pointer py-3 px-14 border ${
                        payload.address_type === "office"
                          ? "border-[#C19A6B] text-black font-[400]"
                          : "text-[#A5A5A5]"
                      } text-sm rounded tracking-wide`}
                    >
                      Office
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                <div disabled={loading} onClick={handleSubmit} className=" cursor-pointer h-12 py-2 md:py-3 px-14 bg-[#C19A6B] text-sm md:text-base rounded-md text-white font-[500] tracking-wider">
                {!loading ? (
              "Update"
            ) : (
              <span className="loading loading-spinner loading-md"></span>
            )}
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </dialog>
    </div>
  );
}
