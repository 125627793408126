import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { OrderByID } from "../../../../store/categorySlice";
import Loader from "../../../Common/Loader";
import BreadCrums from "../../../Common/BreadCrums";

export default function UserDashboardOrders() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(OrderByID(id));
  }, [dispatch, id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <div className="xl:px-4 w-full flex flex-col gap-4">
      {/* name */}
      {/* breadcrum */}
      <BreadCrums
        breadCrum={[
          { name: "Orders", path: "" },
          {
            name: `#${data?.data?.order_uid}`,
          },
        ]}
      />

      {/* search */}
      <div className="w-full grid grid-cols-2 gap-2 xl:flex justify-between border border-[#EBF0ED] rounded-2xl py-4 px-4 md:px-6">
        <div className="flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Customer Name:</span>
          <span className="text-[#6B6B6B] ml-1">{data?.data?.customer}</span>
        </div>
        <div className="flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Order Number:</span>
          <span className="text-[#6B6B6B] ml-1">#{data?.data?.order_uid}</span>
        </div>{" "}
        <div className="flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Payment Method:</span>
          <span className="text-[#6B6B6B] ml-1">
            {data?.data?.payment_method}
          </span>
        </div>{" "}
        <div className="flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Placed On:</span>
          <span className="text-[#6B6B6B] ml-1">{data?.data?.created_on}</span>
        </div>
        <div className="flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Total:</span>
          <span className=" ml-1">${data?.data?.total}</span>
        </div>
      </div>

      {/* table */}
      {loading ? (
        <div className="flex justify-center py-4">
          <Loader />
        </div>
      ) : data?.data?.data?.length > 0 ? (
        data?.data?.data?.map((order) => (
          <div className="flex justify-center border-gray-500 overflow-auto">
            <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
              <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
                {/* Table Header */}
                <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                  <span className="w-[60%] text-left">{order.vendor}</span>
                  {/* <span className="w-[20%] text-left">QUANTITY</span>
                  <span className="w-[20%] text-left">PRICE</span> */}
                </div>

                {/* Table Body */}

                {order?.products?.length > 0 ? (
                  order?.products?.map((product) => (
                    <div
                      key={product.id}
                      className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                    >
                      <div className="flex gap-2 w-[60%] text-left">
                        <img
                          className="w-[94px] h-[94px]"
                          src={product.image}
                          alt=""
                        />
                        <span className="mt-2 text-sm md:text-base font-[500]">
                          {product.name}
                        </span>
                      </div>

                      <span className="w-[20%] text-left font-[400] text-[#888888]">
                        Quantity: {product.product_quantity}
                      </span>
                      <span className="w-[20%] text-left text-sm md:text-base ">
                        <span className="font-[400] text-[#888888]">
                          Price:{" "}
                        </span>
                        <span className="font-semibold">
                          ${product.discount_price}
                        </span>
                      </span>
                      <span className=" w-[20%] text-left ">
                        <Link
                          // to={`/user-dashboard/orders/order-by-id/${product.id}`}
                        >
                          <img
                            className="w-7 h-7 md:w-9 md:h-9"
                            src="/assets/icons/user-dash-board/order-view.svg"
                            alt=""
                          />
                        </Link>
                      </span>
                    </div>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="3"
                      className="text-center font-bold tracking-wide"
                    >
                      No Products Found
                    </td>
                  </tr>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center font-bold tracking-wide">
          No order Found
        </div>
      )}
    </div>
  );
}
