export const ADD_USER_BUNDLE = "/user/bundle";
export const ADD_USER_AFFILIATE_PRODUCT = "/avaible-affilite-product";


export const AD_MARET_PLACE="user/ads"
export const AUCTION_ADD_PRODUCT="user/auction"
export const AUCTION_ALL_PRODUCTS="user/auction"



export const GET_USER_AFFILIATE_PRODUCT="/user/bundle"

// Tickets
export const TICKETS_LIST="/user/tickets"
export const TICKETS_LIST_SEARCH = "/user/ticket-search"
export const USER_PRODUCT_IMAGE  ="/user/product-image/"


// store followed
export const STORE_FOLLOWED="/user/store-followed"
export const DELETE_STORE_FOLLOWED = "/user/follow-delete"

export const APPROVED_OFFER="/user/approved-offers"
export const USER_OFFER = "/user/offer"
export const STATUS_CHANGE_USER_AFFILIATE_PRODUCT="/user/affilite-product/"