import React, { useEffect, useState } from 'react'
import InspirationVideoCategoryPreviewCard from './InspirationVideoCategoryPreviewCard'

function InspirationVideosCategoryPreview({getFunction,category ,divRefs}) {
const [expandReels, setExpandReels] = useState(false);

const openAllReels = () => {
    getFunction(category.slug);
}
  return (
    <div className="flex flex-col w-full h-fit pt-5 md:pt-0" id={category.category.toLowerCase()}>
        <div className="w-full mx-auto h-fit grid pl-[20%] sm:pl-5 md:pl-0 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">

        {category.reels.length>0?
        <div className={`col-span-1 flex justify-center items-center`}>
            <div className={`relative w-full ${expandReels?"":category.reels.length>=3?"h-[455px] 2xl:h-[650px]":(category.reels.length%3)==1?"h-[370px] lg:h-[455px] 2xl:h-[650px]":"h-[410px] lg:h-[455px] 2xl:h-[650px]"}`}>

                {category.reels.slice(0,category.reels.length>3?3:category.reels.length).map((reels,index) => (
                    <InspirationVideoCategoryPreviewCard expandReels={expandReels} setExpandReels={setExpandReels} getFunction={getFunction} cat={category.slug} index={index} reels={reels} length={category.reels.length>=3?0:category.reels.length%3}/>
                ))}
            </div>
        </div>
        :
        <div className={`col-span-full flex justify-center items-start`}>
            <div className={`relative w-fit py-8 font-semibold text-xl text-center`}>
                <p className='text-gray-400 font-mono'>No reels in "{category.category.toUpperCase()}"</p>
            </div>
        </div>
        }
        {category.reels.length>3  && 
        <div className={`col-span-1 flex justify-center items-start`}>
            <div className={`relative w-full ${expandReels?"":category.reels.length>=6?"h-[455px] 2xl:h-[650px]":(category.reels.length%3)==1?"h-[370px] lg:h-[455px] 2xl:h-[650px]":"h-[410px] lg:h-[455px] 2xl:h-[650px]"}`}>

                {category.reels.slice(3,category.reels.length>6?6:category.reels.length).map((reels,index) => (
                    <InspirationVideoCategoryPreviewCard expandReels={expandReels} setExpandReels={setExpandReels} getFunction={getFunction} cat={category.slug} index={index} reels={reels} length={category.reels.length>=6?0:category.reels.length%3} />
                ))}
            </div>
        </div>
        }
        {category.reels.length>6 &&
        <div className={`col-span-1 flex justify-center items-start`}>
            <div className={`relative w-full ${expandReels?"":category.reels.length>=9?"h-[455px] 2xl:h-[650px]":(category.reels.length%3)==1?"h-[370px] lg:h-[455px] 2xl:h-[650px]":"h-[410px] lg:h-[455px] 2xl:h-[650px]"}`}>

                {category.reels.slice(6,category.reels.length>9?9:category.reels.length).map((reels,index) => (
                    <InspirationVideoCategoryPreviewCard expandReels={expandReels} setExpandReels={setExpandReels} getFunction={getFunction} cat={category.slug} index={index} reels={reels} length={category.reels.length>=9?0:category.reels.length%3} />
                ))}
            </div>
        </div>
        } 

        </div>

        {category.reels && (
            <div onClick={openAllReels} className='flex w-full -gap-1 justify-center h-fit py-3'>
                <img 
                    src="https://as2.ftcdn.net/v2/jpg/02/44/42/79/1000_F_244427911_aoHHulebtYy4wLpncBBuWqCTNFKolcCB.jpg" 
                    alt="user-1"
                    className="h-14 w-14 rounded-full"
                />
                <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKMalIq5tRtkY9RE4CHeXRpbL94Vul51sTIlXG1HZzTVakfs8zxlZdWYKHkrdVx0vJ3yQ&usqp=CAU"
                    alt="user-3"
                    className="h-14 w-14 rounded-full"
                />
                <img
                    src="https://as2.ftcdn.net/v2/jpg/02/44/42/79/1000_F_244427911_aoHHulebtYy4wLpncBBuWqCTNFKolcCB.jpg" 
                    alt="user-2"
                    className="h-14 w-14 rounded-full"
                />
            </div>
        )}
    </div>
    )
}

export default InspirationVideosCategoryPreview
