import UserDashboardVenderApprovedOfferPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Dashboard-Vender-Offer-Page/User-Dashboard-Vender-Approved-Offer-page/UserDashboardVenderApprovedOfferPage";
import UserDashboardSellingVenderOfferPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Dashboard-Vender-Offer-Page/UserDashboardSellingVenderOfferPage";

const vendorOffersRoutes = [
  {
    path: "/user-dashboard/vender-offers",
    element: <UserDashboardSellingVenderOfferPage />,
  },
  {
    path: "/user-dashboard/vender-approved-offers",
    element: <UserDashboardVenderApprovedOfferPage />,
  },
];

export default vendorOffersRoutes;
