import MyShelfSubCategory from "../../components/MyShelf-Component/My-Shelf-SubCategory/MyShelfSubCategory";
import SingleSubCategoryProducts from "../../pages/Main-Shop/My-Shelf-Sub-Categories/SingleSubCategoryProducts";
import MyShelf from "../../pages/Main-Shop/MyShelf/MyShelf";

const myShelfRoutes = [
  {
    path: "/my-shelf",
    children: [
      {
        index: true,
        element: <MyShelf />,
      },

      {
        path: "/my-shelf/:category",
        children: [
          {
            index: true,
            element: <MyShelfSubCategory />,
          },
          {
            path: "/my-shelf/:category/:slug",
            element: <SingleSubCategoryProducts />,
          },
        ],
      },
    ],
  },
];

export default myShelfRoutes;
