import React from "react";
import UserDashboardSellingLayout from "../../../Layout/User-Dashboard-layout/UserDashboardSellingLayout";
import UserDashboardAdMarketplaceAddProduct from "../../../components/User-Dashboard-Components/User-Dashboard-Ad-Marketplace-Product-Components/UserDashboardAdMarketplaceAddProduct";

export default function UserDashboardSellingPageAddProduct() {
  return (
    <>
      <UserDashboardSellingLayout>
        <UserDashboardAdMarketplaceAddProduct />
      </UserDashboardSellingLayout>
    </>
  );
}
