import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import LoginPage from "../pages/LoginPage";
import NewPasswordPage from "../pages/NewPasswordPage";
import SignUpPage from "../pages/SignUpPage";
import VerifyOtp from "../pages/VerifyOtp";

const authRoutes = [
  {
    path: "/dashboard/signup",
    element: <SignUpPage />,
  },
  {
    path: "/dashboard/login",
    element: <LoginPage />,
  },
  {
    path: "/dashboard/otp-verification",
    element: <VerifyOtp />,
  },
  {
    path: "/dashboard/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/new-password",
    element: <NewPasswordPage />,
  },
];

export default authRoutes;
