import Inspiration from "../../pages/Main-Shop/inspiration/Inspiration";

const inspirationRoutes = [
  {
    path: "/inspiration",
    element: <Inspiration />,
  },
];

export default inspirationRoutes;
