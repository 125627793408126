import React, { useState, useEffect, useRef } from "react";
import "./otp.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, userForgotPassword } from "../store/categorySlice";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, message, error } = useSelector((state) => state.category);

  const [email, setEmail] = useState("");

  const [inputs, setInputs] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const handleChange = (index, event) => {
    const value = event.target.value;

    if (value.length > 1) return;

    const newInputs = [...inputs];
    newInputs[index] = value;

    setInputs(newInputs);

    if (value && index < inputs.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleEmailSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email === "") {
      toast.error("Please enter email first");
    } else if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
    } else {
      dispatch(userForgotPassword({ email:email }));
  }
  };

  useEffect(() => {
    if (message) {
      toast.success(message);

      if (message === "Email and OTP verified successfully.") {
        navigate("/new-password");
      }
    }
    if (error) {
      toast.error(error);
    }

    dispatch(clearMessage());
  }, [message, error]);

  return (
    <div className="h-[100vh] flex justify-center items-center px-4 md:px-0 bg-[#FFFAF4]">
      <div
        className="w-full md:w-fit p-4 md:p-14 bg-white flex flex-col items-center rounded-3xl"
        style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
      >
        <img className="mx-auto" src="/assets/images/login-logo.svg" alt="" />
        <div className="font-bold tracking-wide text-xl md:text-3xl mt-5 mb-2 text-center">
          Forgot Password
        </div>
        <div className="text-xs md:text-sm text-center font-bold tracking-wide">
          Enter your email to receive an OTP to reset your password{" "}
        </div>
        <div className="w-full md:w-[30rem] mt-6 flex flex-col">
          <label className="form-control ">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter Your Email Address
              </span>
            </div>
            <div className="relative">
              <input
                type="email"
                placeholder="mike.tyson@mail.com"
                className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                disabled={loading}
                onClick={handleEmailSubmit}
                className="absolute right-4 top-4 text-[#C19A6B] text-sm font-normal"
              >
                {!loading ? "Send OTP" : "Sending"}
              </button>
            </div>
          </label>
        </div>
        <div className="flex items-center justify-center mt-4 gap-3">
          <span className="text-xs md:text-sm text-[#18120F] font-normal">
            Didn’t receive an link? Resend in 1 min
          </span>
          <button className="text-[#C19A6B] text-xs md:text-[15px] font-semibold">
            Resend
          </button>
        </div>
      </div>
    </div>
  );
}
