import ProductCard from "../../Common/ProductCard";
import { getMyShelf } from "../../../store/frontendSlice";

function RecentlyBoughtProducts({mainCategorySliderData}) {

  return (
    <div className="w-full">
      <div className="w-full px-8 mt-12">
        <p className="text-3xl font-semibold ml-3">Recently Bought</p>
        <div
          className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-4 place-items-center `}
        >
          {mainCategorySliderData?.products?.map((item) => (
            <ProductCard key={item._id} item={item} getFunction={getMyShelf} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default RecentlyBoughtProducts;
