import UserDashboardBuyingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";
import UserDashboardMyReviews from "../../../../components/User-Dashboard-Components/User-Dashboard-Buying-Components/User-Dashboard-Reviews/UserDashboardMyReviews";

export default function UserDashboardMyReviewsPage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <UserDashboardMyReviews />
      </UserDashboardBuyingLayout>
    </>
  );
}
