import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useProductFilterByCategory } from "../../custom-hooks/useFilterProducts";
import ProductCard from "../../Common/ProductCard";

export default function SmartFilterCards({ view, smartFilterOpen, products, getFunction }) {

  return (
    <>
      <div className="w-full max-w-screen-2xl container mb-6">
        <div
          className={`w-full gap-8 grid ${
            view===3
              ? "grid-cols-3 place-item-center"
              : view===4 ? "grid-cols-4": view===5 ? "grid-cols-5" : "grid-cols-2 sm:grid-cols-3 md:grid-cols-3"
          } `}
        >
          {products?.length>0? products?.map((item) => (
            <ProductCard getFunction={getFunction} key={item._id} item={item} />
          )):"No Product Found"}
        </div>
      </div>
    </>
  );
}
