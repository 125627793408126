import React from "react";
import { Link } from "react-router-dom";
const generateStarRating = (rating) => {
  const stars = [];
  const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
  const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <img key={i} src={filledStar} alt="filled star" className="w-4 h-4" />
      );
    } else {
      stars.push(
        <img
          key={i}
          src={unfilledStar}
          alt="unfilled star"
          className="w-4 h-4"
        />
      );
    }
  }
  return stars;
};

export default function BoostedStoreCard({ data }) {
  // console.log(data);
 
  return (
    <>
      <div
        className="w-full max-h-[237px] md:mi-w-[320px] md:min-h-[230px] lg:min-w-[431px] lg:min-h-[286px]   xl:max-w-[23.8rem] xl:max-h-[252px] pb-4 rounded-md"
        style={{ boxShadow: "0px 23.08px 46.16px 0px #0508281A" }}
      >
        <div >
          <img
            className=  "w-full"
            src="/assets/images/store-square/home/boost-store-square.svg"
            alt=""
          />
        </div>
        <div className="flex justify-around  mt-2 ps-16 mb-4 relative">
          <img
            className="absolute left-4 -top-10  h-[4.5rem] w-[4.5rem] md:h-14 md:w-14 lg:h-[4.5rem] lg:w-[4.5rem] rounded-full "
            src={data.shop_picture}
            alt=""
          />
          <div>
            <div className="flex items-center gap-2">
              <span className="text-sm font-bold">{data.store_name} </span>
              <img
                className="h-3 w-3"
                src="/assets/images/store-square/home/boost-store-verify.svg"
                alt=""
              />
            </div>
            <div className="flex items-center gap-2">
              <div className="flex gap-1">
              {generateStarRating(data.ratings)}
              </div>
              <div className="text-xs text-[#828282]">(92)</div>
            </div>
          </div>
          <Link
            to={"/vendor-shop-front/"+data.slug}
            className="py-[0.45rem] px-6 text-sm border-[0.09rem] border-[#C19A6B] font-bold tracking-wider text-[#C19A6B] rounded-md"
          >
            Visit Store
          </Link>
        </div>
        <div className="flex gap-2 justify-around xl:px-6">
          {data?.products?.slice(0, 3).map((item) => {
            return(
            <Link to={`/single-product/${item.slug}`}>
            <img
              className=" w-20 h-20 lg:w-24 lg:h-24 rounded-lg drop-shadow-lg"
              src={item?.image}
              alt=""
            />
          </Link>)
          })}
          
          
        </div>
      </div>
    </>
  );
}
