import StoreSquareRoot from "../pages/StoreSquareRoot";
import UserDashBoardRoot from "../pages/UserDashBoardRoot";
import Root from "../pages/root";
import authRoutes from "../route/authRoute";
import adMarketPlaceRoutes from "./adMarketPlaceRoutes/adMarketPlaceRoutes";
import cartRoutes from "./mainShopRoutes/cartRoutes";
import checkoutRoutes from "./mainShopRoutes/checkoutRoutes";
import homeRoutes from "./mainShopRoutes/homeRoutes";
import inspirationRoutes from "./mainShopRoutes/inspirationRoutes";
import mainCategoriesRoutes from "./mainShopRoutes/mainCategoriesRoutes";
import myShelfRoutes from "./mainShopRoutes/myShelfRoutes";
import ownerShopFrontRoutes from "./mainShopRoutes/ownerShopFront";
import paymentConformationRoutes from "./mainShopRoutes/paymentConformationRoutes";
import paymentRoutes from "./mainShopRoutes/paymentRoutes";
import shopFrontRoutes from "./mainShopRoutes/shopFrontRoutes";
import SingleProductPageRoutes from "./mainShopRoutes/singleProductPageRoutes";
import vendorShopFrontRoutes from "./mainShopRoutes/vendorShopFrontRoutes";
import storeSquareRoutes from "./multiVendorShopRoutes/storeSqaureRoutes";
import accountAndSettingRoutes from "./userDashboardRoutes/buyingRoutes/accountAndSettingsRoutes";
import followedStoreRoutes from "./userDashboardRoutes/buyingRoutes/followedStore";
import buyingHomeRoutes from "./userDashboardRoutes/buyingRoutes/homeRoutes";
import ordersRoutes from "./userDashboardRoutes/buyingRoutes/ordersRoutes";
import reviewsRoutes from "./userDashboardRoutes/buyingRoutes/reviewsRoutes";
import userChatRoutes from "./userDashboardRoutes/buyingRoutes/userChat";
import userSupportRoutes from "./userDashboardRoutes/buyingRoutes/userSupport";
import wishListRoutes from "./userDashboardRoutes/buyingRoutes/wishListRoutes";
import affiliateRoutes from "./userDashboardRoutes/sellingRoutes/affiliateRoutes";
import auctionRoutes from "./userDashboardRoutes/sellingRoutes/auctionRoutes";
import myFollowerRoutes from "./userDashboardRoutes/sellingRoutes/myFollower";
import sellingHomeRoutes from "./userDashboardRoutes/sellingRoutes/sellingHomeRoutes";
import userAdMarketPLaceRoutes from "./userDashboardRoutes/sellingRoutes/userAdMarketPlaceRoutes";
import vendorOffersRoutes from "./userDashboardRoutes/sellingRoutes/vendorOffersRoutes";
import extraRoutes from "./mainShopRoutes/extraRoutes";

const mainRoutes = [
  {
    path: "/",
    element: <StoreSquareRoot />,
    children: [
      ...homeRoutes,

      ...mainCategoriesRoutes,
      ...inspirationRoutes,
      ...cartRoutes,
      ...checkoutRoutes,
      ...paymentRoutes,
      ...paymentConformationRoutes,
      ...SingleProductPageRoutes,
      ...myShelfRoutes,
      ...shopFrontRoutes,
      // ...ownerShopFrontRoutes,
      ...vendorShopFrontRoutes,
      ...adMarketPlaceRoutes,
      ...storeSquareRoutes,
      ...extraRoutes,
    ],
  },

  {
    path: "/",
    element: <Root />,
    children: [
      ...authRoutes,
      {
        path: "/user-dashboard",
        element: <UserDashBoardRoot />,
        children: [
          ...buyingHomeRoutes,
          ...reviewsRoutes,
          ...userChatRoutes,
          ...accountAndSettingRoutes,
          ...wishListRoutes,
          ...followedStoreRoutes,
          ...ordersRoutes,
          ...userSupportRoutes,
          ...sellingHomeRoutes,
          ...vendorOffersRoutes,
          ...myFollowerRoutes,
          ...affiliateRoutes,
          ...userAdMarketPLaceRoutes,
          ...auctionRoutes,
        ],
      },
    ],
  },
];

export default mainRoutes;
