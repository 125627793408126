import { useEffect, useState } from "react";
import "./UserDashboardVenderOffers.css";
import { BsThreeDotsVertical } from "react-icons/bs";

import BreadCrums from "../../Common/BreadCrums";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getApprovedOffer, handleStatusAffiliateProduct } from "../../../store/categorySlice";

export default function UserDashboardVenderApprovedOffers() {
  const [approvedOffers, setApprovedOffers] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dispatch = useDispatch();
  const { data,token } = useSelector(state => state.category);
  const handleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  useEffect(() => {
    dispatch(getApprovedOffer());
  }, []);
  const handleChat = (store_id) => {
    if (localStorage.getItem("token")) {

      const url = "https://messenger.yugoiptv.com/t/"+localStorage.getItem("token")+"/"+store_id; window.location.href = url;
    } else {
      navigate("/dashboard/login")
    }
    
  }
  const handleStatus = (id,status) => {
    dispatch(handleStatusAffiliateProduct({ uid: id, status })).then(() => {
      dispatch(getApprovedOffer());
    })
  }
  return (
    <div className="w-full flex flex-col gap-5 px-4">
      <BreadCrums
        breadCrum={[
          {
            name: "Aproved Offers",
            path: "",
          },
        ]}
      />

      {data?.products?.length>0&&data?.products.map((offer) => (
        <div
          key={offer.id}
          className="flex flex-col w-full px-2 py-2 xl:px-4 rounded-[10px] shadow-lg"
        >
          {/* vender approved offers  */}
          <div className="relative flex flex-col md:flex-row md:justify-between md:items-center w-full xl:h-[122px]">
            {/* vender details  */}
            <div className="flex items-start lg:items-center gap-3">
              {/* vender image  */}
              <div>
                <img
                  src={offer.shop_picture}
                  alt=""
                  className=" w-11 h-11 lg:w-[82px] lg:h-[86px] rounded-full"
                />
              </div>

              {/* vender details  */}
              <div className="flex flex-col ">
                {/* name  */}
                <div className="font-bold flex gap-1 text-xs xl:text-base">
                  <span className="font-bold text-sm md:text-base">
                    {offer.store_name}
                  </span>{" "}
                  <img
                    src="/assets/icons/user-dash-board/verify-icon.svg"
                    alt=""
                  />
                </div>

                {/* review stars  */}
                {/* <div className="flex items-center gap-1 xl:mb-2">
                  <span>
                    <img className="h-2 md:h-3" src={offer.star} alt="" />
                  </span>{" "}
                  <span className="text-xs text-[#828282]">
                    ({offer.review})
                  </span>
                </div> */}

                {/* vender message  */}
                <div className="w-[10rem] xl:w-[22rem]">
                  <p className="font-medium text-[8px] xl:text-sm text-[#828282]">
                    {offer.msg}
                  </p>
                </div>
              </div>
            </div>

            {/* offer store products  */}
            <div className="flex items-center gap-5 md:gap-10">
              <div className="font-semibold ml-14 text-[10px] xl:text-base">
                <span className="font-semibold">{offer.total_sales} </span>
                <span className="text-[#828282]">Sales</span>
              </div>

              <div className="font-semibold ml-14 text-[10px] xl:text-base">
                <span className="font-semibold">${offer.total_profit} </span>{" "}
                <span className="text-[#828282]">Profit</span>
              </div>
            </div>

            {/* vender response  */}
            <div className="hidden md:flex items-center gap-2 xl:gap-6 xl:pr-8" >
              <Link  className="cursor-pointer"onClick={(e)=>handleChat(offer.store_id)}>
                <img src="/assets/icons/user-dash-board/chat.svg" alt="chat" />
              </Link>{" "}
              <span className="cursor-pointer" onClick={(e)=>handleStatus(offer.uid,"reject")}>
                <img
                  src="/assets/icons/user-dash-board/contract-end.svg"
                  alt="chat"
                />
              </span>{" "}
            </div>
            {/* mobile view */}
            <div className="absolute md:hidden flex  top-1 right-0">
              {openDropdownId === offer.id && (
                <div className="rounded-lg p-2 bg-white font-semibold shadow-md flex flex-col gap-[2px] text-[7px] tracking-wider">
                  <span>Chat</span>
                  <span>Nothing</span>
                </div>
              )}
              <BsThreeDotsVertical onClick={() => handleDropdown(offer.id)} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
