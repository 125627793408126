import UserDashboardBuyingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";
import UserDashboardOrderView from "../../../../components/User-Dashboard-Components/User-Dashboard-Buying-Components/User-Dashboard-Orders/UserDashboardOrderView";

export default function UserDashboardOrderViewPage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <UserDashboardOrderView />
      </UserDashboardBuyingLayout>
    </>
  );
}
