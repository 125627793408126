import UserDashboardUserProfileAddresses from "../../../../components/User-Dashboard-Components/User-Dashboard-User-Profile-Settings/UserDashboardUserProfileAddresses";
import UserDashboardBuyingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";

export default function UserDashboardUserProfileAddressesPage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <UserDashboardUserProfileAddresses />
      </UserDashboardBuyingLayout>
    </>
  );
}
