import React from "react";

const AdStoreSingleProductDescription = ({ Adproduct }) => {
  return (
    <>
      <div className="xl:px-[57px] md:px-[20px] lg:px-[40px] px-4 py-4 max-w-screen-2xl mx-auto">
        <div className="p-4 md:p-5 xl:p-7 rounded-lg shadow-lg h-[190px] md:min-h-[193px] xl:h-fit overflow-hidden overflow-y-auto">
          <h1 className="font-bold text-[22px] xl:text-[32px]">
            Product Description
          </h1>
          <p className="text-sm xl:text-sm opacity-70 mt-4">
            {Adproduct?.products?.description}
          </p>
          {/* <p className="text-sm xl:text-sm opacity-70 mt-1">
            {" "}
            Lorem ipsum dolor sit amet consectetur. Diam sit donec vitae
            malesuada tempor augue. Nec nisl tortor sed malesuada cursus. Erat
            lorem sed senectus commodo. Felis gravida dictum eget molestie
            purus.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit
            amet consectetur. Diam sit donec vitae malesuada tempor augue. Nec
            nisl tortor sed malesuada cursus. Erat lorem sed senectus commodo.
            Felis gravida dictum eget molestie purus. Lorem ipsum dolor sit amet
            consectetur. Diam sit donec vitae malesuada tempor augue. Nec nisl
            tortor sed malesuada cursus. Erat lorem sed senectus commodo. Felis
            gravida dictum eget molestie purus. Lorem ipsum dolor sit amet
            consectetur. Diam sit donec vitae malesuada tempor augue. Nec nisl
            tortor sed malesuada cursus. Erat lorem sed senectus commodo. Felis
            gravida dictum eget molestie purus.
          </p> */}
        </div>
      </div>
    </>
  );
};

export default AdStoreSingleProductDescription;
