import React, { useEffect, useState } from "react";

import { BsArrowBarLeft } from "react-icons/bs";
import { BsArrowBarRight } from "react-icons/bs";
import { BiSolidArrowFromRight } from "react-icons/bi";
import { BiSolidArrowToRight } from "react-icons/bi";

import SubCategoryProductCard from "./SubCategoryProductCard";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdMarketSubCatProducts } from "../../../store/frontendSlice";

const SubCategoryProducts = () => {
  const { slug, subcat } = useParams();

  const dispatch = useDispatch();
  const { adSubCatProduct } = useSelector((state) => state.frontend);

  useEffect(() => {
    dispatch(getAdMarketSubCatProducts({ main: slug, sub: subcat }));
  }, []);

  const [vehicles, setVehicles] = useState([]);
  const [originalVehicles, setOriginalVehicles] = useState([]); // Keep the original order
  const [view, setView] = useState(5);
  const [isOpen, setIsOpen] = useState(false);
  const [sortType, setSortType] = useState("All");
  const [animateClass, setAnimateClass] = useState("");

  const handleView3 = () => {
    setAnimateClass("");
    setView(3); // Change to 3-column view
  };

  const handleView5 = () => {
    setAnimateClass("");
    setView(5); // Change to 5-column view
  };

  const handleSelection = (type) => {
    setSortType(type);
    setIsOpen(false);

    if (type === "Latest") {
      // Sort vehicles
      const sortedVehicles = [...originalVehicles].sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setVehicles(sortedVehicles);
    } else {
      // Restore the original
      setVehicles(originalVehicles);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/Ad-Store-Residence.json");
        const data = await response.json();
        setVehicles(data);
        setOriginalVehicles(data); // Store the original
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  //   const gridClass = `grid grid-cols-${view} gap-8  `;
  // const gridClass = `grid   gap-4 transition-all duration-500 ease-in-out ${
  //   view === 3
  //     ? "md:grid-cols-3 grid-cols-2 md:w-[50%] gap-4 xl:gap-[30px]"
  //     : "xl:grid-cols-5 md:grid-cols-4 grid-cols-2 "
  // }`;

  // const containerStyle = view === 3 ? { width: "100%" } : { width: "100%" };
  // buttons
  const parentdiv =
    view === 3
      ? "flex  xl:w-[60%] md:max-w-[70%] my-5 items-center justify-between"
      : "flex  my-5 items-center justify-between";

  return (
    <>
      <div className="xl:px-[57px] md:px-[40px]  max-w-screen-2xl mx-auto">
        <div className="text-base breadcrumbs font-bold px-4 md:px-0 ">
          <ul>
            <li>
              <Link to={"/ad-market-place"}>
                <a>Home</a>
              </Link>
            </li>
            <li>
              <Link to={`/ad-market-place/${slug}`}>
                <a>{slug}</a>
              </Link>
            </li>
            <li>
              <a>{subcat}</a>
            </li>
          </ul>
        </div>

        <div className="px-4 md:px-0">
          <div className={parentdiv}>
            <div className="font-bold mt-2">
              {adSubCatProduct?.categoryProducts?.length} Results
            </div>
            <div className="flex gap-2">
              <div className="relative inline-block">
                <button
                  className="flex items-center px-1 justify-between py-2 w-44 h-10 border border-gray-200 rounded"
                  onClick={toggleDropdown}
                >
                  <span className="text-[20px] opacity-70">Sort by:</span>
                  <span className="text-[20px]"> {sortType}</span>
                  <svg
                    className={`w-7 h-7 transform ${
                      isOpen ? "rotate-180" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>

                {isOpen && (
                  <div className="absolute z-10 mt-1 w-44 bg-white border rounded shadow-lg">
                    <ul>
                      <li
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleSelection("All")}
                      >
                        All
                      </li>
                      <li
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleSelection("Latest")}
                      >
                        Latest
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            view === 3
              ? "flex flex-col-reverse gap-3   md:flex-row md:justify-between"
              : ""
          } `}
        >
          <div
            className={`grid   gap-4 transition-all duration-500 ease-in-out px-4 md:px-0 ${
              view === 3
                ? "grid-cols-2 lg:grid-cols-3  lg:w-[70%] md:w-[50%] gap-4 xl:gap-[30px]"
                : "xl:grid-cols-5 md:grid-cols-4 grid-cols-2 "
            }`}
          >
            {adSubCatProduct?.categoryProducts?.length > 0 ? (
              adSubCatProduct.categoryProducts.map((item, index) => (
                <div className={`flex ${animateClass}`} key={index}>
                  <SubCategoryProductCard key={index} item={item} view={view} />
                </div>
              ))
            ) : (
              <>
                <div className="flex justify-center items-center h-[30vh] text-center w-full">
                  <h2>No product found</h2>
                </div>
              </>
            )}
          </div>
          <div
            className={`${
              view === 3
                ? "block w-full md:w-2/5   md:right-0  md:animate-from-left-map "
                : "hidden"
            }`}
          ></div>
        </div>
      </div>
    </>
  );
};

export default SubCategoryProducts;
