import PaymentsConfirmationPage from "../../pages/Main-Shop/Payments-Confirmation-Pages/PaymentsConfirmationPage";

const paymentConformationRoutes = [
  {
    path: "/confirm-payments",
    element: <PaymentsConfirmationPage />,
  },
];

export default paymentConformationRoutes;
