import React from "react";
import AdmarketPlaceSearch from "../../components/Search-Components/AdmarketPlaceSearch";


export default function SearchPageAdMarketPlace() {
  return (
    <div>
      <AdmarketPlaceSearch />
    </div>
  );
}
