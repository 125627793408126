import React from "react";
import ProductCard from "../Common/ProductCard";

const AffiliateProducts = ({data,getFunction}) => {

  return (
    <div className="flex w-full justify-center">
      <div className={`max-w-screen-2xl w-full px-4 xl:px-[57px]`}>
        <div className="">
          <div className="font-bold text-3xl mt-12">Products</div>
        </div>
        <div className="mt-4 mb-6">
          {/* Top selling card */}
          <div
            className={`grid lg:grid-cols-4 md:grid-cols-3 xl:grid-cols-4 grid-cols-2 gap-4 2xl:gap-8 `}
          >
            {data?.affiliateProduct?.slice(0, 8)?.map((item) => (
              <ProductCard item={item} getFunction={getFunction} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateProducts;
