import React, { useState } from "react";

export default function CategoryModal({ setFunction, categories, category, setCategory }) {
  // const [subCat, setSubCat] = useState(false);
  // const [subSubCat, setSubSubCat] = useState(false);
  // const [subSubSubCat, setSubSubSubCat] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (

    <>
      <div className={` ${category ? "block" : "hidden"} `}>
        <div
          onMouseLeave={() => setCategory(false)}
          className="absolute  xl:w-[30%] left-1/2 -mt-36  xl:-mt-6   z-50 mx-auto bg-white  rounded-2xl border-[#D9D9D9] p-1 xl:p-4"
        >
          <div className="w-full  flex justify-between ">
            <div className="w-full px-2  xl:px-5">
              <div className="text-[8px] xl:text-sm font-bold tracking-wide border-b xl:pb-2 flex items-center gap-3 ">
                <span>Category</span>
              </div>
              <div className="text-[8px] xl:text-sm font-[300] text-[#8a8a8a]">
                {categories?.map((a, index) => (
                  <div
                  key={index}
                  className={`cursor-pointer xl:p-2 rounded-md ${
                    selectedCategory === addEventListener.name ? "bg-gray-200 " : ""
                  }`}
                  onClick={() => {
                    setFunction("category",a.slug)
                    setSelectedCategory(a.name)
                  }}
                >
                  {a.name}
                </div>
              ))}
                  <div
                    className={`cursor-pointer xl:p-2 rounded-md ${
                      selectedCategory === null ? "bg-gray-200 " : ""
                    }`}
                    onClick={() => {
                      setFunction("category",null)
                      setSelectedCategory(null)
                    }}
                  >
                    None
                  </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>


    // <>
    //   <div
    //     className={` ${
    //       category ? "block w-[40%] px-[57px] mx-auto" : "hidden"
    //     } `}
    //   >
    //     <div
    //       onMouseLeave={() => setCategory(false)}
    //       className="absolute  xl:w-[93%] -mt-28 xl:-mt-6  mr-10  z-50  bg-white  rounded-lg xl:rounded-2xl border-[#D9D9D9] p-2 xl:p-4"
    //     >
    //       <div className="text-[8px] xl:text-sm font-bold tracking-wide pb-2 ml-3  ">
    //         <span>Category</span>
    //       </div>
    //       <div className="w-full  flex gap-5 border-t">
    //         {/* Render countries */}
    //         <div className="w-[25%]  px-2 xl:px-5">
    //           <div className="text-[8px] xl:text-sm  mt-2 flex flex-col gap-2 font-[300] text-[#8a8a8a]">
    //             {categories.length>0 && categories.map((a) => (
    //               <span key={a.id} className="hover:border-r-2 cursor-pointer hover:text-black font-bold">
    //                 {a.name}
    //               </span>
    //             ))}
    //             {/* <span>Furniture</span>
    //             <span>Homes And Living</span>
    //             <span>Books And Staionary</span> */}
    //           </div>
    //         </div>
    //         <div
    //           className={`${
    //             subCat ? "block w-[25%]   px-2 xl:px-5" : "hidden"
    //           }`}
    //         >
    //           <div className="text-[8px] xl:text-sm  mt-2 flex flex-col gap-2 font-[300] text-[#8a8a8a]">
    //             <span
    //               onMouseEnter={() => setSubSubCat(true)}
    //               className="hover:border-r-2 cursor-pointer"
    //             >
    //               Mobile Devices
    //             </span>
    //             <span>Cameras</span>
    //             <span>HeadPhones</span>
    //             <span>Chargers</span>
    //           </div>
    //         </div>
    //         <div
    //           className={`${
    //             subSubCat ? "block w-[25%]   px-2 xl:px-5" : "hidden"
    //           }`}
    //         >
    //           <div className=" text-[8px] xl:text-sm   mt-2 flex flex-col gap-2 font-[300] text-[#8a8a8a]">
    //             <span
    //               onMouseEnter={() => setSubSubSubCat(true)}
    //               className="hover:border-r-2 cursor-pointer"
    //             >
    //               Smart Phones
    //             </span>
    //             <span>Tablets</span>
    //             <span>Ipads</span>
    //           </div>
    //         </div>
    //         <div
    //           className={`${
    //             subSubSubCat ? "block w-[24.5%]   px-2 xl:px-5" : "hidden"
    //           }`}
    //         >
    //           <div className=" text-[8px] xl:text-sm   mt-2 flex flex-col gap-2 font-[300] text-[#8a8a8a]">
    //             <span className="hover:border-r-2 cursor-pointer">
    //               Iphone Mobiles
    //             </span>
    //             <span>Samsung Phones</span>
    //             <span>Vivo Phones</span>
    //             <span>Infinix Phones</span>
    //           </div>
    //         </div>{" "}
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
}
