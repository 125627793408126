import React, { useEffect, useState } from "react";

const AdStoreVehiclesTopCarBrands = () => {
  const [carbrands, setCarbrands] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/AdStore-Vehicles-TopCarBrands.json");
        const data = await response.json();
        setCarbrands(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const SvgIcon = ({ src, alt }) => {
    return (
      <img
        src={src}
        alt={alt}
        className="xl:h-20 xl:w-20 md:w-[67px] md:h-[67px] w-[25px] h-[25px]"
      /> // Adjust height and width as needed
    );
  };

  return (
    <>
      <div className="flex flex-col">
        <h2 className="xl:text-3xl md:text-2xl text-xl font-bold ">
          Top Car Brands
        </h2>
        <div className="flex justify-between md:gap-[70px] gap-[52px]  carousel carousel-center px-5 py-5">
          {carbrands.map((item) => (
            <>
              <div
                className="carousel-item xl:w-[195px] xl:h-[160px] md:w-[164px] md:h-[135px] w-[62px] h-[51px] shadow-lg rounded-lg flex items-center justify-center"
                key={item._id}
              >
                <SvgIcon src={item.img} alt={`Category ${item._id}`} />
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default AdStoreVehiclesTopCarBrands;
