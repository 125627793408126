import React from 'react'
import CheckoutProducts from '../../../components/Checkout-Page-Components/CheckoutProducts'

export default function CheckoutPage() {
    return (
        <>
            <CheckoutProducts />
        </>
    )
}
