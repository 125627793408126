import React from "react";
import UserDashboardSellingLayout from "../../../Layout/User-Dashboard-layout/UserDashboardSellingLayout";
import UserDashboardAuctionProduct from "../../../components/User-Dashboard-Components/User-Dashboard-Auction-Product-Page-Components/UserDashboardAuctionProduct";

export default function UserDashboardSellingAuctionPage() {
  return (
    <>
      <UserDashboardSellingLayout>
        <UserDashboardAuctionProduct />
      </UserDashboardSellingLayout>
    </>
  );
}
