import React, { useEffect, useState } from "react";
import AdStoreRelatedProductCard from "./AdStoreRelatedProductCard";

const AdStoreRelatedProducts = ({ Adproduct }) => {
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "/Adstore-Single-Product-Related-Products.json"
        );
        const data = await response.json();
        setCardData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <div className="xl:px-[57px] md:px-[20px] lg:px-[40px] px-4 max-w-screen-2xl mx-auto">
        <h2 className="font-bold text-3xl mt-8 mb-4 ">Related Products</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 md:gap-[30px] gap-4">
          {Adproduct?.related_products?.length > 0 ? (
            Adproduct.related_products.map((item, index) => (
              <AdStoreRelatedProductCard key={index} item={item} />
            ))
          ) : (
            <p>No related product</p>
          )}
        </div>
      </div>
    </>
  );
};

export default AdStoreRelatedProducts;
