import { Link, NavLink, useLocation } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { SlWallet } from "react-icons/sl";
import { HiOutlineStar } from "react-icons/hi2";
import { BsCardChecklist } from "react-icons/bs";
import { LuFolderCog2 } from "react-icons/lu";
import { useEffect, useState } from "react";
import { IoIosLogOut } from "react-icons/io";
import {
  RiArrowDropDownLine,
  RiArrowDropUpLine,
  RiSettings3Line,
} from "react-icons/ri";

import "./side-bar.css";
import { logout } from "../../../store/categorySlice";
import { useDispatch } from "react-redux";

export default function UserDashboardBuyingSidebar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    // Automatically open the dropdown of the active nav link
    if (
      location.pathname === "/user-dashboard/reviews" ||
      location.pathname === "/user-dashboard/my-reviews"
    ) {
      setOpenDropdown("review");
    } else if (
      location.pathname === "/user-dashboard/profile" ||
      location.pathname === "/user-dashboard/profile-addresses" ||
      location.pathname === "/user-dashboard/payment-methods"
    ) {
      setOpenDropdown("profile");
    } else if (
      location.pathname === "/user-dashboard/help-center" ||
      location.pathname === "/user-dashboard/contact-us" ||
      location.pathname.startsWith("/user-dashboard/tickets")
    ) {
      setOpenDropdown("support");
    } else {
      setOpenDropdown(null);
    }
  }, [location.pathname]);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  // const handleLogout = (e) =>{
  //   e.preventDefault();
  //   dispatch(logout());
  // };

  return (
    <>
      <div className="w-full xl:w-64 h-[87vh]  bg-[#C19A6B] p-4  xl:ml-4 rounded-[0.7rem]  hide-scrollbar overflow-y-auto">
        <div className="flex flex-col gap-1 lg:gap-2 relative">
          {/* <Link className="block">
            <img
              src="/assets/icons/userdashboard/wizalot.svg"
              className="w-full h-12 "
              alt=""
            />
          </Link>

          <div className="absolute right-0 top-0">
            <IoClose />
          </div> */}
          {/* dashboard  */}
          <NavLink
            to="/user-dashboard"
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
            end
          >
            <LuLayoutDashboard className="w-4 h-4" />
            Dashboard
          </NavLink>
          {/* orders  */}
          <NavLink
            to="/user-dashboard/orders"
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
          >
            <SlWallet className="w-4 h-4 " />
            Orders
          </NavLink>

          {/* reviews section  */}
          <div className="flex relative  flex-col ">
            <div
              onClick={() => toggleDropdown("review")}
              className={`${
                location.pathname === "/user-dashboard/reviews" ||
                location.pathname === "/user-dashboard/my-reviews"
                  ? //this is a dummy url
                    "activeLink"
                  : "navLink"
              }`}
            >
              <HiOutlineStar className="w-4 h-4" />
              Reviews
              {openDropdown === "offer" ? (
                <RiArrowDropUpLine className="!w-6 !h-6  absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6  absolute right-1" />
              )}
            </div>
            {/* hot-spot dropdown  */}
            {openDropdown === "review" && (
              <>
                <NavLink
                  to="/user-dashboard/reviews"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                  end
                >
                  History
                </NavLink>
                <NavLink
                  to="/user-dashboard/my-reviews"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  {" "}
                  My Reviews
                </NavLink>
              </>
            )}
          </div>

          {/* wishlist  */}
          <NavLink
            to="/user-dashboard/wishlist"
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
            end
          >
            <BsCardChecklist className="w-4 h-4" />
            Wishlist
          </NavLink>
          {/* store followed  */}
          <NavLink
            to="/user-dashboard/followed-stores"
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
            end
          >
            <LuFolderCog2 className="w-4 h-4" />
            Stores Followed
          </NavLink>
          {/* user support */}
          <div className="flex relative  flex-col ">
            <NavLink
              onClick={() => toggleDropdown("support")}
              className={`${
                location.pathname === "/user-dashboard/help-center" ||
                location.pathname === "/user-dashboard/contact-us" ||
                location.pathname.startsWith("/user-dashboard/tickets")
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <RiSettings3Line className="w-4 h-4" />
              User Support
              {openDropdown === "support" ? (
                <RiArrowDropUpLine className="!w-6 !h-6  absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6  absolute right-1" />
              )}
            </NavLink>
            {/* account dropdown  */}
            {openDropdown === "support" && (
              <>
                <NavLink
                  to="/user-dashboard/help-center"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                  end
                >
                  Help Center
                </NavLink>
                <NavLink
                  to="/user-dashboard/contact-us"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Contact Us
                </NavLink>
                <NavLink
                  to="/user-dashboard/tickets"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Tickets
                </NavLink>
              </>
            )}
          </div>

          {/* account and setting */}
          <div className="flex relative  flex-col ">
            <NavLink
              onClick={() => toggleDropdown("profile")}
              className={`${
                location.pathname === "/user-dashboard/profile" ||
                location.pathname === "/user-dashboard/profile-addresses" ||
                location.pathname === "/user-dashboard/payment-methods"
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <RiSettings3Line className="w-4 h-4" />
              Accounts & Settings
              {openDropdown === "profile" ? (
                <RiArrowDropUpLine className="!w-6 !h-6  absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6  absolute right-1" />
              )}
            </NavLink>
            {/* account dropdown  */}
            {openDropdown === "profile" && (
              <>
                <NavLink
                  to="/user-dashboard/profile"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                  end
                >
                  Profile
                </NavLink>
                <NavLink
                  to="/user-dashboard/profile-addresses"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Addresses
                </NavLink>
                <NavLink
                  to="/user-dashboard/payment-methods"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Payment Method
                </NavLink>
              </>
            )}
          </div>

          {/* logout  */}
          <NavLink
          to="/dashboard/login"
            onClick={() => localStorage.removeItem('token')}
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
          >
            <IoIosLogOut className="w-4 h-4" />
            Logout
          </NavLink>
        </div>
      </div>
    </>
  );
}
