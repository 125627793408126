import React from "react";
import BreadCrums from "../../../Common/BreadCrums";

export default function UserDashboardTrackOrder() {
  return (
    <>
      <div className="px-5 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Orders",
              path: "/user-dashboard/orders",
            },
            {
              name: "Order Details",
              path: "/user-dashboard/orders/order-details",
            },
            {
              name: "Track Order",
              path: "",
            },
          ]}
        />

        {/* map */}
        <div></div>
      </div>
    </>
  );
}
