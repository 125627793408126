import React, { useEffect } from "react";
import BreadCrums from "../../Common/BreadCrums";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTicketById } from "../../../store/categorySlice";

export default function TicketDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.category);

  useEffect(()=>{
    dispatch(getTicketById(id));
  },[id]);

  const DisplayDate = ({ dateString }) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });

    return (
        <>
            {formattedDate}
        </>
    );
  };

  return (
    <>
      <div className="px-4 flex flex-col gap-5">
        <BreadCrums
          breadCrum={[
            {
              name: "Seller Support",
              path: "/user-dashboard/help-center",
            },
            {
              name: "Tickets",
              path: "/user-dashboard/tickets",
            },
            {
              name: "TKT435672",
            },
          ]}
        />

        {/* cards */}
        <div className="w-full lg:w-[60%] border border-[#EBF0ED] flex flex-col md:flex-row justify-between gap-3 md:gap-10 rounded-lg p-2 md:p-5">
          <div className="w-full flex flex-col gap-3">
            <div className="text-[10px] md:text-xs font-semibold flex items-center justify-between ">
              <span className="text-[#6B6B6B]">FULL NAME:</span>
              <span>{data?.ticket? data.ticket.user.name:""}</span>
            </div>
            <span className="border-b border-[#EBF0ED] md:mt-1"></span>
            <div className="text-[10px] md:text-xs font-semibold flex items-center justify-between ">
              <span className="text-[#6B6B6B]">EMAIL:</span>
              <span>{data?.ticket? data.ticket.user.email:""}</span>
            </div>
            <span className="border-b border-[#EBF0ED] md:mt-1"></span>

            <div className="text-[10px] md:text-xs font-semibold flex items-center justify-between ">
              <span className="text-[#6B6B6B]">DATE CREATED:</span>
              <span>{data?.ticket? (<DisplayDate dateString={data.ticket.created_at} />):""}</span>
            </div>
            <span className="border-b border-[#EBF0ED] md:mt-1"></span>
          </div>
          <div className="w-full flex flex-col gap-3">
            <div className="text-[10px] md:text-xs font-semibold flex items-center justify-between ">
              <span className="text-[#6B6B6B]">STATUS:</span>
              <span>{data?.ticket?.status==0?"pending":"active"}</span>
            </div>
            <span className="border-b border-[#EBF0ED] md:mt-1"></span>

            <div className="text-[10px] md:text-xs font-semibold flex items-center justify-between ">
              <span className="text-[#6B6B6B]">CATEGORY:</span>
              <span>{data?.ticket?.ticketcategory? data.ticket.ticketcategory.label:""}</span>
            </div>
            <span className="border-b border-[#EBF0ED] md:mt-1"></span>

            <div className="text-[10px] md:text-xs font-semibold flex items-center justify-between ">
              <span className="text-[#6B6B6B]">HEADING:</span>
              <span>{data?.ticket? data.ticket.ticket_headline:""}</span>
            </div>
            <span className="hidden md:block border-b border-[#EBF0ED] md:mt-1"></span>
          </div>{" "}
        </div>
        {/* summary */}
        <div>
          <h2 className="text-sm md:text-lg font-semibold">Ticket Summary</h2>
          <div className="border border-[#EBF0ED] p-3 bg-[#FAFAFA] rounded-lg h-32 overflow-hidden overflow-y-auto md:h-fit text-[10px] md:text-xs font-[400] ">
            <span>{data?.ticket? data.ticket.ticket_summary:""}</span>
          </div>
        </div>
        {/* attachment */}
        <div className="border border-[#EBF0ED] p-4 my-4 rounded-lg">
          <h2 className="text-sm md:text-lg font-semibold">Attachments</h2>
          <div className="flex items-center justify-between">
            <div className="w-full flex justify-start">
              <div className="  border border-dashed border-[#E0E0E0] w-[100px] h-[75px] rounded-md">
              <img
                  src={
                    data?.ticket?.upload_attachment?
                    data.ticket.upload_attachment:""}
                  alt=""
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
