import React, { useEffect } from "react";
import AdStoreMainCategories from "../../components/Ad-MarketPlace-Components/Home-Page-Components/AdStoreMainCategories";
import AdStoreCarCategories from "../../components/Ad-MarketPlace-Components/Home-Page-Components/Ad-Store-Car-Categories/AdStoreCarCategories";
import AdStoreApartmentscategory from "../../components/Ad-MarketPlace-Components/Home-Page-Components/Ad-Store-Apartments_categories/AdStoreApartmentscategory";
import AdStoreFurnitureAndHomeFurnishing from "../../components/Ad-MarketPlace-Components/Home-Page-Components/Ad-Store_fornitureAndHomeFurnishing/AdStoreFurnitureAndHomeFurnishing";
import AdStorePhonesAndAccessories from "../../components/Ad-MarketPlace-Components/Home-Page-Components/Ad-Store-Phones-And-Accessories/AdStorePhonesAndAccessories";
import AdStoreComputerVedioGame from "../../components/Ad-MarketPlace-Components/Home-Page-Components/Ad-Store-Computers-Video-Games/AdStoreComputerVedioGame";
import { useDispatch, useSelector } from "react-redux";
import { getAdMarketPlace } from "../../store/frontendSlice";

export default function AdMarketPlaceHomePage() {
  const { data } = useSelector(state => state.frontend);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdMarketPlace());
  },[])
  return (
    <>
      <div className=" my-8 px-4 lg:px-8 xl:px-[57px] flex flex-col gap-12">
        <AdStoreMainCategories data={ data?.categories} />
        <AdStoreCarCategories data={data?.categoryProducts } />
       
      </div>
    </>
  );
}
