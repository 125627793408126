import React, { useState } from "react";
import { Link } from "react-router-dom";
// import "./mainCategories.css";

const AdStoreMainCategoriesCard = ({ item }) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const SvgIcon = ({ src, alt }) => {
    return (
      <img src={src} alt={alt} className=" md:min-w-[120px] w-[50px] " /> // Adjust height and width as needed
    );
  };
  // console.log(item);
  return (
    <>
      <Link
        to={`/ad-market-place/${item.slug}`}
        className="relative cursor-pointer md:min-w-[120px] w-[50px] flex flex-col gap-1 items-center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        key={item._id}
      >
        {isHover ? (
          <SvgIcon src={item.image} alt={`Category ${item._id}`} />
        ) : (
        <img src={item.image} alt="category"/>
        )}

        <div
          className={`xl:hidden  w-full flex justify-center 
            }`}
          // style={{ transform: "translateY(50%)" }}
        >
          <p className="text-xs font-bold text-nowrap">{item.name}</p>
        </div>

        {isHover ? (
          <div
            className={`hidden w-full xl:flex justify-center 
            }`}
            // style={{ transform: "translateY(50%)" }}
          >
            <p className="text-sm font-bold name-text">{item.name}</p>
          </div>
        ) : (
          ""
        )}
      </Link>
      {/* <SvgIcon src={item.img} alt={`Category ${item._id}`} /> */}
    </>
  );
};

export default AdStoreMainCategoriesCard;
