import { useEffect } from "react";

const InspirationCategories = ({ data, setExpandedCategory, catScroll, setCatScroll }) => {

  const handleClick = (a) => {
    setExpandedCategory(false);
    setCatScroll(a);
  };

  return (
    <>
      <div className="flex flex-col pl-4 pt-2 z-20">
        {data?.length>0 && data.map((a, index) => (
          <div key={index} className="flex cursor-pointer flex-row justify-start">
            <div className="text-[#828282] flex items-center justify-center w-10">
              <p className="font-normal md:text-[10px] lg:text-xs xl:text-sm">
                {catScroll===a.category && a.reel_count}<span className="opacity-0">.</span>
              </p>
            </div>
            <div
              key={index}
              className={`border-l-2 border-[#D9D9D9] pl-3 pb-1 pt-1 md:pt-0 text-[#828282] font-normal text-base md:text-[10px] lg:text-xs xl:text-base hover:text-black hover:border-l-2 hover:border-black ${
                catScroll === a.category
                  ? "font-bold text-black border-l-2 border-black"
                  : ""
              }`}
              onClick={() => handleClick(a.category)}
            >
              {a.category}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default InspirationCategories;
