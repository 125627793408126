import FirstSlider from "./FirstSlider";
import FirstSliderBanner from "./FirstSliderBanner";
import SecondSlider from "./SecondSlider";
import ThirdSliders from "./ThirdSliders";

export default function HomeSliders({data}) {
  return (
    <>
      <div className={` px-1 lg:px-[20px] w-full`}>
        <div
          className={` flex flex-col md:flex-row gap-2  justify-between w-full  `}
        >
          {data?.banner?.length>0?(
            <>
              <div className={`w-full sm:p-5 sm:px-16 md:p-0 md:px-0 md:w-[45%]`}>
                <FirstSliderBanner banner={data.banner} startIndex={0} endIndex={data?.banner?.length} />
              </div>
              <div className={`w-full sm:p-5 sm:px-16 md:p-0 md:px-0 md:w-[27%] `}>
                <SecondSlider products={data.product} startIndex={0} endIndex={3} />
              </div>
              <div
                className={`w-full sm:p-5 sm:px-16 md:p-0 md:px-0 md:w-[25%] gap-2  flex flex-col  justify-between  `}
              >
                <ThirdSliders  products={data.product} startIndex={3} endIndex={6} />

                <ThirdSliders  products={data.product} startIndex={6} endIndex={9} />
              </div>
            </>
          ):(
            <>
              <div className={`w-full md:w-[45%]`}>
                <FirstSlider products={data.product} startIndex={0} endIndex={3}  />
              </div>
              <div className={`w-full md:w-[27%] `}>
                <SecondSlider products={data.product} startIndex={3} endIndex={6}  />
              </div>
              <div
                className={`w-full md:w-[25%] gap-2  flex flex-col  justify-between  `}
              >
                <ThirdSliders products={data.product} startIndex={6} endIndex={9}  />

                <ThirdSliders products={data.product} startIndex={9} endIndex={12}  />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
