import UserDashboardBuyingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";

import UserDashboardChatUser from "../../../../components/User-Dashboard-Components/UserDashboardchatUserComponent/UserDashboardChatUser";

export default function UserDashboardBuyingChatUserPage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <UserDashboardChatUser />
      </UserDashboardBuyingLayout>
    </>
  );
}
