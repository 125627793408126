import React from 'react'
import PaymentsSection from '../../../components/Checkout-Page-Components/PaymentsSection'

export default function PaymentsPage() {
    return (
        <>
            <PaymentsSection />
        </>
    )
}
