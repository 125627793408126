import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { stringConcat } from "../../functions/formatNumber";

export default function BoostedOfferCard({ item }) {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div
      style={{boxShadow: 'inset 0px 20.43px 30.64px 0px #0000000F'}}
        onClick={()=> navigate(`/offers/${item.link}`)}
        className={`  relative w-full aspect-w-1 aspect-h-1 overflow-hidden  duration-300 ${
          isHovered ? "rounded-2xl cursor-pointer" : "rounded-[2px]"
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="w-full h-full md:h-[19rem] md:w-[19rem]  xl:h-[27rem] xl:w-[27rem]">
          <img
            className="w-full h-full object-cover"
            src={item.image?item.image:"https://gun.io/wp-content/uploads/2023/06/dummy-thumbnail-768x480.jpeg"}
            alt="boosted offers"
          />
        </div>
        <div
          className={` ${
            isHovered ? "top-[85%]" : "top-[85%] lg:top-[87%] xl:top-[90%]"
          }  absolute duration-300 pl-3 lg:pl-7 text-xs lg:text-base left-6 text-nowrap font-bold text-white w-[55%]`}
        >
          {stringConcat(item.title,23)}
        </div>
        <div
          className={`${
            isHovered ? "block left-72" : "hidden"
          } absolute duration-300 pl-2 lg:pl-5 right-5 font-bold text-white top-[86%]`}
        >
          <img src="./assets/images/boosted-offer/boost-arrow.png" alt="" />
        </div>
      </div>
    </>
  );
}
