import { Link, useLocation } from "react-router-dom";
import "./UserDashboardReviews.css";
import UserDashboardReviewsModal from "./UserDashboardReviewsModal";
import BreadCrums from "../../../Common/BreadCrums";
import { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import UserDashboardReturnModal from "./UserDashboardReturnModal";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getReviewHistory } from "../../../../store/categorySlice";
import { toast } from "react-toastify";

export default function UserDashboardReviews() {
  const [reviewIndex, setReviewIndex] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImages1, setSelectedImages1] = useState([]);
  const [review, setReview] = useState("");
  const [reason, setReason] = useState("");

  const dispatch = useDispatch();
  const { data ,message,error,loading } = useSelector((state) => state.category);

  const location = useLocation();

  const handleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  useEffect(() => {
    dispatch(getReviewHistory());
  }, []);

  useEffect(() => {
    if(error){
      toast.error(error);
    }
    if(message){
      toast.success(message);
      dispatch(getReviewHistory());
      document.getElementById("my_modal_review").close()
      document.getElementById("my_modal_return").close()
      setSelectedImages([])
      setSelectedImages1([])
      setReview("")
      setReason("")
    }
    dispatch(clearMessage());
  }, [message,error]);

  return (
    <>
      <div className=" w-full flex flex-col px-5 gap-2">
        <BreadCrums
          breadCrum={[
            {
              name: "Reviews",
              // path: "/dashboard/reviews",
            },
          ]}
        />

        {/* reviews button  */}
        <div className="flex gap-2 xl:gap-3">
          <div
            className={`flex items-center gap-1 md:gap-2 text-[#828282] hover:text-[#C19A6B] ${
              location.pathname.startsWith("/user-dashboard/reviews")
                ? "text-[#C19A6B]"
                : ""
            }`}
          >
            {" "}
            <Link
              to="/user-dashboard/reviews"
              className="font-semibold text-[8px]  md:text-sm "
            >
              HISTORY
            </Link>
            <span className="flex items-center justify-center p-[2px] md:p-1  border-[0.75px]  rounded-[5.75px]">
              <span className=" text-[10.56px] ">({data?.historyCount})</span>
            </span>
          </div>

          <div
            className={`flex items-center gap-1 md:gap-2  hover:text-[#C19A6B] ${
              location.pathname.startsWith("/user-dashboard/my-reviews")
                ? "text-[#C19A6B]"
                : "text-[#828282]"
            }`}
          >
            <Link
              to="/user-dashboard/my-reviews"
              className="font-semibold text-[8px]  md:text-sm "
            >
              MY REVIEWS
            </Link>
            <span className="flex items-center justify-center p-[2px] md:p-1  border-[0.75px]  rounded-[5.75px]">
              <span className="text-[10.56px]">({data?.reviewCount})</span>
            </span>
          </div>
        </div>
        {data?.history?.length>0?
        data?.history?.map((review,index) => (
          <div
            key={review.id}
            className="flex flex-col  w-full mt-3 px-5 py-2 rounded-[10px] shadow-md relative"
          >
            {/* Reviews */}
            <div className="flex flex-col md:flex-row justify-between items-center w-full gap-1">
              <div className="flex w-full gap-3 items-start">
                {/* product image  */}
                <div>
                  <img
                    src={review?.cover_image}
                    alt=""
                    className="w-11 h-11 md:w-14 md:h-14 lg:w-[94px] lg:h-[94px] rounded-[5px]"
                  />
                </div>

                {/* product details  */}
                <div className="flex flex-col gap-1 w-[80%]">
                  <div className="text-xs md:text-base">{review.product_name}</div>
                  <div className="font-semibold text-xs md:text-sm">
                    {review.category_name}
                  </div>
                  <div className="flex gap-2 w-fit">
                    {review.attributes.length>0?review.attributes.map((a)=>(
                      <span className="border px-3 py-1 rounded text-xs md:text-sm">
                        {a.attribute}
                      </span>
                    ))
                    :<></>}
                  </div>
                </div>
              </div>

              {/* product purchased details  */}
              <div className="flex flex-col items-end gap-1 md:gap-2 w-fit absolute bottom-2 right-4 md:relative md:bottom-0 md:right-0">
                <span className="font-bold text-xs md:text-base">
                  {review.store_name}
                </span>
                <span className="text-[10px] md:text-xs font-light text-nowrap">
                {review.order_created_at}
                </span>
                <div className="hidden md:flex items-center gap-2">
                  <button
                    onClick={() => {
                      document.getElementById("my_modal_return").showModal()
                      setReviewIndex(index)
                    }}
                    className="w-full  px-5 py-1 rounded border-[1.63px] border-[#C19A6B] text-[#C19A6B] font-medium text-base text-center hover:bg-[#C19A6B] hover:text-white"
                  >
                    Return
                  </button>
                  <button
                    onClick={() => {
                      setReviewIndex(index)
                      document.getElementById("my_modal_review").showModal()
                    }}
                    className="w-full  px-5 py-1 rounded border-[1.63px] border-[#C19A6B] text-[#C19A6B] font-medium text-base text-center hover:bg-[#C19A6B] hover:text-white"
                  >
                    Review
                  </button>
                </div>
              </div>

              {/* for mobile  */}
              <div className="absolute md:hidden flex  top-2 right-3">
                {openDropdownId === review.id && (
                  <div className="rounded-md p-2 bg-white font-semibold shadow-md flex flex-col gap-[2px] text-[7px] tracking-wider">
                    <span
                      onClick={() => {
                        setReviewIndex(index)
                        document.getElementById("my_modal_return").showModal()
                      }}
                    >
                      Return
                    </span>
                    <span
                      onClick={() => {
                        setReviewIndex(index)
                        document.getElementById("my_modal_review").showModal()
                      }}
                      className="cursor-pointer"
                    >
                      {" "}
                      Review
                    </span>
                  </div>
                )}
                <BsThreeDotsVertical
                  onClick={() => handleDropdown(review.id)}
                />
              </div>
            </div>
          </div>
        )):<></>}
      </div>

      {/* Review modal  */}
      <dialog id="my_modal_review" className="modal">
        <div className="modal-box bg-white !max-w-[1296px] px-4 md:px-9 py-9 ">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-2xl md:text-4xl md:mr-4">
              ✕
            </button>
          </form>
          <h3 className="font-semibold text-xl md:text-3xl  w-fit">
            Write a review
          </h3>
          <UserDashboardReviewsModal loading={loading} review={review} setReview={setReview} selectedImages={selectedImages} setSelectedImages={setSelectedImages} selectedImages1={selectedImages1} setSelectedImages1={setSelectedImages1} reviewIndex={reviewIndex} data={data.history} />
        </div>
      </dialog>

      {/* Return modal  */}
      <dialog id="my_modal_return" className="modal">
        <div className="modal-box bg-white !max-w-[1296px] px-4 md:px-9 py-9 ">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-2xl md:text-4xl md:mr-4">
              ✕
            </button>
          </form>
          <h3 className="font-semibold text-xl md:text-3xl w-fit">
            Return Product
          </h3>
          <UserDashboardReturnModal loading={loading} reason={reason} setReason={setReason} selectedImages={selectedImages} setSelectedImages={setSelectedImages} selectedImages1={selectedImages1} setSelectedImages1={setSelectedImages1} reviewIndex={reviewIndex} data={data.history} />
        </div>
      </dialog>
    </>
  );
}
