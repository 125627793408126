import React from "react";
import TrendingStoreCard from "../../Store-Square-Trending-Page-Components/TrendingStoreCard";
import { Link } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";

export default function TrendingStoreSection({storeSqure}) {
  return (
    <>
      <div className="px-4 md:px-8 lg:px-10  xl:px-[57px] mt-16">
        {/* boosted store */}
        <div className="flex justify-between mt-8">
          <h2 className="font-bold text-base xl:text-2xl  ">Trending Stores</h2>

          <Link to="/store-square/trending-stores">
            <p className="text-[#1983FF] flex items-center gap-1">
              <span>View More</span> <AiOutlineRight />
            </p>
          </Link>
        </div>

        {/* cards */}

        <div className="w-full xl:max-w-screen-2xl container mx-auto mt-2 mb-6 flex justify-center">
          <div className=" grid grid-cols-1 md:grid-cols-2  xl:grid-cols-3 gap-10">
            {storeSqure?.trendingStores?.length>0&&storeSqure?.trendingStores.map((item) => {
       
              return( <TrendingStoreCard data={item} />)
            }) }
           
          
          </div>
        </div>
      </div>
    </>
  );
}
