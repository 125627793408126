import "./user-dashboard-stored-followed.css";
import { Link } from "react-router-dom";

const generateStarRating = (rating) => {
  const stars = [];
  const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
  const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon
  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <img key={i} src={filledStar} alt="filled star" className="w-4 h-4" />
      );
    } else {
      stars.push(
        <img
          key={i}
          src={unfilledStar}
          alt="unfilled star"
          className="w-4 h-4"
        />
      );
    }
  }
  return stars;
};
export default function UserDashboardStoredFollowedCard({ item, isSelecting,selectedIds,setSelectedIds }) {
  const handleSelect = (item) => {
    setSelectedIds( (prevstate)=> {
      if (prevstate.includes(item)) {
        let arr = [...prevstate];
      return arr.filter((data)=>data !=item)
      } else {
        return [...prevstate,item]
    }
    })
  
}
  return (
    <>
      <div className=" py-[29px] px-[43px]  stored-card-box-shadow relative flex justify-center rounded-[7.38px]">
        {/* select checkbox  */}
        {isSelecting && (
          <div
            className={`store-checkbox gap-1 absolute right-2 top-2 px-1 py-1  rounded-full`}
          >
            <input type="checkbox" onClick={(e) => handleSelect(item.id)} />
          </div>
        )}

        <div className="flex flex-col gap-2 justify-center items-center">
          {/* image  */}
          <span>
            <img
              src={item.shop_picture}
              alt="user"
              className="w-14 h-14  md:w-[90px] md:h-[90px] xl:w-[130px] xl:h-[130px] rounded-full"
            />
          </span>

          {/* name  */}
          <div className="flex justify-center items-center gap-2">
            <div className="text-base lg:text-[23px] font-bold ">
              {item.store_name}
            </div>

            <span className="w-3 h-3 lg:w-[19px] lg:h-[19px]">
              <img src={item.verified} alt="" />
            </span>
          </div>

          {/* rating  */}

          <div className="flex gap-1 justify-center items-center ">
          {generateStarRating()}
            <div className="text-[#8C8C8C] text-xs md:text-sm lg:text-lg font-normal">
              ({item.rating_count})
            </div>
          </div>

          {/* account follower and likes  */}
          <div className="flex items-center gap-6 ">
            <div className="flex flex-col items-center">
              <img
                className="w-4 h-4   md:w-[23px] md:h-[20px]"
                src="/assets/icons/heart-icon-shop.png"
                alt=""
              />
              <p className="text-sm lg:text-base font-normal text-[#828282]">
                {item.wishlist_count}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <img
                className="w-4 h-4  md:w-[23px] md:h-[20px]"
                src="/assets/icons/person-icon.png"
                alt=""
              />
              <p className="text-sm lg:text-base font-normal  text-[#828282]">
                {item.follower_count}
              </p>
            </div>
          </div>

          {/* button  */}
          <Link
          to={`/vendor-shop-front/${item.slug}`} className="px-5 py-1 md:px-[30px] md:py-[7px] xl:px-9 xl:py-[10px] text-nowrap text-xs md:text-sm lg:text-[17px] border  lg:border-2 border-[#C19A6B] rounded-md  text-[#C19A6B] font-bold  ">
            Visit Stores
          </Link>
        </div>
      </div>
    </>
  );
}
