import ContactUsPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Support-Pages/ContactUsPage";
import HelpCenterPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Support-Pages/HelpCenterPage";
import TicketDetailspage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Support-Pages/TicketDetailspage";
import TicketsFormPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Support-Pages/TicketsFormPage";
import Ticketspage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Support-Pages/TicketsPage";

const userSupportRoutes = [
  {
    path: "/user-dashboard/help-center",
    element: <HelpCenterPage />,
  },
  {
    path: "/user-dashboard/contact-us",
    element: <ContactUsPage />,
  },
  {
    path: "/user-dashboard/tickets",

    children: [
      {
        index: true,
        element: <Ticketspage />,
      },
      {
        path: "/user-dashboard/tickets/create-tickets",
        element: <TicketsFormPage />,
      },
      {
        path: "/user-dashboard/tickets/tickets-details/:id",
        element: <TicketDetailspage />,
      },
    ],
  },
];

export default userSupportRoutes;
