import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { addBid, clearMessage } from "../../../../store/frontendSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function AuctionBidHistoryComponent({data,auc}) {
  const [bid, setBid] = useState(data?.bids?.bid);
  const [bidButtonIcon, setBidButtonIcon] = useState(0);
  const { message,error} = useSelector((state) => state.frontend);

  const handlePlus = () => {
    setBid(parseInt(bid) + 1);
  };

  const handleMinus = () => {
   
    setBid(parseInt(bid) - 1);
  }
const dispatch=useDispatch()
  const handleBidIcon = () => {
    // Change icon to the other state
    setBidButtonIcon((prevIcon) => (prevIcon === 0 ? 1 : 0));

    // Change back to the original icon after 3 seconds
    dispatch(addBid(  {product_id:data?.id,bid:bid}))
    setTimeout(() => {
      setBidButtonIcon(0); // Assuming 0 is the original icon state
    }, 3000);
  };
  useEffect(() => {

    if(message){
      toast.success(message)
    }
    else if (error){
      toast.error(error)
    }
    dispatch(clearMessage())
    if (parseInt(data?.highestBid) > parseInt(data?.price)) {
      
      setBid(parseInt(data?.highestBid)+1)
    } else {
      setBid(data?.price)
      
    }
  }, [message, error])
  return (
    <>
      <div className="px-4 lg:px-8 xl:px-[128px]">
        {/* featured 3 section wrapper */}
        <div className="flex justify-between gap-6 md:gap-12 pt-10">
          {/* product detail section -- section 0ne */}
          <div className="w-[50%] lg:w-[32%] mt-8 flex flex-col gap-8">
            <div className="flex gap-2">
              <div>
                <img
                  className="h-12 w-12 rounded-full"
                  src={data?.user_image}
                  alt="img"
                />
              </div>
              <div className="text-start">
                <div className="text-sm">Created by</div>
                <div className="text-lg font-bold">@{data?.user_name}</div>
              </div>
            </div>

            <div className="flex  flex-col gap-4">
              <div className="flex flex-col gap-2">
                <div className="text-xl md:text-3xl xl:text-4xl font-[500]">
                {data?.name}
                </div>
                <div className="text-[#505050] text-xs md:text-sm xl:text-base">
                {data?.description?.slice(0,80)}
                </div>
              </div>
              <div className="flex justify-between">
              <Link
                to={"/ad-market-place/auction/single-product/"+data?.slug}
                className="text-base md:text-2xl py-[6.5px] px-[21px] md:py-3 bg-[#C19A6B] text-white rounded w-full text-center md:w-fit"
              >
                View Product
                </Link>
               {!auc && <Link
                to={"/ad-market-place/auction/bid-history/"+data?.slug}
                className="text-base md:text-2xl py-[6.5px] px-[21px] md:py-3 bg-[#C19A6B] text-white rounded w-full text-center md:w-fit"
              >
                Bid History
              </Link>}
              </div>
              
            </div>

          </div>

          {/* image section -- section -- two */}
          <div className="flex flex-col gap-[1.32rem] md:gap-8 w-[50%] lg:w-[30%]">
            <div>
              <img
                className="w-full h-[172px] md:h-[323px] xl:h-[360px]"
                src={data?.image}
                alt=""
              />
            </div>
           
          </div>

        
              </div>
              <div className="w-full mt-12">
        <div className="flex flex-col gap-3 w-full ">
          {data?.bids?.length > 0 && 
            data?.bids.slice(0,7).map((bid, index) => {
              const opacity = Math.max(0.2, 1 - (index * 0.2)); 
              console.log("here");
              return (
                <div
                  key={bid.bid_id}
                  className="relative flex justify-between items-center py-3 px-5 bg-white rounded-xl"
                  style={{
                    boxShadow: "0px 6px 15px 4px #0508280F",
                    // opacity: opacity,
                    transition: 'opacity 0.3s' 
                  }}
                >
                  <div
                    className="absolute top-0 left-0 w-full h-full"
                    style={{
                      background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0))",
                      pointerEvents: "none", // To allow interaction with underlying elements
                      borderRadius: "inherit", // Match the parent's border-radius
                    }}
                  ></div>
                  <div className="flex items-center gap-1">
                    <span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={bid.user_image} // Replace with user avatar URL if available
                        alt="User Avatar"
                      />
                    </span>
                    <span className="text-sm">@{bid.user_name}</span>
                  </div>
                  <div className="text-sm font-bold tracking-wide">${bid.bid}</div>
                </div>
              );
            })}
               
              </div>
            </div>
      </div>
      
    </>
  );
}
