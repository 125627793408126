import React from "react";

import UserDashboardSellingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardSellingLayout";
import UpdateUserAffiliateBundle from "../../../../components/User-Dashboard-Components/User-Dashboard-Affiliate-page-Available-product-components/User-dashboard-Affilliate-Bundles-Components/UpdateUserAffiliateBundle";

export default function UpdateUserDashboardAffilliateUplaodBundlesPage() {
  return (
    <>
      <UserDashboardSellingLayout>
        <UpdateUserAffiliateBundle />
      </UserDashboardSellingLayout>
    </>
  );
}
