import React from "react";
import SingleProductDetails from "../../../components/Single-Product-page-Components/SingleProductDetails";

const SingleProductPage = () => {
  return (
    <>
      <SingleProductDetails />
    </>
  );
};

export default SingleProductPage;
