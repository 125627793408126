import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import "./adds-slider.css";
import { useNavigate } from "react-router-dom";

export default function SecondSlider({ products, startIndex, endIndex }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate()

  const slider = React.useRef(null);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlide,
  };

  return (
    <div className="slider-container relative">
      <div onClick={() => slider?.current?.slickPrev()} className="cursor-pointer left-button absolute z-20 invisible left-0 top-[6.5rem] xl:top-[9.5rem] bg-white flex justify-center items-center w-8 h-8 xl:w-12 xl:h-12 rounded-full opacity-60 hover:opacity-100">
        <button
          className=" xl:p-4"
        >
          <img
            src="../../assets/images/home-sliders/lt.png"
            alt=""
            className="w-[7px] xl:w-[9px]"
          />
        </button>
      </div>
      <div onClick={() => slider?.current?.slickNext()} className="cursor-pointer right-button absolute z-20 invisible right-0 top-[6.5rem] xl:top-[9.5rem] flex justify-center items-center w-8 h-8 xl:w-12 xl:h-12 rounded-full bg-white opacity-60 hover:opacity-100">
        <button
          className=" xl:p-4"
        >
          <img
            src="../../assets/images/home-sliders/gt.png"
            alt=""
            className="w-[7px] xl:w-[9px]"
          />
        </button>
      </div>

      <div className="absolute z-10 left-6 bottom-6 rounded-[25px] bg-black bg-opacity-50 font-bold text-white text-base md:text-sm xl:text-base py-2 px-5">
      {products? products[startIndex]?.label:""}
      </div>

      <Slider ref={slider} {...settings}>
        {products?.slice(startIndex, endIndex).map((item, index) => {
          return (
            <div onClick={() => navigate(`/single-product/${item.slug}`)} className="w-full h-[253px] xl:h-[354px] overflow-hidden self-center rounded-lg">
            <img 
            src={item.image}
            alt="" className="w-full h-full object-fill"/>
          </div>
      );
        })}
      </Slider>
    </div>
  );
}
