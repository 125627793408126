import { configureStore } from "@reduxjs/toolkit";

import categoryReducer from "./categorySlice";
import frontendReducer from "./frontendSlice";

const store = configureStore({
  reducer: {
    category: categoryReducer,
    frontend:frontendReducer
  },
});

export default store;
