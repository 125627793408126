import React from "react";


const generateStarRating = (rating) => {
  const stars = [];
  const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
  const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <img key={i} src={filledStar} alt="filled star" className="w-4 h-4" />
      );
    } else {
      stars.push(
        <img
          key={i}
          src={unfilledStar}
          alt="unfilled star"
          className="w-4 h-4"
        />
      );
    }
  }
  return stars;
};

export default function ReviewCard({ item, index }) {
  return (
    <div
      key={index}
      className="mx-2 shadow-lg py-5  h-[182px] px-8  bg-white mt-10 rounded-md"
    >
      <div className="flex gap-2">
      {generateStarRating(item.rating)}
      </div>
      <div className="py-4 text-sm">
        <p>{item.review}</p>
      </div>
      <div className="flex  items-center">
        <img src={item.dp} className="lg:w-[43px] lg:h-[43px]" alt="" />
        <div>
          <p className="font-semibold text-sm">{item.user}</p>
          <p className="text-xs opacity-45">{item.date}</p>
        </div>
      </div>
    </div>
  );
}
