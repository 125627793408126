import React from "react";
import { AiOutlineHeart, AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";
import {formatNumber, stringConcat} from "../../functions/formatNumber";

const generateStarRating = (rating) => {
  const stars = [];
  const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
  const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <img key={i} src={filledStar} alt="filled star" className="w-4 h-4" />
      );
    } else {
      stars.push(
        <img
          key={i}
          src={unfilledStar}
          alt="unfilled star"
          className="w-4 h-4"
        />
      );
    }
  }
  return stars;
};

export default function ReviewStoreShortDetails({store}) {
  return (
    <div className="flex flex-col gap-1 items-center w-full bg-white card-shadow px-3 sm:px-8 py-1 sm:py-6 rounded-xl ">
      <img
        className="card-shadow rounded-full max-h-[100px] max-w-[100px] xl:max-h-[130px] xl:max-w-[130px]"
        src={store?.shop_picture?store.shop_picture:"/assets/images/reviews/owner-shop-1.png"}
        alt=""
      />
      <div className="flex justify-center space-x-2 items-center">
        <div className="text-lg xl:text-[23px] font-bold whitespace-nowrap">{stringConcat(store.store_name,13)}</div>
        <div className="flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-[15px] h-[15px] xl:w-[19px] xl:h-[19px]"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M9.70683 8.62526C8.98289 8.79909 8.22715 8.79067 7.50726 8.60075C6.78738 8.41083 6.12584 8.04534 5.58187 7.53701C5.0379 7.02868 4.6285 6.39337 4.39031 5.68799C4.15213 4.9826 4.0926 4.22915 4.21706 3.49512C4.19813 3.5134 4.17814 3.53055 4.15721 3.54649C4.00891 3.65931 3.82353 3.70115 3.45278 3.78483L3.11645 3.8611C1.81353 4.15611 1.16206 4.30336 1.00688 4.80175C0.85222 5.29962 1.29606 5.8192 2.18428 6.85784L2.41414 7.12637C2.66626 7.42138 2.79284 7.56915 2.84951 7.75135C2.90618 7.93407 2.88712 8.1311 2.84898 8.52463L2.81403 8.8832C2.68003 10.2693 2.61276 10.9621 3.01847 11.2698C3.42418 11.578 4.03433 11.2973 5.25357 10.7354L5.56977 10.5902C5.91616 10.4303 6.08935 10.3508 6.27314 10.3508C6.45693 10.3508 6.63012 10.4303 6.97704 10.5902L7.29218 10.7354C8.51195 11.2968 9.1221 11.5775 9.52728 11.2703C9.93352 10.9621 9.86625 10.2693 9.73225 8.8832L9.70683 8.62526Z"
              fill="#001AFF"
            />
            <path
              opacity="0.5"
              d="M4.76634 2.60874L4.59262 2.92017C4.40195 3.26232 4.30661 3.4334 4.15831 3.54621C4.17949 3.53033 4.19909 3.51338 4.21816 3.49484C4.09367 4.22893 4.15319 4.98244 4.3914 5.68788C4.62961 6.39332 5.03907 7.02866 5.58311 7.53701C6.12714 8.04536 6.78877 8.41083 7.50872 8.60071C8.22868 8.79058 8.98449 8.79892 9.70846 8.62498L9.69787 8.52435C9.66027 8.13083 9.6412 7.9338 9.69787 7.75107C9.75454 7.56887 9.8806 7.4211 10.1332 7.12609L10.3631 6.85756C11.2513 5.81945 11.6952 5.29987 11.54 4.80147C11.3853 4.30308 10.7339 4.15531 9.43093 3.86082L9.09407 3.78456C8.72385 3.70087 8.53848 3.65903 8.38965 3.54621C8.24135 3.4334 8.14601 3.26232 7.95534 2.92017L7.78214 2.60874C7.11108 1.40539 6.77582 0.803711 6.27424 0.803711C5.77267 0.803711 5.4374 1.40539 4.76634 2.60874Z"
              fill="#001AFF"
            />
          </svg>
        </div>
      </div>
      <div className="flex gap-2 items-center mt-2">
        <div className="rating rating-sm space-x-1">
        {generateStarRating(store.rating)}
        </div>
        <div className="text-[#8C8C8C] text-xs xl:text-base">({store.review_count})</div>
      </div>

      {/* heart and person icon */}
      <div className="flex justify-center md:justify-around w-full items-center mt-3">
        <div className="flex flex-col items-center gap-1">
          <AiOutlineHeart className="text-[#828282] text-xl w-5 h-5 xl:w-7 xl:h-7" />
          <p className="text-xs  xl:text-[18px] text-[#828282]">{formatNumber(store.whishlist)}</p>
        </div>
        <div className="flex flex-col items-center gap-1 ">
          <AiOutlineUser className=" text-[#828282] w-5 h-5 xl:w-7 xl:h-7" />
          <p className="text-xs xl:text-[18px] text-center text-[#828282]">
          {formatNumber(store.follower_count)}
          </p>
        </div>
      </div>
      <Link
        to={`/vendor-shop-front/${store.slug}`}
        className="border-2 border-[#C19A6B] py-[5px] px-[28px] xl:py-[7px] xl:px-[37px] text-nowrap rounded-md bg-white text-[#C19A6B] font-semibold text-sm md:text-[17px] mt-3 text-center"
      >
        Visit Store
      </Link>
    </div>
  );
}
