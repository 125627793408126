import React, { useEffect, useRef, useState } from "react";
import InspirationCategories from "../../../components/inspiration-page-component/inspiration-category/InspirationCategories";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";
import "./inspiration.css";
import InspirationVideosPreview from "../../../components/inspiration-page-component/Inspiration-Video-Preview/InspirationVideosPreview";

import InspirationFilter from "../../../components/inspiration-page-component/inspiration-filter/inspiration-filter-types";
import { IoCloseOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { inspiration, singleCatInspiration } from "../../../store/frontendSlice";
import InspirationVideosCategoryPreview from "../../../components/inspiration-page-component/Inspiration-Video-Preview/InspirationVideosCategoryPreview";
import CategoriesAnimation from "./CategoriesAnimation";
import { useNavigate } from "react-router-dom";

const Inspiration = () => {
  const [isInspirationFilterOpen, setIsInspirationFilterOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState(false);
  const [filterClosing, setFilterClosing] = useState(false);
  const [closing, setClosing] = useState(false);
  const [topOffset, setTopOffset] = useState(145);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);
  const [catScroll, setCatScroll] = useState("")
  const [catSet, setCatSet] = useState(0)
  const [activeCategory, setActiveCategory] = useState("")

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, reels } = useSelector((state) => state.frontend);
  const { token } = useSelector(state => state.category);

  const containerRef = useRef(null);
  const filterRef = useRef(null);
  const fixedDivRef = useRef(null);
  const divRefs = useRef([]);

  /////////////////////////////////// Imp

  const scrollHandler = () => {
      divRefs.current.forEach((divRef, index) => {
        const rect = divRef?.current?.getBoundingClientRect();
        const containerRect = containerRef?.current?.getBoundingClientRect();

        if (rect?.top >= containerRect?.top && rect?.bottom <= containerRect?.bottom) {
          setCatScroll(index)
        }
      });
  };

  useEffect(()=>{
    if(typeof catScroll === 'number') setCatScroll(data[catScroll].category)
  },[catScroll])

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('scroll', scrollHandler);
    return () => {
      container.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  //////////////////////////////////////

  // const smoothScroll = (container, target, duration) => {
  //   const start = container.scrollTop;
  //   const change = target-140 - start;
  //   let currentTime = 0;
  //   const increment = 20;
  
  //   const animateScroll = () => {
  //     currentTime += increment;
  //     const val = easeInOutQuad(currentTime, start, change, duration);
  //     container.scrollTop = val;
  //     if (currentTime < duration) {
  //       window.requestAnimationFrame(animateScroll);
  //     }
  //   };
  //   const easeInOutQuad = (t, b, c, d) => {
  //     t /= d / 2;
  //     if (t < 1) return (c / 2) * t * t + b;
  //     t--;
  //     return (-c / 2) * (t * (t - 2) - 1) + b;
  //   };
  //   animateScroll();
  // };

  // useEffect(() => {
  //   if (containerRef.current) {
  //     const targetElement = document.getElementById(catScroll?.toLowerCase().replace(/\s+/g, "-"));
  //     if (targetElement) {
  //       smoothScroll(containerRef.current, targetElement.offsetTop, 500);
  //     }
  //   }
  // }, [catScroll]);

  useEffect(() => {
    if (fixedDivRef.current) {
      const rect = fixedDivRef.current.getBoundingClientRect();
      setTopOffset(rect.top);
    }
  }, []);

  const handleScroll = () => {
    setTopOffset(145-window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleCategoriesOpen = () => {
    if (isCategoriesOpen) {
      setClosing(true);
      setTimeout(() => {
        setIsCategoriesOpen(false);
        setClosing(false);
      }, 1000);
    } else {
      setIsCategoriesOpen(true);
    }
  };

  const toggleInspirationFilterOpen = () => {
    setIsInspirationFilterOpen(!isInspirationFilterOpen);
  };

  const reelsGet = (a) => {
    setExpandedCategory(true);
    setActiveCategory(a);
    dispatch(singleCatInspiration(a));
  };

  useEffect(() => {
    setSelectedLocation(null)
    setSelectedPrice(null)
    setSelectedSort(null)
    if(isInspirationFilterOpen)setIsInspirationFilterOpen(false);
  },[expandedCategory])

  useEffect(() => {
    if(data[0] && catSet==0){
      setCatScroll(data[0].category)
      setCatSet(1)
    }
    if(data.length>0) divRefs.current = data?.map((_, i) => divRefs.current[i] || React.createRef());
  },[data])

  useEffect(() => {
    if(selectedLocation==null && selectedSort==null && selectedPrice==null)
    dispatch(inspiration())
  },[selectedLocation,selectedSort,selectedPrice])

  const handleUpload = () => {
    if(token){
      navigate("/user-dashboard/affiliate-inReels")
    } else {
      navigate("/dashboard/login")
    }
  }

  return (
    <div className="w-full flex flex-row">

        <div className="hidden md:flex w-[15%]">

          <div className="flex flex-col items-center  gap-12  ">
            <p className="font-semibold text-xl text-start">Inspiration</p>
            <div className=" flex flex-col items-center">
              {/* <img
                src="./assets/images/inspiration/electronics.png"
                alt=""
                className={`w-[53px] h-[53px]  xl:w-24 xl:h-24`}
              />

              <img
                src="./assets/images/inspiration/gaming.png"
                alt=""
                className={`w-[53px] h-[53px]   xl:w-[69px] xl:h-[69px]`}
              /> */}
              <div>
                <img
                  src="./assets/images/inspiration/artwork.png"
                  alt="artwork"
                  className="  max-h-[355px] w-full"
                />
              </div>
            </div>
          </div>

          {/* <CategoriesAnimation containerRef={containerRef}/> */}

        </div>

        <div className={`flex flex-col w-full md:w-[65%]`}>

          {/* //filter */}
          <div className="flex w-full flex-col px-20 lg:px-5">

            {/* //phn filter */}
            {isInspirationFilterOpen && (
              <div
                ref={filterRef}
                className={`flex flex-col md:hidden fixed top-0 right-0 h-screen bg-white z-50 w-3/4 ${
                  filterClosing ? "filter-animate-sm-out" : "filter-animate-sm"
                } `}
              >
                <div className="w-full relative py-4 px-8 flex items-center justify-between  ">
                  <span className="w-full text-center font-bold text-lg">
                    Smart Filter
                  </span>
                  <IoCloseOutline
                    onClick={toggleInspirationFilterOpen}
                    className="absolute right-6 z-10 w-8 h-8 cursor-pointer"
                  />
                </div>
                <div className="py-4 px-8">
                  <InspirationFilter 
                  setSelectedSort={setSelectedSort} 
                  setSelectedPrice={setSelectedPrice} 
                  setSelectedLocation={setSelectedLocation}
                  selectedSort={selectedSort}
                  selectedPrice={selectedPrice}
                  selectedLocation={selectedLocation}
                  activeCategory={activeCategory}
                />
                </div>
              </div>
            )}

            {/* //phn categories/filter head*/}
            <div className="fixed left-0 md:hidden w-full z-30 bg-white px-10 py-2">
              <div className="w-full flex flex-col gap-2">

                <div className="flex items-center justify-between">
                  <p className="font-semibold text-2xl text-start">
                    Inspiration
                  </p>
                  <button onClick={handleUpload} className="cursor-pointer flex justify-center items-center bg-black text-white gap-3 px-6 py-2 font-medium text-xl rounded-full h-8">
                    Upload <span className="font-medium text-xl">+</span>
                  </button>
                </div>

                <div className="w-full flex justify-between items-center">
                  <div
                    onClick={toggleCategoriesOpen}
                    className="flex items-center gap-1 cursor-pointer"
                  >
                    <span className="font-medium text-xl">Category</span>
                    <RiArrowDropDownLine className="w-5 h-5" />
                  </div>
                  <div className="flex items-center gap-4 pr-1">
                    <button
                      onClick={toggleInspirationFilterOpen}
                      className="filter-icon flex justify-center items-center font-semibold text-xl gap-3 w-[110px] h-8 "
                    >
                      Filter{" "}
                      <span>
                        <svg
                          className=""
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.65625 4.87354H18.8402"
                            stroke="black"
                            stroke-width="1.49425"
                            stroke-linecap="round"
                          />
                          <path
                            d="M1.65625 13.0918H18.8402"
                            stroke="black"
                            stroke-width="1.49425"
                            stroke-linecap="round"
                          />
                          <path
                            className="upper-circle"
                            d="M6.88597 7.86178C8.53647 7.86178 9.87447 6.52378 9.87447 4.87327C9.87447 3.22277 8.53647 1.88477 6.88597 1.88477C5.23546 1.88477 3.89746 3.22277 3.89746 4.87327C3.89746 6.52378 5.23546 7.86178 6.88597 7.86178Z"
                            fill="white"
                            stroke="black"
                            stroke-width="1.49425"
                            stroke-linecap="round"
                          />
                          <path
                            className="lower-circle"
                            d="M13.6106 16.0805C15.2611 16.0805 16.5991 14.7425 16.5991 13.092C16.5991 11.4415 15.2611 10.1035 13.6106 10.1035C11.9601 10.1035 10.6221 11.4415 10.6221 13.092C10.6221 14.7425 11.9601 16.0805 13.6106 16.0805Z"
                            fill="white"
                            stroke="black"
                            stroke-width="1.49425"
                            stroke-linecap="round"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>

              </div>
            </div>

            {/* //lap categories/filter head*/}
            <div
              style={{ top: `${topOffset}px` }}
              ref={fixedDivRef} 
              className={`hidden md:flex fixed left-0 z-20 justify-center px-5 whitespace-nowrap w-full`}>
              <div className="flex flex-col bg-white items-end w-[60%] p-2">

                <div className="flex justify-between w-full">

                  <div className="flex items-center gap-2 lg:gap-2">
                    {/* {isInspirationFilterOpen ? (
                      ""
                    ) : (
                      <> */}
                        {selectedSort && (
                          <div className="p-1 bg-[#EDEDED] md:py-[1px] md:px-[6px] lg:py-[2px]  lg:px-[10px] flex items-center rounded-[20px] text-nowrap">
                            <p className="flex  items-center text-[8px] lg:text-xs font-thin  gap-1  ">
                              {selectedSort}
                              <span
                                className="text-[#828282] cursor-pointer relative left-1"
                                onClick={() => setSelectedSort(null)}
                              >
                                <IoIosClose />
                              </span>
                            </p>
                          </div>
                        )}
                        {selectedPrice && (
                          <div className="p-1 bg-[#EDEDED] md:py-[1px] md:px-[6px] lg:py-[2px]  lg:px-[10px] flex items-center rounded-[20px] text-nowrap">
                            <p className="flex  items-center text-[8px] lg:text-xs font-thin gap-1  ">
                              {selectedPrice}
                              <span
                                className="text-[#828282] cursor-pointer relative left-1"
                                onClick={() => setSelectedPrice(null)}
                              >
                                <IoIosClose />
                              </span>
                            </p>
                          </div>
                        )}
                        {selectedLocation && (
                          <div className="p-1 bg-[#EDEDED] md:py-[1px] md:px-[6px] lg:py-[2px]  lg:px-[10px] flex items-center rounded-[20px] text-nowrap ">
                            <p className="flex  items-center text-[8px] lg:text-xs font-thin  gap-1  ">
                              {selectedLocation}
                              <span
                                className="text-[#828282] cursor-pointer relative left-1"
                                onClick={() => setSelectedLocation(null)}
                              >
                                <IoIosClose />
                              </span>
                            </p>
                          </div>
                        )}
                        {selectedSort || selectedPrice || selectedLocation ? (
                          <button
                            className="text-[8px] lg:text-xs text-nowrap"
                            onClick={() => {
                              setSelectedSort(null);
                              setSelectedPrice(null);
                              setSelectedLocation(null);
                            }}
                          >
                            Clear all
                          </button>
                        ) : null}
                      {/* </>
                    )} */}
                  </div>

                  <div className="flex items-center gap-3 lg:gap-4 pr-1">
                    <button
                      onClick={toggleInspirationFilterOpen}
                      className="filter-icon flex justify-center items-center font-semibold text-base lg:text-xl gap-3  "
                    >
                      Filter{" "}
                      <span>
                        <svg
                          className=""
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.65625 4.87354H18.8402"
                            stroke="black"
                            stroke-width="1.49425"
                            stroke-linecap="round"
                          />
                          <path
                            d="M1.65625 13.0918H18.8402"
                            stroke="black"
                            stroke-width="1.49425"
                            stroke-linecap="round"
                          />
                          <path
                            className="upper-circle"
                            d="M6.88597 7.86178C8.53647 7.86178 9.87447 6.52378 9.87447 4.87327C9.87447 3.22277 8.53647 1.88477 6.88597 1.88477C5.23546 1.88477 3.89746 3.22277 3.89746 4.87327C3.89746 6.52378 5.23546 7.86178 6.88597 7.86178Z"
                            fill="white"
                            stroke="black"
                            stroke-width="1.49425"
                            stroke-linecap="round"
                          />
                          <path
                            className="lower-circle"
                            d="M13.6106 16.0805C15.2611 16.0805 16.5991 14.7425 16.5991 13.092C16.5991 11.4415 15.2611 10.1035 13.6106 10.1035C11.9601 10.1035 10.6221 11.4415 10.6221 13.092C10.6221 14.7425 11.9601 16.0805 13.6106 16.0805Z"
                            fill="white"
                            stroke="black"
                            stroke-width="1.49425"
                            stroke-linecap="round"
                          />
                        </svg>
                      </span>
                    </button>
                    <button onClick={handleUpload} className="flex justify-center items-center bg-black text-white gap-3 px-[22.4px] py-[4.48px] font-medium text-base lg:text-xl rounded-full text-nowrap ">
                      Upload +
                    </button>
                  </div>

                </div>

                {/* //filterOpened */}
                <div className={`inspirationFilter w-full hidden md:flex bg-white ${
                    isInspirationFilterOpen ? "inspirationFilterOpen" : ""
                  }`}
                >
                  {isInspirationFilterOpen && (
                    <InspirationFilter
                    setSelectedSort={setSelectedSort}
                    setSelectedPrice={setSelectedPrice}
                    setSelectedLocation={setSelectedLocation}
                    selectedSort={selectedSort}
                    selectedPrice={selectedPrice}
                    selectedLocation={selectedLocation}
                    activeCategory={activeCategory}
                />
                  )}
                </div>

              </div>
            </div>

          </div>

          {/* //reels */}
              <div ref={containerRef} className={` inspirationVideo  ${isInspirationFilterOpen ? "inspirationVideoTransform mb-[300px]" : ""} scrollable-content hide-scrollbar flex flex-col w-full mt-20 md:mt-14 pb-5`}>
                <>
                {expandedCategory?(
                  <> 
                        {reels?.reels?.length>0?
                            <InspirationVideosPreview reels={reels}/>
                        :"No Reel Found"}
                  </>
                ):(
                  <>
                  {data.length>0? data?.map((category,index) => (
                    <section ref={divRefs.current[index]} className="outline-none border-0">
  
                      <InspirationVideosCategoryPreview getFunction={reelsGet} divRefs={divRefs} key={index} category={category}/>
                    </section>
                  )):"No Reel Found"}                  
                  </>
                )}
                </>
              </div>
        </div>

        {/* //categories */}
        {isCategoriesOpen && (
          <div
            // ref={categoriesRef}
            className={`flex flex-col md:hidden fixed top-0 left-0 h-screen bg-white z-50 w-3/4 ${
              closing ? "cat-animate-sm-out" : "cat-animate-sm"
            } `}
          >
            <div className="w-full relative py-4 px-12 flex items-center justify-between ">
              <span className="w-full text-center font-bold text-lg">
                Categories
              </span>
              <IoCloseOutline
                onClick={toggleCategoriesOpen}
                className="absolute right-6 z-10 w-8 h-8 cursor-pointer"
              />
            </div>
            {/* <div className="py-4 px-8"> */}
              <InspirationCategories data={data} setExpandedCategory={setExpandedCategory} catScroll={catScroll} setCatScroll={setCatScroll} />
            {/* </div> */}
          </div>
        )}

        <div className="hidden md:flex w-[20%] pl-5">
          <div className="flex flex-col w-full">
            <p className="font-semibold text-xl relative text-center pr-6">Categories</p>
            <InspirationCategories data={data} setExpandedCategory={setExpandedCategory} catScroll={catScroll} setCatScroll={setCatScroll} />
          </div>
        </div>

    </div>
  );
}

export default Inspiration;
