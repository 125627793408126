import { Link, useLocation } from "react-router-dom";
import BreadCrums from "../../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getMyReview } from "../../../../store/categorySlice";
import "./UserDashboardReviews.css";
import { clearMessage } from "../../../../store/categorySlice";
import { toast } from "react-toastify";

export default function UserDashboardMyReviews() {

  const dispatch = useDispatch();
  const { data,products,message,error } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(getMyReview());
  }, []);

  useEffect(() => {
    if(error){
      toast.error(error);
    }
    if(message){
      toast.success(message);
    }
    dispatch(clearMessage());
  }, [message,error]);

  const location = useLocation();
  return (
    <div className=" w-full flex flex-col px-5 gap-2">
      <BreadCrums
        breadCrum={[
          {
            name: "My Reviews",
            // path: "/dashboard/my-reviews",
          },
        ]}
      />

      {/* reviews button  */}
      <div className="flex gap-2 xl:gap-3">
        <div
          className={`flex items-center gap-1 md:gap-2 text-[#828282] hover:text-[#C19A6B] ${
            location.pathname.startsWith("/user-dashboard/reviews")
              ? "text-[#C19A6B]"
              : ""
          }`}
        >
          {" "}
          <Link
            to="/user-dashboard/reviews"
            className="font-semibold text-[8px]  md:text-sm "
          >
            HISTORY
          </Link>
          <span className="flex items-center justify-center p-[2px] md:p-1  border-[0.75px]  rounded-[5.75px]">
            <span className="text-[10.56px] ">({data?.History})</span>
          </span>
        </div>

        <div
          className={`flex items-center gap-1 md:gap-2  hover:text-[#C19A6B] ${
            location.pathname.startsWith("/user-dashboard/my-reviews")
              ? "text-[#C19A6B]"
              : "text-[#828282]"
          }`}
        >
          <Link
            to="/user-dashboard/my-reviews"
            className="font-semibold text-[8px]  md:text-sm "
          >
            MY REVIEWS
          </Link>
          <span className="flex items-center justify-center p-[2px] md:p-1  border-[0.75px]  rounded-[5.75px]">
            <span className="text-[10.56px]">({data?.myReview})</span>
          </span>
        </div>
      </div>

      {data?.myReview && data?.data?.length>0?
        data.data.map((a) => (
          <div key={a.id}
            className="flex flex-col  w-full mt-3 px-5 py-3 rounded-[10px] shadow-md ">
            <div className="flex flex-col md:flex-row justify-between items-center w-full gap-8">
              <div className="flex flex-col md:flex-row w-full gap-3 items-start">
                {/* product image  */}
                <div className="flex items-center gap-2">
                  <img
                    src={a.product_image}
                    alt=""
                    className="w-11 h-11 md:w-14 md:h-14 lg:w-[94px] lg:h-[94px] rounded-[5px]"
                  />

                  {/* for mobile  */}
                  <div>
                    <div className="block md:hidden text-xs  ">
                      {a.product_name}
                    </div>
                    <div className="font-semibold text-xs block md:hidden">
                      {a.category_name}
                    </div>
                  </div>
                </div>

                {/* product details  */}
                <div className="flex flex-col gap-1 w-full pl-12 md:pl-0">
                  <div className="hidden md:block text-base">
                  {a.product_name}
                  </div>

                  <div className="font-semibold text-sm hidden md:block">
                  {a.category_name}
                  </div>

                  <div className="rating rating-md space-x-1">
                    {a.rating && ["1", "2", "3", "4", "5"].map((value) => (
                      <div
                        className={`star ${a.rating >= value ? 'star-checked' : ''}`}
                      ></div>
                    ))}
                  </div>

                  <div className="">
                    <p className="text-[10px] md:text-sm">
                      {a.review}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center w-full md:w-80 md:pr-4 gap-1">
                {a.rating_images.map((a) => (
                  <img
                    src={a}
                    alt=""
                    className="w-[58px] h-[58px] md:w-12 md:h-12 lg:w-[58px] lg:h-[58px]"
                  />
                ))}
              </div>
            </div>
          </div>
        ))
      :("No Reviews")}


    </div>
  );
}
