import React, { useEffect, useState } from "react";
import "./shopfront.css";
import { useDispatch } from "react-redux";
import { follow } from "../../store/frontendSlice";
import {formatNumber, stringConcat} from "../../functions/formatNumber";
import { toast } from "react-toastify";

const generateStarRating = (rating) => {
  const stars = [];
  const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
  const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <img key={i} src={filledStar} alt="filled star" className="w-3 h-3 sm:w-4 sm:h-4" />
      );
    } else {
      stars.push(
        <img
          key={i}
          src={unfilledStar}
          alt="unfilled star"
          className="w-3 h-3 sm:w-4 sm:h-4"
        />
      );
    }
  }
  return stars;
};
const AffiliateUserProfileSection = ({data}) => {
  const [minimized, setMinimized] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 405) {
        setMinimized(true);
      } else {
        setMinimized(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const dispatch=useDispatch()

  const follows = () => {
    dispatch(follow({user_id:data?.user?.id}))
  }

  // const copyToClipboard = async () => {
    // try {
    //   // Use the Clipboard API to write the text
    //   await navigator.clipboard.writeText(window.location.href);
    //   toast.success('URL copied to clipboard!');
    // } catch (err) {
    //   console.error('Failed to copy: ', err);
    //   toast.error('Failed to copy URL.');
    // }
  // };

  // function copyURLToClipboard() {
  const copyToClipboard = async () => {
      const url = window.location.href;

    if (navigator.clipboard && navigator.clipboard.writeText) {
        // Use the Clipboard API if available
        navigator.clipboard.writeText(url)
            .then(() => {
                console.log('URL copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy URL: ', err);
            });
    } else {
        // Fallback method for older browsers
        const textArea = document.createElement('textarea');
        textArea.value = url;
        textArea.style.position = 'fixed';  // Prevent scrolling to bottom
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
            toast.success('URL copied to clipboard!');
        } catch (err) {
          toast.error('Failed to copy URL: ', err);
        }

        document.body.removeChild(textArea);
    }
  }


  return (
    <>
      <div className="w-full">
        {minimized && (
          <div
            className={`hidden md:block fixed mt-16  top-0 w-full z-50  shadow-lg text-center ${
              minimized ? "minimized-mini " : "expanded-mini"
            }  `}
          >
            <div className="flex px-[57px] bg-white justify-between items-center py-4 ">
              <div className="flex gap-4 items-center">
                <img
                  src={data?.user?.image}
                  className=" w-16 h-16 "
                  alt=""
                />
                <div>
                  <div className="flex items-center gap-2">
                    <h1 className="font-bold text-xl">{data?.user?.name}</h1>
                    <span>
                      <img src="/assets/icons/verify.svg" width={18} alt="" />
                    </span>
                  </div>
                  <div className="flex">
                   {generateStarRating(data?.user?.total_rating)}
                    <span>({data?.user?.total_reviews})</span>
                  </div>
                </div>
                <div className="flex gap-4 ml-3 items-center">
                  <div className="flex flex-col items-center">
                    <img
                      src="/assets/icons/heartN.svg"
                      className="text-black"
                      alt=""
                    />
                    <p>{formatNumber(data?.user?.whishlistCount)}</p>
                  </div>
                  <div className="flex flex-col items-center">
                    <img src="/assets/icons/Person.svg" alt="" />
                    <p>{formatNumber(data?.user?.followes)}</p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center gap-8">
                {data?.user?.followed == 0?
                  <button onClick={(e)=>follows()} className="border rounded-md  transition-all ease-in-out right-0  hover:bg-[#C19A6B] hover:text-white border-[#C19A6B] w-[200px] h-[41px]   text-[#C19A6B]">
                    Follow <span className="ml-3">+</span>
                  </button>
                  :
                  <button onClick={(e)=>follows()} className="border rounded-md  transition-all ease-in-out right-0  hover:bg-[#C19A6B] hover:text-white border-[#C19A6B] w-[200px] h-[41px]   text-[#C19A6B]">
                    Unfollow
                  </button>
                }
                <div class="flex gap-3 items-center">
                  <div class="group relative flex flex-col items-center">
                    <img
                      src="/assets/icons/insta.svg"
                      alt=""
                      class="h-8 w-8"
                    />
                    <span class="absolute  opacity-0 bg-black text-white text-xs rounded py-1 px-2  z-10 group-hover:opacity-100 top-full mt-2 ml-16 transform -translate-x-1/2 transition-opacity duration-500 ease-in-out">
                      @{data?.user?.instagram}
                    </span>
                  </div>

                  <div class="group relative flex flex-col items-center">
                    <img
                      src="/assets/icons/tiktok.svg"
                      alt=""
                      class="h-8 w-8"
                    />
                    <span class="opacity-0 bg-black text-white text-xs rounded py-1 px-2 absolute z-10 group-hover:opacity-100 top-full mt-2 ml-16 transform -translate-x-1/2 transition-opacity duration-500 ease-in-out">
                      @{data?.user?.tiktok}
                    </span>
                  </div>

                  <div class="group relative flex flex-col items-center">
                    <img src="/assets/icons/X.svg" alt="" class="h-8 w-8" />
                    <span class="opacity-0 bg-black text-white text-xs rounded py-1 px-2 absolute z-10 group-hover:opacity-100 top-full mt-2 ml-16 transform -translate-x-1/2 transition-opacity duration-500 ease-in-out">
                      @{data?.user?.twitter}
                    </span>
                  </div>
                </div>
                <div onClick={copyToClipboard} className=" items-center shadow-xl  h-12 w-12 bg-white rounded-full cursor-pointer">
                  <img
                    src="/assets/icons/share.svg"
                    className="h-12 w-12 text-center p-2"
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* <hr className="" /> */}
            {/* <div className="opacity-0 h-0 flex gap-4 py-4 items-center justify-center">
              <button className=" text-xl border bg-[#C19A6B] text-white border-[#C19A6B] px-6 rounded-md">
                All
              </button> */}
              {/* <button className="text-[#C19A6B] text-xl border border-[#C19A6B] px-6 rounded-md">
                Bundels
              </button>
              <button className="text-[#C19A6B] text-xl border border-[#C19A6B] px-6 rounded-md">
                InReels
              </button>
              <button className="text-[#C19A6B] text-xl border border-[#C19A6B] px-6 rounded-md">
                Products
              </button> */}
            {/* </div> */}
          </div>
        )}
        <div>
          <div
            style={{
              backgroundImage:
                `url("/assets/images/shop-front/Rectangle 698.png")`,
              height: "250px",
              width: "100%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              // backgroundSize: "cover",
            }}
            class={` bg-cover px-4 xl:px-[57px] flex flex-col items-center bg-center w-full h-16`}
          >
            <div className="w-full flex justify-end items-center">
              <div onClick={copyToClipboard} className="float-right mt-2 py-2 px-2 md:px-3 bg-white rounded-full cursor-pointer">
                <img
                    src="/assets/icons/share.svg"
                    className="w-[9px] h-[11px] md:w-[14px] md:h-[17px] xl:w-[20px] xl:h-[24px]"
                    alt=""
                  />
              </div>
            </div>

            <div className="relative container flex flex-col gap-3  justify-between pt-20 w-full">
              <div className="relative flex justify-between w-full">
                <div className="flex justify-start items-center md:pt-10 xl:pt-0 w-full"> 
                  <img
                    src={data?.user?.image}
                    className="max-w-[125px] max-h-[125px] sm:max-w-[140px] sm:max-h-[140px] md:max-w-[186px] md:max-h-[186px] xl:max-w-[250px] xl:max-h-[250px]  mt-20 md:mt-0"
                    alt=""
                  />
                  <div className="ml-4 flex flex-col h-full justify-end gap-1 lg:gap-2 w-full overflow-hidden">
                    <div className="flex  gap-6 items-center  ">
                      <div className="flex items-center gap-2">
                        <h1 className="font-bold text-base whitespace-nowrap sm:text-2xl xl:text-3xlxl">
                        {stringConcat(data?.user?.name,13)}
                        </h1>
                        <span>
                          <img
                            src="/assets/icons/verify.svg"
                            className="w-[18px] xl:w-[25px]"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="flex gap-4 ml-3 items-center">
                        <div className="flex flex-col items-center">
                          <img
                            src="/assets/icons/heartN.svg"
                            className="text-black w-4 h-4 md:w-5 md:h-5"
                            alt=""
                          />
                          <p className="text-xs xl:text-[15px]">{formatNumber(data?.user?.whishlistCount)}</p>
                        </div>
                        <div className="flex flex-col items-center">
                          <img
                            src="/assets/icons/Person.svg"
                            alt=""
                            className="w-4 h-4 md:w-5 md:h-5"
                          />
                          <p className="text-xs xl:text-[15px]">{formatNumber(data?.user?.followes)}</p>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center gap-2  w-fit">
                      {generateStarRating(data?.user?.total_rating)}{" "}
                      <span className="text-[#828282] text-[13px] xl:text-[18px]">
                        ({formatNumber(data?.user?.total_reviews)})
                      </span>
                    </div>
                    <div class="flex gap-3 items-center">
                      <div class="group cursor-pointer flex flex-col items-center">
                        <img
                          src="/assets/icons/insta.svg"
                          alt=""
                          class="max-h-4 max-w-4 md:max-h-7 md:max-w-7 xl:h-10 xl:w-10"
                        />
                        <span class="opacity-0 bg-black text-white text-xs rounded py-1 px-2 absolute z-10 group-hover:opacity-100 top-full mt-2 ml-4 transform -translate-x-1/2 transition-opacity duration-500 ease-in-out">
                          {data?.user?.instagram? ("@"+data?.user?.instagram):"No link"}
                        </span>
                      </div>

                      <div class="group cursor-pointer flex flex-col items-center">
                        <img
                          src="/assets/icons/tiktok.svg"
                          alt=""
                          class="max-h-4 max-w-4 md:max-h-7 md:max-w-7 xl:h-10 xl:w-10"
                        />
                        <span class="opacity-0 bg-black text-white text-xs rounded py-1 px-2 absolute z-10 group-hover:opacity-100 top-full mt-2 ml-4 transform -translate-x-1/2 transition-opacity duration-500 ease-in-out">
                          {data?.user?.tiktok? ("@"+data?.user?.tiktok):"No link"}
                        </span>
                      </div>

                      <div class="group cursor-pointer flex flex-col items-center">
                        <img
                          src="/assets/icons/X.svg"
                          alt=""
                          class="max-h-4 max-w-4 md:max-h-7 md:max-w-7 xl:h-10 xl:w-10"
                        />
                        <span class="opacity-0 bg-black text-white text-xs rounded py-1 px-2 absolute z-10 group-hover:opacity-100 top-full mt-2 ml-4 transform -translate-x-1/2 transition-opacity duration-500 ease-in-out">
                          {data?.user?.twitter? ("@"+data?.user?.twitter):"No link"}                          
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hidden mt-[4.5rem]  items-center text-center md:flex flex-col gap-6">
                  <div className=" flex opacity-0 justify-between gap-4 xl:gap-8">
                    <img
                      src="/assets/images/shop-front/UserDp.png"
                      className="max-h-12 max-w-12 xl:w-16 xl:h-16 hotspot-next-button rounded-full"
                      alt=""
                    />
                    <img
                      src="/assets/images/shop-front/UserDp.png"
                      className="max-h-12 max-w-12 xl:w-16 xl:h-16 hotspot-next-button rounded-full"
                      alt=""
                    />
                    <img
                      src="/assets/images/shop-front/UserDp.png"
                      className="max-h-12 max-w-12 xl:w-16 xl:h-16 hotspot-next-button rounded-full"
                      alt=""
                    />
                  </div>

                  {data?.user?.followed == 0?
                    <button onClick={ (e)=>follows()} className="border rounded-md  transition-all ease-in-out   hover:bg-[#C19A6B] hover:text-white border-[#C19A6B] py-[4.4px] px-10 text-nowrap text-center text-sm xl:text-lg font-medium  text-[#C19A6B]">
                      Follow <span className="ml-3 text-sm xl:text-lg">+</span>
                    </button>
                    :
                    <button onClick={ (e)=>follows()} className="border rounded-md  transition-all ease-in-out   hover:bg-[#C19A6B] hover:text-white border-[#C19A6B] py-[4.4px] px-10 text-nowrap text-center text-sm xl:text-lg font-medium  text-[#C19A6B]">
                      Unfollow
                    </button>
                  }
                </div>
              </div>

              {data?.user?.followed == 0?
                <button onClick={(e)=>follows()} className="md:hidden border rounded-md  transition-all ease-in-out   hover:bg-[#C19A6B] hover:text-white border-[#C19A6B] py-[4.4px] px-10 text-center text-sm xl:text-lg font-medium  text-[#C19A6B]">
                  Follow <span className="ml-3 text-sm xl:text-lg">+</span>
                </button>
                :
                <button onClick={(e)=>follows()} className="md:hidden border rounded-md  transition-all ease-in-out   hover:bg-[#C19A6B] hover:text-white border-[#C19A6B] py-[4.4px] px-10 text-center text-sm xl:text-lg font-medium  text-[#C19A6B]">
                  Unfollow
                </button>
              }

              <div className="absolute right-0 top-44 flex md:hidden justify-between gap-4">
                <img
                  src="/assets/images/shop-front/UserDp.png"
                  className="w-8 hotspot-next-button rounded-full"
                  alt=""
                />
                <img
                  src="/assets/images/shop-front/UserDp.png"
                  className="w-8 hotspot-next-button rounded-full"
                  alt=""
                />
                <img
                  src="/assets/images/shop-front/UserDp.png"
                  className="w-8 hotspot-next-button rounded-full"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="px-4 xl:px-[57px] opacity-0 flex gap-2 md:gap-4 mt-28 md:mt-36 ">
            <button className=" text-xl border bg-[#C19A6B] text-white border-[#C19A6B] py-[5px] px-6 rounded-md">
              All
            </button>
          </div>

        </div>
      </div>
    </>
  );
};

export default AffiliateUserProfileSection;
