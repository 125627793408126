import React, { useEffect, useState } from "react";
import ShelfCard from "./ShelfCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./shelf.css";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";

export default function ShelfSection({data}) {
  const slider = React.useRef(null);

  const [shelfProducts, setShelfProducts] = useState([]);
  const dispatch = useDispatch();

  // setting
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <IoIosArrowRoundForward />,
    prevArrow: <IoIosArrowRoundBack />,
  };

  return (
    <>
      <div className={` relative px-4 lg:px-9 w-full `}>
        <div
          className="shelf-section"
          style={{
            backgroundImage:
              'url("/assets/images/shelf-section/shelf-bg-2.png")',
            // width: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            position: "relative",
          }}
        >
          <div className="w-full">
            <Slider ref={slider} {...settings} className="w-full flex justify-start">
              {data?.products?.length>0? data.products.slice(0, 1).map((item) => (
                <ShelfCard key={item.id} item={item} />
              )):<>No Premium Product Found</>}
            </Slider>
          </div>
          {/* Angle button */}
        </div>
      </div>
    </>
  );
}
