import React from "react";
import { Link } from "react-router-dom";
import { BannerDash, mallDash, vendorDash } from "../../functions/URLString";

export default function Footer() {
  return (
    <>
      <div className="flex flex-col bg-white z-50 border-t mt-24">
        <div className="px-10 md:px-5  lg:px-[100px] py-[70px] bg-white flex flex-col gap-6 md:gap-4 md:flex  md:flex-row items-start justify-between border-b border-[#DCDCDC]">
          <img
            className="cursor-pointer max-w-[123px] max-h-[50px]"
            src="/assets/icons/userdashboard/wizalot.svg"
            alt=""
          />
          <div className="flex flex-col gap-6">
            <h2 className="text-[#75BFB3] text-[24px] md:text-lg lg:text-[20px] font-semibold">
              Wizalot
            </h2>
            <div className="flex flex-col gap-2  text-[#828282] text-lg md:text-sm xl:text-lg">
              <Link to="/" className="cursor-pointer">
                Main Shop Page
              </Link>
              <Link to="/my-shelf" className="cursor-pointer">
                My Shelf
              </Link>
              <Link to="/inspiration" className="cursor-pointer">
                Insipration
              </Link>
              <Link to="/Ad-martket-place" className="cursor-pointer">
                Ad-Marketplace
              </Link>
              <Link to="/Ad-market-place/auction" className="cursor-pointer">
                Auction
              </Link>
              <Link to="/store-square" className="cursor-pointer">
                Store Square
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-6">
            <h2 className="text-[#75BFB3] text-[24px] md:text-lg lg:text-[20px]  font-semibold">
              Useful Links
            </h2>
            <div className="flex flex-col gap-2  text-[#828282] text-lg md:text-sm xl:text-lg">
              <Link to="/user-dashboard" className="cursor-pointer">
                User Dashboard
              </Link>
              <Link to={`${vendorDash}`} className="cursor-pointer">Vendor Dashboard</Link>
              <Link to={`${BannerDash}`} className="cursor-pointer">Banner Advertisement</Link>
              <Link to={`${mallDash}`} className="cursor-pointer">Mall Registeration</Link>
            </div>
          </div>{" "}
          <div className="flex flex-col gap-6">
            <h2 className="text-[#75BFB3] text-[24px] md:text-lg lg:text-[20px]  font-semibold">
              Make Money With Us
            </h2>
            <div className="flex flex-col gap-2  text-[#828282] text-lg md:text-sm xl:text-lg">
              <Link to={`${vendorDash}vendor/profile-creation`} className="cursor-pointer">Become a Vendor</Link>
              <Link to={``} className="cursor-pointer">Affiliate Program</Link>
            </div>
          </div>{" "}
          <div className="flex flex-col gap-6">
            <h2 className="text-[#75BFB3] text-[24px] md:text-lg lg:text-[20px]  font-semibold">
              Follow Us
            </h2>
            <div className="flex items-center gap-5 md:gap-3 lg:gap-4">
              <img
                className="cursor-pointer md:w-5 lg:w-7"
                src="/assets/icons/insta.svg"
                alt=""
              />
              <img
                className="cursor-pointer md:w-5 lg:w-7"
                src="/assets/icons/tiktok.svg"
                alt=""
              />
              <img
                className="cursor-pointer md:w-5 lg:w-7"
                src="/assets/icons/X.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-[#828282] font-normal text-sm text-center py-6">
            Design and Developed by Bingtechs
          </h2>
        </div>
      </div>
    </>
  );
}
