import React, { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import CartSidebar from "../Cart-Sidebar/CartSidebar";
import { useDispatch, useSelector } from "react-redux";
import { getCartProducts, getSessionToken } from "../../store/frontendSlice";
// import { Link } from 'react-router-dom'

export default function Navbar({ profileData ,showContent}) {
  const [cart, setCart] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const sessionToken = localStorage.getItem("sessionToken");
    if (!sessionToken) {
      dispatch(getSessionToken());
    }
  }, []);

  const { cartItems } = useSelector((state) => state.frontend);

  const isLogin = localStorage.getItem("token");

  // useEffect(() => {
  //   dispatch(getCartProducts());
  // }, []);

  
  return showContent ?
    (
    <>
    <div className="fixed top-0 left-0 right-0 px-4 md:px-8 lg:px-[57px] bg-white z-30">
      <div className="flex justify-between items-center mt-2 relative">
        {/* logo */}
        <Link to="/" className="flex w-1/4">
          <img
            src="/assets/images/navbar/Main-logo-nobg.png"
            alt="Logo"
            className="h-6 sm:h-8 w-auto"
          />
        </Link>

        {/* main route */}
        <div className="flex items-center justify-center main-navbar-shadow bg-[#FCFCFC] rounded-full p-1">
          <div
            className={`${
              location.pathname === "/" ? "bg-white" : ""
            } font-bold px-2 md:px-4 py-1 md:py-2 rounded-full hover:bg-white`}
          >
            <Link to="/" className="flex items-center gap-2 text-sm">
              <img
                className="h-6 w-6 md:h-7 md:w-9"
                src="/assets/images/navbar/main-shop-icon.png"
                alt="Main Shop Icon"
              />
              <div className="hidden lg:block hover:block duration-700">
                {/* The Main Shop */}
                For you
              </div>
            </Link>
          </div>

          <div
            className={`${
              location.pathname.includes("/Ad-market-place") ? "bg-white" : ""
            } px-2 md:px-4 py-1 md:py-2 hover:bg-white rounded-full`}
          >
            <Link
              to="/Ad-market-place"
              className="flex gap-2 font-bold text-sm items-center"
            >
              <img
                className="h-5 w-5 md:h-6 md:w-7"
                src="/assets/images/navbar/add-market-icon.png"
                alt="Ad Marketplace Icon"
              />
              <div className="hidden lg:block hover:block duration-700">
                Ad Marketplace
              </div>
            </Link>
          </div>

          <div
            className={`${
              location.pathname.includes("/store-square") ? "bg-white" : ""
            } px-2 md:px-4 py-1 md:py-2 hover:bg-white rounded-full`}
          >
            <Link
              to="/store-square"
              className="flex items-center gap-2 font-bold text-sm"
            >
              <img
                className="h-5 w-5 md:h-6 md:w-8"
                src="/assets/images/navbar/multivendor-icon.png"
                alt="Multivendor Shops Icon"
              />
              <div className="hidden lg:block hover:block duration-700">
                Store Square
              </div>
            </Link>
          </div>
        </div>

        {/* cart + profile section */}
        <div className="flex w-1/4 gap-2 md:gap-3 justify-end items-center">

          {isLogin ? (
            <div className="rounded-lg">
              <Link to="/user-dashboard">
                <img
                  className="min-w-7 h-7 sm:min-w-8 sm:h-8 md:w-10 md:h-10 rounded-full"
                  src={
                    profileData?.image
                      ? profileData.image
                      : "/assets/images/navbar/profile-icon-2.png"
                  }
                  alt="Profile Icon"
                />
              </Link>
            </div>
          ) : (
            <Link to={`/dashboard/login`}>
              <div className="tracking-wider text-sm md:text-lg px-4 md:px-7 py-1 rounded-full text-white bg-gradient-to-r from-[#73beb3] via-[#9379b4] via-75% to-[#f7c234]">
                Login
              </div>
            </Link>
          )}

          {location.pathname.toLowerCase().includes(
            "ad-market-place/auction"
          ) ? (
            <Link
              to={`/user-dashboard/auction-product/create-product`}
              className="px-2 md:px-3 py-1 bg-[#9279B2] whitespace-nowrap text-[11px] sm:text-sm md:text-lg text-white rounded-full cursor-pointer"
            >
              + New Auction
            </Link>
          ) : location.pathname.toLowerCase().includes("ad-market-place") ? (
            <Link
              to={`/user-dashboard/ad-market-place/add-product`}
              className="px-3 md:px-[1.3rem] py-1 bg-[#9279B2] whitespace-nowrap text-xs sm:text-sm md:text-lg text-white rounded-full cursor-pointer"
            >
              + New Ad
            </Link>
          ) : isLogin &&
            cartItems?.flatMap((store) => store.products)?.length > 0 ? (
            <button
              onClick={() => setCart(!cart)}
              className="w-9 h-9 md:w-11 md:h-11 bg-neutral-50 rounded-lg shadow-inner p-1 flex flex-wrap justify-center gap-1"
            >
              {cartItems?.flatMap((store) => store.products)?.slice(0, 3)?.map((product, index) => (
                <img
                  key={index}
                  className="w-[12px] h-[12px] md:w-[15.09px] md:h-[15.09px] rounded"
                  src={product.product_picture || "/path/to/dummy/image.png"}
                  alt="cart-item"
                />
              ))}

              {cartItems?.flatMap((store) => store.products)?.length > 3 && (
                <div className="w-[12px] h-[12px] md:w-[15.09px] md:h-[15.09px] bg-orange-600 rounded-full shadow flex justify-center items-center text-white text-[8px] md:text-[10px] font-medium font-['Roboto']">
                  +
                  {cartItems?.flatMap((store) => store.products)?.length - 3}
                </div>
              )}
            </button>
          ) : null}

        </div>
      </div>
    </div>
      <CartSidebar cart={cart} setCart={setCart} cartItems={cartItems} />
    </>
  ):(<><div className="fixed top-0 left-0 right-0 px-4 md:px-8 lg:px-[57px] bg-white bg-opacity-0 z-30">
    <div className="flex justify-between items-center mt-2 relative">
      {/* logo */}
      <Link to="/" className="flex w-1/4">
        <img
          src="/assets/images/navbar/Main-logo-nobg.png"
          alt="Logo"
          className="h-6 sm:h-8 w-auto"
        />
      </Link>
  
      {/* main route */}
      <div className="flex items-center justify-center main-navbar-shadow bg-[#FCFCFC] bg-opacity-50 rounded-full p-1">
        <div
          className={`${
            location.pathname === "/" ? "bg-white bg-opacity-50" : ""
          } font-bold px-2 md:px-4 py-1 md:py-2 rounded-full hover:bg-white hover:bg-opacity-50`}
        >
          <Link to="/" className="flex items-center gap-2 text-sm">
            <img
              className="h-6 w-6 md:h-7 md:w-9"
              src="/assets/images/navbar/main-shop-icon.png"
              alt="Main Shop Icon"
            />
            <div className="hidden lg:block hover:block duration-700">
              For you
            </div>
          </Link>
        </div>
  
        <div
          className={`${
            location.pathname.includes("/Ad-market-place") ? "bg-white bg-opacity-50" : ""
          } px-2 md:px-4 py-1 md:py-2 hover:bg-white hover:bg-opacity-50 rounded-full`}
        >
          <Link
            to="/Ad-market-place"
            className="flex gap-2 font-bold text-sm items-center"
          >
            <img
              className="h-5 w-5 md:h-6 md:w-7"
              src="/assets/images/navbar/add-market-icon.png"
              alt="Ad Marketplace Icon"
            />
            <div className="hidden lg:block hover:block duration-700">
              Ad Marketplace
            </div>
          </Link>
        </div>
  
        <div
          className={`${
            location.pathname.includes("/store-square") ? "bg-white bg-opacity-50" : ""
          } px-2 md:px-4 py-1 md:py-2 hover:bg-white hover:bg-opacity-50 rounded-full`}
        >
          <Link
            to="/store-square"
            className="flex items-center gap-2 font-bold text-sm"
          >
            <img
              className="h-5 w-5 md:h-6 md:w-8"
              src="/assets/images/navbar/multivendor-icon.png"
              alt="Multivendor Shops Icon"
            />
            <div className="hidden lg:block hover:block duration-700">
              Store Square
            </div>
          </Link>
        </div>
      </div>
  
      {/* cart + profile section */}
      <div className="flex w-1/4 gap-2 md:gap-3 justify-end items-center">
        {location.pathname.toLowerCase().includes("ad-market-place/auction") ? (
          <Link
            to={`/user-dashboard/auction-product/create-product`}
            className="px-2 md:px-3 py-1 bg-[#9279B2] whitespace-nowrap text-[11px] sm:text-sm md:text-lg text-white rounded-full cursor-pointer"
          >
            + New Auction
          </Link>
        ) : location.pathname.toLowerCase().includes("ad-market-place") ? (
          <Link
            to={`/user-dashboard/ad-market-place/add-product`}
            className="px-3 md:px-[1.3rem] py-1 bg-[#9279B2] whitespace-nowrap text-xs sm:text-sm md:text-lg text-white rounded-full cursor-pointer"
          >
            + New Ad
          </Link>
        ) : isLogin &&
          cartItems?.flatMap((store) => store.products)?.length > 0 ? (
          <button
            onClick={() => setCart(!cart)}
            className="w-9 h-9 md:w-11 md:h-11 bg-neutral-50 bg-opacity-50 rounded-lg shadow-inner p-1 flex flex-wrap justify-center gap-1"
          >
            {cartItems?.flatMap((store) => store.products)?.slice(0, 3)?.map((product, index) => (
              <img
                key={index}
                className="w-[12px] h-[12px] md:w-[15.09px] md:h-[15.09px] rounded"
                src={product.product_picture || "/path/to/dummy/image.png"}
                alt="cart-item"
              />
            ))}
  
            {cartItems?.flatMap((store) => store.products)?.length > 3 && (
              <div className="w-[12px] h-[12px] md:w-[15.09px] md:h-[15.09px] bg-orange-600 rounded-full shadow flex justify-center items-center text-white text-[8px] md:text-[10px] font-medium font-['Roboto']">
                +{cartItems?.flatMap((store) => store.products)?.length - 3}
              </div>
            )}
          </button>
        ) : null}
  
        {isLogin ? (
          <div className="rounded-lg">
            <Link to="/user-dashboard">
              <img
                className="min-w-7 h-7 sm:min-w-8 sm:h-8 md:w-10 md:h-10 rounded-full"
                src={
                  profileData?.image
                    ? profileData.image
                    : "/assets/images/navbar/profile-icon-2.png"
                }
                alt="Profile Icon"
              />
            </Link>
          </div>
        ) : (
          <Link to={`/dashboard/login`}>
            <div className="tracking-wider text-sm md:text-lg px-4 md:px-7 py-1 rounded-full text-white bg-gradient-to-r from-[#73beb3] via-[#9379b4] via-75% to-[#f7c234]">
              Login
            </div>
          </Link>
        )}
      </div>
    </div>
  </div>
  <CartSidebar cart={cart} setCart={setCart} cartItems={cartItems} />
  </>);
};
