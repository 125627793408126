import React, { useEffect, useState } from "react";
import BreadCrums from "../../Common/BreadCrums";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import { toast } from "react-toastify";
import {
  addProduct,
  adStoreCategories,
  clearMessage,
  getBrands,
  getCitiesByCountries,
  getCitiesByState,
  getCountrie,
  getStateByCountry,
} from "../../../store/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function UserDashboardAdMarketplaceAddProduct() {
  const navigate = useNavigate();
  const [productCategory, setProductCategory] = useState("");
  const {
    data,
    error,
    message,
    citiesByState,
    states,
    countries,
    brands,
    adStoreCat,
    loading,
  } = useSelector((state) => state.category);
  const [selectBrands, setSelectBrands] = useState("");
  const [productTags, setProductTags] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [isAffiliate, setIsAffiliate] = useState(null);
  const [inputFields, setInputFields] = useState([
    { attribute: "", price: "" },
  ]);
  const [payload, setPayload] = useState({
    name: "",
    description: "",
    // state: "",
    // model: "",
    brand_id: selectBrands,
    category_id: productCategory,
    tag: [],
    short_description: "",
    cover_image: null,
    images: [],
    price: 0,
    area: "",
    meta_data: [],
  });
  const dispatch = useDispatch();
  // const handleAffiliateChange = (event) => {
  //   setIsAffiliate(event.target.value === "yes" ? "yes" : "no");
  //   setPayload({ ...payload, affiliate: isAffiliate });
  // };

  const [coverImage, setCoverImage] = useState(null);
  const [images, setImages] = useState(Array(5).fill(null));
  const labels = ["Image 1", "Image 2", "Image 3", "Image 4", "Image 5"];

  const handleInputChange = (index, event, name) => {
    if (name === "attribute") {
      const newInputFields = [...inputFields];
      newInputFields[index].attribute = event.target.value;
      setInputFields(newInputFields);
    } else {
      const newInputFields = [...inputFields];
      newInputFields[index].price = event.target.value;
      setInputFields(newInputFields);
    }
    setPayload({ ...payload, meta_data: inputFields });
    // console.log(payload);
  };
  const handleAddField = () => {
    setInputFields([...inputFields, { attribute: "", price: "" }]);
  };
  const handleRemoveField = (index) => {
    const updatedFields = inputFields.filter((_, i) => i !== index);
    setInputFields(updatedFields);
    setPayload({ ...payload, meta_data: updatedFields });
  };

  // const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  // const toggleDropdown1 = () => setDropdownOpen1(!dropdownOpen1);

  const updateImage = (index, file) => {
    const newImageArray = [...payload.images];
    newImageArray[index] = file;
    setPayload({
      ...payload,
      images: newImageArray,
    });
  };

  const handleImageUpload = (index, event) => {
    if (index === "cover") {
      const file = event.target.files[0];
      if (file) {
        setPayload({ ...payload, cover_image: file });
        const reader = new FileReader();
        reader.onload = () => {
          setCoverImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      const file = event.target.files[0];
      if (file) {
        // payload.images.push(file)
        updateImage(index, file);
        const reader = new FileReader();
        reader.onload = () => {
          const newImages = [...images];
          newImages[index] = reader.result;
          setImages(newImages);
          // updateImage(index, reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleDeleteImage = (index) => {
    if (index === "index") {
      setCoverImage(null);
      setPayload({ ...payload, cover_image: null });
    } else {
      updateImage(index, null);
      const newImages = [...images];
      newImages[index] = null;
      setImages(newImages);
    }
  };

  const handleCategorySelect = (category) => {
    setProductCategory(category);
    setDropdownOpen(false);
    setPayload({ ...payload, category_id: category });
  };
  const handleBrandSelect = (brand) => {
    setSelectBrands(brand);
    setDropdownOpen1(false);
    setPayload({ ...payload, brand_id: brand });
  };
  const handleCountrySelect = (country) => {
    setPayload({ ...payload, country_id: country });

    dispatch(getStateByCountry(country));
  };
  const handleTagAdd = (event) => {
    if (event.key === "Enter") {
      const newTag = event.target.value.trim();
      if (newTag && !productTags.includes(newTag)) {
        setProductTags([...productTags, newTag]);
        var arr = [...payload.tag];
        arr.push(newTag);
        setPayload({ ...payload, tag: arr });
      }
      event.target.value = "";
    }
  };

  const handleTagDelete = (tag) => {
    setProductTags(productTags.filter((t) => t !== tag));
    var arr = [...payload.tag].filter((t) => t !== tag);

    setPayload({ ...payload, tag: arr });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    for (const [key, value] of Object.entries(payload)) {
      if (!value) {
        toast.error("Kindly Fill All Fields");
        // console.log(key);
        return;
      }
      // if (key !== "image") formData.append(key, value);
    }

    // Append all payload entries except 'image' and 'meta_data'
    for (const [key, value] of Object.entries(payload)) {
      if (key != "images" && key != "meta_data" && key != "tag") {
        formData.append(key, value);
      }
    }
    payload.images.forEach((file, index) => {
      if (file instanceof File) {
        formData.append("images[]", file);
      }
    });
    if (payload?.tag?.length > 0) {
      payload.tag.forEach((item, index) => {
        formData.append("tag[]", item);
      });
    } else {
      toast.error("Kindly Add Atleast One Tag");
      return;
    }

    payload.meta_data.forEach((item, index) => {
      formData.append("meta_data[]", JSON.stringify(item));
    });

    // console.log("bb", payload.meta_data);

    dispatch(addProduct(formData));
  };
  useEffect(() => {
    if (message) {
      toast.success(message);
      if (message === "Product inserted successfully") {
        navigate("/user-dashboard/ad-market-place");
      }
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [error, message]);

  useEffect(() => {
    dispatch(getCountrie());
    dispatch(adStoreCategories());
    dispatch(getBrands());
  }, []);
  return (
    <>
      <div className="px-3 w-full">
        {/* head */}
        <div className="flex justify-between items-center md:items-start ">
          <div className="text-xs md:text-base">
            <BreadCrums
              breadCrum={[
                {
                  name: "Create Ad-Marketplace Products",
                  path: "",
                },
              ]}
            />
          </div>

          <div className="flex gap-1 md:gap-3">
            {/* <button className="px-3 md:px-[26px] py-1 md:py-3 text-[7px] md:text-xs text-center text-[#828282] bg-[#FAFAFA] border border-[#EBF0ED] rounded-lg">
              Delete
            </button>
            <button className="px-3 md:px-[26px] py-1 md:py-3 text-[7px] md:text-xs text-center text-[#828282] bg-[#FAFAFA] border border-[#EBF0ED] rounded-lg">
              Draft
            </button> */}

            {/* <button
              className="px-3 md:px-[26px] py-1 md:py-3 text-[7px] md:text-xs text-center text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg"
              onClick={(e) => handleSubmit(e)}
            >
              Update
            </button> */}

            <button
              disabled={loading}
              className="px-3 md:px-[26px] py-1 md:py-3 text-[7px] h-12 md:text-xs text-center text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg"
              onClick={(e) => handleSubmit(e)}
            >
              {!loading ? (
                "Add Product"
              ) : (
                <span className="loading loading-spinner loading-md"></span>
              )}
            </button>
          </div>
        </div>

        <div className="py-5 flex flex-col md:flex-row gap-4">
          {/* Left Side -------------------------------- */}
          <div className="w-full md:w-1/2">
            <div className="flex flex-col gap-4">
              {/* description */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm md:text-lg  font-semibold">
                  Description
                </h1>
                <div className="border flex flex-col gap-2 md:gap-5 py-2 md:py-4 px-1 md:px-3 border-[#EBF0ED] rounded-lg">
                  <div className="flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                      htmlFor="product name "
                    >
                      PRODUCT NAME
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none "
                      type="text"
                      name="product name"
                      id=""
                      placeholder="Enter product name "
                      value={payload.name}
                      onChange={(e) =>
                        setPayload({ ...payload, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] ml-1 md:ml-3 text-[10px] md:text-xs font-semibold"
                      htmlFor="product description"
                    >
                      PRODUCT DESCRIPTION
                    </label>
                    <textarea
                      className="bg-[#FAFAFA] text-[10px] md:text-xs h-10 md:h-16 placeholder: rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none "
                      type="text"
                      name="product description"
                      id=""
                      placeholder="Enter product description "
                      value={payload.description}
                      onChange={(e) =>
                        setPayload({ ...payload, description: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              {/* CATEGORIES */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm md:text-lg font-semibold">
                  Product Categories
                </h1>

                <div className="border flex flex-col gap-2 md:gap-5 py-2 md:py-4 px-1 md:px-3 border-[#EBF0ED] rounded-lg">
                  {/* brand dropdown */}
                  <div className="relative flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 md:ml-3 font-semibold"
                      htmlFor="brands"
                    >
                      BRAND
                    </label>
                    <div className="relative w-full">
                      <select
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none cursor-pointer"
                        type="text"
                        value={selectBrands}
                        onChange={(e) => handleBrandSelect(e.target.value)}
                        placeholder="Select brand"
                      >
                        <option value={"0"}>none</option>
                        {brands?.length > 0 &&
                          brands?.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  {/* Product Category Dropdown */}
                  <div className="relative flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 md:ml-3 font-semibold"
                      htmlFor="productCategory"
                    >
                      PRODUCT CATEGORY
                    </label>
                    <div className="relative w-full">
                      <select
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none cursor-pointer"
                        type="text"
                        value={productCategory}
                        readOnly
                        onChange={(e) => handleCategorySelect(e.target.value)}
                        placeholder="Select category"
                      >
                        <option value={"0"}>none</option>
                        {adStoreCat?.length > 0 &&
                          adStoreCat?.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>

                  {/* Product Tags */}
                  <div className="flex flex-col gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                      htmlFor="productTags"
                    >
                      PRODUCT TAGS
                    </label>
                    <div className="bg-[#FAFAFA] rounded-lg w-full py-2 px-3 outline-none">
                      {productTags.map((tag) => (
                        <span
                          key={tag}
                          className="bg-gray-200 text-gray-700 rounded-lg px-3 py-1 inline-flex items-center gap-2 mr-2"
                        >
                          {tag}
                          <button
                            onClick={() => handleTagDelete(tag)}
                            className="text-red-500"
                          >
                            ✕
                          </button>
                        </span>
                      ))}
                      <input
                        type="text"
                        onKeyPress={handleTagAdd}
                        className="bg-transparent outline-none text-xs w-auto inline-flex"
                        placeholder="Press Enter to add tags"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] ml-1 md:ml-3 text-[10px] md:text-xs font-semibold"
                      htmlFor="product description"
                    >
                      PRODUCT SHORT DESCRIPTION
                    </label>
                    <textarea
                      className="bg-[#FAFAFA] text-[10px] md:text-xs h-10 md:h-16 placeholder: rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none "
                      type="text"
                      name="product description"
                      id=""
                      placeholder="Add short description for Product"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          short_description: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center">
                  <h1 className="text-[#18120F] text-sm md:text-lg font-semibold">
                    meta_data
                  </h1>
                  <label
                    onClick={handleAddField}
                    className="cursor-pointer flex flex-col bg-[#D9D9D9] rounded-full w-4 h-4 items-center justify-center text-white"
                  >
                    <span>+</span>
                  </label>
                </div>

                <div className="border flex flex-col gap-5 py-4 px-3 border-[#EBF0ED] rounded-lg">
                  {inputFields.map((inputField, index) => (
                    <div className="flex gap-2 items-center">
                      <div className="w-full flex flex-col gap-1 md:gap-2">
                        <label
                          className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                          htmlFor="price"
                        >
                          ATTRIBUTE
                        </label>
                        <input
                          className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none  "
                          type="text"
                          name="attribute"
                          id=""
                          placeholder="attribute"
                          value={inputField.attribute}
                          onChange={(event) =>
                            handleInputChange(index, event, "attribute")
                          }
                        />
                      </div>
                      <div className="w-full flex flex-col gap-1 md:gap-2">
                        <div className="w-full h-fit flex flex-row justify-between">
                          <label
                            className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                            htmlFor="price"
                          >
                            PRICE
                          </label>
                          <button
                            onClick={() => handleRemoveField(index)}
                            disabled={loading}
                            className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold cursor-pointer hover:bg-gray-100 rounded-full px-1"
                          >
                            x
                          </button>
                        </div>
                        <input
                          className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none  "
                          type="number"
                          name="price"
                          id=""
                          placeholder="0 "
                          value={inputField.price}
                          onChange={(event) => handleInputChange(index, event)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Right Side ------------------------------- */}
          <div className="w-full md:w-1/2">
            <div className="flex flex-col gap-4">
              {/* Product gallery */}
              <div className="w-full flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm md:text-lg font-semibold">
                  Product Gallery
                </h1>
                <div className=" grid grid-cols-4 md:grid-cols-5 gap-3 md:gap-4 border  py-[15px] px-3 border-[#EBF0ED] rounded-lg">
                  <div
                    key="coverImg"
                    className="relative flex flex-col items-center "
                  >
                    {!coverImage ? (
                      <div
                        className="relative w-14 h-14  lg:w-20 lg:h-20 xl:w-[90px] xl:h-[90px] cursor-pointer border rounded-lg flex justify-center items-center border-dashed border-[#E0E0E0]"
                        onClick={() =>
                          document.getElementById(`coverInput`).click()
                        }
                      >
                        <label className="cursor-pointer flex flex-col bg-[#D9D9D9] rounded-full w-4 h-4 items-center justify-center text-white">
                          <span>+</span>
                        </label>
                        <input
                          type="file"
                          id="coverInput"
                          style={{ display: "none" }}
                          onChange={(event) =>
                            handleImageUpload("cover", event)
                          }
                        />
                      </div>
                    ) : (
                      <div className="relative">
                        <img
                          src={coverImage}
                          alt="{`uploaded-${index}`}"
                          className="w-14 h-14 md:w-[90px] md:h-[90px]  object-cover rounded-lg"
                        />
                        <button
                          onClick={() => handleDeleteImage("index")}
                          className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-[10px] md:text-xs"
                        >
                          x
                        </button>
                      </div>
                    )}
                    <div className="md:mt-1 text-center text-[8px] lg:text-xs xl:text-sm text-[#828282]">
                      Cover Image
                    </div>
                  </div>

                  {images.map((image, index) => (
                    <div
                      key={index}
                      className="relative flex flex-col items-center "
                    >
                      {!image ? (
                        <div
                          className="relative w-14 h-14  lg:w-20 lg:h-20 xl:w-[90px] xl:h-[90px] cursor-pointer border rounded-lg flex justify-center items-center border-dashed border-[#E0E0E0]"
                          onClick={() =>
                            document.getElementById(`fileInput${index}`).click()
                          }
                        >
                          <label className="cursor-pointer flex flex-col bg-[#D9D9D9] rounded-full w-4 h-4 items-center justify-center text-white">
                            <span>+</span>
                          </label>
                          <input
                            type="file"
                            id={`fileInput${index}`}
                            style={{ display: "none" }}
                            onChange={(event) =>
                              handleImageUpload(index, event)
                            }
                          />
                        </div>
                      ) : (
                        <div className="relative">
                          <img
                            src={image}
                            alt={`uploaded-${index}`}
                            className="w-14 h-14 md:w-[90px] md:h-[90px]  object-cover rounded-lg"
                          />
                          <button
                            onClick={() => handleDeleteImage(index)}
                            className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-[10px] md:text-xs"
                          >
                            x
                          </button>
                        </div>
                      )}
                      <div className="md:mt-1 text-center text-[8px] lg:text-xs xl:text-sm text-[#828282]">
                        {labels[index]}
                        {index < 2 && <span className="text-red-600">*</span>}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* Product Price */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm md:text-lg font-semibold">
                  Price
                </h1>
                <div className="border flex flex-col gap-2 md:gap-5 py-2 md:py-4 px-1 md:px-3 border-[#EBF0ED] rounded-lg">
                  <div className="flex gap-2 items-center">
                    <div className="w-full flex flex-col gap-1 md:gap-2">
                      <label
                        className="text-[#6B6B6B] text-[10px] md:text-xs  ml-1 md:ml-3 font-semibold"
                        htmlFor="price"
                      >
                        PRICE $
                      </label>
                      <input
                        className="bg-[#FAFAFA] rounded-lg w-full py-2 md:py-3 px-3 text-xs md:text-sm   outline-none "
                        type="number"
                        name="price"
                        id=""
                        placeholder="0 "
                        onChange={(e) =>
                          setPayload({ ...payload, price: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Location */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm md:text-lg font-semibold">
                  Location Selector
                </h1>
                <div className="border flex flex-col gap-2 md:gap-5 py-2 md:py-4 px-1 md:px-3 border-[#EBF0ED] rounded-xl">
                  {/* Country Dropdown */}
                  <div className="relative flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 md:ml-3 font-semibold"
                      htmlFor="country"
                    >
                      COUNTRY
                    </label>
                    <div className="relative w-full">
                      <select
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none cursor-pointer"
                        type="text"
                        readOnly
                        // value={selectedCountry}
                        onChange={(e) => handleCountrySelect(e.target.value)}
                        placeholder="Select Country"
                      >
                        <option value="0">none</option>
                        {countries.length > 0 &&
                          countries.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>

                  {/* State Dropdown */}
                  <div className="relative flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 md:ml-3 font-semibold"
                      htmlFor="state"
                    >
                      STATE
                    </label>
                    <div className="relative w-full">
                      <select
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none cursor-pointer"
                        type="text"
                        onChange={(e) => {
                          setPayload({ ...payload, state_id: e.target.value });
                          dispatch(getCitiesByState(e.target.value));
                        }}
                        placeholder="Select State"
                      >
                        <option value="0">none</option>
                        {states.length > 0 &&
                          states.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>

                  {/* City Dropdown */}
                  <div className="relative flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 md:ml-3 font-semibold"
                      htmlFor="city"
                    >
                      CITY
                    </label>
                    <div className="relative w-full">
                      <select
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none cursor-pointer"
                        type="text"
                        readOnly
                        onChange={(e) => {
                          setPayload({ ...payload, city_id: e.target.value });
                        }}
                        placeholder="Select City"
                      >
                        <option value={"0"}>none</option>
                        {citiesByState?.length > 0 &&
                          citiesByState?.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>

                  {/* Area Dropdown */}
                  <div className="relative flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 md:ml-3 font-semibold"
                      htmlFor="area"
                    >
                      AREA
                    </label>
                    <div className="relative w-full">
                      <input
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none cursor-pointer"
                        type="text"
                        onChange={(e) => {
                          setPayload({ ...payload, area: e.target.value });
                        }}
                        placeholder="Select Area"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
