import React, { useEffect, useState } from "react";

import VendorBundlesCard from "./VendorBundlesCard";

const VendorBundles = ({data}) => {


  return (
    <div className="flex w-full justify-center">
      <div className="max-w-screen-2xl w-full px-4 xl:px-[57px] ">
        <h1 className="font-bold text-xl md:text-3xl mt-4">Bundles</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4  mt-3 justify-center gap-4">
          {data?.bundles?.length>0 && data?.bundles?.slice(0, 4).map((bundle) => (
            <VendorBundlesCard bundle={bundle} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default VendorBundles;
