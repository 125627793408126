import React, { useEffect, useState } from "react";
import "./ThankYouPage.css";

import ProductCard from "../Common/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { recommendedProducts } from "../../store/frontendSlice";

export default function ThankYouPage() {
  const [showDivs, setShowDivs] = useState(false);
  const [arrow, setArrow] = useState(1);
  const [isThankyouVisible, setIsThankyouVisible] = useState(false);
  const [isThankyouVisible2, setIsThankyouVisible2] = useState(false);
  const [suggestedProduct, setSuggestedProduct] = useState(false);
  const dispatch = useDispatch()

  const [recomendedProducts, setRecomendedProducts] = useState([]);

  const { orderResponseData,recommendedProduct } = useSelector((state) => state.frontend);

  useEffect(() => {
    // Hide divs after 5 seconds
    const timeout = setTimeout(() => {
      setShowDivs(true);
      setSuggestedProduct(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    // Fetch data from the backend
  

    dispatch(recommendedProducts())

  }, []);

  const handleReverseAnimation = () => {
    setIsThankyouVisible(true);
    setIsThankyouVisible2(false);
    setSuggestedProduct(false);
    setArrow(2);
  };
  const handleReverseAnimation2 = () => {
    setIsThankyouVisible2(true);
    setSuggestedProduct(true);
    setArrow(1);
  };

  return (
    <>
      <main className="px-4 md:px-0 md:ps-7 lg:ps-[57px] w-full flex flex-col md:flex-row gap-4 justify-between overflow-x-hidden">
        <div
          className={` w-full md:w-[49%] ${
            showDivs ? "animate-thankyouSecond" : ""
          } ${isThankyouVisible ? "animate-ReverseThankyouSecond" : ""} ${
            isThankyouVisible2 ? "animate-thankyouSecond2" : ""
          }`}
        >
          <div className="font-bold text-2xl tracking-wide">Confirmation</div>

          <div className="flex items-center  flex-col animate-thankyou">
            <div className=" mt-24">
              <div className="relative">
                <svg
                  className="tick_rotation"
                  width="162"
                  height="168"
                  viewBox="0 0 162 168"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M72.013 4.73334C76.3955 -1.47605 85.6045 -1.47606 89.987 4.73334L96.7266 14.2823C99.5388 18.2668 104.639 19.9239 109.256 18.3533L120.321 14.5895C127.516 12.1419 134.967 17.5549 134.862 25.1544L134.702 36.841C134.635 41.7175 137.787 46.0559 142.446 47.4992L153.61 50.9581C160.87 53.2073 163.715 61.9656 159.164 68.0524L152.165 77.4129C149.245 81.3187 149.245 86.6813 152.165 90.5871L159.164 99.9476C163.715 106.034 160.87 114.793 153.61 117.042L142.446 120.501C137.787 121.944 134.635 126.282 134.702 131.159L134.862 142.846C134.967 150.445 127.516 155.858 120.321 153.411L109.256 149.647C104.639 148.076 99.5388 149.733 96.7266 153.718L89.987 163.267C85.6045 169.476 76.3955 169.476 72.013 163.267L65.2734 153.718C62.4612 149.733 57.3612 148.076 52.744 149.647L41.6789 153.411C34.4836 155.858 27.0333 150.445 27.1376 142.846L27.2979 131.159C27.3648 126.282 24.2128 121.944 19.5543 120.501L8.39008 117.042C1.13032 114.793 -1.71544 106.034 2.83577 99.9476L9.83472 90.5871C12.7552 86.6813 12.7552 81.3187 9.83472 77.4129L2.83578 68.0524C-1.71544 61.9656 1.13031 53.2073 8.39007 50.9581L19.5543 47.4992C24.2128 46.0559 27.3648 41.7175 27.2979 36.841L27.1376 25.1544C27.0333 17.5549 34.4836 12.1419 41.6789 14.5895L52.744 18.3533C57.3612 19.9239 62.4612 18.2668 65.2734 14.2823L72.013 4.73334Z"
                    fill="black"
                  />
                </svg>
                <svg
                  className="absolute top-14 left-12"
                  width="64"
                  height="49"
                  viewBox="0 0 64 49"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 25.7333L23.3333 43L58 6"
                    stroke="white"
                    stroke-width="12"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div className="mt-6">
              <div className="text-[3.8rem] font-bold tracking-wide">
                Thank You
              </div>
            </div>

            <div>
              <div className="text-xl font-bold tracking-wide">
                Your order has been placed
              </div>
            </div>
          </div>
        </div>

        <div
          className={` w-full md:w-[49%] md:h-[744px] lg:mt-[-35px] flex justify-center  brown-circle cart-bg mt-0 ${
            showDivs ? "animate-curveSection" : ""
          } ${isThankyouVisible ? "animate-ReverseCurveSection" : ""} ${
            isThankyouVisible2 ? "animate-curveSection2" : ""
          }`}
        >
          <div
            onClick={handleReverseAnimation}
            className={` absolute top-80 md:-left-[24px] lg:left-0 xl:left-12 z-50 py-3 px-4 bg-opacity-50 hover:bg-opacity-100 bg-white rounded-full  ${
              arrow === 1 ? "hidden md:block" : "hidden"
            }`}
          >
            <svg
              width="13"
              height="22"
              viewBox="0 0 13 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.38127 12.1727C0.709497 11.4675 0.709497 10.3222 1.38127 9.61692L9.97993 0.589835C10.6517 -0.115406 11.7427 -0.115406 12.4144 0.589834C13.0862 1.29508 13.0862 2.44039 12.4144 3.14563L5.03033 10.8976L12.4091 18.6496C13.0808 19.3549 13.0808 20.5002 12.4091 21.2054C11.7373 21.9107 10.6463 21.9107 9.97456 21.2054L1.37589 12.1784L1.38127 12.1727Z"
                fill="black"
              />
            </svg>
          </div>
          <div
            onClick={handleReverseAnimation2}
            className={` absolute top-80  md:-left-[24px] lg:left-0 xl:left-12 z-50 py-3 px-4 bg-opacity-50 hover:bg-opacity-100 bg-white rounded-full  ${
              arrow === 2 ? "hidden md:block" : "hidden"
            }`}
          >
            <svg
              width="13"
              height="22"
              viewBox="0 0 13 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6187 9.82728C12.2905 10.5325 12.2905 11.6778 11.6187 12.3831L3.02007 21.4102C2.3483 22.1154 1.25734 22.1154 0.585569 21.4102C-0.0862018 20.7049 -0.0862019 19.5596 0.585569 18.8544L7.96967 11.1024L0.590941 3.35035C-0.0808304 2.64511 -0.0808305 1.4998 0.590941 0.794557C1.26271 0.0893157 2.35367 0.0893156 3.02544 0.794557L11.6241 9.82164L11.6187 9.82728Z"
                fill="black"
              />
            </svg>
          </div>

          <div className="lg:ms-[3.5rem] xl:ms-28 w-full ">
            <div className="mt-10 xl:mt-20 flex gap-3">
              <div className="flex flex-col gap-1">
                <span className="text-white  text-base md:text-sm xl:text-xl font-bold tracking-wide">
                  {" "}
                  Customer Info{" "}
                </span>
                <span className="border-b-4 border-white rounded-full"></span>
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-white  text-base md:text-sm xl:text-xl font-bold tracking-wide">
                  Payments
                </span>
                <span className="border-b-4 border-white rounded-full "></span>
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-white  text-base md:text-sm xl:text-xl  font-bold tracking-wide ">
                  Confirmation
                </span>
                <span className="border-b-4 border-white rounded-full"></span>
              </div>
            </div>

            <div className="animate-orderdetails">
              {/* shipping detail */}
              <div className="mt-4 xl:mt-8 w-full bg-white  lg:max-w-[336px] xl:max-w-[492px] rounded-md px-5 py-2 ">
                <div className="text-lg font-bold tracking-wider mt-1">
                  Shipping Address
                </div>

                <div className="flex items-center mt-2 gap-4">
                  <div className="text-[#8C8C8C] text-sm">Deliver to:</div>
                  <div className="text-sm">
                    Muhammad Danyal Khan | 0300-0000000
                  </div>
                </div>
                <div className="flex items-center mt-1 gap-4">
                  <div className="text-[#8C8C8C] text-sm">Email to:</div>
                  <div className="text-sm">example@gmail.com</div>
                </div>
                <div className="flex items-start mt-1 gap-4">
                  <div className="text-[#8C8C8C] text-sm">Address: </div>
                  <div className="text-sm w-[20rem]">
                    House No. 000, Street 11, F8, Behria Town, Punjab, Pakisatn
                  </div>
                </div>
              </div>

              {/* Total order detail */}
              <div className="mt-4 xl:mt-8 w-full bg-white  lg:max-w-[336px] xl:max-w-[492px] rounded-md px-5 py-2">
                <div className="mt-8">
                  <div className="flex md:flex-col lg:flex-row justify-between">
                    <div className="flex gap-2 text-sm">
                      <div className="text-[#919191]">Order Number:</div>
                      <div className="font-bold tarcking-wide">482634</div>
                    </div>
                    <div className="flex gap-2 text-sm">
                      <div className="text-[#919191]">Date:</div>
                      <div className="font-bold tracking-wide">10/01/2024</div>
                    </div>
                  </div>
                  <div>
                    <div className="flex gap-2 text-sm">
                      <div className="text-[#919191]">Payment Method:</div>
                      <div className="font-bold tracking-wide">
                        Card Payment
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <hr />
                </div>

                <div className="">
                  <div className="font-bold tracking-wide">Products</div>
                  <div className="mt-2 ">
                    <div className="flex justify-between  text-sm">
                      <div className="text-[#8C8C8C]">x2 Product Name</div>
                      <div className="">$180.00</div>
                    </div>
                    <div className="flex justify-between  text-sm">
                      <div className="text-[#8C8C8C]">x1 Product Name</div>
                      <div className="">$90.00</div>
                    </div>
                    <div className="flex justify-between  text-sm">
                      <div className="text-[#8C8C8C]">x2 Product Name</div>
                      <div className="">$180.00</div>
                    </div>
                    <div className="flex justify-between  text-sm">
                      <div className="text-[#8C8C8C]">x1 Product Name</div>
                      <div className="">$90.00</div>
                    </div>
                    <div className="flex justify-between  text-sm">
                      <div className="text-[#8C8C8C]">x2 Product Name</div>
                      <div className="">$180.00</div>
                    </div>
                  </div>
                </div>

                <div className=" ">
                  <div className="font-bold tracking-wide">
                    Shipping and Taxes
                  </div>

                  <div className="space-y-1">
                    <div className="flex justify-between  text-sm">
                      <div className="text-[#8C8C8C]">Total Shipment fee</div>
                      <div className="">$200.00</div>
                    </div>
                    <div className="flex justify-between  text-sm">
                      <div className="text-[#8C8C8C]">Total Taxes</div>
                      <div className="">$10.00</div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="flex justify-between">
                    <div className="font-bold tracking-wide">Total Amount:</div>
                    <div className="font-bold tacking-wide">$1790.00</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div
        className={` md:ps-7 lg:ps-[57px] w-[90%] -mt-[44rem] h-[75vh] ${
          suggestedProduct ? "animate-suggestedCard hidden md:block" : "hidden"
        } `}
      >
        <div className="font-bold text-2xl tracking-wide">Suggestion</div>
        <div>
          <div className=" mx-auto mt-4 mb-6">
            {/* Top selling card */}
            <div
              className={`grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 grid-cols-1 gap-3 xl:gap-3 `}
            >
              {recommendedProduct?.slice(0, 4)?.map((item) => (
                <ProductCard key={item._id} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
