import { useEffect, useState } from "react";
import UserDashboardWishlistProductCard from "./UserDashboardWishlistProductCard";
import BreadCrums from "../../../Common/BreadCrums";
import Loader from "../../../Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, deleteWishlist, getWishlist } from "../../../../store/categorySlice";
import { toast } from "react-toastify";
import ProductCard from "../../../Common/ProductCard";

export default function UserDashboardWishlist() {
  const [isSelecting, setIsSelecting] = useState(false);
  const dispatch = useDispatch();
  const { data, loading, message, error } = useSelector((state) => state.category);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    dispatch(getWishlist());
  }, []);

  useEffect(() => {
    if(message){
      toast.success(message)
      dispatch(getWishlist());
      setIsSelecting(false);
    }
    if(error){
      toast.error(error)
    }
    setSelectedIds([]);
    dispatch(clearMessage());
  }, [loading, message, error]);

  const toggleSelectMode = () => {
    setIsSelecting(!isSelecting);
    setSelectedIds([]);
  };

  const handleDelete = () => {
    dispatch(deleteWishlist({id:selectedIds}))
  }

  return (
    <>
      {/* 1st div  */}
      {data?.wishlist_items?.length === 0 && (
        <div className=" w-full flex flex-col px-8 gap-2">
          <div className="flex flex-col w-full">
            <BreadCrums
              breadCrum={[
                {
                  name: "Wishlist",
                  path: "",
                },
              ]}
            />
            <div className="w-full pt-8 flex justify-center">
              <img
                src="/assets/icons/user-dash-board/empty-wishlist.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {/* 2nd div  */}
      {data?.wishlist_items?.length > 0 && (
        <div className=" w-full flex flex-col px-4 xl:px-8 gap-2">
          <div className="flex justify-between items-center w-full">
            <BreadCrums
              breadCrum={[
                {
                  name: "Wishlist",
                  path: "",
                },
              ]}
            />{" "}
            <button
              onClick={toggleSelectMode}
              className={`font-normal text-sm pr-1 underline hover:text-[#C19A6B] ${
                isSelecting ? "hidden" : "block"
              }`}
            >
              Select
            </button>
            <div className={`gap-3 pr-1 ${isSelecting ? "flex" : "hidden"}`}>
              <button
                onClick={toggleSelectMode}
                className="font-normal text-sm underline hover:text-[#C19A6B]"
              >
                Cancel
              </button>
              <button onClick={handleDelete} className="flex gap-1 items-center font-normal text-sm underline hover:text-[#C19A6B]">
                Delete Selected{" "}
                <span>
                  <img
                    src="/assets/icons/user-dash-board/wishlist-delete.svg"
                    alt=""
                  />
                </span>
              </button>
            </div>
          </div>

          <div className="mt-2">
            {loading?(
              <div className="flex w-full h-[70vh] justify-center items-center">
                <Loader/>
              </div>
            ):(
              <div
                className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4  md:gap-8 `}
              >
                {data?.wishlist_items?.map((item) => (
                  <ProductCard checker={true} key={item._id} item={item} isSelecting={isSelecting} selectedIds={selectedIds} setSelectedIds={setSelectedIds} getFunction={getWishlist}/>
                  // <UserDashboardWishlistProductCard
                  //   key={item.id}
                  //   item={item}
                  //   isSelecting={isSelecting}
                  //   selectedIds={selectedIds}
                  //   setSelectedIds={setSelectedIds}
                  // />
                ))}
              </div>
            )}
          </div>

        </div>
      )}
    </>
  );
}
