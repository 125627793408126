import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";

const UserDashboardSellingMyFollowers = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "/user-dashboard-selling-my-followers.json"
        );
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Pagination control
  const totalPages = Math.ceil(products.length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedProducts = products.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <>
      <div className="px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Followers ",
              path: "",
            },
          ]}
        />

        {/* Search and Add Product */}
        <div className=" relative w-full flex items-center ">
          <img
            src="/assets/icons/search.svg"
            alt=""
            className="absolute left-3"
          />
          <input
            className="w-full py-2 md:py-3 px-9 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-[14px]"
            type="search"
            placeholder="Search product..."
          />
        </div>

        {/* Table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white  rounded-lg border border-gray-300 overflow-x-auto">
            {/* Table Header */}
            <div className="min-w-[768px] md:min-w-[1024px] lg:min-w-[1280px]">
              <div className="flex text-[10px] md:text-sm items-center justify-between p-2 md:p-4 border-b text-[#6B6B6B] font-semibold  border-gray-300">
                <span className="text-left w-[12.5%]">IMAGE</span>
                <span className="text-left w-[12.5%] ">NAME</span>

                <span className="text-left w-[12.5%]">EMAIL</span>
                <span className="text-left w-[12.5%]">ORDER</span>
                <span className="text-left w-[12.5%]">MONTHLY SPEND</span>
                <span className="text-left w-[12.5%]">ROLE</span>
                <span className="text-left w-[12.5%]">ACTION</span>
              </div>

              {/* Table Data Row */}
              {paginatedProducts.map((product, index) => (
                <div
                  className="flex text-[10px] md:text-sm items-center justify-between text-[#18120F] p-2 md:p-4 border-b"
                  key={index}
                >
                  <div className="text-left w-[12.5%]">
                    <img
                      src={product.img}
                      alt={product.name}
                      className="w-8 h-8 md:w-10 md:h-10 rounded-lg mr-4"
                    />
                  </div>
                  <div className="text-left w-[12.5%]">
                    <span className=" text-[#18120F] font-semibold">
                      {product.name}
                    </span>
                  </div>

                  <div className="text-left w-[12.5%]">
                    <span className=" text-[#18120F] font-semibold">
                      {product.email}
                    </span>
                  </div>
                  <span className="text-left w-[12.5%]">{product.orders}</span>
                  <span className="text-left w-[12.5%]">
                    ${product.monthlyspend}
                  </span>
                  <div className="text-left w-[12.5%]">
                    <span className=" text-[#C19A6B] bg-[#FFFBF6] border  py-[5px] px-[15px] rounded-xl border-[#C19A6B]">
                      {product.role}
                    </span>
                  </div>
                  <div className="flex gap-5  text-left w-[12.5%] font-[600] ">
                    <img
                      className="w-[28px] md:w-[38px]"
                      src="/assets/icons/view.svg"
                      alt=""
                    />
                    <img
                      className="w-[28px] md:w-[38px]"
                      src="/assets/icons/delete1.svg"
                      alt=""
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Pagination */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
          <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
            SHOWING {startIndex + 1} -{" "}
            {Math.min(startIndex + itemsPerPage, products.length)} OF{" "}
            {products.length} RESULTS
          </span>
          <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
            {/* Previous Button */}
            <div
              className="py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED]"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <svg
                className={`${
                  currentPage === 1
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-500"
                }`}
                width="6"
                height="12"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 13L1 7L7 1"
                  stroke="#828282"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {/* Page Numbers */}
            <div className="flex items-center gap-2">
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .slice(0, 3)
                .map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`py-[6px] px-[12px] md:py-[10px] md:px-[14.5px] text-lg   rounded ${
                      currentPage === page
                        ? "bg-[#C19A6B] text-white rounded-xl border border-[#EBF0ED]"
                        : " bg-[#FAFAFA] text-[#828282] rounded-xl border border-[#EBF0ED]"
                    }`}
                  >
                    {page}
                  </button>
                ))}
            </div>

            {/* Next Button */}
            <div
              className="py-[12px] px-[12px] md:py-[18px] md:px-[17px]  cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED]"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <svg
                className={`${
                  currentPage === totalPages
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-500"
                }`}
                width="6"
                height="12"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 13L7 7L1 1"
                  stroke="#828282"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDashboardSellingMyFollowers;
