import React, { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { Link } from "react-router-dom";

function SmartFilterSidebarComponent({ children, toggleContent }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    toggleContent(!isOpen);
  };

  return (
    <div className="flex justify-between w-full pt-4 ">
      <Link className="flex justify-center items-center font-semibold text-sm md:text-lg ">
        {children}
      </Link>
      <span className="flex items-center" onClick={toggleDropdown}>
        {isOpen ? (
          <RiArrowDropUpLine
            className="text-2xl md:text-3xl"
            cursor="pointer"
          />
        ) : (
          <RiArrowDropDownLine
            className="text-2xl md:text-3xl"
            cursor="pointer"
          />
        )}
      </span>
    </div>
  );
}

export default SmartFilterSidebarComponent;
