import UserDashboardEditAuctionProductPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/UserDashboardEditAuctionProductPage";
import UserDashboardSellingAuctionPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/UserDashboardSellingAuctionPage";
import UserDashboardSellingCreateAuctionProduct from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/UserDashboardSellingCreateAuctionProduct";

const auctionRoutes = [
  {
    path: "/user-dashboard/auction-product",

    children: [
      {
        index: true,
        element: <UserDashboardSellingAuctionPage />,
      },
      {
        path: "/user-dashboard/auction-product/create-product",
        element: <UserDashboardSellingCreateAuctionProduct />,
      },
      {
        path: "/user-dashboard/auction-product/edit-product/:id",
        element: <UserDashboardEditAuctionProductPage />,
      },
    ],
  },
];

export default auctionRoutes;
