import React, { useEffect } from "react";
import AdStoreSingleProductDetails from "../../../components/Ad-MarketPlace-Components/Single-Product-Page-Components/AdStoreSingleProductDetails";
import AdStoreSingleProductDescription from "../../../components/Ad-MarketPlace-Components/Single-Product-Page-Components/AdStoreSingleProductDescription";

import AdStoreRelatedProducts from "../../../components/Ad-MarketPlace-Components/Single-Product-Page-Components/AdStoreRelatedProducts";

import AdStoreSingleProductLastViewedByYou from "../../../components/Ad-MarketPlace-Components/Single-Product-Page-Components/AdStoreSingleProductLastViewedByYou";
import { useDispatch, useSelector } from "react-redux";
import { getADSingleProduct } from "../../../store/frontendSlice";
import { useParams } from "react-router-dom";

export default function AdStoreSingleProductPage() {
  const { slug } = useParams();
  const dispatch = useDispatch()
  const { Adproduct } = useSelector(state => state.frontend);

  useEffect(() => {
    if(slug) {  
      dispatch(getADSingleProduct(slug))
    }
  }, [slug])

  return (
    <>
      <AdStoreSingleProductDetails Adproduct={Adproduct } />
      <AdStoreSingleProductDescription Adproduct={Adproduct } />
      <AdStoreRelatedProducts Adproduct={Adproduct } />
      <AdStoreSingleProductLastViewedByYou Adproduct={Adproduct?.lastView } />
    </>
  );
}
