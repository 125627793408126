// const URL = "https://wizalot.bingtechs.dev/api/";
const URL = "https://backend.yugoiptv.com/api/";
// const URL = "https://api.bingtechs.dev/api/";

export const imagePath = "https://backend.yugoiptv.com/";

export const vendorDash =
  "https://vendor.yugoiptv.com/";
export const BannerDash =
  "https://banner.yugoiptv.com/";
export const mallDash =
  "https://mall.yugoiptv.com/";

export default URL;
