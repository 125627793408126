import React, { useEffect, useState } from "react";
import BreadCrums from "../../Common/BreadCrums";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import { toast } from "react-toastify";
import moment from "moment";

import {
  auctionCategories,
  clearImageId,
  clearMessage,
  getAuctionProductById,
  getBrands,
  getCitiesByCountries,
  getCitiesByState,
  getCountrie,
  getStateByCountry,
  updateAuctionProduct,
  userImageUpdate,
} from "../../../store/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import { imagePath } from "../../../functions/URLString";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export default function UserDashboardEditAuctionProduct() {
  const { id } = useParams();
  const [productCategory, setProductCategory] = useState("");
  const {
    data,
    error,
    message,
    cities,
    states,
    countries,
    citiesByState,
    brands,
    category,
    loading,
    auctionCat,
    imageId,
  } = useSelector((state) => state.category);
  // const [brands, setBrands] = useState("");
  const [productTags, setProductTags] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [isAffiliate, setIsAffiliate] = useState(null);
  const [imagesId, setImagesId] = useState([]);
  const navigate = useNavigate();
  const [inputFields, setInputFields] = useState([
    { attribute: "", price: "" },
  ]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [coverImage, setCoverImage] = useState(null);
  const [payload, setPayload] = useState({
    product_name: "",
    description: "",
    state: "",
    model: "",
    country_id: null,

    affiliate: isAffiliate,
    commission: "",
    brand_id: brands,
    category_id: productCategory,
    tag: productTags,
    short_description: "",
    images: [],
    image: "",
    price: null,
    bidstartdatetime: startDate,
    bidenddatetime: endDate,
    discountprice: 0,
    sale_from: "",
    sale_to: "",
    threshold: "",
    quantity: 0,
    area: "",
    meta_data: [],
  });

  const dispatch = useDispatch();
  const handleAffiliateChange = (event) => {
    setIsAffiliate(event.target.value === "yes" ? "yes" : "no");
    setPayload({ ...payload, affiliate: isAffiliate });
  };

  const handleStartDateChange = (e) => {
    const rawDate = e.target.value;
    const formatted = moment(rawDate).format("YYYY-MM-DD HH:mm:ss");
    setStartDate(formatted);
    setPayload((prevPayload) => ({
      ...prevPayload,
      bidstartdatetime: formatted,
    }));
  };

  const handleEndDateChange = (e) => {
    const rawDate = e.target.value;
    const formatted = moment(rawDate).format("YYYY-MM-DD HH:mm:ss");
    setEndDate(formatted);
    setPayload((prevPayload) => ({
      ...prevPayload,
      bidenddatetime: formatted,
    }));
  };

  const [images, setImages] = useState([]); // Array to hold images
  const labels = [
    "Cover Image",
    "Alt Image",
    "Image 3",
    "Image 4",
    "Image 5",
    "Image 6",
    "Image 7",
  ];
  const updateImage = (index, file) => {
    // Create a new copy of the image array with the updated element
    const newImageArray = [...payload.image];
    newImageArray[index] = file;

    // Set the new image array back into the payload object
    setPayload({
      ...payload,
      image: newImageArray,
    });
  };
  const handleImageUpload = (index, event) => {
    if (index === "cover") {
      const file = event.target.files[0];
      if (file) {
        var formData = new FormData();
        formData.append("image", file);
        dispatch(userImageUpdate({ id, formData }));
        setPayload({ ...payload, cover_image: file });
        const reader = new FileReader();
        reader.onload = () => {
          setCoverImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      const file = event.target.files[0];
      if (file) {
        // payload?.images.push(file)
        var formData = new FormData();
        formData.append("image", file);
        dispatch(userImageUpdate({ id, formData }));
        // console.log(id);

        // updateImage(index, file);
        const reader = new FileReader();
        reader.onload = () => {
          const newImages = [...images];
          newImages[index] = reader.result;
          setImages(newImages);
          // updateImage(index, reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleDeleteImage = (index, id) => {
    if (index === "index") {
      setCoverImage(null);
      setPayload((prevPayload) => ({
        ...prevPayload,
        cover_image: null,
      }));
      const updatedImages =
        payload?.images?.filter((item) => item.image_id !== id) || [];
      setPayload((prevPayload) => ({
        ...prevPayload,
        images: updatedImages,
      }));
    } else {
      if (id) {
        const updatedImagesId = imagesId.filter((item) => item !== id);
        setImagesId(updatedImagesId);

        const updatedImages =
          payload?.images?.filter((item) => item.image_id !== id) || [];
        setPayload((prevPayload) => ({
          ...prevPayload,
          images: updatedImages,
        }));
      }

      const newImages = [...images];
      newImages[index] = null;
      setImages(newImages);
    }
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleDropdown1 = () => setDropdownOpen1(!dropdownOpen1);

  const handleAddField = () => {
    setInputFields([...inputFields, { attribute: "", price: "" }]);
  };

  const handleRemoveField = (index) => {
    const updatedFields = inputFields.filter((_, i) => i !== index);
    setInputFields(updatedFields);
    setPayload({ ...payload, meta_data: updatedFields });
  };

  const handleInputChange = (index, event, name) => {
    if (name === "attribute") {
      const newInputFields = [...inputFields];
      newInputFields[index].attribute = event.target.value;
      setInputFields(newInputFields);
    } else {
      const newInputFields = [...inputFields];
      newInputFields[index].price = event.target.value;
      setInputFields(newInputFields);
    }
    setPayload({ ...payload, meta_data: inputFields });
  };

  const handleCategorySelect = (category) => {
    setProductCategory(category);
    setDropdownOpen(false);
    setPayload({ ...payload, category_id: category });
  };
  const handleBrandSelect = (category) => {
    // setBrands(category);
    setDropdownOpen1(false);
    setPayload({ ...payload, brand_id: category });
  };
  const handleCountrySelect = (country) => {
    setPayload({ ...payload, country_id: country });

    dispatch(getStateByCountry(country));
  };
  const handleTagAdd = (event) => {
    if (event.key === "Enter") {
      const newTag = event.target.value.trim();
      if (newTag && !payload?.tag.includes(newTag)) {
        setProductTags([...productTags, newTag]);
        const currentTags = payload?.tag || [];
        setPayload({ ...payload, tag: [...currentTags, { tag: newTag }] });
      }
      event.target.value = "";
    }
  };

  const handleTagDelete = (tag) => {
    // console.log(tag);
    setProductTags(productTags.filter((t) => t !== tag));
    var arr = [...payload.tag].filter((t) => t !== tag);

    setPayload({ ...payload, tag: arr });
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("_method", "PUT");

    for (const [key, value] of Object.entries(payload)) {
      if (key == "images" || !value || key == "meta_data" || key == "tag") {
      } else {
        formData.append(key, value);
      }
    }

    payload?.images.forEach((file, index) => {
      formData.append("images[]", JSON.stringify(file));
    });
    if (payload?.tag?.length > 0) {
      payload?.tag.forEach((item, index) => {
        formData.append("tag[]", JSON.stringify(item));
      });
    } else {
      toast.error("Kindly Add Atleast One Tag");
      return;
    }

    payload?.meta_data.forEach((item, index) => {
      formData.append("meta_data[]", JSON.stringify(item));
    });

    dispatch(updateAuctionProduct({ id, formData }));
  };

  useEffect(() => {
    dispatch(getCountrie());
    dispatch(auctionCategories());
    dispatch(getBrands());
  }, []);
  useEffect(() => {
    dispatch(getAuctionProductById(id));
  }, []);
  useEffect(() => {
    setPayload(data?.product);
    dispatch(getCitiesByState(data?.product?.state_id));
    dispatch(getStateByCountry(data?.product?.country_id));
    setInputFields(data?.product?.auctionAttribute);
    setProductTags(
      data?.product?.auctionTag?.map((tagObject) => tagObject.tag)
    );
    setImagesId(data?.product?.auctionImages?.map((item) => item.image_id));
    setImages(data?.product?.auctionImages);
    setCoverImage(data?.product?.image);
    setStartDate(data?.product?.bidstartdatetime);
    setEndDate(data?.product?.bidenddatetime);
    setPayload((prevPayload) => ({
      ...prevPayload,
      tag: data?.product?.auctionTag,
      images: data?.product?.auctionImages,
      image: data?.product?.image,
      meta_data: data?.product?.auctionAttribute,
    }));
  }, [data]);
  useEffect(() => {
    if (imageId) {
      setImagesId([...imagesId, imageId]);
      setPayload((prevPayload) => ({
        ...prevPayload,
        images: [...prevPayload.images, imageId],
      }));
      dispatch(clearImageId());
    }
  }, [imageId]);
  useEffect(() => {
    if (message) {
      toast.success(message);
      if (message === "Product updated successfully") {
        navigate("/user-dashboard/auction-product");
      }
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [error, message]);
  return (
    <>
      <div className="px-3 w-full">
        {/* head */}
        <div className="flex justify-between items-center md:items-start ">
          <div className="text-xs md:text-base">
            <BreadCrums
              breadCrum={[
                {
                  name: "Edit Auction Products",
                  path: "",
                },
              ]}
            />
          </div>

          <div className="flex gap-1 md:gap-3">
            {/* <button className="px-3 md:px-[26px] py-1 md:py-3 text-[7px] md:text-xs text-center text-[#828282] bg-[#FAFAFA] border border-[#EBF0ED] rounded-lg">
              Delete
            </button>
            <button className="px-3 md:px-[26px] py-1 md:py-3 text-[7px] md:text-xs text-center text-[#828282] bg-[#FAFAFA] border border-[#EBF0ED] rounded-lg">
              Draft

            </button>
            <button
              className="px-3 md:px-[26px] py-1 md:py-3 text-[7px] md:text-xs text-center text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg"
              onClick={(e) => handleSubmit(e)}
            >

            </button> */}
            <button
              className="px-3 md:px-[26px] py-1 md:py-3 text-[7px] h-12 md:text-xs text-center text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg"
              onClick={(e) => handleSubmit(e)}
            >
              {!loading ? (
                "Update Product"
              ) : (
                <span className="loading loading-spinner loading-md"></span>
              )}
            </button>
          </div>
        </div>

        <div className="py-5 flex flex-col md:flex-row gap-4">
          {/* Left Side -------------------------------- */}
          <div className="w-full md:w-1/2">
            <div className="flex flex-col gap-4">
              {/* description */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm md:text-lg  font-semibold">
                  Description
                </h1>
                <div className="border flex flex-col gap-2 md:gap-5 py-2 md:py-4 px-1 md:px-3 border-[#EBF0ED] rounded-lg">
                  <div className="flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                      htmlFor="product name "
                    >
                      PRODUCT NAME
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none "
                      type="text"
                      name="product name"
                      id=""
                      placeholder="Enter product name "
                      value={payload?.product_name}
                      onChange={(e) =>
                        setPayload({ ...payload, product_name: e.target.value })
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] ml-1 md:ml-3 text-[10px] md:text-xs font-semibold"
                      htmlFor="product description"
                    >
                      PRODUCT DESCRIPTION
                    </label>
                    <textarea
                      className="bg-[#FAFAFA] text-[10px] md:text-xs h-10 md:h-16 placeholder: rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none "
                      type="text"
                      name="product description"
                      id=""
                      placeholder="Enter product description "
                      value={payload.description}
                      onChange={(e) =>
                        setPayload({ ...payload, description: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              {/* CATEGORIES */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm md:text-lg font-semibold">
                  Product Categories
                </h1>

                <div className="border flex flex-col gap-2 md:gap-5 py-2 md:py-4 px-1 md:px-3 border-[#EBF0ED] rounded-lg">
                  {/* brand dropdown */}
                  <div className="relative flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 md:ml-3 font-semibold"
                      htmlFor="brands"
                    >
                      BRAND
                    </label>
                    <div className="relative w-full">
                      <select
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none cursor-pointer"
                        type="text"
                        value={payload.brand_id}
                        onChange={(e) =>
                          setPayload({ ...payload, brand_id: e.target.value })
                        }
                        placeholder="Select brand"
                      >
                        {brands.length > 0 &&
                          brands.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                        <option value={"0"}>none</option>
                      </select>
                    </div>
                  </div>
                  {/* Product Category Dropdown */}
                  <div className="relative flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 md:ml-3 font-semibold"
                      htmlFor="productCategory"
                    >
                      PRODUCT CATEGORY
                    </label>
                    <div className="relative w-full">
                      <select
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none cursor-pointer"
                        type="text"
                        value={payload.category_id}
                        readOnly
                        onChange={(e) => handleCategorySelect(e.target.value)}
                        placeholder="Select category"
                      >
                        <option value={"0"}>none</option>
                        {auctionCat.length > 0 &&
                          auctionCat.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>

                  {/* Product Tags */}
                  <div className="flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] ml-1 md:ml-3 text-[10px] md:text-xs font-semibold"
                      htmlFor="productTags"
                    >
                      PRODUCT TAGS
                    </label>
                    <div className="bg-[#FAFAFA] rounded-lg w-full py-2 px-3 outline-none">
                      {payload?.tag?.length > 0 &&
                        payload?.tag?.map((tag) => (
                          <span
                            key={tag.tag_id}
                            className="bg-gray-200 text-gray-700 rounded-lg px-3 py-1 inline-flex items-center gap-2 mr-2"
                          >
                            {tag.tag}
                            <button
                              onClick={() => handleTagDelete(tag)}
                              className="text-red-500"
                            >
                              ✕
                            </button>
                          </span>
                        ))}
                      <input
                        type="text"
                        onKeyPress={handleTagAdd}
                        className="bg-transparent outline-none text-xs w-auto inline-flex"
                        placeholder="Press Enter to add tags"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] ml-1 md:ml-3 text-[10px] md:text-xs font-semibold"
                      htmlFor="product description"
                    >
                      PRODUCT SHORT DESCRIPTION
                    </label>
                    <textarea
                      className="bg-[#FAFAFA] text-[10px] md:text-xs h-10 md:h-16 placeholder: rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none "
                      type="text"
                      name="product description"
                      id=""
                      value={payload.short_description}
                      placeholder="Add short description for Product"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          short_description: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* meta data */}
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center">
                  <h1 className="text-[#18120F] text-sm md:text-lg font-semibold">
                    meta_data
                  </h1>
                  <label
                    onClick={handleAddField}
                    className="cursor-pointer flex flex-col bg-[#D9D9D9] rounded-full w-4 h-4 items-center justify-center text-white"
                  >
                    <span>+</span>
                  </label>
                </div>

                <div className="border flex flex-col gap-5 py-4 px-3 border-[#EBF0ED] rounded-lg">
                  {inputFields?.length > 0 &&
                    inputFields.map((inputField, index) => (
                      <div className="flex gap-2 items-center">
                        <div className="w-full flex flex-col gap-1 md:gap-2">
                          <label
                            className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                            htmlFor="price"
                          >
                            ATTRIBUTE
                          </label>
                          <input
                            className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none  "
                            type="text"
                            name="attribute"
                            id=""
                            placeholder="attribute"
                            value={inputField.attribute}
                            onChange={(event) =>
                              handleInputChange(index, event, "attribute")
                            }
                          />
                        </div>
                        <div className="w-full flex flex-col gap-1 md:gap-2">
                          <div className="w-full h-fit flex flex-row justify-between">
                            <label
                              className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold"
                              htmlFor="price"
                            >
                              PRICE
                            </label>
                            <button
                              onClick={() => handleRemoveField(index)}
                              disabled={loading}
                              className="text-[#6B6B6B] text-[10px] md:text-xs ml-1  md:ml-3 font-semibold cursor-pointer hover:bg-gray-100 rounded-full px-1"
                            >
                              x
                            </button>
                          </div>
                          <input
                            className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3  md:px-5   outline-none  "
                            type="number"
                            name="price"
                            id=""
                            placeholder="0 "
                            value={inputField.price}
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          {/* Right Side ------------------------------- */}
          <div className="w-full md:w-1/2">
            <div className="flex flex-col gap-4">
              {/* Product gallery */}
              <div className="w-full flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm  md:text-lg font-semibold">
                  Product Gallery
                </h1>
                <div className=" grid grid-cols-4 md:grid-cols-5 gap-3 md:gap-4 border  py-[15px] px-3 border-[#EBF0ED] rounded-lg">
                  <div className="relative flex flex-col items-center ">
                    {!coverImage ? (
                      <div
                        className="relative w-14 h-14  lg:w-20 lg:h-20 xl:w-[90px] xl:h-[90px] cursor-pointer border rounded-lg flex justify-center items-center border-dashed border-[#E0E0E0]"
                        onClick={() =>
                          document.getElementById(`coverInput`).click()
                        }
                      >
                        <label className="cursor-pointer flex flex-col bg-[#D9D9D9] rounded-full w-4 h-4 items-center justify-center text-white">
                          <span>+</span>
                        </label>
                        <input
                          type="file"
                          id="coverInput"
                          style={{ display: "none" }}
                          onChange={(event) =>
                            handleImageUpload("cover", event)
                          }
                        />
                      </div>
                    ) : (
                      <div className="relative">
                        <img
                          src={coverImage}
                          alt="{`uploaded-${index}`}"
                          className="w-14 h-14 md:w-[90px] md:h-[90px]  object-cover rounded-lg"
                        />
                        <button
                          onClick={() => handleDeleteImage("index", null)}
                          className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-[10px] md:text-xs"
                        >
                          x
                        </button>
                      </div>
                    )}
                    <div className="md:mt-1 text-center text-[8px] lg:text-xs xl:text-sm text-[#828282]">
                      Cover Image
                    </div>
                  </div>

                  {images?.length > 0 &&
                    images.map((image, index) => (
                      <div
                        key={index}
                        className="relative flex flex-col items-center "
                      >
                        {!image ? (
                          <div
                            className="relative w-14 h-14  lg:w-20 lg:h-20 xl:w-[90px] xl:h-[90px] cursor-pointer border rounded-lg flex justify-center items-center border-dashed border-[#E0E0E0]"
                            onClick={() =>
                              document
                                .getElementById(`fileInput${index}`)
                                .click()
                            }
                          >
                            <label className="cursor-pointer flex flex-col bg-[#D9D9D9] rounded-full w-4 h-4 items-center justify-center text-white">
                              <span>+</span>
                            </label>
                            <input
                              type="file"
                              id={`fileInput${index}`}
                              style={{ display: "none" }}
                              onChange={(event) =>
                                handleImageUpload(index, event)
                              }
                            />
                          </div>
                        ) : (
                          <div className="relative">
                            <img
                              src={image?.image ? image?.image : image}
                              alt={`uploaded-${index}`}
                              className="w-14 h-14 md:w-[90px] md:h-[90px]  object-cover rounded-lg"
                            />
                            <button
                              onClick={() =>
                                handleDeleteImage(index, image?.image_id)
                              }
                              className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-[10px] md:text-xs"
                            >
                              x
                            </button>
                          </div>
                        )}
                        <div className="md:mt-1 text-center text-[8px] lg:text-xs xl:text-sm text-[#828282]">
                          {labels[index]}
                          {index < 2 && <span className="text-red-600">*</span>}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm md:text-lg font-semibold">
                  Price
                </h1>
                <div className="border flex flex-col gap-2 md:gap-5 py-2 md:py-4 px-1 md:px-3 border-[#EBF0ED] rounded-lg">
                  <div className="flex gap-2 items-center">
                    <div className="w-full flex flex-col gap-1 md:gap-2">
                      <label
                        className="text-[#6B6B6B] text-[10px] md:text-xs  ml-1 md:ml-3 font-semibold"
                        htmlFor="price"
                      >
                        PRICE $
                      </label>
                      <input
                        className="bg-[#FAFAFA] rounded-lg w-full py-2 md:py-3 px-3 text-xs md:text-sm   outline-none "
                        type="number"
                        name="price"
                        id=""
                        placeholder="0 "
                        value={payload.price}
                        onChange={(e) =>
                          setPayload({ ...payload, price: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex w-full  gap-2">
                  <div className="w-full">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 md:ml-3 font-semibold"
                      htmlFor="price"
                    >
                      BIDDING START DATE
                    </label>
                    <input
                      className="bg-[#FAFAFA]  text-xs rounded-lg w-full py-2 px-5   outline-none cursor-pointer "
                      type="datetime-local"
                      name="price"
                      id=""
                      placeholder="00/00/00 "
                      onChange={handleStartDateChange}
                      value={payload.bidstartdatetime}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 md:ml-3 font-semibold"
                      htmlFor="price"
                    >
                      BIDDING ENDS DATE
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-xs rounded-lg w-full py-2 px-5   outline-none cursor-pointer "
                      type="datetime-local"
                      name="price"
                      id=""
                      placeholder="00/00/00 "
                      onChange={handleEndDateChange}
                      value={payload.bidenddatetime}
                    />
                  </div>
                </div>
              </div>
              {/* Location */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-sm md:text-lg font-semibold">
                  Location Selector
                </h1>
                <div className="border flex flex-col gap-2 md:gap-5 py-2 md:py-4 px-1 md:px-3 border-[#EBF0ED] rounded-xl">
                  {/* Country Dropdown */}
                  <div className="relative flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 md:ml-3 font-semibold"
                      htmlFor="country"
                    >
                      COUNTRY
                    </label>
                    <div className="relative w-full">
                      <select
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none cursor-pointer"
                        type="text"
                        readOnly
                        value={payload.country_id}
                        onChange={(e) => handleCountrySelect(e.target.value)}
                        placeholder="Select Country"
                      >
                        <option value="0">none</option>
                        {countries.length > 0 &&
                          countries.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>

                  {/* State Dropdown */}
                  <div className="relative flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 md:ml-3 font-semibold"
                      htmlFor="state"
                    >
                      STATE
                    </label>
                    <div className="relative w-full">
                      <select
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none cursor-pointer"
                        type="text"
                        value={payload.state_id}
                        onChange={(e) => {
                          setPayload({ ...payload, state_id: e.target.value });
                          dispatch(getCitiesByState(e.target.value));
                        }}
                        placeholder="Select State"
                      >
                        <option value="0">none</option>
                        {states.length > 0 &&
                          states.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>

                  {/* City Dropdown */}
                  <div className="relative flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 md:ml-3 font-semibold"
                      htmlFor="city"
                    >
                      CITY
                    </label>
                    <div className="relative w-full">
                      <select
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none cursor-pointer"
                        type="text"
                        readOnly
                        value={payload.city_id}
                        onChange={(e) => {
                          setPayload({ ...payload, city_id: e.target.value });
                        }}
                        placeholder="Select City"
                      >
                        <option value={"0"}>none</option>
                        {citiesByState?.length > 0 &&
                          citiesByState?.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>

                  {/* Area Dropdown */}
                  <div className="relative flex flex-col gap-1 md:gap-2">
                    <label
                      className="text-[#6B6B6B] text-[10px] md:text-xs ml-1 md:ml-3 font-semibold"
                      htmlFor="area"
                    >
                      AREA
                    </label>
                    <div className="relative w-full">
                      <input
                        className="bg-[#FAFAFA] text-[10px] md:text-xs rounded-lg w-full py-2 md:py-3 px-3 md:px-5 outline-none cursor-pointer"
                        type="text"
                        value={payload.area}
                        onChange={(e) => {
                          setPayload({ ...payload, area: e.target.value });
                        }}
                        placeholder="Select Area"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
