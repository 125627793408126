import VendorShopFronPage from "../../pages/Main-Shop/Vendor-Shop-Front-Page/VendorShopFronPage";

const vendorShopFrontRoutes = [
  {
    path: "/vendor-shop-front/:slug",
    element: <VendorShopFronPage />,
  },
];

export default vendorShopFrontRoutes;
