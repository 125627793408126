import React, { useEffect, useRef, useState } from "react";
import BreadCrums from "../../Common/BreadCrums";
import Loader from "../../Common/Loader";
import UserDashboardAddresesEditAddressForm from "./UserDashboardAddresesEditAddressForm";
import { useDispatch, useSelector } from "react-redux";
import { userAddressDelete, userAddressAdd, clearMessage, userAddressList, getCitiesByState, getCountrie, getStateByCountry } from "../../../store/categorySlice";
import { toast } from "react-toastify";

export default function UserDashboardUserProfileAddresses() {
  const [addressType, setAddressType] = useState("home");
  const [editId, setEditId] = useState("");
  const { message, error, loading, profileSetting, countries, citiesByState, states} = useSelector((state) => state.category);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [isCountryDropdownVisible, setCountryDropdownVisible] = useState(false);
  const [isStateDropdownVisible, setStateDropdownVisible] = useState(false);
  const [isCityDropdownVisible, setCityDropdownVisible] = useState(false);

  const AddNewAddressModalRef = useRef(null);
  const dispatch = useDispatch();
  const [payload, setPayload] = useState({
    name: "",
    phone_number: "",
    country_id: "",
    state_id: "",
    city_id: "",
    area: "",
    address: "",
    landmark: "",
    address_type: "",
    zipcode: ""
  });

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (message == " address created successfully") {
      toast.success(message);
      AddNewAddressModalRef.current.close()
      dispatch(userAddressList());
      setPayload({name: "",phone_number: "",country_id: "",state_id: "",city_id: "",area: "",address: "",landmark: "",address_type: "",zipcode: ""});
    }
    if (message == " address deleted successfully") {
      toast.success(message);
      dispatch(userAddressList());
    }
    dispatch(clearMessage());
  }, [error,message]);

  const toggleCountryDropdown = () => {
    setCountryDropdownVisible((prev) => !prev);
    setCityDropdownVisible(false);
  };

  const toggleCityDropdown = () => {
    setCityDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
  };

  const toggleStateDropdown = () => {
    setStateDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
  };

  const selectCountry = (country) => {
    setSelectedCountry(country.name);
    setPayload({
      ...payload,
      country_id: country.id,state_id: "",city_id: ""
    });
    setCountryDropdownVisible(false);
    dispatch(getStateByCountry(country.id))

    setSelectedState("");
    setSelectedCity("");
  };

  const selectState = (state) => {
    setSelectedState(state.name);
    setPayload({
      ...payload,
      state_id: state.id,city_id: ""
    });
    setStateDropdownVisible(false);
    dispatch(getCitiesByState(state.id))
    setSelectedCity("");
    };

  const selectCity = (city) => {
    setSelectedCity(city.name);
    setPayload({
      ...payload,
      city_id: city.id,
    });
    setCityDropdownVisible(false);
  };

  const updateData = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    dispatch(userAddressList());
    dispatch(getCountrie());
  },[])

  const handleSecond = () => {
    document.getElementById('add_new_address_modal_form').showModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    for (const [key, value] of Object.entries(payload)) {
      if (value == "") {
      } else {
        formData.append(key, value);
      }
    }
    dispatch(userAddressAdd(formData));
  };

  return (
    <div className="px-4 flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          {
            name: "Addresses",
            path: "",
          },
        ]}
      />
      {/* save address card */}
      <div className="flex flex-col gap-3">
        {loading?(
          <div className="flex justify-center items-center h-[80vh]">
            <Loader/>
          </div>
        ):(
          <>
            <div className="grid grid-cols-2 gap-6 ">

              {profileSetting?.length>0 && profileSetting.map((address) => (
                <div className="flex relative w-full">
                  <div
                    // key={box.id}
                    className="cursor-pointer w-full  relative border-2 p-3 md:p-6 rounded-md "
                    //   onClick={() => handleRectangleClick(box.id)}
                  >
                    {/* edit modal address from button */}
                    <div
                      className="absolute right-3 top-1 text-sm text-[#C19A6B]"
                    >
                      <button
                      disabled={loading} 
                      onClick={() => dispatch(userAddressDelete(address.id))}
                      className="pr-2 hover:underline cursor-pointer">
                        Delete
                      </button>
                      <button 
                      onClick={() => {
                        setEditId(address.id)
                        document.getElementById("add_Edit_address_modal_form").showModal()
                      }}
                      className="hover:underline cursor-pointer">
                        Edit
                      </button>
                    </div>

                    <div className="bg-white flex flex-col md:gap-2 w-full h-full rounded-md px-2 md:px-5 py-2">
                      {/* Name */}
                      <div className="flex ">
                        <div className="text-[#8C8C8C] text-sm w-20">Name:</div>
                        <div className="text-sm flex-1">{address.name}</div>
                      </div>

                      {/* Phone */}
                      <div className="flex ">
                        <div className="text-[#8C8C8C] text-sm w-20">Phone:</div>
                        <div className="text-sm flex-1">{address.phone_number}</div>
                      </div>

                      {/* Email */}
                      <div className="flex ">
                        <div className="text-[#8C8C8C] text-sm w-20">Country:</div>
                        <div className="text-sm flex-1">{address.country.name}</div>
                      </div>

                      {/* Address */}
                      <div className="flex">
                        <div className="text-[#8C8C8C] text-sm w-20">Address:</div>
                        <div className="text-sm flex-1 text-nowrap">
                          {address.address}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/* add new */}
              <div className="flex relative w-full">
                <div className="w-full h-full">
                  <div
                    onClick={handleSecond}
                    className="cursor-pointer w-full py-10 h-full flex flex-col items-center justify-center  relative border-2 rounded-md"
                  >
                    <div className="bg-[#D9D9D9] rounded-full w-11 h-11 flex items-center justify-center ">
                      <svg
                        width="25"
                        height="26"
                        viewBox="0 0 25 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.3438 2.625V23.3783"
                          stroke="white"
                          stroke-width="4.15094"
                          stroke-linecap="square"
                        />
                        <path
                          d="M2.10156 13.0039H22.5851"
                          stroke="white"
                          stroke-width="4.15094"
                          stroke-linecap="square"
                        />
                      </svg>
                    </div>
                    <p className="text-sm font-normal pt-2">Add New ddress</p>
                  </div>
                </div>
              </div>

            </div>          
          </>
        )}

      </div>
      {/* Add new address modal */}
      <dialog
        ref={AddNewAddressModalRef}
        id="add_new_address_modal_form"
        className="modal "
      >
        <div className="modal-box bg-white max-w-3xl  rounded-md">
          <div className="flex justify-between items-center">
            <h3 className="font-bold text-base md:text-lg tracking-wider">
              Add New Address
            </h3>
            <button
              className="btn btn-sm btn-circle btn-ghost "
              onClick={() => AddNewAddressModalRef.current.close()}
            >
              ✕
            </button>
          </div>


          <form className="md:space-y-4 overflow-hidden overflow-y-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
              <div>
                <input
                  placeholder="Name"
                  type="text"
                  id="field1"
                  name="name"
                  value={payload.name}
                  onChange={updateData}
                  className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                />
              </div>
              <div>
                <input
                  placeholder="Phone Number"
                  type="text"
                  id="field2"
                  name="phone_number"
                  value={payload.phone_number}
                  onChange={updateData}
                  className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">

              <div className="w-full">
                <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                  COUNTRY
                </label>
                <div className="relative w-full">
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                    type="text"
                    value={selectedCountry}
                    readOnly
                    onClick={toggleCountryDropdown}
                    placeholder="Select Your Country"
                  />
                  <svg
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                    onClick={toggleCountryDropdown}
                    width="11"
                    height="6"
                    viewBox="0 0 11 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742"
                      fill="#6B6B6B"
                    />
                  </svg>

                  {isCountryDropdownVisible && (
                    <div className="absolute z-10 bg-white border border-[#EBF0ED] h-fit overflow-hidden  rounded-lg mt-2 w-full">
                      {countries?.map((country) => (
                        <div
                          key={country.id}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => selectCountry(country)}
                        >
                          {country.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full">
                <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                  STATE
                </label>
                <div className="relative w-full">
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                    type="text"
                    value={selectedState}
                    readOnly
                    onClick={toggleStateDropdown}
                    placeholder="Select Your State"
                  />
                  <svg
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                    onClick={toggleStateDropdown}
                    width="11"
                    height="6"
                    viewBox="0 0 11 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742"
                      fill="#6B6B6B"
                    />
                  </svg>

                  {isStateDropdownVisible && (
                    <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                      {states?.map((state) => (
                        <div
                          key={state.id}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => selectState(state)}
                        >
                          {state.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">

              <div className="w-full">
                <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                  CITY
                </label>
                <div className="relative w-full">
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                    type="text"
                    value={selectedCity}
                    readOnly
                    onClick={toggleCityDropdown}
                    placeholder="Select Your City"
                  />
                  <svg
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                    onClick={toggleCityDropdown}
                    width="11"
                    height="6"
                    viewBox="0 0 11 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742"
                      fill="#6B6B6B"
                    />
                  </svg>

                  {isCityDropdownVisible && (
                    <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                      {citiesByState?.map((city) => (
                        <div
                          key={city.id}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => selectCity(city)}
                        >
                          {city.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div>
                <input
                  placeholder="Zip Code"
                  type="text"
                  id="field6"
                  name="zipcode"
                  value={payload.zipcode}
                  onChange={updateData}
                  className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
              <div>
                <input
                  placeholder="Area"
                  type="text"
                  id="field7"
                  name="area"
                  value={payload.area}
                  onChange={updateData}
                  className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                />
              </div>
              <div>
                <input
                  placeholder="Landmark"
                  type="text"
                  id="field8"
                  name="landmark"
                  value={payload.landmark}
                  onChange={updateData}
                  className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <input
                  placeholder="Address"
                  type="text"
                  id="field7"
                  name="address"
                  value={payload.address}
                  onChange={updateData}
                  className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                />
              </div>
              <div className="flex  gap-4 justify-between">
                <div
                  onClick={() => setPayload({...payload,address_type:"home"})}
                  className={`cursor-pointer py-2 md:py-3 px-14 border ${
                    payload.address_type === "home"
                      ? "border-[#C19A6B] text-black font-[400]"
                      : "text-[#A5A5A5]"
                  } text-sm rounded tracking-wide bg-white`}
                >
                  Home
                </div>
                <div
                  onClick={() => setPayload({...payload,address_type:"office"})}
                  className={`cursor-pointer py-2 md:py-3 px-14 border ${
                    payload.address_type === "office"
                      ? "border-[#C19A6B] text-black font-[400]"
                      : "text-[#A5A5A5]"
                  } text-sm rounded tracking-wide`}
                >
                  Office
                </div>
              </div>
            </div>
            <div className="flex  justify-end mt-2">
              <div disabled={loading} onClick={handleSubmit} className=" cursor-pointer h-12 py-2 md:py-3 px-14 bg-[#C19A6B] text-sm md:text-base rounded-md text-white font-[500] tracking-wider">
              {!loading ? (
              "Save"
            ) : (
              <span className="loading loading-spinner loading-md"></span>
            )}
              </div>
            </div>
          </form>

        </div>
      </dialog>

      <UserDashboardAddresesEditAddressForm editId={editId} />
    </div>
  );
}
