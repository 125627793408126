import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  auctionProductDelete,
  auctionProductsSearch,
  clearMessage,
  getAuctionProducts,
} from "../../../store/categorySlice";

import { toast } from "react-toastify";
import BreadCrums from "../../Common/BreadCrums";
import Loader from "../../Common/Loader";
import DeletePopup from "../../Common/DeletePopup";

const UserDashboardAuctionProduct = () => {
  const dispatch = useDispatch();
  const { data, message, error, loading } = useSelector(
    (state) => state.category
  );
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search) {
      dispatch(auctionProductsSearch(search));
    } else {
      dispatch(getAuctionProducts());
    }
  }, [search]);

  // State for delete popup visibility
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [adProductId, setAdProductId] = useState(null);

  // Handle delete button click
  const handleDeleteClick = (id) => {
    setAdProductId(id);
    setShowDeletePopup(true); // Show the delete popup
  };

  // Handle delete confirmation in popup
  const handleDeleteConfirmation = () => {
    dispatch(auctionProductDelete(adProductId)).then(() => {
      dispatch(getAuctionProducts());
    });
    setShowDeletePopup(false); // Hide the popup after confirmation
  };

  // Handle delete cancellation in popup
  const handleDeleteCancel = () => {
    setShowDeletePopup(false); // Hide the popup on cancel
  };

  // pagination-state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();

  // pagination Function components
  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    // console.log(totalNumberOfPages);
    const pages = [];

    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalNumberOfPages, currentPage + 1);

    if (endPage - startPage < 2) {
      if (startPage === 1) {
        endPage = Math.min(totalNumberOfPages, startPage + 2);
      } else if (endPage === totalNumberOfPages) {
        startPage = Math.max(1, endPage - 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-2 py-1 rounded ${
            currentPage === i
              ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
              : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
          }`}
        >
          {i}
        </button>
      );
    }
    // console.log(pages);
    return <div className="pagination flex gap-2">{pages}</div>;
  }

  // pagination data handling + handle page change
  useEffect(() => {
    if (data?.pagination && data?.pagination?.total) {
      let totalLength = data?.pagination?.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [data]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(getAuctionProducts({ page }));
    }
  };

  // pagination index variable
  const totalAdProducts = data?.pagination?.total || 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalAdProducts);

  useEffect(() => {
    if (message) {
      toast.success(message);
    } else if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [message, error]);

  return (
    <>
      <div className="px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Auction Product",
              path: "",
            },
          ]}
        />
        {/* search */}
        <div className="flex justify-between items-center gap-5">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/search.svg"
              alt=""
              className="absolute left-3"
            />
            <input
              className="w-full py-[10px] px-9 text-[8px] md:text-[15px] rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-medium"
              type="search"
              placeholder="Search Product by name and category..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <Link
            to="/user-dashboard/auction-product/create-product"
            className=" bg-[#C19A6B] px-5 py-2 md:px-9 md:py-3 xl:py-[15px] text-nowrap text-[8px] md:text-[15px] rounded-lg text-white  font-semibold"
          >
            Add Product
          </Link>
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            {/* Table Header */}
            <div className="min-w-[568px] md:min-w-[1024px] lg:w-full">
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[25%] text-left">PRODUCTS</span>

                <span className="w-[15%] text-left">PRICE</span>

                <span className="w-[15%] text-left">CATEGORY</span>

                <span className="text-left w-[40%]">DATE&TIME</span>

                <span className="w-[25%] text-left">ACTION</span>
              </div>

              {/* Table Data Row */}
              {loading ? (
                <div className="flex justify-center py-4">
                  <Loader />
                </div>
              ) : data?.products?.data?.length > 0 ? (
                data?.products?.data?.map((item) => {
                  return (
                    <>
                      <div className="flex text-[10px] md:text-sm items-center justify-between text-[#18120F] p-2 md:p-4 border-b">
                        <div className="flex w-[25%] text-left items-center">
                          <img
                            src={item.image}
                            alt="Product"
                            className="w-8 h-8 md:w-10 md:h-10 rounded-lg mr-4"
                          />
                          <span className=" text-[#18120F] font-[600] ">
                            {item.name}
                          </span>
                        </div>

                        <span className="flex  font-[600] text-[#18120F] w-[15%] text-left">
                          ${item.price}
                        </span>

                        <span className="flex  font-[600] w-[15%] text-left">
                          {item.category_name}
                        </span>

                        <span className="text-left w-[40%] items-center gap-2 font-[600] ">
                          <span className="bg-[#FAFAFA] py-1 px-2 border border-[#EBF0ED] text-[#828282] rounded-xl">
                            {" "}
                            {item.bidstartdatetime}
                          </span>
                          <span>to</span>
                          <span className="bg-[#FAFAFA] py-1 px-2 border border-[#EBF0ED] text-[#828282] rounded-xl">
                            {item.bidenddatetime}
                          </span>
                        </span>

                        <div className="flex items-center gap-2  font-[600] w-[25%] text-left">
                          <img
                            className="w-[28px] md:w-[38px]"
                            src="/assets/icons/view.svg"
                            alt=""
                          />
                          <Link
                            to={`/user-dashboard/auction-product/edit-product/${item.id}`}
                          >
                            <img
                              className="w-[28px] md:w-[38px]"
                              src="/assets/icons/Edit.svg"
                              alt=""
                            />
                          </Link>

                          <img
                            className="w-[28px] md:w-[38px] cursor-pointer"
                            src="/assets/icons/delete1.svg"
                            alt=""
                            onClick={() => handleDeleteClick(item.id)}
                          />
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="text-center font-bold tracking-wide py-4">
                  No Products Found
                </div>
              )}
            </div>
          </div>
          {showDeletePopup && (
            <DeletePopup
              isVisible={showDeletePopup}
              onDeleteConfirmation={handleDeleteConfirmation} // Directly pass the function
              onDeleteCancel={handleDeleteCancel}
            />
          )}
        </div>

        {/* Pagination start here */}
        {!loading && !search && data?.products?.data?.length > 0 ? (
          <>
            <div className="flex justify-between items-center my-4">
              <span className="text-sm text-[#6B6B6B] font-semibold">
                SHOWING {startIndex + 1}-{endIndex} OF {totalAdProducts} RESULTS
              </span>
              <div className="flex items-center gap-3 bg-white rounded-lg px-2">
                {/* Previous Button */}
                <div
                  className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                    currentPage === 1 ? "opacity-50" : ""
                  }`}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <svg
                    className={`${
                      currentPage === 1
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-blue-500"
                    }`}
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 13L1 7L7 1"
                      stroke="#828282"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* Page Numbers */}
                <div className="flex items-center gap-2">
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                </div>
                {/* Next Button */}
                <div
                  className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                    currentPage === totalPages ? "opacity-50" : ""
                  }`}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <svg
                    className={`${
                      currentPage === totalPages
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-blue-500"
                    }`}
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13L7 7L1 1"
                      stroke="#828282"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {/* Pagination end here */}
      </div>
    </>
  );
};

export default UserDashboardAuctionProduct;
