import SingleProductPage from "../../pages/Main-Shop/Single-Product-Page/SingleProductPage";

const SingleProductPageRoutes = [
  {
    path: "/single-product/:slug",
    element: <SingleProductPage />,
  },
];

export default SingleProductPageRoutes;
