import React, { useRef } from "react";
import ProductCard from "../Common/ProductCard";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineHeart, AiOutlineUser } from "react-icons/ai";
import { HiMiniCheckCircle } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { auctionSearch, mainShopSearch } from "../../store/frontendSlice";
import { FaPause, FaPlay } from "react-icons/fa";
import VideoCard from "../Common/VideoCard";

export default function AuctionSearch() {
  const dispatch = useDispatch();
  const { aucSearch } = useSelector((state) => state.frontend);
  const [search, setSearch] = useState("");

  const [searchHistory,setSearchHistory]=useState(localStorage.getItem("auctionSearch")?JSON.parse(localStorage.getItem("auctionSearch")):[])
  useEffect(() => {
    
    dispatch(auctionSearch(search));
  }, [search]);

  return (
    <>
      <div className="px-3 md:px-12 xl:px-20 py-5 md:py-10 flex flex-col gap-10">
        {/* search-bar */}
        <div className="w-full flex items-center gap-[2px] md:gap-6">
          <div className="w-full py-2 px-[12px] md:pl-6 pr-2 text-[5px] md:text-xs flex items-center gap-1 md:gap-[10px] lg:gap-5 bg-white rounded-xl shadow-xl">
            <div className="w-full relative">
              <input
                className="p-[12px] md:p-[15px] w-full rounded-[10px] outline-none text-xs px-6 md:px-8 bg-[#F9F9F9] text-[#828282] font-normal"
                placeholder="Search Products..."
                type="text"
                name=""
                id=""
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <img
                className="absolute left-2 top-[14px] md:left-3 md:top-4 "
                src="/assets/icons/search.svg"
                alt=""
              />
            </div>
            <Link
              onClick={(e) => {
                e.preventDefault();
                let search1 = localStorage.getItem("auctionSearch");
                let arrSearch = search1 ? JSON.parse(search1) : [];

                if (search) {
                  arrSearch.push(search);
                  setSearchHistory(arrSearch);
                  localStorage.setItem(
                    "auctionSearch",
                    JSON.stringify(arrSearch)
                  );
                }
              }}
              className="bg-[#C19A6B] rounded-md md:rounded-xl text-[5px] md:text-base text-white text-xs px-2 py-2 md:px-[55px] md:py-3 font-semibold "
            >
              Search
            </Link>
          </div>
          <Link
            to="/ai-search"
            className=" p-2 md:p-3 rounded-full shadow-xl flex items-center justify-center cursor-pointer"
          >
            <img
              className="w-6 h-6 md:w-fit md:h-fit"
              src="/assets/icons/searchaism.svg"
              alt=""
            />
          </Link>{" "}
          <Link
            to="/"
            className="p-2 md:p-4 rounded-full shadow-xl flex items-center justify-center cursor-pointer"
          >
            <img
              className="w-6 h-6 md:w-[21px] md:h-[21px]"
              src="/assets/icons/cancel.svg"
              alt=""
            />
          </Link>
        </div>
        {/* history */}
        <div className="w-full flex flex-col gap-3">
          <h2 className="text-[30px] font-semibold">History</h2>
          <div className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-2 md:gap-4">
            {searchHistory
              .reverse()
              .slice(0, 8)
              .map((item) => (
                <span
                  className=" text-[10px] font-semibold text-nowrap md:text-sm py-[6px] md:py-2 md:px-4  rounded-full text-center shadow-lg"
                  onClick={(e) => setSearch(item)}
                >
                  {item}
                </span>
              ))}
          </div>
        </div>
        {/* trending */}
        <div className="w-full flex items-start justify-between gap-3 lg:gap-8 xl:gap-16">
          <div className="flex flex-col gap-3">
            <h2 className="text-[30px] md:text-[20px] font-semibold text-nowrap">
              Trending Products
            </h2>
            <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 md:gap-5 xl:gap-8">
              {aucSearch?.categoryProducts?.length > 0 &&
                aucSearch?.categoryProducts.map((item) =>
                  item?.products.map((item1) => (
                    <ProductCard key={item1._id} item={item1} auc={true} />
                  ))
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
