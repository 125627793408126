import UserDashboardBuyingChatUserPage from "../../../pages/User-Dashbard/User-Dashbaord-Buying-Page/User-Dadashboard-Chat-User-Page/UserDashboardBuyingChatUserPage";

const userChatRoutes = [
  {
    path: "/user-dashboard/chat",
    element: <UserDashboardBuyingChatUserPage />,
  },
];

export default userChatRoutes;
