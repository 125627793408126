import React, { useEffect, useState } from "react";

// import "./coupon-store-owner.css";
import ShelfCouponProduct from "./MyShelfCouponProducts";
import StoreInfo from "../../review-from-customers-section/StoreInfo";
import ProductCard from "../../Common/ProductCard";

function CouponStore() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    // Fetch data from the backend
    const fetchData = async () => {
      try {
        const response = await fetch("/topSell.json");
        const data = await response.json();
        setItems(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex bg-white w-full ">
        <div className="flex flex-col lg:flex-row gap-3 lg:gap-8 mt-1 ">
          <StoreInfo />
          <div className=" flex w-full lg:w-2/5 ">
            <div className="flex flex-row items-center lg:flex-col lg:items-start justify-between gap-4 lg:gap-2 mt-4">
              <p className=" font-bold text-base">Offer Expires In:</p>
              <div className="font-black text-2xl flex w-[250px] gap-2 ">
                <div className="flex flex-col items-center">
                  <span>01</span>
                  <span className="font-bold text-xs">Days</span>
                </div>
                :
                <div className="flex flex-col items-center">
                  <span>22</span>
                  <span className="font-bold text-xs">Hours</span>
                </div>
                :
                <div className="flex flex-col items-center">
                  <span>34</span>
                  <span className="font-bold text-xs">Min</span>
                </div>
                :
                <div className="flex flex-col items-center">
                  <span>22</span>
                  <span className="font-bold text-xs">Sec</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-y-auto p-2 grid justify-center  grid-cols-2 lg:grid-cols-3 gap-4 ">
        {items.slice(0, 6).map((item) => (
          <ProductCard key={item._id} item={item} />
        ))}
      </div>
    </div>
  );
}

export default CouponStore;
