import React, { useState, useEffect, useRef } from "react";
import "./otp.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, verifyOtp } from "../store/categorySlice";

export default function VerifyOtp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { message, error } = useSelector((state) => state.category);

  const [inputs, setInputs] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const handleChange = (index, event) => {
    const value = event.target.value;

    if (value.length > 1) return;

    const newInputs = [...inputs];
    newInputs[index] = value;

    setInputs(newInputs);

    if (value && index < inputs.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleOTPSubmit = (e) => {
    e.preventDefault();
    const OTP = inputs.join("");
    dispatch(verifyOtp({otp:OTP}))
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      localStorage.setItem("step", 0)
      navigate("/user-dashboard");
    }
    if (error) {
      toast.error(error);
    }

    dispatch(clearMessage());
  }, [message, error, dispatch, navigate]);

  return (
    <div className="h-[100vh] flex justify-center items-center px-4 md:px-0 bg-[#FFFAF4]">
      <div
        className="w-full md:w-fit p-4 md:p-14 bg-white flex flex-col items-center rounded-3xl"
        style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
      >
        <img className="mx-auto" src="/assets/images/login-logo.svg" alt="" />
        <div className="font-bold tracking-wide text-xl md:text-3xl mt-5 mb-2 text-center">
          Verify OTP
        </div>
        <div className="text-xs md:text-sm text-center font-bold tracking-wide">
          Enter your OTP sent to your registered email{" "}
        </div>
        <div className="w-full md:w-[30rem] mt-6 flex flex-col">
          <label className="form-control mt-3">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter OTP
              </span>
            </div>
            <div className="flex mx-auto items-center gap-4">
              {inputs.map((value, index) => (
                <span key={index} className="email">
                  <input
                    type="number"
                    className="w-7 md:w-10 outline-none border-b-2 border-black pb-2 text-center text-black text-lg font-semibold "
                    value={value}
                    maxLength={1}
                    onChange={(event) => handleChange(index, event)}
                    ref={(input) => (inputRefs.current[index] = input)}
                  />
                </span>
              ))}
            </div>
          </label>
          <div
            onClick={handleOTPSubmit}
            className="bg-[#C19A6B] cursor-pointer text-center w-full rounded-lg  py-3 text-xs md:text-[15px] mt-[1.5rem] text-white font-bold tracking-wider"
          >
            Proceed
          </div>
        </div>
        <div className="flex items-center justify-center mt-4 gap-3">
          <span className="text-xs md:text-sm text-[#18120F] font-normal">
            Didn’t receive an OTP? Resend in 1 min
          </span>
          <button className="text-[#C19A6B] text-xs md:text-[15px] font-semibold">
            Resend
          </button>
        </div>
      </div>
    </div>
  );
}
