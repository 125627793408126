import { useEffect, useRef, useState } from "react";
import { BiMenu } from "react-icons/bi";
import { IoCloseOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserDashboardBuyingSidebar from "../User-Dashboard-Sidebar/UserDashboardBuyingSidebar";
import UserDashboardSellingSidebar from "../User-Dashboard-Sidebar/UserDashboardSellingSidebar";
import Notifications from "../../Common/Notifications/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { notifications } from "../../../store/categorySlice";

const UserDashboardTopBar = ({profileData}) => {
  const [activeSection, setActiveSection] = useState("Buying");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const {message,error}=useSelector(state=>state.category)
  const [notification, setNotification] = useState(false);
  const [dpOptions, setDpOptions] = useState(false);
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[2];
  const { token } = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const [closing, setClosing] = useState(false);

  const sidebarRef = useRef(null);

  const toggleSidebarOpen = () => {
    if (isSidebarOpen) {
      setClosing(true);
      setTimeout(() => {
        setIsSidebarOpen(false);
        setClosing(false);
      }, 1000);
    } else {
      setIsSidebarOpen(true);
    }
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setClosing(true);
      setTimeout(() => {
        setIsSidebarOpen(false);
        setClosing(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    dispatch(notifications())
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    if (isSidebarOpen) {
      setClosing(true);
      setTimeout(() => {
        setIsSidebarOpen(false);
        setClosing(false);
      }, 1000);
    }
    
  }, [location.pathname]);

  const handleClick = (section) => {
    setActiveSection(section);
  };
  const handlenotification = () => {
    setNotification(!notification);
    setDpOptions(false);
  };

  const handleMSG = () => {
    console.log("heeeeree");
    const tok = token;
    // const url = `${process.env.REACT_APP_CHAT_LINK}${token}`;
    const url = `https://messenger.yugoiptv.com/t/${tok}`;
    window.location.href = url;
};
const navigate=useNavigate()
  useEffect(() => {
    if (typeof message === 'string' && message.length > 0) {
      if (message.includes("Unauthenticated")) {
        navigate("/dashboard/login");
      }
    }
    
 
    console.log(error);
    
   
    if (typeof error === 'string' && error.length > 0) {
      if (error.includes("Unauthenticated")) {
        navigate("/dashboard/login");
      }
    }
  },[message,error])
  return (
    <>
      <div className="px-4 py-2 xl:px-[57px] flex justify-between items-center w-full z-30 fixed left-0 top-0 bg-white">
        <Link
        to={`/`}
        className="hidden xl:block">
          <img
            src="/assets/icons/userdashboard/wizalot.svg"
            className="w-24 h-12  xl:w-[182px] xl:h-16"
            alt="Wizalot Logo"
          />
        </Link>

        {/* For mobile */}
        <div className="block xl:hidden">
          <BiMenu className="w-7 h-7" onClick={toggleSidebarOpen} />
        </div>

        <div className="flex items-center justify-center p-1 bg-[#FCFCFC] rounded-full">
          <Link
            to="/user-dashboard"
            className={`flex items-center justify-center w-24 h-7 md:w-[139px] md:h-[42px] rounded-full transition-all duration-500 ${
              activeSection === "Buying" ? "bg-white" : "bg-transparent"
            }`}
            onClick={() => handleClick("Buying")}
          >
            <p className="text-[11px] md:text-[13px] font-semibold">Buying</p>
          </Link>

          <Link
            to="/user-dashboard/selling"
            className={`flex items-center justify-center w-24 h-7 md:w-[139px] md:h-[42px] rounded-full transition-all duration-500 ${
              activeSection === "Selling" ? "bg-white" : "bg-transparent"
            }`}
            onClick={() => handleClick("Selling")}
          >
            <p className="text-[11px] md:text-[13px] font-semibold">Selling</p>
          </Link>
        </div>
        <div className="flex gap-4 items-center">
          <Link
            // to="/user-dashboard/chat"
            onClick={handleMSG}
            className="bg-[#FCFCFC] relative h-11 w-11 rounded-full hidden xl:flex items-center justify-center"
          >
            <img src="/assets/icons/userdashboard/chat2.svg" alt="Chat Icon" />
            <div className="absolute top-1 right-0 w-2 h-2 bg-[#C19A6B] rounded-full"></div>
          </Link>
          <div
            onClick={handlenotification}
            className="bg-[#FCFCFC] relative h-11 w-11 rounded-full hidden xl:flex items-center justify-center"
          >
            <img
              src="/assets/icons/userdashboard/notify.svg"
              alt="Notification Icon"
            />
            <div className="absolute top-1 right-0 w-2 h-2 bg-[#C19A6B] rounded-full"></div>
          </div>

          <div className="flex relative items-center gap-2">
            <div className="flex items-center gap-2">
              <img
                onClick={() => setDpOptions(!dpOptions)}
                src={`${profileData?.image ? profileData?.image : '/assets/icons/userdashboard/dp2.svg'}`}
                className="w-9 h-9 md:w-11 md:h-11 border rounded-lg border-[#C19A6B]"
                alt="User Avatar"
              />
              <h2 className="font-semibold text-base tracking-wider hidden xl:block">
              {profileData?.name?.substring(0, 6)}
              </h2>
            </div>
            {dpOptions && (
              <div className="md:hidden absolute flex flex-col gap-3 top-[110%] right-5 bg-white shadow-md rounded-sm mx-auto p-6 font-semibold tracking-wider text-[10px] ">
                <Link
                  onClick={() => setDpOptions(false)}
                  to="/user-dashboard/profile"
                  className="flex items-center gap-2"
                >
                  <img
                    className="w-4 h-4"
                    src="/assets/icons/person.svg"
                    alt="Profile Icon"
                  />
                  <span>Profile</span>
                </Link>
                <Link
                  onClick={() => { setDpOptions(false);  handleMSG()}}
                  // to="/user-dashboard/chat"
                  className="flex items-center gap-2"
                >
                  <img
                    src="/assets/icons/userdashboard/chat2.svg"
                    className="w-4 h-4"
                    alt="Chat Icon"
                  />
                  <span>Chat</span>
                </Link>
                <div
                  onClick={handlenotification}
                  className="flex items-center gap-2"
                >
                  <img
                    className="w-4 h-4"
                    src="/assets/icons/userdashboard/notify.svg"
                    alt="Notification Icon"
                  />
                  <span>Notifications</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* For mobile sidebar */}
      {isSidebarOpen && (
        <div
          ref={sidebarRef}
          className={`flex flex-col xl:hidden fixed top-0 left-0 h-screen bg-[#C19A6B] z-50 w-[250px] xl:w-[30%] rounded-tr-xl rounded-br-xl ${
            closing ? "animate-sm-out" : "animate-sm"
          }`}
        >
          <div className="w-full relative py-4 px-4 flex items-center justify-between">
            <Link className="flex gap-2 items-center">
              <img
                src="/assets/images/login-logo.svg"
                className="w-full h-10 lg:h-12"
                alt="Login Logo"
              />
              <h1 className="font-bold text-xl lg:text-3xl text-white">
                Wizalot
              </h1>
            </Link>
            <IoCloseOutline
              onClick={toggleSidebarOpen}
              className="absolute right-6 z-10 w-8 h-8 text-white"
            />
          </div>
          {lastSegment === "selling" ? (
            <UserDashboardSellingSidebar />
          ) : (
            <UserDashboardBuyingSidebar />
          )}
        </div>
      )}
      <Notifications
        notification={notification}
        setNotification={setNotification}
      />
    </>
  );
};

export default UserDashboardTopBar;
