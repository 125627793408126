import React, { useState } from "react";
import { FaHeart } from "react-icons/fa";

export default function AuctionPopularProductCard({ item, view, key }) {
  const [isHeartFilled, setIsHeartFilled] = useState(false);

  const handleHeartClick = () => {
    setIsHeartFilled(!isHeartFilled);
  };
  const cardClass =
    view === 1
      ? "border flex  relative w-full  rounded-2xl"
      : view === 3
      ? "border relative max-w-[416px] rounded-3xl "
      : "border relative max-w-[240px]   rounded-2xl ";
  const flex =
    view === 1 ? "flex gap-4 md:gap-8  w-full " : view === 3 ? "" : "";
  const locationdateall =
    view === 1
      ? "flex justify-between"
      : view === 3
      ? "flex justify-between items-center px-3 pb-1"
      : "flex justify-between items-end";
  const locationdate =
    view === 1
      ? " absolute text-xs md:text-[17px] xl:text-2xl right-4 bottom-2 md:bottom-4 text-sm font-[400]   "
      : view === 3
      ? "text-[10px] md:text-sm lg:text-[17px] xl:text-2xl  font-[400]  "
      : "text-sm font-[400]  ";
  const price =
    view === 1
      ? "absolute bottom-1 md:bottom-3 text-base md:text-[30px] xl:text-4xl font-bold"
      : view === 3
      ? "text-[19px] md:text-[24px] lg:text-[29px] xl:text-[42px] font-bold "
      : "text-[17px] md:text-[21px] text-2xl font-bold mt-2";

  const name =
    view === 1
      ? "font-semibold md:mt-2 text-base md:text-[22px] xl:text-[32px]  text-nowrap overflow-hidden"
      : view === 3
      ? "font-bold text-[11px] md:text-[19px] xl:text-[28px] px-3 mt-3 text-nowrap overflow-hidden"
      : "font-[600] text-[11px] md:text-sm xl:text-base text-nowrap overflow-hidden";

  const right =
    view === 1 ? " py-1  w-[60%]" : view === 3 ? "" : "px-3 mt-0 py-1";
  const heart =
    view === 1
      ? "absolute top-2 right-2  z-10"
      : view === 3
      ? "absolute top-2 right-2 items-center z-10"
      : "absolute top-2 right-2  items-center z-10";
  const hearticon =
    view === 1
      ? "h-5 w-5 md:w-7 md:h-7 xl:w-10 xl:h-10 cursor-pointer"
      : view === 3
      ? "h-5 w-5 md:w-7 md:h-7 xl:w-10 xl:h-10 cursor-pointer"
      : "h-5 w-5 md:w-6 md:h-6 xl:w-8 xl:h-8 cursor-pointer";

  const imgdiv =
    view === 1
      ? "w-[120px] h-[120px] md:w-[178px] md:h-[178px]  xl:w-[250px] xl:h-[250px] "
      : view === 3
      ? "w-full h-[200px] md:h-[270px] lg:h-[346px] xl:h-[493px] transition-transform duration-500 ease-in-out hover:scale-110"
      : "w-full h-[200px] md:h-[250px] xl:h-[282px] transition-transform duration-500 ease-in-out hover:scale-110 ";

  return (
    <>
      <div key={key} className={cardClass}>
        <div className={flex}>
          <div className="">
            <div className=" overflow-hidden  rounded-xl relative ">
              <img src={item.img} className={imgdiv} alt="" />
            </div>
          </div>
          {/* .......................... */}
          <div className={right}>
            <h2 className={name}>{item.name}</h2>
            {view === 1 && (
              <>
                <div className="flex flex-wrap gap-2 opacity-70  md:mt-4 text-xs md:text-[17px] xl:text-2xl overflow-hidden">
                  <p>{item.brand} |</p>
                  <p>{item.modal} |</p>
                  <p>{item.modalyear} |</p>
                  <p>{item.type} |</p>
                  <p>{item.miliage} |</p>
                  <p>{item.fueltype} |</p>
                  <p>{item.gearbox} |</p>
                  <p>{item.color} |</p>
                </div>
              </>
            )}
            <div className={locationdateall}>
              <h2 className={price}>${item.price}</h2>

              <div className={locationdate}>
                {/* <p>{item.location}</p> */}
                <p>{item.date}</p>
              </div>
            </div>
          </div>
        </div>

        {/* .................................... */}
        <div className={heart}>
          <div
            className={` gap-1 p-2 bg-[#1983FF33] rounded-full  flex justify-center items-center ${
              isHeartFilled
                ? "text-blue-500 duration-500 ease-in-out"
                : "text-white"
            }`}
            onClick={handleHeartClick}
          >
            <FaHeart className={hearticon} />
          </div>
        </div>
      </div>
    </>
  );
}
