import React from "react";

export default function PaymentEditModal() {
  return (
    <div>
      {/* Add new address modal */}
      <dialog id="add_new_payment_edit_modal_form" className="modal ">
        <div className="modal-box bg-white max-w-3xl  rounded-md">
          <form method="dialog">
            <button
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              onClick={() =>
                document
                  .getElementById("add_new_payment_edit_modal_form")
                  .close()
              }
            >
              ✕
            </button>
          </form>
          <h3 className="font-bold text-base md:text-lg tracking-wider">
            Edit Card
          </h3>

          <div className="">
            <div className=" py-3">
              <form className="md:space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                  <div>
                    {/* <label htmlFor="field1" className="block text-sm font-medium text-gray-600">Field 1</label> */}
                    <input
                      placeholder="Saad"
                      type="text"
                      id="field1"
                      name="field1"
                      className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                    />
                  </div>
                  <div>
                    {/* <label htmlFor="field2" className="block text-sm font-medium text-gray-600">Field 2</label> */}
                    <input
                      placeholder="1234 1234 1234 1234"
                      type="number"
                      id="field2"
                      name="field2"
                      className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                  <div>
                    {/* <label htmlFor="field3" className="block text-sm font-medium text-gray-600">Field 3</label> */}
                    <input
                      placeholder="12/12/1212"
                      type="date"
                      id="field3"
                      name="field3"
                      className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                    />
                  </div>
                  <div>
                    {/* <label htmlFor="field4" className="block text-sm font-medium text-gray-600">Field 4</label> */}
                    <input
                      placeholder="12321"
                      type="number"
                      id="field4"
                      name="field4"
                      className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                    />
                  </div>
                </div>

                <div className="flex justify-end mt-2">
                  <div className="md:mt-6 cursor-pointer py-2  px-12 md:py-3 md:px-16 text-sm md:text-base bg-[#C19A6B] rounded-md text-white font-[500] tracking-wider">
                    Save
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </dialog>
    </div>
  );
}
