import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { addUserBundle, affiliateAvailableProducts, BundleAffiliateProductSearch, clearMessage } from "../../../../store/categorySlice";
import BreadCrums from "../../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";

export default function VendorBundlesUplaod() {
  const [title, setTitle] = useState([]);
  const { data, message, error, loading } = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(affiliateAvailableProducts());
  }, []);

  useEffect(() => {
    if (message) {
      toast.success(message);
      setSelectAll(false);
      setSelectedIds([])
      navigate(-1);
      dispatch(clearMessage());
    }
    if (error) {
      toast.error(error);
      dispatch(clearMessage());
    }
  }, [message, error]);

  useEffect(() => {
    // console.log(selectedIds)
  }, [selectedIds]);

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedIds(data?.products?.map(product => product.product_id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
    setSelectedIds(prevSelectedIds => {
      if (isChecked) {
        return [...prevSelectedIds, id];
      } else {
        return prevSelectedIds.filter(selectedId => selectedId !== id);
      }
    });

    if (!isChecked) {
      setSelectAll(false);
    } else if (selectedIds.length + 1 === data.products.length) {
      setSelectAll(true);
    }
  };

  // const handleSingleCheckboxChange = (id) => {
  //   if(selectedCardIds.includes(id)){
  //     setSelectedCardIds(selectedCardIds.filter(item => item !== id))
  //   } else {
  //     selectedCardIds.push(id);
  //   }
  // }
  
  // const totalPages = Math.ceil(product.length / itemsPerPage);
  // // console.log(cards, "cards-------");
  // const handlePageChange = (page) => {
  //   if (page >= 1 && page <= totalPages) {
  //     setCurrentPage(page);
  //   }
  // };

  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const paginatedProducts = product.slice(
  //   startIndex,
  //   startIndex + itemsPerPage
  // );
  const handleSubmit = () => {
    if (selectedIds.length < 4) {
      toast.error("Select Atleast Four Products!");
    } else if (title.length == 0 || selectedIds.length < 4) {
      toast.error("Kindly Fill Title Field!");
    } else {
      dispatch(addUserBundle({ title:title, pid: selectedIds }));
    }
  };

  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (data?.total) {
      let totalLength = data.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [data]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(affiliateAvailableProducts({page:page}));
    }
  };

  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }

    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="xl:px-4">
        {/* buttons */}
        <div className="flex justify-between  gap-1  ">
          <BreadCrums
            breadCrum={[
              {
                name: "Bundles",
                path: "/user-dashboard/affiliate-bundles",
              },
              {
                name: "Create Bundles",
              },
            ]}
          />
          <div className="flex flex-row gap-2">
            <button
            disabled={loading}
              onClick={(e) => handleSubmit()}
              className="py-[7px] md:py-[10px] px-5 md:px-9 h-12 text-xs md:text-[15px] bg-[#C19A6B]  rounded-lg text-white  font-semibold"
            >
              {!loading ? (
              "Create Bundle"
            ) : (
              <span className="loading loading-spinner loading-md"></span>
            )}
            </button>
            {/* <button
              onClick={() => document.getElementById("my_modal_8").showModal()}
              className="bg-[#C19A6B] text-white rounded-lg py-[7px] md:py-[10px] px-5 md:px-9 text-xs md:text-[15px]  "
            >
              Change
            </button> */}
          </div>
        </div>
        {/* title */}
        <div>
          <h3 className="text-[#344153] text-sm md:text-base font-semibold">
            Title
          </h3>
          <div className="relative flex items-center w-full ">
            <input
              className="w-full py-2 md:[y-3] pl-4 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-xs md:text-[14px]"
              type="text"
              placeholder="Enter Title "
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </div>
        </div>
        {/* search */}
        <div className="relative flex items-center w-full py-4">
          <svg
            className="absolute left-3"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.75 14.7188L11.5625 10.5312C12.4688 9.4375 12.9688 8.03125 12.9688 6.5C12.9688 2.9375 10.0312 0 6.46875 0C2.875 0 0 2.9375 0 6.5C0 10.0938 2.90625 13 6.46875 13C7.96875 13 9.375 12.5 10.5 11.5938L14.6875 15.7812C14.8438 15.9375 15.0312 16 15.25 16C15.4375 16 15.625 15.9375 15.75 15.7812C16.0625 15.5 16.0625 15.0312 15.75 14.7188ZM1.5 6.5C1.5 3.75 3.71875 1.5 6.5 1.5C9.25 1.5 11.5 3.75 11.5 6.5C11.5 9.28125 9.25 11.5 6.5 11.5C3.71875 11.5 1.5 9.28125 1.5 6.5Z"
              fill="#036666"
            />
          </svg>

          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
            type="search"
            placeholder="Search product ..."
            onChange={(e) => dispatch(BundleAffiliateProductSearch(e.target.value))}
          />
        </div>
        {/* table */}
        <div className="flex justify-center border-gray-500">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden">
            {/* Table Header */}
            <div className="flex  items-center p-4 border-b text-[#6B6B6B] font-semibold text-[12px] border-gray-300">
              <input type="checkbox" checked={selectAll} onChange={handleSelectAll} className="mr-4" />

              <span className="flex flex-1 text-left">PRODUCTS</span>

              <span className="flex flex-1 justify-end">PRICE</span>

              {/* <span className="flex flex-1 text-center">COMMISSION</span> */}
              <span className="flex flex-1 justify-end">TOTAL EARNING</span>

              {/* <span className="flex  mr-2  text-center ">ACTION</span> */}
            </div>

            {/* Table Data Row */}
            {data?.products?.length > 0?
              data.products.map((item, index) => (
                <>
                  <div
                    className="flex items-center text-[#18120F]  p-4 border-b "
                    key={item.id}
                  >
                    <input type="checkbox" className="mr-4" checked={selectedIds.includes(item.product_id)}
                     onChange={(event) => handleCheckboxChange(event, item.product_id)} />

                    <div className="flex flex-1 items-center">
                      <img
                        src={item.image}
                        alt="Product"
                        className="w-10 h-10 rounded-lg mr-4"
                      />
                      <div>
                        <p className="text-sm tracking-wide pb-1  text-[#18120F] font-semibold ">
                          {item.name}
                        </p>
                        <p className="text-[#6B6B6B] tracking-wider  font-semibold text-sm">
                          Category:{" "}
                          {item?.category?.name
                            ? item?.category?.name
                            : "no category"}
                        </p>
                      </div>
                    </div>

                    <span className="flex text-sm font-[600] text-[#18120F] flex-1 justify-end">
                      ${item.price}
                    </span>

                    {/* <span className="flex text-sm font-[600] flex-1 text-center">
                      ${item.commission}
                    </span> */}
                    <span className="flex text-sm font-[600] pr-4 flex-1 justify-end">
                      ${0}
                    </span>

                    {/* <div className="flex gap-3 text-sm font-[600]   justify-around">
                      <div className="bg-[#FAFAFA] rounded-xl p-3 border border-[#EBF0ED]">
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16 6.69999C16 7.96011 12.6421 12.3999 8.49999 12.3999C4.35785 12.3999 1 7.96011 1 6.69999C1 5.43988 4.35785 1 8.49999 1C12.6421 1 16 5.43988 16 6.69999Z"
                            stroke="#828282"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.50078 8.68052C9.76878 8.68052 10.482 7.96727 10.482 6.69927C10.482 5.43127 9.76878 4.71802 8.50078 4.71802C7.23278 4.71802 6.51953 5.43127 6.51953 6.69927C6.51953 7.96727 7.23278 8.68052 8.50078 8.68052Z"
                            stroke="#828282"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div> */}
                  </div>
                </>
              )):<p>No Products Found</p>}
          </div>
        </div>

        {/* Pagination */}
        {!loading &&
        data?.products &&
        data?.total > 10 ? (
          <div className="flex flex-col py-5 md:flex-row justify-between items-center">
            <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
              {/* SHOWING {categories.from + "-" + categories.to} OF{" "}
              {categories.total} */}
            </span>
            <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
              {/* Previous Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                }
              </div>
              {/* Next Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}


      </div>

      {/* <ProductBoosterModal
        cards={cards}
        selectedCardIds={selectedCardIds}
        setSelectedCardIds={setSelectedCardIds}
        setCards={setCards}
        recomendedProducts={recomendedProducts}
        data={data}
        setRecomendedProducts={setRecomendedProducts}
      /> */}
    </>
  );
}
