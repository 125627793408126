import React, { useEffect, useState } from "react";
import ProductCard from "../Common/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, topSellingProducts } from "../../store/frontendSlice";
import { toast } from "react-toastify";


export default function TopSellingProducts() {
  const [recomendedProducts, setRecomendedProducts] = useState([]);
  const dispatch = useDispatch();
  const { topSelling } = useSelector((state) => state.frontend);

  useEffect(() => {
    dispatch(topSellingProducts());
  }, []);

  return (
    <>
      <div className={`px-4  lg:px-[57px]`}>
        <div className="max-w-screen-2xl container mx-auto mt-16 mb-6">
          <div className="font-semibold text-[21px] md:text-[22px] lg:text-[30px]">
            Wizalot
          </div>
        </div>
        <div className="max-w-screen-2xl container mx-auto  mb-6">
          {/* Top selling card */}
          <div
            className={`grid md:grid-cols-4 xl:grid-cols-5 grid-cols-2 gap-4`}
          >
            {topSelling?.products?.length>0?
            topSelling.products.map((item) => (
              <ProductCard key={item._id} item={item} getFunction={topSellingProducts}/>
            )):"No Product Found"}
          </div>
        </div>
      </div>
    </>
  );
}
