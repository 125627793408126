import React from "react";
import StoreSquareSmallStoreCard from "../../Store-Square-Small-Store-Components/StoreSquareSmallStoreCard";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SmallStoreSection({storeSqure}) {
  return (
    <>
      <div className="px-4 lg:px-10 xl:px-[57px] mt-16">
        {/* boosted store */}
        <div className="flex justify-between mt-8">
          <h2 className="font-bold text-base xl:text-2xl  ">Small Stores</h2>

          <Link to="/store-square/small-stores">
            <p className="text-[#1983FF] flex items-center gap-1">
              <span>View More</span> <AiOutlineRight />
            </p>
          </Link>
        </div>

        {/* cards */}

        <div className="w-full xl:max-w-screen-2xl container mx-auto mt-2 mb-6 flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 w-full">
          {storeSqure?.smallStores?.length > 0 && storeSqure?.smallStores.map((item) => {
              return(
            <StoreSquareSmallStoreCard data={item} />
         ) })}
          </div>
        </div>
      </div>
    </>
  );
}
