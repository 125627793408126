import React, { useEffect, useRef, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { BiChevronRight } from "react-icons/bi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AuctionProductCard from "../Auction-Common/AuctionProductCard";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addBid, clearMessage, getBidHistory, getSingleForAuction } from "../../../../store/frontendSlice";
import { toast } from "react-toastify";
import { formatNumber } from "../../../../functions/formatNumber";
import BidderChart from "./BidderChart";

// JSON aucSingleProd
const jsonaucSingleProd = {
  days: 2,
  hours: 23,
  minutes: 45,
  seconds: 52,
};

export default function AuctionSingleProduct() {
  const { product } = useParams();
  const dispatch = useDispatch()
  const { aucSingleProd ,message,error,bidHistory} = useSelector((state) => state.frontend);
  const [isHeartFilled, setHeartFilled] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliderimg, setSliderimg] = useState([]);
  const sliderRef = useRef(null);
  const [bid, setBid] = useState(aucSingleProd?.auctionProduct?.highestBid>aucSingleProd?.auctionProduct?.price+1?aucSingleProd?.auctionProduct?.highestBid:aucSingleProd?.auctionProduct?.price+1);
  const [bidButtonIcon, setBidButtonIcon] = useState(0);

  const [relatedProducts, setRelatedProducts] = useState([]);
  const [lastViewedProducts, setLastViewedProducts] = useState([]);

  const [numCards, setNumCards] = useState(2);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleHeart = () => {
    setHeartFilled(!isHeartFilled);
  };

  const handlePlus = () => {
    setBid(parseInt(bid) + 1);
  };

  const handleMinus = () => {

      setBid(parseInt(bid) - 1);
    
  };

  const handleBidIcon = () => {
    setBidButtonIcon((prevIcon) => (prevIcon === 0 ? 1 : 0));

    dispatch(addBid({ product_id: aucSingleProd?.auctionProduct?.id, bid: bid })).then(() => {
      dispatch(getSingleForAuction(product))
    })

    setTimeout(() => {
      setBidButtonIcon(0); 
    }, 3000);
  };

  const handlenext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === sliderimg.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleprev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? sliderimg.length - 1 : prevIndex - 1
    );
  };

  // new
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentIndex(index),
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  // main prouduct aucSingleProd
  useEffect(() => {
    // Fetch aucSingleProd from the backend
    dispatch(getSingleForAuction(product))
  }, []);

  // related products aucSingleProd
 

  const [timeLeft, setTimeLeft] = useState(jsonaucSingleProd);
  useEffect(() => {

    if(message){
      toast.success(message)
    }
    else if (error){
      toast.error(error)
    }
    dispatch(clearMessage())
  }, [message, error])
  useEffect(() => {
    // Function to parse the response string
    const parseTimeString = (timeString) => {
      const timeParts = timeString.split(':').map(part => part.trim());
      return {
        days: parseInt(timeParts[0]),
        hours: parseInt(timeParts[1]),
        minutes: parseInt(timeParts[2]),
        seconds: parseInt(timeParts[3])
      };
    };

    // Set initial state from response
    const initialTimeLeft = parseTimeString(aucSingleProd?.auctionProduct?.remaining_bid_duration?aucSingleProd?.auctionProduct?.remaining_bid_duration:"");
    setTimeLeft(initialTimeLeft);

    const interval = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        const { days, hours, minutes, seconds } = prevTimeLeft;

        if (seconds > 0) {
          return { ...prevTimeLeft, seconds: seconds - 1 };
        } else if (minutes > 0) {
          return { ...prevTimeLeft, minutes: minutes - 1, seconds: 59 };
        } else if (hours > 0) {
          return { ...prevTimeLeft, hours: hours - 1, minutes: 59, seconds: 59 };
        } else if (days > 0) {
          return {
            ...prevTimeLeft,
            days: days - 1,
            hours: 23,
            minutes: 59,
            seconds: 59
          };
        } else {
          clearInterval(interval);
          return { days: 0, hours: 0, minutes: 0, seconds: 0 }; // Time is up
        }
      });
    
    }, 1000);
    setBid(aucSingleProd?.auctionProduct?.highestBid>aucSingleProd?.auctionProduct?.price?aucSingleProd?.auctionProduct?.highestBid+1:aucSingleProd?.auctionProduct?.price+1)
    return () => clearInterval(interval);
  }, [aucSingleProd]);

  useEffect(() => {
    dispatch(getBidHistory(product))
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1280) {
        setNumCards(5);
      } else if (width >= 1024) {
        setNumCards(4);
      } else if (width >= 768) {
        setNumCards(3);
      } else {
        setNumCards(2);
      }
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const data = [
    { name: "John Doe", amount: 50, image: "https://res.cloudinary.com/wizalot/image/upload/v1722525035/yurwyhlkoynwitknszlj.png" },
    { name: "Jane Smith", amount: 130, image: "https://res.cloudinary.com/wizalot/image/upload/v1722525035/yurwyhlkoynwitknszlj.png" },
    { name: "Sam Wilson", amount: 240, image: "https://res.cloudinary.com/wizalot/image/upload/v1722525035/yurwyhlkoynwitknszlj.png" },
    { name: "Chris Lee", amount: 300, image: "https://res.cloudinary.com/wizalot/image/upload/v1722525035/yurwyhlkoynwitknszlj.png  " },
  ];
 
  return (
    <>
      <div className="px-4 lg:px-8 xl:px-[57px] mt-2 flex flex-col gap-4">
        {/* first section start here */}
        <div>
          {/* breadCrumbs */}
          <div className="text-base breadcrumbs font-semibold hidden md:block ">
            <ul>
              <li>Home</li>
              <li>Single Product</li>
              <li>{aucSingleProd?.auctionProduct?.name}</li>
            </ul>
          </div>

          {/* product img slider + auction setup */}
          <div className="flex flex-col md:flex-row justify-between items-start gap-6">
            {/* left slider */}
            <div className="w-full md:w-[53%] xl:w-3/5">
              <div className=" relative">
                <div className="slider-container  relative">
                  <Slider ref={sliderRef} {...settings}>
                    {aucSingleProd?.auctionProduct?.images?.map((item, index) => (
                      <div key={index}>
                        <img
                          src={item.image}
                          alt=""
                          className="w-full h-[390px] xl:h-[37rem]"
                        />
                      </div>
                    ))}
                  </Slider>
                  {sliderRef.current && (
                    <>
                      <button
                        onClick={handlePrev}
                        className={`absolute top-1/2 -left-6 xl:-left-7 transform -translate-y-1/2  text-4xl bg-white text-black z-10 bg-opacity-15 hover:bg-opacity-100 hover:shadow-md rounded-full p-1 ${
                          currentIndex === 0 ? "hidden" : ""
                        }`}
                      >
                        <BiChevronLeft className="text-[35px] xl:text-[50px]" />
                      </button>
                      <button
                        onClick={handleNext}
                        className={`absolute top-1/2 -right-[1.15rem] xl:-right-6 transform -translate-y-1/2 text-4xl  bg-white text-black z-10 bg-opacity-15 hover:bg-opacity-100 hover:shadow-md rounded-full p-1 ${
                          currentIndex === 3 ? "hidden" : ""
                        }`}
                      >
                        <BiChevronRight className="text-[35px] xl:text-[50px]" />
                      </button>
                    </>
                  )}
                </div>
              </div>

              <div className="flex justify-center gap-2 mt-5">
                {aucSingleProd?.auctionProduct?.images?.map((item, index) => (
                  <img
                    src={item.image}
                    alt=""
                    key={index}
                    className={`flex w-8 h-[29px] md:w-[45px] md:h-[41px] xl:w-[70px] xl:h-[64px] top-80 ${
                      index === currentIndex ? "" : "opacity-50"
                    }`}
                    onClick={() => setCurrentIndex(index)}
                  />
                ))}
              </div>
            </div>

            {/* right details */}
            <div className="w-full md:w-[55%] xl:w-2/5 flex flex-col gap-3">
              {/* title and heart icon */}
              <div className="flex justify-between gap-2 items-center w-full">
                <div className="text-3xl md:text-3xl lg:text-4xl xl:text-[40px] font-medium tracking-wide">
                  {aucSingleProd?.auctionProduct?.name}
                </div>
{/* 
                <div
                  className={` bg-gray-500  bg-opacity-10 flex items-center justify-center mr-0  rounded-full p-1`}
                >
                  <svg
                    className="h-12 w-12 md:w-8 md:h-8 lg:h-12 lg:w-12 cursor-pointer text-[#ffff]"
                    viewBox="0 0 24 24"
                    fill={isHeartFilled ? "#1983FF" : "none"}
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    onClick={toggleHeart}
                  >
                    <path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                  </svg>
                </div> */}
              </div>

              {/* remaining time */}
              <div className="flex justify-between items-start  px-2">
                <div className="flex flex-col items-center gap-2">
                  <div className="text-5xl md:text-[52px] lg:text-6xl xl:text-7xl font-bold">
                  {timeLeft.days < 10 ? `0${timeLeft.days}` : timeLeft.days}
                  </div>
                  <div className="text-xs md:text-[13px] lg:text-[15px] xl:text-lg font-bold tracking-wide">
                    DAYS
                  </div>
                </div>
                <div className="text-4xl md:text-[38px] lg:text-[40px] xl:text-5xl font-bold mt-4">
                  :
                </div>
                <div className="flex flex-col items-center gap-2">
                  <div className="text-5xl md:text-[52px] lg:text-6xl xl:text-7xl font-bold">
                    {timeLeft.hours < 10
                      ? `0${timeLeft.hours}`
                      : timeLeft.hours}
                  </div>
                  <div className="text-xs md:text-[13px] lg:text-[15px] xl:text-lg font-bold tracking-wide">
                    HOURS
                  </div>
                </div>
                <div className="text-4xl md:text-[38px] lg:text-[40px] xl:text-5xl font-bold mt-4">
                  :
                </div>
                <div className="flex flex-col items-center gap-2">
                  <div className="text-5xl md:text-[52px] lg:text-6xl xl:text-7xl font-bold">
                    {timeLeft.minutes < 10
                      ? `0${timeLeft.minutes}`
                      : timeLeft.minutes}
                  </div>
                  <div className="text-xs md:text-[13px] lg:text-[15px] xl:text-lg font-bold tracking-wide">
                    MINUTES
                  </div>
                </div>
                <div className="text-4xl md:text-[38px] lg:text-[40px] xl:text-5xl font-bold mt-4">
                  :
                </div>
                <div className="flex flex-col items-center gap-2">
                  <div className="text-5xl md:text-[52px] lg:text-6xl xl:text-7xl font-bold">
                    {timeLeft.seconds < 10
                      ? `0${timeLeft.seconds}`
                      : timeLeft.seconds}
                  </div>
                  <div className="text-xs md:text-[13px] lg:text-[15px] xl:text-lg font-bold tracking-wide">
                    SECONDS
                  </div>
                </div>
              </div>

              {/* bid input */}
              <div className="flex justify-between gap-4 w-full">
                {/* counter */}
                <div className="flex w-full justify-between items-center bg-[#C19A6B] px-1 rounded-[1.3rem]">
                  {/* minus button */}
                  <div
                    className="bg-white py-4 px-3 cursor-pointer"
                    style={{ borderRadius: "18px 0px 0px 18px" }}
                    onClick={handleMinus}
                  >
                    <svg
                      width="21"
                      height="12"
                      viewBox="0 0 21 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 6H19.5"
                        stroke="black"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <div className=" text-3xl md:text-xl lg:text-3xl py-2 text-white">
                    ${bid}.00
                  </div>

                  {/* plus button */}
                  <div
                    className="bg-white py-3 px-3 cursor-pointer"
                    style={{
                      borderRadius: "0px",
                      borderTopRightRadius: "18px",
                      borderBottomRightRadius: "18px",
                      borderBottomLeftRadius: "0px",
                    }}
                    onClick={(e)=>handlePlus()}
                  >
                    <svg
                      width="21"
                      height="22"
                      viewBox="0 0 21 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 11H19.5"
                        stroke="black"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.5 2V20"
                        stroke="black"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>

                {/* cart button */}

                {bidButtonIcon === 0 ? (
                  <>
                    <div
                      div
                      className="bg-[#C19A6B] py-3 px-4 rounded-[15px]  cursor-pointer"
                      onClick={handleBidIcon}
                    >
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5529 0.574578C11.209 0.676769 10.7827 0.906697 10.5211 1.14685C10.4049 1.25415 8.88879 2.69503 7.15471 4.34541C4.85875 6.5374 3.94327 7.43668 3.77858 7.68193C3.23608 8.48413 3.15858 9.52136 3.5703 10.4411C3.73983 10.809 3.81733 10.906 6.19564 13.6805C6.99486 14.6105 7.37268 14.8762 8.09925 15.0192C8.65629 15.1316 9.43129 14.9681 9.91567 14.6411C10.0271 14.5696 10.6907 13.9564 11.4027 13.282L12.6863 12.0557L16.4112 16.3937C19.3611 19.8375 20.1748 20.7572 20.3444 20.839C20.6447 20.9923 21.0564 20.9565 21.3325 20.7521C21.72 20.4609 21.8653 19.8886 21.6522 19.4543C21.5892 19.3214 20.0005 17.4309 17.8789 14.9579C15.8639 12.6126 14.217 10.6659 14.217 10.6404C14.2218 10.6097 14.8031 10.0323 15.5151 9.35275C16.9246 8.00894 17.1281 7.75347 17.3121 7.11988C17.4429 6.67536 17.4526 5.97535 17.3364 5.56659C17.1717 4.99432 16.9828 4.72863 15.5151 3.01693C13.9554 1.20305 13.7374 0.973121 13.3596 0.778959C12.8171 0.503045 12.0954 0.421292 11.5529 0.574578ZM12.5023 2.70525C12.7348 2.87898 15.3165 5.91404 15.394 6.1082C15.4667 6.27681 15.4473 6.48119 15.3456 6.63448C15.3165 6.68047 13.8295 8.11624 12.0469 9.81772C8.60785 13.098 8.66113 13.052 8.33659 12.9141C8.24456 12.8732 7.65362 12.2243 6.76236 11.1922C5.2511 9.42939 5.2075 9.36297 5.33829 9.03085C5.37704 8.92866 6.57345 7.75347 8.62238 5.79652C10.3904 4.10526 11.8871 2.69503 11.9452 2.65927C12.1051 2.56218 12.3327 2.58262 12.5023 2.70525Z"
                          fill="white"
                        />
                        <path
                          d="M1.22585 19.3845C1.03545 19.5118 0.964603 19.5949 0.862762 19.8386C0.752066 20.1044 0.738783 20.1875 0.756494 20.4699C0.787489 20.8742 0.946891 21.1899 1.22585 21.3726L1.42067 21.5H7.40269C13.1102 21.5 13.3936 21.4945 13.5618 21.3948C14.2171 21.0237 14.2437 19.7887 13.6061 19.3734C13.429 19.2571 13.4157 19.2571 7.44697 19.2405L1.46495 19.2294L1.22585 19.3845Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="bg-[#C19A6B] py-3 px-4 rounded-[15px] ms-2">
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.6673 12.9262V13.9995C25.6659 16.5153 24.8512 18.9633 23.3449 20.9783C21.8385 22.9933 19.7211 24.4674 17.3086 25.1807C14.896 25.8941 12.3175 25.8084 9.95753 24.9365C7.59761 24.0647 5.58275 22.4533 4.21344 20.3428C2.84413 18.2322 2.19374 15.7356 2.35927 13.2252C2.52481 10.7148 3.49739 8.32523 5.13198 6.41277C6.76656 4.50031 8.97557 3.16746 11.4295 2.61302C13.8835 2.05858 16.451 2.31224 18.749 3.33618"
                          stroke="white"
                          stroke-width="2.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M25.6667 4.66602L14 16.3443L10.5 12.8443"
                          stroke="white"
                          stroke-width="2.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </>
                )}
              </div>

              {/* highest + start bid */}
              <div className=" flex justify-between gap-2">
                <div className="flex items-center gap-3">
                  <div className="text-[11px] md:text-xs lg:text-sm tracking-wide">
                    Current Highest:
                  </div>
                  <div className="text-xl md:text-lg lg:text-2xl font-bold tracking-wide">
                    ${aucSingleProd?.auctionProduct?.highestBid}
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div className="text-[11px] md:text-xs lg:text-sm tracking-wide">
                    Original Price:
                  </div>
                  <div className="text-xl  md:text-lg lg:text-2xl  font-bold tracking-wide">
                    ${aucSingleProd?.auctionProduct?.price}
                  </div>
                </div>
              </div>

                {/* graph */}
                <div className=" py-[8rem] ">

                  <BidderChart art data={bidHistory?.auctionProduct?.bids?.length>0?bidHistory?.auctionProduct.bids:[]} />
                </div>

              {/* all bidder */}
              <div>
                <div className="flex justify-between items-center">
                  <div className="text-sm">All Bidders</div>
                  <div>
                    <div className="avatar-group -space-x-3 rtl:space-x-reverse">
                      <div className="avatar border-none">
                        <div className="w-8 h-8">
                          <img
                            className=""
                            src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=600"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="avatar border-none">
                        <div className="w-8 h-8">
                          <img
                            className=""
                            src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="avatar border-none">
                        <div className="w-8 h-8">
                          <img
                            className=""
                            src="https://images.pexels.com/photos/1499327/pexels-photo-1499327.jpeg?auto=compress&cs=tinysrgb&w=600"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="avatar border-none">
                        <div className="w-8 h-8">
                          <img
                            className=""
                            src="https://images.pexels.com/photos/1547971/pexels-photo-1547971.jpeg?auto=compress&cs=tinysrgb&w=600"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="avatar border-none">
                        <div className="w-8 h-8">
                          <img
                            className=""
                            src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=600"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="avatar border-none">
                        <div className="w-8 h-8">
                          <img
                            className=""
                            src="https://images.pexels.com/photos/2773977/pexels-photo-2773977.jpeg?auto=compress&cs=tinysrgb&w=600"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* bid creator */}
              <div className=" flex justify-between gap-2">
                <Link className="flex gap-4 items-center"
                to={`/shop-front/${aucSingleProd?.auctionProduct?.user_slug}`}
                >
                  <div>
                    <img
                      className="h-[52px] w-[52px] rounded-full"
                      src={aucSingleProd?.auctionProduct?.user_image}
                      alt="img"
                    />
                  </div>
                  <div className="text-start">
                    <div className="text-sm">Created by</div>
                    <div className="text-lg font-bold">@{aucSingleProd?.auctionProduct?.user_name}</div>
                  </div>
                </Link>

               
              </div>
            </div>
          </div>
        </div>
        {/* first section end here */}

        {/* product description -  second section start here */}
        <div
          className="mt-10 py-4 px-4 md:px-8 rounded-lg"
          style={{
            boxShadow: "0px 16.51px 33.03px 0px #0508281A",
          }}
        >
          <h1 className="font-bold text-2xl xl:text-3xl">
            Product Description
          </h1>
          <div
            className={`text-sm xl:text-base text-[#505050] ${
              isExpanded ? "line-clamp-none" : "line-clamp-3"
            }`}
          >
           {aucSingleProd?.auctionProduct?.description}
          </div>
          <div className="text-center  ">
            <div className="flex flex-col items-center opacity-70">
              <button onClick={toggleExpand} className="opacity-60  ">
                {isExpanded ? "Close" : "View more"}
              </button>
              <button onClick={toggleExpand}>
                {isExpanded ? <BsChevronUp /> : <BsChevronDown />}
              </button>
            </div>
          </div>
        </div>
        {/* second section end here */}

        {/* Related Products Section start here */}
        <div className="mt-8">
          <div>
            <h2 className="font-bold text-3xl mb-3">Related Products</h2>
          </div>
          <div className="flex items-center gap-5">
            {aucSingleProd?.related_products?.slice(0,6)?.map((item) => (
              <>
                <AuctionProductCard key={item._id} item={item} />
              </>
            ))}
          </div>
        </div>

        {/* Last Viewed Products Section */}
        <div className="mt-8">
          <div>
            <h2 className="font-bold text-3xl mb-3">Last Viewed by You</h2>
          </div>
          <div className="flex items-center gap-5">
            {aucSingleProd?.lastView?.slice(0,5)?.map((item) => (
              <>
                <AuctionProductCard key={item._id} item={item} />
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
