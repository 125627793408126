import React, { useEffect } from "react";
import { recommendedProducts } from "../../store/frontendSlice";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../../components/Common/ProductCard";

export default function TrendingPage() {
  const { recommendedProduct } = useSelector((state) => state.frontend);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(recommendedProducts())
  }, []);

  return (
    <div className="flex w-full justify-center">
      <div className={`max-w-screen-2xl w-full px-4 xl:px-[57px]`}>
        <div className="">
          <div className="font-bold text-3xl mt-12">Trending Products</div>
        </div>
        <div className="mt-4 mb-6">
          {/* Top selling card */}
          <div
            className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 2xl:gap-8 `}
          >
          {recommendedProduct?.length>0? recommendedProduct?.map((item) => (
            <ProductCard getFunction={recommendedProducts} key={item._id} item={item} />
          )):"No Product Found"}
          </div>
        </div>
      </div>
    </div>
  );
}
