import React, { useEffect } from "react";
import BubblesHeroSection from "../../../components/Store-Square-Components/Store-Square-Home-Components/Home-Bubble-Section/BubblesHeroSection";
import BoostedStoreSection from "../../../components/Store-Square-Components/Store-Square-Home-Components/Boosted-Store-Section/BoostedStoreSection";
import TrendingStoreSection from "../../../components/Store-Square-Components/Store-Square-Home-Components/Trending-Store-Section/TrendingStoreSection";
import MallsSection from "../../../components/Store-Square-Components/Store-Square-Home-Components/Malls-Section/MallsSection";
import SmallStoreSection from "../../../components/Store-Square-Components/Store-Square-Home-Components/Small-Store-Section/SmallStoreSection";
import StoreSquareFilter from "../../../components/Store-Square-Components/Store-Square-Home-Components/Store-Square-Filter/StoreSquareFilter";
import { useDispatch, useSelector } from "react-redux";
import { getStoreSquare } from "../../../store/frontendSlice";

export default function StoreSquareHomePage() {
  const { storeSqure } = useSelector(state => state.frontend);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getStoreSquare())
  }, [])

  return (
    <>
      <BubblesHeroSection width={1570} height={500} />
      <StoreSquareFilter />
      {storeSqure?.Boostedstores?.length>0 && <BoostedStoreSection storeSqure={storeSqure} />}
      {storeSqure?.trendingStores?.length>0 && <TrendingStoreSection storeSqure={storeSqure} />}
      {storeSqure?.malls?.length>0 && <MallsSection storeSqure={storeSqure} />}
      {storeSqure?.smallStores?.length>0 && <SmallStoreSection storeSqure={storeSqure} />}
    </>
  );
}
