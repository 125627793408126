import React, { useEffect, useState } from "react";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { affiliateStoreSearch, myAffliateStore } from "../../../store/categorySlice";
import { imagePath } from "../../../functions/URLString";

const UserDashboardAffilliateMyAffilliateStore = () => {
  const dispatch = useDispatch();
  const { data, loading, message, error } = useSelector(
    (state) => state.category
  );

  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (data && data.last_page) {
      setTotalPages(data.last_page);
    }
  }, [data]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(myAffliateStore(page));
    }
  };

  useEffect(() => {
    dispatch(myAffliateStore());
  }, []);

  useEffect(() => {
    // console.log(data);
  }, [data]);

  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }
    // console.log(totalNumberOfPages);
    // console.log(tempStart, tempEnd);
    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "My Affilliate Store",
              path: "",
            },
          ]}
        />

        {/* search */}
        <div className="relative flex items-center w-full ">
          <img
            src="/assets/icons/search.svg"
            alt=""
            className="absolute left-3"
          />

          <input
            className="w-full py-2 md:py-3  px-8 text-[8px] md:text-[15px] rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-medium"
            type="search"
            placeholder="Search product ..."
            onChange={(e) => dispatch(affiliateStoreSearch(e.target.value))}
          />
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            {/* Table Header */}
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="text-left w-[35%]">PRODUCTS</span>

                <span className="text-left w-[15%]">PRICE</span>

                <span className="text-left w-[15%]">COMMISSION</span>
                <span className="text-left w-[20%]">TOTAL EARNING</span>

                <span className="text-left w-[20%]">ACTION</span>
              </div>

              {/* Table Data Row */}
              {data?.products?.length > 0?
                data.products.map((item, index) => (
                  <>
                    <div className="flex text-[10px] md:text-sm items-center justify-between text-[#18120F] p-2 md:p-4 border-b">
                      <div className="flex items-center text-left w-[35%]">
                        <img
                          src={item.image}
                          alt="Product"
                          className="w-8 h-8 md:w-10 md:h-10 rounded-lg mr-4"
                        />
                        <div>
                          <p className=" tracking-wide pb-1  text-[#18120F] font-semibold ">
                            {item.name}
                          </p>
                          <p className="text-[#6B6B6B] tracking-wider  font-semibold ">
                            Category: {item.category_name}
                          </p>
                        </div>
                      </div>

                      <span className="flex  font-[600] text-[#18120F] text-left w-[15%]">
                        ${item.price}
                      </span>

                      <span className="flex  font-[600] text-left w-[15%] pl-5">
                        ${item.commission}
                      </span>
                      <span className="flex  font-[600] text-left w-[20%]">
                        ${item.net_amount}
                      </span>

                      <div className="flex items-center gap-2  font-[600] w-[20%] text-left">
                        <img
                          className="w-[28px] md:w-[38px] cursor-pointer"
                          src="/assets/icons/view.svg"
                          alt=""
                        />
                        <img
                          className="w-[28px] md:w-[38px] cursor-pointer"
                          src="/assets/icons/delete1.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </>
                )):"No Store Found"}
            </div>
          </div>
        </div>

        {/* Pagination */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
            <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
              SHOWING{" "}
              {data?.pagination?.from} -{" "}
              {data?.pagination?.to}{" "}
              OF {data?.pagination?.total} RESULTS
            </span>

          {data?.pagination?.total>10 && (
            <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
              {/* Previous Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="6"
                  height="12"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                }
              </div>
              {/* Next Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px]  cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="6"
                  height="12"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserDashboardAffilliateMyAffilliateStore;
