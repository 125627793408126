import UserDashboardMyReviewsPage from "../../../pages/User-Dashbard/User-Dashbaord-Buying-Page/User-Dashboard-Review-Page/UserDashboardMyReviewsPage";
import UserDashboardReviewPage from "../../../pages/User-Dashbard/User-Dashbaord-Buying-Page/User-Dashboard-Review-Page/UserDashboardReviewPage";

const reviewsRoutes = [
  {
    path: "/user-dashboard/reviews",
    element: <UserDashboardReviewPage />,
  },
  {
    path: "/user-dashboard/my-reviews",
    element: <UserDashboardMyReviewsPage />,
  },
];

export default reviewsRoutes;
