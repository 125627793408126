import React, { useEffect, useState } from "react";
import SmartFilterSidebarComponent from "./SmartFilterSidebarComponent";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSmartFilterData } from "../../store/frontendSlice";

function SmartFilterSidebar() {
  const { slug } = useParams();
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  const [isPriceOpen, setIsPriceOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isBrandOpen, setIsBrandOpen] = useState(false);
  const [isColorOpen, setIsColorOpen] = useState(false);
  const [isSizeOpen, setIsSizeOpen] = useState(false);
  const [isSpecialOfferOpen, setIsSpecialOfferOpen] = useState(false);
  const [isStockStatusOpen, setIsStockStatusOpen] = useState(false);
  const [isRatingsOpen, setIsRatingsOpen] = useState(false);
  const [isVendersOpen, setIsVendersOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    price: {},
    brand: null,
    color: null,
    size: null,
    ratings: null,
    vendors: null,
  });
  const [price, setPrice] = useState({ min_price: 0, max_price: 0 });
  const { smartFilterData } = useSelector((state) => state.frontend);
  const [url, setUrl] = useState(`/${slug}?`);
  const dispatch = useDispatch();

  const handleCheckBox = (filter, value, isChecked) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
  
      if (filter !== "price") {
        if (isChecked) {
          newFilters[filter] = value;
        } else {
          newFilters[filter] = null;
        }
      }
  
      return newFilters;
    });
  
    // Update URL string for other filters
    setUrl((prevUrl) => {
      let newURL = prevUrl;
      const regex = new RegExp(`[&?]${filter}=[^&]*`, "g");
  
      if (isChecked) {
        newURL = newURL.replace(regex, "");
        newURL += `&${filter}=${value}`;
      } else {
        newURL = newURL.replace(regex, "");
      }
  
      return newURL;
    });
  };

  useEffect(() => {
    dispatch(getSmartFilterData(url));
  }, [url, dispatch]);
  const handlePriceApply = () => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      price: {
        min_price: price.min_price,
        max_price: price.max_price,
      },
    }));
  
  
    setUrl((prevUrl) => {
      let newURL = prevUrl;
      const priceRegex = /[&?]price_min=[^&]*|[&?]price_max=[^&]*/g;
  
      newURL = newURL.replace(priceRegex, "");
  
 
      if (price.min_price || price.max_price) {
        newURL += `&price_min=${price.min_price}&price_max=${price.max_price}`;
      }
  
      return newURL;
    });
  };
  
  const toggleContent = (isOpen, category) => {
    switch (category) {
      case "categories":
        setIsCategoriesOpen(isOpen);
        break;
      case "price":
        setIsPriceOpen(isOpen);
        break;
      case "sort":
        setIsSortOpen(isOpen);
        break;
      case "brand":
        setIsBrandOpen(isOpen);
        break;
      case "color":
        setIsColorOpen(isOpen);
        break;
      case "size":
        setIsSizeOpen(isOpen);
        break;
      case "special-offer":
        setIsSpecialOfferOpen(isOpen);
        break;
      case "stock-status":
        setIsStockStatusOpen(isOpen);
        break;
      case "ratings":
        setIsRatingsOpen(isOpen);
        break;
      case "venders":
        setIsVendersOpen(isOpen);
        break;
      default:
        break;
    }
  };

  return (
    <div className="w-full">
      <div className="flex w-full flex-col justify-start items-start">
        <div className="border-b-2 w-full">
          <SmartFilterSidebarComponent
            toggleContent={(isOpen) => toggleContent(isOpen, "price")}
          >
            Price
          </SmartFilterSidebarComponent>
          <div className={`w-full mb-3 ${isPriceOpen ? "" : "hidden"}`}>
            <div className="w-full flex items-center gap-4 p-2">
              <div className="w-3/5 flex justify-between items-center gap-1">
                <input
                  type="number"
                  className="w-4/5 border border-black px-1 no-spin-button rounded-sm focus:outline-none"
                  placeholder="$0"
                  onChange={(e) =>
                    setPrice({ ...price, min_price: e.target.value })
                  }
                />
                to
                <input
                  type="number"
                  className="w-4/5 border border-black px-1 rounded-sm no-spin-button focus:outline-none"
                  placeholder="$60"
                  onChange={(e) =>
                    setPrice({ ...price, max_price: e.target.value })
                  }
                />
              </div>
              <div className="w-2/6">
                <button
                  className="w-full bg-[#75BFB3] text-white text-sm rounded-sm text-center p-1"
                  onClick={handlePriceApply}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>

        {smartFilterData?.brands?.length > 0 && (
          <div className="border-b-2 w-full">
            <SmartFilterSidebarComponent
              toggleContent={(isOpen) => toggleContent(isOpen, "brand")}
            >
              Brand
            </SmartFilterSidebarComponent>
            <div className={`w-full mb-3 p-2 ${isBrandOpen ? "" : "hidden"}`}>
              {smartFilterData?.brands?.map((item) => (
                <div className="flex items-center gap-3" key={item.id}>
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    checked={selectedFilters.brand === item.id}
                    onChange={(e) =>
                      handleCheckBox("brand", item.id, e.target.checked)
                    }
                  />
                  <label className="text-[#828282]">{item.name}</label>
                </div>
              ))}
            </div>
          </div>
        )}

        {smartFilterData?.colors?.length > 0 && (
          <div className="w-full border-b-2">
            <SmartFilterSidebarComponent
              toggleContent={(isOpen) => toggleContent(isOpen, "color")}
            >
              Color
            </SmartFilterSidebarComponent>
            <div className={`w-full mb-3 p-3 ${isColorOpen ? "" : "hidden"}`}>
              <div className="grid grid-cols-3 gap-3">
                {smartFilterData?.colors?.map((item) => (
                  <div
                    className="flex flex-col justify-center items-center"
                    key={item.id}
                  >
                    <div
                      className="color-circle border-2 border-[#000000] border-opacity-10"
                      style={{ backgroundColor: item.HexCode }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedFilters.color === item.ColorName}
                        onChange={(e) =>
                          handleCheckBox(
                            "color",
                            item.ColorName,
                            e.target.checked
                          )
                        }
                      />
                    </div>
                    <p className="text-[#828282] text-xs">{item.ColorName.slice(0,8)}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {smartFilterData?.sizes?.length > 0 && (
          <div className="w-full border-b-2">
            <SmartFilterSidebarComponent
              toggleContent={(isOpen) => toggleContent(isOpen, "size")}
            >
              Size
            </SmartFilterSidebarComponent>
            <div className={`w-full mb-3 p-3 ${isSizeOpen ? "" : "hidden"}`}>
              <div className="grid grid-cols-3">
                
                  {smartFilterData?.sizes?.map((item) => (
                    <div className="inline-block mx-1 my-1" key={item.id}>
                    <button
                      className={`px-3 py-[1px] text-sm border rounded-2xl ${
                        selectedFilters.size === item.SizeName
                          ? 'border-[#000] text-[#000]'
                          : 'border-[#474141] text-[#474141] opacity-50'
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.target.nextSibling.click();
                      }}
                    >
                      {item.SizeName}
                    </button>
                    <input
                      type="checkbox"
                      className="hidden"
                      checked={selectedFilters.size === item.SizeName}
                      onChange={(e) =>
                        handleCheckBox(
                          "size",
                          item.SizeName,
                          e.target.checked
                        )
                      }
                    />
                  </div>
                  ))}
                
              </div>
            </div>
          </div>
        )}

        {smartFilterData?.vendors?.ratings?.top_rating && (
          <div className="w-full border-b-2">
            <SmartFilterSidebarComponent
              toggleContent={(isOpen) => toggleContent(isOpen, "ratings")}
            >
              Ratings
            </SmartFilterSidebarComponent>
            <div className={`w-full mb-3 p-3 ${isRatingsOpen ? "" : "hidden"}`}>
              {[5, 4, 3, 2, 1].map((rating) => (
                <div className="flex items-center mb-2 gap-3" key={rating}>
                  <input
                    type="checkbox"
                    checked={selectedFilters.ratings === rating}
                    onChange={(e) =>
                      handleCheckBox("ratings", rating, e.target.checked)
                    }
                  />
                  <label className="text-[#828282]">
                    <img
                      src={`./assets/images/rating/${rating}star.png`}
                      alt={`${rating} stars rating`}
                    />
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}

        {smartFilterData?.vendors?.length > 0 && (
          <div className="w-full border-b-2">
            <SmartFilterSidebarComponent
              toggleContent={(isOpen) => toggleContent(isOpen, "venders")}
            >
              Vendors
            </SmartFilterSidebarComponent>
            <div className={`w-full mb-3 p-3 ${isVendersOpen ? "" : "hidden"}`}>
              {smartFilterData?.vendors?.map((item) => (
                <div className="flex items-center gap-3" key={item.id}>
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    checked={selectedFilters.vendors === item.slug}
                    onChange={(e) =>
                      handleCheckBox("vendors", item.slug, e.target.checked)
                    }
                  />
                  <label className="text-[#828282]">{item.store_name}</label>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SmartFilterSidebar;
