import ContactUs from "../../../../components/User-Dashboard-Components/User-Dashoard-Selling-User-Support-Components/ContactUs";
import UserDashboardBuyingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";

export default function ContactUsPage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <ContactUs />
      </UserDashboardBuyingLayout>
    </>
  );
}
