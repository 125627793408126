import React from "react";
import UserDashboardSellingLayout from "../../../Layout/User-Dashboard-layout/UserDashboardSellingLayout";
import UserDashboardBuyingHome from "../../../components/User-Dashboard-Components/User-Dashboard-Buying-Components/User-dashboard-Buying-Home-Components/UserDashboardBuyingHome";

export default function UserDashboardSellingPage() {
  return (
    <>
      <UserDashboardSellingLayout>
       <UserDashboardBuyingHome/>
      </UserDashboardSellingLayout>
    </>
  );
}
