import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer/Footer";
import { ToastContainer } from "react-toastify";
import Preloader from "../components/Common/preloader/Preloader";
import axiosInstance from "../functions/axios"; // Import your axios instance
import AdStoreCategoriesBar from "../components/Ad-MarketPlace-Components/Home-Page-Components/Ad-Store-Categories-Bar/AdStoreCategoriesBar";
import SliderMainCategory from "../components/slider-main-category/SliderMainCategory";
import { useDispatch, useSelector } from "react-redux";
import { userAccountSetting } from "../store/categorySlice";
import { redirectFalse } from "../store/frontendSlice";
import SliderAdStoreCategory from "../components/Ad-MarketPlace-Components/Home-Page-Components/Ad-Store-Categories-Bar/SliderAdStoreCategory";

const StoreSquareRoot = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { profileSetting } = useSelector((state) => state.category);
  const { redirect } = useSelector((state) => state.frontend);
  const [loading, setLoading] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(true);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [showContent, setShowContent] = useState(true); 
  const navigate = useNavigate()

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        setLoading(true);
        return config;
      },
      (error) => {
        setLoading(false);
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        setLoading(false);
        return response;
      },
      (error) => {
        setLoading(false);
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  useEffect(() => {
    dispatch(userAccountSetting());
    localStorage.setItem("locationData", JSON.stringify({}));
  }, [dispatch]);

  useEffect(() => {
    if(redirect){
      dispatch(redirectFalse());
      navigate("/dashboard/login")
    }
  }, [redirect]);

  useEffect(() => {
    setGlobalLoading(true);
    const timer = setTimeout(() => {
      setContentLoaded(true);
      setGlobalLoading(false);
    }, 1000); // Adjust time if needed

    return () => clearTimeout(timer);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setShowContent(true);
      } else {
        setShowContent(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <main className="bg-white">
        <ToastContainer theme="light" autoClose={2000} />
        <Navbar profileData={profileSetting?.user} showContent={showContent } />
          { location.pathname.toLowerCase().includes("store-square") ? null :
            location.pathname.toLowerCase().includes("ad-market-place") ? (
              // <AdStoreCategoriesBar />
              <SliderAdStoreCategory/>
            ) : (
              <SliderMainCategory />
            )
          }
        {(globalLoading || !contentLoaded) ? (
          <div className="flex justify-center items-center z-20 h-[100vh]">
            <Preloader />
          </div>
        ) : (
          <>
            <div className={`${location.pathname.toLowerCase().includes("store-square")?"mt-24":"mt-36"}`}>
              <Outlet />
            </div>
            <Footer />
          </>
        )}
      </main>
    </>
  );
};

export default StoreSquareRoot;
