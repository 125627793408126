import React from "react";
import UserDashboardSellingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardSellingLayout";
import UserDashboardVenderOffers from "../../../../components/User-Dashboard-Components/User-Dashboard-Vender-Offers/UserDashboardVenderOffers";

export default function UserDashboardSellingVenderOfferPage() {
  return (
    <>
      <UserDashboardSellingLayout>
        <UserDashboardVenderOffers />
      </UserDashboardSellingLayout>
    </>
  );
}
