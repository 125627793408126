import React, { useState } from "react";
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { formatNumber, stringConcat } from "../../../../functions/formatNumber";

const AdStoreVehiclesSingleCategoryCarsCard = ({ item, view, key }) => {
  const [isHeartFilled, setIsHeartFilled] = useState(false);

  const handleHeartClick = () => {
    setIsHeartFilled(!isHeartFilled);
  };
  const cardClass =
    view === 1
      ? "border flex relative  w-full sm:w-[620px] md:w-[840px] lg:w-[960px] xl:w-[1240px] xl:h-[250px] md:h-[178px] h-[120px] rounded-2xl" // Example style for view 1
      : view === 3
      ? "border relative xl:max-w-[416px]  md:max-w-[292px]  max-w-[170px]  rounded-3xl " // Style for view 3
      : "border relative xl:max-w-[240px]  md:max-w-[213px]  max-w-[170px]   rounded-2xl   "; // Default style
  const flex =
    view === 1
      ? "flex xl:gap-5 gap-1  " // Example style for view 1
      : view === 3
      ? "" // Style for view 3
      : ""; // Default style
  const locationdateall =
    view === 1
      ? "flex justify-between" // Example style for view 1
      : view === 3
      ? "flex justify-between xl:p-3 p-1" // Style for view 3
      : "flex justify-between"; // Default style
  const locationdate =
    view === 1
      ? " absolute flex flex-col md:gap-2 xl:text-[24px] md:text-[17px] text-xs xl:right-4 xl:bottom-4 right-1 bottom-1  font-[400] text-[#828282]  "
      : view === 3
      ? "xl:text-[21px] md:text-[14px] text-[8px] font-[400] text-[#828282] flex"
      : "xl:text-xs md:text-[10px] text-[8px] font-[400] text-[#828282] flex";
  const price =
    view === 1
      ? "absolute bottom-2 xl:text-4xl md:text-[30px] text-base font-bold"
      : view === 3
      ? "xl:text-[42px] md:text-[29px] text-[17px] font-bold"
      : "xl:text-[24px] md:text-[21px] text-[17px] font-bold";

  const name =
    view === 1
      ? "font-semibold xl:text-3xl md:text-xl textbase "
      : view === 3
      ? "font-bold xl:text-[28px] md:text-[19px] text-[11px] "
      : "font-[600] xl:text-base md:text-sm text-[11px] ";

  const right =
    view === 1
      ? " xl:py-2 p-1 w-full "
      : view === 3
      ? "px-1"
      : "px-3 mt-0 py-1";
  const heart =
    view === 1
      ? "absolute xl:top-2 xl:right-2 top-1 right-1   z-10"
      : view === 3
      ? "absolute top-2 xl:right-6 lg:right-3 md:right-2 right-2 w-11 h-11 items-center z-10"
      : "absolute xl:top-2 xl:right-2 top-1 right-1 z-10";
  const hearticon =
    view === 1
      ? "h-5 w-5 md:w-7 md:h-7 xl:w-10 xl:h-10"
      : view === 3
      ? "xl:h-11 xl:w-11 md:h-8 md:w-8 w-5 h-5"
      : "h-5 w-5 md:w-6 md:h-6 xl:w-8 xl:h-8 cursor-pointer";

  const imgdiv =
    view === 1
      ? "xl:w-[245px] xl:h-[245px] md:w-[178px] md:h-[178px] w-[120px] h-[120px]"
      : view === 3
      ? "w-full xl:h-[493px] md:h-[300px]  lg:h-[346px]  h-[200px] transition-transform duration-500 ease-in-out hover:scale-110"
      : "w-full xl:h-[282px] md:h-[200px] lg:h-[250px] h-[200px] transition-transform duration-500 ease-in-out hover:scale-110 ";

  return (
    <>
      <Link to={`/ad-market-place/single-product/${item.slug}`}>
        <div key={key} className={cardClass}>
          <div className={flex}>
            <div className="">
              <div className=" overflow-hidden  rounded-xl relative ">
                <div className={imgdiv}>
                  <img src={item.image} className={`w-full h-full`} alt="" />
                </div>
              </div>

              {/* {item.badge.length > 0 && (
              <div className="absolute xl:text-base text-white text-[11px] xl:px-[22px] xl:py-[6px] px-4 py-1  top-2 left-2 bg-opacity-60 bg-black rounded-2xl">
                {item.badge}
              </div>
            )} */}
            </div>
            {/* .......................... */}
            <div className={right}>
              <h2 className={name}>
                {/* {view == 1 || view !== 3
                  ? stringConcat(item.name, 20)
                  : item.name} */}
                {view == 1 && item.name}
                {view == 3 && stringConcat(item.name, 20)}
                {view == 5 && stringConcat(item.name, 20)}
                {/* {view == 3 ?  item.name : stringConcat(item.name, 20)} */}
              </h2>
              {/* {view === 1 && (
                <>
                  <div className="flex xl:gap-2 gap-1 flex-wrap xl:text-xl md:text-[17px] text-xs font-normal text-[#828282] md:ml-4">
                    <p>Toyota |</p>
                    <p>Corolla |</p>
                    <p>1999 |</p>
                    <p>SUV |</p>
                    <p>3400km |</p>
                    <p>Petrol |</p>
                    <p>Manual |</p>
                    <p>Grey |</p>
                  </div>
                </>
              )} */}
              <div className={locationdateall}>
                <div className={locationdate}>
                  <p>
                    {view == 1 && `${item.city}, ${item.state}`}
                    {view == 3 &&
                      `${stringConcat(item.city, 8)}, ${stringConcat(
                        item.state,
                        8
                      )}`}
                    {view == 5 &&
                      `${stringConcat(item.city, 8)}, ${stringConcat(
                        item.state,
                        8
                      )}`}
                    <br />
                    <p className={`${view == 1? "pt-2":""}`}>
                    {item.formatted_created_at}
                    </p>
                  </p>
                </div>
                <div>
                  {/* <h2 className={price}>${formatNumber(item.price)}</h2> */}
                  <h2 className={price}>${item.price}</h2>
                </div>
              </div>
            </div>
          </div>

          {/* .................................... */}
          {/* <div className={heart}>
          <div
            className={`gap-1 p-2 bg-[#1983FF33] rounded-full  flex justify-center items-center ${
              isHeartFilled
                ? "text-blue-500 duration-500 ease-in-out"
                : "text-white"
            }`}
            onClick={handleHeartClick}
          >
            <FaHeart className={hearticon} />
          </div>
        </div> */}
        </div>
      </Link>
    </>
  );
};

export default AdStoreVehiclesSingleCategoryCarsCard;
