import { useEffect, useState } from "react";
import InspirationVideoPreviewCard from "./InspirationVideoPreviewCard";

function InspirationVideosPreview({ reels }) {

  return (
    <>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
      {reels.reels.length>0 && reels.reels.map((reel) => (
            <div
            className="z-20 h-[350px]"
            key={reel.id}>
              <InspirationVideoPreviewCard reel={reel}/>
            </div>
          ))
        }
        </div>
    </>
  );
}

export default InspirationVideosPreview;
