import React, { useEffect, useRef, useState } from 'react'
import { getBannersLink } from '../../store/frontendSlice';
import { useDispatch } from 'react-redux';
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaArrowTrendUp } from "react-icons/fa6";

const Component = ({item,loading}) => {
    const [bannerInfo, setBannerInfo] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    const bannerInfoTimeout = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 768);
        };
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleVisitNow = (g) => {
        dispatch(getBannersLink(g));
      }

      const handleMouseEnter = () => {
        if (!isSmallScreen) {
          setBannerInfo(true);
        }
      };
    
      const handleMouseLeave = () => {
        if (!isSmallScreen) {
          setBannerInfo(false);
        }
      };
    
      const handleClick = () => {
        if (isSmallScreen) {
          setBannerInfo(!bannerInfo);
        }
      };    

  return (
    <div className="relative">
        <div
        className={`absolute bg-black w-10 h-10 sm:w-16 sm:h-16 lg:w-16 lg:h-16 items-center z-20 opacity-70 duration-150 ${
            bannerInfo
            ? "rounded-xl"
            : "rounded-full"
        } right-5 md:right-10 top-3 md:top-6 cursor-pointer `}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        >
        {bannerInfo ? (
            <div className="text-white flex w-full items-center h-full justify-center z-20 font-bold text-[8px] sm:text-xs lg:text-sm">
            Sponsored
            </div>
        ) : (
            <div className="p-2 flex w-full items-center h-full justify-center md:p-3 lg:p-4 bg-[#00000080] rounded-full">
            <FaArrowTrendUp className="text-white h-[20px] w-[20px] md:w-[24px]  md:h-[24px] lg:w-[34px] lg:h-[34px]" />
            </div>
        )}
        {bannerInfo && (
        <div className="text-white bg-black  opacity-80  absolute right-0 top-10 sm:top-16 rounded-xl px-4 py-2 lg:px-8 lg:py-4">
            <div className="text-white font-bold text-sm sm:text-base lg:text-[29px] ">
            {item.name}
            </div>
            <div className="flex items-center gap-2 lg:mt-2">
            <button disabled={loading} onClick={()=>handleVisitNow(item.id)} className="text-[10px] text-nowrap md:text-sm cursor-pointer font-semibold bg-white text-blue-600 border border-[#1983FF] rounded-[5px] px-2 py-1 md:px-[17px] md:py-[4.7px]">
                Visit Now
            </button>
            <div className="flex items-center gap-2">
                <FaMapMarkerAlt className="w-2 h-3" />{" "}
                <span className="font-medium text-[8px] md:text-base">
                {item.distance}
                </span>
            </div>
            </div>
        </div>
        )}
        </div>

    </div>
  )
}

export default Component
