import React, { useEffect, useState } from "react";
import AuctionHomeCategoriesBarCard from "./AuctionHomeCategoriesBarCard";
import { Link } from "react-router-dom";

export default function AuctionHomeCategoriesBar({data}) {


  return (
    <>
      <div className="px-4 lg:px-8 xl:px-[57px]">
        <div className="flex justify-between items-center max-h-38 gap-6 md:gap-8 lg:gap-12 xl:gap-8 carousel carousel-center">
          {data?.length>0 && data?.map((item) => (
            <Link to={"/ad-market-place/auction/"+item?.slug}>
              <AuctionHomeCategoriesBarCard key={item._id} item={item} />
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
