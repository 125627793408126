import UserDashboardPaymentMethodPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Dashboard-Profile-Settings-Page/UserDashboardPaymentMethodPage";
import UserDashboardProfilePage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Dashboard-Profile-Settings-Page/UserDashboardProfilePage";
import UserDashboardUserProfileAddressesPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Dashboard-Profile-Settings-Page/UserDashboardUserProfileAddressesPage";

const accountAndSettingRoutes = [
  {
    path: "/user-dashboard/profile",
    element: <UserDashboardProfilePage />,
  },
  {
    path: "/user-dashboard/profile-addresses",
    element: <UserDashboardUserProfileAddressesPage />,
  },
  {
    path: "/user-dashboard/payment-methods",
    element: <UserDashboardPaymentMethodPage />,
  },
];

export default accountAndSettingRoutes;
