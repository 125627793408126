  import moment from "moment/moment";
import React from "react";
import { GoX } from "react-icons/go";
import { useSelector } from "react-redux";

export default function Notifications({ notification, setNotification }) {
  const { notifications } = useSelector(state => state.category);
  return (
    <>
      <div
        className={`${
          notification
            ? "flex absolute top-20 right-2 justify-end md:mr-16  z-50 "
            : "hidden"
        }`}
      >
        <div className="w-[250px] h-[80vh] md:w-[450px] md:h-[90vh]  bg-white rounded-2xl shadow-xl flex flex-col ">
          <div className="flex justify-between sticky items-center bg-white px-3 py-4  md:px-6 md:py-7">
            <div className="text-xs md:text-sm font-semibold">
              Notifictions <span className="text-[#828282]">({notifications.length})</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-xs text-[#C19A6B] "></span>
              <button onClick={() => setNotification(false)}>
                <GoX />
              </button>
            </div>
          </div>
          <div className=" flex-grow overflow-y-auto hide-scrollbar overflow-hidden ">
            {notifications.map((item) => {
              return(  <div className="border-t flex flex-col px-3 py-2  md:px-6 md:py-7">
                <div className="flex items-start  gap-3">
                  <img
                    className="border border-[#C19A6B] p-[5px] md:p-[10px] rounded-xl"
                    src="/assets/icons/notifications/bell.svg"
                    alt=""
                  />
                  <div className="flex flex-col gap-1">
                    {/* <h2 className="text-xs md:text-sm font-medium text-wrap w-full">
                      Maecenas enim feugiat sodales ut. Sed morbi urna gravida
                      turpis.
                    </h2> */}
                    <p className="text-[10px] md:text-sm font-normal text-[#828282]">
                    {item.message}
                    </p>
                    <div className="flex items-center gap-2">
                      <span className="p-1 rounded-full bg-[#C19A6B]"></span>
                      <span className="text-[#D9D9D9] text-[10px] md:text-sm font-normal">
                       {moment(item.created_at, "YYYY-MM-DD HH:mm:ss").format("MMMM D [at] hh:mm A")}
                      </span>
                    </div>
                  </div>
  
                  {/* <img src="/assets/icons/notifications/dots.svg" alt="" /> */}
                </div>
              </div>)
            })}
          
            
          </div>
        </div>
      </div>
    </>
  );
}
