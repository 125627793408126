import React from 'react'
import ThankYouPage from '../../../components/Payments-Confirmation-Pages-Components/ThankYouPage'

export default function PaymentsConfirmationChanges() {
    return (
        <>
            <ThankYouPage />
        </>
    )
}
