import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function PaymentsSection() {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Set animate to true after the component mounts to trigger the animation
    setAnimate(true);
  }, []);

  const [activeCardIndex, setActiveCardIndex] = useState(0);

  const cards = [
    {
      id: 1,
      bank: "DANYAL KHAN",
      number: "1234567890123456",
      expiry: "12/24",
      ccv: 137,
    },
    {
      id: 2,
      bank: "WAQAS MEHMOOD",
      number: "9876543210987654",
      expiry: "06/23",
      ccv: 132,
    },
    {
      id: 3,
      bank: "UMER FAROOQ",
      number: "5555444433332222",
      expiry: "09/25",
      ccv: 191,
    },
    {
      id: 4,
      bank: "SAAD KHAN",
      number: "1111222233334444",
      expiry: "03/26",
      ccv: 191,
    },
  ];

  const handlePrevCard = () => {
    setActiveCardIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : cards.length - 1
    );
  };

  const handleNextCard = () => {
    setActiveCardIndex((prevIndex) =>
      prevIndex < cards.length - 1 ? prevIndex + 1 : 0
    );
  };

  // const splitCardNumber = (number) => {
  //     const parts = [];
  //     for (let i = 0; i < number.length; i += 4) {
  //         parts.push(number.slice(i, i + 4));
  //     }
  //     return parts;
  // };

  const splitCardNumber = (number) => {
    const parts = [];
    const sanitizedNumber = number.replace(/\s/g, ""); // Remove spaces from the card number
    for (let i = 0; i < sanitizedNumber.length; i += 4) {
      parts.push(sanitizedNumber.slice(i, i + 4));
    }
    const hiddenParts = parts.slice(0, 3).map(() => "* * * *");
    const visiblePart = parts.slice(-1);
    return [...hiddenParts, ...visiblePart];
  };

  const splitCardData = (data) => {
    const parts = data.split("/");
    const hiddenParts = parts.slice(0, -1).map(() => "**");
    const visiblePart = parts.slice(-1);
    return [...hiddenParts, ...visiblePart];
  };

  const splitCardExpiry = (expiry) => {
    const hiddenPart = "**/**";
    return hiddenPart;
  };

  const splitCardCVV = (cvv) => {
    return "* * *";
  };

  const [cardholderName, setCardholderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");

  const handleCardNumberChange = (e) => {
    let value = e.target.value.replace(/\s+/g, "").slice(0, 16); // Remove spaces and limit to 16 digits

    // Format the card number
    let formattedValue = "";
    for (let i = 0; i < value.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedValue += " ";
      }
      formattedValue += i < 12 ? "*" : value[i];
    }

    setCardNumber(formattedValue);
  };

  const handleExpiryDateChange = (e) => {
    let value = e.target.value.replace(/[^0-9/]/gi, "");

    // Remove the extra slashes
    value = value.replace(/\/\//g, "/");

    // Truncate year to two digits
    if (value.length > 5) {
      value = value.slice(0, 5);
    }

    // Handle adding a digit after the slash
    if (value.length === 3 && value.charAt(2) !== "/") {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }

    // Handle removing a digit after the slash
    if (value.length === 3 && value.charAt(2) === "/") {
      value = value.slice(0, 2);
    }

    // Add a slash after the first two digits
    if (value.length === 2 && !value.includes("/")) {
      value += "/";
    }

    setExpiryDate(value);
  };

  const handleCvvChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/gi, "");
    if (value.length <= 3) {
      setCvv(value);
    }
  };

  const generateMaskedCardNumber = (cardNumber) => {
    const visiblePart = cardNumber.slice(-4); // Last 4 digits
    const hiddenParts = [
      cardNumber.slice(0, 4),
      cardNumber.slice(4, 8),
      cardNumber.slice(8, 12),
    ].map(() => "* * * *");

    return [...hiddenParts, visiblePart].join("  "); // Join parts with double spaces
  };

  return (
    <>
      <main className="px-4 md:px-0 md:ps-7 lg:ps-[57px] w-full flex flex-col md:flex-row gap-4 justify-between">
        <div className="w-full md:w-[49%] relative flex flex-col">
          <div className="font-bold text-2xl tracking-wide">Payments</div>

          <div className="mt-6 mb-2">Select your card to proceed payment</div>

          <div className="flex items-center gap-3 bg-[#EAEAEA]   rounded-full shadow w-fit">
            <div className="bg-white py-2 xl:px-8 px-4  rounded-full text-nowrap font-bold text-[10px] lg:text-base xl:text-xl tracking-wide">
              Pay with Card
            </div>
            <div className="py-2 xl:px-8 px-4 font-bold text-[10px] lg:text-base xl:text-xl text-nowrap tracking-wide">
              Pay with Paypal
            </div>
            <div className="py-2 xl:px-8 px-4 font-bold text-[10px] lg:text-base xl:text-xl text-nowrap tracking-wide">
              Pay with Crypto
            </div>
          </div>

          <div className="mt-6 w-full ">
            <div className="relative flex justify-start ">
              <div className="flex justify-center items-center space-x-4">
                <div className="relative w-full">
                  {cards.map((card, index) => (
                    <div
                      key={card.id}
                      className={`flex flex-col gap-4 payments_card_bg rounded-3xl absolute top-0 left-24 md:left-[4rem] lg:left-24 w-[272px] md:w-[312px] lg:w-[23rem] xl:w-[26rem]  p-4 shadow-md transform transition-transform duration-300 ${
                        index === activeCardIndex ? "" : "-translate-x-16"
                      } `}
                      style={{
                        zIndex:
                          index === activeCardIndex
                            ? cards.length
                            : cards.length - index,
                      }}
                      onClick={() => {
                        const maskedNumber = generateMaskedCardNumber(
                          card.number
                        );
                        setCardNumber(maskedNumber);
                        setCardholderName(card.bank);
                        setExpiryDate(card.expiry);
                      }}
                    >
                      <div className="upper_gradient absolute top-[-3rem] left-1 h-32 w-32 rounded-[4rem]"></div>
                      <div className="flex justify-between">
                        <div>
                          <span className="mt-6 ps-4 text-white opacity-[70%] text-[9.6px] md:text-sm tracking-wide">
                            Card Name
                          </span>
                          <h3 className="text-[13px] md:text-xl font-[500] mb-2 ps-4 text-white tracking-wide">
                            {card.bank}
                          </h3>
                        </div>
                        <div>
                          <img src="./assets/icons/Master_card.png" alt="" />
                        </div>
                      </div>
                      <div className=" ">
                        <span className="mt-6 ps-4 text-white opacity-[70%] text-[9.6px] md:text-sm tracking-wide">
                          Card Number
                        </span>
                        <div className="flex gap-6 ps-4 text-[15px] md:text-lg">
                          <p className="text-white">
                            {splitCardNumber(card.number)[0]}
                          </p>
                          <p className="text-white">
                            {splitCardNumber(card.number)[1]}
                          </p>
                          <p className="text-white">
                            {splitCardNumber(card.number)[2]}
                          </p>
                          <p className="text-white">
                            {splitCardNumber(card.number)[3]}
                          </p>
                        </div>
                      </div>

                      <div className="flex ">
                        <div>
                          <span className="mt-6 ps-4 text-white opacity-[70%] text-[9.6px] md:text-sm tracking-wide">
                            Expire Date
                          </span>
                          <p className="text-white ps-4 text-[15px] md:text-lg">
                            {splitCardExpiry(card.expiry)}
                          </p>
                        </div>
                        <div>
                          <span className="mt-6 ps-4 text-white opacity-[70%] text-[9.6px] md:text-sm tracking-wide">
                            CVV
                          </span>
                          <p className="text-white ps-4 text-[15px] md:text-lg">
                            {splitCardCVV(card.ccv)}
                          </p>
                        </div>
                      </div>
                      <div className="absolute bottom-[-2rem] right-0">
                        <svg
                          width="288"
                          height="193"
                          viewBox="0 0 288 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.1"
                            x="349.179"
                            y="0.0117188"
                            width="127.513"
                            height="403.792"
                            transform="rotate(59.5964 349.179 0.0117188)"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="absolute bottom-[-1rem] right-0">
                        <svg
                          width="214"
                          height="139"
                          viewBox="0 0 214 139"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.1"
                            x="295.809"
                            y="0.746094"
                            width="108.26"
                            height="342.823"
                            transform="rotate(59.5964 295.809 0.746094)"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
                </div>

                <button
                  onClick={handlePrevCard}
                  className="absolute -left-2 md:-left-10 lg:-left-2 top-24 z-40 py-4 px-5 bg-opacity-50 bg-white rounded-full shadow-lg"
                >
                  <svg
                    width="13"
                    height="22"
                    viewBox="0 0 13 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.38127 12.1727C0.709497 11.4675 0.709497 10.3222 1.38127 9.61692L9.97993 0.589835C10.6517 -0.115406 11.7427 -0.115406 12.4144 0.589834C13.0862 1.29508 13.0862 2.44039 12.4144 3.14563L5.03033 10.8976L12.4091 18.6496C13.0808 19.3549 13.0808 20.5002 12.4091 21.2054C11.7373 21.9107 10.6463 21.9107 9.97456 21.2054L1.37589 12.1784L1.38127 12.1727Z"
                      fill="black"
                    />
                  </svg>
                </button>
                <button
                  onClick={handleNextCard}
                  className="absolute right-0 md:-right-8 lg:right-0 xl:right-36 top-24 z-40 py-4 px-5 bg-opacity-50 bg-white rounded-full shadow-lg"
                >
                  <svg
                    width="13"
                    height="22"
                    viewBox="0 0 13 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.6187 9.82728C12.2905 10.5325 12.2905 11.6778 11.6187 12.3831L3.02007 21.4102C2.3483 22.1154 1.25734 22.1154 0.585569 21.4102C-0.0862018 20.7049 -0.0862019 19.5596 0.585569 18.8544L7.96967 11.1024L0.590941 3.35035C-0.0808304 2.64511 -0.0808305 1.4998 0.590941 0.794557C1.26271 0.0893157 2.35367 0.0893156 3.02544 0.794557L11.6241 9.82164L11.6187 9.82728Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-[45%] lg:w-[49%] md:h-[744px] lg:mt-[-35px] flex justify-center  brown-circle cart-bg mt-56 md:mt-0">
          <div className="lg:ms-[3.5rem] xl:ms-28 w-full">
            <div className="lg:mt-20 flex gap-3">
              <div className="flex flex-col gap-1">
                <span className="text-white text-base md:text-sm xl:text-xl font-bold tracking-wide">
                  {" "}
                  Customer Info{" "}
                </span>
                <span className="border-b-4 border-white rounded-full"></span>
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-white text-base md:text-sm xl:text-xl font-bold tracking-wide ">
                  Payments
                </span>
                <span className="border-b-4 border-white rounded-full "></span>
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-white text-base md:text-sm xl:text-xl  font-bold tracking-wide opacity-50">
                  Confirmation
                </span>
                <span className="border-b-4 border-white rounded-full opacity-50"></span>
              </div>
            </div>

            {/* shipping detail */}
            <div className="mt-8 w-full  lg:max-w-[336px] xl:max-w-[492px] rounded-md px-5 py-2 ">
              {/* cardholder name */}
              <div className="relative mt-4">
                <label
                  htmlFor="cardholder-name-input"
                  className="absolute left-2 -top-2 px-1 text-xs bg-[#C19A6B] text-[#F8F8F8]"
                >
                  CARDHOLDER NAME
                </label>
                <input
                  type="text"
                  id="cardholder-name-input"
                  name="cardholder-name"
                  className="border p-2 pl-8 w-full rounded-md bg-[#C19A6B] focus:border-white focus:outline-none text-white"
                  value={cardholderName}
                  onChange={(e) => setCardholderName(e.target.value)}
                />
              </div>

              {/* card number input */}
              <div className="relative mt-4">
                <label
                  htmlFor="card-number-input"
                  className="absolute left-2 -top-2 px-1 text-xs bg-[#C19A6B] text-[#F8F8F8]"
                >
                  CARD NUMBER
                </label>
                <input
                  type="text"
                  id="card-number-input"
                  name="card-number"
                  className="border p-2 pl-8 w-full rounded-md bg-[#C19A6B] focus:border-white focus:outline-none text-white"
                  value={cardNumber}
                  onChange={handleCardNumberChange}
                />
              </div>

              {/* expiry date + cvv */}
              <div className="flex gap-3">
                <div className="relative mt-4">
                  <label
                    htmlFor="expiry-input"
                    className="absolute left-2 -top-2 px-1 text-xs bg-[#C19A6B] text-[#F8F8F8]"
                  >
                    EXPIRY DATE
                  </label>
                  <input
                    type="text"
                    id="expiry-input"
                    name="expiry-date"
                    className="border p-2 pl-8 w-full rounded-md bg-[#C19A6B] focus:border-white focus:outline-none text-white"
                    value={expiryDate}
                    onChange={handleExpiryDateChange}
                  />
                </div>
                <div className="relative mt-4">
                  <label
                    htmlFor="cvv-input"
                    className="absolute left-2 -top-2 px-1 text-xs bg-[#C19A6B] text-[#F8F8F8]"
                  >
                    CVV
                  </label>
                  <input
                    type="text"
                    id="cvv-input"
                    name="cvv"
                    className="border p-2 pl-8 w-full rounded-md bg-[#C19A6B] focus:border-white focus:outline-none text-white"
                    value={cvv}
                    onChange={handleCvvChange}
                  />
                </div>
              </div>
            </div>

            {/* Total order detail */}
            <div className={`animate-card z-20 ${animate ? "animate-in" : ""}`}>
              <div className="mt-4 bg-white w-full  lg:max-w-[336px] xl:max-w-[492px] rounded-md px-5 py-2  order-summary-upper-card">
                <div className="flex justify-between my-3">
                  <div className=" font-[700] tracking-wide">Total Payment</div>
                  <div className="font-[700]">$520.00</div>
                </div>
              </div>

              <div className="w-full  lg:max-w-[336px] xl:max-w-[492px] bg-white py-3 order-summary-lower-part">
                {/* dotted line */}
                <div className="border border-dashed border-[#C19A6B] opacity-40 mt-[-0.7rem]"></div>

                <div className="px-5 mt-8 mb-2 flex justify-center">
                  <Link
                    to="/confirm-payments"
                    className="bg-[#C19A6B]  py-2 px-[13px] w-full text-center  rounded text-white font-[500] text-xl tracking-wide"
                  >
                    Pay Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
