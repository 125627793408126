import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import "./adds-slider.css";
import { imagePath } from "../../functions/URLString";
import { useDispatch, useSelector } from "react-redux";
import { getBannersLink } from "../../store/frontendSlice";

export default function FirstSliderBanner({ banner, startIndex, endIndex }) {
  const slider = React.useRef(null);
  const dispatch = useDispatch();
  const { bannersLink } = useSelector(state => state.frontend);

  const settings = {
    dots: true,
    infinite: false,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <simpleNextArrow />,
    prevArrow: <simplePrevArrow />,
  };

  useEffect(() => {
    if(bannersLink){
      window.open(bannersLink, '_blank');
    }
  }, [bannersLink]);

  const handleBanner = (id)=>{
    dispatch(getBannersLink(id));
  }

  return (
    <>
      {/* main div  */}
      <div className=" slider-container relative ">
        {/* Angle button */}
        <div onClick={() => slider?.current?.slickPrev()} className="cursor-pointer left-button invisible absolute z-20 left-0 top-[6.5rem] xl:top-[9.5rem] flex justify-center items-center w-8 h-8 xl:w-12 xl:h-12 rounded-full bg-white opacity-60 hover:opacity-100 ">
          <button
            className=" xl:p-4"
          >
            <img
              src="../../assets/images/home-sliders/lt.png"
              alt=""
              className="w-[7px] xl:w-[9px]"
            />
          </button>
        </div>
        <div onClick={() => slider?.current?.slickNext()} className="cursor-pointer right-button invisible absolute z-20 right-0 top-[6.5rem] xl:top-[9.5rem] flex justify-center items-center w-8 h-8 xl:w-12 xl:h-12 rounded-full bg-white opacity-60 hover:opacity-100 ">
          <button
            className=" xl:p-4"
          >
            <img
              src="../../assets/images/home-sliders/gt.png"
              alt=""
              className="w-[7px] xl:w-[9px]"
            />
          </button>
        </div>

        <div className="absolute z-10 left-6 xl:left-10 bottom-6 rounded-[25px] bg-black bg-opacity-50 font-bold text-white text-base md:text-sm xl:text-base py-2 px-5">
          {banner?.length>0?banner[0].label:""}
        </div>

        {/* slider start*/}
        <Slider ref={slider} {...settings} className="overflow-hidden">
          {banner?.slice(startIndex, endIndex).map((item, index) => {
            return (
              <div className="w-full h-[253px] xl:h-[354px] overflow-hidden self-center rounded-lg">
                <img
                onClick={() => handleBanner(item.id)}
                src={imagePath+item.image}
                alt="" className="w-full h-full object-fill"/>
              </div>
            );
          })}
        </Slider>
        {/* slider end*/}
      </div>
    </>
  );
}
