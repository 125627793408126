
export const OFFERS = "public/offers";

export const HOTSPOT = "/public/hotspot"
export const SESSION_TOKEN = "/public/session"
export const RECOMMENDED_PRODUCT="/public/recommanded-products"
export const MY_SHELF="/public/my-shelf"
export const SINGLE_PRODUCT="/public/products/"

export const SINGLE_PRODUCT_REVIEW = "/public/reviews/"
export const STORE_SQUARE="/public/store-square"
export const MAIN_SHOP_CART="/public/cart"

export const AD_MARKET_PLACE = "/public/ads"
export const VENDOR_PROFILE_FRONT = "/public/vendor-profile/"
export const FOLLOW="/user/follow"
export const AUCTION = "/public/auction"
export const AUCTION_SINGLE_PRODUCT="/public/auction-product/"
export const AUTION_BIDDING_PRODUCT = "/user/bid"
export const USER_PROFILE = "/public/user-profile/"
export const MAIN_SHOP_SEARCH = "/public/search?search="
export const GET_RANDOM_AUCTION_PRODUCT="/public/auction-random"
