import React, { useEffect, useState } from "react";
import VideoCard from "../Common/VideoCard";

const VendorInReels = ({data}) => {
 
  return (
    <div className="flex w-full justify-center">
      <div className="max-w-screen-2xl w-full px-4 xl:px-[57px] py-10">
        <h1 className="text-3xl font-bold">InReels</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-4">
          {data?.inreel?.slice(0, 4).map((item) => (
            <div className="flex w-full justify-center">
              <VideoCard item={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VendorInReels;
