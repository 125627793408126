import UserDashboardStoredFollowedPage from "../../../pages/User-Dashbard/User-Dashbaord-Buying-Page/User-Dashboard-Stored-Followed-Page/UserDashboardStoredFollowedPage";

const followedStoreRoutes = [
  {
    path: "/user-dashboard/followed-stores",
    element: <UserDashboardStoredFollowedPage />,
  },
];

export default followedStoreRoutes;
