import React, { useEffect } from "react";
import BoostedStoreCard from "../../../components/Store-Square-Components/Store-Square-Boosted-Page-Components/BoostedStoreCard";
import { useDispatch, useSelector } from "react-redux";
import {  StoreSquare } from "../../../store/frontendSlice";

export default function StoreSquareBoostedPage() {
  const { boostedStores } = useSelector(state => state.frontend);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(StoreSquare("boosted"));
  },[dispatch])
  return (
    <>
      <div className="px-4 lg:px-14 xl:px-[57px] mt-7">
        {/* boosted store */}
        <div className="flex justify-between mt-8">
          <h2 className="font-bold text-lg xl:text-2xl  ">Boosted Stores</h2>
        </div>

        <div className="max-w-screen-2xl container mx-auto mt-6 mb-6 flex justify-center">
          {/* Top selling card */}
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10">
            {boostedStores?.length>0 && boostedStores.map((item) => {
              return(
                <BoostedStoreCard data={item } />
                )
              })}
        
           
          </div>
        </div>
      </div>
    </>
  );
}
