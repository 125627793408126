import React from 'react'
import AuctionPopularProducts from '../../../../components/Ad-MarketPlace-Components/Auction-Components/Auction-Popular-Products-Page-Components/AuctionPopularProducts'

export default function AuctionPopularProductsPage() {
    return (
        <>
            <AuctionPopularProducts />
        </>
    )
}
