import UserDashboardBuyingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";
import UserDashboardWishlist from "../../../../components/User-Dashboard-Components/User-Dashboard-Buying-Components/User-Dashboard-Wishlist/UserDashboardWishlist";

export default function UserDashboardWishlistPage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <UserDashboardWishlist />
      </UserDashboardBuyingLayout>
    </>
  );
}
