import React, { useState, useRef, useEffect } from "react";
import {
  addShippingAddress,
  clearMessage,
  getCitiesByState,
  getCountries,
  getShippingAdressess,
  getStateByCountry,
} from "../../store/frontendSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// import EditAddressModalForm from './EditAddressModalForm';

export default function EditAddressModal({onSelectBox}) {
  const [addressType, setAddressType] = useState("home");
  const dispatch = useDispatch();
  const {
    loading,
    countries,
    states,
    citiesByState,
    message,
    error,
    shippingAdressess,
  } = useSelector((state) => state.frontend);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [isCountryDropdownVisible, setCountryDropdownVisible] = useState(false);
  const [isStateDropdownVisible, setStateDropdownVisible] = useState(false);
  const [isCityDropdownVisible, setCityDropdownVisible] = useState(false);

  const toggleCountryDropdown = () => {
    setCountryDropdownVisible((prev) => !prev);
    setCityDropdownVisible(false);
  };

  const toggleCityDropdown = () => {
    setCityDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
  };

  const toggleStateDropdown = () => {
    setStateDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
  };

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const selectCountry = (country) => {
    setSelectedCountry(country.name);
    setPayload({
      ...payload,
      country_id: country.id,
      state_id: "",
      city_id: "",
    });
    setCountryDropdownVisible(false);
    dispatch(getStateByCountry(country.id));

    setSelectedState("");
    setSelectedCity("");
  };

  const selectState = (state) => {
    setSelectedState(state.name);
    setPayload({
      ...payload,
      state_id: state.id,
      city_id: "",
    });
    setStateDropdownVisible(false);
    dispatch(getCitiesByState(state.id));
    setSelectedCity("");
  };

  const selectCity = (city) => {
    setSelectedCity(city.name);
    setPayload({
      ...payload,
      city_id: city.id,
    });
    setCityDropdownVisible(false);
  };

  const handleAddressType = (type) => {
    setAddressType(type);
  };




  const myModalRef = useRef(null);
  const editAddressModalRef = useRef(null);
  const AddNewAddressModalRef = useRef(null);



  const handleModal = () => {
    // Close the current modal
    myModalRef.current.close();

    // Open the new modal
    editAddressModalRef.current.showModal();
  };

  const handleSecond = () => {
    myModalRef.current.close();
    AddNewAddressModalRef.current.showModal();
  };

  const [payload, setPayload] = useState({
    name: "",
    phone_number: "",
    country_id: "",
    state_id: "",
    city_id: "",
    area: "",
    address: "",
    landmark: "",
    address_type: "",
    zipcode: "",
  });

  useEffect(() => {
    dispatch(getShippingAdressess());
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    for (const [key, value] of Object.entries(payload)) {
      if (value == "") {
      } else {
        formData.append(key, value)
      }
    }

    dispatch(addShippingAddress(formData));

  };

  const updateData = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }

    if (message == "Shipping address created successfully") {
      toast.success(message);
      document.getElementById("add_new_address_modal_form").close();
      setPayload({
        name: "",
        phone_number: "",
        country_id: "",
        state_id: "",
        city_id: "",
        area: "",
        address: "",
        landmark: "",
        address_type: "",
        zipcode: "",
      });
    }

    dispatch(clearMessage());
  }, [error, message]);

  const [selectBox, setSelectBox] = useState(5)
  const handleSavedAddressSelect = (id) => {
    setSelectBox(id)
    onSelectBox(id);

    document.getElementById('my_modal_3').close();
  }

  return (
    <>
      {/* You can open the modal using document.getElementById('ID').showModal() method */}
      {/* <button className="btn" onClick={() => document.getElementById('my_modal_3').showModal()}>open modal</button> */}

      {/* save address modal */}
      <dialog ref={myModalRef} id="my_modal_3" className="modal">
        <div className="modal-box max-w-5xl bg-white">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg">Saved Addresses</h3>

          {/* save address card */}

          <div className="flex flex-col lg:flex-row w-full gap-2 justify-center my-12 relative">
            {shippingAdressess?.data?.map((box) => (
              <div
                key={box.id}
                className={`cursor-pointer relative rounded-md ${box.id == selectBox ? 'border-2 border-[#C19A6B]' : 'border-2'}`}
                onClick={() => handleSavedAddressSelect(box.id)}
              >
                {/* edit modal address from button */}
                <div
                  onClick={handleModal}
                  className="absolute right-3 top-1 text-sm text-[#C19A6B]"
                >
                  {/* Edit */}
                </div>

                <div className=" bg-white w-full lg:w-[27rem] rounded-md px-5 py-2 shadow-xl">
                  <div className="flex items-center mt-5 gap-4">
                    <div className="text-[#8C8C8C] text-sm">Deliver to:</div>
                    <div className="text-sm">
                      {box.name} | {box.phone_number}
                    </div>
                  </div>
                  <div className="flex items-center mt-1 gap-4">
                    <div className="text-[#8C8C8C] text-sm">Email to:</div>
                    {/* <div className="text-sm">example@gmail.com</div> */}
                  </div>
                  <div className="flex items-start mt-1 gap-4">
                    <div className="text-[#8C8C8C] text-sm">Address: </div>
                    <div className="text-sm w-[20rem]">{box.address},{box.area},{box.landmark}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex items-center gap-8 text-sm justify-end md:px-14 py-2">
            <div
              onClick={handleSecond}
              className="cursor-pointer text-[#C19A6B]"
            >
              + Add New Address
            </div>
            <div className="cursor-pointer py-3 px-12 md:px-16 bg-[#C19A6B] rounded-md text-white font-bold tracking-wide">
              Save
            </div>
          </div>
        </div>
      </dialog>

      {/* edit address modal */}
      <dialog
        ref={editAddressModalRef}
        id="edit_address_modal_form"
        className="modal "
      >
        <div className="modal-box bg-white max-w-3xl  rounded-md">
          <form method="dialog">
            <button
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              onClick={() => editAddressModalRef.current.close()}
            >
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg tracking-wider">Edit Address</h3>

          <div className="">
            <div className=" py-3">
              <form className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    {/* <label htmlFor="field1" className="block text-sm font-medium text-gray-600">Field 1</label> */}
                    <input
                      placeholder="Name"
                      type="text"
                      id="field1"
                      name="field1"
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                  <div>
                    {/* <label htmlFor="field2" className="block text-sm font-medium text-gray-600">Field 2</label> */}
                    <input
                      placeholder="Phone Number"
                      type="text"
                      id="field2"
                      name="field2"
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    {/* <label htmlFor="field3" className="block text-sm font-medium text-gray-600">Field 3</label> */}
                    <input
                      placeholder="Country"
                      type="text"
                      id="field3"
                      name="field3"
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                  <div>
                    {/* <label htmlFor="field4" className="block text-sm font-medium text-gray-600">Field 4</label> */}
                    <input
                      placeholder="State"
                      type="text"
                      id="field4"
                      name="field4"
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    {/* <label htmlFor="field5" className="block text-sm font-medium text-gray-600">Field 5</label> */}
                    <input
                      placeholder="City"
                      type="text"
                      id="field5"
                      name="field5"
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                  <div>
                    {/* <label htmlFor="field6" className="block text-sm font-medium text-gray-600">Field 6</label> */}
                    <input
                      placeholder="Zip Code"
                      type="text"
                      id="field6"
                      name="field6"
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    {/* <label htmlFor="field7" className="block text-sm font-medium text-gray-600">Field 7</label> */}
                    <input
                      placeholder="Area"
                      type="text"
                      id="field7"
                      name="field7"
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                  <div>
                    {/* <label htmlFor="field8" className="block text-sm font-medium text-gray-600">Field 8</label> */}
                    <input
                      placeholder="Landmark"
                      type="text"
                      id="field8"
                      name="field8"
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    {/* <label htmlFor="field7" className="block text-sm font-medium text-gray-600">Field 7</label> */}
                    <input
                      placeholder="Address"
                      type="text"
                      id="field7"
                      name="field7"
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-2 justify-between">
                    <div
                      onClick={() => handleAddressType("home")}
                      className={`cursor-pointer py-3 px-14 border ${
                        addressType === "home"
                          ? "border-[#C19A6B] text-black font-[400]"
                          : "text-[#A5A5A5]"
                      } text-sm rounded tracking-wide bg-white`}
                    >
                      Home
                    </div>
                    <div
                      onClick={() => handleAddressType("office")}
                      className={`cursor-pointer py-3 px-14 border ${
                        addressType === "office"
                          ? "border-[#C19A6B] text-black font-[400]"
                          : "text-[#A5A5A5]"
                      } text-sm rounded tracking-wide`}
                    >
                      Office
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <div className="mt-6 cursor-pointer py-3 px-16 bg-[#C19A6B] rounded-md text-white font-[500] tracking-wider">
                    Save
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </dialog>

      {/* <EditAddressModalForm /> */}

      {/* Add new address modal */}
      <dialog
        ref={AddNewAddressModalRef}
        id="add_new_address_modal_form"
        className="modal "
      >
        <div className="modal-box bg-white max-w-3xl  rounded-md">
          <form method="dialog">
            <button
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              onClick={() => AddNewAddressModalRef.current.close()}
            >
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg tracking-wider">Add New Address</h3>

          <div className="">
            <div className=" py-3">
              <form className="md:space-y-4 overflow-hidden overflow-y-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                  <div>
                    <input
                      placeholder="Name"
                      type="text"
                      id="field1"
                      name="name"
                      value={payload.name}
                      onChange={updateData}
                      className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                    />
                  </div>
                  <div>
                    <input
                      placeholder="Phone Number"
                      type="text"
                      id="field2"
                      name="phone_number"
                      value={payload.phone_number}
                      onChange={updateData}
                      className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                  <div className="w-full">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      COUNTRY
                    </label>
                    <div className="relative w-full">
                      <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                        type="text"
                        value={selectedCountry}
                        readOnly
                        onClick={toggleCountryDropdown}
                        placeholder="Select Your Country"
                      />
                      <svg
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                        onClick={toggleCountryDropdown}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742"
                          fill="#6B6B6B"
                        />
                      </svg>

                      {isCountryDropdownVisible && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] h-fit overflow-hidden  rounded-lg mt-2 w-full">
                          {countries?.map((country) => (
                            <div
                              key={country.id}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => selectCountry(country)}
                            >
                              {country.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-full">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      STATE
                    </label>
                    <div className="relative w-full">
                      <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                        type="text"
                        value={selectedState}
                        readOnly
                        onClick={toggleStateDropdown}
                        placeholder="Select Your State"
                      />
                      <svg
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                        onClick={toggleStateDropdown}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742"
                          fill="#6B6B6B"
                        />
                      </svg>

                      {isStateDropdownVisible && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                          {states?.map((state) => (
                            <div
                              key={state.id}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => selectState(state)}
                            >
                              {state.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                  <div className="w-full">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      CITY
                    </label>
                    <div className="relative w-full">
                      <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                        type="text"
                        value={selectedCity}
                        readOnly
                        onClick={toggleCityDropdown}
                        placeholder="Select Your City"
                      />
                      <svg
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                        onClick={toggleCityDropdown}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742"
                          fill="#6B6B6B"
                        />
                      </svg>

                      {isCityDropdownVisible && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                          {citiesByState?.map((city) => (
                            <div
                              key={city.id}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => selectCity(city)}
                            >
                              {city.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <input
                      placeholder="Zip Code"
                      type="text"
                      id="field6"
                      name="zipcode"
                      value={payload.zipcode}
                      onChange={updateData}
                      className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                  <div>
                    <input
                      placeholder="Area"
                      type="text"
                      id="field7"
                      name="area"
                      value={payload.area}
                      onChange={updateData}
                      className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                    />
                  </div>
                  <div>
                    <input
                      placeholder="Landmark"
                      type="text"
                      id="field8"
                      name="landmark"
                      value={payload.landmark}
                      onChange={updateData}
                      className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <input
                      placeholder="Address"
                      type="text"
                      id="field7"
                      name="address"
                      value={payload.address}
                      onChange={updateData}
                      className="mt-1 py-2 px-4 w-full border text-sm md:text-base focus:outline-none rounded"
                    />
                  </div>
                  <div className="flex  gap-4 justify-between">
                    <div
                      onClick={() =>
                        setPayload({ ...payload, address_type: "home" })
                      }
                      className={`cursor-pointer py-2 md:py-3 px-14 border ${
                        payload.address_type === "home"
                          ? "border-[#C19A6B] text-black font-[400]"
                          : "text-[#A5A5A5]"
                      } text-sm rounded tracking-wide bg-white`}
                    >
                      Home
                    </div>
                    <div
                      onClick={() =>
                        setPayload({ ...payload, address_type: "office" })
                      }
                      className={`cursor-pointer py-2 md:py-3 px-14 border ${
                        payload.address_type === "office"
                          ? "border-[#C19A6B] text-black font-[400]"
                          : "text-[#A5A5A5]"
                      } text-sm rounded tracking-wide`}
                    >
                      Office
                    </div>
                  </div>
                </div>
                <div className="flex  justify-end mt-2">
                  <div
                    disabled={loading}
                    onClick={handleSubmit}
                    className={`${
                      loading ? "cursor-not-allowed" : "cursor-pointer"
                    } h-12 py-2 md:py-3 px-14 bg-[#C19A6B] text-sm md:text-base rounded-md text-white font-[500] tracking-wider `}
                  >
                    {!loading ? (
                      "Save"
                    ) : (
                      <span className="loading loading-spinner loading-md"></span>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </dialog>
    </>
  );
}
