import React, { useState } from "react";

export default function StyleTypeModal({ setFunction, storeStyle, style, setStyle }) {
  const [selectedStoreStyle, setSelectedStoreStyle] = useState(null);
  return (
    <>
      <div className={` ${style ? "block" : "hidden"} `}>
        <div
          onMouseLeave={() => setStyle(false)}
          className="absolute  w-[20%] ml-12 xl:ml-[570px] -mt-24 xl:-mt-6   z-50 mx-auto bg-white  rounded-lg xl:rounded-2xl border-[#D9D9D9] p-2 xl:p-4"
        >
          <div className="w-full  flex justify-between ">
            {/* Render countries */}
            <div className="w-full   px-2 xl:px-5">
              <div className="text-[8px] xl:text-sm font-bold tracking-wide border-b pb-2 flex items-center gap-3 ">
                <span>Style Type</span>
              </div>
              <div className="text-[8px] xl:text-sm  mt-2 flex flex-col gap-2 font-[300] text-[#8a8a8a]">
                {storeStyle.length>0 && storeStyle.map((a,index) => (
                  <span key={index}
                  className={`cursor-pointer xl:p-2 rounded-md ${
                    selectedStoreStyle === a.name ? "bg-gray-200 " : ""
                  }`}
                  onClick={() => {
                    setFunction("storestyle",a.name)
                    setSelectedStoreStyle(a.name)
                  }}>{a.name}</span>
                ))}
                  <span
                    className={`cursor-pointer xl:p-2 rounded-md ${
                      selectedStoreStyle === null ? "bg-gray-200 " : ""
                    }`}
                    onClick={() => {
                      setFunction("storestyle",null)
                      setSelectedStoreStyle(null)
                    }}
                  >
                    None
                  </span>
                {/* <span>Small Store</span>
                <span>Small Store</span>
                <span>Online Store</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
