import UserDashboardBuyingSidebar from "../../components/User-Dashboard-Components/User-Dashboard-Sidebar/UserDashboardBuyingSidebar";

function UserDashboardBuyingLayout({ children }) {
  return (
    <div className="flex gap-2">
      <div className="hidden xl:block ">
        <UserDashboardBuyingSidebar />
      </div>
      <div className="w-full pl-4 xl:pl-0 pt-3">{children}</div>
    </div>
  );
}

export default UserDashboardBuyingLayout;
