import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useState,useEffect } from "react";

import mainRoutes from "./routes/mainRoutes.js";
import Preloader from "./components/Common/preloader/Preloader.jsx";

const router = createBrowserRouter(mainRoutes);
function App() {
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   // Simulate API requests and document load delay
  //   setTimeout(() => {
  //     setLoading(false);
  //     console.log("hello")
  //   }, 3000); // Adjust this timeout according to your API response times
  // }, []);
  return (
    <>
        {/* <Preloader /> */}
      <RouterProvider router={router} />
    </>
  );
}
export default App;