import React from "react";
import UserDashboardSellingLayout from "../../../Layout/User-Dashboard-layout/UserDashboardSellingLayout";
import UserDashboardSellingMyFollowers from "../../../components/User-Dashboard-Components/User-Dashboard-Selling-My-Followers-Components/UserDashboardSellingMyFollowers";
export default function UserDashboardSellingmyFollowersPage() {
  return (
    <>
      <UserDashboardSellingLayout>
        <UserDashboardSellingMyFollowers />
      </UserDashboardSellingLayout>
    </>
  );
}
