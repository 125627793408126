import React, { useEffect } from "react";
import UserDashboardTopBar from "../components/User-Dashboard-Components/User-Dashboard-Top-Bar/UserDashBoardTopBar";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userAccountSetting } from "../store/categorySlice";

export default function UserDashBoardRoot() {
  const dispatch = useDispatch();
  const { token , step, profileSetting} = useSelector((state) => state.category);

  if (!token || step != 0) {
    return <Navigate to="/dashboard/login" replace />;
  }

useEffect(() => {

  dispatch(userAccountSetting())

}, [])


  return (
    <>
      <main className="flex flex-col">
        <UserDashboardTopBar profileData = {profileSetting?.user} />
        
        <div className="relative top-20">
          
          <Outlet />
        </div>
      </main>
    </>
  );
}
