import { useEffect, useState } from "react";
import "./UserDashboardVenderOffers.css";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { getUserOffer, handleStatusAffiliateProduct } from "../../../store/categorySlice";

export default function UserDashboardVenderOffers() {
  const [offers, setOffers] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const handleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.category);
  useEffect(() => {
    dispatch(getUserOffer());
  }, []);
  const handleStatus = (id,status) => {
    dispatch(handleStatusAffiliateProduct({ uid: id, status })).then(() => {
      dispatch(getUserOffer());
    })
  }
  const handleChat = (store_id) => {
    if (localStorage.getItem("token")) {
      const url = "https://messenger.yugoiptv.com/t/"+localStorage.getItem("token")+"/"+store_id; window.location.href = url;
    } else {
      navigate("/dashboard/login")
    }
    
  }
  return (
    <div className="w-full flex flex-col gap-5 px-4">
      <BreadCrums
        breadCrum={[
          {
            name: "New Offers",
            path: "",
          },
        ]}
      />

      {(data && data?.products?.length>0 ) && data?.products.map((offer) => (
        <div
          key={offer.id}
          className="flex flex-col w-full px-2 py-2 xl:px-4 rounded-[10px] shadow-lg"
        >
          {/* vender new offers  */}
          <div className="relative flex flex-col md:flex-row md:justify-between md:items-center w-full xl:h-[122px]">
            {/* vender details  */}
            <div className="flex items-start  lg:items-center gap-3">
              {/* vender image  */}
              <div>
                <img
                  src={offer.shop_picture}
                  alt=""
                  className=" w-11 h-11 lg:w-[82px] lg:h-[86px] rounded-full"
                />
              </div>

              {/* vender details  */}
              <div className="flex flex-col">
                {/* name  */}
                <div className="flex items-center gap-2">
                  <span className="font-bold text-xs lg:text-sm xl:text-base">
                    {offer.store_name}
                  </span>{" "}
                  <span>
                    <img
                      src="/assets/icons/user-dash-board/verify-icon.svg"
                      alt=""
                    />
                  </span>{" "}
                  <span className="text-[10px] xl:text-base text-[#828282]">
                    has sent you an offer 
                  </span>
                </div>

                {/* review stars  */}
                {/* <div className="flex items-center gap-1 xl:mb-2">
                  <span>
                    <img
                      className="h-[8px] xl:h-[10px]"
                      src={offer.star}
                      alt=""
                    />
                  </span>{" "}
                  <span className="text-xs text-[#828282]">
                    ({offer.review})
                  </span>
                </div> */}

                {/* vender message  */}
                <div className="w-[10rem] xl:w-[22rem]">
                  <p className="font-medium text-[8px] lg:text-[10px] xl:text-sm text-[#828282]">
                  {offer.msg}
                  </p>
                </div>

                {/* date  */}
                <span className="absolute bottom-1 right-0  md:relative md:bottom-0  font-normal text-[8px] xl:text-xs text-[#A0A0A0]">
                  {offer.created_at}
                </span>
              </div>
            </div>
            {/* offer store products  */}
            <div className="flex ml-14 gap-1">
              <span className="font-semibold text-[10px] lg:text-sm xl:text-base">
                {offer.commission}%
              </span>{" "}
              <span className="text-[#828282] text-[10px] lg:text-sm xl:text-base">
                on {offer.product_count}
              </span>
            </div>
            {/* vender response */}
            <div className="hidden md:flex items-center gap-2 xl:gap-6 xl:pr-8">
              <Link className="cursor-pointer">
                <img src="/assets/icons/user-dash-board/chat.svg" onClick={(e)=>handleChat(offer.store_id)} alt="chat" />
              </Link>{" "}
              <span className="cursor-pointer" onClick={(e)=>handleStatus(offer.uid,"accept")}>
                <img
                  src="/assets/icons/user-dash-board/approved.svg"
                  alt="chat"
                />
              </span>{" "}
              <span className="cursor-pointer" onClick={(e)=>handleStatus(offer.uid,"reject")}>
                <img
                  src="/assets/icons/user-dash-board/delete.svg"
                  alt="chat"
                />
              </span>
            </div>
            {/* mobile view */}
            <div className="absolute md:hidden flex  top-1 right-0">
              {openDropdownId === offer.id && (
                <div className="rounded-lg p-2 bg-white font-semibold shadow-md flex flex-col gap-[2px] text-[7px] tracking-wider">
                  <span>Approve</span>
                  <span>Chat</span>
                  <span>Delete</span>
                </div>
              )}
              <BsThreeDotsVertical onClick={() => handleDropdown(offer.id)} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
