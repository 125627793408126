import React from "react";
import AffiliateUserProfile from "../../../components/Shop-Front-Components/AffiliateUserProfile";

const ShopFront = () => {
  return (
    <div>
      <AffiliateUserProfile />
    </div>
  );
};

export default ShopFront;
