import React, { useState, useEffect } from "react";
import "./BannerSlider.css";
import { useDispatch, useSelector } from "react-redux";
import { getBanners, getBannersLink } from "../../store/frontendSlice";
import Component from "./Component";

export default function Slider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();
  const { banners, bannersLink, loading } = useSelector(state => state.frontend);

  // Fetch banners and bannersLink on component mount
  useEffect(() => {
    dispatch(getBanners());
  }, [dispatch]);

  // Open banner link in a new tab if bannersLink is available
  useEffect(() => {
    if (bannersLink) {
      window.open(bannersLink, '_blank');
    }
  }, [bannersLink]);

  // Set up the interval for automatically changing the current banner
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const newIndex = banners && banners.length > 0 ? (prevIndex + 1) % banners.length : 0;
        return newIndex;
      });
    }, 4000);

    return () => clearInterval(interval);
  }, [banners]);

  return (
    <div id="default-carousel" className="relative w-full" data-carousel="slide">
      <div className="relative h-56 overflow-hidden md:h-[600px]">

        {Array.isArray(banners) && banners.length > 0 ? (
          banners.map((item, index) => (        
            <div key={index}
              className={`${
              currentIndex === index ? 'block' : 'hidden'
              } duration-700 ease-in-out`} data-carousel-item>
                <Component loading={loading} item={item}/>
                <img src={item.image} className="absolute object-cover block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="Banner"/>
            </div>        
          ))
        ) : (
          <div className="text-center text-gray-500">
            No Banner Found
          </div>
        )}

      </div>
      <div className="absolute z-20 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">

        {Array.isArray(banners) && banners.length > 0 &&
          banners.map((_, index) => (
            <button
              key={index}
              type="button"
              className={`w-3 h-3 rounded-full border-2 border-black ${
                currentIndex === index ? 'bg-black' : 'bg-white'
              }`}
              aria-current={currentIndex === index ? 'true' : 'false'}
              aria-label={`Slide ${index + 1}`}
              data-carousel-slide-to={index}
              onClick={() => setCurrentIndex(index)}
            ></button>
        ))}

      </div>
    </div>
  );
}
