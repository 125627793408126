import React, { useEffect, useState } from "react";
import MiniImagesCard from "./MiniImagesCard";
import { useDispatch, useSelector } from "react-redux";
import { mainCategorySlider } from "../../store/frontendSlice";

export default function MiniImagesSlider() {

const dispatch = useDispatch()
  const { mainCategorySliderData } = useSelector(
    (state) => state.frontend
  );

  useEffect(() => {
    dispatch(mainCategorySlider())
  }, [])

  return (
    <>
      <div className={`px-2 lg:px-[57px] 2xl:px-[20px] max-w-screen-2xl container mx-auto`}>
        <div className="flex justify-start items-center gap-7 carousel carousel-center  py-2 mt-7 h-full">
          {mainCategorySliderData?.categories?.map((item) => (
            <>
              <MiniImagesCard key={item._id} item={item} />
            </>
          ))}
        </div>
      </div>
    </>
  );
}
