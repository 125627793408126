import React, { useEffect } from "react";
import ShelfSection from "../../../components/Main-Category-Components/Shelf-Section/ShelfSection";
import BoostShopSection from "../../../components/Main-Category-Components/Boost-Shop-Section/BoostShopSection";
import SmartFilterBar from "../../../components/SmartFilter/SmartFilterBar";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getCategoryPremiumProducts, getCategoryProducts, getSubCategoryProducts } from "../../../store/frontendSlice";
import { toast } from "react-toastify";

export default function SubCategoryPage() {
  const {slug}=useParams();
  const { data, products } = useSelector(state => state.frontend);
  const dispatch = useDispatch();
  const { message, error, loading } = useSelector(state => state.frontend);

  // useEffect(() => {
  //   if(message){
  //     toast.success(message);
  //   }
  //   if(error){
  //     toast.error(error);
  //   }
  //   dispatch(clearMessage());
  // }, [message, error, loading]);

  useEffect(() => {
    dispatch(getSubCategoryProducts(slug))
    // console.log(products)
  }, [slug]);

  return (
    <>
      {/* <ShelfSection data={data}/>
      <BoostShopSection stores={data.stores}/> */}
      <div className="flex w-full h-[1px] -mt-4"></div>
      <div className="flex w-full justify-center">
        <div className="w-full max-w-screen-2xl flex flex-col">
          <SmartFilterBar getFunction={getCategoryProducts} products={products.data}/>
        </div>
      </div>
    </>
  );
}
