import UserDashboardBuyingLayout from "../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";
import UserDashboardBuyingHome from "../../../components/User-Dashboard-Components/User-Dashboard-Buying-Components/User-dashboard-Buying-Home-Components/UserDashboardBuyingHome";

export default function UserDashboardBuyingPage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <UserDashboardBuyingHome />
      </UserDashboardBuyingLayout>
    </>
  );
}
