import { useEffect } from "react";
import CategoryShelf from "../../../components/MyShelf-Component/Category-Shelf/CategoryShelf";
import RecentlyBoughtProducts from "../../../components/MyShelf-Component/Recently-Bought-Products/RecentlyBoughtProducts";
import ShelfCoupon from "../../../components/MyShelf-Component/Shelf-Coupon/ShelfCoupon";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getMyShelf } from "../../../store/frontendSlice";
import { toast } from "react-toastify";

function MyShelf() {
  const { mainCategorySliderData1 , sessiontoken,message,error,loading} = useSelector(state => state.frontend);
  const dispatch=useDispatch()

  useEffect(() => {
    dispatch(getMyShelf(sessiontoken));
  }, []);

  useEffect(() => {
    if(message){
      toast.success(message)
      dispatch(getMyShelf(sessiontoken));

    }
    if(error){
      toast.error(error)
    }
    dispatch(clearMessage());
  }, [loading, message, error]);

  return (
    <main className="w-full top-6 max-w-screen-2xl mx-auto">
      <ShelfCoupon />
      <CategoryShelf mainCategorySliderData={ mainCategorySliderData1} />
      <RecentlyBoughtProducts mainCategorySliderData={ mainCategorySliderData1}/>
    </main>
  );
}

export default MyShelf;
