import React, { useEffect } from "react";
import AuctionHomeFeaturedProduct from "../../../../components/Ad-MarketPlace-Components/Auction-Components/Auction-HomePage/AuctionHomeFeaturedProduct";
import AuctionHomeCategoriesBar from "../../../../components/Ad-MarketPlace-Components/Auction-Components/Auction-HomePage/Auction-Home-Categories-Bar-Slider/AuctionHomeCategoriesBar";
import AuctionHomeLastChanceSection from "../../../../components/Ad-MarketPlace-Components/Auction-Components/Auction-HomePage/Auction-Home-Last-Chance-Section/AuctionHomeLastChanceSection";
import AuctionHomePopularProductSection from "../../../../components/Ad-MarketPlace-Components/Auction-Components/Auction-HomePage/Auction-Home-Popular-Products-Section/AuctionHomePopularProductSection";
import AuctionHomeCategoriesProducts from "../../../../components/Ad-MarketPlace-Components/Auction-Components/Auction-HomePage/Auction-Home-Categories-Products/AuctionHomeCategoriesProducts";
import { useDispatch, useSelector } from "react-redux";
import { getAuction, getRandomAuctionProduct } from "../../../../store/frontendSlice";
export default function AuctionHomePage() {
  const { aucData ,randomAucProd} = useSelector(state => state.frontend);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAuction());
    dispatch(getRandomAuctionProduct());
  },[])
  return (
    <div className="w-full flex flex-col gap-12">
      <AuctionHomeFeaturedProduct data={ randomAucProd?.auctionProduct} />
      <AuctionHomeCategoriesBar data={ aucData?.categories} />
      {/* <AuctionHomeLastChanceSection />
      <AuctionHomePopularProductSection /> */}
      <AuctionHomeCategoriesProducts  data={aucData?.categoryProducts }/>
    </div>
  );
}
