import React from 'react'
import AuctionMainCategoryProducts from '../../../../components/Ad-MarketPlace-Components/Auction-Components/Auction-Main-Category-Products-Page-Components/AuctionMainCategoryProducts'

export default function AuctionMainCategoryProductsPage() {
  return (
    <>
      <AuctionMainCategoryProducts />
    </>
  )
}
