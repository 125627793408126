import { useDispatch, useSelector } from "react-redux";
import { deleteCartProduct, getCartProducts } from "../../store/frontendSlice";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function CartSidebar({ cart, setCart, cartItems }) {

  const dispatch = useDispatch()


  return (
    <>
      <div className={`${cart ? "flex justify-end mr-24  " : "hidden"}`}>
        <div className="w-[350px] h-[90vh] mt-14 z-50 fixed px-6 bg-white rounded-2xl shadow-xl flex flex-col ">
          <div className="flex justify-between sticky items-start bg-white   py-7">
            <div className="text-xl font-semibold">SHOPPING CART</div>
            <span onClick={() => setCart(false)} className=" cursor-pointer ">
              <img className="w-5 h-5" src="/assets/icons/cancel.svg" alt="" />
            </span>
          </div>
          <div className=" flex-grow overflow-y-auto hide-scrollbar overflow-hidden ">
            {cartItems?.length > 0 &&
              cartItems?.map((item) => {
                return item?.products?.map((item) => {
                  return (
                    <div className="border-t flex flex-col   py-5">
                      <div className="flex items-start justify-between gap-3">
                        <img
                          className="w-20 h-[75px] rounded-xl"
                          src={item.product_picture}
                          alt=""
                        />
                        <div className="flex flex-col gap-2 py-1">
                          <span className="text-sm font-[500]">
                            {item.name}
                          </span>
                          <div className="flex items-center gap-2">
                            <span>{item.quantity} X</span>
                            <span className="font-bold text-[#75BFB3]">
                              ${item.price}
                            </span>
                          </div>
                        </div>

                        <img
                          onClick={(e) => {
                            dispatch(deleteCartProduct(item?.product_id)).then(
                              () => {
                                dispatch(getCartProducts());
                              }
                            );
                          }}
                          className="w-3 h-3 cursor-pointer"
                          src="/assets/icons/cancel.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  );
                });
              })}
          </div>
          <div className="flex flex-col gap-3 justify-end mb-2">
            <div className="border-t border-b py-5 flex items-center justify-between">
              <span className="text-xl font-semibold">SUBTOTAL:</span>
              <span className="font-semibold text-xl text-[#75BFB3]">
                $
                {cartItems.length > 0
                  ? cartItems.reduce((acc, item) => acc + item.Total, 0)
                  : 0}
              </span>
            </div>
            <Link
            to="/cart"
            className="w-full border text-center border-[#75BFB3] rounded-md py-4 text-lg text-[#686868]">
              VIEW CART
            </Link>
            <Link to="/checkout" className="w-full bg-[#75BFB3] border text-center rounded-md py-4 text-lg text-white">
              CHECKOUT
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
