import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import "./adds-slider.css";

export default function ThirdSliders({ products, startIndex, endIndex }) {
  const slider = React.useRef(null);

  // setting
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <simpleNextArrow />,
    prevArrow: <simplePrevArrow />,
  };

  return (
    <div className="slider-container relative">
      {/* Angle button */}
      <div onClick={() => slider?.current?.slickPrev()} className="cursor-pointer left-button absolute z-20 left-0 top-10 xl:top-14 invisible bg-white flex justify-center items-center w-8 h-8 xl:w-12 xl:h-12 rounded-full opacity-50 hover:opacity-100">
        <button className="xl:p-4">
          <img
            src="../../assets/images/home-sliders/small-lt.png"
            alt=""
            className="w-[7px] xl:w-[9px]"
          />
        </button>
      </div>
      <div onClick={() => slider?.current?.slickNext()} className="cursor-pointer right-button absolute z-20 right-0 top-10 xl:top-14 invisible bg-white flex justify-center items-center w-8 h-8 xl:w-12 xl:h-12 rounded-full opacity-50 hover:opacity-100">
        <button className="xl:p-4">
          <img
            src="../../assets/images/home-sliders/small-gt.png"
            alt=""
            className="w-[7px] xl:w-[9px]"
          />
        </button>
      </div>

      {/* slider start*/}
      <Slider ref={slider} {...settings} className="overflow-hidden">
        {products?.slice(startIndex, endIndex).map((item, index) => {
          return (
            <div className="w-full h-[117px] xl:h-[164px] overflow-hidden self-center rounded-lg">
            <img 
            src={item.image}
            alt="" className="w-full h-full object-fill"/>
          </div>
      );
        })}
      </Slider>
      {/* slider end*/}
    </div>
  );
}
