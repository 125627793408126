import UserDashboardBuyingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";
import UserDashboardReviews from "../../../../components/User-Dashboard-Components/User-Dashboard-Buying-Components/User-Dashboard-Reviews/UserDashboardReviews";

export default function UserDashboardReviewPage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <UserDashboardReviews />
      </UserDashboardBuyingLayout>
    </>
  );
}
