import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  availableAffliateProduct,
  logout,
  myAffliateStore,
  searchAffliateProduct,
} from "../../../store/categorySlice";
import { imagePath } from "../../../functions/URLString";

const UserDashboardAffilliateAvailableProduct = () => {
  const dispatch = useDispatch();
  const { data, loading, message, error } = useSelector(
    (state) => state.category
  );

  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (data && data.last_page) {
      setTotalPages(data.last_page);
    }
  }, [data]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(availableAffliateProduct(page));
    }
  };

  useEffect(() => {
    dispatch(availableAffliateProduct());
  }, []);

  useEffect(() => {
    // console.log(data);
  }, [data]);

  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }

    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Available Affilliate Products ",
              path: "",
            },
          ]}
        />

        {/* search */}
        <div className="flex justify-between gap-5">
          <div className="relative flex items-center w-full ">
            <img
              className="absolute left-3"
              src="/assets/icons/search.svg"
              alt=""
            />

            <input
              className="w-full px-9 py-[10px] rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
              type="search"
              placeholder="Search product ..."
              onChange={(e) => dispatch(searchAffliateProduct(e.target.value))}
            />
          </div>
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto ">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            {/* Table Header */}
            <div className="min-w-[768px] md:min-w-[700px] lg:min-w-[900px]">
              <div className="flex  items-center justify-between gap-2 p-4 border-b text-[#6B6B6B] font-semibold text-[12px] border-gray-300">
                <span className="w-[30%] text-left">PRODUCTS</span>

                <span className="w-[7.5%] text-left">PRICE</span>

                <span className="w-[7.5%] text-left">COMMISSION</span>

                <span className="w-[7.5%]  text-left ">ACTION</span>
              </div>

              {/* Table Data Row */}
              {data?.products?.length>0?
                data.products.map((item, index) => (
                  <div className="flex items-center justify-between gap-2 text-[#18120F] p-2 lg:p-4 border-b text-xs lg:text-sm">
                    <div className="w-[30%] flex items-left">
                      <img
                        src={item.image}
                        alt="Product"
                        className="w-10 h-10 rounded-lg mr-4"
                      />
                      <div>
                        <p className=" tracking-wide pb-1  text-[#18120F] font-semibold ">
                          {item.name}
                        </p>
                        <p className="text-[#6B6B6B] tracking-wider  font-semibold  text-nowrap">
                          Category: {item.category_name}
                        </p>
                      </div>
                    </div>

                    <span className="  font-[600] text-[#18120F] w-[7.5%] text-left">
                      ${item.price}
                    </span>

                    <span className="w-[7.5%]  font-[600]  text-left pl-5">
                      ${item.commission}
                    </span>

                    <div className="w-[7.5%]  font-[600]  ">
                      <div className="bg-[#FAFAFA] rounded-xl p-3 border border-[#EBF0ED] w-fit">
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16 6.69999C16 7.96011 12.6421 12.3999 8.49999 12.3999C4.35785 12.3999 1 7.96011 1 6.69999C1 5.43988 4.35785 1 8.49999 1C12.6421 1 16 5.43988 16 6.69999Z"
                            stroke="#828282"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.50078 8.68052C9.76878 8.68052 10.482 7.96727 10.482 6.69927C10.482 5.43127 9.76878 4.71802 8.50078 4.71802C7.23278 4.71802 6.51953 5.43127 6.51953 6.69927C6.51953 7.96727 7.23278 8.68052 8.50078 8.68052Z"
                            stroke="#828282"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                )):"No Products Found"}
            </div>
          </div>
        </div>

          <div className="flex flex-col md:flex-row justify-between items-center mb-4">
            <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
              SHOWING{" "}
              {data?.pagination?.from} -{" "}
              {data?.pagination?.to}{" "}
              OF {data?.pagination?.total} RESULTS
            </span>

        {data?.pagination?.total>10 && (
            <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
              {/* Previous Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                }
              </div>
              {/* Next Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px]  cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
        )}
          </div>
      </div>
    </>
  );
};

export default UserDashboardAffilliateAvailableProduct;
