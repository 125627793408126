import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BoostShopCard from "./BoostShopCard";

import "./BoostShopSection.css"; // Import your CSS file for styling
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";

export default function BoostShopSection({stores}) {
  const [boostShops, setBoostShops] = useState([]);

  // Function to chunk array into arrays of size 'size'
  const chunkArray = (arr, size) => {
    return arr.reduce((chunks, el, i) => {
      if (i % size === 0) {
        chunks.push([el]);
      } else {
        chunks[chunks.length - 1].push(el);
      }
      return chunks;
    }, []);
  };

  // Split boostShops into chunks of 6
  const boostShopsChunks = chunkArray(boostShops, 6);

  // Settings for the slider
  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <IoIosArrowRoundForward />,
    prevArrow: <IoIosArrowRoundBack />,
    dots: true,
    appendDots: (dots) => <ul style={{ margin: "0px" }}> {dots} </ul>,
    customPaging: (i) => (
      <div
        style={{
          width: "7px",
          height: "7px",
          backgroundColor: "#D9D9D9",
          borderRadius: "50%",
          display: "inline-block",
        }}
      />
    ),
  };

  return (
    <div className={`relative px-8 lg:px-6 xl:px-10 w-full my-7 `}>
      <div className="hidden md:block booster-shop">
        <Slider {...sliderSettings} className="w-full">
          {stores?.length>0? stores?.slice(0, Math.ceil(stores?.length/6)).map((chunk, index) => (
            <div key={index} className="">
              <div
                className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-items-center items-center w-full p-4`}
              >
                {stores?.slice(index*6, index*6+6).map((item) => (
                  <BoostShopCard item={item} id={item.id} />
                ))}
              </div>
            </div>
          )):<>No Store Found</>}
        </Slider>
      </div>
    </div>
  );
}
