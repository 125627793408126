import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import BoostedStoreCard from "../../Store-Square-Boosted-Page-Components/BoostedStoreCard";

export default function BoostedStoreSection({storeSqure}) {
  return (
    <>
      <div className="px-4  lg:px-10 xl:px-[57px] mt-7">
        {/* boosted store */}
        <div className="flex justify-between mt-8">
          <h2 className="font-bold text-base xl:text-2xl  ">Boosted Stores</h2>

          <Link to="/store-square/boosted-stores">
            <p className="text-[#1983FF] text-sm xl:text-base flex items-center gap-1">
              <span>View More</span> <AiOutlineRight />
            </p>
          </Link>
        </div>

        {/* cards */}

        <div className="w-full xl:max-w-screen-2xl container mx-auto mt-6 mb-6 flex justify-center">
          {/* Top selling card */}
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10">
            {storeSqure?.Boostedstores?.length>0&&storeSqure?.Boostedstores.map((item) => {
    
              return (<BoostedStoreCard data={ item} />)
            })}
           
          
          </div>
        </div>
      </div>
    </>
  );
}
