import React, { useState } from "react";
// import "./BundleCards.css";
// import { Link } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
const generateStarRating = (rating) => {
  const stars = [];
  const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
  const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <img key={i} src={filledStar} alt="filled star" className="w-4 h-4" />
      );
    } else {
      stars.push(
        <img
          key={i}
          src={unfilledStar}
          alt="unfilled star"
          className="w-4 h-4"
        />
      );
    }
  }
  return stars;
};
const VendorBundlesCard = ({ bundle }) => {
  const [isHeartFilled, setIsHeartFilled] = useState(false);

  const handleHeartClick = () => {
    setIsHeartFilled(!isHeartFilled);
  };

  return (
    <>
      <div className="flex w-full justify-center">
        <div
          key={bundle._id}
          className="w-full md:w-[302px] h-[432px] md:h-96 lg:w-[216px] lg:h-[273px] xl:w-[302px] xl:h-96 relative   rounded-lg bundles-card-shadow"
        >
          <h2 className=" z-10 bottom-0  absolute ml-6 pb-2 text-xl  text-white">
            {bundle.title}
          </h2>
          <div className=" grid grid-cols-2 gap-2 px-8 py-5">
            {bundle.products.map((bundle) => (
              <div
                key={bundle._id}
                className={`relative shadow-md items-center  rounded-lg `}
              >
                <div>
                  <img
                    src={bundle.image}
                    className="h-[160px] md:h-[128px] lg:h-[88px] xl:h-32 relative  w-full"
                    alt={bundle.name}
                  />
                  <p className="absolute items-center top-2 left-2 bg-gray-50 py-[1px] px-1 lg:py-[0.75px] lg:px-[3px] xl:py-[1px] xl:px-1 rounded-full  text-[5px] lg:text-[4px] xl:text-[5px]">
                    {bundle.discountprice}
                  </p>
                  {/* <div
                    className={`rating gap-1 absolute right-1 top-1 p-[2px] bg-[#1983FF33] rounded-full flex justify-center items-center ${
                      isHeartFilled
                        ? "text-blue-500 duration-500 ease-in-out"
                        : "text-white"
                    }`}
                    onClick={handleHeartClick}
                  >
                    <FaHeart className="h-2 w-2 cursor-pointer" />
                  </div> */}
                </div>
                <div className="flex flex-col px-1 w-full justify-between">
                  {/* put */}
                  <div className=" pb-1">
                    <h3 className="text-[7px] lg:text-[5px] xl:text-[7px] font-bold">
                      {bundle.name.slice(0, 12)}
                    </h3>
                    <div className="flex items-center gap-1">
                 {   generateStarRating(bundle.total_rating)}
                      <span className="text-[4px] lg:text-[3px] xl:text-[4px]">
                      bundle.total_rating
                      </span>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center gap-1">
                        <p className="text-[7px] lg:text-[5px] xl:text-[7px] font-bold">
                          ${bundle.discountprice}{" "}
                        </p>
                        <p className="opacity-70 text-[6px] lg:text-[4.6px] xl:text-[6px] ">
                          ${bundle.price}
                        </p>
                      </div>

                      {/* <div className="cursor-pointer">
                        <img
                          src="./assets/images/top-sell/top-sell-cart-icon.png"
                          alt="cart-button"
                          className="h-2 w-2"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorBundlesCard;
