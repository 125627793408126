import React, { useEffect } from "react";
import AdStoreVehiclesTopCarBrands from "./AdStoreVehiclesTopCarBrands";
import AdStoreVehiclesCarsByCategory from "./AdStoreVehiclesCarsByCategory";
import AdStoreVehiclesSingleCategoryCars from "./AdStore-Vehicles-SingleCategoryCars/AdStoreVehiclesSingleCategoryCars";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getAdMarketMainCatProducts } from "../../../store/frontendSlice";



const AdStoreVehicles = () => {

  const { slug } = useParams();
  const dispatch = useDispatch()
  const { adMainCatProduct } = useSelector(state => state.frontend);

  useEffect(() => {
    dispatch(getAdMarketMainCatProducts(slug))
  }, [])
  

  return (
    <>
      <div className="my-8 px-4 lg:px-8 xl:px-[57px] flex flex-col gap-8 max-w-screen-2xl mx-auto">
        <div className="text-base breadcrumbs font-bold ">
          <ul>
            <li>
            <Link to={"/ad-market-place"}>
              <a>Home</a>
            </Link>
            </li>
            <li>
              <a>{slug}</a>
            </li>
          </ul>
        </div>
        {/* <AdStoreVehiclesTopCarBrands /> */}
        {adMainCatProduct?.subCategories.length>0 && 
        <AdStoreVehiclesCarsByCategory  adMainCatProduct={adMainCatProduct?.subCategories}  />
        }
        <AdStoreVehiclesSingleCategoryCars adMainCatProduct={adMainCatProduct?.categoryProducts} />
      </div>
    </>
  );
};

export default AdStoreVehicles;
