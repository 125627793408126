import React from "react";
import { HiMiniCheckCircle } from "react-icons/hi2";
import { AiOutlineHeart } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";
import { imagePath } from "../../../functions/URLString";
import { stringConcat } from "../../../functions/formatNumber";

const generateStarRating = (rating) => {
  const stars = [];
  const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
  const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <img key={i} src={filledStar} alt="filled star" className="w-3 h-3" />
      );
    } else {
      stars.push(
        <img
          key={i}
          src={unfilledStar}
          alt="unfilled star"
          className="w-3 h-3"
        />
      );
    }
  }
  return stars;
};

const generateStarRatingSecond = (rating) => {
  const stars = [];
  const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
  const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <img key={i} src={filledStar} alt="filled star" className="w-2 h-2" />
      );
    } else {
      stars.push(
        <img
          key={i}
          src={unfilledStar}
          alt="unfilled star"
          className="w-2 h-2"
        />
      );
    }
  }
  return stars;
};

export default function BoostShopCard({ item, id }) {

  const formatLikes = (likes) => {
    if (likes >= 1000000) {
      return (likes / 1000000).toFixed(1) + 'M';
    } else if (likes >= 1000) {
      return (likes / 1000).toFixed(1) + 'K';
    } else {
      return likes.toString();
    }
  };

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + '...';
    }
    return str;
  };

  return (
    <div className="flex justify-between m-3 md:m-0 gap-4 px-6 boost-shop-card-shadow py-5 rounded-lg w-[300px] xl:w-[377px] ">
      <div className="flex flex-col min-w-[99px]">
        <div className="w-14 h-14 overflow-hidden self-center rounded-full">
          <img 
          src={item.shop_picture.length>3?item.shop_picture:"https://beforeigosolutions.com/wp-content/uploads/2021/12/dummy-profile-pic-300x300-1.png"}
          alt="" className="w-full h-full object-cover" />
        </div>
        <div className="flex justify-center space-x-2 items-center mt-1">
          <div className="text-sm font-bold text-start whitespace-nowrap">{truncateString(item.store_name,11)}</div>
          <div className="">
            <HiMiniCheckCircle className="text-[#1983FF] w-4 h-4" />
          </div>
        </div>
        <div className="flex gap-1 items-center h-[14px]">
          <div className="rating space-x-1 rating-xs">
            {generateStarRating(item.ratings)}
          </div>
          <div className="text-[#8C8C8C] text-xs">({item.ratings})</div>
        </div>
        <div className="flex justify-around items-center mt-3">
          <div className="flex flex-col items-center">
            <AiOutlineHeart className="text-[#828282] w-5 h-5" />
            <p className="text-xs text-[#828282]">{formatLikes(item.whishlist)}</p>
          </div>
          <div className="flex flex-col items-center">
            <AiOutlineUser className="text-[#828282] w-5 h-5" />
            <p className="text-xs text-[#828282]">{formatLikes(item.followed)}</p>
          </div>
        </div>
        <Link
          to={`/vendor-shop-front/${item.slug}`}
          className="border-2 whitespace-nowrap border-[#C19A6B] py-1 px-5 md:py-1 md:px-4 rounded-md bg-white text-[#C19A6B] font-bold text-[13px] md:text-sm mt-3 text-center"
        >
          Visit Store
        </Link>
      </div>

      <div className=" w-full flex flex-col gap-2">

        {item?.products?.length>0? item.products.map((product)=>(
          <Link
            to={`/single-product/${product.slug}`}
            className="w-full flex gap-2 items-center border cursor-pointer hover:shadow-md border-opacity-25 border-[#CBCBCB] rounded"
          >
            <div className="w-14 h-14 overflow-hidden">
              <img 
              src={product.image.length>3?product.image:"https://beforeigosolutions.com/wp-content/uploads/2021/12/dummy-profile-pic-300x300-1.png"}
              alt="" className="w-full h-full object-cover" />
            </div>
            <div className="w-full flex flex-col ">
              <h2 className="text-xs font-semibold tracking-wide">
                {stringConcat(product.name,16)}
              </h2>
              <div className="flex gap-2 items-center w-full ">
                <div className="rating  space-x-[2px]">
                  {generateStarRatingSecond(product.total_rating)}
                </div>
                <div className="text-[13px] text-[#1983FF] font-semibold">
                  ${product.price}
                </div>
              </div>
            </div>
          </Link>
        )):"No Products"}

      </div>
    </div>
  );
}
