import React, { useEffect, useRef, useState } from "react";
import { TfiLayoutGrid3Alt, TfiLayoutGrid4Alt } from "react-icons/tfi";
import SmartFilterSidebar from "./SmartFilterSidebar";
import SmartFilterCards from "../Main-Category-Components/Smart-Filter-Cards-Section/SmartFilterCards";
import { useDispatch, useSelector } from "react-redux";
// import { setProducts } from "../../store/categorySlice";
import { Link, useParams } from "react-router-dom";
import "./smartFilter.css";
import { IoCloseOutline } from "react-icons/io5";
import { smartFilterInitialData } from "../../store/frontendSlice";

export default function SmartFilterBar({ products, getFunction }) {
  const [view, setView] = useState(5);
  const [isSmartFilterOpen, setIsSmartFilterOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const {slug}=useParams();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [screenSize, setScreenSize] = useState(false);
  const filterRef = useRef(null);
  const dispatch=useDispatch()

  const toggleSmartFilterMenuOpen = () => {
    setIsSmartFilterOpen(!isSmartFilterOpen);
    if(view===5) setView(4);
  };

  useEffect(() => {
    if(isSmartFilterOpen && windowWidth<=1024 && windowWidth>768){
      setView(3);
    }
  }, [ isSmartFilterOpen ]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if(windowWidth<=1024){
      setScreenSize(true);
      setView(6)
    } else {
      setScreenSize(false);
      setView(5)
    }
  }, [ windowWidth ]);

  useEffect(() => {
    dispatch(smartFilterInitialData(slug))
  },[slug])

  // const handleClickOutside = (event) => {
  //   if (filterRef.current && !filterRef.current.contains(event.target)) {
  //     setClosing(true);
  //     setTimeout(() => {
  //       setIsSmartFilterOpen(false);
  //       setClosing(false);
  //     }, 1000);
  //   }
  // };

  // useEffect(() => {
  //   if (isSmartFilterOpen) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [isSmartFilterOpen]);

  return (
    <>
      <div className="flex justify-between items-center px-4 lg:px-9  mt-12 mb-6">
        <div className="flex items-center gap-4">
          <button
            onClick={toggleSmartFilterMenuOpen}
            className="filter-icon flex text-nowrap items-center font-semibold text-sm lg:text-xl gap-3  h-8 cursor-pointer "
          >
            Smart Filter{" "}
            <span>
              <svg
                className="w-[13px] h-[12px] lg:w-[18px] lg:h-[17px]"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.65625 4.87354H18.8402"
                  stroke="black"
                  strokeWidth="1.49425"
                  strokeLinecap="round"
                />
                <path
                  d="M1.65625 13.0918H18.8402"
                  stroke="black"
                  strokeWidth="1.49425"
                  strokeLinecap="round"
                />
                <path
                  className="upper-circle"
                  d="M6.88597 7.86178C8.53647 7.86178 9.87447 6.52378 9.87447 4.87327C9.87447 3.22277 8.53647 1.88477 6.88597 1.88477C5.23546 1.88477 3.89746 3.22277 3.89746 4.87327C3.89746 6.52378 5.23546 7.86178 6.88597 7.86178Z"
                  fill="white"
                  stroke="black"
                  strokeWidth="1.49425"
                  strokeLinecap="round"
                />
                <path
                  className="lower-circle"
                  d="M13.6106 16.0805C15.2611 16.0805 16.5991 14.7425 16.5991 13.092C16.5991 11.4415 15.2611 10.1035 13.6106 10.1035C11.9601 10.1035 10.6221 11.4415 10.6221 13.092C10.6221 14.7425 11.9601 16.0805 13.6106 16.0805Z"
                  fill="white"
                  stroke="black"
                  strokeWidth="1.49425"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          </button>
          <div className="hidden md:block text-xs xl:text-xl">
            "{products?.length}" Products Found for{" "}
            <Link className="text-[#1983FF]">"{slug}"</Link>
          </div>
        </div>

        {screenSize?"":
          <div className="flex items-center gap-7">
            <div className="hidden md:flex items-center gap-3 ">
              <div className="text-sm lg:text-xl">View:</div>
              <div onClick={() => setView(3)}>
                <TfiLayoutGrid3Alt
                  className={`${
                    view === 3 ? "text-black" : "text-[#D9D9D9]"
                  } w-[19px] h-[19px] lg:h-7 lg:w-7`}
                />
              </div>
              <div onClick={() => setView(4)}>
                <TfiLayoutGrid4Alt
                  className={`${
                    view === 4 ? "text-black" : "text-[#D9D9D9]"
                  } w-[19px] h-[19px] lg:h-7 lg:w-7`}
                />
              </div>
              {isSmartFilterOpen?"":
                <div onClick={() => setView(5)}>
                  <TfiLayoutGrid4Alt
                    className={`${
                      view === 5 ? "text-black" : "text-[#D9D9D9]"
                    } w-[19px] h-[19px] lg:h-7 lg:w-7`}
                  />
                </div>
              }
            </div>

            {/* <div className="bg-[#0000001A] rounded-full flex shadow">
              <button className="rounded-full bg-white px-5 py-[4px] lg:px-7 lg:py-[6px] text-[15px] lg:text-[21px]">
                Product
              </button>
              <button className="px-5 py-[4px] lg:px-7 lg:py-[6px] text-[15px] lg:text-[21px]">
                Room
              </button>
            </div> */}
          </div>
        }

      </div>

      {isSmartFilterOpen && (
        <div
          ref={filterRef}
          className={`flex flex-col md:hidden fixed top-0 left-0 h-screen bg-white z-50 w-1/2 sm:w-3/4 ${
            closing ? "animate-sm-out" : "animate-sm"
          } `}
        >
          <div className="w-full relative py-4 px-4 sm:px-8 flex items-center justify-between  ">
            <span className="w-full text-start sm:text-center font-bold text-base sm:text-lg">
              Smart Filter
            </span>
            <IoCloseOutline
              onClick={toggleSmartFilterMenuOpen}
              className="absolute right-4 sm:right-6 z-10 w-6 h-6 sm:w-8 sm:h-8"
            />
          </div>
          <div className="pb-4 px-8">
            <SmartFilterSidebar />
          </div>
        </div>
      )}

      <div
        className={`flex w-full px-4 lg:px-9 ${
          isSmartFilterOpen ? " filter-open" : ""
        }`}
      >
        <div
          className={`filter-container hidden md:flex ${
            isSmartFilterOpen ? "w-64  animate-sm" : ""
          }`}
        >
          {isSmartFilterOpen && <SmartFilterSidebar />}
        </div>
        <div className="filter-content w-full">
          <SmartFilterCards view={view} getFunction={getFunction} products={products} smartFilterOpen={isSmartFilterOpen} />
        </div>
      </div>
    </>
  );
}
