import React from "react";
import "./Shelf-Category-Slider.css";
import { Link } from "react-router-dom";

export default function ShelfCategorySlider({ mainCategorySliderData }) {
  console.log(mainCategorySliderData);

  return (
    <>
      <div>
        <div className="flex justify-between items-center gap-7 carousel carousel-center  py-2">
          {mainCategorySliderData?.categories?.map((item, index) =>
          { // console.log(item)
            return (
            <Link
              to={`/my-shelf/${item.slug}`}
              key={index}
              className="my-shelf-category-card carousel-item rounded-md hover:rounded-lg"
            >
              <img
                className={` h-[203px] w-[358px] md:w-[284px]  xl:w-[398px]`}
                src={item.shelf_image ? item.shelf_image : '/assets/images/alt-img.webp'}
                alt=""
              />
              <div className="my-shelf-category-overlay">
                <div className=" my-shelf-category-text font-black text-white">
                  {item.name}
                </div>
              </div>
            </Link>
          )})}
        </div>
      </div>
    </>
  );
}
