import React, { useEffect, useState } from "react";
import { TfiLayoutGrid3Alt, TfiLayoutGrid4Alt } from "react-icons/tfi";
import { IoListSharp } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import AuctionMainCategoryProductCard from "./AuctionMainCategoryProductCard";
import { useDispatch, useSelector } from "react-redux";
import { getAuctionBySlug } from "../../../../store/frontendSlice";

export default function AuctionMainCategoryProducts() {
  const { category } = useParams();
  const [mainCategoryProducts, setMainCategoryProducts] = useState([]);
  const [originalMainCategoryProducts, setOriginalMainCategoryProducts] =
    useState([]); // Keep the original order
  const [view, setView] = useState(5);
  const [isOpen, setIsOpen] = useState(false);
  const [sortType, setSortType] = useState("All");

  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const handleView3 = () => setView(3);
  const handleView5 = () => setView(5);
  const handleView1 = () => setView(1);

  const handleSelection = (type) => {
    setSortType(type);
    setIsOpen(false);

    if (type === "Latest") {
      // Sort products by timestamp in descending order
      const sortedMainCategoryProducts = [...originalMainCategoryProducts].sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setMainCategoryProducts(sortedMainCategoryProducts);
    } else {
      // Restore the original order for "All"
      setMainCategoryProducts(originalMainCategoryProducts);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const dispatch = useDispatch()
  const { auctionCatData } = useSelector(state => state.frontend);
  useEffect(() => {
    if (category) {
      dispatch(getAuctionBySlug(category));
 }
  }, [category]);

  return (
    <>
      <div className="px-4 lg:px-8 xl:px-[57px]">
        <div className="text-base breadcrumbs font-bold ">
          <ul>
            <li>
              <Link to="/Ad-martket-place/auction">Home</Link>
            </li>
            <li>
              <Link>{breadCrumbs}</Link>
            </li>
          </ul>
        </div>

        {/* sorted and view */}
        <div className="flex my-5 items-center justify-end">

          <div className="flex items-center gap-3">
            <div className="text-lg">View:</div>
            <div onClick={handleView3}>
              <TfiLayoutGrid3Alt
                className={`${
                  view === 3 ? "text-black" : "text-[#D9D9D9]"
                } h-7 w-7 cursor-pointer hidden md:block`}
              />
            </div>
            <div onClick={handleView5}>
              <TfiLayoutGrid4Alt
                className={`${
                  view === 5 ? "text-black" : "text-[#D9D9D9]"
                } h-8 w-8 cursor-pointer`}
              />
            </div>
            <div onClick={handleView1}>
              <IoListSharp
                className={`${
                  view === 1 ? "text-black" : "text-[#D9D9D9]"
                } h-10 w-10 cursor-pointer`}
              />
            </div>
          </div>
        </div>

        {/* Popular products */}
        <div
          className={`grid gap-4 ${
            view === 3
              ? "grid-cols-2 md:grid-cols-3"
              : view === 5
              ? "grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
              : `grid-cols-${view}`
          }`}
        >
          {auctionCatData.categoryProducts && auctionCatData.categoryProducts.length>0 && auctionCatData?.categoryProducts?.map((item, index) => (
            <div className={`${view==1?"justify-start":"justify-center"}flex w-full`}>
              <AuctionMainCategoryProductCard
                key={index}
                item={item}
                view={view}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
