import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { orderListSearch, userOrdersList } from "../../../../store/categorySlice";
import BreadCrums from "../../../Common/BreadCrums";
import Loader from "../../../Common/Loader";


const UserDashboardOrderList = () => {

  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.category);

  const [search, setSearch] = useState("");

  // by default
//   useEffect(() => {
//     dispatch(userOrdersList());
//   }, [dispatch]);

//   if search true
  useEffect(() => {
    if (search) {
      dispatch(orderListSearch(search));
    } else {
      dispatch(userOrdersList());
    }
  }, [search]);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        {/* breadcrum */}
        <BreadCrums
          breadCrum={[
            { name: "Order" },

          ]}
        />

        {/* Search and Add Product */}
        <div className="flex justify-between gap-6">
          <div className="relative flex items-center w-full">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3"
            />
            <input
              className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-xs md:text-sm"
              type="text"
              placeholder="Search Placed Orders Using Order ID..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        {/* Table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[20%] text-left uppercase">ORDER ID </span>

                <span className="w-[20%] text-left uppercase">AMOUNT</span>
                <span className="w-[20%] text-left uppercase">
                  PAYMET METHOD
                </span>
                <span className="w-[20%] text-left uppercase">ORDER DATE</span>

                <span className="w-[20%] text-left uppercase">Action</span>
              </div>

              {/* Table Body */}
              {loading ? (

                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>

              ) : data?.user_orders?.length > 0 ? (
                data?.user_orders?.map((order) => (
                  <div
                    key={order.order_id}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b "
                  >
                    <span className="w-[20%] text-left  ">
                      #{order.order_uid}
                    </span>

                    <span className="w-[20%] text-left ">${order.amount}</span>
                    <span className="w-[20%] text-left ">
                      {order.payment_method}{" "}
                    </span>
                    <span className="w-[20%] text-left ">{order.date} </span>

                    <span className=" w-[20%] text-left ">
                      <Link
                        to={`/user-dashboard/orders/order-by-id/${order.id}`}
                      >
                        <img
                          className="w-7 h-7 md:w-9 md:h-9"
                          src="/assets/icons/user-dash-board/order-view.svg"
                          alt=""
                        />
                      </Link>
                    </span>
                  </div>
                ))
              ) : (
                <div className="text-center font-bold tracking-wide">
                  No Placed Order
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDashboardOrderList;
