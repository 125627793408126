import { Link } from "react-router-dom";
import BreadCrums from "../../../Common/BreadCrums";

import "./progress.css";
import { useEffect, useRef } from "react";

export default function UserDashboardOrderView() {
  const progressCircleRef = useRef(null);

  function updateProgressBar(status) {
    const progressCircle = progressCircleRef.current;
    if (progressCircle) {
      progressCircle.classList.remove("processing", "shipping", "delivered");

      if (status === "processing") {
        progressCircle.classList.add("processing");
      } else if (status === "shipping") {
        progressCircle.classList.add("shipping");
      } else if (status === "delivered") {
        progressCircle.classList.add("delivered");
      }
    }
  }

  useEffect(() => {
    let orderStatus = "delivered";
    updateProgressBar(orderStatus);
  }, []);

  return (
    <div className=" w-full flex flex-col px-4 gap-2">
      <BreadCrums
        breadCrum={[
          {
            name: "Orders",
            path: "/user-dashboard/orders",
          },
          {
            name: "Order Details",
            path: "",
          },
        ]}
      />

      {/* order date & time  */}
      <div className="w-full flex justify-between items-center py-2 rounded-[10px] review-box-shadow">
        <div className="flex gap-4 items-center pl-6 ">
          <div className="flex gap-1">
            <span className="font-semibold text-xs md:text-base">
              Order Number:
            </span>{" "}
            <span className="text-[#828282] font-semibold text-xs md:text-base">
              #1234
            </span>
          </div>

          <div className="flex gap-1">
            <span className="font-light text-[8px] md:text-xs">Place On:</span>
            <span className="font-light text-[8px] md:text-xs  text-[#828282]">
              01 April 1999
            </span>
          </div>
        </div>

        <div className="font-semibold text-xs md:text-base pr-6">
          Total: $1220
        </div>
      </div>

      {/* order details  */}
      <div
        // key={offer.id}
        className="flex flex-col items-center  w-full mt-3  rounded-[10px] review-box-shadow "
      >
        <div className="w-full flex justify-between items-center py-1 border-b border-b-[#E4E4E4] px-5 ">
          <span className=" font-semibold text-sm md:text-lg">BingTechs</span>

          <div className="flex items-center gap-3">
            <Link
              to="/user-dashboard/orders/track-order"
              className="text-[#C19A6B] text-[10px] md:text-base border-b border-[#C19A6B]"
            >
              Track Order
            </Link>

            <Link to="" className="">
              <img
                src="/assets/icons/user-dash-board/chat.svg"
                alt="order-view"
                className="w-6 h-6 md:w-8 md:h-8"
              />
            </Link>
          </div>
        </div>

        {/* order progress bar */}

        <div class="py-4 w-full md:w-[372px] flex flex-col items-center gap-2 px-5">
          <div
            class=" w-full md:w-[331px] h-[2px] text-center flex items-center relative bg-[#D9D9D9]"
            id="progressBar1"
          >
            <div class="absolute -top-[6px] md:-top-2 rounded-full w-3 h-3 md:w-4 md:h-4 -translate-x-[50%] bg-black"></div>
            <div
              ref={progressCircleRef}
              id="progressCircle"
              class="absolute -top-[6px] md:-top-2 rounded-full w-3 h-3 md:w-4 md:h-4 -translate-x-[50%] bg-white border border-solid border-black"
            ></div>
          </div>
          <div class="flex justify-between w-full">
            <div class="font-medium text-xs ">Processing</div>
            <div class="font-medium text-xs ">Shipping</div>
            <div class="font-medium text-xs ">Delivered</div>
          </div>
        </div>

        {/* order */}
        <div className="flex justify-between items-start gap-2 px-5 w-full py-3">
          {/* 1st div  */}
          <div className="flex gap-3 items-start w-full">
            {/* product image  */}
            <div>
              <img
                src="/assets/images/shelf/user.jpg"
                alt=""
                className="w-11 h-11 md:w-14 md:h-14 lg:w-[94px] lg:h-[94px] rounded-[5px]"
              />
            </div>

            {/* product details  */}
            <div className="flex flex-col md:flex-row gap-1 justify-between w-[75%] ">
              <div className="flex flex-col gap-1 ">
                <div className="text-xs md:text-base">
                  Product name: dolor sit amet consectetur jsdbca s aday ifsauid
                  ashda sd asdha
                </div>
              </div>

              {/* quantity div  */}
              <div className="">
                <span className="font-normal text-[13px] text-nowrap text-[#888888]">
                  Quantity: 1
                </span>
              </div>
            </div>
          </div>

          {/* product purchased details  */}
          <div className="py-2">
            <span className="font-semibold text-base ">$120</span>
          </div>
        </div>
      </div>
    </div>
  );
}
