import UserDashboardBuyingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";
import UserDashboardOrders from "../../../../components/User-Dashboard-Components/User-Dashboard-Buying-Components/User-Dashboard-Orders/UserDashboardOrders";

export default function UserDashboardOrdersPage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <UserDashboardOrders />
      </UserDashboardBuyingLayout>
    </>
  );
}
