import React from "react";
import UserDashboardSellingLayout from "../../../Layout/User-Dashboard-layout/UserDashboardSellingLayout";
import UserDashboardAdMarketplaceProductTable from "../../../components/User-Dashboard-Components/User-Dashboard-Ad-Marketplace-Product-Components/UserDashboardAdMarketplaceProductTable";

export default function UserDashboardSellingAdmarketplacePage() {
  return (
    <>
      <UserDashboardSellingLayout>
        <UserDashboardAdMarketplaceProductTable />
      </UserDashboardSellingLayout>
    </>
  );
}
