import React from "react";
import AdStoreVehicles from "../../../components/Ad-MarketPlace-Components/Vehicles-page-Components/AdStoreVehicles";

const AdStoreVehiclesPage = () => {
  return (
    <>
      <AdStoreVehicles />
    </>
  );
};

export default AdStoreVehiclesPage;
