import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { follow } from "../../store/frontendSlice";
import {useDispatch, useSelector} from "react-redux"
import {formatNumber, stringConcat} from "../../functions/formatNumber";

const generateStarRating = (rating) => {
  const stars = [];
  const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
  const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <img key={i} src={filledStar} alt="filled star" className="w-3 h-3" />
      );
    } else {
      stars.push(
        <img
          key={i}
          src={unfilledStar}
          alt="unfilled star"
          className="w-3 h-3"
        />
      );
    }
  }
  return stars;
};
const VendorDetails = ({data}) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);
  const dispatch=useDispatch()
  const handlePlayClick = () => {
    if (videoRef.current) {
      if (isVideoPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsVideoPlaying(!isVideoPlaying);
    }
  };

  const follows = () => {
    dispatch(follow({user_id:data?.user?.id}))
  }
  const navigate = useNavigate();
  const { token } = useSelector(state => state.frontend);

  const handleChat = (e) => {
    if (localStorage.getItem("token")) {
      const url = "https://messenger.yugoiptv.com/t/"+token+"/"+data?.user?.id;
      window.location.href = url;
    } else {
      navigate("/dashboard/login")
    }
    
  }

  return (
    <>
      <div className="w-full">
        <div className="relative">
          <img
            class="  bg-cover  bg-center xl:w-full  xl:h-[250px] lg:h-[177px] h-[180px] "
            src="/assets/images/shop-front/Rectangle 698.png"
            alt=""
          />

          <div className="xl:px-[60px] lg:px-[40px] 2xl:px-[300px] md:px-10 px-4 absolute top-[60%] w-full">
            <div className="  bg-white rounded-xl shadow-lg px-4 lg:pl-10 lg:pr-7 lg:py-5 py-4  flex flex-row  items-start lg:items-center justify-between w-full gap-2">
              <div className="flex justify-between md:items-start items-center md:gap-4 md:justify-normal w-full ">
                <div className="flex items-center gap-4">
                  <img
                    className="lg:w-40 md:w-20 lg:h-40 md:h-20 w-20 h-20"
                    src={data?.user?.shop_picture}
                    alt=""
                  />
                  <div className="flex flex-row justify-between lg:items-start lg:gap-5 md:gap-3 ">
                    <div className="flex flex-col md:gap-1 lg:gap-[7px]">
                      <div className="flex items-center gap-2">
                        <span className="lg:text-[28px] text-base font-semibold">
                         {data?.user?.name}
                        </span>
                        {/* <img
                          className="w-4 h-4 lg:w-6 lg:h-6"
                          src= {data?.user?.image}
                          alt=""
                        /> */}
                      </div>
                      <div className="flex items-center gap-2">
                       {generateStarRating(parseInt(data?.totalRatings))}
                        <span className="lg:text-lg text-xs font-[500] text-[#828282]">
                          ({formatNumber(data?.totalRatings)})
                        </span>
                      </div>
                      <div class="flex gap-3 items-center">
                      <Link to={data?.user?.instagram}>
                        <div class="group flex flex-col items-center">
                          <img
                            src="/assets/icons/insta.svg"
                            alt=""
                            class="lg:max-h-7 h-5 w-5 lg:max-w-7 xl:h-8 xl:w-8"
                          />
                          <span class="opacity-0 bg-black text-white text-xs rounded py-1 px-2 absolute z-10 group-hover:opacity-100 top-full mt-2 ml-4 transform -translate-x-1/2 transition-opacity duration-500 ease-in-out">
                            Instagram
                          </span>
                          </div>
                          </Link>
                        <Link to={data?.user?.tiktok}>
                        <div class="group flex flex-col items-center">
                          <img
                            src="/assets/icons/tiktok.svg"
                            alt=""
                            class="lg:max-h-7 h-5 w-5 lg:max-w-7 xl:h-8 xl:w-8"
                          />
                          <span class="opacity-0 bg-black text-white text-xs rounded py-1 px-2 absolute z-10 group-hover:opacity-100 top-full mt-2 ml-4 transform -translate-x-1/2 transition-opacity duration-500 ease-in-out">
                          tiktock
                          </span>
                        </div>
                        </Link>
                        <Link to={data?.user?.twitter}>
                        <div class="group flex flex-col items-center">
                          <img
                            src="/assets/icons/X.svg"
                            alt=""
                            class="lg:max-h-7 h-5 w-5 lg:max-w-7 xl:h-8 xl:w-8"
                          />
                          <span class="opacity-0 bg-black text-white text-xs rounded py-1 px-2 absolute z-10 group-hover:opacity-100 top-full mt-2 ml-4 transform -translate-x-1/2 transition-opacity duration-500 ease-in-out">
                            @twitter
                          </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2 lg:mt-7">
                  <div className="flex flex-col items-center  mt-2">
                    <img
                      src="/assets/icons/heartN.svg"
                      className="text-black w-4 h-4 md:w-5 md:h-5"
                      alt=""
                    />
                    <span>{formatNumber(data?.wishlist)}</span>
                  </div>
                  <div className="flex flex-col items-center mt-2">
                    <img
                      src="/assets/icons/Person.svg"
                      alt=""
                      className="w-4 h-4 md:w-5 md:h-5"
                    />
                    <span>{formatNumber(data?.followed)}</span>
                  </div>
                </div>
              </div>

              <div className="hidden  md:flex flex-col md:gap-2 lg:gap-6">
                <button onClick={(e)=>follows()} className="flex items-center justify-center text-center gap-2 font-semibold md:text-sm lg:text-[17px] border border-[#E13885] text-[#E13885] md:px-8 lg:px-14 lg:py-[10px] md:py-1 rounded-[4px]">
                  {data?.followedStatus==1?"Following":"Follow"}
                  {data?.followedStatus==0 && 
                  <img src="/assets/icons/follow.svg" alt="" />
                  }
                </button>
                <button onClick={(e)=>handleChat(e)} className="flex items-center justify-center text-center gap-2 font-semibold md:text-sm lg:text-[17px] border border-[#E13885] text-[#E13885] md:px-8 lg:px-14 lg:py-[10px] md:py-1 rounded-[4px] text-nowrap"  >
                  Chat Now
                  <img src="/assets/icons/chatnow.svg" alt="" />
                </button>
              </div>
            </div>
            <div className="flex md:hidden flex-col gap-6  py-4">
              <button onClick={(e)=>follows()} className="w-full flex items-center justify-center gap-2 py-[10px] text-lg font-semibold border-2 border-[#E13885] text-[#E13885]  rounded-[4px]">
                {data.followedStatus==1?"Following":"Follow"}
                  {data.followedStatus==0 && 
                  <img src="/assets/icons/follow.svg" alt="" />
                  }
              </button>
              <button className="w-full flex items-center justify-center gap-2 font-semibold py-[10px]  text-lg border-2 border-[#E13885] text-[#E13885]  rounded-[4px]">
                Chat Now
                <img src="/assets/icons/chatnow.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center">
          <div className="xl:px-[60px] max-w-screen-2xl lg:px-[40px] md:px-10 px-4 w-full py-10 md:py-0 lg:py-10 flex flex-col lg:flex-row items-center md:items-start lg:justify-between mt-44 md:mt-28 ">
            <div className="flex flex-col py-4 md:py-0 md:flex-row">
              <div className="relative rounded-lg overflow-hidden">
                <video
                  src={data?.introVideo?.video}
                  autoPlay
                  controls={false}
                  muted
                  ref={videoRef}
                  className="max-w-[350px] max-h-[400px] w-full min-w-[310px] min-h-[201px]  object-cover bg-black"
                ></video>
              </div>

              <div className="w-full md:w-fit text-nowrap flex flex-col items-start md:px-10 gap-2 mt-2">
                <span className="text-2xl font-semibold">{stringConcat(data?.introVideo?.title,25)}</span>
                <span className="text-[#828282] text-lg text-wrap">{data?.introVideo?.description}</span>
              </div>
            </div>
            <div className="border border-[#D9D9D9] flex flex-col gap-6 lg:gap-3 xl:gap-6 shadow-lg rounded-xl px-6 lg:px-3 py-4 w-full">
              <div className="flex gap-4">
                <div className="flex items-center gap-2 w-[180px]">
                  <img src="/assets/icons/vendor-shop-front/loc.svg" alt="" />

                  <span className="xl:text-[22px] lg:text-base font-semibold">
                    Location:
                  </span>
                </div>
                <span className="xl:text-[22px] md:text-lg lg:text-base font-medium">
                {data?.user?.country}
                </span>
              </div>
              <div className="flex gap-4 ">
                <div className="flex items-center gap-2 w-[180px]">
                  <img src="/assets/icons/vendor-shop-front/stor.svg" alt="" />

                  <span className="xl:text-[22px] md:text-lg lg:text-base font-semibold">
                    Store Type:
                  </span>
                </div>
                <span className="xl:text-[22px] md:text-lg lg:text-base font-medium">
                {data?.user?.storetype_name}
                </span>
              </div>{" "}
              <div className="flex gap-4">
                <div className="flex items-center gap-2 w-[180px]">
                  <img src="/assets/icons/vendor-shop-front/style.svg" alt="" />

                  <span className="xl:text-[22px] md:text-lg lg:text-base font-semibold">
                    Style Type:
                  </span>
                </div>
                <span className="xl:text-[22px] md:text-lg lg:text-base font-medium">
                {data?.user?.styletype_name}
                </span>
              </div>
              <div className="flex gap-4">
                <div className="flex items-center gap-2 w-[180px]">
                  <img src="/assets/icons/vendor-shop-front/chatl.svg" alt="" />

                  <span className="xl:text-[22px] md:text-lg lg:text-base font-semibold">
                    Chat Time:
                  </span>
                </div>
                <span className="xl:text-[22px] md:text-lg lg:text-base font-medium">
                  5 min
                </span>
              </div>
              <div className="flex gap-4">
                <div className="flex items-start gap-2 w-[180px]">
                  <img
                    src="/assets/icons/vendor-shop-front/shipment.svg"
                    alt=""
                  />

                  <span className="xl:text-[22px] md:text-lg lg:text-base font-semibold">
                    Shipment Time:
                  </span>
                </div>
                <span className="xl:text-[22px] md:text-lg lg:text-base font-medium">
                  3 days avg
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorDetails;
