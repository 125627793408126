import UserDashboardSellingAdmarketplacePage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/UserDashboardSellingAdmarketplacePage";
import UserDashboardSellingPageAddProduct from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/UserDashboardSellingPageAddProduct";
import UserDashboardSellingPageEditAdmarketplace from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/UserDashboardSellingPageEditAdmarketplace";

const userAdMarketPLaceRoutes = [
  {
    path: "/user-dashboard/ad-market-place",

    children: [
      {
        index: true,
        element: <UserDashboardSellingAdmarketplacePage />,
      },
      {
        path: "/user-dashboard/ad-market-place/add-product",
        element: <UserDashboardSellingPageAddProduct />,
      },
      {
        path: "/user-dashboard/ad-market-place/edit-product/:id",
        element: <UserDashboardSellingPageEditAdmarketplace />,
      },
    ],
  },
];

export default userAdMarketPLaceRoutes;
