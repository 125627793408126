import React from 'react'
import AuctionSingleProduct from '../../../../components/Ad-MarketPlace-Components/Auction-Components/Auction-Single-Product-Page-Components/AuctionSingleProduct'

export default function AuctionSingleProductPage() {
    return (
        <>
            <AuctionSingleProduct />
        </>
    )
}
