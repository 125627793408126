import React from "react";
import VideoCard from "../Common/VideoCard";

const AffiliateInReels = ({data}) => {

  return (
    <div className="flex w-full justify-center">
      <div className="px-4 w-full max-w-screen-2xl xl:px-[57px] py-10">
        <h1 className="text-3xl font-bold">InReels</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
          {data?.inreel?.slice(0, 4)?.map((item) => (
            <>
              <VideoCard item={item} />
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AffiliateInReels;
