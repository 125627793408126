import React, { useEffect } from "react";
import ShelfSection from "../../../components/Main-Category-Components/Shelf-Section/ShelfSection";
import BoostShopSection from "../../../components/Main-Category-Components/Boost-Shop-Section/BoostShopSection";
import SmartFilterBar from "../../../components/SmartFilter/SmartFilterBar";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getCategoryPremiumProducts, getCategoryProducts } from "../../../store/frontendSlice";
import { toast } from "react-toastify";

export default function MainCategoryPage() {
  const {slug}=useParams();
  const { data, products } = useSelector(state => state.frontend);
  const dispatch = useDispatch();
  const { message, error, loading } = useSelector(state => state.frontend);

  // useEffect(() => {
  //   if(message){
  //     toast.success(message);
  //   }
  //   if(error){
  //     toast.error(error);
  //   }
  //   dispatch(clearMessage());
  // }, [message, error, loading]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(getCategoryPremiumProducts(slug,{ signal }))
    dispatch(getCategoryProducts(slug,{ signal }))
    // console.log(products)
    return () => {
      controller.abort();
    };
  }, [slug]);

  return (
    <>
      <ShelfSection data={data}/>
      <BoostShopSection stores={data.stores}/>
      <SmartFilterBar getFunction={getCategoryProducts} products={products.data}/>
    </>
  );
}
