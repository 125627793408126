import React, { useEffect, useRef, useState } from "react";
import { FaHeart, FaPause, FaPlay } from "react-icons/fa";
import ReviewsProductCard from "./ReviewsProductCard";
import ReviewStoreDetails from "./ReviewStoreDetails";
import ReviewStoreShortDetails from "./ReviewStoreShortDetails";
import StoreInfo from "./StoreInfo";
import { Link, useNavigate } from "react-router-dom";

export default function ReviewVideoCard({ onBackButtonClick, reelIndex, reels }) {
  const [reviewsProduct, setReviewsProduct] = useState([]);
  const [isHeartFilled, setIsHeartFilled] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const handleHeartClick = () => {
    setIsHeartFilled(!isHeartFilled);
  };

  const handleVideoCrossButton = () => {
    onBackButtonClick();
  };

  const handlePlayClick = () => {
    if (videoRef.current) {
      if (isVideoPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsVideoPlaying(!isVideoPlaying);
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row w-full h-full items-center gap-6 relative">
        <button
          className="btn btn-sm btn-circle btn-ghost block absolute -right-2 -top-12 sm:-top-5 lg:right-2  lg:-top-1 text-xl"
          onClick={handleVideoCrossButton}
        >
          ✕
        </button>

        {/* reel */}

        <div className={`relative z-20 overflow-hidden rounded-[10.63px] w-4/5 sm:w-2/5 h-full`}>
          <div className="absolute top-4 right-4 z-20 bg-[#58595933] rounded-full cursor-pointer w-11 h-11 lg:w-10 lg:h-10 md:w-7 md:h-7 flex items-center justify-center">
              <button onClick={handlePlayClick}>
                  {isVideoPlaying ? (
                  <FaPause className="w-4 h-4 lg:h-5 xl:w-5  text-white" />
                  ) : (
                  <FaPlay className="w-4 h-4 lg:h-5 xl:w-5 text-white" />
                  )}
              </button>
          </div>

          <div className="relative w-full max-h-[535px] sm:max-h-[435px] lg:max-h-[535px] h-fit">
            <video
                src={reels[reelIndex]?.reel?.video}
                ref={videoRef}
                className="w-full h-full z-20 object-cover"
            ></video>
            <img
                src={reels[reelIndex]?.reel?.thumbnail!=null?reels[reelIndex]?.reel?.thumbnail:"https://images.pexels.com/photos/14438483/pexels-photo-14438483.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}
                alt="Thumbnail"
                className={`${isVideoPlaying?"hidden":"absolute"} inset-0 w-full h-full z-10 object-cover cursor-pointer`}
                onClick={handlePlayClick}
            />
          </div>

          <div className="w-full z-20 absolute items-center justify-between bottom-4 px-3">
              <div className="flex  items-center gap-2 ">
                  <div className="  ">
                  <img
                      src={reels[reelIndex]?.reel?.creater_image?reels[reelIndex]?.reel?.creater_image:"https://as2.ftcdn.net/v2/jpg/02/44/42/79/1000_F_244427911_aoHHulebtYy4wLpncBBuWqCTNFKolcCB.jpg"}
                      alt=""
                      className="rounded-full w-[52px] h-[52px] md:w-8 md:h-8 xl:w-11 xl:h-11 bg-cover"
                  />
                  </div>
                  <div onClick={() => navigate(`/vendor-shop-front/${reels[reelIndex]?.reel?.vendor.slug}`)} className="font-bold text-base md:text-[10px] xl:text-sm hover:underline cursor-pointer text-white  ">
                  {reels[reelIndex]?.reel?.creater_name?reels[reelIndex]?.reel?.creater_name:"Creator name"}
                  </div>
              </div>

              <div className="flex-col justify-center items-center hidden">
                  <div
                  className={`w-[30px] h-[30px] lg:w-[18px] lg:h-[18px] xl:w-[25px] xl:h-[25px]  px-1 py-1 bg-[#58595933] rounded-full flex justify-center items-center  ${
                      isHeartFilled
                      ? "text-blue-500 duration-500 ease-in-out"
                      : "text-white"
                  }`}
                  onClick={handleHeartClick}
                  >
                  <FaHeart className="h-4 w-4 x:h-5 xl:w-5  cursor-pointer" />
                  </div>
                  <div className="xl:text-xs md:text-[9px] text-[13px] text-white ">
                      likes
                  </div>
              </div>
          </div>
        </div>


        {/* if there are one product in review */}
        {reels[reelIndex]?.products?.length === 1 && (
          <div className="flex flex-col md:flex-row items-center gap-8">
            <div className="flex gap-4">
              {reels[reelIndex]?.products?.slice(0,3).map((item) => {
                return <ReviewsProductCard key={item.id} item={item} />;
              })}
            </div>

            <div className="hidden md:flex lg:hidden">
              <ReviewStoreShortDetails store={reels[reelIndex]?.reel?.vendor}/>
            </div>

            <div className="flex md:hidden lg:flex">
              <ReviewStoreDetails item={reels[reelIndex]?.products} store={reels[reelIndex]?.reel?.vendor}/>
            </div>
          </div>
        )}

        {reels[reelIndex]?.products?.length === 2 && (
          <div className="flex flex-col lg:flex-row items-center gap-8">
            {/* for tab  */}
            <div className="hidden md:flex justify-start w-full lg:hidden">
              <StoreInfo store={reels[reelIndex]?.reel?.vendor} />
            </div>

            <div className="flex gap-8">
              {reviewsProduct.map((item) => {
                return <ReviewsProductCard key={item._id} item={item} />;
              })}
            </div>

            {/* for mobile  */}
            <div className="md:hidden">
              <ReviewStoreDetails item={reels[reelIndex]?.products} store={reels[reelIndex]?.reel?.vendor}/>
            </div>

            {/* for tab and laptop  */}
            <div className="hidden lg:flex">
              <ReviewStoreShortDetails store={reels[reelIndex]?.reel?.vendor} />
            </div>
          </div>
        )}

        {reels[reelIndex]?.products?.length === 3 && (
          <div className="flex flex-col lg:items-start h-full w-full justify-center gap-2">
            <div className="hidden md:flex">
              <StoreInfo store={reels[reelIndex]?.reel?.vendor} />
            </div>

            <div className="grid w-full grid-cols-2 md:grid-cols-3 gap-4 xl:gap-6">
              {reels[reelIndex]?.products?.map((item) => {
                return <ReviewsProductCard key={item._id} item={item} />;
              })}

              {/* for mobile  */}
              <div className="flex md:hidden">
                <ReviewStoreShortDetails store={reels[reelIndex]?.reel?.vendor} />
              </div>
            </div>
          </div>
        )}

      </div>
    </>
  );
}
