import { React,  useEffect } from "react";

import ProductCard from "../Common/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { recommendedProducts } from "../../store/frontendSlice";

export default function SearchHistoryProducts() {
  const dispatch = useDispatch();
  const { recommendedProduct, message, error, loading, token,sessionToken } = useSelector(state => state.frontend);


  useEffect(() => {
    if (!token) {
      dispatch(recommendedProducts(sessionToken));
    } else {
      dispatch(recommendedProducts())
    }
  }, []);
  return (
    <>
      <div className={` px-4  lg:px-[57px]`}>
        <div className="max-w-screen-2xl container mx-auto mt-16 mb-6">
          <div className="font-semibold text-[21px] md:text-[22px] lg:text-[30px]">
            Recommended
          </div>
        </div>
        <div className="max-w-screen-2xl container mx-auto">
          {/* Top selling card */}
          <div
            className={`grid  xl:grid-cols-5  md:grid-cols-4 grid-cols-2 gap-4`}
          >
            {recommendedProduct.map((item) => (
              <ProductCard key={item._id} item={item} getFunction={recommendedProducts}/>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
