import React, { useEffect, useRef, useState } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { BiChevronLeft } from "react-icons/bi";
import { BiChevronRight } from "react-icons/bi";

import { BiSolidMessageRoundedDetail } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./single-prod-page.css";

import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  getCartProducts,
  postProductToCart,
} from "../../store/frontendSlice";
import { toast } from "react-toastify";
import LoginModal from "../Common/LoginModal";
import { stringConcat } from "../../functions/formatNumber";

const SingleProductImgSlider = ({ products, affiliate_code }) => {
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState("standard");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const { message, error } = useSelector((state) => state.frontend);

  const [isHeartFilled, setHeartFilled] = useState(false);

  // const [startIndex, setStartIndex] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliderimg, setSliderimg] = useState([]);
  const [colors, setColors] = useState("");
  const [counter, setCounter] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedWeight, setSelectedWeight] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [weightDropdownOpen, setWeightDropdownOpen] = useState(false);
  const [sizeDropdownOpen, setSizeDropdownOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [color_id, setColor_id] = useState(null);
  const [size_id, setSize_id] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const sliderRef = useRef(null);

  const size = [28, 30, 32, 34, 36, 38, 40];
  const weights = [34, 45, 65, 54];

  // slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentIndex(index),
    nextArrow: <MdOutlineNavigateNext />,
    prevArrow: <MdOutlineNavigateBefore />,
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //minimize
  useEffect(() => {
    const handleScroll = () => {
      const screenHeight = window.innerHeight;
      const scrollThreshold = screenHeight * 0.4; // 40% of the screen's height

      const isScrolled = window.scrollY > scrollThreshold;
      setIsMinimized(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //heart toggle
  const toggleHeart = () => {
    setHeartFilled(!isHeartFilled);
  };
  // counter function
  const handleminus = () => {
    if (counter > 1) setCounter(counter - 1);
  };
  const handleplus = () => {
    setCounter(counter + 1);
  };

  // color slider
  const handlecolors = () => {
    setColors(!colors);
  };

  //modal
  const openModal = (index) => {
    if (!isSmallScreen) {
      setModalOpen(true);
    }
  };

  // Handle weight dropdown
  const toggleWeightDropdown = () => {
    setWeightDropdownOpen(!weightDropdownOpen);
    setSizeDropdownOpen(false); // Close the size dropdown if open
  };

  const handleWeightSelect = (weight) => {
    setSelectedWeight(weight);
    setWeightDropdownOpen(false);
  };

  // Handle size dropdown
  const toggleSizeDropdown = () => {
    setSizeDropdownOpen(!sizeDropdownOpen);
    setWeightDropdownOpen(false); // Close the weight dropdown if open
  };

  const handleSizeSelect = (size) => {
    setSelectedSize(size.attribute);
    setSizeDropdownOpen(false);
  };
  useEffect(() => {
    if (products?.allImages?.length > 0) {
      setSliderimg(products?.allImages);
    }
  }, [products]);
  // console.log(sliderimg);

  const handleProductToCart = (e) => {
    // console.log("cc",e)
    if (affiliate_code) {
      dispatch(
        postProductToCart({
          product_id: e.id,
          quantity: counter,
          attribute: [],
          color_id,
          size_id,
          affiliate_code,
        })
      );
    } else {
      dispatch(
        postProductToCart({
          product_id: e.id,
          quantity: counter,
          attribute: [],
          color_id,
          size_id,
        })
      );
    }
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(getCartProducts());
    }

    if (error) {
      toast.error(error);
      if (error == "Unauthenticated.")
        document.getElementById("login_modal_form").showModal();
    }

    dispatch(clearMessage());
  }, [message, error]);

  const goToSlide = (index) => {
    sliderRef?.current?.slickGoTo(index);
    setCurrentIndex(index);
  };

  return (
    <>
      {/* Minimized card displayed on scroll down */}
      <div className="hidden md:flex">
        {isMinimized && (
          <div className="fixed flex items-center justify-center z-50 top-0 lg:top-32  right-2 w-[593px]  mx-[57px] h-[89px] bg-white rounded-lg minimzed-content  shadow-lg">
            <div className="flex p-1 gap-2 items-center">
              <div>
                <h1 className="text-[24px] font-[500] text-black text-nowrap overflow-hidden">
                  {stringConcat(products?.name,20)}
                </h1>
                <p className="float-right text-lg text-[#C19A6B]">
                  ${products?.price}
                </p>
              </div>
              <div>
                {" "}
                {sliderimg.length > 0
                  ? sliderimg.map((item, index) => {
                      // console.log(item);
                      return (
                        <img
                          src={item}
                          alt=""
                          key={index}
                          className={
                            index === currentIndex
                              ? "block h-[49px] w-14"
                              : "hidden"
                          }
                          // onClick={() => openModal(index)}
                        />
                      );
                    })
                  : "No Image Found"}
              </div>
              <div>
                <button
                  onClick={(e) => handleProductToCart(products)}
                  disabled={products?.quantity <= 0}
                  className="w-32 h-12 bg-[#C19A6B] rounded-md p-2 text-center text-white"
                >
                  {products?.quantity <= 0 ? "Out of Stock" : "Add to Cart"}
                </button>
              </div>
              {/* <div>
                <div className={` bg-gray-400 bg-opacity-10 rounded-full p-1`}>
                  <svg
                    className="h-10 w-10 cursor-pointer text-[#1983FF]"
                    viewBox="0 0 24 24"
                    fill={isHeartFilled ? "#1983FF" : "none"}
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    onClick={toggleHeart}
                  >
                    <path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                  </svg>
                </div>
              </div> */}
            </div>
          </div>
        )}
      </div>

      <div className={isMinimized ? "header-section" : ""}>
        <div className="scroll-smooth flex flex-col items-center lg:flex-row gap-2 mt-2 px-4 md:px-6 lg:px-[57px] justify-between ">
          {/* left side */}
          <div className="product-image h-fit w-full lg:max-w-[452px] xl:max-w-[552px] ">
            {/* breadcrumbs  */}
            <div className="text-sm hidden md:breadcrumbs ">
              <ul>
                <li className="opacity-70">Home</li>
                <li className="opacity-70">Category Name</li>
                <li className="opacity-70">Sub-Category Name</li>
                <li className="opacity-70">Product Name</li>
              </ul>
            </div>

            {/* back button  */}
            <button className="hidden absolute top-1/5 left-14 w-20 shadow-md h-10 rounded-md  font-semibold md:flex justify-between gap-1 items-center px-3">
              <BiLeftArrowAlt className="text-9xl" />{" "}
              <span className="">Back</span>
            </button>

            {/* image slider  */}

            <div className="slider-container relative mt-2">
              <Slider ref={sliderRef} {...settings}>
                {sliderimg.map((item, index) => (
                  <div key={index}>
                    <img
                      src={item}
                      alt=""
                      onClick={openModal}
                      className="w-full max-h-[435px] rounded-2xl"
                    />
                  </div>
                ))}
              </Slider>
            </div>

            {/* below images  */}
            <div className="flex flex-wrap w-full gap-4 mt-5 ">
              {sliderimg.map((item, index) => (
                <img
                  src={item}
                  alt=""
                  key={index}
                  className={` max-w-[66px] max-h-[60px] md:max-w-[161px] md:max-h-[147px] lg:max-w-[104px] lg:max-h-[95px] ${
                    index === currentIndex ? "" : "opacity-50"
                  }`}
                  onClick={() => goToSlide(index)}
                />
              ))}
            </div>
          </div>

          {/* right side */}

          <div className="w-full ">
            <div className="flex items-center gap-1">
              <img
                src="/assets/icons/Vector1.png"
                className="max-h-4 max-w-4"
                alt=""
              />
              <span className="opacity-65 text-base lg:text-lg  ">
                {" "}
                {products?.solds} Sold
              </span>
            </div>
            <p className="w-1/2 font-semibold text-2xl  ">{products?.name}</p>
            <div className="flex flex-col md:flex-row justify-between mt-5 gap-5">
              <div className="w-full flex flex-col gap-2">
                <p className="left font-light text-[#505050] text-xl lg:text-base ">
                  {products?.short_description}
                </p>
                <div className="flex flex-wrap gap-1 md:gap-2 ">
                  {products?.colors?.length > 0 &&
                    products?.colors?.map((color, index) => {
                      const style = {
                        backgroundColor: color.HexCode,
                        border:
                          color_id === color.id ? "1px solid #000" : "none",
                      };

                      return (
                        <div
                          key={index}
                          className="p-3  rounded-full"
                          style={style}
                          onClick={() => setColor_id(color.id)}
                        ></div>
                      );
                    })}
                </div>
                <div className="flex flex-wrap gap-1 md:gap-2  " ref={size}>
                  {products?.sizes?.length > 0 &&
                    products?.sizes.map((size, index) => {
                      // const style = {
                      //   border: size_id === size.id ? "2px solid #000" : "none",
                      // };
                      return (
                        <div
                          key={index}
                          className={`px-3 py-[1px] text-sm border rounded-2xl cursor-pointer ${size_id === size.id ? 'border-[#000] text-[#000]' : 'border-[#474141] text-[#474141] opacity-50'}`}
                          // style={style}
                          onClick={() => setSize_id(size.id)}
                        >
                          {size.SizeName}
                        </div>
                      );
                    })}
                </div>
                {/* select inputs */}
                {/* <div className="w-full flex flex-col gap-2 "> */}
                {/* <div className="w-full relative">
                    <div
                      onClick={toggleWeightDropdown}
                      className="relative w-full flex items-center"
                    >
                      <input
                        className=" text-sm font-normal  rounded-[5px] w-full px-2  py-2 border border-black text-black outline-none"
                        type="text"
                        name="weight"
                        readOnly
                        placeholder="Select weight"
                        value={selectedWeight}
                      />
                      {weightDropdownOpen ? (
                        <RiArrowDropUpLine className="absolute right-2 w-7 h-7" />
                      ) : (
                        <RiArrowDropDownLine className="absolute right-2 w-7 h-7" />
                      )}
                    </div> */}

                {/* weight dropdown  */}
                {/* {weightDropdownOpen && (
                      <div className="flex flex-col w-full  rounded-lg bg-[#FAFAFA] py-3 px-3  z-10 absolute">
                        {weights.map((weight) => (
                          <span
                            key={weight}
                            className="p-1 px-2 text-xs cursor-pointer hover:bg-[#f3f0f0]"
                            onClick={() => handleWeightSelect(weight)}
                          >
                            {weight}
                          </span>
                        ))}
                      </div>
                    )}
                  </div> */}

                {/* <div className="w-full relative">
                    <div
                      onClick={toggleSizeDropdown}
                      className="relative w-full flex items-center"
                    >
                      <input
                        className=" text-sm font-normal  rounded-[5px] w-full px-2  py-2 border border-black text-black outline-none"
                        type="text"
                        name="size"
                        readOnly
                        placeholder="Select Size"
                        value={selectedSize}
                      />
                      {sizeDropdownOpen ? (
                        <RiArrowDropUpLine className="absolute right-2 w-7 h-7" />
                      ) : (
                        <RiArrowDropDownLine className="absolute right-2 w-7 h-7" />
                      )}
                    </div> */}

                {/* size dropdown  */}
                {/* {sizeDropdownOpen && (
                      <div className="flex flex-col w-full  rounded-lg bg-[#FAFAFA] py-3 px-3  z-10 absolute">
                        {products?.attributes?.map((size) => (
                          <span
                            key={size}
                            className="p-1 px-2 text-xs cursor-pointer hover:bg-[#f3f0f0]"
                            onClick={() => handleSizeSelect(size)}
                          >
                            {size.attribute}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </div> */}

                {/* colors div */}
                {/* <p className="mb-0 mt-3 text-[23px] lg:text-lg">Select Color</p>
                <div className="flex items-center">
                  <div
                    onClick={handlecolors}
                    className="max-w-10 max-h-10 flex justify-between cursor-pointer gap-5 border rounded-full"
                  >
                    <img src="/assets/icons/Component 22.png" alt="sd" />
                  </div> */}

                {/* Apply the animation class when colors is true */}
                {/* {colors && (
                    <>
                      <div className="grid grid-cols-7 lg:grid-cols-4 xl:grid-cols-7 gap-2">
                        <div className="w-7 h-7 lg:w-4 lg:h-4 xl:w-7 xl:h-7 border ms-3 border-black rounded-full bg-cyan-600 color-item"></div>
                        <div className="w-7 h-7 lg:w-4 lg:h-4 xl:w-7 xl:h-7 border ms-3 border-black rounded-full bg-yellow-400 color-item"></div>
                        <div className="w-7 h-7 lg:w-4 lg:h-4 xl:w-7 xl:h-7 border ms-3 border-black rounded-full bg-gray-600 color-item"></div>
                        <div className="w-7 h-7 lg:w-4 lg:h-4 xl:w-7 xl:h-7 border ms-3 border-black rounded-full bg-green-900 color-item"></div>
                        <div className="w-7 h-7 lg:w-4 lg:h-4 xl:w-7 xl:h-7 border ms-3 border-black rounded-full bg-green-200 color-item"></div>
                        <div className="w-7 h-7 lg:w-4 lg:h-4 xl:w-7 xl:h-7 border ms-3 border-black rounded-full bg-white color-item"></div>
                        <div className="w-7 h-7 lg:w-4 lg:h-4 xl:w-7 xl:h-7 border ms-3 border-black rounded-full bg-green-600 color-item"></div>
                      </div>
                    </>
                  )}
                </div> */}
              </div>

              {/* Standard card price --------starts */}
              <div className="w-full h-fit flex flex-col gap-3   shadow-lg rounded-lg p-8">
                <div className="flex justify-between gap-3 ">
                  <div className="text-nowrap">
                    <h2 className="font-bold text-[19px] md:text-lg lg:text-[23px] text-nowrap">
                      Standard Price
                    </h2>
                    {products?.price != products?.discountprice ? (
                      <>
                        <span className="text-[32px] md:text-[38px] lg:text-3xl text-[#C19A6B]">
                          ${products?.discountprice}
                        </span>{" "}
                        <span className="ml-2 text-gray-400 text-[19px] md:text-[23px] lg:text-lg line-through">
                          ${products?.price}
                        </span>
                      </>
                    ) : (
                      <span className="text-[32px] md:text-[38px] lg:text-3xl text-[#C19A6B]">
                        ${products?.price}
                      </span>
                    )}
                  </div>
                  {/* <div className="">
                    <div
                      className={` bg-gray-400 bg-opacity-10 rounded-full p-1`}
                    >
                      <svg
                        className="max-h-14 max-w-14 cursor-pointer text-[#1983FF]"
                        viewBox="0 0 24 24"
                        fill={isHeartFilled ? "#1983FF" : "none"}
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        onClick={toggleHeart}
                      >
                        <path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                      </svg>
                    </div>

                    <p className=" text-center text-sm md:text-lg lg:text-[15px]">
                      {products?.wishlist}
                    </p>
                  </div> */}
                </div>
                <div className="border border-gray-400 rounded-full flex justify-evenly text-2xl ">
                  <p
                    className="cursor-pointer w-5  text-center text-2xl"
                    onClick={handleminus}
                  >
                    -
                  </p>
                  <p className="">{counter}</p>
                  <p
                    className="cursor-pointer  w-5 text-center text-2xl"
                    onClick={handleplus}
                  >
                    +
                  </p>
                </div>
                <div className=" flex items-center gap-2">
                  {products?.quantity > 0 ? (
                    <>
                      <div className="w-3 h-3 rounded-full bg-green-400"></div>
                      <span className="text-sm opacity-50">In Stock</span>
                    </>
                  ) : (
                    <>
                      <div className="w-3 h-3 rounded-full bg-red-700"></div>
                      <span className="text-sm opacity-55 text-red-700">
                        Out of Stock
                      </span>
                    </>
                  )}
                </div>

                <div
                  className={`relative border text-sm px-3 py-1 shadow-sm cursor-pointer ${
                    selectedOption === "standard"
                      ? "border-yellow-500"
                      : "border-gray-500"
                  }`}
                  onClick={() => handleOptionClick("standard")}
                >
                  {selectedOption === "standard" && (
                    <FaCheck className="absolute top-0 right-0 w-6 h-5 text-sm text-white rounded-bl-sm bg-[#C19A6B]" />
                  )}
                  <p className="font-semibold text-[15px] md:text-lg lg:text-sm">
                    Standard Delivery | ${products?.standard_shipping_amount}
                  </p>
                  <p className="opacity-60 text-sm md:text-base lg:text-sm">
                    Received by 2 Jan - 8 Jan
                  </p>
                </div>

                <div
                  className={`relative border text-sm px-3 py-1 shadow-sm cursor-pointer ${
                    selectedOption === "express"
                      ? "border-yellow-500"
                      : "border-gray-500"
                  }`}
                  onClick={() => handleOptionClick("express")}
                >
                  {selectedOption === "express" && (
                    <FaCheck className="absolute top-0 right-0 w-6 h-5 text-sm text-white rounded-bl-sm bg-[#C19A6B]" />
                  )}
                  <p className="font-semibold text-[15px] md:text-lg lg:text-sm">
                    Express Delivery | ${products?.express_shipping_amount}
                  </p>
                  <p className="opacity-60 text-sm md:text-base lg:text-sm">
                    Received within 2 days
                  </p>
                </div>
                <button
                  onClick={(e) => handleProductToCart(products)}
                  disabled={products?.quantity <= 0}
                  className="w-full  mt-3 bg-[#C19A6B] rounded-md py-[14px] md:py-4 lg:py-[13px] text-center text-[19px] md:text-[23px] lg:text-lg  text-white"
                >
                  {products?.quantity <= 0 ? "Out of Stock" : "Add to Cart"}
                </button>
              </div>
              {/* card ends */}
            </div>
          </div>
        </div>

        {/* chat button  */}
      </div>

      {/* Modal -----------------------------------------------------------Start */}
      {modalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white w-full h-full p-8 rounded-lg">
            <button
              className="float-right w-14 h-14 text-4xl hover:shadow-lg hover:bg-white  rounded-full text-black"
              onClick={() => setModalOpen(false)}
            >
              <RxCross2 className="w-14 p-1 h-14" />
            </button>
            <div className="slider-container w-[70%] relative mx-auto">
              <Slider ref={sliderRef} {...settings} initialSlide={currentIndex}>
                {sliderimg.map((item, index) => (
                  <div key={index}>
                    <img
                      src={item}
                      alt=""
                      className="mx-auto h-[80vh] rounded-3xl"
                    />
                  </div>
                ))}
              </Slider>
              {sliderRef.current && (
                <>
                  <button
                    onClick={handlePrev}
                    className={`absolute top-1/2 left-0 transform -translate-y-1/2 text-4xl text-black z-10  hover:shadow-md rounded-full p-2 ${
                      currentIndex === 0 ? "hidden" : ""
                    }`}
                  >
                    <BiChevronLeft className="text-[70px]" />
                  </button>
                  <button
                    onClick={handleNext}
                    className={`absolute top-1/2 right-0 transform -translate-y-1/2 text-4xl text-black z-10  hover:shadow-md rounded-full p-2 ${
                      currentIndex === 3 ? "hidden" : ""
                    }`}
                  >
                    <BiChevronRight className="text-[70px]" />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="group fixed bottom-0 right-0 z-50 mb-6 mr-6">
        <BiSolidMessageRoundedDetail className="text-5xl text-[#C19A6B] cursor-pointer" />
        <div className="hidden group-hover:block bg-gray-900 text-white px-4  rounded-md absolute right-12 bottom-4">
          ChatNow
        </div>
      </div>
      {/* modal ---------------------------------end */}

      <LoginModal />
    </>
  );
};

export default SingleProductImgSlider;
