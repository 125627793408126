import React, { useEffect } from "react";
import AffiliateUserProfileSection from "./AffiliateUserProfileSection";
import AffiliateBundles from "./AffiliateBundles";
import AffiliateInReels from "./AffiliateInReels";
import AffiliateProducts from "./AffiliateProducts";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearMessage, getUserProfile } from "../../store/frontendSlice";
import { toast } from "react-toastify";

const AffiliateUserProfile = () => {
  const {user}=useParams()
  const { data,message,error } = useSelector(state => state.frontend);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserProfile(!user?"tahir":user))
  }, [])

  useEffect(() => {
    if(message){
      toast.success(message)
      dispatch(getUserProfile(!user?"tahir":user))
    }
    else if (error){
      toast.error(error)
    }
    dispatch(clearMessage())
  }, [message, error])

  return (
    <>
      <AffiliateUserProfileSection data={data} />
      <AffiliateBundles data={data} />
      <AffiliateInReels data={data} />
      <AffiliateProducts getFunction={getUserProfile} data={data} />
    </>
  );
};

export default AffiliateUserProfile;
