import React from "react";

import TopSellingProducts from "../../../components/top-selling-product-section/TopSellingProducts";
import SearchHistoryProducts from "../../../components/search-history-product-section/SearchHistoryProducts";
import MiniImagesSlider from "../../../components/mini-images-slider-section/MiniImagesSlider";
import BoostedOffers from "../../../components/boosted-offer-section/BoostedOffers";
import HotspotImage from "../../../components/hotspot-image-section/HotspotImage";
import CustomersReviews from "../../../components/review-from-customers-section/CustomersReviews";
import BannerSlider from "../../../components/TheBannerSlider/BannerSlider";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../../store/frontendSlice";
import { useEffect } from "react";

export default function HomePage() {
  const { message, error, loading } = useSelector(state => state.frontend);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if(message){
  //     toast.success(message);
  //   }
  //   if(error){
  //     toast.error(error);
  //   }
  //   dispatch(clearMessage());
  // }, [message, error, loading]);

  return (
    <>
      <HotspotImage />
      <MiniImagesSlider />
      <BoostedOffers />
      <CustomersReviews />
      <TopSellingProducts />
      <BannerSlider />
      <SearchHistoryProducts />
    </>
  );
}
