import UserDashboardBuyingPage from "../../../pages/User-Dashbard/User-Dashbaord-Buying-Page/UserDashboardBuyingPage";

const buyingHomeRoutes = [
  {
    path: "/user-dashboard",
    element: <UserDashboardBuyingPage />,
  },
];

export default buyingHomeRoutes;
