import React from "react";
import ProductCard from "../../../Common/ProductCard";
import MyShelfSubCategoryProductsCard from './MyShelfSubCategoryProductCard'

function SubCategoryProducts({ products, smartFilterOpen, getFunction, slug, view }) {

  return (
    <div className="w-full max-w-screen-2xl container mb-6">
    <div
      className={`w-full gap-8 grid ${
        view===3
          ? "grid-cols-3 place-item-center"
          : view===4 ? "grid-cols-4": view===5 ? "grid-cols-5" : "grid-cols-2 sm:grid-cols-3 md:grid-cols-3"
      } `}
    >
      {products?.data?.length>0? products.data.map((product) => (
        // <MyShelfSubCategoryProductsCard
        //   key={product._id}
        //   item={product}
        //   smartFilterOpen={smartFilterOpen}
        // />
        <ProductCard
          getFunction={getFunction}
          slug={slug}
          key={product._id}
          item={product}
          smartFilterOpen={smartFilterOpen}
        />
      )):"No Product Found"}
    </div>
  </div>
  );
}

export default SubCategoryProducts;
