import React from "react";
import UserDashboardSellingSidebar from "../../components/User-Dashboard-Components/User-Dashboard-Sidebar/UserDashboardSellingSidebar";

export default function UserDashboardSellingLayout({ children }) {
  return (
    <div className="flex gap-2">
      <div className="hidden xl:block ">
        <UserDashboardSellingSidebar />
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
}
