import React, { useState, useEffect } from 'react';
// import ModuleExtra from './ModuleExtra';
import { motion } from 'framer-motion';

const categories = [
  { id: 1, name: 'Category 1', icon: 'https://via.placeholder.com/100' },
  { id: 2, name: 'Category 2', icon: 'https://via.placeholder.com/100' },
  { id: 3, name: 'Category 3', icon: 'https://via.placeholder.com/100' },
  // Add more categories as needed
];

function Extra() {  
  const circlePath = {
    initial: { rotate: 0 },
    animate: { rotate: 360 },
  };

  const [currentCategory, setCurrentCategory] = useState(0);

  const handleNextCategory = () => {
    setCurrentCategory((prevCategory) => (prevCategory + 1) % categories.length);
  };
    return (
      // <div className="relative flex-col w-full p-5 h-screen bg-white">
      //   <div className='w-fit h-80 rounded-lg absolute top-10 px-0 bg-yellow-500'><span className='w-40'></span></div>
      //   <div className='w-fit h-80 rounded-lg absolute top-5 px-5 bg-blue-500'><span className='w-40'></span></div>
      //   <div className='w-fit h-80 rounded-lg absolute top-0 px-10 bg-red-500'><span className='w-40'></span></div>
      // </div>
      <div className="flex flex-col w-full p-5 h-screen bg-gray-500 items-center justify-center">

        {/* <div className="circle"></div> */}

        {/* <div className="circle-container">
          <div className="wave-container">
            <div className="wave"></div>
          </div>
        </div> */}

<div className="circle-container">
      <div className="circle-border"></div>
      <div className="wave-container">
        {/* <div className="wave"></div> */}
      </div>
    </div>
        {/* <motion.div
          style={{
            width: '50px',
            height: '50px',
            backgroundColor: 'blue',
            borderRadius: '50%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-25px',
            marginLeft: '-25px',
          }}
          variants={circlePath}
          initial="initial"
          animate="animate"
          transition={{
            loop: Infinity,
            ease: 'linear',
            duration: 5,
          }}
        /> */}

      </div>
    )
}

export default Extra
