import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const Description = ({ products }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className="px-4 xl:px-[57px] w-full mt-10">
        <div className="shadow-lg px-8 py-5 rounded-md">
          <h1 className="text-2xl font-bold">Description</h1>
          <div
            className={`text-sm py-3 text-[#505050] ${
              isExpanded ? "line-clamp-none" : "line-clamp-3"
            }`}
          >
            {products?.description}
          </div>
          <div className="text-center  mt-3">
            <div className="flex flex-col items-center opacity-70">
              <button onClick={toggleExpand} className="opacity-60  ">
                {isExpanded ? "Close" : "View more"}
              </button>
              <button onClick={toggleExpand}>
                {isExpanded ? <BsChevronUp /> : <BsChevronDown />}
              </button>
            </div>
          </div>
        </div>
      </div>
      ;
    </>
  );
};

export default Description;
