import UserDashboardOrderListPage from "../../../pages/User-Dashbard/User-Dashbaord-Buying-Page/User-Dashboard-Order-Page/UserDashboardOrderListPage";
import UserDashboardOrderViewPage from "../../../pages/User-Dashbard/User-Dashbaord-Buying-Page/User-Dashboard-Order-Page/UserDashboardOrderViewPage";
import UserDashboardOrdersPage from "../../../pages/User-Dashbard/User-Dashbaord-Buying-Page/User-Dashboard-Order-Page/UserDashboardOrdersPage";
import UserDashboardTrackOrderPage from "../../../pages/User-Dashbard/User-Dashbaord-Buying-Page/User-Dashboard-Order-Page/UserDashboardTrackOrderPage";

const ordersRoutes = [
  {
    path: "/user-dashboard/orders",
    children: [
      {
        index: true,
        element: <UserDashboardOrderListPage />,
      },
      {
        path: "/user-dashboard/orders/order-by-id/:id",
        element: <UserDashboardOrdersPage />,
      },
      {
        path: "/user-dashboard/orders/order-details",
        element: <UserDashboardOrderViewPage />,
      },
      {
        path: "/user-dashboard/orders/track-order",
        element: <UserDashboardTrackOrderPage />,
      },
    ],
  },
];

export default ordersRoutes;
