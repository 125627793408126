import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ReferenceLine,
  Tooltip,
  Cell,
} from "recharts";
import { formatNumberWithCommas } from "../../functions/formatNumber";

const UserBarChart = ({ GraphData = {}, MainTitle , DollarSymbol }) => {
  const [selectedWeek, setSelectedWeek] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hoveredBar, setHoveredBar] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const [totalUv, setTotalUv] = useState(0);

  const data = GraphData[selectedWeek];

  useEffect(() => {
    if (Object.keys(GraphData).length > 0) {
      setSelectedWeek(Object.keys(GraphData)[0]);
    }
  }, [GraphData]);

  useEffect(() => {
    if (data) {
      const total = data?.reduce((acc, curr) => acc + curr.uv, 0);
      setTotalUv(total);
    }
  }, [selectedWeek, data]);

  const uvValues = data?.map((entry) => entry.uv);
  const minValue = uvValues?.length ? Math.min(...uvValues) : 0;
  const maxValue = uvValues?.length ? Math.max(...uvValues) : 0;
  const tickCount = 4;

  const tickInterval =
    maxValue > minValue ? (maxValue - minValue) / (tickCount - 1) : 1;
  const yAxisTicks = Array.from(
    { length: tickCount },
    (_, i) => minValue + tickInterval * i
  );

  const axisLabelStyle = {
    fontSize: "12px",
    fontFamily: "Cascadia, sans-serif",
    fill: "#6B6B6B",
  };

  const handleWeekSelect = (week) => {
    setSelectedWeek(week);
    setIsDropdownOpen(false);
  };

  const CustomTooltip = ({ active, payload, label, coordinate }) => {
    if (active && payload && payload.length) {
      const { name, uv } = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            textAlign: "center",
            padding: "3px 15px",
            backgroundColor: "#15CAB8",
            border: "1px solid #ccc",
            borderRadius: "10px",
            color: "white",
            fontSize: "16px",
            position: "absolute",
            top: `${coordinate.y - 40}px`, // Adjust this value for desired distance above bar
            left: `${coordinate.x - 20}px`, // Center tooltip above bar
            transform: "translateX(-50%)",
            pointerEvents: "none",
          }}
        >
          {/* <p>{name}</p> */}
          <p>{DollarSymbol}{uv}</p>
        </div>
      );
    }
    return null;
  };

  const formatYAxisTick = (value) => {
    if (value >= 1000) {
      return `${Math.round(value / 1000)}K`;
    }
    return `${Math.round(value)}`;
  };

  return (
    <div
      className="w-full h-[18rem] md:h-[24.5rem] rounded-2xl pt-2"
      style={{ boxShadow: "0px 5px 30px 0px #AAAAAA0F", position: "relative" }}
    >
      <div className="md:mt-7 ms-6 md:ms-9 flex justify-between me-5 md:me-7">
        <div className="font-semibold tracking-wide text-base md:text-xl">
          {MainTitle}
        </div>
        <div
          className="py-[7px] px-4 bg-[#FAFAFA] rounded-xl text-sm text-[#6B6B6B] border border-[#EBF0ED] flex gap-2 items-center cursor-pointer relative"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          {selectedWeek}
          <span>
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.375 5.375C5.19922 5.375 5.04297 5.31641 4.92578 5.19922L1.17578 1.44922C0.921875 1.21484 0.921875 0.804688 1.17578 0.570312C1.41016 0.316406 1.82031 0.316406 2.05469 0.570312L5.375 3.87109L8.67578 0.570312C8.91016 0.316406 9.32031 0.316406 9.55469 0.570312C9.80859 0.804688 9.80859 1.21484 9.55469 1.44922L5.80469 5.19922C5.6875 5.31641 5.53125 5.375 5.375 5.375Z"
                fill="#6B6B6B"
              />
            </svg>
          </span>
          {isDropdownOpen && (
            <div className="absolute top-full left-0 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
              {Object.keys(GraphData).map((week) => (
                <div
                  key={week}
                  className="p-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleWeekSelect(week)}
                >
                  {week}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="mt-2 ms-6 md:ms-9 flex justify-between me-5 md:me-7">
        <div className="font-bold tracking-wide text-base md:text-2xl">
          {DollarSymbol}{formatNumberWithCommas(totalUv)}
        </div>
        <div className="py-[7px] px-4 bg-[#FAFAFA] rounded-xl text-sm text-[#15CAB8] border border-[#15CAB8] flex gap-2 items-center">
          <span>
            <svg
              width="22"
              height="12"
              viewBox="0 0 22 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.375 1.46875C13.375 1.01172 13.7266 0.625 14.2188 0.625H20.4062C20.8633 0.625 21.25 1.01172 21.25 1.46875V7.65625C21.25 8.14844 20.8633 8.5 20.4062 8.5C19.9141 8.5 19.5625 8.14844 19.5625 7.65625V3.50781L12.8125 10.2227C12.4961 10.5742 11.9688 10.5742 11.6523 10.2227L7.75 6.32031L2.40625 11.6289C2.08984 11.9805 1.5625 11.9805 1.24609 11.6289C0.894531 11.3125 0.894531 10.7852 1.24609 10.4336L7.15234 4.52734C7.46875 4.21094 7.99609 4.21094 8.3125 4.52734L12.25 8.46484L18.3672 2.3125H14.2188C13.7266 2.3125 13.375 1.96094 13.375 1.46875Z"
                fill="#15CAB8"
              />
            </svg>
          </span>
          +12%
        </div>
      </div>

      {/* Bar chart */}
      <ResponsiveContainer
        className="rounded-3xl bg-white"
        width="100%"
        height="73%"
        style={{
          boxShadow: "0px 20px 20px 0px #AAAAAA0F",
          position: "relative",
        }}
      >
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 20,
            left: 10,
            bottom: 20,
          }}
          barSize={25} // Adjust the width of the bars
        >
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            dy={10}
            tick={{ ...axisLabelStyle }}
          />
          <YAxis
            tickFormatter={formatYAxisTick}
            axisLine={false} // Remove Y axis line
            tickLine={false} // Remove tick lines
            dx={-10} // Adjust the horizontal distance of the axis labels from the axis
            tick={{ ...axisLabelStyle }} // Apply custom style to Y axis labels
          />
          {yAxisTicks.map((tick, index) => (
            <ReferenceLine
              key={index}
              y={tick}
              stroke="rgba(0, 0, 0, 0.024)"
              strokeDasharray="9 8"
            />
          ))}
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Bar
            dataKey="uv"
            radius={[5, 5, 5, 5]}
            fill="#15CAB8"
            onMouseEnter={(data, index) => {
              setHoveredBar(index);
              setTooltipPosition(data.coordinate);
            }}
            onMouseLeave={() => setHoveredBar(null)}
            fillOpacity={hoveredBar !== null ? 1 : 1}
          >
            {data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={hoveredBar === index ? "#15CAB8" : "#44A6E9"}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default UserBarChart;
