import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

import "./single-prod-page.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
import ReviewCard from "./ReviewCard";

const ReviewsSingleProductPage = ({ products, singleProductReviews }) => {
  const [review, setReview] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [closing, setClosing] = useState(false);

  const sliderRef = useRef(null);

  const sidebarRef = useRef(null);

  const toggleSidebarOpen = () => {
    if (isSidebarOpen) {
      setClosing(true);
      setTimeout(() => {
        setIsSidebarOpen(false);
        setClosing(false);
      }, 1000);
    } else {
      setIsSidebarOpen(true);
    }
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setClosing(true);
      setTimeout(() => {
        setIsSidebarOpen(false);
        setClosing(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    if (isSidebarOpen) {
      setClosing(true);
      setTimeout(() => {
        setIsSidebarOpen(false);
        setClosing(false);
      }, 1000);
    }
  }, []);

  //for moodal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const generateStarRating = (rating) => {
    const stars = [];
    const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
    const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(
          <img key={i} src={filledStar} alt="filled star" className="w-4 h-4" />
        );
      } else {
        stars.push(
          <img
            key={i}
            src={unfilledStar}
            alt="unfilled star"
            className="w-4 h-4"
          />
        );
      }
    }
    return stars;
  };



  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // 2 slides for screens between 768px and 1023px
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1, // 1 slide for screens less than 768px
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <MdOutlineNavigateNext />,
    prevArrow: <MdOutlineNavigateBefore />,
  };

  return (
    <>
      <div className=" bg-[#FFF3E5] h-[396px] px-4 xl:px-[57px] w-full ">
        <div className="pt-10 ml-2">
          <h1 className="font-bold text-3xl">Reviews</h1>
          <div className="flex py-3 gap-3">
            <img src="/assets/icons/Frame 315.png" alt="" />
            <div>
              <p className="font-semibold text-[15px]">
                {products?.total_ratings} Rating
              </p>
              <p className="opacity-80 font-medium text-[13px]">
                Based on {products?.totalreview} reviews
              </p>
            </div>
          </div>
          <div className="gap-10 flex  items-center">
            <button
              onClick={toggleSidebarOpen}
              className="drawer-overlay text-sm md:text-base text-nowrap px-[25px] py-[5px] bg-[#C19A6B] rounded-md text-white"
            >
              Show All Reviews ( {products?.totalreview} )
            </button>
            {/* <p
              className="font-bold opacity-70 text-sm md:text-lg text-nowrap cursor-pointer"
              onClick={toggleModal}
            >
              Write a review
            </p> */}
          </div>
        </div>
        {/* review cards */}

        {/* cards */}
        <div className="relative review-slide">
          <Slider ref={sliderRef} {...settings} className="gap-2">
            {products?.reviews?.map((item, index) => (
              <ReviewCard item={item} index={index} />
            ))}
          </Slider>
        </div>
      </div>

      {/*All reviews side bar */}
      <div></div>
      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex px-4 md:px-[57px] items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white w-full  h-20% rounded-lg p-4 md:p-8 ">
            {/* Modal content */}
            <div className="flex justify-between items-center">
              <p className="md:text-2xl font-bold ">write a review</p>
              <button
                className="w-8 h-8 md:w-12 md:h-12 items-center text-5xl hover:shadow-lg hover:bg-white bg-gray-50 rounded-full"
                onClick={toggleModal}
              >
                <AiOutlineClose className="w-8 h-8 md:w-12 md:h-12 p-2" />
              </button>
            </div>
            <div className="flex flex-col md:flex-row md:py-5 justify-between">
              <div className="w-full md:w-[300px]">
                <input
                  className="w-full md:w-[309px] h-10 px-4 bg-[#F9FAFB] rounded-sm outline-none"
                  type="text"
                  placeholder="Uploaded images"
                />
                {/* absolute   add the class for the below tick icon in the input */}
                {/* <AiOutlineCheck className="relative float-right top-3" /> */}
                <img
                  src="./assets/icons/Group 7360.png"
                  alt=""
                  className="py-2 md:py-6"
                />
                <div className="flex gap-1">
                  <p className="opacity-70 text-sm md:text-xl">
                    Select rating :
                  </p>{" "}
                  <img
                    src="./assets/icons/Component 44.png"
                    className="h-3 md:h-6 w-30 md:w-40"
                    alt=""
                  />
                </div>
              </div>

              <input
                type="text"
                className=" h-[80px] md:h-[181px] px-4 w-full md:w-[850px] bg-[#F9FAFB] rounded-lg outline-none"
                placeholder="Lorem ipsum dolor sit amet consectetur. Vivamus dolor egestas feugiat feugiat ullamcorper Habita sim utjh dsdgsdg ssk csvdsjv svvhksdh vs vsjhvsd"
                name=""
                id=""
              />
            </div>
            <div className="float-right mt-2 md:mt-0">
              <button className="font-semibold py-2 md:py-[14px] px-9 md:px-[70px] rounded-lg border border-[#C19A6B] text-[#C19A6B]">
                Update
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Sidebar */}
      {isSidebarOpen && (
        <div
          ref={sidebarRef}
          className={`fixed z-50 top-0 right-0 h-full w-[70%] md:w-[40%] xl:w-[519px] bg-white overflow-y-auto ${
            closing ? "review-animate-sm-out" : "review-animate-sm"
          }`}
          style={{ scrollbarWidth: "none" }}
        >
          {/* sidebar content */}
          <div className="flex flex-col gap-2">
            <div className="flex  shadow-md gap-2 px-6 py-4 w-full bg-white  items-center pb-5">
              <button
                className="w-12 h-12 items-center text-5xl hover:shadow-lg hover:bg-white bg-gray-50 rounded-full"
                onClick={toggleSidebarOpen}
              >
                <AiOutlineClose className=" w-8 h-8 xl:w-12 xl:h-12 p-2" />
              </button>

              <h1 className=" font-bold text-xl md:text-2xl w-full text-center">
                Reviews
              </h1>
            </div>
            <div className=" w-full px-4 xl:px-4 py-5 ">
              <div className="flex px-4 py-4 gap-3">
                <img
                  src="/assets/icons/Frame 315.png"
                  alt=""
                  className="w-8 h-8"
                />
                <div>
                  <p className="font-semibold text-xs md:text-[15px]">
                    {singleProductReviews?.total_ratings} Rating
                  </p>
                  <p className="opacity-80 font-medium text-[10px] md:text-[13px]">
                    Based on {singleProductReviews?.total_reviews} reviews
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex items-center w-full xl:w-[424px] gap-2 px-4">
                  <p>5</p>
                  <div class="w-[356px] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div className={`bg-[#FFB800] h-2.5 rounded-full w-[${(singleProductReviews?.fiveStar/singleProductReviews?.total_reviews)*100==0?"5":(singleProductReviews?.fiveStar/100)*singleProductReviews?.total_reviews}%]`}></div>
                  </div>
                  <p>{singleProductReviews?.fiveStar}</p>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex items-center w-full xl:w-[424px] gap-2 px-4">
                  <p>4</p>
                  <div class="w-[356px] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div className={`bg-[#FFB800] h-2.5 rounded-full w-[${(singleProductReviews?.fourStar/singleProductReviews?.total_reviews)*100==0?"5":(singleProductReviews?.fiveStar/100)*singleProductReviews?.total_reviews}%]`}></div>
                  </div>
                  <p>{singleProductReviews?.fourStar}</p>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex items-center w-full xl:w-[424px] gap-2 px-4">
                  <p>3</p>
                  <div class="w-[356px] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div className={`bg-[#FFB800] h-2.5 rounded-full w-[${(singleProductReviews?.threeStar/singleProductReviews?.total_reviews)*100==0?"5":(singleProductReviews?.fiveStar/100)*singleProductReviews?.total_reviews}%]`}></div>
                  </div>
                  <p>{singleProductReviews?.threeStar}</p>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex items-center w-full xl:w-[424px] gap-2 px-4">
                  <p>2</p>
                  <div class="w-[356px] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div className={`bg-[#FFB800] h-2.5 rounded-full w-[${(singleProductReviews?.twoStar/singleProductReviews?.total_reviews)*100==0?"5":(singleProductReviews?.fiveStar/100)*singleProductReviews?.total_reviews}%]`}></div>
                  </div>
                  <p>{singleProductReviews?.twoStar}</p>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex items-center w-full xl:w-[424px] gap-2 px-4">
                  <p>1</p>
                  <div class="w-[356px] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div className={`bg-[#FFB800] h-2.5 rounded-full w-[${(singleProductReviews?.oneStar/singleProductReviews?.total_reviews)*100==0?"5":(singleProductReviews?.fiveStar/100)*singleProductReviews?.total_reviews}%]`}></div>
                  </div>
                  <p>{singleProductReviews?.oneStar}</p>
                </div>
              </div>
            </div>
            <hr />
            {singleProductReviews?.reviews?.map((item, index) => (
              <div>
                <div
                  key={index}
                  className=" py-5 px-4 xl:px-8 w-full   bg-white"
                >
                  <div className="">
                    <div className="flex justify-between">
                      <div className="flex gap-2">{generateStarRating(item.rating)}</div>
                      <div className="text-center">
                        <img
                          src="/assets/icons/mdi_like.svg"
                          alt=""
                          className="w-6 h-6"
                        />
                        <p className="font-medium text-xs xl:text-base">13</p>
                      </div>
                    </div>
                    <div className="flex flex-col-reverse lg:flex-col gap-2">
                      <div className="text-xs md:text-sm">
                        <p>{item.review}</p>
                      </div>
                      <div className="flex flex-col lg:flex-row gap-2 justify-between xl:items-center ">
                        <div className="flex items-center ">
                          <img
                            src={item.image}
                            className="w-[43px] h-[43px] rounded-full"
                            alt=""
                          />
                          <div>
                            <p className="font-semibold text-xs md:text-sm">
                              {item.name}
                            </p>
                            <p className="md:text-sm opacity-45 text-xs">
                              {item.date}
                            </p>
                          </div>
                        </div>
                        <div className="flex gap-1">
                          {item.images.map((img) => {
                            return(
                          <img
                          src={img}
                          className="w-12 h-12 md:w-16 md:h-14"
                          alt=""
                        />
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewsSingleProductPage;
