import React, { useEffect, useState } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import AdMarketplaceCard from "../common/AdMarketplaceCard";

const AdStoreCarCategories = ({ data }) => {
  return (
    data?.length > 0 &&
    data.map((item) => {
      // console.log(item,"add")
      return (
        <>
          <div className=" flex flex-col gap-2">
            <div className="flex justify-between ">
              <h2 className="font-bold text-xl md:text-3xl">
                {item.category.name}
              </h2>
              {item?.products?.length > 0 && (
                <Link
                  to={`/ad-market-place/${item.category.slug}`}
                  className="text-[#1983FF] flex items-center gap-1 cursor-pointer"
                >
                  <span>View More</span>
                  <AiOutlineRight />
                </Link>
              )}
            </div>
            <div className="flex  carousel carousel-start md:gap-8  lg:gap-8 gap-4">
              {item?.products?.length > 0 ? (
                item.products.map((product) => (
                  <Link
                    to="/ad-market-place/single-product"
                    className="carousel-item"
                    key={product.id} // Ensure each item has a unique key
                  >
                    <AdMarketplaceCard item={product} />
                  </Link>
                ))
              ) : (
                <p>No product in this category</p>
              )}
            </div>
          </div>
        </>
      );
    })
  );
};

export default AdStoreCarCategories;
