import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
// import "./bundle.css";
import { useDispatch, useSelector } from "react-redux";
import { userBundleList, userBundlesSearch } from "../../../../store/categorySlice";
import BreadCrums from "../../../Common/BreadCrums";

export default function UserDashboardAffiliateBundles() {
  const [bundlesData, setBundlesData] = useState([]);
  const [isHeartFilled, setIsHeartFilled] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const [search, setSearch] = useState("");
  const [selectVarient, setSelectVarient] = useState(false);
  const [cartButtonOne, setCartButtonOne] = useState(true);
  const [bundlesQuantity, SetbundlesQuantity] = useState(1);
  const [bundlesQuantityBadge, SetbundlesQuantityBadge] = useState(false);
  const { data, message, error } = useSelector((state) => state.category);
  const dispatch = useDispatch();

  const [cartButtonSecond, setCartButtonSecond] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleCartButtonOne = () => {
    setSelectVarient(true);
    setCartButtonOne(false);
  };

  const handleVarientButtons = () => {
    setSelectVarient(false);
    SetbundlesQuantityBadge(true);
    setCartButtonSecond(true);
  };

  const handlePlus = () => {
    SetbundlesQuantity(bundlesQuantity + 1);
  };
  const handleMinus = () => {
    if (bundlesQuantity === 1) {
    } else {
      SetbundlesQuantity(bundlesQuantity - 1);
    }
  };

  const handleCartButtonSecond = () => {
    setCartButtonSecond(false);
    SetbundlesQuantityBadge(false);
    setCartButtonOne(true);
  };
  useEffect(() => {
    if (data.bundles) setBundlesData(data?.bundles);
  }, [data]);

  useEffect(() => {
    // if(search!=""){
    //   dispatch(bundleSearch(search));
    // } else{
      dispatch(userBundleList());
    // }
  }, []);

  return (
    <>
      <div className="xl:px-4">
        {/* buttons */}
        <div className="flex justify-between items-center">
          <BreadCrums
            breadCrum={[
              {
                name: "Bundles",
                path: "/user-dashboard/affiliate-bundles",
              },
            ]}
          />
          <Link
            to="/user-dashboard/affiliate-bundles/upload-bundle"
            className="py-[7px] md:py-[10px] px-5 md:px-9 text-xs md:text-[15px] bg-[#C19A6B]  rounded-lg text-white  font-semibold"
          >
            Create New Bundle
          </Link>
        </div>
        {/* search */}
        <div className="relative flex items-center w-full py-4">
          <svg
            className="absolute left-3"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.75 14.7188L11.5625 10.5312C12.4688 9.4375 12.9688 8.03125 12.9688 6.5C12.9688 2.9375 10.0312 0 6.46875 0C2.875 0 0 2.9375 0 6.5C0 10.0938 2.90625 13 6.46875 13C7.96875 13 9.375 12.5 10.5 11.5938L14.6875 15.7812C14.8438 15.9375 15.0312 16 15.25 16C15.4375 16 15.625 15.9375 15.75 15.7812C16.0625 15.5 16.0625 15.0312 15.75 14.7188ZM1.5 6.5C1.5 3.75 3.71875 1.5 6.5 1.5C9.25 1.5 11.5 3.75 11.5 6.5C11.5 9.28125 9.25 11.5 6.5 11.5C3.71875 11.5 1.5 9.28125 1.5 6.5Z"
              fill="#036666"
            />
          </svg>

          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
            type="search"
            placeholder="Search product ..."
            onChange={(e) => dispatch(userBundlesSearch(e.target.value))}
        />
        </div>
        {/* cards */}
        <div className="grid grid-cols-4 gap-4">
          {bundlesData.length>0? bundlesData.map((bundle) => (
            <>
              <Link to={"/user-dashboard/affiliate-bundles/update-bundle/" + bundle.id}>
                <div className="flex">
                  <div
                    key={bundle.id}
                    className="w-[260px] h-[329px] relative   rounded-lg bundles-card-shadow"
                  >
                    <h2 className=" z-10 bottom-0  absolute ml-6 pb-2 text-xl  text-white">
                      {bundle.title}
                    </h2>
                    <div className=" grid grid-cols-2 gap-2 px-8 py-5">
                      {bundle.products.map((bundle, i) => {
                        if (i > 3) return;
                        return (
                          <div
                            key={bundle.id}
                            className={`relative shadow-md items-center  rounded-lg ${handleMouseEnter} ${handleMouseLeave}`}
                          >
                            <div>
                              <img
                                src={bundle.image}
                                className="h-28 relative  w-full"
                                alt={bundle.title}
                              />
                              <p className="absolute items-center top-2 left-2 bg-gray-50 px-1 rounded-full  text-[5px]">
                                {bundle.discount}
                              </p>
                              <div
                                className={`rating gap-1 absolute right-1 top-1 px-1 py-1 bg-[#1983FF33] rounded-full ${
                                  isHeartFilled
                                    ? "text-blue-500 duration-500 ease-in-out"
                                    : "text-white"
                                }`}
                                // onClick={handleHeartClick}
                              >
                                <FaHeart className="h-2 w-2 cursor-pointer" />
                              </div>
                            </div>
                            <div className="flex justify-between">
                              {/* put */}
                              <div className="ml-1 pb-1">
                                <h3 className="text-[5px] font-bold">
                                  {/* {bundle.title.slice(0, 12)} */}
                                </h3>
                                <div className="flex items-center gap-1">
                                  <img
                                    src="./assets/icons/stars.svg"
                                    className="w-4 h-1"
                                    alt=""
                                  />
                                  <span className="text-[4px]">224</span>
                                </div>
                                <div className="flex gap-1">
                                  <p className="text-[8px] font-bold">
                                    ${bundle.price}{" "}
                                  </p>
                                  <p className="opacity-70 text-[8px] mt-[1px]">
                                    ${bundle.price}
                                  </p>
                                </div>
                              </div>
                              <div
                                className={` absolute bottom-0 right-1  space-x-3 ${
                                  selectVarient
                                    ? " flex slide-from-right"
                                    : "hidden slide-to-left"
                                }`}
                              >
                                <div
                                  onClick={handleVarientButtons}
                                  className="bg-white py-1 px-1 rounded-full text-[7px] font-bold text-black"
                                >
                                  100ml
                                </div>
                                <div
                                  onClick={handleVarientButtons}
                                  className="bg-white py-1 px-1 rounded-full text-[7px] font-bold text-black"
                                >
                                  300ml
                                </div>
                                <div
                                  onClick={handleVarientButtons}
                                  className="bg-white py-1 px-1 rounded-full text-[7px] font-bold text-black"
                                >
                                  600ml
                                </div>
                              </div>
                              <div
                                className={`flex absolute top-60 left-20 space-x-3 bg-white rounded-full px-5 ${
                                  bundlesQuantityBadge
                                    ? " flex slide-from-right"
                                    : "hidden"
                                }`}
                              >
                                <div onClick={handleMinus} className="">
                                  -
                                </div>
                                <div className="">{bundlesQuantity}</div>
                                <div onClick={handlePlus} className="">
                                  +
                                </div>
                              </div>

                              <div className="px-1 py-1">
                                <div className="h-2">
                                  <div
                                    className={`  ${
                                      isHovered
                                        ? "hidden"
                                        : "flex gap-2 items-center slide-from-top"
                                    }`}
                                  ></div>
                                  <div
                                    className={`${
                                      isHovered
                                        ? "flex items-center gap-2 slide-from-bottom "
                                        : "hidden "
                                    }`}
                                  >
                                    <img
                                      src="./assets/images/top-sell/review-avatar.png"
                                      alt=""
                                    />
                                    <p className="text-[10px]">
                                      I love this product it smells...
                                    </p>
                                  </div>
                                </div>

                                <div className="card-actions justify-between items-center bottom-0 ">
                                  <div
                                    onClick={handleCartButtonOne}
                                    className={` py-1 px-2 rounded-md  text-[#1983FF] text-[7px] cursor-pointer ${
                                      cartButtonOne ? "block" : "hidden"
                                    }`}
                                  >
                                    <img
                                      src="/assets/images/top-sell/top-sell-cart-icon.png"
                                      alt="cart-button"
                                      className="h-2 w-2"
                                    />
                                  </div>
                                  <div
                                    onClick={handleCartButtonSecond}
                                    className={` py-1 px-2 rounded-md  text-[#1983FF] text-[7px] cursor-pointer ${
                                      cartButtonSecond ? "block" : "hidden"
                                    }`}
                                  >
                                    <img
                                      src="/assets/images/top-sell/plus-button.png"
                                      alt="cart-button"
                                      className="h-2 w-2"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <p>Category: {bundle.category}</p> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Link>
            </>
          )):<p>No Bundles Found</p>}
        </div>
      </div>
    </>
  );
}
