import StoreSquareBoostedPage from "../../pages/Store-Square/Store-Square-Boosted-Page/StoreSquareBoostedPage";
import StoreSquareHomePage from "../../pages/Store-Square/Store-Square-Home/StoreSquareHomePage";
import StoreSquareMallPage from "../../pages/Store-Square/Store-Square-Mall-Page/StoreSquareMallPage";
import StoreSquareSmallStoresPage from "../../pages/Store-Square/Store-Square-Small-Stores-Page/StoreSquareSmallStoresPage";
import StoreSquareTrendingPage from "../../pages/Store-Square/Store-Square-Trending-Page/StoreSquareTrendingPage";
import StoreSquareVisitMallPage from "../../pages/Store-Square/Store-Square-Visit-Mall-Page/StoreSquareVisitMallPage";
import StoreSquareRoot from "../../pages/StoreSquareRoot";

const storeSquareRoutes = [
  {
    path: "/store-square",
    // element: <StoreSquareRoot />,
    children: [
      {
        index: true,
        element: <StoreSquareHomePage />,
      },
      {
        path: "/store-square/boosted-stores",
        element: <StoreSquareBoostedPage />,
      },
      {
        path: "/store-square/trending-stores",
        element: <StoreSquareTrendingPage />,
      },
      {
        path: "/store-square/malls",
        element: <StoreSquareMallPage />,
      },
      {
        path: "/store-square/small-stores",
        element: <StoreSquareSmallStoresPage />,
      },
      {
        path: "/store-square/visit-mall/:slug",
        element: <StoreSquareVisitMallPage />,
      },
    ],
  },
];

export default storeSquareRoutes;
