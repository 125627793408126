import React from "react";
import AdStoreResidence from "../../../components/Ad-MarketPlace-Components/Residence-Page-Components/AdStoreResidence";

const AdStoreResidencepage = () => {
  return (
    <>
      <AdStoreResidence />
    </>
  );
};

export default AdStoreResidencepage;
