import "./mini-image-slider.css";

import { Link } from "react-router-dom";

export default function MiniImagesCard({ item }) {
  return (
    <>
      <Link
        to={`/main-categories/${item.slug}`}
        className="mini-images-card carousel-item rounded-md hover:rounded-lg flex flex-col items-center gap-1"
      >
        <img
          className={` w-[117px] h-[117px] md:w-[221px] md:h-[221px] lg:h-[160px] lg:w-[160px] `}
          src={item.image}
          alt=""
        />

        <div className="overlay">
          <div className="text">{item.name}</div>
        </div>
        <div className="text-below">{item.name}</div>
      </Link>
    </>
  );
}
