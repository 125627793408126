import React from "react";

import UserDashboardSellingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardSellingLayout";
import UserDashboardAffilliateUplaodInreels from "../../../../components/User-Dashboard-Components/User-Dashboard-Affiliate-page-Available-product-components/User-Dashboard-Affilliate-Inreels-Components/UserDashboardAffilliateUplaodInreels";

export default function UserDashboardAffilliateUplaodInreelsPage() {
  return (
    <>
      <UserDashboardSellingLayout>
        <UserDashboardAffilliateUplaodInreels />
      </UserDashboardSellingLayout>
    </>
  );
}
