import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStoresInMall } from "../../../store/frontendSlice";
import { useParams } from "react-router-dom";
import BoostShopCard from "../../../components/Main-Category-Components/Boost-Shop-Section/BoostShopCard";

export default function StoreSquareVisitMallPage() {
  const { data,message,error,loading} = useSelector(state => state.frontend);
  const dispatch=useDispatch()
  const { slug } = useParams();

  useEffect(() => {
    dispatch(getStoresInMall(slug));
  }, []);

  return (
    <>
      <div className="px-0 sm:px-[57px] mt-7">
        {/* small store */}
        <div className="flex justify-between px-5 sm:px-0 sm:mt-8">
          <h2 className="font-bold text-2xl">{data?.mall_name?data.mall_name:"Mall Name"}</h2>
        </div>

        <div className="max-w-screen-2xl container mx-auto my-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:gap-10">
            {data && data?.data?.length>0?
            data.data.map((a) => (
              <BoostShopCard item={a}/>
            )):(
              <div className="flex w-full items-center my-6">
                No Stores in {data?.mall_name?data.mall_name:"Mall"}
              </div>
            )}

            {/* <BoostShopCard item={store}/> */}
            {/* <StoreSquareVisitMallCard /> */}
          </div>
        </div>
      </div>
    </>
  );
}
