import UserDashboardProfile from "../../../../components/User-Dashboard-Components/User-Dashboard-User-Profile-Settings/UserDashboardProfile";
import UserDashboardBuyingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";

export default function UserDashboardProfilePage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <UserDashboardProfile />
      </UserDashboardBuyingLayout>
    </>
  );
}
