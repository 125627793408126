import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../functions/axios";
import {
  HOTSPOT,
  RECOMMENDED_PRODUCT,
  SESSION_TOKEN,
  OFFERS,
  SINGLE_PRODUCT,
  SINGLE_PRODUCT_REVIEW,
  MAIN_SHOP_CART,
  MY_SHELF,
  STORE_SQUARE,
  AD_MARKET_PLACE,
  VENDOR_PROFILE_FRONT,
  FOLLOW,
  AUCTION,
  AUCTION_SINGLE_PRODUCT,
  AUTION_BIDDING_PRODUCT,
  USER_PROFILE,
  MAIN_SHOP_SEARCH,
  GET_RANDOM_AUCTION_PRODUCT,
} from "./FrontendUrlConst";

const initialState = {
  data: [],
  aucData: [],
  token: localStorage.getItem("token") || null,
  role: localStorage.getItem("cookies") || null,
  step: localStorage.getItem("step") || null,
  loading: false,
  error: null,
  message: null,
  errorOrder:null,
  messageOrder:null,
  signout: false,
  topSelling: [],
  reels: [],
  banners: [],
  bannersLink: "",
  hotspot: [],
  products: [],
  sessionToken: localStorage.getItem("sessionToken") || null,
  recommendedProduct: [],
  mainCategorySliderData: [],
  subCategorySliderData: [],
  mainCategorySliderData1: [],
  mainOffersData: [],
  singleProductReviews: [],
  storeSqure: {},
  redirect: false,

  boostedStores: [],
  cartItems: [],
  countries: [],
  storeStyle: [],
  storeType: [],
  states: [],
  citiesByState: [],
  shippingAdressess: [],
  shippingAdress: null,
  billingAdressess: [],
  billingAdress: null,
  orderResponseData: null,
  Adproduct: null,
  adMainCatProduct: null,
  adMainSubProduct: null,
  ownerShopFrontProfileDetails: null,
  ownerShopFrontAllBundles: null,
  ownerShopFrontSelectedBundles: null,
  ownerShopFrontAllReels: null,
  ownerShopFrontSelectedReels: null,
  smartFilterData: {},
  auctionCatData: [],
  aucSingleProd: [],
  randomAucProd: {},
  aucSearch:[],
  bubbleModData: null,
  coupan: [],
  bidHistory:[]
  
};

// main page category slider
export const mainCategorySlider = createAsyncThunk(
  "mainCategorySlider",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(`public/categories`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// main page subcategory slider
export const subCategorySlider = createAsyncThunk(
  "subCategorySlider",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/public/categories/${a}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRandomAuctionProduct = createAsyncThunk(
  "getRandomAuctionProduct",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(GET_RANDOM_AUCTION_PRODUCT);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const smartFilterInitialData = createAsyncThunk(
  "smartFilterInitialData",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get("public/filter/"+a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getBidHistory = createAsyncThunk(
  "getBidHistory",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get("public/auction-bids/"+a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getSmartFilterData = createAsyncThunk(
  "getSmartFilterData",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get("public/category-products"+a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSmartFilterReelsData = createAsyncThunk(
  "getSmartFilterReelsData",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get("public/Inspiration"+a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSmartFilterSingleCatReelsData = createAsyncThunk(
  "getSmartFilterSingleCatReelsData",
  async ({CAT,URL}, { rejectWithValue }) => {
    try {
      const res = await axios.get(`public/Inspiration/${CAT}`+URL);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// main page offers section
export const mainOffers = createAsyncThunk(
  "mainOffers",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(OFFERS);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getHotSpot = createAsyncThunk(
  "getHotSpot",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(HOTSPOT);

      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSessionToken = createAsyncThunk(
  "getSessionToken",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(SESSION_TOKEN);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getCoupan = createAsyncThunk(
  "getCoupan",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get("/user/coupon");
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getAdMarketPlace = createAsyncThunk(
  "getAdMarketPlace",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(AD_MARKET_PLACE);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getUserProfile = createAsyncThunk(
  "getUserProfile",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get("/public/user-profile/" + a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getSingleForAuction = createAsyncThunk(
  "getSingleForAuction",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(AUCTION_SINGLE_PRODUCT + a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const addBid = createAsyncThunk(
  "addBid",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.post(AUTION_BIDDING_PRODUCT, a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getAuction = createAsyncThunk(
  "getAuction",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(AUCTION);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getAuctionBySlug = createAsyncThunk(
  "getAuctionBySlug",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(AUCTION + "/" + a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getVendorProfile = createAsyncThunk(
  "getVendorProfile",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(VENDOR_PROFILE_FRONT + a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const recommendedProducts = createAsyncThunk(
  "recommendedProduct",
  async (a, { rejectWithValue }) => {
    try {
      if (a) {
        const res = await axios.get(RECOMMENDED_PRODUCT + "?token=" + a);
        return res.data;
      } else {
        const res = await axios.get(RECOMMENDED_PRODUCT);
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMyShelf = createAsyncThunk(
  "getMyShelf",
  async (a, { rejectWithValue }) => {
    try {
      if (a) {
        const res = await axios.get(MY_SHELF + "?token=" + a);
        return res.data;
      } else {
        const res = await axios.get(MY_SHELF);
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStoresInMall = createAsyncThunk(
  "getStoresInMall",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/public/store-square/mall-stores/${a}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const myShelfMainCategory = createAsyncThunk(
  "myShelfMainCategory",
  async (a, { rejectWithValue }) => {
    try {
      if (a) {
        const res = await axios.get(MY_SHELF + "/" + a);
        return res.data;
      } else {
        const res = await axios.get(MY_SHELF);
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const topSellingProducts = createAsyncThunk(
  "topSellingProducts",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/public/wizalot-products`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const inspiration = createAsyncThunk(
  "inspiration",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/public/Inspiration`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const singleCatInspiration = createAsyncThunk(
  "singleCatInspiration",
  async (cat, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/public/Inspiration/${cat}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStoreSquare = createAsyncThunk(
  "getStoreSquare",
  async (a, { rejectWithValue }) => {
    try {
      if(a && a!=""){
        const res = await axios.get(`/public/store-square`+a);
        return res.data;
      } else {
        const res = await axios.get(`/public/store-square`);
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addToWishlist = createAsyncThunk(
  "addToWishlist",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.post(`/user/wishlists`, a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getReels = createAsyncThunk(
  "getReels",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/public/reels`, a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBanners = createAsyncThunk(
  "getBanners",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/public/banners`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBannersLink = createAsyncThunk(
  "getBannersLink",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/public/banners/${id}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// deleteWishlist
export const deleteWishlist = createAsyncThunk(
  "deleteWishlist",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.post(`/user/wishlists-delete`, a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// getCategoryProducts
export const getCategoryPremiumProducts = createAsyncThunk(
  "getCategoryPremiumProducts",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/public/category/${slug}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// getOfferProducts
export const getOfferProducts = createAsyncThunk(
  "getOfferProducts",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/public/offers/${slug}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// getCategoryProducts
export const getCategoryProducts = createAsyncThunk(
  "getCategoryProducts",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/public/category-products/${slug}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSingleProduct = createAsyncThunk(
  "getSingleProduct",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${SINGLE_PRODUCT}${slug}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getADSingleProduct = createAsyncThunk(
  "getADSingleProduct",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get(`public/ads-product/${slug}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSubCategory = createAsyncThunk(
  "getSubCategory",
  async (subCategory, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/public/subcategory/${subCategory}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const follow = createAsyncThunk(
  "follow",
  async (subCategory, { rejectWithValue }) => {
    try {
      const res = await axios.post(FOLLOW, subCategory);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSubCategoryProducts = createAsyncThunk(
  "getSubCategoryProducts",
  async (subCategory, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `/public/subcategory-products/${subCategory}`
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSingleProductReviews = createAsyncThunk(
  "getSingleProductReviews",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${SINGLE_PRODUCT_REVIEW}${slug}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getCartProducts = createAsyncThunk(
  "getCartProducts",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${MAIN_SHOP_CART}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCartProduct = createAsyncThunk(
  "deleteCartProduct",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.delete(`/public/cart/${id}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCartProduct = createAsyncThunk(
  "updateCartProduct",
  async (data, { rejectWithValue }) => {
    try {
      console.l
      const res = await axios.put(`/public/cart/${data.product_id}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCountries = createAsyncThunk(
  "/getCountries",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("countries");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStoreType = createAsyncThunk(
  "/getStoreType",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("/public/store-type");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStoreStyle = createAsyncThunk(
  "/getStoreStyle",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get("/public/store-style");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStateByCountry = createAsyncThunk(
  "/getStateByCountry",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.get(`countries/${arg}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCitiesByState = createAsyncThunk(
  "/getCitiesByState",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/countries/state/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addShippingAddress = createAsyncThunk(
  "addShippingAddress",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.post("public/shipping-address", a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getShippingAdressess = createAsyncThunk(
  "/getShippingAdressess",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`public/shipping-address`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getShippingAdressByID = createAsyncThunk(
  "/getShippingAdressByID",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`public/shipping-address/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addBillingAddress = createAsyncThunk(
  "addBillingAddress",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.post("public/billing-address", a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBillingAddressess = createAsyncThunk(
  "/getBillingAddressess",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`public/billing-address`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBillingAdressByID = createAsyncThunk(
  "/getBillingAdressByID",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`public/billing-address/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const mainShopSearch = createAsyncThunk(
  "/mainShopSearch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(MAIN_SHOP_SEARCH+data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const adMarketPlaceSearch = createAsyncThunk(
  "/adMarketPlaceSearch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get("/public/ads?search="+data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const auctionSearch = createAsyncThunk(
  "/auctionSearch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get("/public/auction?search="+data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const orderConfirm = createAsyncThunk(
  "orderConfirm",
  async (a, { rejectWithValue }) => {
    try {
      const res = await axios.post("public/order", a);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const StoreSquare = createAsyncThunk(
  "StoreSquare",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get(STORE_SQUARE + "/" + slug);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAdMarketMainCatProducts = createAsyncThunk(
  "getAdMarketMainCatProducts",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get("public/ads/" + slug);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAdMarketSubCatProducts = createAsyncThunk(
  "getAdMarketSubCatProducts",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get("public/ads/" + slug.main + "/" + slug.sub);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOwnerShopFrontProfileDetails = createAsyncThunk(
  "getOwnerShopFrontProfileDetails",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get("public/user-profile/" + slug);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOwnerShopAllBundles = createAsyncThunk(
  "getOwnerShopAllBundles",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get("public/bundle-select/" + slug);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOwnerShopSelectedBundles = createAsyncThunk(
  "getOwnerShopSelectedBundles",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get("public/bundle-unselect/" + slug);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postOwnerShopSelectedBundle = createAsyncThunk(
  "postOwnerShopSelectedBundle",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.put(`public/bundle-update/${data.slug}`, data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postProductToCart = createAsyncThunk(
  "postProductToCart",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`public/cart`, data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOwnerShopAllReels = createAsyncThunk(
  "getOwnerShopAllReels",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get("public/inreel-select/" + slug);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOwnerShopSelectedReels = createAsyncThunk(
  "getOwnerShopSelectedReels",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get("public/inreel-unselect/" + slug);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postOwnerShopSelectedReels = createAsyncThunk(
  "postOwnerShopSelectedReels",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.put(`public/inreel/${data.slug}`, data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBubbleModalData = createAsyncThunk(
  "getBubbleModalData",
  async (slug, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/public/store-square/s/${slug}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const frontendSlice = createSlice({
  name: "frontend",
  initialState,
  reducers: {
    addToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
    },
    logout: (state, action) => {
      state.token = null;
      state.step = null;
      localStorage.removeItem("token");
      localStorage.removeItem("step");
    },
    clearMessage: (state) => {
      state.message = null;
      state.error = null;
      state.bannersLink = null;
      state.messageOrder = null;
      state.errorOrder = null;
    },
    redirectFalse: (state) => {
      state.redirect = false;
    },
    resetSignout: (state) => {
      state.signout = false;
    },
    nulling: (state) => {
      state.states  = [];
      state.citiesByState = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(mainCategorySlider.pending, (state) => {
        state.loading = true;
      })
      .addCase(mainCategorySlider.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.mainCategorySliderData = payload;
      })
      .addCase(mainCategorySlider.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(subCategorySlider.pending, (state) => {
        state.loading = true;
      })
      .addCase(subCategorySlider.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.subCategorySliderData = payload;
      })
      .addCase(subCategorySlider.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getRandomAuctionProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRandomAuctionProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.randomAucProd = payload;
      })
      .addCase(getRandomAuctionProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(smartFilterInitialData.pending, (state) => {
        state.loading = true;
      })
      .addCase(smartFilterInitialData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.smartFilterData = payload;
      })
      .addCase(smartFilterInitialData.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(topSellingProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(topSellingProducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.topSelling = payload;
      })
      .addCase(topSellingProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })

      .addCase(inspiration.pending, (state) => {
        // state.loading = true;
      })
      .addCase(inspiration.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(inspiration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(singleCatInspiration.pending, (state) => {
        // state.loading = true;
      })
      .addCase(singleCatInspiration.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.reels = payload;
      })
      .addCase(singleCatInspiration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getStoreSquare.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStoreSquare.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.storeSqure = payload;
      })
      .addCase(getStoreSquare.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(addToWishlist.pending, (state) => {
        state.loading = true;
      })
      .addCase(addToWishlist.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
      })
      .addCase(addToWishlist.rejected, (state, action) => {
        const error = action.payload?.message;
        if (error && error.includes("Unauthenticated")) {
          state.signout = true;
          state.redirect = true;
          state.error = "Login First";
        } else {
          state.loading = false;
          state.error = action.payload.message;
        }
      })

      .addCase(getReels.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReels.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.reels = payload;
      })
      .addCase(getReels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getBanners.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBanners.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.banners = payload;
      })
      .addCase(getBanners.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getBannersLink.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBannersLink.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.bannersLink = payload;
      })
      .addCase(getBannersLink.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // deletewishlist
      .addCase(deleteWishlist.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteWishlist.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(deleteWishlist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })

      // main page offers section
      .addCase(mainOffers.pending, (state) => {
        state.loading = true;
      })
      .addCase(mainOffers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.mainOffersData = payload;
        // console.log(payload);
      })
      .addCase(mainOffers.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getHotSpot.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHotSpot.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.hotspot = payload.hero_sections;
      })
      .addCase(getHotSpot.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getSessionToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSessionToken.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.sessionToken = payload.session_token;
        localStorage.setItem("sessionToken", payload.session_token);
      })
      .addCase(getSessionToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(recommendedProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(recommendedProducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.recommendedProduct = payload;
      })
      .addCase(recommendedProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getAdMarketPlace.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdMarketPlace.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(getAdMarketPlace.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getSingleForAuction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleForAuction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.aucSingleProd = payload;
      })
      .addCase(getSingleForAuction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(addBid.pending, (state) => {
        state.loading = true;
      })
      .addCase(addBid.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        // state.data = payload;
      })
      .addCase(addBid.rejected, (state, action) => {
        state.loading = false;
        if(action.payload.message){
          state.error = action.payload.message;
        }
        else{
          state.error = action.payload.error;
        }
      })

      .addCase(getAuction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.aucData = payload;
      })
      .addCase(getAuction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getAuctionBySlug.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuctionBySlug.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.auctionCatData = payload;
      })
      .addCase(getAuctionBySlug.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getVendorProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(getVendorProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })

      .addCase(getMyShelf.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyShelf.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.mainCategorySliderData1 = payload;
      })
      .addCase(getMyShelf.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(getCoupan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCoupan.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.coupan = payload;
      })
      .addCase(getCoupan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getStoresInMall.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStoresInMall.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.data = payload;
      })
      .addCase(getStoresInMall.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(follow.pending, (state) => {
        state.loading = true;
      })
      .addCase(follow.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
      })
      .addCase(follow.rejected, (state, action) => {
        const error = action.payload?.message;
        if (error && error.includes("Unauthenticated")) {
          state.signout = true;
          state.redirect = true;
          state.error = "Login First";
          state.message = "Login First";
        } else {
          state.loading = false;
          state.error = action.payload.error;
        }
      })

      .addCase(myShelfMainCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(myShelfMainCategory.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(myShelfMainCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getCategoryPremiumProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCategoryPremiumProducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.data = payload;
      })
      .addCase(getCategoryPremiumProducts.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(getSmartFilterData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSmartFilterData.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.products = payload;
      })
      .addCase(getSmartFilterData.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })
      .addCase(getBidHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBidHistory.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.bidHistory = payload;
      })
      .addCase(getBidHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getSmartFilterReelsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSmartFilterReelsData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(getSmartFilterReelsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getSmartFilterSingleCatReelsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSmartFilterSingleCatReelsData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.reels = payload;
      })
      .addCase(getSmartFilterSingleCatReelsData.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(getOfferProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOfferProducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(getOfferProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getCategoryProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCategoryProducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.products = payload;
      })
      .addCase(getCategoryProducts.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(getSingleProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.products = payload;
      })
      .addCase(getSingleProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(StoreSquare.pending, (state) => {
        state.loading = true;
      })
      .addCase(StoreSquare.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.boostedStores = payload;
      })
      .addCase(StoreSquare.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getSubCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubCategory.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(getSubCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getSubCategoryProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubCategoryProducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.products = payload;
      })
      .addCase(getSubCategoryProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getSingleProductReviews.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleProductReviews.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.singleProductReviews = payload;
      })
      .addCase(getSingleProductReviews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getCartProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCartProducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.cartItems = payload;
      })
      .addCase(getCartProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        console.log("sdsd",action.payload.message)
      })

      .addCase(deleteCartProduct.pending, (state) => {
        // state.loading = true;
      })
      .addCase(deleteCartProduct.fulfilled, (state, { payload }) => {
        // state.loading = false;
        // state.message = payload.message;
        // state.cartItems = payload;
      })
      .addCase(deleteCartProduct.rejected, (state, action) => {
        // state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(updateCartProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCartProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        // state.cartItems = payload;
      })
      .addCase(updateCartProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getCountries.pending, (state) => {
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countries = action.payload.countries;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.error = action.payload.message;
      })

      .addCase(getStoreStyle.pending, (state) => {
      })
      .addCase(getStoreStyle.fulfilled, (state, action) => {
        state.storeStyle = action.payload.data;
      })
      .addCase(getStoreStyle.rejected, (state, action) => {
        state.error = action.payload.message;
      })

      .addCase(getStoreType.pending, (state) => {
      })
      .addCase(getStoreType.fulfilled, (state, action) => {
        state.storeType = action.payload.data;
      })
      .addCase(getStoreType.rejected, (state, action) => {
        state.error = action.payload.message;
      })

      .addCase(getStateByCountry.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getStateByCountry.fulfilled, (state, action) => {
        // state.loading = false;
        state.states = action.payload.states;
      })
      .addCase(getStateByCountry.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getCitiesByState.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getCitiesByState.fulfilled, (state, action) => {
        // state.loading = false;
        state.citiesByState = action.payload.cities;
      });
    builder
      .addCase(getCitiesByState.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(addShippingAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(addShippingAddress.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
      })
      .addCase(addShippingAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getShippingAdressess.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getShippingAdressess.fulfilled, (state, action) => {
        // state.loading = false;
        state.shippingAdressess = action.payload;
      })
      .addCase(getShippingAdressess.rejected, (state, action) => {
        // state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(getShippingAdressByID.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getShippingAdressByID.fulfilled, (state, action) => {
        // state.loading = false;
        state.shippingAdress = action.payload;
      });
    builder
      .addCase(getShippingAdressByID.rejected, (state, action) => {
        // state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(addBillingAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(addBillingAddress.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
      })
      .addCase(addBillingAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getBillingAddressess.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getBillingAddressess.fulfilled, (state, action) => {
        // state.loading = false;
        state.billingAdressess = action.payload;
      })
      .addCase(getBillingAddressess.rejected, (state, action) => {
        // state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(getBillingAdressByID.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getBillingAdressByID.fulfilled, (state, action) => {
        // state.loading = false;
        state.billingAdress = action.payload;
      })
      .addCase(getBillingAdressByID.rejected, (state, action) => {
        // state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(orderConfirm.pending, (state) => {
        state.loading = true;
      })
      .addCase(orderConfirm.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.messageOrder = payload.message;
        state.orderResponseData = payload;
      })
      .addCase(orderConfirm.rejected, (state, action) => {
        state.loading = false;
        if (action.payload.message) {
          state.errorOrder = action.payload.message;
        }
        else if (action.payload.error) { 
          state.errorOrder = action.payload.error;
        }
        else {
          state.errorOrder = Object.values(action.payload.errors)[0][0];
        }
      })
      .addCase(mainShopSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(mainShopSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(mainShopSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(adMarketPlaceSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(adMarketPlaceSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
      })
      .addCase(adMarketPlaceSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(auctionSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(auctionSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.aucSearch = payload;
      })
      .addCase(auctionSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getADSingleProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(getADSingleProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.Adproduct = payload;
      })
      .addCase(getADSingleProduct.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(getAdMarketMainCatProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdMarketMainCatProducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.adMainCatProduct = payload;
      })
      .addCase(getAdMarketMainCatProducts.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(getAdMarketSubCatProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdMarketSubCatProducts.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.adSubCatProduct = payload;
      })
      .addCase(getAdMarketSubCatProducts.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(getOwnerShopFrontProfileDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getOwnerShopFrontProfileDetails.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          // state.message = payload.message;
          state.ownerShopFrontProfileDetails = payload;
        }
      )
      .addCase(getOwnerShopFrontProfileDetails.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(getOwnerShopAllBundles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOwnerShopAllBundles.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.ownerShopFrontAllBundles = payload;
      })
      .addCase(getOwnerShopAllBundles.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(getOwnerShopSelectedBundles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOwnerShopSelectedBundles.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.ownerShopFrontSelectedBundles = payload;
      })
      .addCase(getOwnerShopSelectedBundles.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(postOwnerShopSelectedBundle.pending, (state) => {
        state.loading = true;
      })
      .addCase(postOwnerShopSelectedBundle.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        // state.postOwnerShopSelectedBundle = payload;
      })
      .addCase(postOwnerShopSelectedBundle.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(postProductToCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(postProductToCart.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        // state.postOwnerShopSelectedBundle = payload;
        //console.log(payload);
      })
      .addCase(postProductToCart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        console.log("sdsdv",action.payload.message)
      })

      .addCase(getOwnerShopAllReels.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOwnerShopAllReels.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.ownerShopFrontAllReels = payload;
      })
      .addCase(getOwnerShopAllReels.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(getOwnerShopSelectedReels.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOwnerShopSelectedReels.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.ownerShopFrontSelectedReels = payload;
      })
      .addCase(getOwnerShopSelectedReels.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(postOwnerShopSelectedReels.pending, (state) => {
        state.loading = true;
      })
      .addCase(postOwnerShopSelectedReels.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        // state.postOwnerShopSelectedBundle = payload;
      })
      .addCase(postOwnerShopSelectedReels.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(getBubbleModalData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBubbleModalData.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.message = payload.message;
        state.bubbleModData = payload;
      })
      .addCase(getBubbleModalData.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })
  },
});

export const { addToken, logout, nulling, clearMessage, resetSignout, redirectFalse } =
  frontendSlice.actions;

export default frontendSlice.reducer;
