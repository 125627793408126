// axios.js
import axios from 'axios';
import URL from './URLString';

let activeRequests = 0;

const instance = axios.create({
    baseURL: URL,
});

instance.interceptors.request.use(
    function (config) {
        activeRequests++;
        // Show loading indicator or update loading state here
        // console.log("API request started, active requests:", activeRequests);
        
        // Get token from localStorage
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        
        return config;
    },
    function (error) {
        activeRequests--;
        // Handle request error (if needed)
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    function (response) {
        activeRequests--;
        // Handle response data (if needed)
        // console.log("API response received, active requests:", activeRequests);
        return response;
    },
    function (error) {
        activeRequests--;
        // Handle response error (if needed)
        return Promise.reject(error);
    }
);

export default instance;
