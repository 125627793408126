import { NavLink, useLocation } from "react-router-dom";
import { LuLayoutDashboard, LuSunMedium } from "react-icons/lu";
import { CgMenuBoxed } from "react-icons/cg";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BsPersonDown } from "react-icons/bs";
import { TbHammer } from "react-icons/tb";
import { RiSettings3Line } from "react-icons/ri";

import { TbUsers } from "react-icons/tb";
import { useEffect, useState } from "react";
import { IoIosLogOut } from "react-icons/io";
import { LiaHandshakeSolid } from "react-icons/lia";
import { PiHandArrowDown } from "react-icons/pi";

import "./side-bar.css";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/categorySlice";

export default function UserDashboardSellingSidebar() {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // Automatically open the dropdown of the active nav link
    if (
      location.pathname.startsWith("/user-dashboard/vender-offers") ||
      location.pathname.startsWith("/user-dashboard/vender-approved-offers")
    ) {
      setOpenDropdown("offer");
    } else if (
      location.pathname.startsWith(
        "/user-dashboard/affiliate-available-product"
      ) ||
      location.pathname.startsWith("/user-dashboard/my-affiliate-store") ||
      location.pathname.startsWith("/user-dashboard/affiliate-bundles") ||
      location.pathname.startsWith("/user-dashboard/affiliate-inReels")
    ) {
      setOpenDropdown("affiliate");
    } else if (
      location.pathname === "/user-dashboard/profile" ||
      location.pathname === "/user-dashboard/profile-addresses" ||
      location.pathname === "/user-dashboard/payment-methods"
    ) {
      setOpenDropdown("account");
    } else {
      setOpenDropdown(null);
    }
  }, [location.pathname]);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const handleLogout = (e) =>{
    e.preventDefault();
    dispatch(logout());
  };

  return (
    <>
      <div className="w-full xl:w-64 h-[87vh]  bg-[#C19A6B] p-4  xl:ml-4 rounded-[0.7rem] hide-scrollbar overflow-y-auto">
        <div className="flex flex-col gap-2 ">
          {/* dashboard  */}
          <NavLink
            to="/user-dashboard/selling"
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
            end
          >
            <LuLayoutDashboard className="w-4 h-4" />
            Dashboard
          </NavLink>

          {/* offer section  */}
          <div className="flex relative  flex-col ">
            <div
              onClick={() => toggleDropdown("offer")}
              className={`${
                location.pathname.startsWith("/user-dashboard/vender-offers") ||
                location.pathname.startsWith(
                  "/user-dashboard/vender-approved-offers"
                )
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <PiHandArrowDown className="w-4 h-4" />
              Offer From Vendors
              {openDropdown === "offer" ? (
                <RiArrowDropUpLine className="!w-6 !h-6  absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6  absolute right-1" />
              )}
            </div>
            {/* hot-spot dropdown  */}
            {openDropdown === "offer" && (
              <>
                <NavLink
                  to="/user-dashboard/vender-offers"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                  end
                >
                  New Offers
                </NavLink>
                <NavLink
                  to="/user-dashboard/vender-approved-offers"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  {" "}
                  Approved Offers
                </NavLink>
              </>
            )}
          </div>

          {/* my followers  */}
          <NavLink
            to="/user-dashboard/my-follower"
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
            end
          >
            <AiOutlineUsergroupAdd className="w-4 h-4" />
            My Followers
          </NavLink>

          {/* affiliate section  */}
          <div className="flex relative  flex-col ">
            <div
              onClick={() => toggleDropdown("affiliate")}
              className={`${
                location.pathname.startsWith(
                  "/user-dashboard/affiliate-available-product"
                ) ||
                location.pathname.startsWith(
                  "/user-dashboard/my-affiliate-store"
                ) ||
                location.pathname.startsWith(
                  "/user-dashboard/affiliate-bundles"
                ) ||
                location.pathname.startsWith(
                  "/user-dashboard/affiliate-inReels"
                )
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <BsPersonDown className="w-4 h-4" />
              Affiliate
              {openDropdown === "affiliate" ? (
                <RiArrowDropUpLine className="!w-6 !h-6  absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6  absolute right-1" />
              )}
            </div>
            {/* affiliate dropdown  */}
            {openDropdown === "affiliate" && (
              <>
                <NavLink
                  to="/user-dashboard/affiliate-available-product"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Available Products
                </NavLink>
                <NavLink
                  to="/user-dashboard/my-affiliate-store"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  My Affiliate Store
                </NavLink>
                <NavLink
                  to="/user-dashboard/affiliate-bundles"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Bundles
                </NavLink>
                <NavLink
                  to="/user-dashboard/affiliate-inReels"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  InReels
                </NavLink>
              </>
            )}
          </div>

          {/* ad marketplace  */}
          <NavLink
            to="/user-dashboard/ad-market-place"
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
          >
            <LiaHandshakeSolid className="w-4 h-4" />
            Ad-Marketplace
          </NavLink>
          {/* Auction  */}
          <NavLink
            to="/user-dashboard/auction-product"
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
          >
            <TbHammer className="w-4 h-4" />
            Auction
          </NavLink>

          {/* account and setting */}
          <div className="flex relative  flex-col ">
            <div
              onClick={() => toggleDropdown("account")}
              className={`${
                location.pathname === "/user-dashboard/profile" ||
                location.pathname === "/user-dashboard/profile-addresses" ||
                location.pathname === "user-dashboard/payment-methods"
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <RiSettings3Line className="w-4 h-4" />
              Accounts & Settings
              {openDropdown === "account" ? (
                <RiArrowDropUpLine className="!w-6 !h-6  absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6  absolute right-1" />
              )}
            </div>
            {/* Roll Management dropdown  */}
            {openDropdown === "account" && (
              <>
                <NavLink
                  to="/user-dashboard/profile"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                  end
                >
                  Profile
                </NavLink>
                <NavLink
                  to="/user-dashboard/profile-addresses"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Addresses
                </NavLink>
                <NavLink
                  to="/user-dashboard/payment-methods"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Payment Method
                </NavLink>
              </>
            )}
          </div>

          {/* logout  */}
          <NavLink
            to="/dashboard/login"
            // onClick={handleLogout}
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
          >
            <IoIosLogOut className="w-4 h-4" />
            Logout
          </NavLink>
        </div>
      </div>
    </>
  );
}
