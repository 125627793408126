import React from 'react'
import SubCategoryProducts from '../../../components/Ad-MarketPlace-Components/Sub-Category-Page-Components/SubCategoryProducts'

export default function SubCategoryPage() {
  return (
    <>
    <SubCategoryProducts />
    </>
  )
}
