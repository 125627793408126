import React from "react";
import { Outlet } from "react-router-dom";
import AdStoreNavbar from "../components/Ad-MarketPlace-Components/Home-Page-Components/Ad-Store-Navbar/AdStoreNavbar";
import AdStoreCategoriesBar from "../components/Ad-MarketPlace-Components/Home-Page-Components/Ad-Store-Categories-Bar/AdStoreCategoriesBar";
import Footer from "../components/Footer/Footer";

export default function AdMarketPlaceRoot() {
  return (
    <>
      <main>
        {/* <AdStoreNavbar />
        <AdStoreCategoriesBar /> */}

        <div className="mt-36">
          <Outlet />
        </div>
        {/* <Footer /> */}
      </main>
    </>
  );
}
