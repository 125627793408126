import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { addBid, clearMessage } from "../../../../store/frontendSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function AuctionHomeFeaturedProduct({data}) {
  const [bid, setBid] = useState(data?.bids?.bid);
  const [bidButtonIcon, setBidButtonIcon] = useState(0);
  const { message,error} = useSelector((state) => state.frontend);

  const handlePlus = () => {
    setBid(parseInt(bid) + 1);
  };

  const handleMinus = () => {
   
    setBid(parseInt(bid) - 1);
  }
const dispatch=useDispatch()
  const handleBidIcon = () => {
    // Change icon to the other state
    setBidButtonIcon((prevIcon) => (prevIcon === 0 ? 1 : 0));

    // Change back to the original icon after 3 seconds
    dispatch(addBid(  {product_id:data?.id,bid:bid}))
    setTimeout(() => {
      setBidButtonIcon(0); // Assuming 0 is the original icon state
    }, 3000);
  };
  useEffect(() => {

    if(message){
      toast.success(message)
    }
    else if (error){
      toast.error(error)
    }
    dispatch(clearMessage())
    if (parseInt(data?.highestBid) > parseInt(data?.price)) {
      
      setBid(parseInt(data?.highestBid)+1)
    } else {
      setBid(data?.price)
      
    }
  }, [message, error])
  return (
    <>
      <div className="px-4 lg:px-8 xl:px-[128px]">
        {/* featured 3 section wrapper */}
        <div className="flex justify-between gap-6 md:gap-12 pt-10">
          {/* product detail section -- section 0ne */}
          <div className="w-[50%] lg:w-[32%] mt-8 flex flex-col gap-8">
            <div className="flex gap-2">
              <div>
                <img
                  className="h-12 w-12 rounded-full"
                  src={data?.user_image}
                  alt="img"
                />
              </div>
              <div className="text-start">
                <div className="text-sm">Created by</div>
                <div className="text-lg font-bold">@{data?.user_name}</div>
              </div>
            </div>

            <div className="flex  flex-col gap-4">
              <div className="flex flex-col gap-2">
                <div className="text-xl md:text-3xl xl:text-4xl font-[500]">
                {data?.name}
                </div>
                <div className="text-[#505050] text-xs md:text-sm xl:text-base">
                {data?.description?.slice(0,80)}
                </div>
              </div>
              <div className="flex justify-between">
              <Link
                to={"/ad-market-place/auction/single-product/"+data?.slug}
                className="text-base md:text-2xl py-[6.5px] px-[21px] md:py-3 bg-[#C19A6B] text-white rounded w-full text-center md:w-fit"
              >
                View Product
                </Link>
               
              </div>
              
            </div>

            <div
              className="flex justify-between items-center py-2 md:py-4 px-3 md:px-6  bg-white rounded-lg md:mt-[4.8rem] xl:mt-[6.8rem]"
              style={{
                boxShadow: "0px 6px 15px 4px #0508280F",
              }}
            >
              <div className="text-xs md:text-sm">All Bidders</div>
              <Link
                to={"/ad-market-place/auction/bid-history/"+data?.slug}
                className="text-base md:text-xl py-[6.5px] px-[21px] md:py-3 bg-[#C19A6B] text-white rounded w-full text-center md:w-fit"
              >
               Bid History
              </Link>
              
            </div>
          </div>

          {/* image section -- section -- two */}
          <div className="flex flex-col gap-[1.32rem] md:gap-8 w-[50%] lg:w-[30%]">
            <div>
              <img
                className="w-full h-[172px] md:h-[323px] xl:h-[360px]"
                src={data?.image}
                alt=""
              />
            </div>
            <div className="flex flex-col md:flex-row w-full justify-between gap-8 md:gap-4">
              {/* counter */}
              <div className="flex items-center justify-between bg-[#C19A6B] px-1 py-1 rounded-[8px] w-full">
                {/* minus button */}
                <div
                  className="bg-white py-3 px-2 cursor-pointer rounded-tl-[10px] rounded-bl-[10px] "
                  //   style={{ borderRadius: "9px 0px 0px 9px" }}
                  onClick={handleMinus}
                >
                  <svg
                    className="w-3 h-2 md:w-7 md:h-6"
                    // width="21"
                    // height="12"
                    viewBox="0 0 21 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 6H19.5"
                      stroke="black"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div className="px-4 md:px-8 text-base xl:text-xl py-1 text-white">
                  ${bid}.00
                </div>

                {/* plus button */}
                <div
                  className="bg-white py-3 px-2 cursor-pointer rounded-tr-[10px] rounded-br-[10px]"
                  //   style={{
                  //     borderRadius: "0px",
                  //     borderTopRightRadius: "12px",
                  //     borderBottomRightRadius: "12px",
                  //     borderBottomLeftRadius: "0px",
                  //   }}
                  onClick={handlePlus}
                >
                  <svg
                    className="w-3 h-2 md:w-7 md:h-6"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 11H19.5"
                      stroke="black"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5 2V20"
                      stroke="black"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>

              {/* cart button */}

              {bidButtonIcon === 0 ? (
                <>
                  <div
                    div
                    className="bg-[#C19A6B] py-2 md:py-3 px-4 rounded-[8px]  cursor-pointer flex justify-center items-center"
                    onClick={handleBidIcon}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.5529 0.574578C11.209 0.676769 10.7827 0.906697 10.5211 1.14685C10.4049 1.25415 8.88879 2.69503 7.15471 4.34541C4.85875 6.5374 3.94327 7.43668 3.77858 7.68193C3.23608 8.48413 3.15858 9.52136 3.5703 10.4411C3.73983 10.809 3.81733 10.906 6.19564 13.6805C6.99486 14.6105 7.37268 14.8762 8.09925 15.0192C8.65629 15.1316 9.43129 14.9681 9.91567 14.6411C10.0271 14.5696 10.6907 13.9564 11.4027 13.282L12.6863 12.0557L16.4112 16.3937C19.3611 19.8375 20.1748 20.7572 20.3444 20.839C20.6447 20.9923 21.0564 20.9565 21.3325 20.7521C21.72 20.4609 21.8653 19.8886 21.6522 19.4543C21.5892 19.3214 20.0005 17.4309 17.8789 14.9579C15.8639 12.6126 14.217 10.6659 14.217 10.6404C14.2218 10.6097 14.8031 10.0323 15.5151 9.35275C16.9246 8.00894 17.1281 7.75347 17.3121 7.11988C17.4429 6.67536 17.4526 5.97535 17.3364 5.56659C17.1717 4.99432 16.9828 4.72863 15.5151 3.01693C13.9554 1.20305 13.7374 0.973121 13.3596 0.778959C12.8171 0.503045 12.0954 0.421292 11.5529 0.574578ZM12.5023 2.70525C12.7348 2.87898 15.3165 5.91404 15.394 6.1082C15.4667 6.27681 15.4473 6.48119 15.3456 6.63448C15.3165 6.68047 13.8295 8.11624 12.0469 9.81772C8.60785 13.098 8.66113 13.052 8.33659 12.9141C8.24456 12.8732 7.65362 12.2243 6.76236 11.1922C5.2511 9.42939 5.2075 9.36297 5.33829 9.03085C5.37704 8.92866 6.57345 7.75347 8.62238 5.79652C10.3904 4.10526 11.8871 2.69503 11.9452 2.65927C12.1051 2.56218 12.3327 2.58262 12.5023 2.70525Z"
                        fill="white"
                      />
                      <path
                        d="M1.22585 19.3845C1.03545 19.5118 0.964603 19.5949 0.862762 19.8386C0.752066 20.1044 0.738783 20.1875 0.756494 20.4699C0.787489 20.8742 0.946891 21.1899 1.22585 21.3726L1.42067 21.5H7.40269C13.1102 21.5 13.3936 21.4945 13.5618 21.3948C14.2171 21.0237 14.2437 19.7887 13.6061 19.3734C13.429 19.2571 13.4157 19.2571 7.44697 19.2405L1.46495 19.2294L1.22585 19.3845Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </>
              ) : (
                <>
                  <div className="bg-[#C19A6B] py-3 px-4 rounded-[15px] ms-2">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.6673 12.9262V13.9995C25.6659 16.5153 24.8512 18.9633 23.3449 20.9783C21.8385 22.9933 19.7211 24.4674 17.3086 25.1807C14.896 25.8941 12.3175 25.8084 9.95753 24.9365C7.59761 24.0647 5.58275 22.4533 4.21344 20.3428C2.84413 18.2322 2.19374 15.7356 2.35927 13.2252C2.52481 10.7148 3.49739 8.32523 5.13198 6.41277C6.76656 4.50031 8.97557 3.16746 11.4295 2.61302C13.8835 2.05858 16.451 2.31224 18.749 3.33618"
                        stroke="white"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M25.6667 4.66602L14 16.3443L10.5 12.8443"
                        stroke="white"
                        stroke-width="2.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </>
              )}
            </div>
          </div>

          <div
      className="w-[25%] hidden lg:flex"
      style={{ position: "relative" }}
    >
      <div className="w-full">
        <div className="flex flex-col gap-3 w-full">
          {data?.bids?.length > 0 && 
            data?.bids.slice(0,7).map((bid, index) => {
              const opacity = Math.max(0.2, 1 - (index * 0.2)); 
              console.log("here");
              return (
                <div
                  key={bid.bid_id}
                  className="relative flex justify-between items-center py-3 px-5 bg-white rounded-xl"
                  style={{
                    boxShadow: "0px 6px 15px 4px #0508280F",
                    opacity: opacity,
                    transition: 'opacity 0.3s' 
                  }}
                >
                  <div
                    className="absolute top-0 left-0 w-full h-full"
                    style={{
                      background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0))",
                      pointerEvents: "none", // To allow interaction with underlying elements
                      borderRadius: "inherit", // Match the parent's border-radius
                    }}
                  ></div>
                  <div className="flex items-center gap-1">
                    <span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.pexels.com/photos/1441151/pexels-photo-1441151.jpeg?auto=compress&cs=tinysrgb&w=600" // Replace with user avatar URL if available
                        alt="User Avatar"
                      />
                    </span>
                    <span className="text-sm">@{bid.user_name}</span>
                  </div>
                  <div className="text-sm font-bold tracking-wide">${bid.bid}</div>
                </div>
              );
            })}
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
