import React from "react";
import VendorProfile from "../../../components/Vendor-Shop-front/VendorProfile";

export default function VendorShopFronPage() {
  return (
    <div>
      <VendorProfile />
    </div>
  );
}
