import React from "react";
import "./Category-Shelf.css";

import ShelfCategorySlider from "./ShelfCategorySlider";

function CategoryShelf({ mainCategorySliderData }) {
  return (
    <div className="w-full">
      <div className="shelf w-full">
        <div className="flex gap-2 px-8 md:px-10 py-8 ">
          <ShelfCategorySlider mainCategorySliderData={mainCategorySliderData} />
        </div>
      </div>
    </div>
  );
}

export default CategoryShelf;
