import CheckoutPage from "../../pages/Main-Shop/Checkout-page/CheckoutPage";

const checkoutRoutes = [
  {
    path: "/checkout",
    element: <CheckoutPage />,
  },
];

export default checkoutRoutes;
