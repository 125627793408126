import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import "./reChart.css";

const BidderChart = ({ data }) => {
  if (data.length === 0) {
    return <div>No data available</div>; // Handle empty data case
  }

  // Find the minimum and maximum bid values
  const minValue = Math.min(...data.map(item => item.bid));
  const maxValue = Math.max(...data.map(item => item.bid));

  // Calculate a suitable interval
  const range = maxValue - minValue;
  const interval = Math.ceil(range / 5); // Adjust to have 5 intervals

  // Create ticks for Y-axis
  const ticks = [];
  for (let i = Math.floor(minValue / interval) * interval; i <= Math.ceil(maxValue / interval) * interval; i += interval) {
    ticks.push(i);
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data}>
        <XAxis 
          dataKey="user_name"
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 12 }} // Optional: Adjust tick font size
          angle={-30} // Optional: Rotate labels for better readability
          textAnchor="end"
        />
        <YAxis 
          axisLine={false}
          tickLine={false}
          orientation="right"
          ticks={ticks}
          tickFormatter={(value) => `$${value}`}
          domain={['auto', 'auto']} // Allow the Y-axis to adjust its domain automatically
        />
        <Tooltip formatter={(value) => `${value}`} />
        <Line
          type="monotone"
          dataKey="bid"
          stroke="#6A4C93"
          strokeWidth={4}
          dot={(props) => {
            const { cx, cy, payload } = props;
            return (
              <foreignObject x={cx - 25} y={cy - 25} width={50} height={50}>
                <div xmlns="http://www.w3.org/1999/xhtml">
                  <img
                    src={payload.user_image}
                    alt={payload.user_name}
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: "50%",
                      border: "2px solid #6A4C93",
                    }}
                  />
                </div>
              </foreignObject>
            );
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default BidderChart;
