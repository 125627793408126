import React, { useEffect, useState } from "react";
import { TfiLayoutGrid3Alt, TfiLayoutGrid4Alt } from "react-icons/tfi";
import { IoListSharp } from "react-icons/io5";
import AuctionPopularProductCard from "./AuctionPopularProductCard";
import { Link } from "react-router-dom";

export default function AuctionPopularProducts() {
  const [popularProducts, setPopularProducts] = useState([]);
  const [originalPopularProducts, setOriginalPopularProducts] = useState([]); // Keep the original order
  const [view, setView] = useState(5);
  const [isOpen, setIsOpen] = useState(false);
  const [sortType, setSortType] = useState("All");

  const handleView3 = () => setView(3);
  const handleView5 = () => setView(5);
  const handleView1 = () => setView(1);

  const handleSelection = (type) => {
    setSortType(type);
    setIsOpen(false);

    if (type === "Latest") {
      // Sort vehicles by timestamp in descending order
      const sortedPopularProducts = [...originalPopularProducts].sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setPopularProducts(sortedPopularProducts);
    } else {
      // Restore the original order for "All"
      setPopularProducts(originalPopularProducts);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from an API
        const response = await fetch("/AdStoreVehicles.json");
        const data = await response.json();
        setPopularProducts(data);
        setOriginalPopularProducts(data); // Store the original data as-is
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="px-4 lg:px-8 xl:px-[57px]">
        <div className="text-base breadcrumbs font-bold ">
          <ul>
            <li>
              <Link to="/Ad-martket-place/auction">Home</Link>
            </li>
            <li>
              <Link to="/Ad-martket-place/auction/popular-products">
                Popular Products
              </Link>
            </li>
          </ul>
        </div>

        {/* sorted and view */}
        <div className="flex my-5 items-center justify-between">
          <div className="relative inline-block">
            <button
              className="flex items-center px-1 justify-between py-2 w-44 h-10 border border-gray-200 rounded"
              onClick={toggleDropdown}
            >
              <span className="text-[20px] opacity-70">Sort by:</span>
              <span className="text-[20px]"> {sortType}</span>
              <svg
                className={`w-7 h-7 transform ${isOpen ? "rotate-180" : ""}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            {isOpen && (
              <div className="absolute z-10 mt-1 w-44 bg-white border rounded shadow-lg">
                <ul>
                  <li
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleSelection("All")}
                  >
                    All
                  </li>
                  <li
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleSelection("Latest")}
                  >
                    Latest
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div className="flex items-center gap-3">
            <div className="text-lg">View:</div>
            <div onClick={handleView3}>
              <TfiLayoutGrid3Alt
                className={`${
                  view === 3 ? "text-black" : "text-[#D9D9D9]"
                } h-7 w-7 cursor-pointer hidden md:block`}
              />
            </div>
            <div onClick={handleView5}>
              <TfiLayoutGrid4Alt
                className={`${
                  view === 5 ? "text-black" : "text-[#D9D9D9]"
                } h-8 w-8 cursor-pointer`}
              />
            </div>
            <div onClick={handleView1}>
              <IoListSharp
                className={`${
                  view === 1 ? "text-black" : "text-[#D9D9D9]"
                } h-10 w-10 cursor-pointer`}
              />
            </div>
          </div>
        </div>

        {/* Popular products */}
        <div
          className={`grid gap-4 ${
            view === 3
              ? "grid-cols-2 md:grid-cols-3"
              : view === 5
              ? "grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
              : `grid-cols-${view}`
          }`}
        >
          {popularProducts.map((item, index) => (
            <Link
              to="/Ad-martket-place/auction/single-product"
              className="flex justify-center"
            >
              <AuctionPopularProductCard key={index} item={item} view={view} />
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
