import React, { useState } from "react";
import { FaHeart } from "react-icons/fa";
// import "./AdStoreMap.css";

const AdStoreResidenceCard = ({ item, view, key }) => {
  const [isHeartFilled, setIsHeartFilled] = useState(false);

  const handleHeartClick = () => {
    setIsHeartFilled(!isHeartFilled);
  };

  return (
    <>
      <div
        key={key}
        className={`${
          view === 3
            ? "xl:w-[240px] xl:h-[354px] lg:w-[192px]   lg:h-[284px] md:w-[120px] md:h-[200px]  min-w-[170px] min-h-[250px] border border-gray-300  relative rounded-2xl"
            : "xl:w-[240px] xl:h-[354px] lg:w-[213px]   lg:h-[314px] md:w-[150px] md:h-[280px]  min-w-[170px] min-h-[250px] border border-gray-300  relative rounded-2xl "
        }`}
      >
        <div className="">
          <div className="">
            <div className=" overflow-hidden  rounded-2xl">
              <img
                src={item.img}
                className={`${
                  view === 3
                    ? "w-full xl:h-[282px] lg:h-[226px] md:h-[170px]    min-h-[199px] transition-transform duration-500 ease-in-out hover:scale-110"
                    : " w-full xl:h-[282px] lg:h-[250px] md:h-[220px]    min-h-[199px] transition-transform duration-500 ease-in-out hover:scale-110"
                }`}
                alt=""
              />
            </div>

            {item.badge.length > 0 && (
              <div className="absolute xl:text-base text-white text-[11px] xl:px-[22px] xl:py-[6px] px-4 py-1  top-2 left-2 bg-opacity-60 bg-black rounded-2xl">
                <p className=" text-white  z-10">{item.badge}</p>
              </div>
            )}
          </div>
          {/* .......................... */}
          <div className="p-1 lg:p-2">
            <h2 className="font-bold xl:text-base lg:text-sm text-[11px] ">
              {item.name}
            </h2>

            <div className=" flex justify-between items-center ">
              <div className="xl:text-xs lg:text-[10px] text-[8px] text-[#828282]">
                <p>{item.location}</p>
                <p>{item.date}</p>
              </div>
              <div>
                <h2 className="xl:text-[24px] md:text-[20px] text-[17px] font-bold ">
                  ${item.price}
                </h2>
              </div>
            </div>
          </div>
        </div>

        {/* .................................... */}
        <div className="absolute top-2 -right-3 w-12 h-12 items-center z-10">
          <div
            className={`rating gap-1 px-1 py-1 bg-[#1983FF33] rounded-full ${
              isHeartFilled
                ? "text-blue-500 duration-500 ease-in-out"
                : "text-white"
            }`}
            onClick={handleHeartClick}
          >
            <FaHeart className="h-5 w-5 cursor-pointer" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdStoreResidenceCard;
