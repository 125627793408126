import UpdateUserDashboardAffilliateUplaodBundlesPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Dashboard-Affilliate-page/UpdateUserDashboardAffilliateUplaodBundlesPage";
import UserDashboardAffilliateInreelsPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Dashboard-Affilliate-page/UserDashboardAffilliateInreelsPage";
import UserDashboardAffilliateUplaodBundlesPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Dashboard-Affilliate-page/UserDashboardAffilliateUplaodBundlesPage";
import UserDashboardAffilliateUplaodInreelsPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Dashboard-Affilliate-page/UserDashboardAffilliateUplaodInreelsPage";
import UserDashboardBundlesPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Dashboard-Affilliate-page/UserDashboardBundlesPage";
import UserDashboardMyAfflliateStorePage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Dashboard-Affilliate-page/UserDashboardMyAfflliateStorePage";
import UserdashboardAvailableAffilliateProductPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/User-Dashboard-Affilliate-page/UserdashboardAvailableAffilliateProductPage";

const affiliateRoutes = [
  {
    path: "/user-dashboard/affiliate-available-product",
    element: <UserdashboardAvailableAffilliateProductPage />,
  },
  {
    path: "/user-dashboard/my-affiliate-store",
    element: <UserDashboardMyAfflliateStorePage />,
  },

  {
    path: "/user-dashboard/affiliate-bundles",
    children: [
      {
        index: true,
        element: <UserDashboardBundlesPage />,
      },
      {
        path: "/user-dashboard/affiliate-bundles/upload-bundle",
        element: <UserDashboardAffilliateUplaodBundlesPage />,
      },
      {
        path: "/user-dashboard/affiliate-bundles/update-bundle/:id",
        element: <UpdateUserDashboardAffilliateUplaodBundlesPage />,
      },
    ],
  },
  {
    path: "/user-dashboard/affiliate-inReels",

    children: [
      {
        index: true,
        element: <UserDashboardAffilliateInreelsPage />,
      },
      {
        path: "/user-dashboard/affiliate-inReels/upload-inReels",
        element: <UserDashboardAffilliateUplaodInreelsPage />,
      },
    ],
  },
];

export default affiliateRoutes;
