import MainCategoryPage from "../../pages/Main-Shop/Main-Category-Page/MainCategoryPage";
import SubCategoryPage from "../../pages/Main-Shop/Main-Category-Page/SubCategoryPage";

const mainCategoriesRoutes = [
  {
    path: "/main-categories/:slug",
    element: <MainCategoryPage />,
  },
  {
    path: "/sub-category/:slug",
    element: <SubCategoryPage />,
  },
];

export default mainCategoriesRoutes;
