import React from "react";
import UserDashboardSellingLayout from "../../../Layout/User-Dashboard-layout/UserDashboardSellingLayout";
import UserDashboardCreateAuctionProduct from "../../../components/User-Dashboard-Components/User-Dashboard-Auction-Product-Page-Components/UserDashboardCreateAuctionProduct";

export default function UserDashboardSellingCreateAuctionProduct() {
  return (
    <>
      <UserDashboardSellingLayout>
        <UserDashboardCreateAuctionProduct />
      </UserDashboardSellingLayout>
    </>
  );
}
