import React, { useState } from "react";
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { formatNumber, stringConcat } from "../../../functions/formatNumber";

const AdStoreRelatedProductCard = ({ item }) => {
  // const [isHeartFilled, setIsHeartFilled] = useState(false);
  // const handleHeartClick = () => {
  //   setIsHeartFilled(!isHeartFilled);
  // };

  return (
    <>
    <Link
    to={`/ad-market-place/single-product/${item.slug}`}
    >

      <div className="border xl:w-[240px] xl:h-[356px] md:w-[213px] md:h-[314px] w-[170px] min-h-[251px] rounded-2xl">
        <div className="relative">
          <div className="relative overflow-hidden  rounded-2xl">
            <img
              src={item.image}
              className="w-full xl:h-[282px]  md:h-[250px]  min-h-[200px] transition-transform duration-500 ease-in-out hover:scale-110"
              alt=""
            />
          </div>
          {/* <div className="absolute top-2 -right-3 w-12 h-12 items-center z-10">
            <div
              className={`rating gap-1 px-1 py-1 bg-[#1983FF33] rounded-full ${
                isHeartFilled
                  ? "text-blue-500 duration-500 ease-in-out"
                  : "text-white"
              }`}
              onClick={handleHeartClick}
            >
              <FaHeart className="h-5 w-5 cursor-pointer" />
            </div>
            <p className="text-white ml-2 text-[10px]">{item.like}</p>
          </div> */}
        </div>

        <div className="px-3 mt-0 py-2">
          <h2 className="font-bold xl:text-base md:text-sm text-[11px]">
            {stringConcat(item.name,20)}
          </h2>
          <div className="flex justify-between">
            <div className="xl:text-xs md:text-[10px] text-[8px] opacity-70">
              <p>{stringConcat(item.state,8)},{stringConcat(item.country,8)}</p>
              <p>{item.formatted_created_at}</p>
            </div>
            <div>
              <h2 className="xl:text-[24px] md:text-[21px] text-[17px] font-bold ">
                {/* ${formatNumber(item.price)} */}
                ${item.price}
              </h2>
            </div>
          </div>
        </div>
      </div>
      </Link>
    </>
  );
};

export default AdStoreRelatedProductCard;
