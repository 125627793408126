import React, { useEffect, useState } from "react";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getCitiesByState, getCountrie, getStateByCountry, userAccountSetting, userProfileUpdate } from "../../../store/categorySlice";
import { toast } from "react-toastify";

export default function UserDashboardProfile() {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [isCountryDropdownVisible, setCountryDropdownVisible] = useState(false);
  const [isStateDropdownVisible, setStateDropdownVisible] = useState(false);
  const [isCityDropdownVisible, setCityDropdownVisible] = useState(false);
  const [info, setInfo] = useState(false);

  const dispatch = useDispatch();
  const { message, error, loading, profileSetting, countries, citiesByState, states} = useSelector((state) => state.category);

  const [data, setData] = useState({
    name: "",
    number: "",
    email: "",
    country_id: "",
    city_id: "",
    state_id: "",
    postal_code: "",
    address: "",
    description: "",
    tiktok: "",
    instagram: "",
    twitter: "",
    image : "",
  });

  useEffect(() => {
    if(profileSetting && profileSetting.user ){
       const user = profileSetting?.user;
       setData({
          name: user?.name || "",
          number: user?.number || "",
          email: user?.email || "",
          country_id: user?.country_id || "",
          city_id: user?.city_id || "",
          state_id: user?.state_id || "",
          postal_code: user?.postal_code || "",
          address: user?.address || "",
          description: user?.description || "",
          tiktok: user?.tiktok || "",
          instagram: user?.instagram || "",
          twitter: user?.twitter || "",
          image : user?.image || "",
        })
       setInfo({
         Name: user?.name || "",
         Number: user?.number || "",
         Email: user?.email || "",
         Country: user?.country?.name || "",
         City: user?.city?.name || "",
         Image : user?.image || "",
       })
       setSelectedCountry(user?.country?.name);
       setSelectedState(user?.state?.name);
       setSelectedCity(user?.city?.name);
       if(user?.country?.id) dispatch(getStateByCountry(user.country.id));
       if(user?.state?.id) dispatch(getCitiesByState(user.state.id));
      }
   }, [profileSetting])

   useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (message) {
      toast.success(message);
    }
    dispatch(clearMessage());
  }, [error,message]);

  useEffect(() => {
    dispatch(userAccountSetting())
    dispatch(getCountrie())
  }, []);

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const toggleCountryDropdown = () => {
    setCountryDropdownVisible((prev) => !prev);
    setCityDropdownVisible(false);
  };

  const toggleCityDropdown = () => {
    setCityDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
  };

  const toggleStateDropdown = () => {
    setStateDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
  };

  const selectCountry = (country) => {
    setSelectedCountry(country.name);
    setData({
      ...data,
      country_id: country.id,state_id: "",city_id: ""
    });
    setCountryDropdownVisible(false);
    dispatch(getStateByCountry(country.id))

    setSelectedState("");
    setSelectedCity("");
  };

  const selectState = (state) => {
    setSelectedState(state.name);
    setData({
      ...data,
      state_id: state.id,city_id: ""
    });
    setStateDropdownVisible(false);
    dispatch(getCitiesByState(state.id))
    setSelectedCity("");
    };

  const selectCity = (city) => {
    setSelectedCity(city.name);
    setData({
      ...data,
      city_id: city.id,
    });
    setCityDropdownVisible(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (value == "") {
      } else {
        formData.append(key, value);
      }
    }
    formData.append("_method", "PUT");
    dispatch(userProfileUpdate(formData));
  };

  return (
    <>
      <form
        id="uploadForm"
        enctype="multipart/form-data"
        method="post"
        onSubmit={handleSubmit}
      >
        <div className="px-4 flex flex-col gap-2">
          {/* head */}
          <div className="flex justify-between ">
            <BreadCrums
              breadCrum={[
                {
                  name: "Profile",
                  path: "",
                },
              ]}
            />
            <div>
              <button type="submit"
                disabled={loading}
                className="px-5 py-2 md:px-9 md:py-[10px] text-[10px] h-12 md:text-base  text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg">
                {!loading ? (
                  "Update"
                ) : (
                  <span className="loading loading-spinner loading-md"></span>
                )}
              </button>
            </div>
          </div>

          {/* Profile Section */}
          <div className="flex flex-col md:flex-row gap-6">
            {/* Left */}
            <div className="w-full md:w-[35%] flex flex-col gap-4 flex-1">
              <div className=" border border-[#EBF0ED] rounded-lg p-3">
                <div className="flex items-center gap-2 mb-4">
                  <div className="relative">
                    <img
                      className="w-14 h-14"
                      src={info?.Image? info.Image : ""}
                      alt=""
                    />
                    <div className="absolute flex items-center justify-center rounded-full bottom-0 right-0 bg-[#C19A6B] w-4 h-4">
                      <svg
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.98389 0.204598H5.955C6.29153 0.204598 6.57998 0.428951 6.67613 0.733431L6.85241 1.23021H8.0543C8.61519 1.23021 9.07992 1.69494 9.07992 2.25583V6.35829C9.07992 6.93519 8.61519 7.3839 8.0543 7.3839H1.90061C1.32371 7.3839 0.875 6.93519 0.875 6.35829V2.25583C0.875 1.69494 1.32371 1.23021 1.90061 1.23021H3.08648L3.26276 0.733431C3.35891 0.428951 3.64736 0.204598 3.98389 0.204598ZM4.97746 5.84548C5.8268 5.84548 6.51588 5.15639 6.51588 4.30706C6.51588 3.45772 5.8268 2.76863 4.97746 2.76863C4.1121 2.76863 3.43904 3.45772 3.43904 4.30706C3.43904 5.15639 4.1121 5.84548 4.97746 5.84548Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  <div>
                    <h2 className="font-semibold text-base text-[#18120F]">
                    {info?.Name}
                    </h2>
                    <h2 className="text-[12px] font-semibold text-[#6B6B6B]">
                      CUSTOMER
                    </h2>
                  </div>
                </div>
                <div>
                  <div className="flex justify-between my-2">
                    <p className="text-[#6B6B6B] text-[12px] font-semibold">
                      FULL NAME :
                    </p>
                    <p className="float-end text-[12px] font-semibold text-[#18120F]">
                    {info?.Name}
                    </p>
                  </div>
                  <hr />
                  <div className="flex justify-between my-2">
                    <p className="text-[#6B6B6B] text-[12px] font-semibold">
                      MOBILE :
                    </p>
                    <p className="float-end text-[12px] font-semibold text-[#18120F]">
                    {info?.Number}
                    </p>
                  </div>
                  <hr />
                  <div className="flex justify-between my-2">
                    <p className="text-[#6B6B6B] text-[12px] font-semibold">
                      E-MAIL :
                    </p>
                    <p className="float-end text-[12px] font-semibold text-[#18120F]">
                    {info?.Email}
                    </p>
                  </div>
                  <hr />
                  <div className="flex justify-between my-2">
                    <p className="text-[#6B6B6B] text-[12px] font-semibold">
                      LOCATION :
                    </p>
                    <p className="float-end text-[12px] font-semibold text-[#18120F]">
                    {info?.City+", "+info?.Country}
                    </p>
                  </div>
                </div>
              </div>

              {/* Social Accounts */}
              <div>
                <h1 className="text-[#18120F] text-base md:text-lg font-semibold">
                  Social Accounts
                </h1>
                <div className=" border border-[#EBF0ED] rounded-lg p-3">
                  <div className="flex flex-col ga-2">
                    <label
                      className="text-[#6B6B6B] text-xs ml-1 mb-1 font-semibold"
                      htmlFor="instagram"
                    >
                      INSTAGRAM
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg  py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      name="instagram"
                      value={data.instagram}
                      onChange={updateData}
                      placeholder="Enter Your Username"
                    />
                  </div>
                  <div className="flex flex-col ga-2">
                    <label
                      className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold"
                      htmlFor="tiktok"
                    >
                      TIKTOK
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg  py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      name="tiktok"
                      value={data.tiktok}
                      onChange={updateData}
                      placeholder="Enter Your Username"
                    />
                  </div>
                  <div className="flex flex-col ga-2">
                    <label
                      className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold"
                      htmlFor="twitter"
                    >
                      TWITTER
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg  py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      name="twitter"
                      value={data.twitter}
                      onChange={updateData}
                      placeholder="Enter Your Username"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Right */}
            <div className="w-full md:w-[65%] flex flex-col gap-5 flex-2">
              {/* Edit Profile Section */}
              <div>
                <h1 className=" text-[#18120F] text-base md:text-lg font-semibold">
                  Edit Profile
                </h1>
                <div className="w-full border border-[#EBF0ED] rounded-lg p-3">
                  {/* Name Inputs */}
                  <div className="flex flex-col md:flex-row items-center gap-2">
                    <div className="flex w-full flex-col md:gap-2">
                      <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                        FIRST NAME
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                        type="text"
                        placeholder="Enter Your First Name"
                        name="name"
                        value={data.name}
                        onChange={updateData}
                        />
                    </div>
                  </div>

                  {/* Contact Inputs */}
                  <div className="flex flex-col md:flex-row items-center gap-2 my-2">
                    <div className="flex w-full flex-col md:gap-2">
                      <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                        PHONE NUMBER
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                        type="number"
                        placeholder="Enter Your Phone Number"
                        name="number"
                        value={data.number}
                        onChange={updateData}
                        />
                    </div>
                    <div className="flex w-full flex-col md:gap-2">
                      <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                        EMAIL ADDRESS
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                        type="email"
                        placeholder="Enter Your Email"
                        name="email"
                        value={data.email}
                        onChange={updateData}
                        />
                    </div>
                  </div>

                  {/* Country, City, Postal Code Inputs */}
                  <div className="relative flex flex-col md:flex-row gap-2 items-center ">

                      {/* Country */}
                      <div className="w-full">
                        <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                          COUNTRY
                        </label>
                        <div className="relative w-full">
                          <input
                            className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                            type="text"
                            value={selectedCountry}
                            readOnly
                            onClick={toggleCountryDropdown}
                            placeholder="Select Your Country"
                          />
                          <svg
                            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                            onClick={toggleCountryDropdown}
                            width="11"
                            height="6"
                            viewBox="0 0 11 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742"
                              fill="#6B6B6B"
                            />
                          </svg>

                          {isCountryDropdownVisible && (
                            <div className="absolute z-10 bg-white border border-[#EBF0ED] h-fit overflow-hidden  rounded-lg mt-2 w-full">
                              {countries?.map((country) => (
                                <div
                                  key={country.id}
                                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                  onClick={() => selectCountry(country)}
                                >
                                  {country.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* state */}
                      <div className="w-full">
                        <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                          STATE
                        </label>
                        <div className="relative w-full">
                          <input
                            className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                            type="text"
                            value={selectedState}
                            readOnly
                            onClick={toggleStateDropdown}
                            placeholder="Select Your State"
                          />
                          <svg
                            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                            onClick={toggleStateDropdown}
                            width="11"
                            height="6"
                            viewBox="0 0 11 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742"
                              fill="#6B6B6B"
                            />
                          </svg>

                          {isStateDropdownVisible && (
                            <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                              {states?.map((state) => (
                                <div
                                  key={state.id}
                                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                  onClick={() => selectState(state)}
                                >
                                  {state.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* City */}
                      <div className="w-full">
                        <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                          CITY
                        </label>
                        <div className="relative w-full">
                          <input
                            className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                            type="text"
                            value={selectedCity}
                            readOnly
                            onClick={toggleCityDropdown}
                            placeholder="Select Your City"
                          />
                          <svg
                            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                            onClick={toggleCityDropdown}
                            width="11"
                            height="6"
                            viewBox="0 0 11 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742"
                              fill="#6B6B6B"
                            />
                          </svg>

                          {isCityDropdownVisible && (
                            <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                              {citiesByState?.map((city) => (
                                <div
                                  key={city.id}
                                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                  onClick={() => selectCity(city)}
                                >
                                  {city.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>

                  </div>

                  {/* Postal Code */}
                  <div className="flex w-full flex-col md:flex-row md:gap-2 md:my-2">
                    <div className="w-full">
                      <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                        POSTAL CODE
                      </label>
                      <div className="relative w-full">
                        <input
                          className="bg-[#FAFAFA] cursor-pointer text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                          type="number"
                          name="postal_code"
                          value={data.postal_code}
                          onChange={updateData}
                          placeholder="Select Your Postal Code"
                        />
                      </div>
                    </div>

                    <div className="flex w-full flex-col md:gap-2">
                      <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                        ADDRESS
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none "
                        type="text"
                        placeholder="Enter Your Address"
                        name="address"
                        value={data.address}
                        onChange={updateData}
                        />
                    </div>
                  </div>
                  <div className="flex w-full flex-col md:gap-2">
                    <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                      ABOUT ME
                    </label>
                    <textarea
                      className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none "
                      type="text"
                      placeholder="Text"
                      name="description"
                      value={data.description}
                      onChange={updateData}
                    />
                  </div>
                </div>
              </div>

              {/* Change Password Section */}
              <div className="w-full items-center">
                <h1 className="text-[#18120F] md:text-lg font-semibold">
                  Change Password
                </h1>
                <div className=" border border-[#EBF0ED] rounded-lg p-3">
                  {/* Current and New Password Inputs */}
                  <div className="  flex flex-col md:flex-row items-center md:gap-2">
                    <div className=" w-full flex flex-col md:gap-2">
                      <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                        CURRENT PASSWORD
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                        type="password"
                        placeholder="Current Password"
                      />
                    </div>
                    <div className="w-full flex flex-col md:gap-2">
                      <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                        NEW PASSWORD
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                        type="password"
                        placeholder="New Password"
                      />
                    </div>
                  </div>

                  {/* Repeat Password */}
                  <div className=" flex items-center gap-2 my-2">
                    <div className="w-full lg:w-1/2 flex flex-col md:gap-2">
                      <label className="text-[#6B6B6B] text-[12px] ml-1 mb-1 font-semibold">
                        REPEAT PASSWORD
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                        type="password"
                        placeholder="Repeat Password"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
