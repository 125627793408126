import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, clearMessage, resetSignout } from "../store/categorySlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, step, token, loading, message, error } = useSelector(
    (state) => state.category
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleLogin = () => {
    if (email === "" && password === "") {
      toast.error("Please fill all fields");
    } else {
      dispatch(loginUser({ email, password }));
    }
  };

  useEffect(() => {
    if (!loading) {
      if (message) {
        toast.success(message);
        if(step==1){
          navigate("/dashboard/otp-verification");
        } else {
          navigate("/user-dashboard");
        }
      }
      if (error) {
        toast.error(error);
      }
      dispatch(clearMessage());
    }
    dispatch(resetSignout());
  }, [loading, message, error, dispatch]);

  // for password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

    // useEffect(() => {

    //   localStorage.removeItem('token')

    // }, [])

  return (
    <div className="h-[100vh] flex justify-center items-center px-4 md:px-0 bg-[#FFFAF4]">
      <div
        className="w-full md:w-fit p-4 md:p-14 bg-white flex flex-col items-center rounded-3xl"
        style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
      >
        <img className="mx-auto" src="/assets/images/login-logo.svg" alt="" />
        <div className="font-bold tracking-wide text-xl md:text-3xl mt-5 mb-2 text-center">
          Login to Account
        </div>
        <div className="text-xs md:text-sm text-center font-bold tracking-wide">
          Login to your Wizalot account!
        </div>
        <div className="w-full md:w-[30rem] mt-6">
          <label className="form-control ">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter Your Email Address
              </span>
            </div>
            <input
              type="email"
              placeholder="mike.tyson@mail.com"
              className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label className="form-control mt-3">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter Your Password
              </span>
            </div>
            <span className="flex items-center relative">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="********"
                className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordVisible ? (
                <IoEyeOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <IoEyeOffOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              )}
            </span>
          </label>
          <p onClick={()=>navigate("/dashboard/forgot-password")} className="text-[#C19A6B] hover:underline text-end mt-1 font-bold tracking-wide cursor-pointer">
            Forgot Password?
          </p>

            <button
              disabled={loading}
              onClick={handleLogin}
              className="bg-[#C19A6B] w-full rounded-lg py-3 mt-[1.5rem] h-12 text-sm md:text-base text-white font-bold tracking-wider"
            >
                {!loading ? (
                  "Login"
                ) : (
                  <span className="loading loading-spinner loading-md"></span>
                )}
            </button>
        </div>
        <p className="mt-3">
          Don't have an account? <span onClick={()=>navigate("/dashboard/signup")} className="text-[#C19A6B] hover:font-bold cursor-pointer">Sign Up</span>
        </p>
      </div>
    </div>
  );
}
