import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTicket, clearMessage, getTicketCategories } from "../../../store/categorySlice";

export default function TicketForm() {
  const { loading, data,message, error } = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    ticket_category_id:"",
    ticket_headline: "",
    upload_attachment: "",
    ticket_summary: "",
  });

  useEffect(() =>{
    dispatch(getTicketCategories());
  },[])

  const handleSubmit = () => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      if (!value) return toast.error("Fill All Fields Correctly");
      formData.append(key, value);
    }
    dispatch(addTicket(formData));
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      setPayload({
        name: "",
        email: "",
        ticket_headline: "",
        upload_attachment: "",
        ticket_summary: "",
      });
      navigate(-1);
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [error, message]);

  return (
    <>
      <div className="px-4">
        <div className="flex items-center justify-between">
          <BreadCrums
            breadCrum={[
              {
                name: "Seller Support",
                path: "/user-dashboard/help-center",
              },
              {
                name: "Tickets",
                path: "/user-dashboard/tickets",
              },
              {
                name: "Ticket Form",
                path: "",
              },
            ]}
          />
          <button disabled={loading} onClick={handleSubmit} className="bg-[#C19A6B] h-12 px-5 py-2 md:px-9 md:py-[10px] text-xs xl:text-sm rounded-lg text-white">
              {!loading ? (
                  "Save"
                ) : (
                  <span className="loading loading-spinner loading-md"></span>
                )}
          </button>
        </div>

        {/* Profile Section */}
        <div className="flex gap-6 mt-5">
          {/* Right */}
          <div className="w-full flex flex-col gap-5 flex-2">
            <div className="w-full border border-[#EBF0ED] rounded-lg p-3">
              <h1 className=" text-[#18120F] text-sm md:text-lg font-semibold">
                Ticket Form
              </h1>
              {/* Name Inputs */}
              <div className="flex flex-col md:flex-row items-center gap-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    Full Name
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full p-2 md:p-3 px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Full Name"
                    value={payload.name}
                    onChange={(e) => {
                      setPayload({ ...payload, name: e.target.value });
                    }}
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    Email
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full p-2 md:p-3 px-2 border border-[#EBF0ED]   outline-none "
                    type="email"
                    placeholder="test@gmail.com"
                    value={payload.email}
                    onChange={(e) => {
                      setPayload({ ...payload, email: e.target.value });
                    }}
                  />
                </div>
              </div>

              {/* Contact Inputs */}
              <div className="flex items-center gap-2 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    Ticket Headline
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full p-2 md:p-3 px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter a short headline for your ticket"
                    value={payload.ticket_headline}
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        ticket_headline: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-2 items-center ">
                <div className="flex flex-col w-1/2 items-start">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    Ticket Category
                  </label>
                  <div className="relative w-full">
                    <select
                      name=""
                      id=""
                      value={payload.ticket_category_id}
                      onChange={(e) =>
                        setPayload({...payload,ticket_category_id:e.target.value})
                      }
                      className="bg-[#FAFAFA] placeholder:text-black rounded-lg w-full py-2 px-3 outline-none cursor-pointer"
                    >
                      <option value="">none</option>
                      {data?.categories?.length > 0 &&
                        data.categories.map((item) => {
                          return <option value={item.id}>{item.label}</option>;
                        })}
                    </select>
                  </div>
                </div>

                <div className="w-1/2">
                  <label
                    className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold"
                    for="upload"
                  >
                    <input
                      type="file"
                      id="upload"
                      className="hidden w-full h-full"
                      onChange={(e) => {
                        setPayload({
                          ...payload,
                          upload_attachment: e.target.files[0],
                        });
                      }}
                    />
                    Upload Attachment
                    <div className="bg-[#FAFAFA] flex items-center justify-between px-3 text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px]  border border-[#EBF0ED] ">
                      <span>
                        {payload.upload_attachment
                          ? "Choose Another File"
                          : "No File Choosen"}
                      </span>
                      <span className="cursor-pointer text-xs font-semibold text-[#C19A6B]">
                        {payload.upload_attachment
                          ? "File Uploaded Successfully"
                          : "Choose File"}
                      </span>
                    </div>
                  </label>
                </div>
              </div>

            <div className="flex w-full flex-col ga-2 pt-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                Ticket Summary
              </label>
              <textarea
                className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[80px] md:h-[146px] px-2 border border-[#EBF0ED] outline-none "
                type="text"
                placeholder="Text"
                value={payload.ticket_summary}
                onChange={(e) => {
                  setPayload({ ...payload, ticket_summary: e.target.value });
                }}
              />
            </div>

            </div>
          </div>
        </div>
      </div>
    {/* </div> */}
    </>
  );
}
