import React from 'react'
import CartProducts from '../../../components/Cart-Page-Components/CartProducts'

export default function CartPage() {
    return (
        <>
            <CartProducts />
        </>
    )
}
