import React, { useEffect } from "react";
import { useState } from "react";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import LocationModal from "./LocationModal";
import StoreTypeModal from "./StoreTypeModal";
import StyleTypeModal from "./StyleTypeModal";
import CategoryModal from "./CategoryModal";
import BubblesModal from "../Home-Bubble-Section/BubblesModal";
import { useDispatch, useSelector } from "react-redux";
import { getCountries, getStoreSquare, getStoreStyle, getStoreType } from "../../../../store/frontendSlice";
import { getCategory } from "../../../../store/categorySlice";

export default function StoreSquareFilter() {
  const [location, setLocation] = useState(false);
  const [store, setStore] = useState(false);
  const [style, setStyle] = useState(false);
  const [categoryy, setCategoryy] = useState(false);
 
  const dispatch = useDispatch()
  const { countries,storeStyle,storeType } = useSelector(state => state.frontend);
  const { category } = useSelector(state => state.category);
  const [url, setUrl] = useState(``);
  const [selectedFilters, setSelectedFilters] = useState({
    country: null,
    storetype: null,
    storestyle: null,
    category: null,
    search: null,
  });

  const handleCheckBox = (filter, value) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = { ...prevFilters };

      if (filter === "country") {
        // setSelectedSort(value)
        newFilters[filter] = value;
      } else if (filter === "storetype") {
        // setSelectedPrice(value)
        newFilters[filter] = value;
      } else if (filter === "storeStyle") {
        // setSelectedLocation(value)
        newFilters[filter] = value;
      } else if (filter === "category") {
        // setSelectedLocation(value)
        newFilters[filter] = value;
      }

      return newFilters;
    });

    // Update URL string
    setUrl((prevUrl) => {
      let newURL = prevUrl;
      const regex = new RegExp(`[&?]${filter}=[^&]*`, "g");

      if (value === null || value === "") {
        newURL = newURL.replace(regex, "");
        // Clean up any trailing '?' or '&' from the URL
        newURL = newURL.replace(/[&?]$/, "").replace(/[&?]{2,}/g, "&");
      } else {
        // Otherwise, proceed with adding/replacing the filter
        newURL = newURL.replace(regex, "");
        newURL += `&${filter}=${value}`;
      }

      newURL = newURL.replace(/%20|\s+/g, "");
      newURL = newURL.toLowerCase();
      newURL = newURL.replace(newURL[0], "?");

      return newURL;
    });
  };
  
  useEffect(()=>{
    dispatch(getStoreSquare(url))
    console.log(url);
  },[url])

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getStoreStyle());
    dispatch(getStoreType());
    dispatch(getCategory());
  }, [])

  useEffect(() => {
    if(location){
      setCategoryy(false);
      setStore(false);
      setStyle(false);
    }
    if(categoryy){
      setLocation(false);
      setStore(false);
      setStyle(false);
    }
    if(style){
      setLocation(false);
      setCategoryy(false);
      setStore(false);
    }
    if(store){
      setLocation(false);
      setCategoryy(false);
      setStyle(false);
    }
  }, [location,store,style,categoryy])

  const closeAll = () => {
    setLocation(false);
    setCategoryy(false);
    setStyle(false);
    setStore(false);
  }

  return (
    <div onMouseLeave={closeAll}>
      <div className="px-4  lg:px-[40px] xl:px-[57px] relative">
        <div className="p-4 xl:py-[30px] xl:pl-[40px] md:py-[21px] md:pl-[28px]  flex flex-col md:flex-row items-center xl:gap-6 gap-4  bg-[#E23B87] bg-opacity-[30%] rounded-2xl">
          <div className="bg-white p-4 xl:py-[28px] md:py-[16px] xl:px-[64px] text-[11px] md:text-[9px] lg:text-base w-full md:w-[560px] xl:w-[783px] rounded-2xl md:rounded-full  grid grid-cols-2 md:grid-cols-4 gap-x-[53px] md:gap-x-[32px] gap-y-[20px] xl:gap-[53px]">
            <div
              onMouseEnter={() => setLocation(true)}
              // onMouseLeave={() => setLocation(false)}
              onClick={() => setLocation(!location)}
              className="flex   justify-between md:justify-center  w-full cursor-pointer items-center gap-3 text-[#828282]"
            >
              <span>{selectedFilters.country?selectedFilters.country:"Location"}</span>
              <GoChevronDown />
            </div>
            <div
              onMouseEnter={() => setStore(true)}
              // onMouseLeave={() => setStore(false)}
              onClick={() => setStore(!store)}
              className="flex w-full justify-between md:justify-center items-center gap-3 text-[#828282] cursor-pointer"
            >
              <span className="text-nowrap">{selectedFilters.storetype?selectedFilters.storetype:"Store Type"}</span>
              <GoChevronDown />
            </div>{" "}
            <div
              onMouseEnter={() => setStyle(true)}
              // onMouseLeave={() => setStyle(false)}
              onClick={() => setStyle(!style)}
              className="flex justify-between md:justify-center items-center gap-3 text-[#828282] cursor-pointer"
            >
              <span className="text-nowrap">{selectedFilters.storestyle?selectedFilters.storestyle:"Style Style"}</span>
              <GoChevronDown />
            </div>{" "}
            <div
              onMouseEnter={() => setCategoryy(true)}
              // onMouseLeave={() => setCategoryy(false)}
              onClick={() => setCategoryy(!categoryy)}
              className="flex justify-between md:justify-center items-center gap-3 text-[#828282] cursor-pointer"
            >
              <span>{selectedFilters.category?selectedFilters.category:"Category"}</span>
              <GoChevronDown />
            </div>{" "}
          </div>
          <div className="flex justify-between  items-center gap-12  md:gap-2 lg:gap-6 xl:gap-4">
            <div className="relative w-[259px] md:w-[222px]  xl:w-[311px]">
              <input
                className="w-full py-[18px] xl:py-[28px] md:py-[10px] lg:py-[16px] px-[60px] md:px-[40px] xl:px-[60px] text-[11px]  lg:text-base  outline-none  bg-white rounded-full font-normal text-[#828282]"
                placeholder="search..."
                type="search"
                value={selectedFilters.search}
                onChange={(e) => handleCheckBox("search",e.target.value)}
              />
              <img
                className="absolute left-8 md:left-4 xl:left-11 top-[41%]  text-black"
                src="/assets/icons/searchsqa.svg"
                alt=""
              />
            </div>
            <div className="rounded-full bg-[#E13885] border-2 border-[#FFFFFF] flex items-center justify-center p-[17px] md:p-[14px] xl:p-[25px] cursor-pointer">
              <img
                className="w-[14px] h-[14px] md:w-[10px] md:h-[10px] xl:w-[20px] xl:h-[20px]"
                src="/assets/icons/squareSearch.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          onClick={() => document.getElementById("my_modal_104").showModal()}
          className="hidden   absolute lg:right-1 xl:right-4 lg:top-[36%] xl:top-[38%] bg-white lg:flex items-center gap-[2px] rounded-xl px-[10px] py-[8.5px] cursor-pointer"
        >
          <div className="w-4 h-4 rounded-full bg-[#6FB4A9]"></div>
          <div className="w-4 h-4 rounded-full bg-[#9980B7]"></div>
          <div className="w-4 h-4 rounded-full bg-[#E13784]"></div>
        </div>
      </div>

      {/* location modal */}
      <LocationModal setFunction={handleCheckBox} countries={countries} location={location} setLocation={setLocation} />
      {/* store modal */}
      <StoreTypeModal setFunction={handleCheckBox} storeType={storeType} store={store} setStore={setStore} />
      {/* style type modal */}
      <StyleTypeModal setFunction={handleCheckBox} storeStyle={storeStyle} style={style} setStyle={setStyle} />
      {/* category modal */}
      <CategoryModal setFunction={handleCheckBox} categories={category} category={categoryy} setCategory={setCategoryy} />

      <BubblesModal />
    </div>
  );
}
