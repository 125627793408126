import React from "react";
import UserDashboardSellingLayout from "../../../Layout/User-Dashboard-layout/UserDashboardSellingLayout";

import UserDashboardAdMarketplaceEditProduct from "../../../components/User-Dashboard-Components/User-Dashboard-Ad-Marketplace-Product-Components/UserDashboardAdMarketplaceEditProduct";

export default function UserDashboardSellingPageEditAdmarketplace() {
  return (
    <>
      <UserDashboardSellingLayout>
        <UserDashboardAdMarketplaceEditProduct />
      </UserDashboardSellingLayout>
    </>
  );
}
