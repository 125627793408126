import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../store/categorySlice";
import { toast } from "react-toastify";

export default function Sidebar({ toggleSidebar }) {
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.category);
  const navigate = useNavigate()

  const handleLogout = (e) =>{
    e.preventDefault();
    dispatch(logout());
  };

  const handleChat = () => {
    if(token){
      const tok = token;
      // const url = `${process.env.REACT_APP_CHAT_LINK}${token}`;
      const url = `https://messenger.yugoiptv.com/t/${tok}`;
      // window.open(url, '_blank', 'noopener,noreferrer');
      window.location.href = url;
    } else {
      toast.error("Login First")
      navigate('/dashboard/login')
    }
  }

  const handleRedi = (a) => {
    if(token){
      if(a=="a") navigate("/user-dashboard/ad-market-place");
      if(a=="b") navigate("/user-dashboard/reviews");
      if(a=="c") navigate("/user-dashboard/ad-market-place");
    } else {
      toast.error("Login First")
      navigate('/dashboard/login')
    }
  }

  return (
    <>
      <div className={` bg-white w-full  text-black`}>
        <div className=" flex flex-col items-start  py-3 w-full px-2">
          <div className=" flex flex-col font-bold w-full">
            <div className="flex gap-2 items-center py-2 ">
              <span>
                <img
                  src="/assets/icons/my-shelf.svg"
                  alt=""
                  className="w-6 h-6"
                />
              </span>
              <Link to="/my-shelf" className="font-semibold text-base">
                My Shelf
              </Link>
            </div>
            <div className="flex gap-2 items-center py-2">
              <span>
                <img
                  src="/assets/icons/explore.svg"
                  alt=""
                  className="w-6 h-6"
                />
              </span>
              <Link to="/search" className="font-semibold text-base">Explore</Link>
            </div>
            <div className="flex gap-2 items-center py-2">
              <span>
                <img
                  src="/assets/icons/trending.svg"
                  alt=""
                  className="w-6 h-6"
                />{" "}
              </span>
              <Link to="/trending" className="font-semibold text-base">Trending</Link>
            </div>
            <div className="flex gap-2 items-center py-2">
              <span>
                <svg
                  className="w-6 h-6"
                  viewBox="0 0 13 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.70309 15.6604H4.12992C4.04608 15.6604 3.97748 15.7274 3.97748 15.8092V16.4044C3.97748 16.7337 4.24996 16.9997 4.58724 16.9997H8.24577C8.58304 16.9997 8.85553 16.7337 8.85553 16.4044V15.8092C8.85553 15.7274 8.78693 15.6604 8.70309 15.6604ZM6.4165 0.333008C2.96567 0.333008 0.166504 3.06552 0.166504 6.4342C0.166504 8.69238 1.42413 10.6641 3.2915 11.7188V13.8747C3.2915 14.2039 3.56399 14.4699 3.90126 14.4699H8.93175C9.26902 14.4699 9.5415 14.2039 9.5415 13.8747V11.7188C11.4089 10.6641 12.6665 8.69238 12.6665 6.4342C12.6665 3.06552 9.86734 0.333008 6.4165 0.333008ZM8.85362 10.5599L8.16955 10.9468V13.1306H4.66346V10.9468L3.97939 10.5599C2.47977 9.71359 1.53846 8.15481 1.53846 6.4342C1.53846 3.80399 3.72214 1.67229 6.4165 1.67229C9.11086 1.67229 11.2946 3.80399 11.2946 6.4342C11.2946 8.15481 10.3532 9.71359 8.85362 10.5599Z"
                    fill="black"
                  />
                </svg>
              </span>
              <Link to="/inspiration" className="font-semibold text-base">
                Inspiration
              </Link>
            </div>
            <div className="flex gap-2 items-center py-2">
              <span>
                <img src="/assets/icons/chat.svg" alt="" className="w-6 h-6" />
              </span>
              <span onClick={handleChat} className="font-semibold text-base cursor-pointer">Chat</span>
            </div>
            <div className="flex gap-2 items-center py-2">
              <span>
                <img
                  src="/assets/icons/my-shelf.svg"
                  alt=""
                  className="w-6 h-6"
                />
              </span>
              <Link to="/search" className="font-semibold text-base">Coupon</Link>
            </div>
          </div>
        </div>

        <div className=" border-3 border-b"></div>

        <div className=" flex flex-col items-start w-full ">
          <div className="py-5  w-full px-2">
            <div className="text-[#BEBEBE] text-center text-lg">
              {" "}
              Quick Action
            </div>
            <div className=" flex flex-col ">
              {/* <div className="flex gap-2 cursor-pointer items-center py-2">
                <span>
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.99984 0.373047C4.39749 0.373047 0.666504 4.10404 0.666504 8.70638C0.666504 13.309 4.39749 17.0397 8.99984 17.0397C13.6024 17.0397 17.3332 13.309 17.3332 8.70638C17.3332 4.10404 13.6024 0.373047 8.99984 0.373047ZM8.99984 16.0145C4.97926 16.0145 1.70817 12.727 1.70817 8.70635C1.70817 4.68577 4.97926 1.41468 8.99984 1.41468C13.0204 1.41468 16.2915 4.68579 16.2915 8.70635C16.2915 12.7269 13.0204 16.0145 8.99984 16.0145ZM12.6457 8.18555H9.52067V5.06055C9.52067 4.77305 9.28734 4.53971 8.99984 4.53971C8.71234 4.53971 8.479 4.77305 8.479 5.06055V8.18555H5.354C5.0665 8.18555 4.83317 8.41888 4.83317 8.70638C4.83317 8.99388 5.0665 9.22721 5.354 9.22721H8.479V12.3522C8.479 12.6397 8.71234 12.873 8.99984 12.873C9.28734 12.873 9.52067 12.6397 9.52067 12.3522V9.22721H12.6457C12.9332 9.22721 13.1665 8.99388 13.1665 8.70638C13.1665 8.41888 12.9332 8.18555 12.6457 8.18555Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <span onClick={() => handleRedi("a")} className="text-sm font-semibold">
                  Request for Product
                </span>
              </div> */}
              <div className="flex gap-2 cursor-pointer items-center py-2">
                <span>
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.99984 0.373047C4.39749 0.373047 0.666504 4.10404 0.666504 8.70638C0.666504 13.309 4.39749 17.0397 8.99984 17.0397C13.6024 17.0397 17.3332 13.309 17.3332 8.70638C17.3332 4.10404 13.6024 0.373047 8.99984 0.373047ZM8.99984 16.0145C4.97926 16.0145 1.70817 12.727 1.70817 8.70635C1.70817 4.68577 4.97926 1.41468 8.99984 1.41468C13.0204 1.41468 16.2915 4.68579 16.2915 8.70635C16.2915 12.7269 13.0204 16.0145 8.99984 16.0145ZM12.6457 8.18555H9.52067V5.06055C9.52067 4.77305 9.28734 4.53971 8.99984 4.53971C8.71234 4.53971 8.479 4.77305 8.479 5.06055V8.18555H5.354C5.0665 8.18555 4.83317 8.41888 4.83317 8.70638C4.83317 8.99388 5.0665 9.22721 5.354 9.22721H8.479V12.3522C8.479 12.6397 8.71234 12.873 8.99984 12.873C9.28734 12.873 9.52067 12.6397 9.52067 12.3522V9.22721H12.6457C12.9332 9.22721 13.1665 8.99388 13.1665 8.70638C13.1665 8.41888 12.9332 8.18555 12.6457 8.18555Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <span onClick={() => handleRedi("b")} className="text-sm font-semibold">Review</span>
              </div>
              <div className="flex gap-2 cursor-pointer items-center py-2">
                <span>
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.99984 0.373047C4.39749 0.373047 0.666504 4.10404 0.666504 8.70638C0.666504 13.309 4.39749 17.0397 8.99984 17.0397C13.6024 17.0397 17.3332 13.309 17.3332 8.70638C17.3332 4.10404 13.6024 0.373047 8.99984 0.373047ZM8.99984 16.0145C4.97926 16.0145 1.70817 12.727 1.70817 8.70635C1.70817 4.68577 4.97926 1.41468 8.99984 1.41468C13.0204 1.41468 16.2915 4.68579 16.2915 8.70635C16.2915 12.7269 13.0204 16.0145 8.99984 16.0145ZM12.6457 8.18555H9.52067V5.06055C9.52067 4.77305 9.28734 4.53971 8.99984 4.53971C8.71234 4.53971 8.479 4.77305 8.479 5.06055V8.18555H5.354C5.0665 8.18555 4.83317 8.41888 4.83317 8.70638C4.83317 8.99388 5.0665 9.22721 5.354 9.22721H8.479V12.3522C8.479 12.6397 8.71234 12.873 8.99984 12.873C9.28734 12.873 9.52067 12.6397 9.52067 12.3522V9.22721H12.6457C12.9332 9.22721 13.1665 8.99388 13.1665 8.70638C13.1665 8.41888 12.9332 8.18555 12.6457 8.18555Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <span onClick={() => handleRedi("c")} className="text-sm font-semibold">Upload</span>
              </div>
            </div>
          </div>
        </div>

        <div className=" border-3 border-b"></div>

        <div className=" flex flex-col  py-3">
          <div className=" ">
            {/* <div className="text-[#BEBEBE] text-center"> Quick Action</div> */}
            <div className="flex flex-col ">
              <div className="flex gap-2 items-center py-2">
                <span>
                  <img
                    src="/assets/icons/setting.svg"
                    alt=""
                    className="w-6 h-6"
                  />
                </span>
                <Link to="/user-dashboard/profile" className="text-sm font-semibold">Setting</Link>
              </div>
              <div onClick={handleLogout} className="flex gap-2 items-center py-2">
                <span>
                  <img
                    src="/assets/icons/logout.svg"
                    alt=""
                    className="w-6 h-6"
                  />
                </span>
                <span className="text-sm font-semibold cursor-pointer">Logout</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
