import UserDashboardOrderList from "../../../../components/User-Dashboard-Components/User-Dashboard-Buying-Components/User-Dashboard-Orders/UserDashboardOrderList";
import UserDashboardBuyingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";

export default function UserDashboardOrderListPage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <UserDashboardOrderList />
      </UserDashboardBuyingLayout>
    </>
  );
}
