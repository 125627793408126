import React from 'react'
import AuctionLastChanceProducts from '../../../../components/Ad-MarketPlace-Components/Auction-Components/Auction-Last-Chance-Product-Page-Components/AuctionLastChanceProducts'

export default function AuctionLastChanceProductsPage() {
    return (
        <>
            <AuctionLastChanceProducts />
        </>
    )
}
