import { FaFlag, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCountries, getSmartFilterReelsData, getSmartFilterSingleCatReelsData } from "../../../store/frontendSlice";

function InspirationFilter({
  setSelectedSort,
  setSelectedPrice,
  setSelectedLocation,
  selectedSort,
  selectedPrice,
  selectedLocation,
  activeCategory
}) {

  const { countries } = useSelector((state) => state.frontend);
  const [url, setUrl] = useState(``);
  const [selectedFilters, setSelectedFilters] = useState({
    sort: null,
    price: null,
    location: null,
  });

  const dispatch = useDispatch();

  const handleCheckBox = (filter, value, isChecked) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = { ...prevFilters };

      if (filter === "sort") {
        setSelectedSort(value)
        newFilters[filter] = value;
      } else if (filter === "price") {
        setSelectedPrice(value)
        newFilters[filter] = value;
      } else if (filter === "location") {
        setSelectedLocation(value)
        newFilters[filter] = value;
      }

      return newFilters;
    });

    // Update URL string
    setUrl((prevUrl) => {
      let newURL = prevUrl;
      const regex = new RegExp(`[&?]${filter}=[^&]*`, "g");

      if (newURL.includes("?")) {
        newURL = newURL.replace(regex, "");
        newURL += `&${filter}=${value}`;
      } else {
        newURL = newURL.replace(regex, "");
        newURL += `?${filter}=${value}`;
      }

      newURL = newURL.replace(/%20|\s+/g, "");
      newURL = newURL.toLowerCase();

      return newURL;
    });
  };
  
  useEffect(()=>{
    if(activeCategory.length>3){
      dispatch(getSmartFilterSingleCatReelsData({CAT:activeCategory,URL:url}))
    } else {
      dispatch(getSmartFilterReelsData(url))
    }
  },[url])

  useEffect(() => {
    dispatch(getCountries());
  },[])

  return (
    <div className="flex flex-col-reverse gap-2  md:flex-row justify-between items-start w-full p-4 md:border-b-2 mb-2 ">
      <div className="flex flex-col w-full">
        <p className="font-bold text-xl md:text-[11px] lg:text-xl  uppercase">
          Sort
        </p>

        <div className="flex flex-col justify-start mt-2">
          {/* <InspirationFilterTypesComponent
            // isActive={true}
            onClick={() => setSelectedSort("Random")}
          >
            Random
          </InspirationFilterTypesComponent> */}
          <div
            className={`text-${
              selectedSort=="Random" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            // onClick={() => setSelectedSort("Random")}
            onClick={() => handleCheckBox("sort", "Random", true)}
          >
            Random
          </div>
          <div
            className={`text-${
              selectedSort=="Popularity" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            onClick={() => handleCheckBox("sort", "Popularity", true)}
          >
            Popularity
          </div>
          <div
            className={`text-${
              selectedSort=="Newness" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            onClick={() => handleCheckBox("sort", "Newness", true)}
          >
            Newness
          </div>
          <div
            className={`text-${
              selectedSort=="Average rating" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            onClick={() => handleCheckBox("sort", "Average rating", true)}
          >
            Average rating
          </div>
          <div
            className={`text-${
              selectedSort=="Interest" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            // onClick={() => setSelectedSort("Interest")}
            onClick={() => handleCheckBox("sort", "Interest", true)}
          >
            Interest
          </div>
        </div>
      </div>

      <div className="flex flex-col border-b-2 md:border-none w-full">
        <p className="font-bold text-xl md:text-[11px] lg:text-xl uppercase">
          Price
        </p>
        <div className="flex flex-col justify-start mt-2 ">
          <div
            className={`text-${
              selectedPrice=="All" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            // onClick={() => setSelectedPrice("All")}
            onClick={() => handleCheckBox("price", "All", true)}
          >
            All
          </div>
          <div
            className={`text-${
              selectedPrice=="Low to High" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            // onClick={() => setSelectedPrice("Low to High")}
            onClick={() => handleCheckBox("price", "Low to High", true)}
          >
            Low to High
          </div>
          <div
            className={`text-${
              selectedPrice=="High to Low" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            // onClick={() => setSelectedPrice("High to Low")}
            onClick={() => handleCheckBox("price", "High to Low", true)}
          >
            High to Low
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-start border-b-2 md:border-none w-full">
        <p className="font-bold text-xl md:text-[11px] lg:text-xl uppercase">
          Location
        </p>
        <div className={`${countries?.length>4?"overflow-y-scroll h-[155px] hide-scrollbar":""} flex flex-col justify-start mt-2`}>

          <div
            className={`text-${
              selectedLocation=="All" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            // onClick={() => setSelectedLocation("All")}
            onClick={() => handleCheckBox("location", "All", true)}
          >
            All
          </div>

          {countries?.length>0?
          countries.map((a) => (
            <div
            key={a.id}
              className={`text-${
                selectedLocation===a.name ? "black" : "[#828282]"
              } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
              // onClick={() => setSelectedLocation("All")}
              onClick={() => handleCheckBox("location", a.name, true)}
            >
              {a.name}
            </div>
          )):<></>}


          {/* <div
            className={`text-${
              selectedLocation=="North America" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            // onClick={() => setSelectedLocation("North America")}
            onClick={() => handleCheckBox("location", "North America", true)}
          >
            North America
          </div>

          <div
            className={`text-${
              selectedLocation=="South America" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            // onClick={() => setSelectedLocation("South America")}
            onClick={() => handleCheckBox("location", "South America", true)}
          >
            South America
          </div>

          <div
            className={`text-${
              selectedLocation=="Europe" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            // onClick={() => setSelectedLocation("Europe")}
            onClick={() => handleCheckBox("location", "Europe", true)}
          >
            Europe
          </div>

          <div
            className={`text-${
              selectedLocation=="Africa" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            // onClick={() => setSelectedLocation("Africa")}
            onClick={() => handleCheckBox("location", "Africa", true)}
          >
            Africa
          </div>

          <div
            className={`text-${
              selectedLocation=="Asia" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            // onClick={() => setSelectedLocation("Asia")}
            onClick={() => handleCheckBox("location", "Asia", true)}
          >
            Asia
          </div>

          <div
            className={`text-${
              selectedLocation=="Oceanic" ? "black" : "[#828282]"
            } font-normal text-base md:text-[10px] lg:text-base py-1 hover:text-black`}
            // onClick={() => setSelectedLocation("Oceanic")}
            onClick={() => handleCheckBox("location", "Oceanic", true)}
          >
            Oceanic
          </div> */}

        </div>
      </div>

      {/* Search Input Currently opacity is Zero */}
      <div className="flex flex-col opacity-0 relative left-2 border-b-2 md:border-none w-full">
        <div className="flex items-center">
          <input
            type="search"
            placeholder="Search"
            className="bg-[#F2F2F2] outline-[#cfcdcd] w-full md:w-40 lg:w-56 rounded-[30px] placeholder:relative placeholder:left-2 placeholder:text-base text-base py-1 px-2 "
          />
          <FaSearch className="relative right-6 w-3 h-3 text-[#828282]" />
        </div>
        <div className="flex gap-2 items-center py-3 md:p-3">
          <div>
            <FaFlag />
          </div>
          <div className="text-xs lg:text-base">Country</div>
        </div>
      </div>
    </div>
  );
}

export default InspirationFilter;
