import { useState } from "react";
import "./UserDashboardReviews.css";
import { useDispatch } from "react-redux";
import { postReview } from "../../../../store/categorySlice";

export default function UserDashboardReviewsModal({loading,review, setReview, selectedImages1,selectedImages,setSelectedImages1,setSelectedImages,data,reviewIndex}) {
  const [rating, setRating] = useState(0);
  const dispatch = useDispatch();

  const handleRatingChange = (event) => {
    setRating(parseInt(event.target.value));
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const newImages = [...selectedImages];
    if(files.length>4){
      for (let i = 0; i < 4 ; i++) {
        newImages.push(URL.createObjectURL(files[i]));
        selectedImages1.push(files[i])
      }
    } else {
      for (let i = 0; i < files.length ; i++) {
        newImages.push(URL.createObjectURL(files[i]));
        selectedImages1.push(files[i])
      }
    }
    setSelectedImages(newImages);
  };

  const handleImageDelete = (index) => {
    const updatedFiles = selectedImages.filter((_, i) => i !== index);
    const updatedFiles1 = selectedImages1.filter((_, i) => i !== index);
    setSelectedImages(updatedFiles);
    setSelectedImages1(updatedFiles1);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData()
    formData.append("product_id",data[reviewIndex].product_id);
    formData.append("rating",rating);
    formData.append("review",review);
    formData.append("order_id",data[reviewIndex].order_id);

    if(selectedImages1.length>0){
      for (let i = 0; i < selectedImages1.length ; i++) {
        if (selectedImages1[i] instanceof File) {
          formData.append("images[]", selectedImages1[i]);
        }
      }
    }

    dispatch(postReview(formData));
  }

  return (
    <div className="py-2 flex flex-col md:flex-row gap-4 mt-2">
      {/* upload image and rating  */}
      <div className="flex flex-col gap-4">
        {/* input  */}
        <div class="fileUpload flex justify-between w-full md:w-[309px]">
          <input
            type="file"
            class="upload"
            onChange={handleFileChange}
            multiple
          />
          <span className="w-full font-normal text-sm text-[#828282]">
            Uploaded Images
          </span>
          <span className="w-5 h-5 ">
            <img src="/assets/icons/user-dash-board/upload.svg" alt="" />
          </span>
        </div>

        {/* images  */}
        <div className="w-full md:w-64 flex gap-3 md:gap-1">
          {/* Render selected images dynamically */}
          {selectedImages.slice(0, 4).map((image, index) => (
            <div className="relative w-fit mr-2">
              <button onClick={() => handleImageDelete(index)} className="absolute right-0 rounded-full px-[3px] text-sm bg-white cursor-pointer hover:bg-black hover:text-white">X</button>
              <img
                key={index}
                src={image}
                alt={`Uploaded ${index + 1}`}
                className="w-[58px] h-[58px]"
              />
            </div>
          ))}
        </div>

        {/* review star */}
        <div className="flex items-center gap-3 mt-4">
          <span className="font-medium text-base md:text-lg text-[#828282]">
            Select rating:
          </span>
          <div className="rating rating-md space-x-1  ">
            {[1, 2, 3, 4, 5].map((value) => (
              <input
                key={value}
                type="radio"
                name="rating"
                value={value}
                className="mask mask-star-2 bg-orange-400"
                checked={rating === value}
                onChange={handleRatingChange}
              />
            ))}
          </div>
        </div>
      </div>

      {/* write review  */}
      <div className="flex flex-col gap-4 items-end w-full">
        <textarea
          name="review"
          id=""
          cols="30"
          rows="10"
          className="bg-[#F9FAFB] w-full h-[181px] px-4 py-3 rounded-[8px] outline-[#828282] font-normal text-sm resize-none"
          placeholder="Write your review here..."
          value={review}
          onChange={(e) => setReview(e.target.value)}
        ></textarea>
        <button disabled={loading} onClick={handleSubmit} className="w-[192px] h-12 mt-2 py-1 px-6 rounded border-[1.63px] border-[#C19A6B] text-[#C19A6B] font-medium text-base text-center hover:bg-[#C19A6B] hover:text-white">
          {!loading ? (
            "Submit"
          ) : (
            <span className="loading loading-spinner loading-md"></span>
          )}
        </button>
      </div>
    </div>
  );
}
