import ShopFront from "../../pages/Main-Shop/shop-front/ShopFront";

const shopFrontRoutes = [
  {
    path: "/shop-front/:user",
    element: <ShopFront />,
  },
];

export default shopFrontRoutes;
