import React, { useEffect } from "react";
import BoostShopSection from "../../../components/Main-Category-Components/Boost-Shop-Section/BoostShopSection";
import SmartFilterBar from "../../../components/SmartFilter/SmartFilterBar";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getCategoryProducts, getOfferProducts } from "../../../store/frontendSlice";
import { toast } from "react-toastify";

const Offers = () => {
    const {slug}=useParams();
    const { data, products } = useSelector(state => state.frontend);
    const dispatch = useDispatch();
    const { message, error, loading } = useSelector(state => state.frontend);
  
    useEffect(() => {
      if(message){
        toast.success(message);
      }
      if(error){
        toast.error(error);
      }
      dispatch(clearMessage());
    }, [message, error, loading]);
  

    useEffect(() => {
        dispatch(getOfferProducts(slug))
        // console.log(data)
    }, [slug]);
    
    return (
    <>
      {/* <BoostShopSection stores={data.stores}/> */}
      <SmartFilterBar getFunction={getOfferProducts} products={data.offers}/>    
    </>
  )
}

export default Offers
