import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BubblesModal from "./BubblesModal";
import { getBubbleModalData } from "../../../../store/frontendSlice";

const BubblesHeroSection = ({ width = 1200, height = 800 }) => {
  const dispatch = useDispatch()
  const canvasRef = useRef(null);
  const bubbles = useRef([]);
  const { storeSqure, bubbleModData } = useSelector((state) => state.frontend);
  const hasWindow = typeof window !== 'undefined';
  const [size, setSize] = useState(getWindowDimensions());
  const [dragging, setDragging] = useState(false);
  const [draggedBubble, setDraggedBubble] = useState(false);

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const resizeBubbles = () => {
    bubbles.current.forEach((bubble) => {
      // console.log(bubble.originalRadius * (size.width / width), "width", bubble.originalRadius);
    });
  };

  function handleResize() {
    setSize(getWindowDimensions());
    // resizeBubbles()
  }

  useEffect(() => {
    if (hasWindow) {
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow, window.innerWidth]);

  const gradients = [
    { start: "rgba(255, 255, 255, 0.1)", end: "rgba(153, 128, 183, 0.3)" },
    { start: "rgba(255, 100, 100, 0.1)", end: "rgba(153, 0, 0, 0.3)" },
    { start: "rgba(100, 255, 100, 0.1)", end: "rgba(0, 153, 0, 0.3)" },
    { start: "rgba(100, 100, 255, 0.1)", end: "rgba(0, 0, 153, 0.3)" },
  ];

  class Bubble {
    constructor(x, y, dx, dy, radius, img, shopName,slug, gradient) {
      this.x = x;
      this.y = y;
      this.dx = dx;
      this.dy = dy;
      this.radius = Math.min(Math.max(radius, 0), 100);
      const scaleFactor = width / size.width;
      const adjustedRadius = radius * scaleFactor;
      this.originalRadius = Math.min(Math.max(adjustedRadius, 0), 100);
      this.img = new Image();
      this.img.src = img;
      this.shopName = shopName;
      this.slug = slug
      this.dragging = false;
      this.hovering = false;
      this.gradient = gradient;
      this.clicked = false;
    }

    draw(ctx) {
      const gradient = ctx.createRadialGradient(
        this.x,
        this.y,
        0,
        this.x,
        this.y,
        this.radius
      );
      gradient.addColorStop(0.5, this.gradient.start);
      gradient.addColorStop(0.9, this.gradient.end);
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
      ctx.closePath();
      ctx.fillStyle = gradient;
      ctx.fill();

      this.applyInnerShadow(ctx, "rgba(0, 0, 0, 0.5)", 20, 0, 0);

      const imgSize = this.radius * 0.8;
      ctx.drawImage(
        this.img,
        this.x - imgSize / 2,
        this.y - imgSize / 1.2,
        imgSize,
        imgSize
      );

      ctx.fillStyle = "#000000";
      ctx.font = `${this.radius / 3.5}px Arial`;
      ctx.textAlign = "center";
      ctx.fillText(this.shopName, this.x, this.y + this.radius / 2 + 2);

      if (this.hovering || this.clicked) {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        ctx.closePath();
        ctx.lineWidth = 4;
        ctx.strokeStyle = this.gradient.end;
        ctx.stroke();
      }
    }

    applyInnerShadow(ctx, shadowColor, blur, offsetX, offsetY) {
      ctx.save();
      ctx.globalCompositeOperation = "source-over";

      ctx.beginPath();
      ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
      ctx.clip();

      ctx.shadowColor = shadowColor;
      ctx.shadowBlur = blur;
      ctx.shadowOffsetX = offsetX;
      ctx.shadowOffsetY = offsetY;
      ctx.lineWidth = this.radius * 2;

      ctx.strokeStyle = "rgba(0, 0, 0, 0)";
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
      ctx.stroke();

      ctx.restore();
    }

    update(canvas) {
      if (!this.dragging) {
        this.x += this.dx;
        this.y += this.dy;

        if (this.x + this.radius > canvas.width) {
          this.x = canvas.width - this.radius;
          this.dx = -this.dx;
        } else if (this.x - this.radius < 0) {
          this.x = this.radius;
          this.dx = -this.dx;
        }

        if (this.y + this.radius > canvas.height) {
          this.y = canvas.height - this.radius;
          this.dy = -this.dy;
        } else if (this.y - this.radius < 0) {
          this.y = this.radius;
          this.dy = -this.dy;
        }
      }
      this.draw(canvas.getContext("2d"));
    }

    isMouseInside(mx, my) {
      return Math.sqrt((this.x - mx) ** 2 + (this.y - my) ** 2) < this.radius;
    }

    static resolveCollision(b1, b2) {
      const dx = b1.x - b2.x;
      const dy = b1.y - b2.y;
      const dist = Math.sqrt(dx ** 2 + dy ** 2);
      if (dist < b1.radius + b2.radius) {
        const angle = Math.atan2(dy, dx);
        const sin = Math.sin(angle);
        const cos = Math.cos(angle);

        const b1v = {
          x: b1.dx * cos + b1.dy * sin,
          y: b1.dy * cos - b1.dx * sin,
        };
        const b2v = {
          x: b2.dx * cos + b2.dy * sin,
          y: b2.dy * cos - b2.dx * sin,
        };

        const b1vAfter = { x: b2v.x, y: b1v.y };
        const b2vAfter = { x: b1v.x, y: b2v.y };

        b1.dx = b1vAfter.x * cos - b1vAfter.y * sin;
        b1.dy = b1vAfter.y * cos + b1vAfter.x * sin;
        b2.dx = b2vAfter.x * cos - b2vAfter.y * sin;
        b2.dy = b2vAfter.y * cos + b2vAfter.x * sin;

        const overlap = 0.5 * (b1.radius + b2.radius - dist + 1);
        const ax = overlap * (dx / dist);
        const ay = overlap * (dy / dist);
        if (!b1.dragging) {
          b1.x += ax;
          b1.y += ay;
        }
        if (!b2.dragging) {
          b2.x -= ax;
          b2.y -= ay;
        }

        b1.radius =
          b1.originalRadius +
          Math.sin((dist * Math.PI) / (b1.radius + b2.radius)) * 2;
        b2.radius =
          b2.originalRadius +
          Math.sin((dist * Math.PI) / (b1.radius + b2.radius)) * 2;
      } else {
        b1.radius = b1.originalRadius;
        b2.radius = b2.originalRadius;
      }
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    let animationId;

    function init() {
      bubbles.current = [];

      storeSqure?.allshops?.forEach((shop, index) => {
        console.log("Shop data:", shop); // Debug: log shop data
        let x, y, overlapping;
        const gradient = gradients[index % gradients.length];
        do {
          overlapping = false;
          x = Math.random() * (canvas.width - shop.Size * 2) + shop.Size;
          y = Math.random() * (canvas.height - shop.Size * 2) + shop.Size;
          for (let bubble of bubbles.current) {
            if (
              Math.sqrt((bubble.x - x) ** 2 + (bubble.y - y) ** 2) <
              shop.Size + bubble.radius
            ) {
              overlapping = true;
              break;
            }
          }
        } while (overlapping);

        const dx = (Math.random() - 0.5) * 1;
        const dy = (Math.random() - 0.5) * 1;
        bubbles.current.push(
          new Bubble(x, y, dx, dy, shop.Size, shop.img, shop.ShopName, shop.slug, gradient)
        );
      });

      animate();
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let bubble of bubbles.current) {
        bubble.update(canvas);
      }
      for (let i = 0; i < bubbles.current.length; i++) {
        for (let j = i + 1; j < bubbles.current.length; j++) {
          Bubble.resolveCollision(bubbles.current[i], bubbles.current[j]);
        }
      }
      animationId = requestAnimationFrame(animate);
    }

    init();

    return () => {
      cancelAnimationFrame(animationId);
    };
  }, [storeSqure, width, height]);

  const handleMouseDown = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const mx = e.clientX - rect.left;
    const my = e.clientY - rect.top;
    let bubbleClicked = false;

    for (let bubble of bubbles.current) {
      if (bubble.isMouseInside(mx, my)) {
        bubble.dragging = true;
        bubble.clicked = false;
        setDragging(true); // Set dragging to true when a bubble is clicked
        bubbleClicked = true;
        setDraggedBubble(false); // Initially set draggedBubble to false when dragging starts
        break;
      }
    }

    if (!bubbleClicked) {
      setDragging(false);
      setDraggedBubble(false); // Reset draggedBubble if no bubble was clicked
    }
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      setDraggedBubble(true); // Set draggedBubble to true during dragging
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const mx = e.clientX - rect.left;
      const my = e.clientY - rect.top;

      for (let bubble of bubbles.current) {
        if (bubble.dragging) {
          bubble.x = mx;
          bubble.y = my;
          break;
        }
      }
    }
  };

  const handleMouseUp = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const mx = e.clientX - rect.left;
    const my = e.clientY - rect.top;

    for (let bubble of bubbles.current) {
      if (bubble.dragging) {
        bubble.dragging = false;
        setDragging(false); // Set dragging to false when mouse is released
        if (bubble.isMouseInside(mx, my) && !draggedBubble) {
          bubble.clicked = true;
        }
        break;
      }
    }
  };

  const handleBubbleClick = (bubble) => {
    if (!dragging && !draggedBubble) {
      // Open the modal if not dragging and bubble wasn't dragged
      console.log("Bubble clicked:", bubble);
      setSelectedShop(bubble);
      document.getElementById("my_modal_104").show();
      dispatch(getBubbleModalData(bubble))
    }

    

  };

  const [showModal, setShowModal] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);

  return (
    <div className="w-full h-screen bg-transparent bg-opacity-0 relative">
      <canvas
        ref={canvasRef}
        width={size.width}
        height={size.height}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onClick={(e) => {
          const canvas = canvasRef.current;
          const rect = canvas.getBoundingClientRect();
          const mx = e.clientX - rect.left;
          const my = e.clientY - rect.top;

          for (let bubble of bubbles.current) {
            if (bubble.isMouseInside(mx, my)) {
              handleBubbleClick(bubble?.slug);
              break;
            }
          }
        }}
      />
      {showModal && (
        <BubblesModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedShop={selectedShop}
          // storeModalData={bubbleModData?.store}
        />
      )}
    </div>
  );
};

export default BubblesHeroSection;
