import { useEffect, useState } from "react";
import "./Shelf-Component.css";
import CouponStore from "../Shelf-Coupon-Modal/MyShelfCouponModal";
import { toast } from "react-toastify";
import { getCoupan } from "../../../store/frontendSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

function ShelfCoupon() {
  const { coupan } = useSelector(state => state.frontend);
  const [numCards, setNumCards] = useState(2);
  const dispatch=useDispatch()
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(getCoupan())
    }
  }, []);
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => toast.success("Coupan Code Copied To Clip Board"))
      .catch(err => toast.error("Coupan Code Was Not Able To Copied"));
  };
  const handleCouponClick = (couponCode) => {
    copyToClipboard(couponCode);
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setNumCards(6);
      } else if (width >= 768) {
        setNumCards(6);
      } else {
        setNumCards(6);
      }
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRemainingDays = () => {
    const endDate = moment("2024-08-07 00:00:00", "YYYY-MM-DD HH:mm:ss");
    const currentDate = moment();
    const daysLeft = endDate.diff(currentDate, 'days');
    return daysLeft!=0? daysLeft+" Days to go" : "ends today"
  }

  const colour=["#FFC792","#FF9292","#9C9C9C"]
  return (
    <>
      <div className="w-full">
        <div className="py-4 px-2 md:px-8 w-full carousel carousel-center flex gap-6 ">
          {/* modal open from here */}
          {/* <div className="flex justify-between items-center gap-7 carousel carousel-center  py-2"> */}
            {coupan.map((coupon,index) => (
              <div
                key={coupon.id}
                className="w-1/2 md:max-w-[23%] carousel-item flex flex-col gap-1 cursor-pointer relative"
                // onClick={() =>
                //   document.getElementById("my_modal_coupon").showModal()
                // }
                onClick={() => handleCouponClick(coupon.code)}
              >
                <div className="h-[17px] md:h-[22px] xl:h-[31px]  w-[79px] md:w-[78px] xl:w-32 flex items-center gap-1  bg-[#FF7C00] rounded-[49px] py-[2px] px-[8px] md:py-[2.7px] md:px-[10px] xl:py-[3.78px] xl:px-[15px] text-center text-nowrap">
                  {/* <span className="font-black text-[11px] md:text-[14px] xl:text-[20px] ">
                    {handleRemainingDays(coupon.remainingDay)}
                  </span>{" "} */}
                  <span className="font-bold text-[8px] md:text-[10px] xl:text-[15px] text-nowrap">
                   {moment(coupon.end_date).diff(moment(), 'days')} Days to go
                  </span>
                </div>

                {/* svg  */}
                <div>
                  <div className="absolute w-1/5 flex justify-center top-[9px] md:top-[13px] left-20 xl:top-5 xl:left-28 z-30">
                    <svg
                      width="52"
                      height="44"
                      viewBox="0 0 52 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M42.2309 1.53303C33.4156 3.42125 26.4893 16.6403 26.4893 17.271C26.4893 17.9017 39.7122 12.8585 46.6385 10.3442C53.5648 7.82987 51.0462 -0.355196 42.2309 1.53303Z"
                        stroke="black"
                        stroke-width="2.51866"
                      />
                      <path
                        d="M10.3199 2.43732C19.1352 4.32555 26.0615 18.1753 26.0615 18.1753C26.0615 18.1753 12.8386 13.7628 5.91227 11.2485C-1.01403 8.73416 1.50462 0.549101 10.3199 2.43732Z"
                        stroke="black"
                        stroke-width="2.51866"
                      />
                      <path
                        d="M38.387 42.3625C38.335 43.056 38.8551 43.6604 39.5487 43.7124C40.2422 43.7644 40.8466 43.2444 40.8986 42.5508L38.387 42.3625ZM22.7765 17.137C25.5624 18.5305 29.7489 21.5005 33.0957 25.8179C36.4304 30.1199 38.8868 35.6966 38.387 42.3625L40.8986 42.5508C41.4563 35.1122 38.6987 28.9351 35.0863 24.2749C31.4859 19.6302 26.9922 16.4295 23.9032 14.8844L22.7765 17.137Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>

                <div className="w-full rounded-md">
                  {/* 1st div  */}
                  <div
                    className={`shelf-coupon-top w-full  rounded-[6px]   px-1 py-1 pt-1`}
                    style={{ backgroundColor: colour[index%2] }}
                  >
                    <div className="border-2 rounded-[3.78px]   border-dashed border-white h-[130px] flex justify-center ">
                      <div className="flex justify-around items-center w-full ">
                        <div>
                          <img
                            src={"assets/images/shelf/user.jpg"}
                            alt="user"
                            className="w-[65px] h-[65px] md:w-[84px] md:h-[84px] xl:w-[118px] xl:h-[118px] rounded-[62px]"
                          />
                        </div>
                        <div className="flex flex-col justify-center">
                          <img
                            src="assets/images/inspiration/electronics.png"
                            alt="user"
                            className="w-[57px] min-h-[44px] md:min-w-[73px] md:min-h-[57px] xl:min-w-[103px] xl:min-h-[80px]"
                          />
                          <p className="font-black text-xs md:text-base xl:text-[22px] ml-1">
                            <span>{parseInt(coupon.usage_limit) - parseInt(coupon.used_count)}</span> Items
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 2nd div  */}
                  <div
                    className={`shelf-coupon-bottom  w-full rounded-[6px] bg-[#FFC792] px-1 py-1 pt-3`}
                    style={{ backgroundColor: colour[index%2]  }}
                  >
                    <div className="border-2 rounded-[3.78px]  border-dashed border-white ">
                      {/* show discount  */}
                      <p className=" text-center font-black text-[15px] md:text-[19px] xl:text-[27px]">
                        <span>{coupon.discount_amount}</span> OFF
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {/* </div> */}
        </div>
      </div>

      <dialog id="my_modal_coupon" className="modal">
        <div className="modal-box  bg-white flex justify-center max-w-[400px] md:max-w-[450px] lg:max-w-[898px]">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 z-50">
              ✕
            </button>
          </form>
          <CouponStore />
        </div>
      </dialog>
    </>
  );
}

export default ShelfCoupon;
