import React, { useEffect, useState } from "react";

import { BsArrowBarLeft } from "react-icons/bs";
import { BsArrowBarRight } from "react-icons/bs";
import { BiSolidArrowFromRight } from "react-icons/bi";
import { BiSolidArrowToRight } from "react-icons/bi";

import AdStoreResidenceCard from "./AdStoreResidenceCard";
import "./AdStoreMap.css";

const AdStoreResidence = () => {
  const [vehicles, setVehicles] = useState([]);
  const [originalVehicles, setOriginalVehicles] = useState([]); // Keep the original order
  const [view, setView] = useState(5);
  const [isOpen, setIsOpen] = useState(false);
  const [sortType, setSortType] = useState("All");
  const [animateClass, setAnimateClass] = useState("");

  const handleView3 = () => {
    setAnimateClass("");
    setView(3); // Change to 3-column view
  };

  const handleView5 = () => {
    setAnimateClass("");
    setView(5); // Change to 5-column view
  };

  const handleSelection = (type) => {
    setSortType(type);
    setIsOpen(false);

    if (type === "Latest") {
      // Sort vehicles
      const sortedVehicles = [...originalVehicles].sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setVehicles(sortedVehicles);
    } else {
      // Restore the original
      setVehicles(originalVehicles);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/Ad-Store-Residence.json");
        const data = await response.json();
        setVehicles(data);
        setOriginalVehicles(data); // Store the original
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  //   const gridClass = `grid grid-cols-${view} gap-8  `;
  // const gridClass = `grid   gap-4 transition-all duration-500 ease-in-out ${
  //   view === 3
  //     ? "md:grid-cols-3 grid-cols-2 md:w-[50%] gap-4 xl:gap-[30px]"
  //     : "xl:grid-cols-5 md:grid-cols-4 grid-cols-2 "
  // }`;

  // const containerStyle = view === 3 ? { width: "100%" } : { width: "100%" };
  // buttons
  const parentdiv =
    view === 3
      ? "flex  xl:w-[60%] md:max-w-[70%] my-5 items-center justify-between"
      : "flex  my-5 items-center justify-between";

  return (
    <>
      <div className="xl:px-[57px] md:px-[40px]  ">
        <div className="text-base breadcrumbs font-bold px-4 md:px-0 ">
          <ul>
            <li>
              <a>Home</a>
            </li>
            <li>
              <a>Residence</a>
            </li>
            <li>
              <a>Apartments</a>
            </li>
          </ul>
        </div>

        <div className="px-4 md:px-0">
          <div className={parentdiv}>
            <div className="font-bold mt-2">
              {originalVehicles.length} Results
            </div>
            <div className="flex gap-2">
              <div className="relative inline-block">
                <button
                  className="flex items-center px-1 justify-between py-2 w-44 h-10 border border-gray-200 rounded"
                  onClick={toggleDropdown}
                >
                  <span className="text-[20px] opacity-70">Sort by:</span>
                  <span className="text-[20px]"> {sortType}</span>
                  <svg
                    className={`w-7 h-7 transform ${
                      isOpen ? "rotate-180" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>

                {isOpen && (
                  <div className="absolute z-10 mt-1 w-44 bg-white border rounded shadow-lg">
                    <ul>
                      <li
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleSelection("All")}
                      >
                        All
                      </li>
                      <li
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleSelection("Latest")}
                      >
                        Latest
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <div className="flex items-center   gap-3">
                {view !== 3 && (
                  <div onClick={handleView3}>
                    <BiSolidArrowFromRight className=" h-8 w-8 cursor-pointer" />
                  </div>
                )}
                {view !== 5 && (
                  <div onClick={handleView5}>
                    <BiSolidArrowToRight className=" h-8 w-8 cursor-pointer" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            view === 3
              ? "flex flex-col-reverse gap-3   md:flex-row md:justify-between"
              : ""
          } `}
        >
          <div
            className={`grid   gap-4 transition-all duration-500 ease-in-out px-4 md:px-0 ${
              view === 3
                ? "grid-cols-2 lg:grid-cols-3  lg:w-[70%] md:w-[50%] gap-4 xl:gap-[30px]"
                : "xl:grid-cols-5 md:grid-cols-4 grid-cols-2 "
            }`}
          >
            {vehicles.map((item, index) => (
              <>
                <div className={`flex  ${animateClass}`} key={index}>
                  <AdStoreResidenceCard key={index} item={item} view={view} />
                </div>
              </>
            ))}
          </div>
          <div
            className={`${
              view === 3
                ? "block w-full md:w-2/5   md:right-0  md:animate-from-left-map "
                : "hidden"
            }`}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d130265.83887462858!2d17.817247914264563!3d59.32621310806035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f763119640bcb%3A0xa80d27d3679d7766!2sStockholm%2C%20Sweden!5e0!3m2!1sen!2s!4v1713958416173!5m2!1sen!2s"
              style={{ border: "0;" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              className="h-[100vh] w-full md:h-[80vh] "
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdStoreResidence;
