import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import { useRef } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getCartProducts, mainCategorySlider, subCategorySlider } from "../../store/frontendSlice";
import ScrollSubCat from "../header/ScrollSubCat";
import ScrollCat from "../header/ScrollCat";

export default function SliderMainCategory({showContent}) {
  const dispatch = useDispatch();
  const [select, setSelect] = useState(false);
  const [select1, setSelect1] = useState(false);
  const { mainCategorySliderData, subCategorySliderData, loading } = useSelector((state) => state.frontend);
  const [visibleItemId, setVisibleItemId] = useState(null);
  const [visibleItemId1, setVisibleItemId1] = useState(null);
  const [selectCategory, setSelectCategory] = useState(false);
  const [mainCatSlug, setMainCatSlug] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(mainCategorySlider());
    dispatch(getCartProducts())
  }, []);

  const getSubCat = (a) =>{
    dispatch(subCategorySlider(a));
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const location = useLocation();

  const sidebarRef = useRef(null);

  const toggleSidebarOpen = () => {
    if (isSidebarOpen) {
      setClosing(true);
      setTimeout(() => {
        setIsSidebarOpen(false);
        setClosing(false);
      }, 1000);
    } else {
      setIsSidebarOpen(true);
    }
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setClosing(true);
      setTimeout(() => {
        setIsSidebarOpen(false);
        setClosing(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    if (isSidebarOpen) {
      setClosing(true);
      setTimeout(() => {
        setIsSidebarOpen(false);
        setClosing(false);
      }, 1000);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`${isVisible ? 'transform translate-y-0' : 'transform -translate-y-full hidden'} px-5 xl:px-[30px] ${
          selectCategory ? "h-[90px]" : "pb-2"
        } fixed top-[45px] md:top-[60px] lg:top-[70px] xl:top-[60px] left-0 right-0 bg-white z-30`}
      >
        <div className="flex w-full justify-center">
          <div className="flex w-full h-full pt-3">

            <div className="flex w-[10%] justify-center md:justify-end items-start flex-row gap-4">
              <div onClick={toggleSidebarOpen} className="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <rect width="22" height="22" fill="white" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.5 3.78125C0.5 3.42901 0.639927 3.0912 0.888999 2.84212C1.13807 2.59305 1.47588 2.45313 1.82812 2.45312H20.4219C20.7741 2.45312 21.1119 2.59305 21.361 2.84212C21.6101 3.0912 21.75 3.42901 21.75 3.78125C21.75 4.13349 21.6101 4.4713 21.361 4.72038C21.1119 4.96945 20.7741 5.10938 20.4219 5.10938H1.82812C1.47588 5.10938 1.13807 4.96945 0.888999 4.72038C0.639927 4.4713 0.5 4.13349 0.5 3.78125ZM0.5 11.3073C0.5 10.9551 0.639927 10.6172 0.888999 10.3682C1.13807 10.1191 1.47588 9.97917 1.82812 9.97917H20.4219C20.7741 9.97917 21.1119 10.1191 21.361 10.3682C21.6101 10.6172 21.75 10.9551 21.75 11.3073C21.75 11.6595 21.6101 11.9973 21.361 12.2464C21.1119 12.4955 20.7741 12.6354 20.4219 12.6354H1.82812C1.47588 12.6354 1.13807 12.4955 0.888999 12.2464C0.639927 11.9973 0.5 11.6595 0.5 11.3073ZM0.5 18.8333C0.5 18.4811 0.639927 18.1433 0.888999 17.8942C1.13807 17.6451 1.47588 17.5052 1.82812 17.5052H9.79688C10.1491 17.5052 10.4869 17.6451 10.736 17.8942C10.9851 18.1433 11.125 18.4811 11.125 18.8333C11.125 19.1856 10.9851 19.5234 10.736 19.7725C10.4869 20.0215 10.1491 20.1615 9.79688 20.1615H1.82812C1.47588 20.1615 1.13807 20.0215 0.888999 19.7725C0.639927 19.5234 0.5 19.1856 0.5 18.8333Z"
                    fill="black"
                  />
                </svg>
              </div>

              <div onClick={()=> navigate("/")} className="hidden md:flex items-center justify-center gap-2 bg-white py-1 px-4 shadow rounded-md text-sm font-bold cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <rect
                    x="2.99967"
                    y="1.39909"
                    width="10.8333"
                    height="10.8333"
                    rx="1.66667"
                    stroke="black"
                    stroke-width="1.66667"
                  />
                  <rect
                    x="1.23031"
                    y="3.86458"
                    width="10"
                    height="10"
                    rx="0.833333"
                    fill="white"
                    stroke="black"
                    stroke-width="1.66667"
                  />
                </svg>{" "}
                <span className="hidden xl:flex">All</span>
              </div>
            </div>

            <div className="flex w-[80%] h-full items-start flex-row">

              <div className="flex w-[15%] justify-center h-max">
                <div onClick={()=> navigate("/search")} className="hidden w-fit sm:flex items-center justify-center gap-2 bg-white py-1 px-1 lg:px-4 shadow rounded-md text-sm font-bold cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <rect
                      x="2.99967"
                      y="1.39909"
                      width="10.8333"
                      height="10.8333"
                      rx="1.66667"
                      stroke="black"
                      stroke-width="1.66667"
                    />
                    <rect
                      x="1.23031"
                      y="3.86458"
                      width="10"
                      height="10"
                      rx="0.833333"
                      fill="white"
                      stroke="black"
                      stroke-width="1.66667"
                    />
                  </svg>{" "}
                  <span className="hidden md:flex text-xs lg:text-sm">Explore</span>
                </div>
              </div>

              <div className="flex w-[70%] h-full items-center flex-col">

                <div className={`${selectCategory? "":""} h-10 flex w-full justify-center items-center`}>
                  {/* <SwipeableHeader
                    handleSelectCategory={handleSelectCategory}
                    items={mainCategorySliderData?.categories}
                    active={0}
                  /> */}
                  <ScrollCat select={select} setSelect={setSelect} check={""} setMainCatSlug={setMainCatSlug} getFunc={getSubCat} selectCategory={selectCategory} setSelectCategory={setSelectCategory} visibleItemId={visibleItemId} setVisibleItemId={setVisibleItemId} categories={mainCategorySliderData?.categories}/>
                </div>
                
                {selectCategory &&
                  <>
                    <div className="flex w-full h-[4px] justify-center items-center">
                      <div
                        style={{
                          borderStyle: 'solid',
                          borderImageSource: 'linear-gradient(90deg, #BEFFF4 0%, #DCC0FF 33.33%, #FFBFDC 66.67%, #FFEBBF 100%)',
                          borderImageSlice: 1,
                        }}
                        className="border-b-4 flex left-[330px] top-14 w-full"
                      >
                      </div>
                    </div>

                    <div className={`${subCategorySliderData?.subcategories?.length>0? "h-10":"h-1"} flex w-full justify-center items-center bg-white rounded-b-lg`}>
                      {selectCategory && (
                        // <SubSwipeableHeader items={menuArray} active={0} />
                        <ScrollSubCat select1={select1} setSelect1={setSelect1} mainCatSlug={mainCatSlug} visibleItemId={visibleItemId1} setVisibleItemId={setVisibleItemId1} categories={subCategorySliderData?.subcategories}/>
                      )}
                    </div>
                  </>
                }

              </div>

              <div className="flex flex-col w-[15%] pl-4 gap-2">
                {Array.isArray(mainCategorySliderData?.categories) && 
                <>
                <div className="hidden sm:flex flex-row w-full z-40">
                  {visibleItemId+2<=mainCategorySliderData?.categories.length && 
                    <div className="-mt-5 flex object-contain p-[2px] overflow-hidden rounded-md bg-white shadow-xl w-[23px] h-[23px] sm:w-[29px] sm:h-[29px] xl:w-[24px] xl:h-[24px]">
                      <img
                        src={mainCategorySliderData?.categories[visibleItemId+1]?.icon? mainCategorySliderData?.categories[visibleItemId+1]?.icon:mainCategorySliderData?.categories[0]?.icon}
                        alt="Icon"
                        className="w-full h-full object-cover"
                      />
                    </div>
                  }
                  {visibleItemId+3<=mainCategorySliderData?.categories.length && 
                    <div className="-mt-8 flex object-contain p-[2px] overflow-hidden rounded-md bg-white shadow-xl w-[17px] h-[17px] sm:w-[23px] sm:h-[23px] xl:w-[18px] xl:h-[18px]">
                      <img
                        src={mainCategorySliderData?.categories[visibleItemId+2]?.icon? mainCategorySliderData?.categories[visibleItemId+2]?.icon:mainCategorySliderData?.categories[1]?.icon}
                        alt="Icon"
                        className="w-full h-full object-cover"
                      />
                    </div>
                  }
                </div>
                <div className={`${selectCategory?"opacity-0":"opacity-0"} flex flex-row w-full mt-4`}>
                  <p className="text-[10px] tracking-wide whitespace-nowrap font-bold">{mainCategorySliderData?.categories[visibleItemId1+1]?.name? mainCategorySliderData?.categories[visibleItemId1+1]?.name:mainCategorySliderData?.categories[1]?.name}</p>
                </div>
                </>
                }
              </div>

            </div>

            <div className="flex w-[10%] justify-center items-start flex-row">
              {/* search */}
              <Link
                to="/search"
                className="w-[6rem] h-[2rem] relative px-2 cursor-pointer "
              >
                <svg
                  className="absolute top-2 left-3"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.0636 12.8493L9.25661 9.16125M1.64258 6.08788C1.64258 6.65292 1.75746 7.21243 1.98067 7.73446C2.20388 8.25649 2.53104 8.73082 2.94347 9.13036C3.3559 9.52991 3.84553 9.84685 4.3844 10.0631C4.92327 10.2793 5.50083 10.3906 6.0841 10.3906C6.66737 10.3906 7.24493 10.2793 7.7838 10.0631C8.32267 9.84685 8.81229 9.52991 9.22473 9.13036C9.63716 8.73082 9.96432 8.25649 10.1875 7.73446C10.4107 7.21243 10.5256 6.65292 10.5256 6.08788C10.5256 5.52284 10.4107 4.96333 10.1875 4.4413C9.96432 3.91927 9.63716 3.44494 9.22473 3.04539C8.81229 2.64585 8.32267 2.32891 7.7838 2.11268C7.24493 1.89645 6.66737 1.78516 6.0841 1.78516C5.50083 1.78516 4.92327 1.89645 4.3844 2.11268C3.84553 2.32891 3.3559 2.64585 2.94347 3.04539C2.53104 3.44494 2.20388 3.91927 1.98067 4.4413C1.75746 4.96333 1.64258 5.52284 1.64258 6.08788Z"
                    stroke="black"
                    stroke-width="1.63158"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <input
                  className="w-[92px] hidden sm:flex xl:w-[96px] text-sm py-1 px-6 bg-white shadow-xl rounded-md placeholder:text-black  focus:outline-none "
                  type="text"
                  readOnly
                  placeholder="Search"
                />
              </Link>
            </div>

          </div>
        </div>
      </div>

      {/*  sidebar */}
      {isSidebarOpen && (
        <div
          ref={sidebarRef}
          className={`flex flex-col  fixed top-[7.6rem] left-0  h-[calc(100vh-7.6rem)] bg-white z-50 w-[15rem] p-3 rounded-br-xl overflow-y-auto hide-scrollbar ${
            closing ? "animate-sm-out" : "animate-sm"
          }`}
        >
          <div className="w-full relative py-4 px-4 flex items-center justify-between">
            <MdKeyboardArrowLeft
              onClick={toggleSidebarOpen}
              className="absolute right-0 z-10 w-8 h-8 text-black rounded-lg shadow-lg cursor-pointer"
            />
          </div>
          <Sidebar />
        </div>
      )}
    </>
  );
}
