import React, { useEffect, useState } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import AuctionProductCard from "../../Auction-Common/AuctionProductCard";

export default function AuctionHomeCategoriesProducts({ data }) {
  // console.log(data,"dataaaaaaaaaaaaa");

  return (
    <>
      <div className="px-4 lg:px-8 xl:px-[57px] flex flex-col gap-8">
        {data?.length > 0 && data?.map((item) =>
        
        
        {
          
          return item?.products?.length>0 &&  (
       
          <div key={item.id} className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <h2 className="font-semibold text-2xl md:text-3xl l">
                {item?.category?.name}
              </h2>
              <Link to={`/ad-market-place/auction/${item?.category?.slug}`}>
                <p className="text-[#1983FF] flex items-center gap-1">
                  <span className="font-semibold text-xl">View More</span>{" "}
                  <AiOutlineRight className="font-bold text-xl" />
                </p>
              </Link>
            </div>
            <div className="flex items-center gap-9 xl:gap-7 carousel carousel-center">
              {item?.products?.map((product) => {
                // console.log(product,"productsssssss")
             return    (
                
                <Link
                  to={"/ad-market-place/auction/single-product/"+ product?.slug}
                  className="carousel-item"
                  key={product._id}
                >
                  <AuctionProductCard item={product} />
                </Link>
              )})}
            </div>
          </div>
        )}
        )}
      </div>
    </>
  );
}
