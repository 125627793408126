import React, { useEffect } from "react";
import TrendingStoreCard from "../../../components/Store-Square-Components/Store-Square-Trending-Page-Components/TrendingStoreCard";
import { useDispatch, useSelector } from "react-redux";
import {  StoreSquare } from "../../../store/frontendSlice";

export default function StoreSquareTrendingPage() {
  const { boostedStores } = useSelector(state => state.frontend);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(StoreSquare("trending"));
  },[dispatch])

  return (
    <>
      <div className="px-4 md:px-8 lg:px-14 xl:px-[57px] mt-7">
        {/* boosted store */}
        <div className="flex justify-between mt-8">
          <h2 className="font-bold text-lg md:text-2xl  ">Trending Stores</h2>
        </div>

        <div className="max-w-screen-2xl container mx-auto mt-6 mb-6 ">
          {/* Top selling card */}
          <div className="grid grid-flow-col-1 md:grid-cols-2 xl:grid-cols-3 gap-10 lg:gap-2 xl:gap-10">
          {boostedStores?.length>0 && boostedStores.map((item) => {
              return(
            <TrendingStoreCard data={item} />
          )
        })}
          </div>
        </div>
      </div>
    </>
  );
}
