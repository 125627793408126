import React, { useEffect, useState } from "react";
import AdStoreSingleProductLastViewedByYouCard from "./AdStoreSingleProductLastViewedByYouCard";

const AdStoreSingleProductLastViewedByYou = ({ Adproduct }) => {
  const [viewed, setViewed] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "/AdStoreSingleProductLastViewedByYou.json"
        );
        const data = await response.json();
        setViewed(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <div className="xl:px-[57px] md:px-[20px] lg:px-[40px] px-4 max-w-screen-2xl mx-auto">
        <h2 className="font-bold text-[22px] xl:text-[32px] mt-8 mb-4">
          Last Viewed by You
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 md:gap-[30px] gap-4">
          {Adproduct?.length > 0 ? (
            Adproduct.map((item, index) => (
              <AdStoreSingleProductLastViewedByYouCard
                key={index}
                item={item}
              />
            ))
          ) : (
            <p>No product found</p>
          )}
        </div>
      </div>
    </>
  );
};

export default AdStoreSingleProductLastViewedByYou;
