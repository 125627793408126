import React from "react";
import UserDashboardBuyingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";
import UserDashboardStoredFollowed from "../../../../components/User-Dashboard-Components/User-Dashboard-Buying-Components/User-Dashboard-Stored-Followed/UserDashboardStoredFollowed";

export default function UserDashboardStoredFollowedPage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <UserDashboardStoredFollowed />
      </UserDashboardBuyingLayout>
    </>
  );
}
