import UserDashboardWishlistPage from "../../../pages/User-Dashbard/User-Dashbaord-Buying-Page/User-Dashboard-WishlistPage/UserDashboardWishlistPage";

const wishListRoutes = [
  {
    path: "/user-dashboard/wishlist",
    element: <UserDashboardWishlistPage />,
  },
];

export default wishListRoutes;
