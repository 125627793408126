import UserDashboardSellingLayout from "../../../../../Layout/User-Dashboard-layout/UserDashboardSellingLayout";
import UserDashboardVenderApprovedOffers from "../../../../../components/User-Dashboard-Components/User-Dashboard-Vender-Offers/UserDashboardVenderApprovedOffers";

export default function UserDashboardVenderApprovedOfferPage() {
  return (
    <>
      <UserDashboardSellingLayout>
        <UserDashboardVenderApprovedOffers />
      </UserDashboardSellingLayout>
    </>
  );
}
