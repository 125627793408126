import React, { useState } from "react";
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { formatNumber, stringConcat } from "../../../../functions/formatNumber";

const AdMarketplaceCard = ({ item }) => {
  const [isHeartFilled, setIsHeartFilled] = useState(false);
  const handleHeartClick = () => {
    setIsHeartFilled(!isHeartFilled);
  };

  // const truncateString = (str, num) => {
  //   if (str.length <= num) {
  //     return str;
  //   }
  //   return str.slice(0, num) + '...';
  // };

  return (
    <>
    <Link
    to={`/ad-market-place/single-product/${item.slug}`}
    >

      <div className="border xl:w-[240px]  xl:h-[354px] md:w-[213px] md:h-[314px] w-[169px] h-[251px]  rounded-2xl">
        <div className="relative">
          <div className="relative overflow-hidden  rounded-2xl">
            <img
              src={item?.img}
              className="xl:w-[238px] xl:h-[282px] md:w-[211px] md:h-[250px] w-[169px] h-[200px] transition-transform duration-500 ease-in-out hover:scale-110"
              alt=""
            />
          </div>
        
          {/* {item.badge.length > 0 && ( */}
            {/* <div className="absolute flex items-center justify-center top-2 left-2 bg-opacity-60 bg-black rounded-2xl w-[116px] h-[26px] text-center">
              <p className=" text-white font-medium  tracking-wider py-1 px-2 z-10">
                HIGHLIGHTS
              </p>
            </div> */}
          {/* )} */}
        </div>

        <div className="px-3 mt-0 xl:py-1">
          <h2 className="font-[600] xl:text-base md:text-sm text-xs">
            {stringConcat(item.name, 20)}
          </h2>
          <div className="flex justify-between">
            <div className="md:text-xs text-[10px] font-[400] text-[#828282] ">
            {/* ${stringConcat(item.city, 4)} */}
              <p className=" ">{`${stringConcat(item.country, 8)}, ${stringConcat(item.state, 8)}`}</p>
              <p className="">{item.formatted_created_at}</p>
            </div>
            <div>
              <h2 className="xl:text-2xl md:text-xl  text-[17px] font-bold ">
                {/* ${formatNumber(item.price)} */}
                ${item.price}
              </h2>
            </div>
          </div>
        </div>
      </div>

      </Link>
    </>
  );
};

export default AdMarketplaceCard;
