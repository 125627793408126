import SingleProductImgSlider from "./SingleProductImgSlider";
import ReviewsSingleProductPage from "./ReviewsSingleProductPage";
import Description from "./Description";
import SingleProductPageProfile from "./SingleProductPageProfile";
import RelatedProducts from "./RelatedProducts";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleProduct, getSingleProductReviews } from "../../store/frontendSlice";
import Loader from "../Common/Loader"

const SingleProductDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const affiliateCode = queryParams.get('affiliatecode');
  const { slug } = useParams();
  const dispatch = useDispatch()
  const { products, singleProductReviews, loading } = useSelector(state => state.frontend);
  useEffect(() => {
    if(slug) {  
      dispatch(getSingleProduct(slug))
      dispatch(getSingleProductReviews(slug))
    }
  }, [slug])
  // console.log(products);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-8 max-w-screen-2xl mx-auto">
      <SingleProductImgSlider products={products} affiliate_code={ affiliateCode} />
      <ReviewsSingleProductPage singleProductReviews={singleProductReviews}  products={products }/>
      <Description products={products } />


      <SingleProductPageProfile products={products } />
      <RelatedProducts  products={products }/>
    </div>

  );
};

export default SingleProductDetails;
