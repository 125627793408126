import UserDashboardSellingmyFollowersPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/UserDashboardSellingmyFollowersPage";

const myFollowerRoutes = [
  {
    path: "/user-dashboard/my-follower",
    element: <UserDashboardSellingmyFollowersPage />,
  },
];

export default myFollowerRoutes;
