import React, { useEffect } from "react";
import BuyingProfitChart from "../../Graphs/BuyingProfitChart";
import { Link } from "react-router-dom";
import GoLiveChart from "../../Graphs/GoLiveChart";
import BreadCrums from "../../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { getUserDashStats } from "../../../../store/categorySlice";
import UserBarChart from "../../../charts/UserBarChart";
import { formatNumber } from "../../../../functions/formatNumber";
import Loader from "../../../Common/Loader"

export default function UserDashboardBuyingHome() {
  const dispatch = useDispatch();

  const { loading, userDashStats } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(getUserDashStats());
  }, []);


  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <> 

      <div className="flex flex-col px-5 gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Dashboard",
              path: "",
            },
          ]}
        />
        <div className="w-full flex flex-col lg:flex-row gap-5 ">
          <div className="w-full lg:w-[70%] flex flex-col gap-8">
            <div>
              <UserBarChart
                GraphData={userDashStats?.yearlyEarnings}
                MainTitle="Profit"
                DollarSymbol="$"
              />
              {/* <BuyingProfitChart /> */}
            </div>
            <div className="flex flex-col gap-2  bg-white shadow-sm rounded-xl">
              <h2 className="font-semibold">Total Overview</h2>
              <div className="flex flex-wrap xl:flex-nowrap items-center gap-2 xl:gap-4 xl:justify-between">
                <div className="w-fit xl:w-full flex items-center gap-2 text-white rounded-xl p-2 xl:p-3 bg-[#C19A6B] ">
                  <span className="text-lg md:text-[26px] font-bold tracking-wider">
                    ${formatNumber(userDashStats?.totalEarnings)}
                  </span>
                  <span className="text-[8px] md:text-[10px] font-semibold tracking-wider">
                    EARNINGS
                  </span>
                </div>
                <div className="w-fit xl:w-full flex items-center gap-2 text-white rounded-xl p-2 xl:p-3 bg-[#C19A6B] ">
                  <span className="text-lg md:text-[26px] font-bold tracking-wider">
                    ${formatNumber(userDashStats?.totalSpending)}
                  </span>
                  <span className="text-[8px] md:text-[10px] font-semibold tracking-wider">
                    SPENDING
                  </span>
                </div>
                <div className="w-fit xl:w-full flex items-center gap-2 text-white rounded-xl p-2 xl:p-3 bg-[#C19A6B] ">
                  <span className="text-lg md:text-[26px] font-bold tracking-wider">
                    {userDashStats?.totalOrder}
                  </span>
                  <span className="text-[8px] md:text-[10px] font-semibold tracking-wider">
                    ORDERS
                  </span>
                </div>
                <div className="w-fit xl:w-full flex items-center gap-2 text-white rounded-xl p-2 xl:p-3 bg-[#C19A6B] ">
                  <span className="text-lg md:text-[26px] font-bold tracking-wider">
                    {formatNumber(userDashStats?.totalVisitors)}
                  </span>
                  <span className="text-[8px] md:text-[10px] font-semibold tracking-wider">
                    VIEWS
                  </span>
                </div>
                <div className="w-fit xl:w-full flex items-center gap-2 text-white rounded-xl p-2 xl:p-3 bg-[#C19A6B] ">
                  <span className="text-lg md:text-[26px] font-bold tracking-wider">
                    {formatNumber(userDashStats?.totalFollowers)}
                  </span>
                  <span className="text-[8px] md:text-[10px] font-semibold tracking-wider">
                    FOLLOWERS
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-[30%] flex flex-col gap-8">
            <div
              className="w-full py-5 px-6 rounded-2xl "
              style={{ boxShadow: "0px 5px 30px 0px #AAAAAA0F" }}
            >
              <div className="font-bold">Offers from vendors</div>
              <div
                // to="/user-dashboard/vender-offers"
                className="grid grid-cols-4  mt-5"
              >
                {userDashStats?.offers?.map((item) => {
                  return(
                  <div>
                    <img
                      style={{ boxShadow: "0px 30.79px 61.59px 0px #0508281A" }}
                      className="h-16 w-16 rounded-full mt-5"
                      src={`${item?.image ? item?.image : '/assets/images/user-profile.svg'}`}
                      alt=""
                    />
                  </div>
                  )
                })}

                {/* <div>
                  <img
                    style={{ boxShadow: "0px 30.79px 61.59px 0px #0508281A" }}
                    className="h-16 w-16 rounded-full mt-5"
                    src="/assets/images/user-profile.svg"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    style={{ boxShadow: "0px 30.79px 61.59px 0px #0508281A" }}
                    className="h-16 w-16 rounded-full mt-5"
                    src="/assets/images/user-profile.svg"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    style={{ boxShadow: "0px 30.79px 61.59px 0px #0508281A" }}
                    className="h-16 w-16 rounded-full mt-5"
                    src="/assets/images/user-profile.svg"
                    alt=""
                  />
                </div> */}
              </div>
            </div>
            <div>
              {/* <GoLiveChart /> */}
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
}
