import React, { useEffect, useState } from "react";
import "./AdStoreVehiclesCarsByCategory.css";
import { Link, useParams } from "react-router-dom";

const AdStoreVehiclesCarsByCategory = ({ adMainCatProduct }) => {
  const [carbrands, setCarbrands] = useState([]);
  const { slug,subcat } = useParams();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch("/AdStore-Vehicles-CaraByCategory.json");
  //       const data = await response.json();
  //       setCarbrands(data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <>
      {/* <div className="flex flex-col gap-3">
        <h2 className="xl:text-3xl md:text-2xl text-xl font-bold ">
          Top Car Brands
        </h2>
        <div className="flex justify-between md:gap-[70px] gap-[52px]  carousel carousel-center px-5">
          {carbrands.map((item) => (
            <>
              <div className="carousel-item">
                <div
                  className="xl:w-[195px] xl:h-[160px] md:w-[164px] md:h-[135px] w-[62px] h-[51px] shadow-xl rounded-lg flex items-center justify-center"
                  key={item._id}
                >
                  <SvgIcon src={item.img} alt={`Category ${item._id}`} />
                </div>
              </div>
            </>
          ))}
        </div>
      </div> */}
      <div className="flex flex-col gap-3">
        <h2 className="text-xl md:text-3xl font-bold">{slug}</h2>
        <div className="flex carousel carousel-center xl:gap-[30px] gap-[21px]">

            {adMainCatProduct?.map((item) => (
              <>
              <Link
              to={`/ad-market-place/${slug}/${item.slug}`}
              >
                <div className="car-category-img carousel-item  rounded-md hover:rounded-lg">
                  <div className="" key={item.id}>
                    <img
                      className="xl:w-[135px] xl:h-[135px] md:w-[139px] md:h-[139px]"
                      src={item.image}
                      alt=""
                    />
                  </div>

                  <div className=" overlay-category">
                    <div className="text-category">{item.name}</div>
                  </div>
                </div>
                </Link>
              </>
            ))}

        </div>
      </div>
    </>
  );
};

export default AdStoreVehiclesCarsByCategory;
