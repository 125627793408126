import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import './scrollcatandsubcat.css';

export default function ScrollCat({ select, setSelect, locat, setLocat, check, setMainCatSlug, getFunc,selectCategory, setSelectCategory, categories,visibleItemId,setVisibleItemId}) {
  // const [select, setSelect] = useState(false);
  const carouselRef = useRef(null);
  const [visibleItemsCount, setVisibleItemsCount] = useState(4);
  const [itemWidth, setItemWidth] = useState(0);
  const navigate = useNavigate()

  const updateVisibleItemsCount = () => {
    const carousel = carouselRef.current;
    const items = Array.from(carousel.querySelectorAll(".ccarousel"));
    if (items.length > 0) {
      const itemWidth = items[0].offsetWidth;
      const visibleItemsCount = Math.floor(carousel.clientWidth / itemWidth);
      setVisibleItemsCount(visibleItemsCount);
      setItemWidth(itemWidth);
    }
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    const items = Array.from(carousel.querySelectorAll(".ccarousel"));

    if (items.length > 0) {
      setItemWidth(items[0].offsetWidth);
    }

    const handleScroll = () => {
      const scrollLeft = carousel.scrollLeft;
      const currentIndex = Math.floor(scrollLeft / itemWidth);
      setVisibleItemId(Math.min(currentIndex + visibleItemsCount - 1, categories.length - 1));
    };

    // Remove manual scroll handling for wheel events
    // This allows natural scrolling to take place
    // const handleWheel = (event) => {
    //   event.preventDefault();
    //   carousel.scrollLeft += event.deltaY;
    // };

    carousel.addEventListener("scroll", handleScroll);
    // carousel.addEventListener("wheel", handleWheel);

    updateVisibleItemsCount();
    window.addEventListener("resize", updateVisibleItemsCount);

    return () => {
      carousel.removeEventListener("scroll", handleScroll);
      // carousel.removeEventListener("wheel", handleWheel);
      window.removeEventListener("resize", updateVisibleItemsCount);
    };
  }, [itemWidth, visibleItemsCount, categories]);

  useEffect(() => {
    console.log("Visible Item ID:", visibleItemId);
  }, [visibleItemId]);

  return (
    <>
      <div
        className="flex justify-start items-center gap-1 sm:gap-2 karousel h-full overflow-x-auto"
        ref={carouselRef}
        style={{ scrollBehavior: "smooth" }}
      >
        {Array.isArray(categories) && categories.map((a, index) => (
          <button
            key={index}
            id={index}
            onClick={() => {
                if(select===a.id){
                    setSelectCategory(!selectCategory)
                    setSelect(false)
                    // setLocat({...locat,state:"",city:""})
                  } else {
                  setSelectCategory(true)
                  setSelect(a.id)
                  if(check=="adstore" || check=="auction"){
                    setLocat({...locat,state:a.id,city:""})
                    getFunc(a.id)
                  } else {
                    navigate(`/main-categories/${a.slug}`)
                    getFunc(a.slug)
                    setMainCatSlug(a.slug)
                    }
                }
            }}
            className="ccarousel flex items-center outline-none justify-center transition-all duration-500 w-[130px] "
          >
            <span
              className={`${
                select === a.id
                  ? "text-white bg-[#75BFB3] font-normal"
                  : "text-black font-medium"
              } text-[10px] lg:text-xs xl:text-sm rounded-full px-4 py-[5px] text-nowrap`}
            >
              {a.name.length>11?a.name.slice(0,11)+"...":a.name}
            </span>
          </button>
        ))}
      </div>
    </>
  );
}
