import React from "react";
import AffiliateBundlesCard from "./AffiliateBundlesCard";

const AffiliateBundles = ({data}) => {
 

  return (
    <div className="flex w-full justify-center">
      <div className="px-4 w-full max-w-screen-2xl xl:px-[57px] ">
        <h1 className="font-bold text-3xl mt-12">Bundles</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4  mt-3 justify-center gap-4">
          {data?.bundles?.slice(0, 4).map((bundle) => (
            <AffiliateBundlesCard bundle={bundle} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AffiliateBundles;
