import React, { useState } from "react";

export default function AuctionHomeCategoriesBarCard({ item }) {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <>
      <div
        className="flex flex-col items-center gap-2  p-2 min-w-[77px] md:min-w-[120px] cursor-pointer "
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="">
          <img
            className={` ${
              hover
                ? "scale-105 translate-y-3 duration-500"
                : "scale-75 duration-500"
            } `}
            src={item?.icon}
            alt=""
          />
        </div>
        <div
          className={`${
            hover
              ? "opacity-0 duration-500 translate-y-1"
              : "opacity-100 duration-500"
          }  text-xs md:text-lg font-bold tracking-wide`}
        >
          {item?.name}
        </div>
      </div>
    </>
  );
}
