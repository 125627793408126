import Extra from "../../pages/Main-Shop/inspiration/Extra";
import Offers from "../../pages/Main-Shop/Offer-Page/Offers";
import TrendingPage from "../../pages/Trending/TrendingPage";

const inspirationRoutes = [
  {
    path: "/inspiration/extra",
    element: <Extra />,
  },
  {
    path: "/offers/:slug",
    element: <Offers />,
  },
  {
    path: "/trending",
    element: <TrendingPage />,
  },
];

export default inspirationRoutes;
