import UserDashboardSellingPage from "../../../pages/User-Dashbard/User-Dashboard-Selling-Page/UserDashboardSellingPage";

const sellingHomeRoutes = [
  {
    path: "/user-dashboard/selling",
    element: <UserDashboardSellingPage />,
  },
];

export default sellingHomeRoutes;
