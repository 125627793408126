import React, { useEffect, useState } from "react";

import ProductCard from "../Common/ProductCard";

const VendorProducts = ({data,getFunction}) => {

  return (
    <div className="flex w-full justify-center">
      <div className={`max-w-screen-2xl w-full px-4 xl:px-[57px] py-4`}>
        <div className=" mt-4 mb-6">
          {/* Top selling card */}
          <div
            className={`grid lg:grid-cols-4 md:grid-cols-3 xl:grid-cols-5 grid-cols-2 gap-5 xl:gap-5`}
          >
            {data?.products?.slice(0, 10).map((item) => (
              <ProductCard item={item} getFunction={getFunction} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorProducts;
