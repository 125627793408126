// CustomersReviews.jsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReviewsCard from "./ReviewsCard";
import ReviewVideoCard from "./ReviewVideoCard";
import { getReels } from "../../store/frontendSlice";

export default function CustomersReviews() {
  const [customersReviews, setCustomersReviews] = useState([]);
  const [showVideo, setShowVideo] = useState(false);
  const [reelIndex, setReelIndex] = useState("");
  const { reels, loading, message, error } = useSelector((state) => state.frontend);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReels());
  }, []);

  const handleVideoButtonClick = (index) => {
    setShowVideo(true);
    setReelIndex(index)
  };

  const handleBackButtonClick = () => {
    setShowVideo(false);
  };

  return (
    <>
      <div className={`px-4 lg:px-[57px] 2xl:px-[20px] h-fit xl:h-[610px] max-w-screen-2xl mx-auto`}>
        <div className=" mx-auto mt-16 mb-6">
          <div className=" font-semibold text-xl md:text-[21px] lg:text-[30px]">
            InReels
          </div>
        </div>
        <div className={`h-fit w-full flex`}>
          {showVideo ? (
            <ReviewVideoCard reelIndex={reelIndex} reels={reels.reels} getFunction={getReels} onBackButtonClick={handleBackButtonClick} />
          ) : (
            <div
              className={`grid gap-5 xl:gap-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full`}
            >
              {reels?.reels?.length>0? reels.reels.slice(0, 3).map((item,index) => (
                <div
                  className="flex w-full h-full">
                  <ReviewsCard
                    key={item?.reel?.id}
                    reel={item?.reel}
                    onVideoButtonClick={() => handleVideoButtonClick(index)}
                  />
                  </div>
              )):"No reel Found"}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
