import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";

export default function MyShelfSubCategorySlider() {
  const {category}= useParams()
  const [sliderCategories, setSliderCategories] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const { data } = useSelector(state => state.frontend);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/shelf-categories.json");
        const data = await response.json();
        setSliderCategories(data);

        const category = location.pathname.replace("/my-shelf", "");
        const index = data.findIndex((item) => item.url === category);
        if (index !== -1) {
          setActiveIndex(index);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [location.pathname]);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const getClassName = (index) => {
    if (
      index === activeIndex ||
      index === (activeIndex + 1) % sliderCategories.length
    ) {
      return "carousel-item active";
    } else {
      return "carousel-item";
    }
  };

  return (
    <div className="flex justify-between items-center gap-7 carousel carousel-center px-2 py-2 relative w-full md:w-[37rem] float-right">
      {data?.length > 0 && data.map((items, index) => {
        return items?.subCategory.map((item) => {
          return(
            <Link
              to={`/my-shelf/${category}/${item.slug}`}
              key={item.id}
              className={getClassName(index)}
              onClick={() => handleClick(index)}
            >
              <img
                className={`${
                  index === activeIndex
                    ? "w-[200px] h-[120px] md:h-[153px] md:w-[300px]"
                    : "h-[95px] md:h-[128px] w-[150] md:w-[250px]"
                }`}
                src={item.image}
                alt=""
              />
            </Link>
          )
        })
      })}
    </div>
  );
}
