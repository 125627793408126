import React, { useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import "./bubbleModal.css";
import { getBubbleModalData } from "../../../../store/frontendSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function BubblesModal() {
  //   const dispatch = useDispatch();

  // useEffect(() => {

  //   dispatch(getBubbleModalData(selectedShop?.shopName))

  // }, [selectedShop])

  // console.log(storeModalData)

  const { bubbleModData } = useSelector((state) => state.frontend);

  const generateStarRating = (rating) => {
    const stars = [];
    const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
    const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(
          <img key={i} src={filledStar} alt="filled star" className="w-3 h-3" />
        );
      } else {
        stars.push(
          <img
            key={i}
            src={unfilledStar}
            alt="unfilled star"
            className="w-3 h-3"
          />
        );
      }
    }
    return stars;
  };

  return (
    <>
      <div>
        <dialog id="my_modal_104" className="modal">
          <div
            // style={{
            //   backgroundImage:
            //     "url(/assets/images/storeSquareImages/vedio.png)",
            //   backgroundSize: "cover",
            // }}
            className="modal-box bg-gray-300  flex flex-col gap-5 w-[11/12]   md:max-w-[990px] "
          >
            <div className="flex items-center justify-between">
              <h2 className="text-[27px] font-bold  text-white tracking-wider">
                {bubbleModData?.store?.store_name} Overview
              </h2>

              <RxCross2
                className="cursor-pointer text-white w-8 h-8"
                onClick={() => document.getElementById("my_modal_104").close()}
              />
            </div>
            <div className="w-full flex flex-col md:flex-row gap-2  md:gap-8">
              {/* left */}
              {bubbleModData?.store?.bundles?.length > 0 ? (
                <>
                  <div className="relative  p-[18px] bubble-modal-card-shadow rounded-xl">
                    <div className="md:gap-5 gap-2 grid grid-cols-2">
                      {bubbleModData?.store?.bundles?.[0]?.product_images?.map(
                        (item, index) => {
                          return (
                            <img
                              className="max-w-[115px] max-h-[115px] rounded-lg"
                              key={index}
                              src={item}
                              alt=""
                            />
                          );
                        }
                      )}
                    </div>

                    <span className="absolute bottom-2 left-2 text-white text-[20px] font-bold tracking-wider">
                      {bubbleModData?.store?.bundles?.[0]?.title}
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className=" flex flex-col gap-8">
                {/* right top */}
                <div className=" flex flex-col md:flex-row items-center gap-8">
                  {bubbleModData?.store?.bundles?.length > 0 ? (
                    <>
                      <div className="bg-white relative flex items-start gap-5 rounded-xl px-[18px] pt-[20px] pb-[64px] bubble-modal-card-shadow">
                        {bubbleModData?.store?.bundles?.[1]?.product_images
                          ?.slice(0, 2)
                          ?.map((item, index) => {
                            return (
                              <img
                                className="max-w-[115px] max-h-[115px] rounded-lg"
                                key={index}
                                src={item}
                                alt=""
                              />
                            );
                          })}
                        <span className="absolute bottom-2 left-2 text-white text-[20px] font-bold tracking-wider">
                          {bubbleModData?.store?.bundles?.[1]?.title}
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {bubbleModData?.store?.bundles?.length > 0 ? (
                    <>
                      <div className="bg-white relative flex items-start gap-5 rounded-xl px-[18px] pt-[20px] pb-[64px] bubble-modal-card-shadow">
                        {bubbleModData?.store?.bundles?.[1]?.product_images
                          ?.slice(2, 4)
                          ?.map((item, index) => {
                            return (
                              <img
                                className="max-w-[115px] max-h-[115px] rounded-lg"
                                key={index}
                                src={item}
                                alt=""
                              />
                            );
                          })}
                        <span className="absolute bottom-2 left-2 text-white text-[20px] font-bold tracking-wider">
                          {bubbleModData?.store?.bundles?.[1]?.title}
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {/* right bottom */}
                <div className=" flex flex-col md:flex-row items-center gap-5">
                  {/* vedio */}

                  {bubbleModData?.store?.introVideo?.length > 0 ? (
                    <>
                      <div className="relative">
                        {/* <img
                      src="/assets/images/storeSquareImages/vedio.png"
                      alt=""
                    /> */}
                        <video
                          className="rounded-xl max-h-[200px] w-[300px]"
                          src={bubbleModData?.store?.introVideo?.[0]?.video}
                          controls
                        ></video>
                        {/* <span className="absolute top-[40%] left-[45%] flex items-center justify-center bg-black bg-opacity-50 rounded-full p-3">
                      <img
                        className=""
                        src="/assets/images/storeSquareImages/polygon.svg"
                        alt=""
                      />
                    </span> */}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* logo card */}
                  <div className="p-2 rounded-xl bg-white flex flex-col items-center gap-3">
                    <div className="flex items-center gap-3">
                      <img
                        className="max-w-[70px] max-h-[70px] rounded-full"
                        src={bubbleModData?.store?.shop_picture}
                        // src="/assets/images/storeSquareImages/w.svg"
                        alt=""
                      />
                      <div className="flex flex-col justify-between py-2">
                        <div className="flex items-center gap-2">
                          <span className="font-bold text-lg">
                            {bubbleModData?.store?.store_name}
                          </span>
                          <img
                            src="/assets/images/storeSquareImages/ticki.svg"
                            alt=""
                          />
                        </div>
                        <div className="flex items-center gap-2">
                          {generateStarRating(bubbleModData?.store?.rating)}
                          <span className="text-xs ">
                            ({bubbleModData?.store?.review})
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-5">
                      <div className="flex flex-col justify-center items-center">
                        <img
                          src="/assets/images/storeSquareImages/hearti.svg"
                          alt=""
                        />
                        <span>{bubbleModData?.store?.like}</span>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <img
                          src="/assets/images/storeSquareImages/personi.svg"
                          alt=""
                        />
                        <span>{bubbleModData?.store?.followed}</span>
                      </div>
                    </div>
                    <Link
                      to={`/vendor-shop-front/${bubbleModData?.store?.slug}`}
                      className="w-full rounded-lg py-2 text-[#C19A6B] border border-[#C19A6B] text-center"
                    >
                      Visit Store
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dialog>
      </div>
    </>
  );
}
