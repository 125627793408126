import React from "react";
import { AiOutlineHeart, AiOutlineUser } from "react-icons/ai";
import { HiMiniCheckCircle } from "react-icons/hi2";
import { Link } from "react-router-dom";
const generateStarRating = (rating) => {
  const stars = [];
  const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
  const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <img key={i} src={filledStar} alt="filled star" className="w-4 h-4" />
      );
    } else {
      stars.push(
        <img
          key={i}
          src={unfilledStar}
          alt="unfilled star"
          className="w-4 h-4"
        />
      );
    }
  }
  return stars;
};
export default function StoreSquareSmallStoreCard({data}) {
  return (
    <>
      <div className="w-full flex justify-between m-3 md:m-0 gap-4 px-6 boost-shop-card-shadow py-5 rounded-lg lg:min-w-[431px] max-w-[377px]  ">
        <div className="flex flex-col">
          <img
            className=" rounded-full max-h-[74px] max-w-[74px] w-18 mx-auto"
            src={data.img}
            alt=""
          />
          <div className="flex justify-center space-x-2 items-center mt-1">
            <div className="text-sm font-bold text-start">{data.ShopName}</div>
            <div className="">
              <HiMiniCheckCircle className="text-[#1983FF] w-4 h-4" />
            </div>
          </div>
          <div className="flex gap-2 items-center h-[14px]">
            <div className="rating space-x-1 rating-xs">
             {generateStarRating(data?.rating)}
            </div>
            <div className="text-[#8C8C8C] text-xs">({data?.ratings})</div>
          </div>
          {/* heart and person icon */}
          {/* <div className="flex justify-around items-center mt-3">
            <div className="">
              <AiOutlineHeart className="text-[#828282] w-5 h-5" />
              <p className="text-xs text-[rgb(130,130,130)]">88k</p>
            </div>
            <div className="">
              <AiOutlineUser className=" ms-2 text-[#828282] w-5 h-5" />
              <p className="text-xs text-[#828282]">23.5k</p>
            </div>
          </div> */}
          <Link
            to={"/vendor-shop-front/"+data.shop_slug}
            className="border-2 border-[#C19A6B] py-1 px-5 md:py-1 md:px-4 rounded-md bg-white text-[#C19A6B] font-bold text-[13px] md:text-sm mt-3 text-center"
          >
            Visit Store
          </Link>
        </div>

        <div className=" min-w-[150px] flex flex-col gap-4 my-1 ">
        {data?.products.length > 0 && data.products.map((item)=>
         ( 
          
          <Link
            to={"/single-product/"+item.slug}
            className="w-full flex gap-2 items-center border border-opacity-25 border-[#CBCBCB] rounded"
          >
            <div>
              <img
                className="max-h-[53px] max-w-[53px]"
                src={item.image}
                alt=""
              />
            </div>
            <div className="w-full flex flex-col ">
              <h2 className="text-xs font-semibold tracking-wide">
               {item.name.slice(0,15)+"..."}
              </h2>
              <div className="flex gap-2 items-center w-full ">
                <div className="rating  space-x-[2px] ">
                 {generateStarRating(item.total_rating)}
                </div>
                <div className="text-[13px] text-[#1983FF] font-semibold">
                  ${item.price}
                </div>
              </div>
            </div>
          </Link>

        ))}

        </div>
      </div>
    </>
  );
}
