import Tickets from "../../../../components/User-Dashboard-Components/User-Dashoard-Selling-User-Support-Components/Tickets";
import UserDashboardBuyingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";

export default function Ticketspage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <Tickets />
      </UserDashboardBuyingLayout>
    </>
  );
}
