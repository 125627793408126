import React, { useState } from "react";
import { FaHeart } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./product-card.css";
import {
  addToWishlist,
  clearMessage,
  deleteWishlist,
} from "../../store/frontendSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { stringConcat } from "../../functions/formatNumber";

const generateStarRating = (rating) => {
  const stars = [];
  const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
  const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <img key={i} src={filledStar} alt="filled star" className="w-4 h-4" />
      );
    } else {
      stars.push(
        <img
          key={i}
          src={unfilledStar}
          alt="unfilled star"
          className="w-4 h-4"
        />
      );
    }
  }
  return stars;
};

export default function ProductCard({ checker, item, getFunction,auc ,admarketplace,isSelecting, selectedIds, setSelectedIds,}) {
  const [isHeartFilled, setIsHeartFilled] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const [selectVarient, setSelectVarient] = useState(false);
  const [cartButtonOne, setCartButtonOne] = useState(true);
  const [itemsQuantity, SetItemsQuantity] = useState(1);
  const [itemsQuantityBadge, SetItemsQuantityBadge] = useState(false);
  const [cartButtonSecond, setCartButtonSecond] = useState(false);
  const dispatch = useDispatch();
  const { loading, message, error, token, sessionToken } = useSelector(
    (state) => state.frontend
  );
  const { slug } = useParams();

  const handleHeartClick = (id) => {
    if (!isHeartFilled && id)
      dispatch(addToWishlist({ product_id: id })).then(() => {
        if (!token && !slug) {
          dispatch(getFunction(sessionToken));
        } else if (slug) {
          dispatch(getFunction(slug));
        } else {
          dispatch(getFunction());
        }
      });
    if (isHeartFilled && id) {
      dispatch(deleteWishlist({ id: item.whishlist[0] })).then(() => {
        if (!token) {
          dispatch(getFunction(sessionToken));
        } else if (slug) {
          dispatch(getFunction(slug));
        } else {
          dispatch(getFunction());
        }
      });
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleCartButtonOne = () => {
    setSelectVarient(true);
    setCartButtonOne(false);
  };

  const handleVarientButtons = () => {
    setSelectVarient(false);
    SetItemsQuantityBadge(true);
    setCartButtonSecond(true);
  };

  const handlePlus = () => {
    SetItemsQuantity(itemsQuantity + 1);
  };
  const handleMinus = () => {
    if (itemsQuantity === 1) {
    } else {
      SetItemsQuantity(itemsQuantity - 1);
    }
  };

  const handleCartButtonSecond = () => {
    setCartButtonSecond(false);
    SetItemsQuantityBadge(false);
    setCartButtonOne(true);
  };

  const handleSelect = (item) => {
    setSelectedIds( (prevstate)=> {
      if (prevstate.includes(item)) {
        let arr = [...prevstate];
      return arr.filter((data)=>data !=item)
      } else {
        return [...prevstate,item]
    }
    })
  }

  return (
    <div
      className={`card border-[#E1E1E1] border relative  cursor-pointer max-w-[640px] w-full rounded-2xl`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >

        {isSelecting && (
          <div
            className={`wishlist-checkbox gap-1 absolute right-2 top-2 px-1 py-1  rounded-full`}
          >
            <input type="checkbox" onChange={(e) => handleSelect(item.id)}/>
          </div>
        )}


        {checker?<></>:
          <div
            className={`rating gap-1 absolute right-2 top-2 flex justify-center items-center md:p-1 lg:p-2 bg-[#1983FF33] rounded-full w-[26px] h-[26px] md:w-8 md:h-8 lg:w-9 lg:h-9 ${
              item?.whishlist?.length > 0
                ? "text-blue-500 duration-500 ease-in-out"
                : "text-white"
            }`}
            onClick={() => handleHeartClick(item.id)}
          >
            <FaHeart className="w-[17px] h-[15px] md:w-[21px] md:h-[18px] lg:w-6 lg:h-[21px] cursor-pointer" />
          </div>
        }

      <div className={` gap-1 absolute left-2 mt-2 `}>
        {((((item.price - item.discountprice) / item.price) * 100).toFixed(2) !==
          "0.00" && item.discountprice) && (
          <p className="text-[black] bg-[#FFFFFF80] bg-opacity-10 py-[1.7px] px-[6.9px] md:py-[2px] md:px-[8.5px] lg:px-[10px] rounded-[22px] md:rounded-[28px] lg:rounded-[31px] text-[9px] md:text-[11px] lg:text-[13px] font-bold">
            {(((item.price - item.discountprice) / item.price) * 100).toFixed(
              2
            )}
            %
          </p>
        )}
      </div>
      {/* heart and discount end here */}

      {/* image start here  */}
      <Link to={
  !auc && !admarketplace ? `/single-product/${item.slug}` :
  auc ? `/ad-market-place/auction/single-product/${item.slug}` :
  admarketplace ? `/ad-market-place/single-product/${item.slug}` :
  `/single-product/${item.slug}` // Default case
}>
  <img
    src={
      item.image ||
      item.product_image ||
      item.img ||
      "https://as2.ftcdn.net/v2/jpg/02/44/42/79/1000_F_244427911_aoHHulebtYy4wLpncBBuWqCTNFKolcCB.jpg"
    }
    alt="Shoes"
    className="w-full h-[40vw] min-h-[200px] md:h-[19vw] object-cover rounded-2xl"
  />
</Link>

      {/* Image end here */}
      <div
        className={` absolute bottom-[6.5rem] xl:bottom-[7rem] flex w-full gap-1 items-center justify-center px-3 md:px-1 xl:px-3 ${
          selectVarient ? " flex slide-from-right" : "hidden slide-to-left"
        }`}
      >
        <div
          onClick={handleVarientButtons}
          className="bg-white py-[1.46px] px-[7.2px] md:py-[1.79px] md:px-[8.9px] xl:py-[2px] xl:px-[10px] rounded-full text-[11px] md:text-[11.7px] xl:text-[13px]  font-bold text-black"
        >
          100ml
        </div>
        <div
          onClick={handleVarientButtons}
          className="bg-white py-[1.46px] px-[7.2px] md:py-[1.79px] md:px-[8.9px] xl:py-[2px] xl:px-[10px]  rounded-full text-[11px] md:text-[11.7px] xl:text-[13px] font-bold text-black"
        >
          300ml
        </div>
        <div
          onClick={handleVarientButtons}
          className="bg-white py-[1.46px] px-[7.2px] md:py-[1.79px] md:px-[8.9px] xl:py-[2px] xl:px-[10px]  rounded-full text-[11px] md:text-[11.7px] xl:text-[13px] font-bold text-black"
        >
          600ml
        </div>
      </div>

      <div
        className={`flex w-full justify-center items-center absolute bottom-[6.5rem] xl:bottom-[7rem] ${
          itemsQuantityBadge ? " flex slide-from-right" : "hidden"
        }`}
      >
        <div className="flex items-center   px-4  bg-white rounded-full ">
          <div onClick={handleMinus} className="px-1 mr-1">
            -
          </div>
          <div className="px-1">{itemsQuantity}</div>
          <div onClick={handlePlus} className="px-1 ml-1">
            +
          </div>
        </div>
      </div>

      <div className="px-3 py-2 flex flex-col gap-[2px]">
        <Link to={
  !auc && !admarketplace ? `/single-product/${item.slug}` :
  auc ? `/ad-market-place/auction/single-product/${item.slug}` :
  admarketplace ? `/ad-market-place/single-product/${item.slug}` :
  `/single-product/${item.slug}` // Default case
}>
          <h2 className="font-semibold text-xs md:text-sm lg:text-base overflow-hidden text-nowrap">
            {stringConcat(item.name, 23)}
          </h2>
        </Link>
        {item.total_ratings ? (
          <>
            <div className="h-4 flex items-center">
              <div
                className={`  ${
                  isHovered
                    ? "hidden"
                    : "flex gap-2 items-center slide-from-top w-fit "
                }`}
              >
                <div className="rating rating-xs space-x-1 h-[9px] md:h-[10px] lg:h-3 ">
                  {generateStarRating(item.total_ratings)}
                </div>
                <div className="text-[#8C8C8C] text-[7px] md:text-[8px] lg:text-[10px]">
                  {item.total_rating}
                </div>
              </div>
              <div
                className={`${
                  isHovered
                    ? "flex items-center gap-1 slide-from-bottom "
                    : "hidden "
                }`}
              >
                <img
                  className="w-4 h-4 rounded-full"
                  src={
                    item.review_image
                      ? item.review_image
                      : item.img
                      ? item.img
                      : "https://as2.ftcdn.net/v2/jpg/02/44/42/79/1000_F_244427911_aoHHulebtYy4wLpncBBuWqCTNFKolcCB.jpg"
                  }
                  alt=""
                />
                <p className="text-[10px] overflow-hidden text-nowrap">
                  {stringConcat(item.random_review, 25)}
                </p>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="card-actions justify-between items-center mt-1">
          <Link
            to={
              !auc && !admarketplace ? `/single-product/${item.slug}` :
              auc ? `/ad-market-place/auction/single-product/${item.slug}` :
              admarketplace ? `/ad-market-place/single-product/${item.slug}` :
              `/single-product/${item.slug}` // Default case
            }
            className="flex items-center gap-1"
          >
            <h5 className="font-semibold  text-[13px] md:text-sm lg:text-base">
              ${item.discountprice || item.price}
            </h5>
            <span className=" text-[#8C8C8C] font-medium text-[9px] md:text-[11px] lg:text-xs line-through">
              {item.price == item.discountprice ||!item.discountprice ? "" : `$${item.price}`}
            </span>
          </Link>
          <Link
            to={
              !auc && !admarketplace ? `/single-product/${item.slug}` :
              auc ? `/ad-market-place/auction/single-product/${item.slug}` :
              admarketplace ? `/ad-market-place/single-product/${item.slug}` :
              `/single-product/${item.slug}` // Default case
            }
            // onClick={handleCartButtonOne}
            className={` rounded-md bg-white text-[#1983FF]  cursor-pointer h-[18px] w-[17px] md:h-[22px] md:w-[21px] lg:h-[25px] lg:w-[24px] block`}
          >
            <img
              src="/assets/images/top-sell/top-sell-cart-icon.png"
              alt="cart-button"
              className="w-full h-full"
            />
          </Link>
          {/* <div
            onClick={handleCartButtonSecond}
            className={`rounded-md bg-white text-[#1983FF] text-xs cursor-pointer h-[18px] w-[17px] md:h-[22px] md:w-[21px] lg:h-[25px] lg:w-[24px] ${
              cartButtonSecond ? "block" : "hidden"
            }`}
          >
            <img
              src="./assets/images/top-sell/plus-button.png"
              alt="cart-button"
              className="w-full h-full"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}
