import React, { useRef, useState } from "react";

import BreadCrums from "../../Common/BreadCrums";
import PaymentAddNewModal from "./PaymentAddNewModal";
import PaymentEditModal from "./PaymentEditModal";

export default function UserDashboardPaymentMethod() {
  return (
    <div className="px-4 flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          {
            name: "Payment Method",
            path: "",
          },
        ]}
      />
      {/* save address card */}
      <div className="flex flex-col gap-3">
        <div className="flex flex-col md:flex-row gap-6 ">
          <div className="flex relative w-full">
            <div
              // key={box.id}
              className="cursor-pointer w-full  relative border-2 p-3 md:p-6 rounded-md "
              //   onClick={() => handleRectangleClick(box.id)}
            >
              {/* edit modal address from button */}
              <div
                onClick={() =>
                  document
                    .getElementById("add_new_payment_edit_modal_form")
                    .showModal()
                }
                // onClick={handleModal}
                className="absolute right-3 top-1 text-sm text-[#C19A6B]"
              >
                Edit
              </div>

              <div className="bg-white flex flex-col md:gap-2 w-full h-full rounded-md px-2 md:px-5 py-2">
                {/* Name */}
                <div className="flex ">
                  <div className="text-[#8C8C8C] text-sm w-40">
                    Cradholder Name:
                  </div>
                  <div className="text-sm font-semibold flex-1">
                    Muhammad Danyal
                  </div>
                </div>

                {/* Phone */}
                <div className="flex ">
                  <div className="text-[#8C8C8C] text-sm w-40">
                    Crad Number:
                  </div>
                  <div className="text-sm font-semibold flex-1">
                    1234 1234 1234 1223
                  </div>
                </div>

                {/* Email */}
                <div className="flex ">
                  <div className="text-[#8C8C8C] text-sm w-40">
                    Epiray Date:
                  </div>
                  <div className="text-sm font-semibold flex-1">09 / 27</div>
                </div>

                {/* Address */}
                <div className="flex">
                  <div className="text-[#8C8C8C] text-sm w-40">CVV:</div>
                  <div className="text-sm font-semibold flex-1">123</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex relative w-full">
            <div
              // key={box.id}
              className="cursor-pointer w-full  relative border-2 p-3 md:p-6 rounded-md "
              //   onClick={() => handleRectangleClick(box.id)}
            >
              {/* edit modal address from button */}
              <div
                onClick={() =>
                  document
                    .getElementById("add_new_payment_edit_modal_form")
                    .showModal()
                }
                // onClick={handleModal}
                className="absolute right-3 top-1 text-sm text-[#C19A6B]"
              >
                Edit
              </div>

              <div className="bg-white flex flex-col md:gap-2 w-full h-full rounded-md px-2 md:px-5 py-2">
                {/* Name */}
                <div className="flex ">
                  <div className="text-[#8C8C8C] text-sm w-40">
                    Cradholder Name:
                  </div>
                  <div className="text-sm font-semibold flex-1">
                    Muhammad Danyal
                  </div>
                </div>

                {/* Phone */}
                <div className="flex ">
                  <div className="text-[#8C8C8C] text-sm w-40">
                    Crad Number:
                  </div>
                  <div className="text-sm font-semibold flex-1">
                    1234 1234 1234 1223
                  </div>
                </div>

                {/* Email */}
                <div className="flex ">
                  <div className="text-[#8C8C8C] text-sm w-40">
                    Epiray Date:
                  </div>
                  <div className="text-sm font-semibold flex-1">09 / 27</div>
                </div>

                {/* Address */}
                <div className="flex">
                  <div className="text-[#8C8C8C] text-sm w-40">CVV:</div>
                  <div className="text-sm font-semibold flex-1">123</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="w-full ">
            <div
              onClick={() =>
                document
                  .getElementById("add_new_payment_modal_form")
                  .showModal()
              }
              className="cursor-pointer w-full h-[120px] md:h-[148px] flex flex-col items-center justify-center  relative border-2 border-dashed rounded-md"
            >
              <div className="bg-[#D9D9D9] rounded-full w-11 h-11 flex items-center justify-center ">
                <svg
                  width="25"
                  height="26"
                  viewBox="0 0 25 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3438 2.625V23.3783"
                    stroke="white"
                    stroke-width="4.15094"
                    stroke-linecap="square"
                  />
                  <path
                    d="M2.10156 13.0039H22.5851"
                    stroke="white"
                    stroke-width="4.15094"
                    stroke-linecap="square"
                  />
                </svg>
              </div>
              <p className="text-sm font-normal">Add New ddress</p>
            </div>
          </div>
          <div className="w-full"></div>
        </div>
      </div>
      <PaymentAddNewModal />
      <PaymentEditModal />
    </div>
  );
}
