import React from "react";

import UserDashboardAffilliateAvailableProduct from "../../../../components/User-Dashboard-Components/User-Dashboard-Affiliate-page-Available-product-components/UserDashboardAffilliateAvailableProduct";
import UserDashboardSellingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardSellingLayout";

export default function UserdashboardAvailableAffilliateProductPage() {
  return (
    <>
      <UserDashboardSellingLayout>
        <UserDashboardAffilliateAvailableProduct />
      </UserDashboardSellingLayout>
    </>
  );
}
