import React, { useRef, useState } from "react";
import { BsFillPauseFill } from "react-icons/bs";
import { FaHeart, FaPlay } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function VideoCard({ item }) {
  const [isHeartFilled, setIsHeartFilled] = useState(false);
  const navigate = useNavigate();

  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const handlePlayClick = () => {
    if (videoRef.current) {
      if (isVideoPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsVideoPlaying(!isVideoPlaying);
    }
  };
  return (
    <>
      <div
        key={item}
        className={` rounded-xl relative overflow-hidden w-full`}
      >
        {/* heart icon  */}
        <div
          className="absolute top-4 right-4 bg-[#1983FF33] rounded-full p-3  cursor-pointer  flex justify-center items-center h-12 w-12 md:h-[38px] md:w-[38px] lg:h-[54px] lg:w-[54px]"
          // onClick={handleVideoButton}
        >
          <FaPlay className="w-7 h-7 md:h-[24px] md:w-[24px] lg:h-7 lg:w-7 text-white" />
        </div>
        {/* image  */}
          <div className="relative">
            <div className="absolute top-[45%]  z-20 right-[45%] bg-white rounded-full cursor-pointer w-11 h-11 lg:w-10 lg:h-10 md:w-7 md:h-7 flex items-center justify-center">
              <button onClick={handlePlayClick}>
                {isVideoPlaying ? (
                  <BsFillPauseFill className="w-4 h-4 lg:h-5 xl:w-5  " />
                ) : (
                  <FaPlay className="w-4 h-4 lg:h-5 xl:w-5 " />
                )}
              </button>
            </div>

            {/* <div className="relative w-full max-h-[535px] sm:max-h-[435px] lg:max-h-[535px] h-full"> */}
            <div className="relative w-full h-[430px]  object-cover bg-black">
                <video
                    src={item?.video}
                    ref={videoRef}
                    className="w-full h-full z-20 object-cover"
                ></video>
                <img
                    src={item?.thumbnail?item.thumbnail:"https://images.pexels.com/photos/14438483/pexels-photo-14438483.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}
                    alt="Thumbnail"
                    className={`${isVideoPlaying?"hidden":"absolute"} inset-0 w-full h-full z-10 object-cover cursor-pointer`}
                    onClick={handlePlayClick}
                />
            </div>

          </div>

        <div className="absolute  z-20 px-4 bottom-2  w-full flex justify-between items-center ">
          <div className="flex gap-2 items-center text-white">
            <img
              className="w-[55px] h-[55px] rounded-full md:w-11 md:h-11 lg:w-11 lg:h-11"
              src={item?.image?item.image:"https://as2.ftcdn.net/v2/jpg/02/44/42/79/1000_F_244427911_aoHHulebtYy4wLpncBBuWqCTNFKolcCB.jpg"}
              alt=""
            />
            <span className="font-semibold cursor-pointer text-[17px] md:text-xs lg:text-lg xl:text-xl">
              {item.user_name?item.user_name:"Creator Name"}
            </span>
          </div>

        </div>
      </div>
    </>
  );
}
