import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearMessage,
  loginUser,
  resetSignout,
} from "../../store/categorySlice";
import { toast } from "react-toastify";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

export default function LoginModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, step, token, loading, message, error } = useSelector(
    (state) => state.category
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleLogin = () => {
    if (email === "" && password === "") {
      toast.error("Please fill all fields");
    } else {
      dispatch(loginUser({ email, password }));
    }
  };

  useEffect(() => {
    if (!loading) {
      if (message) {
        toast.success(message);
        document.getElementById("login_modal_form").close();
        //   if(step==1){
        //     navigate("/dashboard/otp-verification");
        //   } else {
        //     navigate("/user-dashboard");
        //   }
      }
      if (error) {
        if (error == "Unauthenticated.") {
        } else {
          toast.error(error);
        }
      }
      dispatch(clearMessage());
    }
    dispatch(resetSignout());
  }, [loading, message, error, dispatch]);

  // for password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    <>
      <dialog id="login_modal_form" className="modal ">
        <div className="modal-box bg-white max-w-3xl  rounded-md">
          <form method="dialog">
            <button
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              onClick={() => {
                document.getElementById("login_modal_form").close();
              }}
            >
              ✕
            </button>
          </form>
          <div className="flex justify-center items-center px-4 md:px-0 ">
            <div
              className="w-full md:w-fit p-4 md:p-14  flex flex-col items-center rounded-3xl"
              style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
            >
              <img
                className="mx-auto"
                src="/assets/images/login-logo.svg"
                alt=""
              />
              <div className="font-bold tracking-wide text-lg mt-2 mb-2 text-center">
                Login to Account
              </div>
              <div className="text-sm text-center font-bold tracking-wide">
                Login to your Wizalot account!
              </div>
              <div className="w-full md:w-[30rem] mt-4">
                <label className="form-control ">
                  <div className="label">
                    <span className="text-sm font-bold tracking-wide">
                      Enter Your Email Address
                    </span>
                  </div>
                  <input
                    type="email"
                    placeholder="mike.tyson@mail.com"
                    className="p-1 md:p-2 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                <label className="form-control mt-2">
                  <div className="label">
                    <span className="text-sm font-bold tracking-wide">
                      Enter Your Password
                    </span>
                  </div>
                  <span className="flex items-center relative">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      placeholder="********"
                      className="p-1 md:p-2 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {passwordVisible ? (
                      <IoEyeOutline
                        className="absolute right-4 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <IoEyeOffOutline
                        className="absolute right-4 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </span>
                </label>
                <p
                  onClick={() => navigate("/dashboard/forgot-password")}
                  className="text-[#C19A6B] hover:underline text-end mt-2 tracking-wide cursor-pointer"
                >
                  Forgot Password?
                </p>

                <button
                  disabled={loading}
                  onClick={handleLogin}
                  className="bg-[#C19A6B] w-full rounded-lg py-2 mt-1 h-8 text-sm md:text-base text-white font-bold tracking-wider"
                >
                  {!loading ? (
                    "Login"
                  ) : (
                    <span className="loading loading-spinner loading-md"></span>
                  )}
                </button>
              </div>
              <p className="mt-1">
                Don't have an account?{" "}
                <span
                  onClick={() => navigate("/dashboard/signup")}
                  className="text-[#C19A6B] hover:font-bold cursor-pointer"
                >
                  Sign Up
                </span>
              </p>
            </div>
          </div>
        </div>
      </dialog>
    </>
  );
}
