import React from "react";
import Search from "../../components/Search-Components/Search";

export default function SearchPage() {
  return (
    <div>
      <Search />
    </div>
  );
}
