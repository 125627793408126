import React from "react";
import { Link } from "react-router-dom";

export default function StoreSquareMallCard({ data }) {
  return (
    <>
      <div
        className="w-full h-[242px]  xl:w-[23.8rem] rounded-md px-6 py-6"
        style={{ boxShadow: "0px 23.08px 46.16px 0px #0508281A" }}
      >
        <div className="flex justify-between items-center">
          {/* img + name + store count */}
          <div className="flex items-center gap-3">
            <div>
              <img
                className="w-[3.75rem] h-[3.75rem]"
                src={data.mall_image}
                alt=""
              />
            </div>
            <div>
              <h1 className="text-sm font-bold tracking-wide">{data.mall_name}</h1>
              <p className="text-[#828282] text-xs">{data.storesCount} Stores</p>
            </div>
          </div>

          {/* button */}
          <Link
            to={`/store-square/visit-mall/${data.slug}`}
            className="py-[0.20rem] px-6 text-sm border-[0.09rem] border-[#C19A6B] font-bold tracking-wider text-[#C19A6B] rounded-md"
          >
            Visit Mall
          </Link>
        </div>

        {/* stores */}
        <div className="mt-4">
          <div className="flex justify-between flex-wrap">
            {data?.vendors?.length > 0 && data?.vendors.map((item) => {
              
              return (
                <Link to={"/vendor-shop-front/"+item.slug} >
                <img
                className="h-[3.75rem] w-[3.75rem]"
                src={item.shop_picture}
                alt=""
              /></Link>)
            })}
            
            
          </div>
          
        </div>
      </div>
    </>
  );
}
