import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaHeart, FaPlay } from "react-icons/fa";
import BreadCrums from "../../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import VideoCard from "../../../Common/VideoCard";
import { getInreels } from "../../../../store/categorySlice";

export default function UserDashboardAffilliateInreels() {
  const dispatch = useDispatch()
  const {loading, data} = useSelector((state) => state.category)
  const [reels, setReels] = useState([]);
  const [isHeartFilled, setIsHeartFilled] = useState(false);
  const handleHeartClick = () => {
    setIsHeartFilled(!isHeartFilled);
  };

  useEffect(() => {

    dispatch(getInreels())

  }, [])

  return (
    <>
      <div className="px-4">
        {/* buttons */}
        <div className="flex justify-between items-center">
          <BreadCrums
            breadCrum={[
              {
                name: "Inreels",
              },
            ]}
          />{" "}
          <div>
            <Link
              to="/user-dashboard/affiliate-inReels/upload-inReels"
              className="px-6 py-2 md:px-9 md:py-[10px] text-xs md:text-[15px] bg-[#C19A6B] flex items-center justify-center rounded-lg text-white text-center font-semibold"
            >
              Add InReel
            </Link>
          </div>
        </div>
        {/* search */}
        {/* <div className="relative flex items-center w-full py-4">
          <img
            className="absolute left-3"
            src="/assets/icons/search.svg"
            alt=""
          />

          <input
            className="w-full px-9 py-[10px] rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
            type="search"
            placeholder="Search inReels..."
          />
        </div> */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 mt-4 gap-2 w-full">
          {data?.inReels?.data?.map((item) => (
            <VideoCard item={item}  />
          ))}
        </div>
      </div>
    </>
  );
}
