import React, { useEffect } from "react";
import AuctionHomeFeaturedProduct from "../../../../components/Ad-MarketPlace-Components/Auction-Components/Auction-HomePage/AuctionHomeFeaturedProduct";
import { useDispatch, useSelector } from "react-redux";
import {  getBidHistory } from "../../../../store/frontendSlice";
import { useParams } from "react-router-dom";
import AuctionBidHistoryComponent from "../../../../components/Ad-MarketPlace-Components/Auction-Components/Auction-Bid-History/AuctionBidHIstory";
export default function BidHistory() {
  const { bidHistory} = useSelector(state => state.frontend);
  const dispatch = useDispatch();
  const {product} = useParams();
  useEffect(() => {

dispatch(getBidHistory(product))
  },[product])
  return (
    <div className="w-full flex flex-col gap-12">
      <AuctionBidHistoryComponent data={bidHistory?.auctionProduct} auc={ "auc"} />
     
    </div>
  );
}
