import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { ticketsList, ticketsListSearch } from "../../../store/categorySlice";
import Loader from "../../Common/Loader";

const Tickets = () => {
  const { data, loading } = useSelector((state) => state.category);
  const dispatch = useDispatch();

  const [ticketsListData, setTicketsListData] = useState([]);
  const [search, setSearch] = useState("");

  // pagination-state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    dispatch(ticketsList());
  }, []);

  useEffect(() => {
    if (search) {
      dispatch(ticketsListSearch(search));
    } else {
      dispatch(ticketsList());
    }
  }, [search, dispatch]);

  useEffect(() => {
    if (data?.tickets) {
      setTicketsListData(data?.tickets);
    }
  }, [data, loading]);

  // pagination Function components
  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    // console.log(totalNumberOfPages);
    const pages = [];

    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalNumberOfPages, currentPage + 1);

    if (endPage - startPage < 2) {
      if (startPage === 1) {
        endPage = Math.min(totalNumberOfPages, startPage + 2);
      } else if (endPage === totalNumberOfPages) {
        startPage = Math.max(1, endPage - 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-2 py-1 rounded ${
            currentPage === i
              ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
              : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
          }`}
        >
          {i}
        </button>
      );
    }
    // console.log(pages);
    return <div className="pagination flex gap-2">{pages}</div>;
  }

  // pagination data handling + handle page change
  useEffect(() => {
    if (data?.pagination && data?.pagination?.total) {
      let totalLength = data?.pagination?.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [data]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(ticketsList({ arg1: "page", arg2: page }));
    }
  };

  // pagination index variable
  const totalTicketsCount = data?.pagination?.total || 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalTicketsCount);

  return (
    <>
      <div className="px-4 flex flex-col gap-4">
        {/* name */}
        <div className="flex items-center justify-between">
          <BreadCrums
            breadCrum={[
              {
                name: "User Support",
                path: "/user-dashboard/help-center",
              },
              {
                name: "Tickets",
              },
            ]}
          />
          <Link
            to="/user-dashboard/tickets/create-tickets"
            className="bg-[#C19A6B] px-7 py-2 md:px-9 md:py-[10px] text-xs xl:text-sm rounded-lg text-white"
          >
            Create Ticket
          </Link>
        </div>

        {/* search */}
        <div className="w-full flex gap-3 items-center">
          <div className="relative w-full flex items-center">
            <img
              className="absolute left-4"
              src="/assets/icons/search.svg"
              alt=""
            />

            <input
              className="w-full py-2 md:py-3  px-8 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-xs md:text-sm"
              type="search"
              placeholder="Search Tickets Here ..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        {/* filter */}
        {/* <div className="flex gap-6 py-3">
          <NavLink
            onClick={() => setActiveRole("Pending")}
            className={`flex items-center font-semibold text-xs md:text-sm gap-2 text-[#828282] uppercase ${
              activeRole === "Pending"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            PENDING
            <span
              className={`text-xs md:text-sm font-semibold px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Pending"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              {instockCount}
            </span>
          </NavLink>
          <NavLink
            onClick={() => setActiveRole("Resolved")}
            className={`flex items-center font-semibold text-xs md:text-sm gap-2 text-[#828282] uppercase ${
              activeRole === "Resolved"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            RESOLVED
            <span
              className={`text-xs md:text-sm font-semibold px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Resolved"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              {outofstockCount}
            </span>
          </NavLink>
          <div className="">
            <div className="relative">
              <input
                className="bg-[#FAFAFA] cursor-pointer text-[10px] md:text-[12px]  font-semibold text-[#828282] rounded-lg py-[6px] px-2 md:px-[20px] border border-[#EBF0ED] outline-none"
                type="text"
                value={selectedCategory}
                readOnly
                onClick={toggleCategoryDropdown}
                placeholder="CATEGORY"
              />
              {isCategoryDropdownVisible ? (
                <GoChevronUp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500" />
              ) : (
                <GoChevronDown className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500" />
              )}

              {isCategoryDropdownVisible && (
                <div className="absolute z-10 bg-white border border-[#EBF0ED]  overflow-hidden  rounded-lg mt-2 w-full">
                  {categoryOptions.map((category) => (
                    <div
                      key={category}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => selectCountry(category)}
                    >
                      {category}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div> */}

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            {/* Table Header */}
            <div className="min-w-[768px] md:min-w-[1024px] ">
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm font-semibold border-b text-[#6B6B6B]   border-gray-300 justify-between">
                <span className="w-[16.6%] text-left">TICKET ID</span>

                <span className="w-[16.6%] text-left">EMAIL</span>

                <span className="w-[16.6%] text-left">STATUS</span>
                <span className="w-[25%] text-left">HEADLINE</span>
                <span className="w-[10%] text-left">ACTION</span>
              </div>

              {/* Table Body */}
              <div className="flex flex-col">
                {loading ? (
                  <div className="flex justify-center py-4">
                    <Loader />
                  </div>
                ) : ticketsListData.length > 0 ? (
                  ticketsListData.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className=" flex justify-between items-center text-[10px] md:text-sm border-b border-[#EBF0ED] text-[#18120F] font-semibold  p-2 md:p-4 rounded-b-md"
                      >
                        <span className="w-[16.6%]  text-left">
                          {item.ticket_id}
                        </span>
                        <span className="w-[16.6%] text-left">
                          {item.user.email}
                        </span>
                        <div className="w-[16.6%] text-left">
                          <span
                            className={`${
                              item.status == "0"
                                ? "bg-[#FFFBF0]  px-2 py-[0.189rem] border border-[#FEC600] text-[#FEC600] rounded-xl"
                                : "bg-[#F6FFF7]  px-2 py-[0.189rem] border border-[#15CA32] text-[#15CA32] rounded-xl"
                            }`}
                          >
                            {item.status == "0" ? "Pending" : "Resolve"}
                          </span>
                        </div>

                        <span className="w-[25%] text-left text-nowrap">
                          {item.ticket_headline}
                        </span>
                        <span className="w-[10%] text-left">
                          <Link to={`/user-dashboard/tickets/tickets-details/${item.id}`}>
                            <img src="/assets/icons/view.svg" alt="" />
                          </Link>
                        </span>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center font-bold tracking-wide py-4">
                    No Tickets Found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Pagination start here */}
        {!loading && data && ticketsList.length > 10 ? (
          <>
            <div className="flex flex-col md:flex-row justify-between items-center">
              <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
                SHOWING {startIndex + 1}-{endIndex} OF {totalTicketsCount}{" "}
                RESULTS
              </span>
              <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
                {/* Previous Button */}
                <div
                  className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED]${
                    currentPage === 1 ? "opacity-50" : ""
                  }`}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <svg
                    className={`${
                      currentPage === 1
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-blue-500"
                    }`}
                    width="6"
                    height="12"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 13L1 7L7 1"
                      stroke="#828282"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* Page Numbers */}
                <div className="flex items-center gap-2">
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                </div>
                {/* Next Button */}
                <div
                  className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px]  cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED]${
                    currentPage === totalPages ? "opacity-50" : ""
                  }`}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <svg
                    className={`${
                      currentPage === totalPages
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-blue-500"
                    }`}
                    width="6"
                    height="12"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13L7 7L1 1"
                      stroke="#828282"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {/* Pagination end here */}
      </div>
    </>
  );
};

export default Tickets;
