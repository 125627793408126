import { useEffect, useRef, useState } from "react";
import { FaHeart, FaPause, FaPlay } from "react-icons/fa";
import { Link } from "react-router-dom";
import { stringConcat } from "../../../functions/formatNumber";
import { SlArrowDown } from "react-icons/sl";

const generateStarRatingSecond = (rating) => {
  const stars = [];
  const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
  const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <img key={i} src={filledStar} alt="filled star" className="w-2 h-2" />
      );
    } else {
      stars.push(
        <img
          key={i}
          src={unfilledStar}
          alt="unfilled star"
          className="w-2 h-2"
        />
      );
    }
  }
  return stars;
};

function InspirationVideoPreviewCard({reel}) {
  const [isHeartFilled, setIsHeartFilled] = useState(false);
  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isVideoPlayingId, setIsVideoPlayingId] = useState(false);
  const videoRef = useRef(null);

  const handleHeartClick = () => {
    setIsHeartFilled(!isHeartFilled);
  };

  const handlePlayClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        pauseOtherVideos(videoRef.current);
        setIsVideoPlaying(true);
      } else {
        videoRef.current.pause();
        setIsVideoPlaying(false);
      }
    }
  };  

  const videos = document.querySelectorAll('video');
  
  function pauseOtherVideos(currentVideo) {
    videos.forEach(video => {
      if (video !== currentVideo) {
        video.pause();
      } else {
        setIsVideoPlayingId(video.id)
      }
    });
  }

  // videos.forEach(video => {
  //   video.addEventListener('play', function() {
  //       pauseOtherVideos(video);
  //   });
  // });

  const handleCloseSlider = () => {
    setIsSliderVisible(false); // Close slider
  };

const handleOpenSlider = () => {
    setIsSliderVisible(!isSliderVisible);
  };

  useEffect(()=>{
    console.log(isVideoPlayingId)
  },[isVideoPlayingId])

  return (

    <>
      <div className={`relative h-full z-20 overflow-hidden rounded-[10.63px] w-[280px] md:w-full`}>
          <div className="absolute top-4 right-4 z-20 bg-[#58595933] rounded-full cursor-pointer w-11 h-11 lg:w-10 lg:h-10 md:w-7 md:h-7 flex items-center justify-center">
              <button onClick={()=> {
                setIsVideoPlayingId(reel.id);
                handlePlayClick()}
              }>
                  {isVideoPlayingId!=reel.id ? 
                  <FaPlay className="w-4 h-4 lg:h-5 xl:w-5 text-white" />
                  : isVideoPlaying ?
                  <FaPause className="w-4 h-4 lg:h-5 xl:w-5  text-black" />
                  :
                  <FaPlay className="w-4 h-4 lg:h-5 xl:w-5 text-white" />
                  }
              </button>
          </div>

          <div className="relative w-full h-full">
              <video
                  src={reel.video_url}
                  ref={videoRef}
                  className="w-full h-full z-20 object-cover"
                  id={reel.id}
              ></video>
              <img
                  src={!reel.thumbnail?reel.thumbnail:"https://s3-alpha-sig.figma.com/img/d7d2/be05/a7535c2d55bcccd48072555a44e0b2d6?Expires=1721606400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=M1xCYh8Ax7qOmJwM5jjqKcaLvh23ocD65RihGNAReWfEqlYFxeeW~JXtKoqnY5BF5fEnNc0gWSl-cQlMfrqVBQriFJAGhX69Ib8bR5mjkmoXVC7wmAw87eSiYF0-8bAmWdVhAFHeJ5AzIVtld12tpU9t4e3WtR8WHV2gilpmG7gVESz4ND0jbuzCvU8i3po2qNWgt2HAJWsfz5CyGOvuUsac6E-eI2~hJ-kMXSuSfg8HzkDLpdT4zJQFGL1614OAQS9fVlqb1j0TErXc1DTqBEjyr-GDYlw3w1YRyxEzPDc0k4cXkVk1cafeeeT3KLZVdkZY~11V6iqXqZ6OkUSdzw__"}
                  // src="https://s3-alpha-sig.figma.com/img/d7d2/be05/a7535c2d55bcccd48072555a44e0b2d6?Expires=1721606400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=M1xCYh8Ax7qOmJwM5jjqKcaLvh23ocD65RihGNAReWfEqlYFxeeW~JXtKoqnY5BF5fEnNc0gWSl-cQlMfrqVBQriFJAGhX69Ib8bR5mjkmoXVC7wmAw87eSiYF0-8bAmWdVhAFHeJ5AzIVtld12tpU9t4e3WtR8WHV2gilpmG7gVESz4ND0jbuzCvU8i3po2qNWgt2HAJWsfz5CyGOvuUsac6E-eI2~hJ-kMXSuSfg8HzkDLpdT4zJQFGL1614OAQS9fVlqb1j0TErXc1DTqBEjyr-GDYlw3w1YRyxEzPDc0k4cXkVk1cafeeeT3KLZVdkZY~11V6iqXqZ6OkUSdzw__"
                  alt="Thumbnail"
                  className={`${isVideoPlaying===reel.id?"hidden":"absolute"} inset-0 w-full h-full z-10 object-cover cursor-pointer`}
                  // onClick={handleThumbnailClick}
              />
          </div>

          <div className="w-full gap-1 z-20 h-full flex flex-col relative bottom-[104px] sm:bottom-[96px] 2xl:bottom-[72px] px-3">

            <div className="flex w-full items-center justify-end">
              <div onClick={handleOpenSlider} className="flex flex-col cursor-pointer justify-center items-center">
                  <div
                  className={`w-[30px] h-[30px] lg:w-[18px] lg:h-[18px] xl:w-[25px] xl:h-[25px] px-1 py-1 bg-[#58595933] rounded-full flex justify-center items-center text-white`}
                  >
                  <SlArrowDown className="h-4 w-4 lg:h-5 xl:w-5" />
                  </div>
                  <div className="xl:text-xs md:text-[9px] text-[13px] text-white">
                  View
                  </div>
              </div>
            </div>

            <div className="flex w-full items-center justify-between">
              <div className="flex items-center gap-2 ">
                  <div className="  ">
                  <img
                      src={reel.creater_image?reel.creater_image:"https://as2.ftcdn.net/v2/jpg/02/44/42/79/1000_F_244427911_aoHHulebtYy4wLpncBBuWqCTNFKolcCB.jpg"}
                      alt=""
                      className="rounded-full w-[52px] h-[52px] md:w-8 md:h-8 xl:w-11 xl:h-11 bg-cover"
                  />
                  </div>
                  <div className="font-bold text-base md:text-[10px] xl:text-sm text-white  ">
                  {reel.creater}
                  </div>
              </div>
              <div className="flex flex-col justify-center items-center" >
                  <div
                  className={`w-[30px] h-[30px] lg:w-[18px] lg:h-[18px] xl:w-[25px] xl:h-[25px]  px-1 py-1 bg-[#58595933] rounded-full flex justify-center items-center  ${
                      isHeartFilled
                      ? "text-blue-500 duration-500 ease-in-out"
                      : "text-white"
                  }`}
                  onClick={handleHeartClick}
                  >
                  <FaHeart className="h-4 w-4 x:h-5 xl:w-5  cursor-pointer" />
                  </div>
                  <div className="xl:text-xs md:text-[9px] text-[13px] text-white ">
                      {reel.likes}
                  </div>
              </div>
            </div>
          </div>

            {/* Product Card Slider */}
            <div
              className={`absolute bottom-0 left-0 right-0 bg-white transition-transform transform ${
                isSliderVisible ? 'translate-y-0' : 'translate-y-full'
              } z-30`}
            >

            <div className=" w-full flex bg-white flex-col">
                <div className="w-full rounded-md flex justify-between items-center py-1 px-2">
                    <p className="text-sm font-semibold">Related Products</p>
                    <p onClick={handleCloseSlider} className="text-sm hover:font-bold cursor-pointer">x</p>
                </div>
                <div className=" w-full flex flex-col gap-2 p-1">

                    {reel?.products?.length>0? reel.products.slice(0,reel?.products?.length>4?4:reel?.products?.length).map((a)=>(
                    <Link
                        to={reel?.affiliate_code?`/single-product/${a.product_slug}?affiliatecode=${reel?.affiliate_code}`:`/single-product/${a.product_slug}`}
                        className="w-full flex gap-2 items-center border cursor-pointer hover:shadow-md border-opacity-25 border-[#CBCBCB] rounded"
                    >
                        <div className="w-14 h-14 overflow-hidden">
                        <img 
                        src={a.product_image?a.product_image:"https://beforeigosolutions.com/wp-content/uploads/2021/12/dummy-profile-pic-300x300-1.png"}
                        alt="" className="w-full h-full object-cover" />
                        </div>
                        <div className="w-full flex flex-col ">
                        <h2 className="text-xs font-semibold tracking-wide">
                            {stringConcat(a.product_name,16)}
                        </h2>
                        <div className="flex gap-2 items-center w-full ">
                            <div className="rating  space-x-[2px]">
                            {generateStarRatingSecond(a.product_rating)}
                            </div>
                            <div className="text-[13px] text-[#1983FF] font-semibold">
                            ${a.product_price}
                            </div>
                        </div>
                        </div>
                    </Link>
                    )):"No Products"}

                </div>
            </div>
            </div>

      </div>
    </>

  );
}

export default InspirationVideoPreviewCard;



// <div
//   className={`w-full h-[400px] md:h-[272px] xl:h-[383px] rounded-[10.63px] `}
// >
//   <div className=" relative h-full overflow-hidden rounded-[10.63px]">
//     <div className="absolute top-4 right-4 bg-[#58595933] rounded-full cursor-pointer w-11 h-11 lg:w-10 lg:h-10 md:w-7 md:h-7 flex items-center justify-center z-10">
//       <button onClick={handlePlayClick}>
//         {isVideoPlaying ? (
//           <FaPause className="w-4 h-4 lg:h-5 xl:w-5  text-white" />
//         ) : (
//           <FaPlay className="w-4 h-4 lg:h-5 xl:w-5 text-white" />
//         )}
//       </button>
//     </div>

//     <video
//       src={video}
//       ref={videoRef}
//       className=" w-full h-full object-cover"
//     ></video>

//     <div className="w-full flex items-center justify-between relative bottom-14 px-3">
//       <div className="flex  items-center gap-2 ">
//         <div className="  ">
//           <img
//             src={image}
//             alt=""
//             className="rounded-full w-[52px] h-[52px] md:w-8 md:h-8 xl:w-11 xl:h-11 bg-cover"
//           />
//         </div>
//         <div className="font-bold text-base md:text-[10px] xl:text-sm text-white  ">
//           {name}
//         </div>
//       </div>

//       <div className="flex flex-col justify-center items-center ">
//         <div
//           className={`w-[30px] h-[30px] lg:w-[18px] lg:h-[18px] xl:w-[25px] xl:h-[25px]  px-1 py-1 bg-[#58595933] rounded-full flex justify-center items-center  ${
//             isHeartFilled
//               ? "text-blue-500 duration-500 ease-in-out"
//               : "text-white"
//           }`}
//           onClick={handleHeartClick}
//         >
//           <FaHeart className="h-4 w-4 x:h-5 xl:w-5  cursor-pointer" />
//         </div>
//         <div className="xl:text-xs md:text-[9px] text-[13px] text-white ">
//           {likes}
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
