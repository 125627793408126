// Preloader.jsx

import React, { useEffect, useState } from 'react';
import './preloader.css'; // Import the CSS file
import logo from "./wizalot.png"
const Preloader = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Simulate loading completion after a delay (you can replace this with actual loading logic)
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 3000); // Adjust delay as needed

    // Clear timeout on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div id="preloader" className={loaded ? 'slide-up' : ''}>
      <div id="loader">
        <div className="loader-container">
        <img src={logo} alt="Logo" id="loader-img" />
          <div id="superouter-line"></div>
          <div id="outer-line"></div>
          <div id="middle-line"></div>
          <div id="inner-line"></div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
