import CartPage from "../../pages/Main-Shop/Cart-Page/CartPage";

const cartRoutes = [
  {
    path: "/cart",
    element: <CartPage />,
  },
];

export default cartRoutes;
