import HelpCenter from "../../../../components/User-Dashboard-Components/User-Dashoard-Selling-User-Support-Components/HelpCenter";
import UserDashboardBuyingLayout from "../../../../Layout/User-Dashboard-layout/UserDashboardBuyingLayout";

export default function HelpCenterPage() {
  return (
    <>
      <UserDashboardBuyingLayout>
        <HelpCenter />
      </UserDashboardBuyingLayout>
    </>
  );
}
