import React, { useState } from "react";

export default function LocationModal({ setFunction, countries, location, setLocation }) {
  const [selectedCountry, setSelectedCountry] = useState(null);
  // const [selectedCity, setSelectedCity] = useState(null);

  // const countryCities = {
  //   Pakistan: ["Peshawar", "Islamabad", "Karachi", "Lahore", "Swabi", "Mardan"],
  //   India: ["Delhi", "Mumbai", "Kolkata", "Chennai", "Bangalore", "Hyderabad"],
  //   USA: [
  //     "New York",
  //     "Los Angeles",
  //     "Chicago",
  //     "Houston",
  //     "Phoenix",
  //     "Philadelphia",
  //   ],
  //   UAE: [
  //     "Dubai",
  //     "Abu Dhabi",
  //     "Sharjah",
  //     "Ajman",
  //     "Fujairah",
  //     "Ras Al Khaimah",
  //   ],
  //   Russia: [
  //     "Moscow",
  //     "Saint Petersburg",
  //     "Novosibirsk",
  //     "Yekaterinburg",
  //     "Kazan",
  //     "Chelyabinsk",
  //   ],
  //   England: [
  //     "London",
  //     "Birmingham",
  //     "Manchester",
  //     "Liverpool",
  //     "Leeds",
  //     "Newcastle",
  //   ],
  //   Brazil: [
  //     "São Paulo",
  //     "Rio de Janeiro",
  //     "Brasília",
  //     "Salvador",
  //     "Fortaleza",
  //     "Belo Horizonte",
  //   ],
  // };

  // const handleCountrySelect = (country) => {
  //   setSelectedCountry(country);
  // };

  // const handleCitySelect = (city) => {
  //   setSelectedCity(city);
  // };

  return (
    <>
      <div className={` ${location ? "block" : "hidden"} `}>
        <div
          onMouseLeave={() => setLocation(false)}
          className="absolute  xl:w-[30%] ml-12  xl:ml-40 -mt-36  xl:-mt-6   z-50 mx-auto bg-white  rounded-2xl border-[#D9D9D9] p-1 xl:p-4"
        >
          <div className="w-full  flex justify-between ">
            {/* Render countries */}
            <div className="w-full px-2  xl:px-5">
              <div className="text-[8px] xl:text-sm font-bold tracking-wide border-b xl:pb-2 flex items-center gap-3 ">
                <span>Country</span>
              </div>
              <div className="text-[8px] xl:text-sm font-[300] text-[#8a8a8a]">
                {countries?.map((country, index) => (
                  <div
                    key={index}
                    className={`cursor-pointer xl:p-2 rounded-md ${
                      selectedCountry === country.name ? "bg-gray-200 " : ""
                    }`}
                    onClick={() => {
                      setFunction("country",country.name)
                      setSelectedCountry(country.name)
                    }}
                  >
                    {country.name}
                  </div>
                ))}
                  <div
                    className={`cursor-pointer xl:p-2 rounded-md ${
                      selectedCountry === null ? "bg-gray-200 " : ""
                    }`}
                    onClick={() => {
                      setFunction("country",null)
                      setSelectedCountry(null)
                    }}
                  >
                    None
                  </div>
              </div>
            </div>
            {/* <span className="border-l"></span> */}
            {/* Render cities based on selected country */}
            {/* <div className="w-full px-2 xl:px-5">
              <div className="text-[8px] xl:text-sm font-bold border-b xl:pb-2 tracking-wide flex items-center gap-3 ">
                <span>City</span>
              </div>
              <div className="text-[8px] xl:text-sm font-[300] text-[#8a8a8a]">
                {selectedCountry &&
                  countryCities[selectedCountry].map((city, index) => (
                    <div
                      onClick={() => handleCitySelect(city)}
                      key={index}
                      className={`cursor-pointer xl:p-2 rounded-md ${
                        selectedCity === city ? "bg-gray-200  " : ""
                      }`}
                    >
                      {city}
                    </div>
                  ))}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
