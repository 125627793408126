import PaymentsPage from "../../pages/Main-Shop/Payments-page/PaymentsPage";

const paymentRoutes = [
  {
    path: "/payments",
    element: <PaymentsPage />,
  },
];

export default paymentRoutes;
