import React, { useState, useRef } from "react";
import { FaHeart, FaPlay, FaPause } from "react-icons/fa";

export default function ReelCard({ reel, onVideoButtonClick }) {
  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isHeartFilled, setIsHeartFilled] = useState(false);

  const handleVideoButton = () => {
    onVideoButtonClick();
    handlePlayClick();
  };

  const handleHeartClick = () => {
    setIsHeartFilled(!isHeartFilled);
  };

  const handlePlayClick = () => {
    if (videoRef.current) {
      if (isVideoPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsVideoPlaying(!isVideoPlaying);
    }
  };

  return (
    <div className="relative z-20 overflow-hidden rounded-[10.63px] w-full h-full">
      <div className="absolute top-4 right-4 z-20 bg-[#58595933] rounded-full cursor-pointer w-11 h-11 lg:w-10 lg:h-10 md:w-7 md:h-7 flex items-center justify-center">
        <button onClick={handleVideoButton}>
          {isVideoPlaying ? (
            <FaPause className="w-4 h-4 lg:h-5 xl:w-5 text-white" />
          ) : (
            <FaPlay className="w-4 h-4 lg:h-5 xl:w-5 text-white" />
          )}
        </button>
      </div>

      <div className="relative w-full max-h-[535px] sm:max-h-[435px] lg:max-h-[535px] h-full">
        <video
          src={reel?.video}
          ref={videoRef}
          className="w-full h-full z-20 object-cover"
        ></video>
        <img
          src={
            reel?.thumbnail
              ? reel.thumbnail
              : "https://images.pexels.com/photos/14438483/pexels-photo-14438483.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          }
          alt="Thumbnail"
          className={`${
            isVideoPlaying ? "hidden" : "absolute"
          } inset-0 w-full h-full z-10 object-cover cursor-pointer`}
          onClick={handleVideoButton}
        />
      </div>

      <div className="w-full z-20 absolute items-center justify-between bottom-4 px-3">
        <div className="flex items-center gap-2">
          <div>
            <img
              src={
                reel?.vendor?.shop_picture ||
                reel?.user?.image ||
                "https://as2.ftcdn.net/v2/jpg/02/44/42/79/1000_F_244427911_aoHHulebtYy4wLpncBBuWqCTNFKolcCB.jpg"
              }
              alt=""
              className="rounded-full w-[52px] h-[52px] md:w-8 md:h-8 xl:w-11 xl:h-11 bg-cover"
            />
          </div>
          <div className="font-bold text-base md:text-[10px] xl:text-sm text-white">
            {reel?.creater_name || "Creator name"}
          </div>
        </div>

        <div className="flex-col justify-center items-center hidden">
          <div
            className={`w-[30px] h-[30px] lg:w-[18px] lg:h-[18px] xl:w-[25px] xl:h-[25px] px-1 py-1 bg-[#58595933] rounded-full flex justify-center items-center ${
              isHeartFilled
                ? "text-blue-500 duration-500 ease-in-out"
                : "text-white"
            }`}
            onClick={handleHeartClick}
          >
            <FaHeart className="h-4 w-4 xl:h-5 xl:w-5 cursor-pointer" />
          </div>
          <div className="xl:text-xs md:text-[9px] text-[13px] text-white">
            likes
          </div>
        </div>
      </div>
    </div>
  );
}
