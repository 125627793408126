import React, { useEffect, useState } from "react";
import { TfiLayoutGrid3Alt, TfiLayoutGrid4Alt } from "react-icons/tfi";
import { IoListSharp } from "react-icons/io5";
import AdStoreVehiclesSingleCategoryCarsCard from "./AdStoreVehiclesSingleCategoryCarsCard";

const AdStoreVehiclesSingleCategoryCars = ({ adMainCatProduct }) => {
  const [vehicles, setVehicles] = useState([]);
  const [originalVehicles, setOriginalVehicles] = useState([]); // Keep the original order
  const [view, setView] = useState(5);
  const [isOpen, setIsOpen] = useState(false);
  const [sortType, setSortType] = useState("All");

  const handleView3 = () => setView(3);
  const handleView5 = () => setView(5);
  const handleView1 = () => setView(1);

  const handleSelection = (type) => {
    setSortType(type);
    setIsOpen(false);

    if (type === "Latest") {
      // Sort vehicles by timestamp in descending order
      const sortedVehicles = [...originalVehicles].sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setVehicles(sortedVehicles);
    } else {
      // Restore the original order for "All"
      setVehicles(originalVehicles);
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // Fetch data from an API
  //       const response = await fetch("/AdStoreVehicles.json");
  //       const data = await response.json();
  //       setVehicles(data);
  //       setOriginalVehicles(data); // Store the original data as-is
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="">
      <div className="flex my-5 items-center justify-end">
        {/* <div className="relative inline-block">
          <button
            className="flex items-center px-1 justify-between py-2 w-44 h-10 border border-gray-200 rounded"
            onClick={toggleDropdown}
          >
            <span className="text-[20px] opacity-70">Sort by:</span>
            <span className="text-[20px]"> {sortType}</span>
            <svg
              className={`w-7 h-7 transform ${isOpen ? "rotate-180" : ""}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>

          {isOpen && (
            <div className="absolute z-10 mt-1 w-44 bg-white border rounded shadow-lg">
              <ul>
                <li
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleSelection("All")}
                >
                  All
                </li>
                <li
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleSelection("Latest")}
                >
                  Latest
                </li>
              </ul>
            </div>
          )}
        </div> */}

        <div className="lg:flex items-center gap-3 hidden">
          <div className="text-lg">View:</div>
          <div onClick={handleView3}>
            <TfiLayoutGrid3Alt
              className={`${
                view === 3 ? "text-black" : "text-[#D9D9D9]"
              } h-7 w-7 cursor-pointer`}
            />
          </div>
          <div onClick={handleView5}>
            <TfiLayoutGrid4Alt
              className={`${
                view === 5 ? "text-black" : "text-[#D9D9D9]"
              } h-8 w-8 cursor-pointer`}
            />
          </div>
          <div onClick={handleView1}>
            <IoListSharp
              className={`${
                view === 1 ? "text-black" : "text-[#D9D9D9]"
              } h-10 w-10 cursor-pointer`}
            />
          </div>
        </div>
      </div>

      <div
        className={`grid gap-4 ${
          view === 3
            ? "grid-cols-2 md:grid-cols-3"
            : view === 5
            ? "grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
            : `grid-cols-${view}`
        }`}
      >
        {adMainCatProduct?.map((item, index) => (
          <div className="flex justify-center">
            <AdStoreVehiclesSingleCategoryCarsCard
              key={index}
              item={item}
              view={view}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdStoreVehiclesSingleCategoryCars;
