import React, { useEffect, useState } from "react";
import "./hotspot-section.css";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHotSpot } from "../../store/frontendSlice";
// import { imagePath } from "../../functions/URLString";
export default function HotspotImage() {
  const [hotspotImage, setHotspotImage] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [visiblePopups, setVisiblePopups] = useState({});
  const dispatch = useDispatch()
  const { hotspot } = useSelector(state => state.frontend);

  useEffect(() => {
    dispatch(getHotSpot());

    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Run the effect only once after the initial render

  const handleNextButtonClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % hotspot.length);
  };

  const togglePopup = (index) => {
    setVisiblePopups((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  return (
    <div className="hotspot-container relative">
      <img
        src={hotspot.length > 0 ? hotspot[currentImageIndex].image : ""}
        alt="Hotspot"
        className="hotspot-image w-full  object-contain"
      />
      <div
        className="hotspot-next-button absolute cursor-pointer bottom-1 left-4 flex items-center gap-1 sm:gap-4 bg-white rounded-full w-[180px] sm:w-[224px] md:w-[336px] lg:w-[472px]"
        onClick={handleNextButtonClick}
      >
        <img
          className="max-w-10 h-[33px] sm:max-w-14 sm:h-[52px] md:h-[98px] md:max-w-[126px] lg:max-w-44 lg:h-[126px]"
          style={{ borderRadius: "83.12px 0px 83.12px 83.12px" }}
          src={hotspot.length > 0 ? hotspot[(currentImageIndex + 1) % hotspot.length].image : ""}
          alt=""
        />
        <div className="">
          <h3 className="font-bold text-[9px] sm:text-sm lg:text-[20px]">
            {hotspot.length > 0 ? hotspot[(currentImageIndex + 1) % hotspot.length].title : ""}
          </h3>
          <p className="text-[#606060] text-[7px] sm:text-[11px] md:leading-5 lg:text-sm lg:w-44 lg:pt-2">
            {hotspot.length > 0 ? hotspot[(currentImageIndex + 1) % hotspot.length].description : ""}
          </p>
        </div>
      </div>
  
      {hotspot.length > 0 &&
        hotspot[currentImageIndex].dots.map((data, index) => (
          <div
            key={index}
            href={data.link}
            className="hotspot-link w-[14px] h-[14px] md:w-9 md:h-9 lg:w-[50px] lg:h-[50px] absolute"
            style={{ top: `${data.top}%`, left: `${data.left}%` }}
            onMouseOver={() => {
              if (!isMobileView) {
                const popup = document.querySelector(`#popup-${index}`);
                if (popup) popup.style.display = "block";
              }
            }}
            onMouseOut={() => {
              if (!isMobileView) {
                const popup = document.querySelector(`#popup-${index}`);
                if (popup) popup.style.display = "none";
              }
            }}
            onClick={() => {
              
              if (isMobileView) {
                togglePopup(index);
              }
            }}
          >
            <Link
              id={`popup-${index}`}
              to={"/main-categories/"+data.link}
              className="absolute bg-white top-[17px] -left-[28px] md:top-[40px] lg:top-[52px] md:-left-[43px] rounded-lg text-nowrap py-1 px-2 md:py-[11px] md:px-[21px] lg:py-[15px] lg:px-[25px] text-[9px] md:text-sm lg:text-base md:font-semibold"
              style={{
                display: isMobileView
                  ? visiblePopups[index]
                    ? "block"
                    : "none"
                  : "none",
              }}
            >
              {data.category}
            </Link>
          </div>
        ))}
    </div>
  );
  
}
